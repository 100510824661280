import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';

import { ORG_LEAVE } from '../../../../../constants/APDConstants';

import Tooltip from '../../../../../control/tooltip/Tooltip';

const useStyle = makeStyles(theme => ({
    root: {
        width: '212px',
        height: '320px',
        position: 'absolute',
        right: '33px',
        top: '90px',
        zIndex: 200,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: '#CCCCCC',
        borderRadius: '5px',
        padding: '5px',
        backgroundColor: ' white',
        [theme.breakpoints.down('xs')]: {
            top: '145px',
        }
    },
    rootImage: {
        backgroundColor: 'white',
        padding: '1px',
        color: '#757575',
        cursor: 'pointer'
    }
}))

const LayoutToolbarView = ({
    isOpenLayout,
    onChangeLayout
}) => {
    const classes = useStyle();

    return (
        <Grow
            in={isOpenLayout}
            style={{ transformOrigin: '0 0 0' }}
            {...(isOpenLayout ? { timeout: 300 } : {})}
        >
            <Grid className={classes.root} container>
                {ORG_LEAVE.LAYOUT.map(layout => (
                    <Grid
                        key={`layout-${layout.id}`}
                        item xs={6}
                        container
                        justify="center"
                        alignItems="center"
                        onClick={() => onChangeLayout(layout.id)}
                        className={classes.rootImage}
                    >
                        <Tooltip key={`tooltip-${layout.name}`} title={layout.label} placement="bottom">
                            <img alt="img" width="100" height="100" src={layout.image} />
                        </Tooltip>
                    </Grid>
                ))}
            </Grid>
        </Grow>
    )
}

export default LayoutToolbarView
