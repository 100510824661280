import React from 'react';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { Field } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';

import { ORG_LEAVE } from '../../../../../constants/APDConstants';
import routeConfig from '../../../../../config/appRoute/RouteConfig';

import Button from '../../../../../control/button/Button';
import checkBox from '../../../../../control/checkbox/Checkbox';

const useStyle = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    row: {
        padding: `0px ${theme.spacing(1)}px`,
        '&:first-child': {
            marginTop: '10px',
        },
    },
    newEmpColor: {
        color: `${theme.mainTheme.color4} !important`,
    },
    changeOrgColor: {
        color: theme.mainTheme.color1,
    },
    permissionIcon: {
        width: '15px',
        height: '15px',
        margin: '2px',
    },
    iconButton: {
        width: '45px',
        height: '45px',
    },
    visibilityIcon: {
        fontSize: '20px',
    },
    represent: {
        height: '30px',
        padding: '0px 16px',
        color: 'red',
    },
    managerSetting: {
        height: '57px',
        padding: '0px 16px',
    },
    buttonManagerSetting: {
        width: '100%',
        border: '1px dashed #63666a !important',
        backgroundColor: '#FFFFFF !important',
        color: '#63666a !important',
        '&:hover': {
            backgroundColor: '#00000012 !important',
        },
    },
    settingIcon: {
        width: '20px',
        height: '20px',
    },
    buttonManagerSettingMode: {
        width: '100%',
    },
    buttonManagerSettingModeCancel: {
        width: '100%',
        backgroundColor: '#808080 !important',
    },
    editMode: {
        backgroundColor: 'red',
        color: '#FFFFFF',
        height: '20px',
        fontSize: '18px',
        textAlign: 'center',
    },
    checkBox: {
        margin: 0,
    },
    iconManager: {
        fontWeight: 'bold',
        fontSize: '26px',
        color: '#F47B15',
        marginLeft: '15px',
    },
    countSignAbsence: {
        marginLeft: '10px',
    },
    tooltip: {
        fontSize: '18px',
    },
}));

const setPermissionDisplay = ({ permission, classes }) => {
    const permissionDisplays = [];

    if (permission.indexOf(ORG_LEAVE.PERMISSION.AUDIT_ID) !== -1) {
        permissionDisplays.push(
            <img
                alt="img"
                key="audit"
                className={classes.permissionIcon}
                src={`${process.env.PUBLIC_URL}/img/leave/audit.svg`}
            />
        );
    }

    if (permission.indexOf(ORG_LEAVE.PERMISSION.REPRESENT_ID) !== -1) {
        permissionDisplays.push(
            <img
                alt="img"
                key="represent"
                className={classes.permissionIcon}
                src={`${process.env.PUBLIC_URL}/img/leave/represent.svg`}
            />
        );
    }

    if (permission.indexOf(ORG_LEAVE.PERMISSION.APPROVE_ID) !== -1) {
        permissionDisplays.push(
            <img
                alt="img"
                key="approve"
                className={classes.permissionIcon}
                src={`${process.env.PUBLIC_URL}/img/leave/approve.svg`}
            />
        );
    }

    return permissionDisplays;
};

const checkStatus = (isManager, classes) => {
    if (isManager) {
        return <label className={classes.iconManager}>M</label>;
    } else {
        return null;
    }
};

const RowDisplay = ({
    classes,
    isOpenAbsence,
    data,
    visibleSlink,
    onEdit,
    onVisible,
    settingMode,
}) => {
    const notMode = settingMode === 'M' ? 'A' : 'M';

    return (
        <Grid
            container
            className={classnames(classes.row, {
                [classes.changeOrgColor]:
                    data.prevOrgId || data.isChangeManager,
                [classes.newEmpColor]: data.isNewEmp,
            })}
            alignItems="center"
        >
            <Grid item xs={1} container justify="center">
                {settingMode ? (
                    checkStatus(data.isManager, classes) !== notMode ? (
                        <Field
                            component={checkBox}
                            name={settingMode}
                            id={data.id}
                            className={classes.checkBox}
                        />
                    ) : (
                        notMode
                    )
                ) : (
                    checkStatus(data.isManager, classes)
                )}
            </Grid>
            <Grid item xs={2} container justify="center">
                {setPermissionDisplay({ permission: data.permission, classes })}
            </Grid>
            <Grid item xs={data.prevOrgId ? 6 : 7}>
                {data.name}
                {data.isContractEmployee && <label> (ลูกจ้าง)</label>}

                {data.countSignAbsence > 0 &&
                    (isOpenAbsence ? (
                        <Tooltip
                            title="จำนวนใบลาที่ถือครอง"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <a
                                className={classes.countSignAbsence}
                                href={`${process.env.PUBLIC_URL}${routeConfig.LEAVE_ABSENCE}?nameSign=${data.name}`}
                                target={data.name}
                            >
                                ({data.countSignAbsence})
                            </a>
                        </Tooltip>
                    ) : (
                        <label> ({data.countSignAbsence})</label>
                    ))}
            </Grid>
            <Grid item xs={data.prevOrgId ? 3 : 2} container justify="flex-end">
                {data.prevOrgId && (
                    <IconButton
                        onClick={() => onVisible(data)}
                        className={classes.iconButton}
                    >
                        {visibleSlink === data.id ? (
                            <VisibilityOffIcon
                                className={classes.visibilityIcon}
                            />
                        ) : (
                            <VisibilityIcon
                                className={classes.visibilityIcon}
                            />
                        )}
                    </IconButton>
                )}
                <IconButton
                    onClick={() => onEdit(data)}
                    className={classes.iconButton}
                >
                    <img
                        alt="img"
                        src={`${process.env.PUBLIC_URL}/img/leave/edit.svg`}
                        width="15px"
                        height="15px"
                    />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
        </Grid>
    );
};

const getHeightOther = ({ representEmpName, settingMode, isSetManager }) => {
    let heightOther = isSetManager ? 57 : 0; // ความสูง "ปุ่มจัดการผู้จัดการ (57)"

    if (representEmpName) {
        heightOther = heightOther + 30; // ความสูง "ผู้ทำการแทน"
    }

    if (settingMode) {
        heightOther = heightOther + 20; // ตวามสูง "กำลังแก้ไข..."
    }

    return heightOther;
};

const DetailOrgView = ({
    isOpenAbsence,
    isSetManager,
    empDisplay,
    representEmpName,
    visibleSlink,
    settingMode,

    onEdit,
    onVisible,
    onSettingManagerClick,
    onSaveClick,
}) => {
    const classes = useStyle();
    const title = settingMode === 'M' ? 'ผู้จัดการ' : 'ผู้ช่วยผู้จัดการ';
    const heightOther = getHeightOther({
        representEmpName,
        settingMode,
        isSetManager,
    });

    return (
        <Grid container className={classes.root}>
            {settingMode && (
                <Grid item xs={12} className={classes.editMode}>
                    กำลังแก้ไข {title}
                </Grid>
            )}

            <Grid
                item
                xs={12}
                style={{ height: `calc(100% - ${heightOther}px)` }}
            >
                <Scrollbars universal={true}>
                    {empDisplay.map((data, index) => (
                        <RowDisplay
                            classes={classes}
                            key={data.id}
                            isOpenAbsence={isOpenAbsence}
                            data={data}
                            visibleSlink={visibleSlink}
                            onEdit={onEdit}
                            onVisible={onVisible}
                            settingMode={settingMode}
                            index={index}
                        />
                    ))}
                </Scrollbars>
            </Grid>

            {representEmpName && (
                <Grid item xs={12} className={classes.represent}>
                    ถูกทำการแทนโดย : {representEmpName}
                </Grid>
            )}

            {isSetManager && (
                <Grid item xs={12} className={classes.managerSetting}>
                    {settingMode ? (
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                                <Button
                                    label="ยกเลิก"
                                    className={
                                        classes.buttonManagerSettingModeCancel
                                    }
                                    onClick={() => onSettingManagerClick(null)}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Button
                                    label="ตกลง"
                                    className={classes.buttonManagerSettingMode}
                                    onClick={onSaveClick}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <Button
                                    label=" ผู้จัดการ"
                                    className={classes.buttonManagerSetting}
                                    startIcon={
                                        <SettingsIcon
                                            className={classes.settingIcon}
                                        />
                                    }
                                    onClick={() => onSettingManagerClick('M')}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default DetailOrgView;
