import React, { useContext, Fragment } from 'react'
import { BookingByDateContext } from './BookingByDateProvider'

import Loading from '../../../../control/loading/Loading'
import AlertMessage from '../../../../control/alert/AlertMessage'

const UtilsPage = () => {
    const { loadingState, alertMessageState } = useContext(BookingByDateContext)

    return (
        <Fragment>
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    )
}

export default UtilsPage
