import { ABSENCE, ERRORS } from '../../../../constants/APDConstants'

const { FORM_SEARCH_ABSENCE, FORM_EDIT_ABSENCE, LEAVE_STATE_ID } = ABSENCE

export const validateSearchAbsence = (values) => {
    const errors = {}

    if (!values || !Object.keys(values).some(key => (values[key] && key !== 'year'))) {
        errors[FORM_SEARCH_ABSENCE.NAME.ERROR] = ERRORS.AT_LEAST_ONE_CONDITION
    }

    return errors
}

const {
    LEAVE_TYPE_ID,
    LEAVE_REASON,
    CONTACT_ADDRESS,
    ATTACHMENT,
    IN_ADVANCE
} = FORM_EDIT_ABSENCE.NAME

export const validateEditAbsence = (values) => {
    const errors = {}

    switch (values[LEAVE_TYPE_ID]) {
        case FORM_EDIT_ABSENCE.LEAVE_TYPE_ID.SICK: //ลาป่วย
            if (values[IN_ADVANCE] > 0 && !values[LEAVE_REASON]) {
                errors[LEAVE_REASON] = ERRORS.REQUIRED
            }
            if (!values[CONTACT_ADDRESS]) {
                errors[CONTACT_ADDRESS] = ERRORS.REQUIRED
            }
            break;
        case FORM_EDIT_ABSENCE.LEAVE_TYPE_ID.PERSONAL: //ลากิจส่วนตัว
            if (!values[LEAVE_REASON]) {
                errors[LEAVE_REASON] = ERRORS.REQUIRED
            }
            if (!values[CONTACT_ADDRESS]) {
                errors[CONTACT_ADDRESS] = ERRORS.REQUIRED
            }
            break;
        case FORM_EDIT_ABSENCE.LEAVE_TYPE_ID.VACATION: //ลาพักผ่อน
            if (values[IN_ADVANCE] < 7 && !values[LEAVE_REASON]) {
                errors[LEAVE_REASON] = ERRORS.REQUIRED
            }
            if (!values[CONTACT_ADDRESS]) {
                errors[CONTACT_ADDRESS] = ERRORS.REQUIRED
            }
            break;
        case FORM_EDIT_ABSENCE.LEAVE_TYPE_ID.MATERNITY: //ลาคลอด
            if (!values[CONTACT_ADDRESS]) {
                errors[CONTACT_ADDRESS] = ERRORS.REQUIRED
            }
            if (!values[ATTACHMENT] || values[ATTACHMENT].length === 0) {
                errors[ATTACHMENT] = ERRORS.REQUIRED
            }
            break;
        case FORM_EDIT_ABSENCE.LEAVE_TYPE_ID.CHILD_SUPPORT: //ลาเลี้ยงดูบุตร
            if (!values[CONTACT_ADDRESS]) {
                errors[CONTACT_ADDRESS] = ERRORS.REQUIRED
            }

            if (!values[LEAVE_REASON]) {
                errors[LEAVE_REASON] = ERRORS.REQUIRED
            }
            break;
        case FORM_EDIT_ABSENCE.LEAVE_TYPE_ID.ASSIST_BIRTH: //ลาไปช่วยเหลือภริยาที่คลอดบุตร
            if (!values[CONTACT_ADDRESS]) {
                errors[CONTACT_ADDRESS] = ERRORS.REQUIRED
            }
            if (!values[ATTACHMENT] || values[ATTACHMENT].length === 0) {
                errors[ATTACHMENT] = ERRORS.REQUIRED
            }
            break;
        case FORM_EDIT_ABSENCE.LEAVE_TYPE_ID.ORDINATION: // ลาอุปสมบท
            if (values[IN_ADVANCE] < 60 && !values[LEAVE_REASON]) {
                errors[LEAVE_REASON] = ERRORS.REQUIRED
            }
            if (!values[ATTACHMENT] || values[ATTACHMENT].length === 0) {
                errors[ATTACHMENT] = ERRORS.REQUIRED
            }
            break;
        case FORM_EDIT_ABSENCE.LEAVE_TYPE_ID.HAJJ: // ลาไปประกอบพิธีฮัจย์
            if (values[IN_ADVANCE] < 60 && !values[LEAVE_REASON]) {
                errors[LEAVE_REASON] = ERRORS.REQUIRED
            }
            if (!values[CONTACT_ADDRESS]) {
                errors[CONTACT_ADDRESS] = ERRORS.REQUIRED
            }
            if (values[IN_ADVANCE] < 60 && (!values[ATTACHMENT] || values[ATTACHMENT].length === 0)) {
                errors[ATTACHMENT] = ERRORS.REQUIRED
            }
            break;
        case FORM_EDIT_ABSENCE.LEAVE_TYPE_ID.WOMAN_ORDINATION: // สตรีลาไปถือศีลและปฎิบัติธรรม
            if (values[IN_ADVANCE] < 30 && !values[LEAVE_REASON]) {
                errors[LEAVE_REASON] = ERRORS.REQUIRED
            }
            if (!values[CONTACT_ADDRESS]) {
                errors[CONTACT_ADDRESS] = ERRORS.REQUIRED
            }
            if (!values[ATTACHMENT] || values[ATTACHMENT].length === 0) {
                errors[ATTACHMENT] = ERRORS.REQUIRED
            }
            break;
        default:
            break;
    }

    return errors
}

export const validateCancelAbsence = (values) => {
    const errors = {}

    if (!values.cancelReason) {
        errors.cancelReason = ERRORS.REQUIRED
    }

    return errors
}

export const checkStatusCancel = (leaveStateId) => {
    const stateCancel = [LEAVE_STATE_ID.CANCEL, LEAVE_STATE_ID.CANCEL_SAP]
    let isCancel = false

    if (stateCancel.indexOf(leaveStateId) !== -1) {
        isCancel = true
    }

    return isCancel
}

export const checkShowCancelAbsence = (leaveStateId) => {
    const stateShowCancel = [
        LEAVE_STATE_ID.WAITING_PERMIT,
        LEAVE_STATE_ID.WAITING_APPROVE,
        LEAVE_STATE_ID.PERMITTED,
        LEAVE_STATE_ID.WAITING_CANCEL_PERMIT,
        LEAVE_STATE_ID.WAITING_CANCEL_APPROVE
    ]

    let isShowCancel = false

    if (stateShowCancel.indexOf(leaveStateId) !== -1) {
        isShowCancel = true
    }

    return isShowCancel

}