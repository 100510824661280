import { useState } from 'react'

const UseSlinksState = () => {
    const [visibleSlink, setVisibleSlink] = useState();
    const [tempNodeId, setTempNodeId] = useState();

    const _findNode = (nodes, nodeId) => {
        return nodes.some(node => node.id === nodeId)
    }

    const _getLevelNode = (nodeData) => {
        let level = 0;

        window.chart.config.nodes.forEach(node => {
            if (node.id === nodeData.pid) {
                level = level + 1 + _getLevelNode(node)
            }
        });
        return level
    }

    const _getTemplate = (fromNodeId, toNodeId) => {
        let fromNode = null, toNode = null

        window.chart.config.nodes.forEach(node => {
            if (node.id === fromNodeId) {
                fromNode = node
            }

            if (node.id === toNodeId) {
                toNode = node
            }
        })

        return (_getLevelNode(fromNode) >= _getLevelNode(toNode)) ? 'children' : 'parent'
    }

    const setSlink = data => {
        if (visibleSlink === data.id) {
            clearSlink();
        } else {
            setVisibleSlink(data.id)

            if (tempNodeId) {
                window.chart.remove(tempNodeId);
                setTempNodeId();
            }

            if (!_findNode(window.chart.config.nodes, data.prevOrgId)) {
                window.chart.add({
                    id: data.prevOrgId,
                    name: "มาจากหน่วยงานอื่น",
                    tags: ["tempOrg"],
                    isTempNode: true
                });
                setTempNodeId(data.prevOrgId)
            }

            window.chart.config.slinks = [{
                from: data.prevOrgId,
                to: data.orgId,
                template: _getTemplate(data.prevOrgId, data.orgId),
                // label: data.name
            }]

            window.chart.draw();
            window.chart.center(data.prevOrgId)
        }
    }

    const clearSlink = () => {
        if (tempNodeId) {
            window.chart.remove(tempNodeId);
            setTempNodeId();
        }

        setVisibleSlink()
        window.chart.config.slinks = []
        window.chart.draw();
    }

    return {
        visibleSlink,

        setSlink,
        clearSlink
    }
}

export default UseSlinksState