import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        padding: '24px',
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    rootPaper: {
        margin: '10px',
        paddingBottom: '2%',
        paddingTop: '2%'
    },
    title: {
        paddingTop: '26px !important',
        fontSize: '26px',
        borderBottom: '2px solid #F47B15'
    },
    form: {
        marginTop: '24px'
    }
}))

const EditUserGroupView = ({
    children
}) => {
    const classes = useStyles()

    return (
        <Scrollbars>
            <Grid container justify="center" className={classes.root}>
                <Grid item xs={12} sm={11}>
                    <Paper className={classes.rootPaper}>
                        <Grid item xs={12} container justify='center'>
                            <Grid item xs={10} className={classes.title}>
                                <label>แก้ไขกลุ่มผู้ใช้งาน</label>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container justify='center' className={classes.form}>
                            <Grid item xs={10}>
                                {children}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Scrollbars>
    )
}

export default EditUserGroupView
