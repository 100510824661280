import { useState } from 'react';

function UseDrawerState() {
    const [open, setOpen] = useState(false);

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    return [open, handleDrawerOpen, handleDrawerClose];
}

export default UseDrawerState;
