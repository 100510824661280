import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen
        }),
        width:'100%',
        height:'calc(100% - 92px)',
        marginTop:'92px',

    },
    contentShift: {
        marginLeft: 0,
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    toolbar: theme.mixins.toolbar
}));

function Main({ children, open }) {
    const classes = useStyles();
    return (
        <main
            className={clsx(classes.content, { [classes.contentShift]: open })}
        >
            {children}
        </main>
    );
}

export default Main;
