import React from 'react';

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ReceiptIcon from '@material-ui/icons/Receipt';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import RouteConfig from '../../config/appRoute/RouteConfig'
import { FUNCTIONS } from '../../constants/APDConstants'

const MeetingMenu = [
    {
        functionId: FUNCTIONS.MEETING_ROOM.id,
        icon: <MeetingRoomIcon />,
        label: 'จัดการห้องประชุม',
        to: RouteConfig.MEETING_MANAGE,
        pathActive: [
            RouteConfig.MEETING_MANAGE,
            RouteConfig.MEETING_MANAGE_ADD,
            RouteConfig.MEETING_MANAGE_EDIT
        ]
    },
    {
        functionId: FUNCTIONS.BOOKING_ROOM.id,
        icon: <DateRangeIcon />,
        label: 'จัดการข้อมูลการจองห้องประชุม',
        to: RouteConfig.MEETING_BOOKING_TABLE,
        pathActive: [
            RouteConfig.MEETING_BOOKING_TABLE,
            RouteConfig.MEETING_BOOKING_CALENDAR
        ]
    },
    {
        functionId: FUNCTIONS.DAILY_BOOKING.id,
        icon: <AssignmentIcon />,
        label: 'รายงานจองห้องประชุมประจำวัน',
        to: RouteConfig.MEETING_DAILY_BOOKING
    },
    {
        functionId: FUNCTIONS.EXPORT_CANCELLED_BOOKING.id,
        icon: <ReceiptIcon />,
        label: 'รายงานการยกเลิกการจองห้องประชุม',
        to: RouteConfig.MEETING_CANCELLED_BOOKING
    },
    {
        functionId: '-1',
        icon: <LiveHelpIcon />,
        label: 'คู่มือ',
        to: window.URL_USERMANUAL.MEETING
    },

];

export default MeetingMenu
