import React, { useContext } from 'react'

import SignAbsenceView from '../../presentational/absence/SignAbsenceView'
import { AbsenceContext } from './AbsenceProvider'


const SignAbsence = ({ onClose }) => {
    const { absenceState } = useContext(AbsenceContext)
    const { signBy } = absenceState.dataEditAbsence

    return (
        <SignAbsenceView
            signBy={signBy}
            onClose={onClose}
        />
    )
}

export default SignAbsence
