import React from 'react';
import CancelBookingProvider from './CancelBookingProvider'
import CancelBookingPage from './form/CancelBookingPage'
const CancelBookingIndex = () => {
    return (

        <CancelBookingProvider>
            <CancelBookingPage />
        </CancelBookingProvider>
    );
};

export default CancelBookingIndex;
