import React, { useContext, Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'

import { ConnextContext } from '../../container/connext/ConnextProvider'
import { MODE_MODAL } from '../../../../constants/maintenance/ConnextConstants'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    root: {
        // height: 'calc(100% - 150px)',
        width: '60%',
        // [theme.breakpoints.up('lg')]: {
        //     width: '70%',
        // }
    },
}))

const ModalConnextView = ({
    children
}) => {
    const { modalState } = useContext(ConnextContext)
    const classes = useStyles();

    return (
        <Fragment>
            <Modal
                open={(modalState.mode === MODE_MODAL.ADD_MAINTENANCE || modalState.mode === MODE_MODAL.EDIT_MAINTENANCE)}
                className={classes.modal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Grid container justify="center" alignItems="center" className={classes.root}>
                    {children}
                </Grid>
            </Modal>
        </Fragment>
    )
}

export default ModalConnextView