import React, { useEffect, Fragment } from 'react'
import moment from 'moment';
import { reduxForm, formValueSelector, isDirty } from "redux-form"
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FORM_MAINTENANCE, API_ID } from '../../../../../constants/maintenance/ConnextConstants'
import { validateFormMaintenance } from '../ConnextUtils'
import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'

import UseAlertMessageState from '../../../../../control/alert/UseAlertMessageState'

import FormMaintenanceView from '../../../presentational/connext/form/FormMaintenanceView'
import AlertMessage from '../../../../../control/alert/AlertMessage'

const FormMaintenance = ({
    title,
    startDate,
    endDate,
    displayDate,
    dirty,

    handleSubmit,
    callBackSubmit,
    callBackCancel,
    initialize,
    initData,
    change
}) => {
    const alertMessageState = UseAlertMessageState()

    useEffect(() => {
        if (initData) {
            initialize({
                [FORM_MAINTENANCE.NAME.START_DATE]: new Date(initData.startDate),
                [FORM_MAINTENANCE.NAME.END_DATE]: new Date(initData.endDate),
                [FORM_MAINTENANCE.NAME.DISPLAY_DATE]: new Date(initData.displayDate),
                [FORM_MAINTENANCE.NAME.NOTE]: initData.note,
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleCancel = () => {
        if (dirty) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: "ยกเลิก",
                btnCancelName: "ไม่ยกเลิก",
                callbackConfirm: () => {
                    callBackCancel()
                }
            })
        } else {
            callBackCancel()
        }
    }

    const handleChangeDate = field => value => {
        if (value) {
            switch (field) {
                case FORM_MAINTENANCE.NAME.START_DATE:
                    if (endDate && value > endDate) {
                        change(FORM_MAINTENANCE.NAME.END_DATE, value)
                    }
                    if ((displayDate && value < displayDate)) {
                        change(FORM_MAINTENANCE.NAME.DISPLAY_DATE, value)
                    }
                    // if (!displayDate) {
                    // const now = new Date()
                    // const defaultDisplayDate = now > value ? value : now
                    // change(FORM_MAINTENANCE.NAME.DISPLAY_DATE, defaultDisplayDate)
                    // }
                    const defaultDisplayDate = new Date(moment(value).subtract(1, 'days'))
                    change(FORM_MAINTENANCE.NAME.DISPLAY_DATE, defaultDisplayDate)
                    break;
                case FORM_MAINTENANCE.NAME.END_DATE:
                    if (startDate && value < startDate) {
                        const defaultDisplayDate = new Date(moment(value).subtract(1, 'days'))

                        change(FORM_MAINTENANCE.NAME.START_DATE, value)
                        change(FORM_MAINTENANCE.NAME.DISPLAY_DATE, defaultDisplayDate)
                        // change(FORM_MAINTENANCE.NAME.DISPLAY_DATE, value)
                    }
                    break;
                case FORM_MAINTENANCE.NAME.DISPLAY_DATE:
                    if (startDate && value > startDate) {
                        alertMessageState.showAlert({
                            altMsg: 'เวลาที่แสดงข้อความแจ้งเตือนต้องไม่เกินเวลาเริ่มต้นปิดระบบ',
                            callbackCancel: () => {
                                alertMessageState.hideAlert()
                                change(FORM_MAINTENANCE.NAME.DISPLAY_DATE, startDate)
                            }
                        })
                    }
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <Fragment>
            <FormMaintenanceView
                title={title}
                startDate={startDate ? startDate : undefined}
                disabled={initData && initData.apiId && initData.apiId !== API_ID.CONNEXT}
                api={initData && initData.apiName ? initData.apiName : "connext"}

                onSubmit={handleSubmit(callBackSubmit)}
                onCancel={handleCancel}
                onChangeDate={handleChangeDate}
            />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    )
}



const reduxFormParameter = {
    form: 'FormMaintenance',
    validate: validateFormMaintenance,
};

const selector = formValueSelector('FormMaintenance')

const mapStateToProps = state => ({
    startDate: selector(state, FORM_MAINTENANCE.NAME.START_DATE),
    endDate: selector(state, FORM_MAINTENANCE.NAME.END_DATE),
    displayDate: selector(state, FORM_MAINTENANCE.NAME.DISPLAY_DATE),
    dirty: isDirty('FormMaintenance')(state)
})

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps)
)(FormMaintenance)