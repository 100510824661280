import React from 'react';
import { Field } from 'redux-form';
import { Scrollbars } from 'react-custom-scrollbars';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { ORG_LEAVE } from '../../../../../constants/APDConstants';

import TextFields from '../../../../../control/textfield/TextFields';
import Autocomplete from '../../../../../control/autocomplete/AutoComplete'
import Button from '../../../../../control/button/Button';

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    body: {
        padding: theme.spacing(3)
    },
    action: {
        marginTop: theme.spacing(1) / 2
    },
    button: {
        width: '100px',
        marginLeft: '30px'
    },
    prevDisplay: {
        color: 'red',
        marginBottom: '10px'
    }
}))

const EditOrgView = ({
    isHaveRepresent,
    lutApprove = [],
    lutAudit = [],
    prevApproveName,
    prevAuditName,

    onSubmit
}) => {
    const classes = useStyle();

    return (
        <Grid container className={classes.root}>
            <Scrollbars universal={true}>
                <Grid container className={classes.body}>
                    <Grid item xs={12}>
                        <Field
                            name={ORG_LEAVE.FORM_EDIT_ORG.NAME.NAME}
                            component={TextFields}
                            label={ORG_LEAVE.FORM_EDIT_ORG.LABEL.NAME}
                            required
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <Field
                            name={ORG_LEAVE.FORM_EDIT_ORG.NAME.PERMISSION_APPROVE}
                            component={Autocomplete}
                            options={lutApprove}
                            // label={ORG_LEAVE.FORM_EDIT_ORG.LABEL.PERMISSION_APPROVE}
                            label={
                                <label>
                                    {ORG_LEAVE.FORM_EDIT_ORG.LABEL.PERMISSION_APPROVE}{" "}
                                    <img alt="img" key="approve" width={10} height={10} src={`${process.env.PUBLIC_URL}/img/leave/approve.svg`} />
                                </label>
                            }
                            optionKey={{ label: "empName", value: "empId", abbr: "empId" }}
                            required={!isHaveRepresent}
                        />
                    </Grid>
                    {prevApproveName && <Grid item xs={12} className={classes.prevDisplay}>
                        ผู้อนุญาตเดิม : {prevApproveName}
                    </Grid>}
                    <Grid item xs={12}>
                        <Field
                            name={ORG_LEAVE.FORM_EDIT_ORG.NAME.PERMISSION_AUDIT}
                            component={Autocomplete}
                            options={lutAudit}
                            // label={ORG_LEAVE.FORM_EDIT_ORG.LABEL.PERMISSION_AUDIT}
                            label={
                                <label>
                                    {ORG_LEAVE.FORM_EDIT_ORG.LABEL.PERMISSION_AUDIT}{" "}
                                    <img alt="img" key="audit" width={10} height={10} src={`${process.env.PUBLIC_URL}/img/leave/audit.svg`} />
                                </label>
                            }
                            optionKey={{ label: "empName", value: "empId", abbr: "empId" }}
                        />
                    </Grid>
                    {prevAuditName && <Grid item xs={12} className={classes.prevDisplay}>
                        ผู้พิจารณาเดิม : {prevAuditName}
                    </Grid>}

                    <Grid container className={classes.action} justify="center">
                        <Button className={classes.button} onClick={onSubmit} label="ตกลง" />
                    </Grid>
                </Grid>
            </Scrollbars>
        </Grid>
    )
}

export default EditOrgView
