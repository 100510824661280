import { ERRORS } from '../../../../constants/APDConstants'
import { FORM_MAINTENANCE } from '../../../../constants/maintenance/ConnextConstants'

export const validateFormMaintenance = values => {
    const errors = {}

    if (!values[FORM_MAINTENANCE.NAME.START_DATE]) {
        errors[FORM_MAINTENANCE.NAME.START_DATE] = ERRORS.REQUIRED
    }

    if (!values[FORM_MAINTENANCE.NAME.END_DATE]) {
        errors[FORM_MAINTENANCE.NAME.END_DATE] = ERRORS.REQUIRED
    }

    if (!values[FORM_MAINTENANCE.NAME.DISPLAY_DATE]) {
        errors[FORM_MAINTENANCE.NAME.DISPLAY_DATE] = ERRORS.REQUIRED
    }

    return errors
}