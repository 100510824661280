import React, { useState } from 'react'
import Flatpickr from 'react-flatpickr';
// import 'flatpickr/dist/themes/material_green.css'
// import { Thai } from 'flatpickr/dist/l10n/th.js'

// import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'

import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import { addYears } from 'date-fns';

// import yearDropdownPlugin from './YearDropdownPlugin'
import FormHelperText from '@material-ui/core/FormHelperText';

import './Themeflat.css'

const UseStyles = makeStyles(theme => ({
    textField: {
        [`& input`]: {
            height: theme.field.height,
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        // marginTop: '2px',
        width: '100%',
        boxShadow: theme.field.boxShadow
    },
    formHelper: {
        color: theme.mainTheme.color5,
        marginTop: '1px',
        marginLeft: '10px'
    },
    label: {
        color: theme.mainTheme.color1
    },
    error: {
        color: theme.mainTheme.color5
    },
    badge: {
        top: '25px',
        right: '14px',
        fontSize: '12px'
    },
    icon: {
        color: theme.mainTheme.color6,
        width: '25px',
        height: '25px'
    },
    asterisk: {
        color: '#FF0000'
    },
    inputFlat: {
        width: '100%',
        borderRadius: '4px',
        height: '40px',
        border: '1px solid #cbcbcb',
        padding: '0px 15px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 7px 0px',
        '&:hover': {
            border: '1px solid #000000',
        },
        '&:focus': {
            outlineColor: 'unset',
            outline: 'unset',
            border: `2px solid ${theme.mainTheme.color1}`
        }
    },
    inputFlatErr: {
        width: '100%',
        borderRadius: '4px',
        height: '40px',
        border: `1px solid ${theme.mainTheme.color5}`,
        padding: '0px 15px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 7px 0px',
        '&:hover': {
            border: '1px solid #000000',
        },
        '&:focus': {
            outlineColor: 'unset',
            outline: 'unset',
            border: `2px solid ${theme.mainTheme.color5}`
        }
    }
}));

const Dateflat = ({
    label,
    input,
    required,
    meta: { error, touched, invalid, active, submitFailed },
    placeholder = 'ชั่วโมง/นาที',
    onChange
}) => {

    const classes = UseStyles()
    const asterisk = required ? (
        <span className={classes.asterisk}> *</span>
    ) : (
            ''
        );
    const isError = touched && invalid && error ? true : false;

    const [date, setDate] = useState()

    if (input.value !== '' && !date) {
        setDate(input.value)
    } else if (input.value !== "" && input.value !== date) {
        setDate(input.value)
    }

    const handleChange = (dt) => {
        if (dt[0]) {
            input.onChange(dt[0])
            setDate(dt[0])
            onChange && onChange(dt[0])
        } else {
            input.onChange('')
            setDate('')
        }

    }

    if (input.value === '' && date) {
        setDate()
    }

    const convertYearToBD = (dt) => {
        return addYears(dt, 543)
    }

    const options = {
        enableTime: true,
        dateFormat: `H:i`,
        time_24hr: true,
        static: true,
        noCalendar: true,
        minuteIncrement: 15,
        disableMobile: "true",
    }

    return (
        <div id='source' style={{ height: '90px' }}>
            <label
                className={classnames(classes.label, {
                    [classes.error]: isError
                })}
            >
                {label}
                {asterisk}
            </label>
            <Flatpickr
                data-enable-time
                value={date}
                options={options}
                onChange={handleChange}

                render={({ defaultValue }, ref) => {
                    return (
                        <div>
                            <input className={touched && invalid && error ? classes.inputFlatErr : classes.inputFlat} value={date} ref={ref} placeholder={placeholder} />
                            {/* <button type='button' data-clear>Clear</button> */}
                        </div>
                    )
                }} />
            <FormHelperText className={classes.formHelper}>
                {touched && invalid && error}
            </FormHelperText>
        </div>


    )
}

export default Dateflat

