import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import FieldTextDisplayView from './FieldTextDisplayView'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '8px 0px important',
        paddingBottom: '14px'
    }
}))

const FieldTextReduxDisplay = ({ label, input }) => {
    const classes = useStyles()

    return (
        <FieldTextDisplayView
            label={label}
            data={input.value}
            classes={{ root: classes.root }}
        />
    )
}

export default FieldTextReduxDisplay
