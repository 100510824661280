import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Material UI Importing
import {
    ThemeProvider,
    StylesProvider,
    createGenerateClassName,
} from '@material-ui/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';

const generateClassName = createGenerateClassName({ productionPrefix: 'got' });

const closeConsole = () => {
    console.log(
        '%cThis is console for Developer',
        'font-weight: bold; font-size: 50px; color: red; text-shadow: 1px 1px 0px black, 1px -1px 0px black, -1px 1px 0px black, -1px -1px 0px black'
    );
    window.console.log = function () {
        // console.error('This is console for developer')
        window.console.log = function () {
            return false;
        };
    };

    window.console.warn = function () {
        window.console.warn = function () {
            return false;
        };
    };

    window.console.error = function () {
        window.console.error = function () {
            return false;
        };
    };
};

//closeConsole();

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <StylesProvider generateClassName={generateClassName}>
            <CssBaseline />
            <App />
        </StylesProvider>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
