import { fetchGet, createParamGet, fetchPost } from '../utilities/ServiceUtils';
import * as ApiConstants from '../constants/ApiConstants'
import { API } from '../constants/ServiceMSGConstants'

export const getLutLeaveState = () => {
    return fetchGet(ApiConstants.SERVICE_GET_LU_LEAVE_STATE)
        .then(res => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch(ex => {
            console.log('ex getLutLeaveState', ex)
        })
}

export const getLutLeaveType = () => {
    return fetchGet(ApiConstants.SERVICE_GET_LU_LEAVE_TYPE)
        .then(res => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch(ex => {
            console.log('ex getLutLeaveType', ex)
        })
}

export const getLutSpecialLeave = (leaveTypeId) => {
    return fetchGet(`${ApiConstants.SERVICE_GET_LUT_SPECIAL_LEAVE}?leaveTypeId=${leaveTypeId}`)
        .then(res => {
            if (!res.ok) {
                throw res.status
            }
            return res.json()
        })
        .catch(ex => {
            console.log('ex getLutSpecialLeave', ex)
        })
}

export const getLeaveAbsence = ({
    absenceId,
    name,
    orgId,
    startDate,
    endDate,
    leaveStateId,
    leaveTypeId,
    depId,
    permissionOrgId,
    nameSign,
    flag
}) => {
    return fetchGet(`${ApiConstants.SERVICE_GET_LEAVE_ABSENCE}?${createParamGet({
        absenceId,
        name,
        orgId,
        startDate,
        endDate,
        leaveStateId,
        leaveTypeId,
        depId,
        permissionOrgId,
        nameSign,
        flag
    })}`)
        .then(res => {
            if (!res.ok) {
                throw { status: res.status, api: API.LEAVE };
            }
            return res.json();
        })
        .catch(ex => {
            console.log('ex getLeaveAbsence', ex)
            return ex
        })
}

export const getLutSickReason = () => {
    return fetchGet(ApiConstants.SERVICE_GET_LU_SICK_REASON)
        .then(res => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch(ex => {
            console.log('ex getLutSickReason', ex)
        })
}

export const updateLeaveAbsence = ({
    absenceId,
    leaveReason,
    contactAddress,
    isAttachment,
    attachments,
    updateBy,
    empId
}) => {
    return fetchPost(ApiConstants.SERVICE_UPDATE_LEAVE, {
        absenceId,
        leaveReason,
        contactAddress,
        isAttachment,
        attachments,
        updateBy,
        empId
    })
        .then(res => {
            if (!res.ok) {
                // throw res.status;
                throw { status: res.status, api: API.LEAVE };
            }
            return res.json();
        })
        .catch(ex => {
            console.log('ex updateLeaveAbsence', ex)
            return ex
        })
}

export const getLeaveAbsenceDetail = async (absenceId) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_LEAVE_ABSENCE_DETAIL}?absenceId=${absenceId}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLeaveAbsenceDetail :', err)
        return err
    }
}

export const getLutLeaveFlag = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LU_LEAVE_FLAG)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLutLeaveFlag :', err)
    }
}

export const getLutSyncSapResult = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LUT_SYNC_SAP_RESULT)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLutSyncSapResult :', err)
    }
}