import React, { useEffect, useContext } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';

import * as APILeaveSAP from '../../../../api/ApiLeaveSAP';
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';
import { validateLeaveSapSearch } from './LeaveSAPUtils';
import { LeaveSAPContext } from './LeaveSAPProvider'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'

import LeaveSAPSearchView from '../../presentational/leavesap/LeaveSAPSearchView';

const LeaveSAPSearch = props => {
    const {
        handleDataTable,
        handleSubmit,
        reset,
        initialize
    } = props;

    const {
        isResearch,
        searchLeaveSAPState,
        loadingState,
        alertMessageState,
        profile,
        setIsReSearch
    } = useContext(LeaveSAPContext)

    const {
        valuesSearch,
        lutResult,
        lutOrg,
        setValuesSearch,
        setInitLutResult,
        setInitLutOrg
    } = searchLeaveSAPState


    useEffect(() => {
        if (!valuesSearch) {
            firstStepLeaveSAP();
        } else {
            initialize(valuesSearch);
            checkResearch()
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const firstStepLeaveSAP = async () => {
        loadingState.showLoading();
        if (!lutResult.length) {
            await setInitLutResult()
            await setInitLutOrg()
        }

        initialize({
            responseTypeId: 'E'
        });
        handleSubmit(handleSearch)();
    };

    const checkResearch = () => {
        if (isResearch) {
            handleSearch(valuesSearch);
            setIsReSearch(false);
        }
    }

    const formatValuesToString = values => {
        let params = { ...values };

        if (values.startDate) {
            params.startDate = moment(values.startDate).format('YYYY-MM-DD') + ' 00:00:00'
        }

        if (values.endDate) {
            params.endDate = moment(values.endDate).format('YYYY-MM-DD') + ' 23:59:59'
        }

        if (values.startDateLeave) {
            params.startDateLeave = moment(values.startDateLeave).format('YYYY-MM-DD') + ' 00:00:00'
        }

        if (values.endDateLeave) {
            params.endDateLeave = moment(values.endDateLeave).format('YYYY-MM-DD') + ' 23:59:59'
        }

        const depId = profile.depId ? profile.depId : profile.orgId;

        let stringFormat = `permissionOrgId=${profile.permissionOrgId}&depId=${depId}`;
        for (const key in params) {
            if (params[key]) {
                stringFormat = `${stringFormat}&${key}=${params[key]}`;
            }
        }

        return stringFormat;
    };

    const handleSearch = async values => {
        console.log('value', values)
        loadingState.showLoading();
        setValuesSearch(values);
        const stringParams = formatValuesToString(values);

        const res = await APILeaveSAP.getLeaveSap(stringParams)
        loadingState.hideLoading();
        if (res && res.data && !res.error) {
            handleDataTable(res);
        } else {
            const msg = checkResStatusService(res)
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR
            });
        }
    };

    const handleReset = () => {
        reset('leaveSapSearch');
    };

    const handleChangeDate = field => value => {
        if (value) {
            const {
                startDate,
                endDate,
                startDateLeave,
                endDateLeave,
                change
            } = props;

            switch (field) {
                case 'startDate':
                    if (endDate && value > endDate) {
                        change('endDate', value);
                    }
                    break;
                case 'endDate':
                    if (startDate && value < startDate) {
                        change('startDate', value);
                    }
                    break;
                case 'startDateLeave':
                    if (endDateLeave && value > endDateLeave) {
                        change('endDateLeave', value);
                    }
                    break;
                case 'endDateLeave':
                    if (startDateLeave && value < startDateLeave) {
                        change('startDateLeave', value);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <LeaveSAPSearchView
            optionsResponse={lutResult}
            optionsOrg={lutOrg}
            onSearchClick={handleSubmit(handleSearch)}
            onResetClick={handleReset}
            onChangeDate={handleChangeDate}
        />
    );
};

const reduxFormParameter = {
    form: 'leaveSapSearch',
    validate: validateLeaveSapSearch
};

const selector = formValueSelector('leaveSapSearch');

const mapStateToProps = state => ({
    startDate: selector(state, 'startDate'),
    endDate: selector(state, 'endDate'),
    startDateLeave: selector(state, 'startDateLeave'),
    endDateLeave: selector(state, 'endDateLeave')
});

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps)
)(LeaveSAPSearch);
