import React from 'react'
import classnames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import withWidth from '@material-ui/core/withWidth';

import { checkWidthDown } from '../../../../utilities/Utils'
import { checkShowCancelAbsence } from '../../container/absence/AbsenceUtils'

import Button from '../../../../control/button/Button'

const a11yProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        width: '100%',
        height: '100%'
    },
    rootPaper: {
        width: '100%',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        paddingTop: '24px',
        paddingLeft: '24px',
        height: '100%',
        width: '245px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingLeft: '0px',
            paddingTop: '20px',
            borderBottom: `1px solid ${theme.palette.divider}`
        }
    },
    wrapper: {
        alignItems: 'flex-end',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    selected: {
        backgroundColor: '#F47B15',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'white',
            color: 'gray',
            borderBottom: '3px solid #F47B15',
        }
    },
    body: {
        width: '100%',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    btnCancel: {
        width: '90%',
        marginBottom: '24px',
        position: 'absolute',
        bottom: '0px',
        color: '#FFFFFF',
        marginLeft: '5%',
        backgroundColor: theme.mainTheme.color3,
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: theme.mainTheme.color3
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    positionBtn2: {
        bottom: '50px !important',
        [theme.breakpoints.down('sm')]: {
            bottom: '0px !important'
        }
    },
    rootTab: {
        maxWidth: '100%',
    },
    CanceledMsg: {
        color: theme.mainTheme.color3,
        marginBottom: '30px',
        position: 'absolute',
        bottom: '0px',
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            margin: '0px',
            position: 'absolute',
            right: '24px',
            marginBottom: '7px'
        }
    },
    flexContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    indicator: {
        display: 'none'
    },
    panel: {
        height: '100%',
        width: '245px',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '72px'
        }
    },
    children: {
        height: '100%',
        width: 'calc(100% - 245px)',
        backgroundColor: theme.palette.background.paper,
        padding: '24px 0px 24px 24px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'calc(100% - 72px)',
        }
    },
    tabCancelAbsence: {
        color: theme.mainTheme.color3,
        opacity: '1 !important'
    }
}))

const LeaveSAPDetailView = (props) => {
    const classes = useStyles()
    const {
        children,
        isOpenHistory,
        isCancel,
        width,
        permisCancel,

        onCancelClick,
        readyChange,
        callbackReady,
        loadingState,
        onChangeMenu,
        leaveStateId,
        leaveStateName
    } = props

    const [value, setValue] = React.useState(2);
    const isSmDowm = checkWidthDown(width, 'sm')

    if (readyChange) {
        loadingState.showLoading()
        setTimeout(() => {
            setValue(3)
            callbackReady(false)
            loadingState.hideLoading()
        }, 700)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (onChangeMenu) {
            onChangeMenu()
        }
    };

    const renderCancel = () => {
        let component = null

        if (isCancel) {
            if (isSmDowm) {
                component = <Tab
                    classes={{ wrapper: classes.wrapper, selected: classes.selected, root: classes.rootTab }}
                    className={classes.tabCancelAbsence}
                    disabled
                    label="ใบลาถูกยกเลิกแล้ว"
                    {...a11yProps(4)}
                />
            } else {
                component = <span className={classnames(classes.CanceledMsg)}>ใบลาถูกยกเลิกแล้ว</span>
            }
        } else if (!checkShowCancelAbsence(leaveStateId)) {
            component = <span className={classnames(classes.CanceledMsg)}>{leaveStateName}</span>
        } else if (permisCancel) {
            component = <Button
                label='ยกเลิกใบลา'
                className={classes.btnCancel}
                onClick={onCancelClick}
            />
        }

        return component
    }

    const renderHistory = () => {
        return isOpenHistory && <Tab
            classes={{ wrapper: classes.wrapper, selected: classes.selected, root: classes.rootTab }}
            label="ประวัติการนำเข้า SAP"
            {...a11yProps(3)}
        />
    }

    return (
        <Grid container className={classes.rootPaper}>
            <Grid item className={classes.panel} >
                <Tabs
                    orientation={isSmDowm ? 'horizontal' : 'vertical'}
                    variant="scrollable"
                    role="tabpanel"
                    scrollButtons={isSmDowm ? 'on' : 'off'}
                    indicatorColor="primary"
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                    classes={{ indicator: classes.indicator }}
                >
                    <Tab classes={{ wrapper: classes.wrapper, selected: classes.selected, root: classes.rootTab }} label="รายละเอียดใบลา" {...a11yProps(1)} />
                    <Tab classes={{ wrapper: classes.wrapper, selected: classes.selected, root: classes.rootTab }} label="ลำดับการพิจารณา/อนุญาต" {...a11yProps(2)} />
                    <Tab classes={{ wrapper: classes.wrapper, selected: classes.selected, root: classes.rootTab }} label="ผลการนำเข้า SAP ล่าสุด" {...a11yProps(0)} />
                    {renderHistory()}
                    {!isSmDowm && renderCancel()}
                </Tabs>
            </Grid>
            <Grid item className={classes.children}>
                {children[value]}
            </Grid>
        </Grid>
    )
}

export default withWidth()(LeaveSAPDetailView)