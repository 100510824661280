import React from 'react'

import TemplateProvider from './TemplateProvider'
import Template from './Template'
import UtilsPage from './UtilsPage'

const TemplateIndex = () => {
    return (
        <TemplateProvider>
            <Template />
            <UtilsPage />
        </TemplateProvider>
    )
}

export default TemplateIndex
