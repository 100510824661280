import React, { Fragment } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Field } from 'redux-form'
import classnames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Backdrop from '@material-ui/core/Backdrop';
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete';

import { UM_USER_GROUP, PERMISSION_ORG } from '../../../../constants/APDConstants'

import Autocomplete from '../../../../control/autocomplete/AutoComplete'
import Button from '../../../../control/button/Button'

const { LABEL } = UM_USER_GROUP.FORM_USER_GROUP

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '33px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        height: '95%',
        maxHeight: '720px',
        width: '60%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%'
        }
    },
    rootPersonal: {
        maxHeight: '630px'
    },
    children: {
        height: '100%'
    },
    containerEmp: {
        height: '227px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 7px 0px',
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    label: {
        color: '#F47B15'
    },
    deleteIcon: {
        fontSize: '22px'
    },
    list: {
        padding: '0px',
        fontSize: '20px'
    },
    footer: {
        marginTop: '10px',
        padding: 24,
        paddingTop: 0
    },
    primaryListText: {
        fontSize: '20px'
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    },
    paddingT: {
        padding: 24,
        paddingBottom: 0
    },
    paddingM: {
        paddingRight: 24,
        paddingLeft: 24,
    },
    paddingB: {
        padding: 24,
        paddingTop: 0
    }
}))

const ListEmp = ({ classes, emp, onClick }) => (
    <ListItem divider >
        <ListItemText
            classes={{ primary: classes.primaryListText }}
            primary={`${emp.name} (${emp.empId})`}
        />
        {!emp.notDelete &&
            <ListItemSecondaryAction>
                <IconButton edge="end"
                    onClick={() => onClick(emp)}
                >
                    <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
            </ListItemSecondaryAction>
        }
    </ListItem>
)

const FormAddUserView = ({
    listEmp = [],
    lutUser,
    listEmpInPermission,
    lutOrg = [],
    permissionOrgId,
    userGroupId,

    onChangeEmp,
    onDeleteClick,
    onCancelClick,
    onSubmitClick,
    onUserGroupChange,
    onChangeOrg
}) => {
    const classes = useStyles();
    const isPersonalOrg = permissionOrgId === PERMISSION_ORG.PERSONAL_ORG

    return (
        <Fragment>
            <Modal
                className={classes.modal}
                open={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Grid container className={classnames(classes.root, { [classes.rootPersonal]: isPersonalOrg })}>
                    <Grid item xs={12} className={classes.paddingT}>
                        <span className={classes.title}>จัดการผู้ใช้งาน</span>
                    </Grid>
                    <Grid item xs={12} style={{ height: 'calc(100% - 190px)' }}>
                        <Scrollbars universal={true}>
                            <Grid item xs={12} className={classes.paddingM}>
                                <Field
                                    name='userGroupId'
                                    label='กลุ่มผู้ใช้งาน'
                                    component={Autocomplete}
                                    options={lutUser.lutUserGroup}
                                    optionKey={{ label: "userGroupName", value: "userGroupId", abbr: null }}
                                    onChangeDropDown={onUserGroupChange}
                                    required={true}
                                />
                            </Grid>
                            {!isPersonalOrg && <Grid item xs={12} className={classes.paddingM}>
                                <Field
                                    name='org'
                                    label='หน่วยงาน'
                                    component={Autocomplete}
                                    options={lutOrg}
                                    optionKey={{ label: "name", value: "orgId", abbr: "nameSht" }}
                                    onChangeDropDown={onChangeOrg}
                                    disabled={(!userGroupId)}
                                />
                            </Grid>}
                            <Grid item xs={12} className={classes.paddingM}>
                                <Field
                                    id="search-emp"
                                    name='userId'
                                    isShowNoSelect={false}
                                    label={LABEL.SEARCH_EMP}
                                    component={Autocomplete}
                                    options={listEmpInPermission}
                                    optionKey={{ label: "empName", value: "empId", abbr: 'empId' }}
                                    onChangeDropDown={onChangeEmp}
                                    disabled={(!userGroupId)}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.paddingM}>
                                <span className={classes.label}>{LABEL.LIST_USER}</span>
                                <Grid item xs={12} className={classes.containerEmp}>
                                    <Scrollbars universal={true}>
                                        <List className={classes.list}>
                                            {listEmp.map((emp, index) => <ListEmp
                                                key={`listemp-${emp.empId}`}
                                                classes={classes}
                                                emp={emp}
                                                index={index}
                                                onClick={onDeleteClick}
                                            />)}
                                        </List>
                                    </Scrollbars>
                                </Grid>
                            </Grid>
                        </Scrollbars>
                    </Grid>
                    <Grid container justify="center" spacing={2} className={classes.footer}>
                        <Grid item>
                            <Button label='ยกเลิก' onClick={onCancelClick} className={classes.btnCancel} />
                        </Grid>
                        <Grid item>
                            <Button label='บันทึก' onClick={onSubmitClick} />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </Fragment>
    )
}

export default FormAddUserView