import React, { useContext } from 'react'
import ForceUpdateTableView from '../../../presentational/forceUpdate/ForceUpdateTableView'

import { ForceUpdateContext } from '../ForceUpdateProvider'


const ForceUpdateTable = () => {
    const { dataTableForceUpdateState, forceUpdaeState } = useContext(ForceUpdateContext)

    const onRowClick = (data) => {
        forceUpdaeState.setEditPage(true)
        forceUpdaeState.setEditData(data)
    }

    return (
        <ForceUpdateTableView
            dataTableState={dataTableForceUpdateState}
            onRowClick={onRowClick}
        />
    )
}

export default ForceUpdateTable