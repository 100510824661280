import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import configureStore from "./store/ConfigureStore";
import routeConfig from './config/appRoute/RouteConfig';

import Layout from './components/layout/Layout';
import Page from './Pages';
import PrivateRoute from './config/appRoute/PrivateRoute';
import PrivateRouteLogin from './config/appRoute/PrivateRouteLogin';
import Login from './components/login/Login';
import PromptRouter from './pages/PromptRouter'
import RouterProvider from './RouterProvider'
// import PublicMeetingIndex from './components/publicPage/publicMeeting/PublicMeetingIndex'

const _store = configureStore();

function Main() {
    return (
        <RouterProvider>
            <Layout>
                <PromptRouter />
                <Page />
            </Layout>
        </RouterProvider>
    );
}

function App() {
    return (
        <Router basename={process.env.REACT_APP_FOLDER_APPLICATION}>
            <Provider store={_store}>
                <Switch>
                    {/* <Route path={routeConfig.PUBLIC_MEETING} component={PublicMeetingIndex} /> */}
                    <PrivateRouteLogin path={routeConfig.LOGIN} component={Login} />
                    <PrivateRoute path="/" component={Main} />
                </Switch>
            </Provider>
        </Router>
    );
}

export default App;