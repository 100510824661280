import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import RouteConfig from '../config/appRoute/RouteConfig'
import { FUNCTIONS } from '../constants/APDConstants'

import MeetingPage from '../components/meeting/container/manageMeetingRoom/MeetingIndex'
import BookingPage from '../components/meeting/container/booking/BookingIndex'
import BookingByDatePage from '../components/meeting/container/bookingByDate/BookingByDateIndex'
import CancelBookingPage from '../components/meeting/container/cancelBooking/CancelBookingIndex'

function Meeting({ authenRdc }) {
    const router = []

    authenRdc.profile.functions.forEach(func => {
        switch (func.functionId) {
            case FUNCTIONS.MEETING_ROOM.id:
                router.push(<Route key={RouteConfig.MEETING_MANAGE} path={RouteConfig.MEETING_MANAGE} component={MeetingPage} />)
                break;
            case FUNCTIONS.BOOKING_ROOM.id:
                router.push(<Route key={RouteConfig.MEETING_BOOKING} path={RouteConfig.MEETING_BOOKING} component={BookingPage} />)
                break;
            case FUNCTIONS.DAILY_BOOKING.id:
                router.push(<Route exact key={RouteConfig.MEETING_DAILY_BOOKING} path={RouteConfig.MEETING_DAILY_BOOKING} component={BookingByDatePage} />)
                break;
            case FUNCTIONS.EXPORT_CANCELLED_BOOKING.id:
                router.push(<Route exact key={RouteConfig.MEETING_CANCELLED_BOOKING} path={RouteConfig.MEETING_CANCELLED_BOOKING} component={CancelBookingPage} />)
                break;
            default:
                break;
        }
    })

    return (
        <Switch>
            {router}
            <Route path={RouteConfig.MEETING} render={() => {
                const pathRedirect = router.length ? router[0].key : '/'
                return <Redirect to={pathRedirect} />
            }} />
        </Switch>
    );
}


const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default connect(mapStateToProps)(Meeting);
