import React from 'react'

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const useStylesTextFields = makeStyles(theme => ({
    gridTextFields: {
        marginBottom: '8px'
    },
    root: {
        [`& input`]: {
            height: theme.field.height,
            padding: theme.field.padding,
        },
        width: '100%',
        boxShadow: theme.field.boxShadow
    },
    rootError: {
        [`& fieldset`]: {
            borderColor: '#ff0505'
        },
        [`& input`]: {
            height: theme.field.height,
            padding: theme.field.padding,
        },
        width: '100%'
    },
    errorText: {
        color: theme.mainTheme.color5,
        marginTop: "1px",
        marginLeft: 10,
    }
}))

const TextFieldSearch = ({
    placeholder,
    required,
    onChange
}) => {
    const classes = useStylesTextFields();

    return (
        <Grid container>
            <Grid item xs={12} className={classes.gridTextFields}>
                <Grid item xs={12}>
                    <TextField
                        placeholder={placeholder}
                        variant="outlined"
                        className={classes.root}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TextFieldSearch
