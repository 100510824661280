import { fetchGet, fetchDel_, fetchPost } from '../utilities/ServiceUtils'
import * as ApiConstants from '../constants/ApiConstants'
import { API } from '../constants/ServiceMSGConstants'

export const getLeaveSap = async (params) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_LEAVE_SAP}?${params}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLeaveSap :', err)
        return err
    }
}

export const getLeaveSapDetail = async (absenceId) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_LEAVE_DETAIL_SAP}?absenceId=${absenceId}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLeaveSapDetail :', err)
        return err
    }
}

export const getLuLeaveSapType = async (leaveTypeId) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_LU_LEAVE_SAP_TYPE}?leaveTypeId=${leaveTypeId}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLuLeaveSapType :', err)
    }
}

export const cancelAbsence = async (params) => {
    try {
        const fetchData = await fetchDel_(`${ApiConstants.SERVICE_DELETE_LEAVE}`, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex cancelAbsence :', err)
        return err
    }
}

export const updateLeaveSapTransaction = async (params) => {
    try {
        const fetchData = await fetchPost(`${ApiConstants.SERVICE_UPDATE_LEAVE_SAP_TRANSACTION}`, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex updateLeaveSapTransaction :', err)
        return err
    }
}

export const getLeaveAbsence = async (absenceId) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_LEAVE_ABSENCE_DETAIL}?absenceId=${absenceId}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLeaveAbsence :', err)
    }
}

export const callServiceGetLutResult = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_LUT_LEAVE_SAP_RESULT}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceGetLutResult :', err)
    }
}

export const callServiceGetLutOrg = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LU_CUSTOM_ORG)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()

        return res
    }
    catch (err) {
        console.log('ex getLutCustomOrg :', err)
        return err
    }
}

export const callServiceGetLutSpecialLeave = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LUT_SPECIAL_LEAVE)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE }
        }
        const res = await fetchData.json()

        return res
    }
    catch (err) {
        console.log('ex getLutSpecialLeave :', err)
        return err
    }
}