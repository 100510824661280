import React, { Fragment } from 'react'
import { Field } from 'redux-form';

import { makeStyles, Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Subject from '../SubjectMeetingView'
import CheckboxWithText from '../../../../../control/checkbox/CheckboxWithText'
import CheckboxOne from '../../../../../control/checkbox/Checkbox'
import AutoComplete from '../../../../../control/autocomplete/AutoComplete'
import TextFields from '../../../../../control/textfield/TextFields'
// import TimePicker from '../../../../../control/datetime/TimePicker'
import TimePicker from '../../../../../control/fdatetime/TimeFlatePicker'
import ColorPicker from '../../../../../control/color/ColorPicker'

import { normalizeNumber } from '../../../../../utilities/Validate'
import { MEETING } from '../../../../../constants/APDConstants'

const { LABEL, NAME } = MEETING.FORM

const useStyles = makeStyles(theme => ({
    subject: {
        paddingTop: '26px !important',
        paddingBottom: '26px !important'
    },
    maintainerList: {
        minWidth: '48px',
        minHeight: 'auto',
        borderRadius: '24px',
        // backgroundColor: '#B8D3D7',
        padding: ' 0px 5px 0px 15px',
        margin: '0px 5px 5px 5px',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 3px 7px 0px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'defaul',
        border: '1px solid #df7923e6'
    },
    buttonFab: {
        width: '20px',
        height: '20px',
        padding: '0px',
    },
    extendedIcon: {
        width: '20px',
        height: '20px'
    },
    boxEquipment: {
        border: '1px solid #c4c4c4',
        paddingRight: '20px !important',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 7px 0px'
    },
    checkboxOneOrg: {
        position: 'relative',
        top: '30px',
        [theme.breakpoints.down('sm')]: {
            top: '0px'
        }
    },
    spacing: {
        ...theme.form.spacing
    }
}))

const MtRoomDescriptionView = ({
    lu = {},
    maintainer = [],
    callbackColor,
    colorInit,

    onChangeMaintainerForm,
    onClickRemoveMaintainer,
    onChangeTime
}) => {
    const classes = useStyles()

    return (
        <Fragment>
            <Grid container>
                {/* description */}
                <Grid container justify='center'>
                    <Grid item xs={10}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} className={classes.subject}>
                                <Subject label={MEETING.SUBJECT_DESCRIPTION} />
                            </Grid>
                            <Grid item xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.MEA_DIST_ID}
                                    label={LABEL.MEA_DIST_ID}
                                    component={AutoComplete}
                                    options={lu.meaDistrict}
                                    required
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.BD_NAME}
                                    component={TextFields}
                                    label={LABEL.BD_NAME}
                                    placeholder={LABEL.BD_NAME}
                                    maxLength={250}
                                    required
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.ROOM_TYPE_ID}
                                    component={AutoComplete}
                                    options={lu.roomType}
                                    label={LABEL.ROOM_TYPE_ID}
                                    required
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.ROOM_NAME_TH}
                                    component={TextFields}
                                    label={LABEL.ROOM_NAME_TH}
                                    placeholder={'ชื่อห้อง'}
                                    maxLength={250}
                                    required={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.ROOM_NAME_EN}
                                    component={TextFields}
                                    label={LABEL.ROOM_NAME_EN}
                                    placeholder={'room name'}
                                    maxLength={250}
                                    required={true}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.FLOOR}
                                    component={TextFields}
                                    label={LABEL.FLOOR}
                                    required={true}
                                    normalize={normalizeNumber}
                                    maxLength={2}
                                    placeholder={'2'}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.FLOOR_DISPLAY}
                                    component={TextFields}
                                    label={LABEL.FLOOR_DISPLAY}
                                    placeholder={'1A'}
                                    maxLength={4}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.SEAT}
                                    component={TextFields}
                                    label={LABEL.SEAT}
                                    normalize={normalizeNumber}
                                    required
                                    placeholder={'10'}
                                    maxLength={4}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.ADDITIONAL_SEAT}
                                    component={TextFields}
                                    label={LABEL.ADDITIONAL_SEAT}
                                    normalize={normalizeNumber}
                                    required
                                    placeholder={'2'}
                                    maxLength={4}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.ROOM_NO}
                                    component={TextFields}
                                    label={LABEL.ROOM_NO}
                                    maxLength={50}
                                    required={true}
                                    placeholder={4304}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.BOOKING_WEEKEND}
                                    label={LABEL.BOOKING_WEEKEND}
                                    component={AutoComplete}
                                    options={lu.bookingWeekend}
                                    required
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} className={classes.spacing}>
                                <Field
                                    id="open-time"
                                    name={NAME.OPEN_TIME}
                                    component={TimePicker}
                                    label={LABEL.OPEN_TIME}
                                    onChange={onChangeTime(NAME.OPEN_TIME)}
                                    required
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} className={classes.spacing}>
                                <Field
                                    id="close-time"
                                    name={NAME.CLOSE_TIME}
                                    component={TimePicker}
                                    label={LABEL.CLOSE_TIME}
                                    onChange={onChangeTime(NAME.CLOSE_TIME)}
                                    required
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.RESERVATION_DATE}
                                    component={TextFields}
                                    placeholder={'10'}
                                    label={LABEL.RESERVATION_DATE}
                                    required
                                    maxLength={3}
                                    normalize={normalizeNumber}
                                />
                            </Grid>
                            <Grid item md={5} sm={6} xs={12} className={classes.spacing} style={{ alignSelf: 'center' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <ColorPicker
                                            cbColor={colorInit}
                                            callbackColor={callbackColor}
                                        />
                                    </Grid>
                                    <Grid item xs={9} style={{ alignSelf: 'center' }}>
                                        <Field
                                            name={NAME.AVAILABLE}
                                            component={CheckboxOne}
                                            label={LABEL.AVAILABLE}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.DESCRIPTION}
                                    component={TextFields}
                                    label={LABEL.DESCRIPTION}
                                    maxLength={500}
                                    // row={5}
                                    // isMultiline={true}
                                    placeholder={LABEL.DESCRIPTION}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* equiption */}
                <Grid container justify='center' style={{ marginBottom: '25px' }}>
                    <Grid item xs={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.subject}>
                                <Subject label={MEETING.SUBJECT_EQUIPMENT} />
                            </Grid>

                            <Grid item xs={12} className={classes.boxEquipment}>
                                <Field
                                    nameFields={NAME.EQUIPMEMT}
                                    name={NAME.EQUIPMEMT}
                                    component={CheckboxWithText}
                                    options={lu.equipment}
                                    isDisplayRow={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* maintainer */}
                <Grid container justify='center'>
                    <Grid item xs={10}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} className={classes.subject}>
                                <Subject label={MEETING.SUBJECT_MAINTAINER} />
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.spacing}>
                                <Field
                                    name={NAME.ORG_ID}
                                    component={AutoComplete}
                                    options={lu.org}
                                    label={LABEL.ORG_ID}
                                    optionKey={{ label: "name", value: "orgId", abbr: "nameSht" }}
                                    required
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.spacing}>
                                <Field
                                    id="search-maintainer-emp"
                                    name={NAME.MAINTAINER_FORM}
                                    label={LABEL.MAINTAINER}
                                    component={AutoComplete}
                                    options={lu.lutMaintainer}
                                    optionKey={{ label: "empName", value: "empId", abbr: "empId" }}
                                    onChangeDropDown={onChangeMaintainerForm}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item md={12} xs={12} container direction={"row"} >
                                        {LABEL.MAINTAINER} :
                                        {maintainer.map((item, index) => {
                                            return (
                                                <Grid item key={`MAINTAINER-${item.name}-${index}`} className={classes.maintainerList}>
                                                    {item.empName}
                                                    {item.isDelete ? (
                                                        <Fragment>
                                                            <span style={{ color: '#c4c4c4', marginLeft: '2px' }}>{" |"}</span>
                                                            <IconButton aria-label="delete" className={classes.buttonFab}
                                                                onClick={() => onClickRemoveMaintainer(item)}
                                                            >
                                                                <CloseIcon
                                                                    className={classes.extendedIcon}
                                                                />
                                                            </IconButton>
                                                        </Fragment>) : (<label style={{ marginRight: '15px' }} />)}
                                                </Grid>)
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name={NAME.CONTACT}
                                    component={TextFields}
                                    label={LABEL.CONTACT}
                                    // row={5}
                                    // isMultiline={true}
                                    placeholder={LABEL.CONTACT}
                                    maxLength={500}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default MtRoomDescriptionView