import React, { useContext } from 'react';
import { isDirty } from 'redux-form';
import { connect } from 'react-redux';

import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';
import HeaderOrgLeaveView from '../../presentational/orgLeave/HeaderOrgLeaveView';
import { OrgLeaveContext } from './OrgLeaveProvider'

const HeaderOrgLeave = ({
    dirtyEditOrg,
    dirtyEditEmp
}) => {
    const {
        initailOrgId,
        orgState,
        lupOrgState,
        loadingState,
        alertMessageState,
        slinksState,
    } = useContext(OrgLeaveContext)

    const handleChangeOrg = async value => {
        if (value) {
            if (orgState.isEnableSave || dirtyEditOrg || dirtyEditEmp) { // เช็คว่ามีการเปลื่ยนแปลงไหม
                alertMessageState.showAlert({
                    altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                    altType: 2,
                    btnConfirmName: "ยกเลิก",
                    btnCancelName: "ไม่ยกเลิก",
                    callbackConfirm: () => {
                        alertMessageState.hideAlert();
                        getOrgData(value.orgId);
                    },
                })
            } else {
                getOrgData(value.orgId);
            }
        }
    }

    const getOrgData = async (orgId) => {
        loadingState.showLoading();
        slinksState.clearSlink();
        const resGetStructureOrg = await orgState.getStructureOrg(orgId);
        loadingState.hideLoading();
        if (resGetStructureOrg.msg) {
            alertMessageState.showAlert({ altMsg: resGetStructureOrg.msg })
        }
    }

    return (
        <HeaderOrgLeaveView
            initailOrgId={initailOrgId}
            options={lupOrgState.lutOrg}
            onChangeOrg={handleChangeOrg}
        />
    )
}

const mapStateToProps = state => ({
    dirtyEditOrg: isDirty('OrgLeaveEditOrg')(state),
    dirtyEditEmp: isDirty('OrgLeaveEditEmp')(state)
})

export default connect(mapStateToProps, null)(HeaderOrgLeave)
