import React from 'react'

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toggleRoot: {
        color: '#000000',
    },
    toggleSelected: {
        backgroundColor: `${theme.mainTheme.color1} !important`,
        color: '#FFFFFF !important',
    }
}));

const Toggle = (props) => {
    const classes = useStyles();
    const { input, options = [], onChange } = props


    const handleChange = (event, value) => {
        if (value) {
            input.onChange(value)

            if (onChange) {
                onChange(value)
            }
        }
    }

    return (
        <ToggleButtonGroup size="small" value={input.value} exclusive onChange={handleChange}>
            {options.map(option => (
                <ToggleButton value={option.value} classes={{
                    root: classes.toggleRoot,
                    selected: classes.toggleSelected,
                }}>
                    {option.label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}

export default Toggle
