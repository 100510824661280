import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const ButtonView = (props) => {

    const {
        classes,
        className,
        label,
        color,
        type,
        onClick,
        disabled,
        startIcon,
        endIcon
    } = props

    return (
        <Button
            variant="contained"
            type={type}
            className={`${className} ${classes.button}`}
            style={{ color: color }}
            onClick={onClick}
            color="primary"
            disabled={disabled}
            classes={{ root: classes.root, label: classes.label }}
        >
            {/* <span style={{alignItems: 'auto !important'}}> */}
                {startIcon && startIcon} {label} {endIcon && endIcon}
            {/* </span> */}
        </Button>
    )
}

export default withStyles(theme => ({
    root: {
        padding: '0px 16px',
        height: theme.field.height,

    },
    label: {
        height: '100%',
        // alignItems: 'auto !important'
        // marginTop:' 5px',
        // backgroundColor: 'red'
    },
    button: {
        // height: theme.field.height,
        backgroundColor: theme.mainTheme.color1,
        borderRadius: '10px',
        fontSize: theme.fontSize.body1,
        height: '40px'
    }
}))(ButtonView)