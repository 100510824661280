import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';

import DatePicker from '../../../../control/fdatetime/DateflatPicker'
import TextFields from '../../../../control/textfield/TextFields';
import AutoCompleteMultiSelectForm from '../../../../control/autocomplete/AutoCompleteMultiSelectForm'
import ToggleButtonGroup from '../../../../control/toggleButtonGroup/ToggleButtonGroup'

import * as BookingByDateConstants from '../../../../constants/meeting/BookingByDateConstants';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // padding: '24px',
        paddingBottom: 0
        // height: '120px'
    },
    container: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: '21px'
        }
    },
    spanSearch: {
        fontSize: '29px',
        marginRight: '10px'
    },
    grid: {
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '0px',
            width: '100% !important'
        }
    },
    gridButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '-30px',
            width: '100% !important'
        }
    },
    button: {
        height: '39px',
        padding: '0px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(33% - 10px)'
        }
    },
    buttonSelect: {
        backgroundColor: `${theme.mainTheme.color1} !important`,
        color: '#FFFFFF',
        height: '39px',
        padding: '0px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(33% - 10px)'
        }
    },
    startIcon: {
        marginRight: '0px',
        height: '20px'
    },
    homeIcon: {
        marginRight: '0px',
        height: '23px'
    },
    endIcon: {
        marginLeft: '0px',
        height: '20px'
    },
    gridFilter: {
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
            position: 'absolute',
            top: '25px',
            right: '24px'
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '10px'
        }
    },
    spacing: {
        ...theme.form.spacing
    },
    gridSearch: {
        marginTop: '-28px'
    },
    dateGrid: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    btnGrid: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '-25px',
            width: '100%'
        }
    },
    searchGrid: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '35px'
        }
    },
    buttonExportAll: {
        height: '40px',
        marginBottom: '5px',
        padding: '0px',
        marginLeft: '10px',
        paddingRight: '10px',
        paddingLeft: '10px',
        backgroundColor: '#016f01 !important',
        color: '#FFFFFF'
    }
}));

const BookingByDateSearchView = props => {
    const {
        select = true,
        minDate,
        maxDate,
        lutBuilding,
        lutMeaDistrict,

        onBtnClick,
        onDateChange,
        onDropdownChange,
        onTextChange,
        onChangeMeaDistrictDropdown,
        onChangeSource,
        onExportAll
    } = props;

    const classes = useStyles();

    return (
        <Fragment>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <span className={classes.spanSearch}>
                        {BookingByDateConstants.TITLE_SEARCH}
                    </span>

                    <Grid container>
                        <Grid item md={6} sm={12}>
                            <Grid container>
                                <Grid
                                    item
                                    md={4}
                                    sm={12}
                                    className={classes.dateGrid}
                                >
                                    <Field
                                        className={classes.date}
                                        component={DatePicker}
                                        name={BookingByDateConstants.DATE}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        onDateChange={onDateChange}
                                        notClear={true}
                                        id={BookingByDateConstants.DATE}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={8}
                                    sm={12}
                                    className={classes.btnGrid}
                                >
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        classes={{
                                            startIcon: classes.startIcon
                                        }}
                                        startIcon={<ArrowLeftIcon />}
                                        onClick={() =>
                                            onBtnClick(
                                                BookingByDateConstants.PREV
                                            )
                                        }
                                    >
                                        {BookingByDateConstants.PREV}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={
                                            select
                                                ? classes.buttonSelect
                                                : classes.button
                                        }
                                        classes={{
                                            startIcon: classes.homeIcon
                                        }}
                                        startIcon={<HomeIcon />}
                                        onClick={() =>
                                            onBtnClick(
                                                BookingByDateConstants.NOW
                                            )
                                        }
                                    >
                                        {BookingByDateConstants.NOW}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        classes={{ endIcon: classes.endIcon }}
                                        endIcon={<ArrowRightIcon />}
                                        onClick={() =>
                                            onBtnClick(
                                                BookingByDateConstants.NEXT
                                            )
                                        }
                                    >
                                        {BookingByDateConstants.NEXT}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12}
                            className={classes.searchGrid}
                        >
                            <Grid
                                container
                                justify="flex-end"
                                spacing={2}
                                className={classes.gridSearch}
                            >
                                <Grid item xs={4} className={classes.spacing}>
                                    <Field
                                        component={AutoCompleteMultiSelectForm}
                                        label={
                                            BookingByDateConstants.FILTER_FORM
                                                .LABEL.DISTRICT
                                        }
                                        options={lutMeaDistrict}

                                        name={
                                            BookingByDateConstants.FILTER_FORM
                                                .NAME.DISTRICT
                                        }
                                        placeholder={
                                            BookingByDateConstants.FILTER_FORM
                                                .LABEL.DISTRICT
                                        }
                                        onChangeDropDown={onChangeMeaDistrictDropdown}

                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.spacing}>
                                    <Field
                                        component={AutoCompleteMultiSelectForm}
                                        label={
                                            BookingByDateConstants.FILTER_FORM
                                                .LABEL.BUILDING
                                        }
                                        name={
                                            BookingByDateConstants.FILTER_FORM
                                                .NAME.BUILDING
                                        }
                                        options={lutBuilding}
                                        onChangeDropDown={onDropdownChange}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.spacing}>
                                    <Field
                                        component={TextFields}
                                        label={
                                            BookingByDateConstants.FILTER_FORM
                                                .LABEL.FLOOR
                                        }
                                        name={
                                            BookingByDateConstants.FILTER_FORM
                                                .NAME.FLOOR
                                        }
                                        placeholder={
                                            BookingByDateConstants.FILTER_FORM
                                                .LABEL.FLOOR
                                        }
                                        onTextChange={onTextChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify='flex-end' spacing={2} style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        className={classes.buttonExportAll}
                                        startIcon={<GetAppIcon />}
                                        onClick={onExportAll}
                                    >
                                        ออกรายงาน EXCEL ทั้งหมด
                                        </Button>
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={ToggleButtonGroup}
                                        name={BookingByDateConstants.FILTER_FORM.NAME.SOURCE}
                                        onChange={onChangeSource}
                                        options={BookingByDateConstants.OPTION_SOURCE}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment >
    );
};

export default BookingByDateSearchView;
