import React from 'react'
import { Field } from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { FORM_MAINTENANCE } from '../../../../../constants/maintenance/ConnextConstants'

import Button from '../../../../../control/button/Button'
import TextFields from '../../../../../control/textfield/TextFields'
// import DateTimePicker from '../../../../../control/datetime/DateTimePicker'
import DateTimePicker from '../../../../../control/fdatetime/DateTimeFlatPicker'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '24px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        }
    },
    title: {
        fontSize: '33px'
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    },
}))

const FormMaintenanceView = ({
    title,
    startDate,
    disabled,
    api,

    onSubmit,
    onCancel,
    onChangeDate
}) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12}>
                <span className={classes.title}>{title}{" "}{api}</span>
            </Grid>
            <Grid item xs={6}>
                <Field
                    id="start-date-maintenance"
                    name={FORM_MAINTENANCE.NAME.START_DATE}
                    label={FORM_MAINTENANCE.LABEL.START_DATE}
                    component={DateTimePicker}
                    onChange={onChangeDate(FORM_MAINTENANCE.NAME.START_DATE)}
                    disabled={disabled}
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    id="end-date-maintenance"
                    name={FORM_MAINTENANCE.NAME.END_DATE}
                    label={FORM_MAINTENANCE.LABEL.END_DATE}
                    component={DateTimePicker}
                    onChange={onChangeDate(FORM_MAINTENANCE.NAME.END_DATE)}
                    disabled={disabled}
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    id="display-date-maintenance"
                    name={FORM_MAINTENANCE.NAME.DISPLAY_DATE}
                    label={FORM_MAINTENANCE.LABEL.DISPLAY_DATE}
                    component={DateTimePicker}
                    maxDate={startDate}
                    onChange={onChangeDate(FORM_MAINTENANCE.NAME.DISPLAY_DATE)}
                    required
                />
            </Grid>
            <Grid item xs={12} style={{ height: '145px' }}>
                <Field
                    name={FORM_MAINTENANCE.NAME.NOTE}
                    label={FORM_MAINTENANCE.LABEL.NOTE}
                    component={TextFields}
                    isMultiline={true}
                    row={2}
                    placeholder={FORM_MAINTENANCE.PLACEHOLDER.NOTE}
                />
            </Grid>
            <Grid item xs={12} container justify="center" spacing={2}>
                <Grid item>
                    <Button label="ยกเลิก" className={classes.btnCancel} onClick={onCancel} />
                </Grid>
                <Grid item>
                    <Button label="บันทึก" onClick={onSubmit} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormMaintenanceView
