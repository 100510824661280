import React, { useContext, useEffect, Fragment } from 'react'
import { TemplateContext } from './TemplateProvider'
import ModalTemplate from './ModalTemplate'
import TemplateView from '../../presentational/template/TemplateView'

import { getAllTemplate } from '../../../../api/ApiTemplate'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'

const Template = () => {
    const { dataTableState, loadingState, alertMessageState, modalState } = useContext(TemplateContext)

    useEffect(() => {
        // console.log("templateState::", templateState)
        // initialTableData()
        getTemplate()
    }, [])

    // const [templateData, setTemplateData] = useState([])

    const getTemplate = () => {
        loadingState.showLoading();
        getAllTemplate().then(res => {
            loadingState.hideLoading();
            if (res && res.data) {
                res.data.forEach((item, idx) => {
                    item.no = idx + 1
                    item.edit = true
                });
                // setTemplateData(res.data);
                dataTableState.setInitialDataTable(res.data)
            } else {
                const msg = checkResStatusService(res)

                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR
                })
            }
        });
    };

    const handleRowClick = (data, type) => {
        modalState.setDataEdit(data)
    }
    return (
        <Fragment>
            <TemplateView dataTableState={dataTableState} onRowClick={handleRowClick} />
            <ModalTemplate callbackSubmit={getTemplate} />
        </Fragment>
    )
}

export default Template
