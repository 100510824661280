import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import RouteConfig from '../config/appRoute/RouteConfig'
import { FUNCTIONS } from '../constants/APDConstants'

import ConnextPage from '../components/maintenance/container/connext/ConnextIndex'
import TemplatePage from '../components/maintenance/container/template/TemplateIndex'
import ForceUpdatePage from '../components/maintenance/container/forceUpdate/ForceUpdateIndex'

function Maintenance({ authenRdc }) {
    const router = []

    authenRdc.profile.functions.forEach(func => {
        switch (func.functionId) {
            case FUNCTIONS.MAINTAINER_CONNEXT.id:
                router.push(<Route exact key={RouteConfig.MAINTENANCE_CONNEXT} path={RouteConfig.MAINTENANCE_CONNEXT} component={ConnextPage} />)
                break;
            case FUNCTIONS.MANAGE_TEMPLATES.id:
                router.push(<Route exact key={RouteConfig.MAINTENANCE_TEMPLATE} path={RouteConfig.MAINTENANCE_TEMPLATE} component={TemplatePage} />)
                break;
            case FUNCTIONS.FORCE_UPDATE.id:
                router.push(<Route exact key={RouteConfig.MAINTENANCE_FORCE_UPDATE} path={RouteConfig.MAINTENANCE_FORCE_UPDATE} component={ForceUpdatePage} />)
                break;
            default:
                break;
        }
    })

    return (
        <Switch>
            {router}
            <Route path={RouteConfig.MAINTENANCE} render={() => {
                const pathRedirect = router.length ? router[0].key : '/'
                return <Redirect to={pathRedirect} />
            }} />
        </Switch>
    );
}


const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default connect(mapStateToProps)(Maintenance);
