import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Main from './Main';
import Nav from './Nav';

import useDrawerState from './UseDrawerState';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%'
    }
}));

function Layout({ children }) {
    const classes = useStyles();
    const [open, handleDrawerOpen, handleDrawerClose] = useDrawerState();
    return (
        <div className={classes.root}>
            <Header
                open={false}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
            />
            <Nav open={open} handleDrawerClose={handleDrawerClose} />
            <Main open={false}>{children}</Main>
        </div>
    );
}

export default Layout;
