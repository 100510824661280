import React, { useState, useEffect, Fragment } from 'react';
import Scrollbar from 'react-custom-scrollbars';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import classnames from 'classnames';

import Pagination from '../pagination/Pagination';

//import functions
import { usePrevious } from '../StateFunctions';
import { withStyles } from '@material-ui/core/styles';

//import constants
import { CONTROL_TABLE, ABSENCE } from '../../constants/APDConstants';

import './css/animation.css';
import { Grid } from '@material-ui/core';

const styles = (theme) => ({
    tableFrame: {
        // height: '100%',
        width: '100%',
        textAlign: 'center',
    },
    table: {
        textAlign: 'center',
        width: '100%',
        minWidth: '1026px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.02) 0px -1px 0px',
    },
    theader: {
        display: 'flex',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        background: 'linear-gradient(180deg, #464646 0%, #464646 100%)',
        color: '#FFFFFF',
    },
    tableRow: {
        minHeight: '48px',
        display: 'flex',
        width: '100%',
        background: 'inherit',
        position: 'relative',
        cursor: 'pointer',
        zIndex: 2,
    },
    tableHeader: {
        cursor: 'pointer',
        zIndex: 2,
        marginTop: '1%',
        marginBottom: '1%',
    },
    tableHeaderTitle: {
        width: 'calc(100% -20px)',
    },
    tableSort: {
        float: 'right',
        marginTop: '4px',
    },
    rowFrameOdd: {
        background: '#EBEBEB',
        alignItems: 'center',
        display: 'flex',
    },
    rowFrame: {
        position: 'relative',
        width: '100%',
        background: '#E1E1E1',
        alignItems: 'center',
        display: 'flex',
    },
    tableTool: {
        position: 'absolute',
        opacity: 1,
        left: 0,
        zIndex: 0,
    },
    tableBTNEdit: {
        width: '80px',
        background:
            'linear-gradient(270deg, rgb(255, 123, 0) 0%, rgb(244, 123, 21) 100%)',
        display: 'inline-flex',
        color: '#FFFFFF',
        justifyContent: 'center',
        alignItems: 'center',
        border: '0px solid #5b5b5b',
        cursor: 'pointer',
        minHeight: '48px',
    },
    tableBTNDelete: {
        width: '80px',
        background:
            'linear-gradient(270deg, rgb(235, 8, 0) 0%, rgb(255, 0, 0) 100%)',
        display: 'inline-flex',
        color: '#FFFFFF',
        justifyContent: 'center',
        alignItems: 'center',
        border: '0px solid #5b5b5b',
        cursor: 'pointer',
        height: '100%',
        minHeight: '48px',
    },
    tableBTNDetail: {
        width: '100px',
        background:
            'linear-gradient(270deg, rgb(255, 123, 0) 0%, rgb(244, 123, 21) 100%)',
        display: 'inline-flex',
        color: '#FFFFFF',
        justifyContent: 'center',
        alignItems: 'center',
        border: '0px solid #5b5b5b',
        cursor: 'pointer',
        height: '100%',
        minHeight: '48px',
    },
    tableBTNIcon: {
        paddingRight: '5px',
    },
    tableCell: {
        // minHeight: '48px',
        height: '48px',
        display: 'inline-flex',
        padding: '10px',
        color: '#5B5B5B',
        fontSize: '18px !important',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        alignItems: 'center',
        background: 'inherit',
    },
    problemTableCell: {
        // minHeight: '48px',
        height: '48px',
        display: 'inline-flex',
        padding: '20px',
        color: 'red',
        fontSize: '18px !important',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        alignItems: 'center',
        background: 'inherit',
    },
    tableHeaderCell: {
        borderRight: '1px solid #FFFFFF',
    },
    hide: {
        display: 'none',
    },
    unChecked: {
        color: '#a9a9a9',
        ['& svg']: {
            width: '25px',
            height: '25px',
        },
    },
    checked: {
        color: `${theme.mainTheme.color3} !important`,
        ['& svg']: {
            width: '25px',
            height: '25px',
        },
    },
    checkbox: {
        color: `#FFFFFF !important`,
        ['& svg']: {
            width: '20px',
            height: '20px',
        },
    },
    formControl: {
        margin: '0px',
        ['& span']: {
            fontSize: '20px !important',
        },
    },
    disabledCheckbox: {
        color: 'grey !important',
        ['& svg']: {
            width: '25px',
            height: '25px',
        },
    },
});

const DataTable = (props) => {
    const {
        column,
        data,
        page,
        rowPerPage,
        total,
        handlePageChange,
        handleRowClick,
        noDataTableMsg = CONTROL_TABLE.NO_DATA_MSG_DEFAULT,
        sortColumn,
        sortType,
        handleSortExternal,
        classes,
        notScroll,
        isCheck,
        // onCheckboxChange,
        // dataCheck,
        classesTable,
        notSort,
        labelBtnDelete = 'ลบ',
        iconBtnDelete = (
            <img
                className={classes.tableBTNIcon}
                width="16px"
                src={`${process.env.PUBLIC_URL}/img/table/delete.png`}
                alt=""
            />
        ),
        hidePagination,
    } = props;

    //value in datatable
    // let countRow = 0
    let intViewportHeight = window.innerHeight;

    //state in datatable
    const [rowSlideId, setRowSlideId] = useState(null);

    //prevState in datatable
    const prevRowSlideId = usePrevious(rowSlideId);

    useEffect(() => {
        if (rowSlideId !== null && prevRowSlideId === rowSlideId) {
            setRowSlideId(null);
        }
    }, [rowSlideId, prevRowSlideId]);

    const slideRow = (id) => {
        if (rowSlideId === id) {
            setRowSlideId(null);
        } else {
            setRowSlideId(id);
        }
    };

    const handleSort = (colId) => {
        if (column[colId].sort) {
            if (sortType === CONTROL_TABLE.SORT.ASC) {
                handleSortExternal(
                    column[colId].data,
                    CONTROL_TABLE.SORT.DESC,
                    column[colId].type
                );
            } else {
                handleSortExternal(
                    column[colId].data,
                    CONTROL_TABLE.SORT.ASC,
                    column[colId].type
                );
            }
        }
    };

    const displaySort = (colId) => {
        let img = `${process.env.PUBLIC_URL}/img/table/sort_both.png`;

        if (column[colId].data === sortColumn) {
            if (sortType === CONTROL_TABLE.SORT.ASC) {
                img = `${process.env.PUBLIC_URL}/img/table/sort_asc.png`;
            } else {
                img = `${process.env.PUBLIC_URL}/img/table/sort_desc.png`;
            }
        }

        return img;
    };

    const getPosition = (ref) => {
        if (ref) {
            if (ref.offsetTop + ref.offsetHeight <= intViewportHeight - 397) {
                // countRow++;
            }
        }
    };

    const handleAnimationClass = (item) => {
        if (
            (item.edit && !item.delete && !item.detail) ||
            (!item.edit && item.delete && !item.detail)
        ) {
            return CONTROL_TABLE.SLIDE.SLIDE_IN;
        } else if (!item.edit && !item.delete && item.detail) {
            return CONTROL_TABLE.SLIDE.SLIDE_IN_DETAIL;
        } else if (!item.edit && item.delete && item.detail) {
            return CONTROL_TABLE.SLIDE.SLIDE_2_ROW;
        } else {
            if (item.edit && item.delete) {
                return `${CONTROL_TABLE.SLIDE.SLIDE_IN_DELETE}`;
            }
        }
    };
    const getTableCellClass = (citem, rowData) => {
        if (
            rowData.syncSapResultCode &&
            rowData.syncSapResultCode === 'suspend_overlap' &&
            citem &&
            citem.data === ABSENCE.TABLE_ABSENCE.DATA.ABSENCE_ID
        ) {
            return classes.problemTableCell;
        } else {
            return classes.tableCell;
        }
    };

    const createTable = () => {
        let columns, rows, pagination;

        if (data) {
            columns = column.map((item, i) => {
                return (
                    <Grid
                        container
                        className={classes.tableHeader}
                        onClick={() => {
                            !notSort && handleSort(i);
                        }}
                        style={{ width: item.width }}
                        key={item.data}
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            style={
                                column.length - 1 !== i
                                    ? { borderRight: '1px solid #FFFFFF' }
                                    : null
                            }
                        >
                            {isCheck && i === 0 ? (
                                'เลือก'
                            ) : (
                                <Fragment>
                                    <div style={{ width: '100%' }}>
                                        <span>{item.title}</span>
                                    </div>
                                    <div
                                        style={{
                                            height: '28px',
                                            marginTop: '-28px',
                                        }}
                                    >
                                        {!notSort && (
                                            <img
                                                src={displaySort(i)}
                                                className={classes.tableSort}
                                                alt=""
                                            />
                                        )}
                                    </div>
                                </Fragment>
                            )}
                        </Grid>
                    </Grid>
                );
            });

            if (data.length > 0) {
                rows = data.map((item, index) => {
                    return (
                        <div
                            className={
                                index % 2 === 0
                                    ? classes.rowFrameOdd
                                    : classes.rowFrame
                            }
                            key={'c' + index}
                            ref={(r) => {
                                getPosition(r);
                            }}
                        >
                            <div
                                className={classnames(classes.tableTool, {
                                    [classes.hide]: rowSlideId !== 'c' + index,
                                })}
                            >
                                {item.edit && (
                                    <div
                                        className={classes.tableBTNEdit}
                                        onClick={() =>
                                            handleRowClick(item, 'edit')
                                        }
                                    >
                                        <img
                                            className={classes.tableBTNIcon}
                                            width="16px"
                                            src={`${process.env.PUBLIC_URL}/img/table/pencil.png`}
                                            alt=""
                                        />
                                        แก้ไข
                                    </div>
                                )}
                                {item.detail && (
                                    <div
                                        className={classes.tableBTNDetail}
                                        onClick={() =>
                                            handleRowClick(item, 'detail')
                                        }
                                    >
                                        <img
                                            className={classes.tableBTNIcon}
                                            width="16px"
                                            src={`${process.env.PUBLIC_URL}/img/table/view.png`}
                                            alt=""
                                        />
                                        {item.history
                                            ? `ดูประวัติ`
                                            : `รายละเอียด`}
                                    </div>
                                )}
                                {item.delete && (
                                    <div
                                        className={classes.tableBTNDelete}
                                        onClick={() =>
                                            handleRowClick(item, 'delete')
                                        }
                                    >
                                        {iconBtnDelete}
                                        {labelBtnDelete}
                                    </div>
                                )}
                            </div>
                            <div
                                className={
                                    rowSlideId === 'c' + index
                                        ? index % 2 === 0
                                            ? 'table_row table_row_active ' +
                                              handleAnimationClass(item)
                                            : 'table_row table_row_active_odd ' +
                                              handleAnimationClass(item)
                                        : classes.tableRow
                                }
                                onClick={() => {
                                    // controlOnClick(item)
                                    if (
                                        item.edit ||
                                        item.delete ||
                                        (item.detail && !item.history)
                                    ) {
                                        slideRow('c' + index);
                                    }
                                }}
                            >
                                {column.map((citem, i) => {
                                    return (
                                        <div
                                            className={getTableCellClass(
                                                citem,
                                                item
                                            )}
                                            style={{
                                                width: citem.width,
                                                minHeight: '48px',
                                            }}
                                            key={
                                                'r' +
                                                citem.data +
                                                item[citem.data]
                                            }
                                            onClick={() => {
                                                if (
                                                    (item.history && i !== 3) ||
                                                    (item.history &&
                                                        item.isSlide)
                                                ) {
                                                    slideRow('c' + index);
                                                }
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    textAlign: citem.align,
                                                }}
                                            >
                                                {/* {item[citem.data] ? (
                                                    item[citem.data]
                                                ) : '-'} */}
                                                {item[citem.data] ||
                                                item[citem.data] === 0
                                                    ? item[citem.data]
                                                    : '-'}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                });

                if (!hidePagination) {
                    pagination = (
                        <Pagination
                            margin={1}
                            page={page}
                            count={Math.ceil(
                                Number(total) / parseInt(rowPerPage)
                            )}
                            onPageChange={handlePageChange}
                            total={total}
                            rowPerPage={rowPerPage}
                        />
                    );
                }
            } else {
                rows = (
                    <div className="table_row no_data">{noDataTableMsg}</div>
                );
            }
        }

        return (
            <Fragment>
                <div className={classes.tableFrame}>
                    <Scrollbar
                        autoHeight
                        autoHeightMin={10}
                        autoHeightMax={notScroll ? '100%' : window.innerHeight}
                        autoHide={false}
                        universal={true}
                    >
                        <div
                            className={
                                classesTable ? classesTable : classes.table
                            }
                        >
                            <div className={classes.theader}>{columns}</div>
                            {rows}
                        </div>
                    </Scrollbar>
                </div>
                {!notScroll && pagination}
            </Fragment>
        );
    };

    return <Fragment>{createTable()}</Fragment>;
};

export default withStyles(styles)(DataTable);
