import React from 'react';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ButtonDefault from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Button from '../button/Button';

import { NAME_OF_MONTH } from '../../utilities/DatetimeUtils';

const useStyle = makeStyles(theme => ({
    root: {
        padding: '20px',
        border: '1px solid #DDD',
        borderBottom: '0px'
    },
    btnSearch: {
        display: 'flex'
    },
    label: {
        color: theme.mainTheme.color1,
        fontSize: '35px',
        fontWeight: 300
    },
    button: {
        height: ' 40px',
        padding: '0px 10px',
        marginLeft: '5px',
        marginRight: '5px'
    },
    labelฺButton: {
        height: ' 40px'
    },
    marginButton: {
        marginRight: ' 30px'
    }
}));

const CustomToolBar = props => {
    const {
        isShowButtonNextPrev,
        toolbar,
        currentView,

        onChangeMonth,
        onClickSearchMeetingRoom,
        onClickAgenda,
        onClickMonth
    } = props;
    const classes = useStyle();

    const goToBack = () => {
        const date = toolbar.date.getMonth() - 1;
        toolbar.date.setMonth(date);
        toolbar.onNavigate('prev');
        onChangeMonth && onChangeMonth(toolbar.date);
    };

    const goToNext = () => {
        const date = toolbar.date.getMonth() + 1;
        toolbar.date.setMonth(date);
        toolbar.onNavigate('next');
        onChangeMonth && onChangeMonth(toolbar.date);

        ///////////////////////////////////////////////////////////////////

        // const date = toolbar.date.getMonth();
        // // toolbar.date.setMonth(date);
        // toolbar.date.setDate(toolbar.date.getDate() + 1);
        // toolbar.onNavigate('next');
        // onChangeMonth && onChangeMonth(toolbar.date);

        ///////////////////////////////////////////////////////////////////
    };

    const label = () => {
        const getMonth = toolbar.date.getMonth();
        const getFullYear = toolbar.date.getFullYear() + 543;
        const strMonth = NAME_OF_MONTH.filter(item => item.id === getMonth)[0].name;
        const strLabel = `${strMonth} ${getFullYear}`;

        return (
            <span>{strLabel}</span>
        );
    };

    const month = () => {
        if (onClickMonth) {
            onClickMonth();
        }
        toolbar.onView('month');
    };

    const agenda = () => {
        if (onClickAgenda) {
            onClickAgenda(
                props.toolbar.date.getMonth(),
                props.toolbar.date.getFullYear()
            );
        }
        toolbar.onView('agenda');
    };


    return (
        <Grid
            container
            className={classes.root}
            justify="space-between"
            alignItems="center"
        >
            <Grid item>
                <IconButton onClick={goToBack}>
                    {isShowButtonNextPrev && <ChevronLeftIcon />}
                </IconButton>
                <IconButton onClick={goToNext}>
                    {isShowButtonNextPrev && <ChevronRightIcon />}
                </IconButton>
            </Grid>
            <Grid item>
                <label className={classes.label}>{label()}</label>
            </Grid>
            <Grid item>
                <ButtonDefault
                    variant='outlined'
                    color={currentView === 'month' ? 'primary' : 'default'}
                    className={classes.button}
                    onClick={month}
                    classes={{
                        root: classes.button,
                        label: classes.labelฺButton
                    }}
                >
                    เดือน
                </ButtonDefault>
                <ButtonDefault
                    variant='outlined'
                    color={currentView === 'agenda' ? 'primary' : 'default'}
                    onClick={agenda}
                    className={classnames(classes.button, classes.marginButton)}
                >
                    รายการ
                </ButtonDefault>
                <Button
                    onClick={onClickSearchMeetingRoom}
                    label={
                        <div className={classes.btnSearch}>
                            <SearchIcon /> ค้นหาห้องประชุม
                        </div>
                    }
                />
            </Grid>
        </Grid>
    );
};

export default CustomToolBar;
