import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    children: {
        backgroundColor: theme.palette.background.paper,
    },
}))

const ModalToolbarView = ({
    children,
}) => {
    const classes = useStyles();

    return (
        <Modal
            open={children !== undefined}
            className={classes.modal}
            closeAfterTransition
            BackdropProps={{
                timeout: 500
            }}
        >
            <Grid container justify="center" alignItems="center">
                <Grid item xs={9} className={classes.children} >
                    {children}
                </Grid>
            </Grid>
        </Modal>
    )
}

export default ModalToolbarView