import { useState } from 'react'

const UseAbsenceState = () => {
    // const [dataEditAbsence, setdataEditAbsence] = useState({
    //     "absenceId": "137/2562",
    //     "empId": "2262205",
    //     "empName": "น.ส. ปณันศิญา ระวิระ",
    //     "orgId": "3944",
    //     "orgName": "งานพัฒนาระบบงานสนับสนุนองค์กร (ระดับ 10)",
    //     "jobId": "10000284",
    //     "jobName": "นักประมวลผลข้อมูล 6",
    //     "startDate": "2019-11-28",
    //     "endDate": "2019-11-28",
    //     "leavePeriodId": 4,
    //     "leavePeriodName": "ระบุช่วงเวลา",
    //     "totalHours": 3,
    //     "leaveReason": null,
    //     "leaveTypeId": 1,
    //     "leaveTypeName": "ลาป่วย",
    //     "sickReasonId": 1,
    //     "sickReasonName": "กระเพาะอาหารอักเสบ",
    //     "sickOther": null,
    //     "leaveStateId": 1,
    //     "leaveStateName": "รอพิจารณา",
    //     "leaveSapTransactionTypeId": null,
    //     "leaveSapTransactionTypeName": null,
    //     "ordinationHall": null,
    //     "ordinationAddress": null,
    //     "ordinationDate": null,
    //     "temple": null,
    //     "templeAddress": null,
    //     "wifeName": null,
    //     "childName": null,
    //     "childBirthDate": null,
    //     "startTime": "1970-01-01T17:30:00.000Z",
    //     "endTime": "1970-01-01T20:30:00.000Z",
    //     "refIdSapOutput": null,
    //     "leaveSapType": null,
    //     "remark": null,
    //     "leaveSapOutputId": null,
    //     "duration": null,
    //     "contactAddress": "บ้าน",
    //     "attachment": null
    // })
    const [dataEditAbsence, setdataEditAbsence] = useState()
    const [isOpenCancelForm, setOpenCancelForm] = useState(false)
    const [dataSpecialLeave, setDataSpecialLeave] = useState()

    return {
        isOpenCancelForm,
        dataEditAbsence,
        dataSpecialLeave,

        setOpenCancelForm,
        setdataEditAbsence,
        setDataSpecialLeave
    }
}

export default UseAbsenceState
