import React from 'react';
import { Field, FieldArray } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Subject from '../SubjectMeetingView';

import CheckboxTree from '../../../../../control/checkboxTree/CheckboxTree';
import RadioButton from '../../../../../control/radio/RadioButton';
import AutoComplete from '../../../../../control/autocomplete/AutoComplete';
// import DropdownSearch from '../../../../control/dropdown/DropdownSearch';
// import DropdownSmartSearch from '../../../../control/dropdown/DropdownSmartSearch';

import { MEETING } from '../../../../../constants/APDConstants';
const { NAME, LABEL } = MEETING.FORM

const UseStyle = makeStyles(theme => ({
    containerListEmp: {
        boxShadow: theme.field.boxShadow
    },
    listEmp: {
        width: '100%',
        // boxShadow: 'none',
        margin: '0px',
        padding: '0px',
        border: '1px solid #df7923e6',
        marginBottom: '3px',
        borderRadius: '5px',
        boxShadow: theme.field.boxShadow,
        height: theme.field.height
    },
    secondaryAction: {
        padding: '0px 15px',
        height: theme.field.height
    },
    subject: {
        paddingTop: '26px !important',
        paddingBottom: '26px !important'
    },
    root: {
        marginTop: 0,
        marginBottom: 0,
        '& span': {
            fontSize: theme.fontSize.body1
        }
    },
    spacing: {
        ...theme.form.spacing
    }
}))

const renderPermissionEmp = ({ fields, values, classes }) => {
    return fields.map((field, index) => (
        <List className={classes.listEmp} key={`permissionEmp-${index}`}>
            <ListItem classes={{ secondaryAction: classes.secondaryAction }} >
                <ListItemText
                    primary={values[index].empName}
                    classes={{ root: classes.root }}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => fields.remove(index)} >
                        <CloseIcon style={{ fontSize: '20px' }} />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    ))
}

const MtRoomConditionView = ({
    isShowPermissionForm,
    isAllBooking,
    lupOrgTree,
    lupEmp,
    lupPublicityType,
    lupBookingType,
    permissionEmp,
    onChangeEmp,
    getHalfChecked
}) => {
    const classes = UseStyle();

    return (
        <Grid container justify='center'>
            <Grid item xs={10}>
                <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.subject}>
                        <Subject label={MEETING.SUBJECT_CONDITION} />
                    </Grid>

                    <Grid xs={12} item>
                        <Field
                            name={NAME.PUBLICITY_TYPE_ID}
                            component={RadioButton}
                            options={lupPublicityType}
                            label={LABEL.PERMISSION_SEE}
                            xs={12}
                            md={4}
                            required
                        />
                    </Grid>

                    <Grid xs={12} item>
                        <Field
                            name={NAME.BOOKING_TYPE_ID}
                            component={RadioButton}
                            options={lupBookingType}
                            label={LABEL.BOOKING_TYPE_ID}
                            isAllBooking={isAllBooking}
                            xs={12}
                            md={4}
                            required
                        />
                    </Grid>

                    {isShowPermissionForm &&
                        <Grid container spacing={1}>
                            <Grid md={6} xs={12} item className={classes.spacing}>
                                <Grid xs={12} item style={{ marginTop: '4px' }}>
                                    <Field
                                        name={NAME.PERMISSION_ORG}
                                        label={LABEL.PERMISSION_ORG}
                                        component={CheckboxTree}
                                        options={lupOrgTree}
                                        keyData={{ label: "name", value: "orgId", abbr: "nameSht" }}
                                        isSearch={true}
                                        checkStrictly={true}
                                        getHalfChecked={getHalfChecked}
                                        required
                                    />
                                </Grid>
                            </Grid>

                            <Grid md={6} xs={12} item className={classes.spacing}>
                                <Grid xs={12} item>
                                    <Field
                                        id="search-emp"
                                        name={NAME.EMP}
                                        label={LABEL.PERMISSION_EMP}
                                        component={AutoComplete}
                                        options={lupEmp}
                                        optionKey={{ label: "empName", value: "empId", abbr: "empId" }}
                                        onChangeDropDown={onChangeEmp}
                                        isShowNoSelect={false}
                                        required
                                    />
                                </Grid>
                                <Grid xs={12} className={classes.containerListEmp} item>
                                    <FieldArray
                                        name={NAME.PERMISSION_EMP}
                                        component={renderPermissionEmp}
                                        values={permissionEmp}
                                        classes={classes}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>}
                </Grid>
            </Grid>
        </Grid >
    )
}

export default MtRoomConditionView