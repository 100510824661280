import { useState } from 'react'

import {
    callServiceGetLutResult,
    callServiceGetLutOrg,
    callServiceGetLutSpecialLeave
} from '../../../../../api/ApiLeaveSAP'

const UseSearchLeaveSAPState = () => {
    const [valuesSearch, setValuesSearch] = useState(null);
    const [lutResult, setLutResult] = useState([])
    const [lutSpecialLeave, setLutSpacialLeave] = useState()
    const [lutOrg, setLutOrg] = useState([])

    const setInitLutResult = async () => {
        const resLut = await callServiceGetLutResult()
        if (resLut && resLut.data) {
            setLutResult(resLut.data)
        }

        return resLut
    }

    const setInitLutOrg = async () => {
        const result = await callServiceGetLutOrg()
        if (result && result.data) {
            setLutOrg(result.data)
        }
    }

    const setInitLutSpecial = async (leaveTypeId) => {
        const result = await callServiceGetLutSpecialLeave(leaveTypeId)
        if (result && result.data && result.data.length > 0) {
            setLutSpacialLeave(result.data[0])
        }
    }

    return {
        valuesSearch,
        lutResult,
        lutOrg,
        lutSpecialLeave,

        setValuesSearch,
        setLutResult,
        setInitLutResult,
        setInitLutOrg,
        setInitLutSpecial
    }
}

export default UseSearchLeaveSAPState
