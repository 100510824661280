import { useState, useEffect } from 'react';

import { getCancelBooking } from '../../../../../api/ApiBooking';
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'
import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'

// import { formatResult } from '../DataManageBookingByDate';
const UseCancelBookingState = ({ loadingState, alertMessageState }) => {

    const [resultSearch, setResultSearch] = useState([]);
    const [valueSearch, setValueSearch] = useState({
        values: {},
        strParams: ''
    });
    useEffect(() => {
        // callServiceGetBuiding();
    }, []);

    // const callServiceGetBuiding = () => {
    //     loadingState.showLoading();
    //     getLutBooking().then(res => {
    //         if (res && res.data) {
    //             setLut({
    //                 ...lut,
    //                 lutBuilding: formatLut({
    //                     data: res.data,
    //                     keyId: 'building',
    //                     keyName: 'building'
    //                 })
    //             });
    //         }
    //     });
    //     loadingState.hideLoading();
    // };

    // const formatLut = ({ data, keyId, keyName }) => {
    //     const newFormat = data.map(item => {
    //         item.id = item[keyId];
    //         item.name = item[keyName];

    //         return item;
    //     });

    //     return newFormat;
    // };

    // const setResult = result => {
    //     const newResult = formatResult(result);
    //     setResultSearch(newResult);
    // };

    const searchCancelBooking = stringParams => {
        loadingState.showLoading();
        getCancelBooking(stringParams).then(res => {
            loadingState.hideLoading();
            let msg = checkResStatusService(res)
            if (res && res.data) {
                setResultSearch(res.data);
            } else {
                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR
                })
            }
        });
    };


    return {
        searchCancelBooking,
        valueSearch,
        // lut,
        resultSearch,
        setValueSearch
        // setResultSearch
    };
};

export default UseCancelBookingState;
