import React, { useEffect, useContext } from 'react'
import { reduxForm, formValueSelector, isDirty } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from "recompose"

import { ABSENCE } from '../../../../constants/APDConstants'
import { checkSuccessResService } from '../../../../utilities/Utils'
import { AbsenceContext } from './AbsenceProvider'
import { updateLeaveAbsence } from '../../../../api/ApiAbsence'
import { validateEditAbsence, checkStatusCancel, checkShowCancelAbsence } from './AbsenceUtils'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'

import EditAbsenceView from '../../presentational/absence/EditAbsenceView'

const { NAME } = ABSENCE.FORM_EDIT_ABSENCE

const EditAbsence = ({
    leavePeriodId,
    leaveTypeId,
    initialize,
    handleSubmit,
    callBackSave,
    dirtyEditAbsence,

    onClose
}) => {
    const {
        absenceState,
        empId,
        alertMessageState,
        loadingState,
        permissionSystem
    } = useContext(AbsenceContext)

    useEffect(() => {
        const initailData = absenceState.dataEditAbsence
        console.log('initailData', initailData)
        initialize({
            [NAME.LEAVE_TYPE_ID]: initailData.leaveTypeId && initailData.leaveTypeId,
            [NAME.LEAVE_PERIOD_ID]: initailData.leavePeriodId && initailData.leavePeriodId,
            [NAME.IN_ADVANCE]: initailData.inAdvance && initailData.inAdvance,

            // ลาป่วย
            [NAME.SICK_REASON_ID]: initailData.sickReasonId && initailData.sickReasonId,

            // ลาป่วย, ลากิจส่วนตัว, ลาพักผ่อนประจำปี, ลาเพื่อเลี้ยงดูบุตร, ลาไปช่วยเหลือภริยาที่คลอดบุตร, ลาอุปสมบท, ลาไปประกอบพิธีฮัจย์, สตรีลาไปถือศีลและปฎิบัติธรรม
            [NAME.LEAVE_REASON]: initailData.leaveReason && initailData.leaveReason,

            // ลาป่วย, ลากิจส่วนตัว, ลาพักผ่อนประจำปี, ลาคลอดบุตร, ลาเพื่อเลี้ยงดูบุตร, ลาไปช่วยเหลือภริยาที่คลอดบุตร, ลาอุปสมบท, ลาไปประกอบพิธีฮัจย์, สตรีลาไปถือศีลและปฎิบัติธรรม
            [NAME.CONTACT_ADDRESS]: initailData.contactAddress && initailData.contactAddress,

            // ลาป่วย, ลาพักผ่อนประจำปี, ลาคลอดบุตร, ลาไปช่วยเหลือภริยาที่คลอดบุตร, ลาอุปสมบท, ลาไปประกอบพิธีฮัจย์, สตรีลาไปถือศีลและปฎิบัติธรรม
            [NAME.ATTACHMENT]: initailData.attachments && initailData.attachments,
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const createBlob = (file) => {
        var blob = new Blob([file], { type: file.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url)
    }

    const haldleDownloadAttachment = async (fileData) => {
        if (fileData.attachment) {
            window.open(fileData.attachment)
        } else {
            createBlob(fileData.file)
        }
    }

    const classifyAttachment = attachments => {
        const listFileUpload = []
        const listAttachment = []

        attachments.forEach(at => {
            if (at.file) {
                listFileUpload.push(at)
            } else {
                listAttachment.push({ attachmentId: at.id })
            }
        })

        return { listFileUpload, listAttachment }
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
        })
    }

    const getAttachment = async attachments => {
        const { listFileUpload, listAttachment } = classifyAttachment(attachments)
        const listFileUploadBase64 = listFileUpload.map(async fileUpload => {
            const fileBase64 = await getBase64(fileUpload.file)
            const dataFile = fileBase64.split(',')[1]
            const fileData = {
                fileName: fileUpload.name,
                type: fileUpload.fileType,
                data: dataFile,
            }
            return fileData
        })
        const listAttachmentUpload = await Promise.all(listFileUploadBase64)
        return [...listAttachment, ...listAttachmentUpload]
    }

    const handleSubmitForm = async values => {
        loadingState.showLoading();
        const initailData = absenceState.dataEditAbsence
        const isAttachment = (ABSENCE.LEAVE_TYPE_R_ATTACHMENT.indexOf(values.leaveTypeId) !== -1)
        const attachments = isAttachment ? await getAttachment(values.attachment) : []
        const resUpdateLeave = await updateLeaveAbsence({
            absenceId: initailData.absenceId,
            leaveReason: values.leaveReason && values.leaveReason.trimEnd(),
            contactAddress: values.contactAddress && values.contactAddress.trimEnd(),
            isAttachment: isAttachment,
            attachments: attachments,
            updateBy: empId, // คนแก้ไข
            empId: initailData.empId // เข้าของใบลา
        })

        loadingState.hideLoading()
        if (resUpdateLeave && resUpdateLeave.data && checkSuccessResService(resUpdateLeave.data.data)) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.SAVE_SUCCESS,
                callbackCancel: () => {
                    alertMessageState.hideAlert()
                    onClose()
                    callBackSave()
                }
            })
        } else {
            const msg = checkResStatusService(resUpdateLeave)
            alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SAVE_FAIL })
        }
    }

    const handleClose = () => {
        if (dirtyEditAbsence) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: "ยกเลิก",
                btnCancelName: "ไม่ยกเลิก",
                callbackConfirm: () => {
                    alertMessageState.hideAlert()
                    onClose()
                },
            })
        } else {
            onClose()
        }
    }

    const handleCancelAbsence = () => {
        absenceState.setOpenCancelForm(true)
    }

    return (
        <EditAbsenceView
            initailData={absenceState.dataEditAbsence}
            info={absenceState.dataSpecialLeave}
            leavePeriodId={leavePeriodId}
            leaveTypeId={leaveTypeId}
            isCancel={checkStatusCancel(absenceState.dataEditAbsence.leaveStateId)}
            isShowCancel={permissionSystem.permisCancel && checkShowCancelAbsence(absenceState.dataEditAbsence.leaveStateId)}

            onClose={handleClose}
            onSubmit={handleSubmit(handleSubmitForm)}
            onClickFile={haldleDownloadAttachment}
            onCancelAbsence={handleCancelAbsence}
        />
    )
}

const reduxFormParameter = {
    form: 'formEditAbsence',
    validate: validateEditAbsence,
};

const selector = formValueSelector('formEditAbsence')

const mapStateToProps = state => ({
    leavePeriodId: selector(state, NAME.LEAVE_PERIOD_ID),
    leaveTypeId: selector(state, NAME.LEAVE_TYPE_ID),
    dirtyEditAbsence: isDirty('formEditAbsence')(state),
})

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps, null)
)(EditAbsence)
