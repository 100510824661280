import React, { useEffect, useState, useContext } from 'react';
import { reduxForm, isDirty, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ORG_LEAVE } from '../../../../../constants/APDConstants';
import { validateEditEmp } from '../OrgLeaveUtils';
import { OrgLeaveContext } from '../OrgLeaveProvider'

import EditEmpView from '../../../presentational/orgLeave/panel/EditEmpView';

const EditEmp = ({
    isHaveRepresentState,
    dirty,
    initialize,
    handleSubmit
}) => {
    const [permissionObj, setPermissionObj] = useState([])
    const [empApproveDisplay, setEmpApproveDisplay] = useState([])
    const {
        lupOrgState,
        panelState,
        orgState,
    } = useContext(OrgLeaveContext)
    const { id, name, position, orgId, permission, img, empIdApprRep, empApprove } = panelState.dataEditEmp;
    useEffect(() => {
        if (panelState.dataEditEmp) {
            managePermission()
            initialize({
                [ORG_LEAVE.FORM_EDIT_EMP.NAME.ID]: id,
                [ORG_LEAVE.FORM_EDIT_EMP.NAME.NAME]: name,
                [ORG_LEAVE.FORM_EDIT_EMP.NAME.POSITION]: position,
                [ORG_LEAVE.FORM_EDIT_EMP.NAME.ORG]: orgId,
                [ORG_LEAVE.FORM_EDIT_EMP.NAME.PERMISSION]: permission,
                [ORG_LEAVE.FORM_EDIT_EMP.NAME.REPRESENT]: empIdApprRep,
                [ORG_LEAVE.FORM_EDIT_EMP.NAME.IS_HAVE_REPRESENT]: empIdApprRep !== null && empIdApprRep !== undefined
            })

            const empApproveName = empApprove.map(empAppr => empAppr.empName)
            setEmpApproveDisplay([...new Set([...empApproveName])]) // filter duplicate data
        }
    }, [panelState.dataEditEmp]) // eslint-disable-line react-hooks/exhaustive-deps

    const managePermission = () => {
        const permissionFilter = permission.filter(item => item !== 0)
        let permissionObjFn = []

        if (permissionFilter.length > 0) {
            permissionFilter.forEach(item => {
                switch (item) {
                    case ORG_LEAVE.PERMISSION.AUDIT_ID: //audit
                        permissionObjFn.push({
                            img: <img alt="img" width="20" height="20" src={`${process.env.PUBLIC_URL}/img/leave/audit.svg`} />,
                            // name: lupOrgState.lupPermission.filter(itemLup => itemLup.id === ORG_LEAVE.PERMISSION.AUDIT_ID)[0].name
                            name: "ผู้พิจารณา"
                        })
                        break;
                    case ORG_LEAVE.PERMISSION.APPROVE_ID: //approve
                        permissionObjFn.push({
                            img: <img alt="img" width="20" height="20" src={`${process.env.PUBLIC_URL}/img/leave/approve.svg`} />,
                            // name: lupOrgState.lupPermission.filter(itemLup => itemLup.id === ORG_LEAVE.PERMISSION.APPROVE_ID)[0].name
                            name: "ผู้อนุญาต"
                        })
                        break;
                    case ORG_LEAVE.PERMISSION.REPRESENT_ID: //represent
                        permissionObjFn.push({
                            img: <img alt="img" width="20" height="20" src={`${process.env.PUBLIC_URL}/img/leave/represent.svg`} />,
                            // name: lupOrgState.lupPermission.filter(itemLup => itemLup.id === ORG_LEAVE.PERMISSION.REPRESENT_ID)[0].name
                            name: "ผู้ทำการแทน"
                        })
                        break;

                    default:
                        break;
                }
            })
        }

        setPermissionObj(permissionObjFn)
    }

    const handleFormSubmit = async (values) => {
        if (dirty) {
            let prevEmpIdApprRep = empIdApprRep;

            if (values.empIdApprRep === empIdApprRep && values.isHaveRepresent) { // กรณีไม่เปลื่ยนผู้ทำการแทนค่าจะเป็น null
                prevEmpIdApprRep = null;
            }

            await orgState.updateEmp({
                id: panelState.dataEditEmp.id,
                prevEmpIdApprRep: prevEmpIdApprRep,
                empIdApprRep: values.isHaveRepresent ? values.empIdApprRep : null, // กรณีไม่ติ๊กมีพนักงานทำการแทนจะคืนค่าเป็น null
                isChangeOrg: values.orgId !== orgId,
                ...values,
            })
        }
        handleCloseEditEmp();
    }

    const handleCloseEditEmp = () => {
        window.chart.draw();
        panelState.setPanel("DETAIL_ORG", panelState.dataDetailOrg)
    }

    return (
        <EditEmpView
            img={img}
            lupPermission={lupOrgState.lupPermission}
            lupEmp={orgState.lutRepresent}
            lupOrg={orgState.structureOrg}
            isDisableOrg={permission.indexOf(ORG_LEAVE.PERMISSION.APPROVE_ID) !== -1 || permission.indexOf(ORG_LEAVE.PERMISSION.AUDIT_ID) !== -1}
            isShowRepresent={permission.indexOf(ORG_LEAVE.PERMISSION.APPROVE_ID) !== -1}
            isHaveRepresent={isHaveRepresentState === ORG_LEAVE.PERMISSION.IS_HAVE_REPRESENT}
            empApprove={empApproveDisplay}
            permission={permissionObj}
            handleSubmit={handleSubmit}
            onSubmit={handleFormSubmit}
        />
    )
}

const selector = formValueSelector('OrgLeaveEditEmp')

const reduxFormParameter = {
    form: 'OrgLeaveEditEmp',
    validate: validateEditEmp,
};

const mapStateToProps = state => ({
    dirty: isDirty('OrgLeaveEditEmp')(state),
    isHaveRepresentState: selector(state, ORG_LEAVE.FORM_EDIT_EMP.NAME.IS_HAVE_REPRESENT),
})

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps, null)
)(EditEmp)

