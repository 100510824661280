import React, { useContext, Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'

import { AbsenceContext } from '../../container/absence/AbsenceProvider'

import PanelModalAbsence from '../../container/absence/PanelModalAbsence'
import CancelLeave from '../../container/absence/CancelLeave'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    root: {
        height: 'calc(100% - 150px)',
        width: '80%',
        [theme.breakpoints.up('lg')]: {
            width: '70%',
        }
    },
    panel: {
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        borderRight: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down('sm')]: {
            height: '72px',
            borderBottom: `1px solid ${theme.palette.divider}`,
        }
    },
    children: {
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100% - 72px)',
        }
    },
}))

const ModalAbsebceView = ({
    children,
    reFetchData
}) => {
    const classes = useStyles();
    const { panelState, absenceState } = useContext(AbsenceContext)

    return (
        <Fragment>
            <Modal
                open={children !== undefined}
                className={classes.modal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Grid container justify="center" alignItems="center" className={classes.root}>
                    <Grid item xs={12} sm={12} md={3} className={classes.panel} >
                        <PanelModalAbsence />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} className={classes.children}>
                        {children && children[panelState.panelActive]}
                    </Grid>
                </Grid>
            </Modal>
            {absenceState.isOpenCancelForm && <CancelLeave reFetchData={reFetchData} />}
        </Fragment>
    )
}

export default ModalAbsebceView