import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { TABLE_CONNEXT_MAINTENANCE } from '../../../../constants/maintenance/ConnextConstants';

import DataTable from '../../../../control/table/DataTable';
import Grid from '@material-ui/core/Grid'
import Button from '../../../../control/button/Button'

const { TABLE, DATA } = TABLE_CONNEXT_MAINTENANCE

const column = [
    { title: TABLE.NO, data: DATA.NO, width: '10%', sort: 'asc', align: 'center', type: 'number' },
    { title: TABLE.API_NAME, data: DATA.API_NAME, width: '10%', sort: 'asc', align: 'center' },
    { title: TABLE.DISPLAY_DATE, data: DATA.DISPLAY_DATE, width: '15%', sort: 'asc', align: 'center', type: 'date' },
    { title: TABLE.START_DATE, data: DATA.START_DATE, width: '15%', sort: 'asc', align: 'center', type: 'date' },
    { title: TABLE.END_DATE, data: DATA.END_DATE, width: '15%', sort: 'asc', align: 'center', type: 'date' },
    { title: TABLE.NOTE, data: DATA.NOTE, width: '35%', sort: 'asc', align: 'left' }
]

const useStyles = makeStyles(theme => ({
    tableCell: {
        minHeight: '48px',
        height: 'auto',
        display: 'inline-flex',
        padding: '10px',
        color: '#5B5B5B',
        fontSize: '18px !important',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        alignItems: 'center',
        background: 'inherit'
    },
    btn: {
        width: '225px',
        marginBottom: '24px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}))

const TableConnextView = ({
    dataTableState,
    onRowClick,
    onClickAddMaintenance
}) => {
    const classes = useStyles()

    return (
        <>
            <Grid item xs={12} container justify="flex-end" >
                {/* <Grid item> */}
                <Button label="สร้างรายการแจ้งเตือนปิดระบบ" onClick={onClickAddMaintenance} className={classes.btn} />
                {/* </Grid> */}
            </Grid>
            <DataTable
                column={column}
                data={dataTableState.dataPage}
                page={dataTableState.page}
                rowPerPage={dataTableState.rowPerPage}
                total={dataTableState.total}
                sortColumn={dataTableState.sortColumn}
                sortType={dataTableState.sortType}
                classes={{ tableCell: classes.tableCell }}

                handlePageChange={dataTableState.changePage}
                handleRowClick={onRowClick}
                handleSortExternal={dataTableState.sortData}
                handleRowPerPage={() => { }}
            />
        </>
    )
}

export default TableConnextView 
