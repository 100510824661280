import { FORM } from '../../../../constants/maintenance/TemplateConstants'
// import { ERRORS } from '../../../../constants/APDConstants'

export const validateEditTemplate = values => {
    const errors = {}

    // if (!values[FORM.NAME.MESSAGE_TH]) {
    //     errors[FORM.NAME.MESSAGE_TH] = ERRORS.REQUIRED
    // }

    // if (!values[FORM.NAME.MESSAGE_EN]) {
    //     errors[FORM.NAME.MESSAGE_EN] = ERRORS.REQUIRED
    // }

    if (!values[FORM.NAME.MESSAGE_TH] || !values[FORM.NAME.MESSAGE_EN]) {
        errors[FORM.NAME.ERROR] = 'กรุณาระบุข้อมูลให้ครบถ้วน'
    }

    return errors
}