import React from 'react'

import { Scrollbars } from 'react-custom-scrollbars'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import DataTable from '../../../../control/table/DataTable';
import * as TemplateConstants from '../../../../constants/maintenance/TemplateConstants'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    body: {
        padding: '24px'
    },
    rootPaper: {
        ...theme.paperPadding
    },
    tableCell: {
        minHeight: '48px',
        height: 'auto',
        display: 'inline-flex',
        padding: '10px',
        color: '#5B5B5B',
        fontSize: '18px !important',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        alignItems: 'center',
        background: 'inherit'
    },
}))

const TableConnextView = ({
    dataTableState,
    onRowClick
}) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.root}>
            <Scrollbars universal={true}>
                <Grid item xs={12} className={classes.body}>
                    <Paper className={classes.rootPaper}>
                        <Grid item xs={12}>
                            <DataTable
                                column={TemplateConstants.COLUMN_TEMPLATE}
                                data={dataTableState.dataPage}
                                page={dataTableState.page}
                                rowPerPage={dataTableState.rowPerPage}
                                total={dataTableState.total}
                                sortColumn={dataTableState.sortColumn}
                                sortType={dataTableState.sortType}
                                classes={{ tableCell: classes.tableCell }}
                                handlePageChange={dataTableState.changePage}
                                handleRowClick={onRowClick}
                                handleSortExternal={dataTableState.sortData}
                                handleRowPerPage={() => { }}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Scrollbars>
        </Grid>
    )
}

export default TableConnextView 
