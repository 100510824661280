import React, { Fragment, useEffect, useState } from 'react'
import FormHoliday from '../FormHoliday'

import { callServiceGetLutHolidayInfo, putHoliday } from '../../../../../api/ApiHoliday'
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'

import AlertMessage from '../../../../../control/alert/AlertMessage'
import UseAlertMessageState from '../../../../../control/alert/UseAlertMessageState'
import Loading from '../../../../../control/loading/Loading'
import UseLoadingState from '../../../../../control/loading/UseLoadingState'

import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'
import * as HolidayConstants from '../../../../../constants/leave/HolidayConstants'
import { SV_RES_SUCCESS } from '../../../../../constants/APDConstants'

const EditHoliday = (props) => {

    const {
        closeFormHoliday,
        callServiceGet,
        initialEdit,
        logAbsence,
        dateDup,
        empId,
        yearState,
        callServiceGetHolidayYear
    } = props

    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();

    const [initail, setInitail] = useState()
    const [isEdit, setIsEdit] = useState()
    const [isFail, setIsFail] = useState(false)
    const [dateDupFilter, setDateDupFilter] = useState([])

    useEffect(() => {
        loadingState.showLoading()
        callServiceGetLutHolidayInfo().then(res => {
            loadingState.hideLoading()
            if (res && res.data) {
                const newInitail = {
                    date: new Date(initialEdit.date),
                    info: res.data.filter(item => item.id === initialEdit.holidayInfoId)[0],
                    enabled: initialEdit.enabled ? 1 : 2,
                    description: initialEdit.description
                }

                setIsEdit(res.data)
                setInitail(newInitail)
            } else {
                setIsEdit([])
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleCloseSave = (countAbsence, params, holidayId) => {
        setTimeout(() => {
            closeFormHoliday()

            if (yearState !== Number(params.date.slice(0, 4)) + 543) {
                callServiceGetHolidayYear()
            } else {
                callServiceGet()
            }

            if (countAbsence > 0) {
                logAbsence(params.date,
                    holidayId,
                    params.enabled ? HolidayConstants.STR_ADD : HolidayConstants.STR_CANCEL
                )
            }
        }, 600)
    }

    const handleCancel = () => {
        alertMessageState.hideAlert()
        setIsFail(true)
    }

    const lutUpdate = () => {
        setIsFail(false)
    }

    const confirmEditHoliday = (holidayInfoId, values) => {
        alertMessageState.showAlert({
            altMsg: 'คุณต้องการยืนยันการแก้ไข ?',
            altType: 2,
            callbackCancel: () => alertMessageState.hideAlert(),
            callbackConfirm: () => {
                saveHoliday(holidayInfoId, values)
            }
        })
    }

    const saveHoliday = (holidayInfoId, values) => {
        loadingState.showLoading()
        const year = values.date.getFullYear()
        const month = values.date.getMonth() + 1
        const day = values.date.getDate()

        const strMonth = month.toString().length > 1 ? `${month}` : `0${month}`
        const strDate = day.toString().length > 1 ? `${day}` : `0${day}`

        const params = {
            date: `${year}-${strMonth}-${strDate}`,
            holidayInfoId: holidayInfoId,
            enabled: values.enabled.toString() === 'true',
            empId: empId,
            description: values.description ? values.description : null
        }

        putHoliday(params, initialEdit.holidayId).then(res => {
            loadingState.hideLoading()

            if (res && res.data && res.data[0].message.toLowerCase() === SV_RES_SUCCESS) {
                alertMessageState.showAlert({
                    altMsg: AlertMSGConstants.SAVE_SUCCESS,
                    altPs: res.data[0].countAbsence > 0 && `พบใบลาที่ได้รับผลกระทบ ${res.data[0].countAbsence} รายการ `,
                    callbackCancel: () => { handleCloseSave(res.data[0].countAbsence, params, initialEdit.holidayId) }
                })
            } else {
                let errorMsg = checkResStatusService(res)
                if (!errorMsg) {
                    errorMsg = res ? res.errors ? res.errors[0].detail : AlertMSGConstants.SAVE_FAIL : AlertMSGConstants.SAVE_FAIL
                }
                alertMessageState.showAlert({ altMsg: errorMsg, callbackCancel: () => { handleCancel() } })
            }
        })
    }

    useEffect(() => {
        if (initail) {
            const str = new Date(`${initail.date}`)

            const filter = dateDup.filter(item => item.toString() !== str.toString())
            setDateDupFilter(filter)
        }
    }, [initail])

    return (
        <Fragment>
            {isEdit &&
                <FormHoliday
                    initialValues={initail}
                    isEdit={isEdit}
                    closeFormHoliday={closeFormHoliday}
                    callServiceGet={callServiceGet}
                    isEditValue={initail}
                    saveHoliday={confirmEditHoliday}
                    dateDup={dateDupFilter}
                    lutUpdate={lutUpdate}
                    isFail={isFail}
                    editDate={initialEdit.date}
                />
            }
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    )
}

export default EditHoliday