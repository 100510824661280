import React, { Fragment, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import { MEETING, SUB_FUNCTION } from '../../../../constants/APDConstants'
import { PermissionSubFuncUtils } from '../../../../utilities/PermissionUtils';
import RouteConfig from '../../../../config/appRoute/RouteConfig'

import MeetingPage from './Meeting'
import AddMeetingRoom from './add/AddMeetingRoom'
import EditMeetingRoom from './edit/EditMeetingRoom';

const MeetingIndex = (props) => {
    const {
        authenRdc
    } = props
    const [txtSearch, setTxtSearch] = useState()
    const permission = {
        isAddMeetingRoom: PermissionSubFuncUtils(SUB_FUNCTION.ADD_MEETING_ROOM)
    }

    const renderContainer = (meetingPage) => () => {
        switch (meetingPage) {
            case MEETING.PAGE.ADD:
                return <AddMeetingRoom />
            case MEETING.PAGE.EDIT:
                return <EditMeetingRoom />
            default:
                return <MeetingPage
                    txtSearch={txtSearch}
                    setTxtSearch={setTxtSearch}
                    authenUUID={authenRdc.profile.uuid}
                />
        }
    }

    const renderRoute = () => {
        return (
            <Switch>
                {permission.isAddMeetingRoom && <Route
                    exact
                    key={RouteConfig.MEETING_MANAGE_ADD}
                    path={RouteConfig.MEETING_MANAGE_ADD}
                    render={renderContainer(MEETING.PAGE.ADD)}
                />}
                <Route
                    exact
                    key={RouteConfig.MEETING_MANAGE_EDIT}
                    path={RouteConfig.MEETING_MANAGE_EDIT}
                    render={renderContainer(MEETING.PAGE.EDIT)}
                />
                <Route
                    exact
                    key={RouteConfig.MEETING_MANAGE}
                    path={RouteConfig.MEETING_MANAGE}
                    render={renderContainer()}
                />
                <Redirect
                    to={RouteConfig.MEETING_MANAGE}
                />
            </Switch>
        )
    }
    return (
        <Fragment>
            {/* {authenRdc.profile.uuid &&
                renderContainer()
            } */}
            {renderRoute()}
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    authenRdc: state.authen
})

export default connect(mapStateToProps, null)(MeetingIndex)