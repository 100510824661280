import { useState } from 'react'

const UseForceUpdateState = () => {
    const [editPage, setEditPage] = useState(false)
    const [editData, setEditData] = useState(null)

    return {
        editPage,
        editData,

        setEditPage,
        setEditData
    }
}

export default UseForceUpdateState