import React from 'react';

import Calendar from '../../../../../control/calendar/Calendar';

const CalendarListView = ({
    events,
    currentDateObj,
    onChangeMonth,
    onClickSearchMeetingRoom,
    agendaEventData
}) => {
    return (
        <Calendar
            events={events}
            onChangeMonth={onChangeMonth}
            onClickSearchMeetingRoom={onClickSearchMeetingRoom}
            currentDateObj={currentDateObj}
            agendaEventData={agendaEventData}
        />
    );
};

export default CalendarListView;
