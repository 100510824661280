import React, { Fragment, useEffect, useContext } from 'react'
import { isDirty } from "redux-form";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { RouterContext } from '../../../../RouterProvider'
import { OrgLeaveContext } from './OrgLeaveProvider'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'

let dirtyEditOrgGlobal = false
let dirtyEditEmpGlobal = false
let isEnableSaveGlobal = false

// เอาไว้สำหรับ confirm ก่อนเปลื่ยนฟังก์ชันโดยการกด hanburger 
const Temp = ({
    history,
    dirtyEditEmp,
    dirtyEditOrg
}) => {
    const { setCallbackRouteChange } = useContext(RouterContext)
    const { alertMessageState, orgState } = useContext(OrgLeaveContext)

    useEffect(() => {
        setCallbackRouteChange(handleChangeRoutePath)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isEnableSaveGlobal = orgState.isEnableSave
    }, [orgState.isEnableSave]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dirtyEditEmpGlobal = dirtyEditEmp
    }, [dirtyEditEmp]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dirtyEditOrgGlobal = dirtyEditOrg
    }, [dirtyEditOrg]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeRoutePath = location => {
        if (isEnableSaveGlobal || dirtyEditOrgGlobal || dirtyEditEmpGlobal) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: "ยกเลิก",
                btnCancelName: "ไม่ยกเลิก",
                callbackConfirm: async () => {
                    await setCallbackRouteChange()
                    alertMessageState.hideAlert()
                    history.push(location.pathname)
                }
            })
            return false
        } else {
            return true
        }
    }

    return <Fragment />
}


const mapStateToProps = state => ({
    dirtyEditEmp: isDirty('OrgLeaveEditEmp')(state),
    dirtyEditOrg: isDirty('OrgLeaveEditOrg')(state)
})

export default connect(mapStateToProps)(withRouter(Temp))
