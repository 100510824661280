import React, { Fragment } from 'react'
import { Field } from 'redux-form';

import { makeStyles } from '@material-ui/core'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid'
import { useSpring, animated } from 'react-spring';

import Button from '../../../../control/button/Button'
import Autocomplete from '../../../../control/autocomplete/AutoComplete'

import * as HolidayConstants from '../../../../constants/leave/HolidayConstants'

const { FORM } = HolidayConstants

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(5, 6, 5),
        width: '500px',
        [theme.breakpoints.down('xs')]: {
            padding: '20px',
            width: '100%',
        }
    },
    title: {
        fontSize: '30px',
        textAlign: 'center',
        marginBottom: '21px'
    },
    btn: {
        paddingTop: '20px',
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    },
    spanAbsence: {
        color: theme.mainTheme.color5,
        fontSize: theme.fontSize.body2
    },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const ExportHolidayView = (props) => {

    const classes = useStyles()
    const { open = true, lutHoliday, date, countLogAbsence, onCloseFormClick, onSubmitClick, onChangeDate } = props

    return (
        <Fragment>
            <Modal
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 700,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.title}>
                            ออกรายงานใบลาที่มีผลกระทบ
                        </div>
                        <div>
                            {/* <Field
                                id={FORM.NAME.HOLIDAY_DAY}
                                name={FORM.NAME.HOLIDAY_DAY}
                                component={DatePicker}
                                label={FORM.LABEL.HOLIDAY_DAY}
                                required
                            /> */}
                            <Field
                                component={Autocomplete}
                                name={FORM.NAME.HOLIDAY_DAY}
                                label={FORM.LABEL.HOLIDAY_DAY}
                                options={lutHoliday}
                                onChangeDropDown={onChangeDate}
                                optionKey={{
                                    label: 'dateDisplay',
                                    value: 'date',
                                }}
                                required
                            />
                        </div>
                        {date && <span className={classes.spanAbsence} >
                            {`พบใบลาที่ได้รับผลกระทบ ทั้งหมด ${countLogAbsence} ใบ `}
                        </span>}

                        <Grid container spacing={2} justify="center" className={classes.btn}>
                            <Grid item>
                                <Button
                                    label='ยกเลิก'
                                    onClick={onCloseFormClick}
                                    className={classes.btnCancel}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    label='ออกรายงาน'
                                    onClick={onSubmitClick}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </Fragment>
    )
}

export default ExportHolidayView
