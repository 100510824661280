import React, { Fragment } from 'react'

import HeaderOrgLeave from '../../container/orgLeave/HeaderOrgLeave';
import Panel from '../../container/orgLeave/panel/Panel';
import Toolbar from '../../container/orgLeave/toolbar/Toolbar';
import SearchToolbar from '../../container/orgLeave/toolbar/SearchToolbar'

const ChildrenOrgLeaveView = ({
    isPersonalOrg,
    onSave,
    onRefresh
}) => {
    return (
        <Fragment>
            {!isPersonalOrg && <HeaderOrgLeave />}
            <Panel />
            <Toolbar onSave={onSave} onRefresh={onRefresh} />
            <SearchToolbar />
        </Fragment>
    )
}

export default ChildrenOrgLeaveView