import React, { useState, Fragment, useEffect } from 'react'
import { reduxForm } from 'redux-form'

import { validateFormUserGroup, makeDataSaveUserGroup } from './UsergroupUtils'
import { UM_USER_GROUP } from '../../../../constants/APDConstants'

import FormUserGroupView from '../../presentational/usergroup/FormUserGroupView'
import FormUser from './FormUser'

const { NAME } = UM_USER_GROUP.FORM_USER_GROUP

const FormUserGroup = ({
    lutUserGroupState,
    initialForm,
    profile,
    userGroupId,

    change,
    onCancel,
    handleSubmit,
    callBackSubmit,
    initialize
}) => {
    const [isOpenUserForm, setOpenUserForm] = useState(false)

    useEffect(() => {
        if (initialForm) {
            initialize(initialForm)
        }
    }, [initialForm]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpenUserForm = () => {
        setOpenUserForm(true)
    }

    const handleCloseUserForm = () => {
        setOpenUserForm(false)
    }

    const callBackCheckFunction = (checkedKeys, halfCheckedKeys) => {
        const checks = [...checkedKeys, ...halfCheckedKeys]
        change([NAME.FUNCTION], checks)
    }

    const handleSubmitForm = values => {
        callBackSubmit(makeDataSaveUserGroup(values))
    }

    return (
        <Fragment>
            <FormUserGroupView
                lutPermissionOrg={lutUserGroupState.lutPermissionOrg}
                lutFunction={lutUserGroupState.lutFunction}
                callBackCheckFunction={callBackCheckFunction}

                onOpenUserForm={handleOpenUserForm}
                onSubmit={handleSubmit(handleSubmitForm)}
                onCancel={onCancel}
            />
            <FormUser
                isOpen={isOpenUserForm}
                lutUserGroupState={lutUserGroupState}
                change={change}
                onClose={handleCloseUserForm}
                profile={profile}
                userGroupId={userGroupId}
            />
        </Fragment>
    )
}

const reduxFormParam = {
    form: 'formUserGroup',
    validate: validateFormUserGroup
}

export default reduxForm(reduxFormParam)(FormUserGroup)
