import React, { useEffect, Fragment, useContext } from 'react'
import { withRouter } from 'react-router';

import { LeaveSAPContext } from './LeaveSAPProvider'
import RouteConfig from '../../../../config/appRoute/RouteConfig'

import LeaveSAPDetail from './LeaveSAPDetail'

const LeaveSAPEditPage = ({
    history
}) => {
    const { profile, permissionSystem, setIsReSearch, loadingState, searchLeaveSAPState } = useContext(LeaveSAPContext)
    const data = history.location.state && history.location.state.data && history.location.state.data

    useEffect(() => {
        if (!data) {
            history.push(RouteConfig.LEAVE_SAP)
        } else {
            if (!searchLeaveSAPState.lutResult.length) {
                setInitail()
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const setInitail = async () => {
        loadingState.showLoading()
        await searchLeaveSAPState.setInitLutResult()
        loadingState.hideLoading()
    }

    const handleReSearch = async () => {
        await setIsReSearch(true)
        history.push(RouteConfig.LEAVE_SAP)
    }

    const handleCloseForm = () => {
        history.push(RouteConfig.LEAVE_SAP)
    }

    return data ? <LeaveSAPDetail
        onCancelClick={handleCloseForm}
        onReSearch={handleReSearch}
        data={data}
        profile={profile}
        permisCancel={permissionSystem.permisCancel}
    /> : <Fragment />
}


export default withRouter(LeaveSAPEditPage)
