import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import Grid from '@material-ui/core/Grid';

import Autocomplete from '../../../../control/autocomplete/AutoComplete'


const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '98px',
        position: 'fixed',
        top: '-10px',
        left: '100px',
        right: '100px',
        zIndex: theme.zIndex.appBar + 1,
        marginLeft: '0px',
        justifyContent: 'center',
        alignContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '250px',
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: 0,
            position: 'absolute',
            zIndex: 2,
            justifyContent: 'unset',
            top: 0,
            left: 0,
            right: 0,
            alignContent: 'unset',
            padding: '24px'
        }
    },
    form: {
        height: '40px',
        minWidth: '200px',
        maxWidth: '350px',
        width: '100%',
        left: '100px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'unset',
            minWidth: 'unset'
        }
    },
    ddlOrg: {
        minWidth: '200px',
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))

const HeaderOrgLeaveView = ({
    initailOrgId,
    options,
    onChangeOrg,
}) => {
    const classes = useStyle();

    return (
        <Grid className={classes.root} container justify="center" alignItems="center">
            <Grid item xs={12} sm={4} className={classes.ddlOrg} container >
                <Form
                    onSubmit={() => { }}
                    initialValues={{ org: initailOrgId }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <Field
                                isHideLabel={true}
                                name="org"
                                component={Autocomplete}
                                options={options}
                                onChangeDropDown={onChangeOrg}
                                optionKey={{ label: "name", value: "orgId", abbr: "nameSht" }}
                                required
                            />
                        </form>
                    )}
                />
            </Grid>
        </Grid>
    )
}

export default HeaderOrgLeaveView
