// import * as focusAction from '../../actions/FocusAction'

let ipRf = [];
let nameInput = [];
let i = 0

const Focus = () => {

    const focusFn = (isTrue, focusName, inputName) => {
        let success = false
        if (ipRf != null) {
            if(isTrue) {
                if(focusName === inputName) {
                    const index = nameInput.indexOf(focusName)
                    ipRf[index].focus()

                    success = true
                }
            }
        }

        return success
    }
    
    const manageDataFocus = (input) => {
        if(input) {
            const same = nameInput.filter(item => item === input.getAttribute('name')).length < 1
            if(same) {
                ipRf[i] = input
                nameInput[i] = input.getAttribute('name')
                i++
            }
        }
    }

    return { focusFn, manageDataFocus }
}

export default Focus

