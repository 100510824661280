import Excel from 'exceljs';
import { getCurrentDate } from '../../../../utilities/DatetimeUtils';

export default (datasource) => {
    const startRowHeader = 1; //ใช้กำหนดแถวที่เป็นหัวเรื่อง
    const startRowHeaderTable = 3; //ใช้กำหนดแถวที่จะเป็นหัวตาราง
    const workbook = new Excel.Workbook(); //สร้าง instance
    const worksheet = workbook.addWorksheet('sheet', {
        //จำเป็นต้องตั้งค่าส่วนนี้
        pageSetup: {
            paperSize: 9, //follow documentation the 9 value is A4
            orientation: 'landscape', //แนวตั้ง
        },
    });
    worksheet.name = 'รายงานการยกเลิกการจองห้องประชุม';
    workbook.creator = 'APD-WEB'; //ผู้สร้าง
    workbook.lastModifiedBy = 'APD-WEB'; //ผู้แก้ไขล่าสุด

    const headerTable = [
        {
            key: 'dateBookingDisplay',
            name: 'ช่วงเวลาที่จองห้องประชุม',
            width: 40,
        },
        { key: 'topic', name: 'หัวข้อการประชุม', width: 40 },
        { key: 'roomName', name: 'ชื่อห้องประชุม', width: 30 },
        { key: 'floor', name: 'ชั้น', width: 10 },
        { key: 'building', name: 'อาคาร', width: 20 },
        { key: 'district', name: 'เขต', width: 30 },
        { key: 'chairman', name: 'ประธาน', width: 20 },
        { key: 'numberOfAttendees', name: 'ผู้เข้าร่วม', width: 10 },
        { key: 'empName', name: 'ผู้จองห้องประชุม', width: 30 },
        { key: 'empOrgName', name: 'หน่วยงาน', width: 40 },
        { key: 'tel', name: 'เบอร์โทร', width: 15 },
        { key: 'reason', name: 'เหตุผลการยกเลิก', width: 20 },
        { key: 'modifyDateExport', name: 'วันที่ยกเลิก', width: 30 },
        { key: 'modifyEmp', name: 'ผู้ยกเลิก', width: 30 },
    ];

    const downloadProcess = () => {
        workbook.xlsx.writeBuffer().then(function (xls64data) {
            const dateNow = new Date();
            const date = dateNow.getDate();
            const strDate = date > 9 ? date : `0${date}`;
            const month = dateNow.getMonth() + 1;
            const strMonth = month > 9 ? month : `0${month}`;
            const year = dateNow.getFullYear() + 543;
            const time = dateNow.toTimeString().slice(0, 8);
            const strTime = time.replace(/:/g, '');

            const blob = new Blob([xls64data], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');

            document.body.appendChild(anchor); //for firefox ref: https://stackoverflow.com/questions/41805095/excel-export-not-working-on-firefox-but-working-fine-in-google-crome/41806544
            anchor.href = url;
            anchor.download = `รายงานการยกเลิกการจองห้องประชุม_${strDate}${strMonth}${year}_${strTime}.xlsx`;
            var isIE = /*@cc_on!@*/ false || !!document.documentMode;
            var isEdge = !isIE && !!window.StyleMedia;
            if (isEdge) {
                anchor.click();
                anchor.change();
            } else {
                anchor.click();
            }

            window.URL.revokeObjectURL(url); //เมื่อใช้ URL.createObjectURL แล้วต้องเรียกคำสั่งนี้เพื่อให้ Browser รู้ว่าจะไม่เก็บ Reference file อีกต่อไป
        });
    };

    const setCellBorder = (row, colKey) => {
        row.getCell(colKey).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        };
    };

    const generateHeader = () => {
        const columns = headerTable.length;

        worksheet.mergeCells(startRowHeader, 1, startRowHeader, columns);
        const row = worksheet.getRow(startRowHeader);
        row.getCell(columns).value = `รายงานการยกเลิกการจองห้องประชุม`;
        row.getCell(columns).alignment = {
            vertical: 'middle',
            horizontal: 'center',
        };
    };

    const generateColumns = () => {
        worksheet.columns = headerTable.map((item) => {
            return {
                key: item.key,
                width: item.width,
            };
        });
    };

    const generateTableHeader = () => {
        generateColumns();
        const startRowTableHeader = startRowHeaderTable;

        const row = worksheet.getRow(startRowTableHeader);
        headerTable.forEach((item) => {
            row.getCell(item.key).value = item.name;
            row.getCell(item.key).alignment = {
                vertical: 'middle',
                horizontal: 'center',
            };
            row.getCell(item.key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '004A4A4A',
                },
            };
            row.getCell(item.key).font = {
                color: { argb: '00FFFFFF' },
            };
            setCellBorder(row, item.key);
        });
    };

    const generateTableContent = () => {
        datasource.forEach((item, index) => {
            item.no = index + 1;
            worksheet.addRow(item);

            //set style
            const lastRowNumber = worksheet.lastRow._number;
            const row = worksheet.getRow(lastRowNumber);
            worksheet.lastRow._cells.forEach((cell) => {
                setCellBorder(row, cell.col);
                if (cell.col === 4 || cell.col === 8) {
                    row.getCell(cell.col).alignment = {
                        wrapText: true,
                        vertical: 'top',
                        horizontal: 'center',
                    };
                } else {
                    row.getCell(cell.col).alignment = {
                        wrapText: true,
                        vertical: 'top',
                        horizontal: 'left',
                    };
                }
                if (item.no % 2 !== 0) {
                    row.getCell(cell.col).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: '00DCDCDC',
                        },
                    };
                }
            });
        });
    };

    const generateReportDate = () => {
        const columns = headerTable.length;
        const startRow = startRowHeader + 1;

        worksheet.mergeCells(startRow, 1, startRow, columns);
        const row = worksheet.getRow(startRow);
        row.getCell(columns).value = `วันที่ออกรายงาน ${getCurrentDate()}`;
        row.getCell(columns).alignment = {
            vertical: 'middle',
            horizontal: 'center',
        };
    };

    const generateTable = () => {
        generateTableHeader();
        generateTableContent();
    };

    const generateContentProcess = () => {
        generateHeader();
        generateReportDate();
        generateTable();
    };

    const mainProcess = () => {
        generateContentProcess();
        downloadProcess();
    };

    mainProcess();
};
