import React, { useContext, useState, useEffect } from 'react'

import { OrgLeaveContext } from '../OrgLeaveProvider'
import Fuse from 'fuse.js'

import SearchToolbarView from '../../../presentational/orgLeave/toolbar/SearchToolbarView'

let timeOutBlur = null
let timeOutHover = null

const SearchToolbar = () => {
    const { orgState } = useContext(OrgLeaveContext)
    const [items, setItems] = useState([])
    const [nodeHighlight, setNodeHighlight] = useState()
    const [isFocus, setFocus] = useState(false)

    const options = { threshold: 0.2, keys: ['id', 'name'] }
    const fuseEmp = new Fuse(orgState.emp, options)
    const fuseOrg = new Fuse(orgState.structureOrg, options)

    useEffect(() => {
        if (orgState.orgID) {
            setItems([])
            setNodeHighlight()
            document.getElementById('search-orgLeave').value = ''
        }
    }, [orgState.orgID])

    const handleChangeSearch = e => {
        let empResult = [], orgResult = []
        const value = e.target.value

        if (value.length >= 3) {
            let empSearch = fuseEmp.search(e.target.value)
            let orgSearch = fuseOrg.search(e.target.value)
            const empSearchLength = empSearch.length, orgSearchLength = orgSearch.length

            if (empSearchLength >= 4 && orgSearchLength >= 4) {
                empSearch = empSearch.slice(0, 4)
                orgSearch = orgSearch.slice(0, 4)
            } else if (empSearchLength >= 4 && orgSearchLength < 4) {
                const scrap = 4 - orgSearchLength
                empSearch = empSearch.slice(0, 4 + scrap)
            } else if (orgSearchLength >= 4 && empSearchLength < 4) {
                const scrap = 4 - empSearchLength
                orgSearch = orgSearch.slice(0, 4 + scrap)
            }

            empResult = empSearch.map(emp => ({ id: emp.id, label: emp.name, value: emp.orgId }))
            orgResult = orgSearch.map(org => ({ id: org.id, label: org.name, value: org.id }))
        }

        setItems([...orgResult, ...empResult])

        if (!value && nodeHighlight) {
            window.chart.removeNodeTag(nodeHighlight, 'highlight');
            setNodeHighlight()
        }
    }

    const highlightNode = nodeId => {
        if (nodeHighlight) {
            window.chart.removeNodeTag(nodeHighlight, 'highlight');
        }
        if (nodeId) {
            window.chart.addNodeTag(nodeId, 'highlight');
        }
        setNodeHighlight(nodeId)
    }

    const handleClickItem = async value => {
        highlightNode(value)
        value && window.chart.center(value)
        const elSearch = document.getElementById('search-orgLeave')
        elSearch && elSearch.blur();
    }

    const handleFocus = () => {
        clearTimeout(timeOutBlur)
        setFocus(true)
    }

    const handleBlur = () => {
        timeOutBlur = setTimeout(() => {
            window.chart.draw()
            setFocus(false)
        }, 250)
    }

    const handleMouseOverIcon = () => {
        clearTimeout(timeOutHover)
        timeOutHover = setTimeout(() => {
            document.getElementById('container-search').style.display = 'block';
            document.getElementById('search-orgLeave').focus()
        }, 100)
    }

    return (
        <SearchToolbarView
            isFocus={isFocus}
            items={items}
            onChangeSearch={handleChangeSearch}
            onClilckItem={handleClickItem}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseOverIcon={handleMouseOverIcon}
        />
    )
}

export default SearchToolbar
