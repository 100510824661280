import React, { Fragment } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../../../../../control/button/Button';

import { BOOKING_LIST_INDEX_PAGE } from '../../../../../constants/meeting/BookingConstants';
import TodayIcon from '@material-ui/icons/Today';
import TableIcon from '@material-ui/icons/TableChart';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '24px',
        height: '100%'
    },
    button: {
        textAlign: 'right',
        margin: theme.spacing(1) / 2
    },
    buttonSwith: {
        textAlign: 'right',
        margin: theme.spacing(1) / 2,
        marginTop: '0px',
        paddingTop: '2px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    btnSwitch: {
        backgroundColor: '#1890ff !important',
        width: "203px",
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

const BookingListIndexView = ({ children, onSwitchClick, statePage }) => {
    const classes = useStyles();

    return (
        <Grid container style={{ height: '100%' }}>
            <Scrollbars universal={true} autoHide>
                <Grid container justify="center" id="buttonPage" style={{ height: '100%' }}>
                    <Grid item xs={12} className={classes.root}>
                        <Grid container spacing={1} justify={"flex-end"} style={{ height: '100%' }}>
                            <Grid item className={classes.buttonSwith}>
                                <Button
                                    label={
                                        statePage
                                            ? BOOKING_LIST_INDEX_PAGE.BUTTON_SWITCH_LABEL_TABLE
                                            : BOOKING_LIST_INDEX_PAGE.BUTTON_SWITCH_LABEL_CALENDAR
                                    }
                                    onClick={onSwitchClick}
                                    className={classes.btnSwitch}
                                    startIcon={(statePage) ? <TableIcon fontSize="small" style={{ paddingRight: 5 }} /> : <TodayIcon fontSize="small" style={{ paddingRight: 5 }} />}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ height: 'calc(100% - 50px)' }}>
                                {children}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Scrollbars>
        </Grid>
    );
};

export default BookingListIndexView;
