import { useState } from 'react';

/*
    const loadingState = UseLoadingState();

    loadingState.showLoading();
    loadingState.hideLoading();
    loadingState.isShowLoading
*/

const UseLoadingState = (init = false) => {
    const [isShowLoading, setShowLoading] = useState(init);

    const showLoading = () => {
        setShowLoading(true);
    };

    const hideLoading = () => {
        setShowLoading(false);
    };

    return { isShowLoading, showLoading, hideLoading };
};

export default UseLoadingState;
