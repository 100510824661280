import React from 'react'

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import RouteConfig from '../../config/appRoute/RouteConfig'
import { FUNCTIONS } from '../../constants/APDConstants'

const UmMenu = [
    {
        functionId: FUNCTIONS.USER.id,
        icon: <AccountCircleIcon />,
        label: 'จัดการผู้ใช้งาน',
        to: RouteConfig.UM_USER
    },
    {
        functionId: FUNCTIONS.USER_GROUP.id,
        icon: <SupervisedUserCircleIcon />,
        label: 'จัดการกลุ่มผู้ใช้งาน',
        to: RouteConfig.UM_USER_GROUP,
        pathActive: [
            RouteConfig.UM_USER_GROUP,
            RouteConfig.UM_USER_GROUP_ADD,
            RouteConfig.UM_USER_GROUP_EDIT
        ]
    },
    {
        functionId: '-1',
        icon: <LiveHelpIcon />,
        label: 'คู่มือ',
        to: window.URL_USERMANUAL.UM
    },
];

export default UmMenu
