import React, { useContext } from 'react'
import { reduxForm } from "redux-form"

import { AbsenceContext } from './AbsenceProvider'
import { validateCancelAbsence } from './AbsenceUtils'
import { checkSuccessResService } from '../../../../utilities/Utils'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'
import * as ApiLeaveSAP from '../../../../api/ApiLeaveSAP'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'

import LeaveSAPCancelView from '../../presentational/leavesap/LeaveSAPCancelView'

const CancelLeave = ({
    handleSubmit,
    reFetchData
}) => {
    const {
        absenceState,
        alertMessageState,
        loadingState,
        empId
    } = useContext(AbsenceContext)

    const handleClose = () => {
        absenceState.setOpenCancelForm(false)
    }

    const callBackCancelAbsence = () => {
        alertMessageState.hideAlert()
        absenceState.setdataEditAbsence()
        handleClose()
        reFetchData()
    }

    const callserviceCancelLeave = async (values) => {
        loadingState.showLoading()
        const resCancelAbsence = await ApiLeaveSAP.cancelAbsence({
            empId: empId,
            cancelReason: values.cancelReason,
            absenceId: absenceState.dataEditAbsence.absenceId
        })
        loadingState.hideLoading()

        if (resCancelAbsence && resCancelAbsence.data && checkSuccessResService(resCancelAbsence.data)) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CANCEL_ABSENCE_SUCCESS,
                callbackCancel: callBackCancelAbsence
            })
        } else {
            const msg = checkResStatusService(resCancelAbsence)
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
                callbackCancel: () => alertMessageState.hideAlert()
            })
        }
    }

    const handleSubmitCancelAbsence = values => {
        alertMessageState.showAlert({
            altMsg: `คุณต้องการยืนยันการยกเลิกใบลา ${absenceState.dataEditAbsence.absenceId} ?`,
            altType: 2,
            callbackCancel: () => alertMessageState.hideAlert(),
            callbackConfirm: () => {
                callserviceCancelLeave(values)
            }
        })
    }

    return (
        <LeaveSAPCancelView
            isOpenCancelForm={absenceState.isOpenCancelForm}

            onClickClose={handleClose}
            onSubmitClick={handleSubmit(handleSubmitCancelAbsence)}
        />
    )
}

const reduxFormParameters = {
    form: 'LeaveCancel',
    validate: validateCancelAbsence
}


export default reduxForm(reduxFormParameters)(CancelLeave)
