import { ORG_LEAVE, ERRORS } from '../../../../constants/APDConstants';

export const validateEditEmp = (values) => {
    const errors = {}

    if (!values[ORG_LEAVE.FORM_EDIT_EMP.NAME.NAME]) {
        errors[ORG_LEAVE.FORM_EDIT_EMP.NAME.NAME] = ERRORS.REQUIRED
    }

    if (!values[ORG_LEAVE.FORM_EDIT_EMP.NAME.ORG]) {
        errors[ORG_LEAVE.FORM_EDIT_EMP.NAME.ORG] = ERRORS.REQUIRED
    }

    if (values[ORG_LEAVE.FORM_EDIT_EMP.NAME.IS_HAVE_REPRESENT] && !values[ORG_LEAVE.FORM_EDIT_EMP.NAME.REPRESENT]) {
        errors[ORG_LEAVE.FORM_EDIT_EMP.NAME.REPRESENT] = ERRORS.REQUIRED
    }

    return errors
}

export const validateEditOrg = (values, props) => {
    const { representEmpId } = props.panelState.dataEditOrg
    const errors = {}

    if (!representEmpId && !values[ORG_LEAVE.FORM_EDIT_ORG.NAME.PERMISSION_APPROVE]) {
        errors[ORG_LEAVE.FORM_EDIT_ORG.NAME.PERMISSION_APPROVE] = ERRORS.REQUIRED
    }

    return errors
}

export const convertFormatUpdateOrgLeave = ({ uuid, updateBy, structureOrg, emp, empIdModify }) => {
    const dataOrg = structureOrg.map(org => ({
        orgId: org.id,
        parentOrgId: org.pid,
        auditEmpId: org.auditEmpId,
        approveEmpId: org.approveEmpId,
        representEmpId: org.representEmpId,
    }))
    let dataEmp = []

    if (empIdModify.length > 0) {
        dataEmp = emp.filter(emp => empIdModify.indexOf(emp.id) !== -1).map(emp => ({
            empId: emp.id,
            orgId: emp.orgId,
            isManager: emp.isManager
        }))
    }

    return { uuid, updateBy, dataOrg, dataEmp }
}

export const convertFormatUpdateOrgSap = ({ uuid, structureOrg, emp, empInActive, updateBy, empIdModify }) => {
    const empFilter = emp.filter(emp => empIdModify.indexOf(emp.id) !== -1 || emp.isNewEmp || emp.prevOrgId || emp.isChangeManager)
    const empConcat = [...empFilter, ...empInActive]
    const dataOrg = structureOrg.map(org => ({
        orgId: org.id,
        parentOrgId: org.pid,
        auditEmpId: org.auditEmpId,
        approveEmpId: org.approveEmpId,
        representEmpId: org.representEmpId
    }))
    const dataEmp = empConcat.map(emp => ({
        empId: emp.id,
        uuid: emp.uuid,
        orgId: emp.orgId,
        isManager: emp.isManager,
        orgIdSap: emp.orgIdSap
    }))

    return { uuid, dataOrg, dataEmp, updateBy }
}

export const checkEmpActive = (empStatusId, effectiveDate) => {
    // console.log('effectiveDate', effectiveDate)
    // return EMP_STATUS_ACTIVE.indexOf(empStatusId) !== -1 

    return !effectiveDate || new Date() < new Date(effectiveDate)
}
