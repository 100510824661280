import React from 'react'
import { Field } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { MEETING } from '../../../../../constants/APDConstants'
// import Swiper from '../../../../../control/swiperimg/SwiperImage';
import MeetingSwiperImage from '../../../container/manageMeetingRoom/MeetingSwiperImage'

const MtRoonUploadView = ({
    funcUploadImage,
    funcDeleteImage,
    roomId
}) => {
    return (
        <Grid container justify='center'>
            <Grid item sm={10} xs={11} container justify='center'>
                {/* <Grid item > */}
                <Field
                    name={MEETING.FORM.NAME.IMAGES}
                    component={MeetingSwiperImage}
                    // funcUploadImage={funcUploadImage}
                    // funcDeleteImage={funcDeleteImage}
                    // roomId={roomId}
                    limit={10}
                />
                {/* </Grid> */}
                <Grid item xs={12} style={{ fontSize: '15px', color: 'red', textAlign: 'center' }}>
                    * ขนาดไฟล์สูงสุดไม่เกิน 5MB, จำนวนไม่เกิน 10 รูป, รองรับไฟล์นามสกุล .jpeg, .jpg, .png เท่านั้น, อัตราส่วนรูปภาพที่แนะนำ 16:9
                </Grid>
            </Grid>
        </Grid >
    )
}

export default MtRoonUploadView