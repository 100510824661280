import React, { Fragment } from 'react'

import UseAlertMessageState from '../alert/UseAlertMessageState'

import UploadMultiFileView from './UploadMultiFileView'
import AlertMessage from '../alert/AlertMessage';

const UploadMultiFile = (props) => {
    const {
        input,
        supportFiles,
        maximumFile,
        maxSize, //MB
        onClickFile,
        disabled
    } = props

    let refUpload = null;
    const alertMessageState = UseAlertMessageState()

    const handleClickUpload = () => {
        if (!disabled) {
            if (maximumFile && input.value && input.value.length >= maximumFile) {
                alertMessageState.showAlert({ altMsg: `จำนวนไฟล์สูงสุดไม่เกิน ${maximumFile} ไฟล์` })
            } else {
                refUpload.click();
            }
        }
    }

    const handleDelete = (indexDelete) => (e) => {
        e.stopPropagation()
        const listFileUpdate = input.value.filter((file, index) => index !== indexDelete)
        input.onChange(listFileUpdate)
    }

    const getFileExtension = filename => {
        const filenameSplit = filename.split('.')
        let fileExtension = ""

        if (filenameSplit.length > 0) {
            fileExtension = filenameSplit.pop().toLowerCase()
        }
        return fileExtension
    }

    const handleChangeFile = async (e) => {
        if (e.target.files[0]) {
            const fileNameExtension = e.target.files[0].name
            const fileExtension = getFileExtension(fileNameExtension)
            const isSupport = supportFiles ? supportFiles.indexOf(fileExtension) !== -1 : true
            const [fileName] = fileNameExtension.split(`.${fileExtension}`)

            if (isSupport) {
                if (maxSize && e.target.files[0].size > maxSize * 1048576) {
                    alertMessageState.showAlert({ altMsg: `ขนาดไฟล์ต้องไม่เกิน ${maxSize} MB` })
                } else {
                    const fileData = {
                        name: fileName,
                        fileType: fileExtension,
                        file: e.target.files[0]
                    }
                    const file = input.value ? [...input.value, fileData] : [fileData];
                    input.onChange(file)
                }
            } else {
                alertMessageState.showAlert({ altMsg: `รองรับไฟล์นามสกุล ${supportFiles.join(", ")} เท่านั้น` })
            }
        }
    }

    return (
        <Fragment>
            <UploadMultiFileView
                {...props}
                disabled={disabled}
                onClick={handleClickUpload}
                onDelete={handleDelete}
                onClickFile={onClickFile}
            />
            <input
                ref={node => refUpload = node}
                type="file"
                name="upload"
                onChange={handleChangeFile}
                style={{ display: 'none' }}
            />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    )
}

export default UploadMultiFile
