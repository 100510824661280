import React, { Fragment } from 'react'
import { reduxForm } from "redux-form"

import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseLoadingState from '../../../../control/loading/UseLoadingState'

import AlertMessage from '../../../../control/alert/AlertMessage'
import Loading from '../../../../control/loading/Loading'

import LeaveSAPCancelView from '../../presentational/leavesap/LeaveSAPCancelView'

import * as APILeaveSAP from '../../../../api/ApiLeaveSAP'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'

const LeaveSAPCancel = (props) => {

    const {
        isOpenCancelForm,
        onClickClose,
        handleSubmit,
        profile,
        data,
        onReSearch
    } = props

    const alertMessageState = UseAlertMessageState()
    const loadingState = UseLoadingState()

    const handleCancel = (values) => {
        alertMessageState.showAlert({
            altMsg: `คุณต้องการยืนยันการยกเลิกใบลา ${data.absenceId} ?`,
            altType: 2,
            callbackCancel: () => alertMessageState.hideAlert(),
            callbackConfirm: () => {
                handleConfirmCancel(values)
            }
        })
    }

    const handleConfirmCancel = (values) => {
        loadingState.showLoading()
        const params = {
            empId: profile.empId,
            cancelReason: values.cancelReason,
            absenceId: data.absenceId
        }
        APILeaveSAP.cancelAbsence(params).then(res => {
            loadingState.hideLoading()

            if (res && res.data && res.data[0].message.toLowerCase() === 'success') {
                alertMessageState.showAlert({
                    altMsg: 'ยกเลิกใบลาสำเร็จ',
                    callbackCancel: () => {
                        alertMessageState.hideAlert()
                        onClickClose()
                        onReSearch()
                    }
                })
            } else {
                const msg = checkResStatusService(res)
                alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
            }
        })
    }

    return (
        <Fragment>
            <LeaveSAPCancelView
                isOpenCancelForm={isOpenCancelForm}
                onClickClose={onClickClose}
                onSubmitClick={handleSubmit(handleCancel)}
            />
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    )
}

const validateCancel = (values) => {
    const errors = {}

    if (!values.cancelReason) {
        errors.cancelReason = 'กรุณาระบุ'
    }

    return errors
}

const reduxFormParameters = {
    form: 'LeaveSAPCancel',
    validate: validateCancel
}

export default reduxForm(reduxFormParameters)(LeaveSAPCancel)