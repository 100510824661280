import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { keyEnterSubmitForm } from '../../../../utilities/FormUtils';

// import DatePicker from '../../../../control/datetime/DatePicker';
import DatePicker from '../../../../control/fdatetime/DateflatPicker'
import Autocomplete from '../../../../control/autocomplete/AutoComplete';
import TextField from '../../../../control/textfield/TextFields';
import Button from '../../../../control/button/Button';
import ErrorField from '../../../../control/errorField/ErrorField';

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '33px'
    },
    btnGrid: {
        textAlign: 'end'
    },
    divider: {
        width: '100%',
        margin: '24px 0px'
    },
    spacing: {
        ...theme.form.spacing
    },
    btnSearch: {
        width: '105px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 0,
            marginBottom: '5px',
            marginTop: '5px'
        }
    },
    btnReset: {
        backgroundColor: `${theme.mainTheme.color6} !important`,
        width: '105px',
        marginRight: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 0,
            marginBottom: '5px',
            marginTop: '5px'
        }
    },
    label: {
        color: theme.mainTheme.color1
    },
    rootGroup: {
        border: '1px solid #cccccc',
        height: '95px',
        padding: '5px 10px',
        borderRadius: '5px',
        marginBottom: '15px'
    },
    fieldGroup: {
        width: 'calc(50% - 20px)'
    },
    labelTo: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
    },
    fieldError: {
        marginRight: theme.spacing(2)
    }
}));

const GroupField = ({
    label,
    fields = [],
    classes
}) => {

    return (
        <Grid container justify='space-between' className={classes.rootGroup}>
            <Grid item xs={12} className={classes.label} >{label}</Grid>
            <Grid item className={classes.fieldGroup}>{fields[0]}</Grid>
            <Grid item className={classes.labelTo} >ถึง</Grid>
            <Grid item className={classes.fieldGroup}>{fields[1]}</Grid>
        </Grid>
    )
}

const LeaveSAPSearchView = props => {
    const classes = useStyles();
    const {
        optionsResponse,
        onSearchClick,
        onResetClick,
        onChangeDate,
        optionsOrg
    } = props;

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                    <GroupField
                        classes={classes}
                        label="ช่วงวันที่เข้า SAP"
                        fields={[
                            <Field
                                id="startDate"
                                component={DatePicker}
                                name="startDate"
                                // label="วันที่เข้า SAP เริ่มต้น"
                                onChange={onChangeDate('startDate')}
                            />,
                            <Field
                                id="endDate"
                                component={DatePicker}
                                name="endDate"
                                // label="วันที่เข้า SAP สุดท้าย"
                                onChange={onChangeDate('endDate')}
                                rightMos={true}
                            />
                        ]}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <GroupField
                        classes={classes}
                        label="ช่วงวันที่ลา"
                        fields={[
                            <Field
                                id="startDateLeave"
                                component={DatePicker}
                                name="startDateLeave"
                                onChange={onChangeDate('startDateLeave')}
                            />,
                            <Field
                                id="endDateLeave"
                                component={DatePicker}
                                name="endDateLeave"
                                onChange={onChangeDate('endDateLeave')}
                                rightMos={true}
                            />
                        ]}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className={classes.spacing}>
                    <Field
                        component={Autocomplete}
                        name="responseTypeId"
                        label="สถานะการส่ง SAP"
                        options={optionsResponse}
                        optionKey={{ label: 'name', value: 'id', abbr: null }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.spacing}>
                    <Field
                        component={TextField}
                        name="keyword"
                        label="คำค้นหา"
                        placeholder={'เลขที่ใบลา, ประเภทที่ส่งเข้า SAP'}
                        maxLength={50}
                        onKeyDown={keyEnterSubmitForm(onSearchClick)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.spacing}>
                    <Field
                        component={Autocomplete}
                        name="orgId"
                        label="หน่วยงานผู้ยื่นใบลา"
                        options={optionsOrg}
                        optionKey={{ label: 'name', value: 'orgId', abbr: 'nameSht' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.spacing}>
                    <Field
                        component={TextField}
                        name="emp"
                        label="รหัสพนักงานหรือชื่อ-นามสกุล ผู้ยื่นใบลา"
                        placeholder={'รหัสพนักงานหรือชื่อ-นามสกุล ผู้ยื่นใบลา'}
                        maxLength={100}
                        onKeyDown={keyEnterSubmitForm(onSearchClick)}
                    />
                </Grid>
                <Grid item xs={12} container justify="flex-end" alignItems="center" className={classes.btnGrid}>
                    <Grid item className={classes.fieldError}>
                        <Field
                            component={ErrorField}
                            name="error"
                        />
                    </Grid>
                    {/* <Grid item> */}
                    <Button
                        label="ค่าเริ่มต้น"
                        className={classes.btnReset}
                        onClick={onResetClick}
                    />
                    <Button
                        label="ค้นหา"
                        className={classes.btnSearch}
                        onClick={onSearchClick}
                    />
                    {/* </Grid> */}
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
        </Fragment>
    );
};

export default LeaveSAPSearchView;
