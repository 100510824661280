import React from 'react'
import classnames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

const useStyles = makeStyles(theme => ({
    gridTextFields: {
        height: '90px'
    },
    gridTextFieldsDisable: {
        height: '25px'
    },
    root: {
        [`& input`]: {
            height: theme.field.height,
            padding: theme.field.padding,
            cursor: 'pointer'
        },
        width: '100%',
        boxShadow: theme.field.boxShadow
    },
    rootDisable: {
        [`& input`]: {
            cursor: 'default'
        }
    },
    rootError: {
        [`& fieldset`]: {
            borderColor: '#ff0505'
        },
    },
    label: {
        color: '#F47B15'
    },
    errorText: {
        color: "red",
        marginTop: "0px",
        marginLeft: 15,
    },
    error: {
        color: "red",
    },
    clearIcon: {
        fontSize: '22px'
    },
    listItem: {
        // cursor: 'pointer',
        padding: '0px 10px',
        marginBottom: '3px',
        borderRadius: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 7px 0px',
        width: '100%',
        height: '40px',
        justifyContent: 'space-between'
    },
    icon: {
        color: '#757575'
    },
    rootListItemText: {
        textDecoration: 'underline',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        '&:hover': {
            color: '#0000EE'
        },
    },
    rootClearIconButton: {
        padding: '5px'
    },
    divFileNameDisable: {
        maxWidth: '100% !important',
    },
    divFileName: {
        maxWidth: 'calc(100% - 30px)'
    },
    btnDelFile: {
        width: '30px'
    },
}))

const FileItem = ({ classes, file, index, disabled, onDelete, onClickFile }) => {
    const fileName = `${file.name}.${file.type ? file.type : file.fileType}`

    return (
        <ListItem divider className={classes.listItem} >
            <div className={classnames(classes.divFileName, { [classes.divFileNameDisable]: disabled })} >
                <ListItemText
                    primary={fileName}
                    classes={{ root: classes.rootListItemText }}
                    onClick={() => { onClickFile(file) }}
                />
            </div>
            {!disabled && <div className={classes.btnDelFile}>
                <IconButton classes={{ root: classes.rootClearIconButton }} onClick={onDelete(index)}>
                    <ClearIcon className={classes.clearIcon} />
                </IconButton>
            </div>}
        </ListItem>
    )
}

const UploadMultiFileView = ({
    label,
    placeholder = "อัพโหลดไฟล์",
    input,
    required,
    meta: { error, touched, submitError },
    row,
    disabled,
    onClick,
    onDelete,
    onClickFile
}) => {
    const classes = useStyles()
    const isError = (error && submitError) || (touched && error)

    return (
        <Grid container>
            <Grid item xs={12} className={disabled ? classes.gridTextFieldsDisable : classes.gridTextFields} >
                <Grid item xs={12} className={classnames(classes.label, { [classes.error]: isError })} >
                    {label}{" "}{required && <span className={classes.error} >*</span>}
                </Grid>
                {!disabled && <Grid item xs={12}>
                    <TextField
                        placeholder={placeholder && placeholder.toString()}
                        variant="outlined"
                        className={classnames(classes.root, { [classes.rootError]: isError, [classes.rootDisable]: disabled })}
                        error={isError}
                        rows={row}
                        onClick={onClick}
                        disabled={disabled}
                        InputProps={{
                            endAdornment: (<InsertDriveFileOutlinedIcon className={classes.icon} />),
                            readOnly: true,
                        }}
                    />
                </Grid>}
                <FormHelperText
                    className={classes.errorText}
                    style={isError ? { display: 'block' } : { display: 'none' }}
                >
                    {error}
                </FormHelperText>
            </Grid>
            <Grid item xs={12}>
                {input.value && input.value.map((file, index) => <FileItem
                    key={`fileitem-${index}`}
                    disabled={disabled}
                    file={file}
                    index={index}
                    classes={classes}
                    onDelete={onDelete}
                    onClickFile={onClickFile}
                />)}
            </Grid>
        </Grid >
    )
}

export default UploadMultiFileView
