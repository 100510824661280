import React from 'react'

//import component
import MtRoomUploadView from '../../../presentational/manageMeetingRoom/formMeeting/MtRoomUploadView'

const MtRoomUpload = ({
    // roomId,
    // funcUploadImage,
    // funcDeleteImage
}) => {
    return <MtRoomUploadView
    // roomId={roomId}
    // funcUploadImage={funcUploadImage}
    // funcDeleteImage={funcDeleteImage}
    />
}

export default MtRoomUpload