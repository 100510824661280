import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';

import CommunityCard from '../components/layout/CommunityCard';
import CenterContent from '../components/portal/CenterContent';

import { SYSTEM } from '../constants/APDConstants';

import Footer from '../components/layout/Footer';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1%',
        [theme.breakpoints.up('xl')]: {
            padding: '3%',
        },
    },
    card: {
        padding: 0,
        margin: 16,
        minWidth: '300px',
        [theme.breakpoints.down('sm')]: {
            minWidth: 0,
            width: 'calc(100% - 32px)',
        },
    },
    gridContainer: {
        height: '100%',
    },
}));

const communities = [
    {
        systemId: SYSTEM.LEAVE.id,
        path: SYSTEM.LEAVE.path,
        image: SYSTEM.LEAVE.image,
        title: 'ระบบลา',
        subTitle: 'Leave System',
    },
    {
        systemId: SYSTEM.MEETING.id,
        path: SYSTEM.MEETING.path,
        image: SYSTEM.MEETING.image,
        title: 'ระบบห้องประชุม',
        subTitle: 'Meeting Room System',
    },
    {
        systemId: SYSTEM.REGISTRATION.id,
        path: SYSTEM.REGISTRATION.path,
        image: SYSTEM.REGISTRATION.image,
        title: 'จัดการการลงทะเบียน',
        subTitle: 'System',
    },
    {
        systemId: SYSTEM.EV.id,
        path: SYSTEM.EV.path,
        image: SYSTEM.EV.image,
        title: 'จัดการ EV',
        subTitle: 'System',
    },
    {
        systemId: SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.id,
        path: SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.path,
        image: SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.image,
        title: 'ระบบจัดการข้อมูลพนักงาน',
        subTitle: 'Employee Profile Management',
    },
    {
        systemId: SYSTEM.UM.id,
        path: SYSTEM.UM.path,
        image: SYSTEM.UM.image,
        title: 'ระบบจัดการผู้ใช้งาน',
        subTitle: 'APD User Management',
    },
    {
        systemId: SYSTEM.MAINTENANCE.id,
        path: SYSTEM.MAINTENANCE.path,
        image: SYSTEM.MAINTENANCE.image,
        title: 'ระบบจัดการบำรุงรักษา',
        subTitle: 'Maintenance System',
    },
];

function Portal({ authenRdc }) {
    const systemProfileID = authenRdc.profile.systems.map(
        (system) => system.systemId
    );
    const systemList = communities.filter(
        (communitie) => systemProfileID.indexOf(communitie.systemId) !== -1
    );
    const classes = useStyles();

    return (
        <Scrollbars universal={true} autoHide>
            <Grid container className={classes.gridContainer}>
                <Grid container className={classes.root}>
                    <CenterContent />
                    <Grid container justify="center" spacing={4}>
                        {systemList.map((community, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={7}
                                md={2}
                                lg={2}
                                key={`commu_${index}`}
                                className={classes.card}
                            >
                                <Link
                                    to={community.path}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <CommunityCard
                                        title={community.title}
                                        subTitle={community.subTitle}
                                        image={community.image}
                                    />
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-end" justify="center">
                    <Footer />
                </Grid>
            </Grid>
        </Scrollbars>
    );
}

const mapStateToProps = (state) => ({
    authenRdc: state.authen,
});

export default connect(mapStateToProps, null)(Portal);
