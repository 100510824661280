export const TABLE_CONNEXT_MAINTENANCE = {
    TABLE: {
        NO: 'ลำดับ',
        START_DATE: 'วันที่เริ่มต้นปิดระบบ',
        END_DATE: 'วันที่สิ้นสุดปิดระบบ',
        DISPLAY_DATE: 'วันที่แสดงข้อความแจ้งเตือน',
        NOTE: 'หมายเหตุ',
        API_NAME: 'ระบบ'
    },
    DATA: {
        NO: 'no',
        START_DATE: 'startDateDisplay',
        END_DATE: 'endDateDisplay',
        DISPLAY_DATE: 'displayDateDisplay',
        NOTE: 'note',
        API_NAME: 'apiName'
    }
}

export const MODE_MODAL = {
    ADD_MAINTENANCE: 'ADD_MAINTENANCE',
    EDIT_MAINTENANCE: 'EDIT_MAINTENANCE'
}

export const FORM_MAINTENANCE = {
    NAME: {
        START_DATE: 'startDate',
        END_DATE: 'endDate',
        DISPLAY_DATE: 'displayDate',
        NOTE: 'note'
    },
    LABEL: {
        START_DATE: 'วันที่เริ่มต้นปิดระบบ',
        END_DATE: 'วันที่สิ้นสุดปิดระบบ',
        DISPLAY_DATE: 'วันที่แสดงข้อความแจ้งเตือน',
        NOTE: 'หมายเหตุ'
    },
    PLACEHOLDER: {
        NOTE: 'หมายเหตุ'
    }
}

export const API_ID = {
    CONNEXT: "4"
}