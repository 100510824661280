import React, { Fragment, useState } from 'react'

import FormHoliday from '../FormHoliday'

import { postHoliday } from '../../../../../api/ApiHoliday'
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'

import AlertMessage from '../../../../../control/alert/AlertMessage'
import UseAlertMessageState from '../../../../../control/alert/UseAlertMessageState'
import Loading from '../../../../../control/loading/Loading'
import UseLoadingState from '../../../../../control/loading/UseLoadingState'

import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'
import * as HolidayConstants from '../../../../../constants/leave/HolidayConstants'
import { SV_RES_SUCCESS } from '../../../../../constants/APDConstants'

const AddHoliday = (props) => {

    const {
        closeFormHoliday,
        callServiceGet,
        logAbsence,
        dateDup,
        empId,
        yearState,
        callServiceGetHolidayYear,
        handleChangeYear
    } = props

    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();

    const [isFail, setIsFail] = useState(false)

    const handleCloseSave = (countAbsence, date, holidayId, reason) => {
        setTimeout(() => {
            closeFormHoliday()

            if (yearState !== Number(date.slice(0, 4)) + 543) {
                callServiceGetHolidayYear()
            } else {
                callServiceGet()
            }

            if (countAbsence > 0) {
                logAbsence(date, holidayId, reason)
            }
        }, 600)
    }

    const handleCancel = () => {
        alertMessageState.hideAlert()
        setIsFail(true)
    }

    const lutUpdate = () => {
        setIsFail(false)
    }

    const saveHoliday = (id, values) => {
        loadingState.showLoading()
        const year = values.date.getFullYear()
        const month = values.date.getMonth() + 1
        const day = values.date.getDate()

        const strMonth = month.toString().length > 1 ? `${month}` : `0${month}`
        const strDate = day.toString().length > 1 ? `${day}` : `0${day}`

        const enabled = Object.keys(values).indexOf('enabled') < 0 ? false : values.enabled

        const params = {
            date: `${year}-${strMonth}-${strDate}`,
            holidayInfoId: id,
            enabled: enabled,
            empId: empId,
            description: values.description ? values.description : null
        }

        postHoliday(params).then(res => {
            loadingState.hideLoading()
            if (res && res.data && res.data[0].message.toLowerCase() === SV_RES_SUCCESS) {
                alertMessageState.showAlert({
                    altMsg: AlertMSGConstants.SAVE_SUCCESS,
                    altPs: res.data[0].countAbsence > 0 && `พบใบลาที่ได้รับผลกระทบ ${res.data[0].countAbsence} รายการ `,
                    callbackCancel: () => {
                        handleCloseSave(res.data[0].countAbsence,
                            params.date,
                            res.data[0].holidayId,
                            params.enabled ? HolidayConstants.STR_ADD : HolidayConstants.STR_CANCEL
                        )
                    }
                })
            } else {
                let errorMsg = checkResStatusService(res)

                if (!errorMsg) {
                    errorMsg = res ? res.errors ? res.errors[0].detail : AlertMSGConstants.SAVE_FAIL : AlertMSGConstants.SAVE_FAIL
                }
                alertMessageState.showAlert({ altMsg: errorMsg, callbackCancel: () => { handleCancel() } })
            }
        })
    }

    return (
        <Fragment>

            <FormHoliday
                saveHoliday={saveHoliday}
                closeFormHoliday={closeFormHoliday}
                callServiceGet={callServiceGet}
                dateDup={dateDup}
                lutUpdate={lutUpdate}
                isFail={isFail}
                handleChangeYear={handleChangeYear}
            />

            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    )
}

export default AddHoliday