import React from 'react'

import { ABSENCE } from '../../../../constants/APDConstants';
import { makeStyles } from '@material-ui/core/styles'

import DataTable from '../../../../control/table/DataTable';

const column = [
    { title: ABSENCE.TABLE_ABSENCE.TABLE.ABSENCE_ID, data: ABSENCE.TABLE_ABSENCE.DATA.ABSENCE_ID, width: '10%', sort: 'asc', align: 'left', type: 'number' },
    { title: ABSENCE.TABLE_ABSENCE.TABLE.LEAVE_TYPE, data: ABSENCE.TABLE_ABSENCE.DATA.LEAVE_TYPE, width: '12%', sort: 'asc', align: 'left' },
    { title: ABSENCE.TABLE_ABSENCE.TABLE.EMP_ID, data: ABSENCE.TABLE_ABSENCE.DATA.EMP_ID, width: '10%', sort: 'asc', align: 'center' },
    { title: ABSENCE.TABLE_ABSENCE.TABLE.NAME, data: ABSENCE.TABLE_ABSENCE.DATA.NAME, width: '15%', sort: 'asc', align: 'left' },
    { title: ABSENCE.TABLE_ABSENCE.TABLE.ORG, data: ABSENCE.TABLE_ABSENCE.DATA.ORG, width: '15%', sort: 'asc', align: 'left' },
    { title: ABSENCE.TABLE_ABSENCE.TABLE.LEAVE_DATE, data: ABSENCE.TABLE_ABSENCE.DATA.LEAVE_DATE, width: '20%', sort: 'asc', type: 'date' },
    { title: ABSENCE.TABLE_ABSENCE.TABLE.DURATION, data: ABSENCE.TABLE_ABSENCE.DATA.DURATION, width: '9%', sort: 'asc', align: 'center' },
    { title: ABSENCE.TABLE_ABSENCE.TABLE.LEAVE_STATE, data: ABSENCE.TABLE_ABSENCE.DATA.LEAVE_STATE, width: '9%', sort: 'asc', align: 'center' },
];

const useStyles = makeStyles(theme => ({
    tableCell: {
        padding: '20px'
    }
}))

const TableAbsenceView = ({
    dataTableState,
    onRowClick
}) => {
    const classes = useStyles()

    return (
        <DataTable
            column={column}
            data={dataTableState.dataPage}
            page={dataTableState.page}
            rowPerPage={dataTableState.rowPerPage}
            total={dataTableState.total}
            sortColumn={dataTableState.sortColumn}
            sortType={dataTableState.sortType}
            classes={{ tableCell: classes.tableCell }}

            handlePageChange={dataTableState.changePage}
            handleRowClick={onRowClick}
            handleSortExternal={dataTableState.sortData}
            handleRowPerPage={() => { }}
        />
    )
}

export default TableAbsenceView 
