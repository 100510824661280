import React, { Fragment, useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import FormSearchBooking from '../form/FormSearchBooking';
import FormTableBookingView from '../../../presentational/booking/bookingList/TableBookingView';
import DetailBookingList from './DetailBookingList';
import CancelBooking from './CancelBooking';

import { BookingContext } from '../BookingProvider';
import {
    // formatResult,
    formatResultTable,
} from './DataBookingManagement';
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'
import ExportBookingList from './ExportBookingList';

import { cancelBookingByDate } from '../../../../../api/ApiBooking';

import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants';

const TableList = props => {
    const { profile } = props;
    const [openDetail, setOpenDetail] = useState(false);
    const [openCancelPage, setOpenCancelPage] = useState(false);
    const [data, setData] = useState(null);
    const [cancelBooking, setCancelBooking] = useState(null)
    // const [disabledCheck, setDisabledCheck] = useState(false);

    // const [allData, setAllData] = useState([]);
    // const [exportData, setExportData] = useState(null)

    const {
        bookingSearchState,
        dataTableMeetingState,
        alertMessageState,
        loadingState,
        // tableBookingState
    } = useContext(BookingContext);

    // const { exportData, setExportData } = tableBookingState

    useEffect(() => {
        if (bookingSearchState.resultSearch) {
            // const data = formatResult(
            //     bookingSearchState.resultSearch,
            //     profile,
            //     bookingSearchState.valueSearch
            // );

            const dataTable = formatResultTable(
                bookingSearchState.resultSearch,
                profile,
                bookingSearchState.valueSearch
            )

            // setAllData(dataTable);
            // setExportData(data)

            // const isCanCheck = data.filter(item => item.checkbox).length > 0;
            // setDisabledCheck(!isCanCheck);
            dataTableMeetingState.setInitialDataTable(dataTable);
        }
    }, [bookingSearchState.resultSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    //----------------- table click action for open detail booking ------------------
    const handleRowClick = (values, type) => {
        switch (type) {
            case 'detail':
                setOpenDetail(true);
                setData(values);

                break;
            case 'delete':
                setCancelBooking(values)
                handleCancelBooking()

                break;

            default:
                break;
        }

    };

    //---------------- set data detail for use detail booking and action state open detail booking ---------
    const handleClose = () => {
        setOpenDetail(false);
        setData(null);
    };

    //---------------- when button search click --------------------
    const callbackSearch = strParams => {
        strParams += `&uuid=${profile.uuid}`
        bookingSearchState.searchBooking(strParams);
    };

    //------------- set cancel's page for insert cancel reason before call service -----------------
    const handleCancelBooking = () => {
        setOpenCancelPage(!openCancelPage);
    };

    //------------- confirm cancel reason -----------------
    const confirmCancelBooking = reason => {
        alertMessageState.hideAlert();
        loadingState.showLoading();
        const params = {
            reason: reason,
            uuid: profile.uuid
        }

        cancelBookingByDate(cancelBooking.bookingId, params).then(res => {
            loadingState.hideLoading();
            if (
                res &&
                res.data &&
                res.data[0].message.toLowerCase() === 'success'
            ) {
                alertMessageState.showAlert({
                    altMsg: AlertMSGConstants.CANCEL_BOOKING_SUCCESS,
                    callbackCancel: () => {
                        alertMessageState.hideAlert();
                        setOpenCancelPage(false);
                        setCancelBooking(null)
                        // setExportData(null)
                        callSearchAgain();
                    }
                });
            } else {
                const msg = checkResStatusService(res)
                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR
                });
            }
        });
    };

    //-------------- refresh result --------------
    const callSearchAgain = () => {
        callbackSearch(bookingSearchState.valueSearch.strParams);
    };

    //-------------- export excel -----------------
    const handleExportExcel = () => {
        loadingState.showLoading();
        // console.log('exportData', exportData)
        // ExportBookingList(exportData);
        ExportBookingList(dataTableMeetingState.dataTable)
        loadingState.hideLoading();
    };

    return (
        <Fragment>

            <FormSearchBooking
                callbackSearch={callbackSearch}
                onExportClick={handleExportExcel}
            />
            <FormTableBookingView
                dataTableState={dataTableMeetingState}
                onRowClick={handleRowClick}
                onCancelBookingClick={handleCancelBooking}
                onExportClick={handleExportExcel}
            // disabledCheck={disabledCheck}
            />
            {openDetail && (
                <DetailBookingList onClickClose={handleClose} data={data} />
            )}
            {openCancelPage && (
                <CancelBooking
                    onClickClose={handleCancelBooking}
                    myProfile={`${profile.prefix} ${profile.firstName} ${profile.lastName} (${profile.empId})`}
                    callbackCancelBooking={confirmCancelBooking}
                />
            )}
        </Fragment>
    );
};

const mapStateToProps = state => ({
    profile: state.authen.profile
});

export default connect(mapStateToProps, null)(TableList);
