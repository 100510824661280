import React from 'react'

import Connext from './Connext'
import ConnextProvider from './ConnextProvider'

const ConnextIndex = () => {
    return (
        <ConnextProvider>
            <Connext />
        </ConnextProvider>
    )
}

export default ConnextIndex
