import React from 'react';

import Calendar from '@material-ui/icons/CalendarToday';
import GroupIcon from '@material-ui/icons/Group';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SettingsIcon from '@material-ui/icons/Settings';

import { SYSTEM } from '../../constants/APDConstants';

const PortalMenu = [
    {
        systemId: SYSTEM.LEAVE.id,
        icon: <Calendar />,
        label: 'ระบบลา',
        to: SYSTEM.LEAVE.path,
    },
    {
        systemId: SYSTEM.MEETING.id,
        icon: <MeetingRoomIcon />,
        label: 'ระบบห้องประชุม',
        to: SYSTEM.MEETING.path,
    },
    {
        systemId: SYSTEM.REGISTRATION.id,
        icon: <KeyboardIcon />,
        label: 'จัดการการลงทะเบียน',
        to: SYSTEM.REGISTRATION.path,
    },
    {
        systemId: SYSTEM.EV.id,
        icon: <DriveEtaIcon />,
        label: 'จัดการ EV',
        to: SYSTEM.EV.path,
    },
    {
        systemId: SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.id,
        icon: <GroupIcon />,
        label: 'ระบบจัดการข้อมูลพนักงาน',
        to: SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.path,
    },
    {
        systemId: SYSTEM.UM.id,
        icon: <GroupIcon />,
        label: 'ระบบจัดการผู้ใช้งาน',
        to: SYSTEM.UM.path,
    },
    {
        systemId: SYSTEM.MAINTENANCE.id,
        icon: <SettingsIcon />,
        label: 'ระบบจัดการบำรุงรักษา',
        to: SYSTEM.MAINTENANCE.path,
    },
];

export default PortalMenu;
