import React, { useState, Fragment, useContext, useCallback } from 'react'

import { OrgLeaveContext } from '../OrgLeaveProvider'

import ToolbarView from '../../../presentational/orgLeave/toolbar/ToolbarView'
import LayoutToolbar from './LayoutToolbar'
import EmpToolbar from './EmpToolbar'

const TOOLBAR = {
    LAYOUT: "LAYOUT",
    MANAGE_EMP: "MANAGE_EMP"
}

const Toolbar = ({
    onSave,
    onRefresh
}) => {
    const [toolbarActive, setToolbarActive] = useState()

    const {
        isEnableSave,
        isOrgSap,
        orgState
    } = useContext(OrgLeaveContext)

    const handleFit = () => {
        window.chart.fit();
    }

    const handleClickLayout = useCallback(() => {
        setToolbarActive(la => la === TOOLBAR.LAYOUT ? null : TOOLBAR.LAYOUT)
    }, [setToolbarActive])

    const handleClickManageEmp = useCallback(() => {
        setToolbarActive(ea => ea === TOOLBAR.MANAGE_EMP ? null : TOOLBAR.MANAGE_EMP)
    }, [setToolbarActive])

    const handleCloseToolbar = useCallback(() => {
        setToolbarActive(null)
    }, [setToolbarActive])

    return (
        <Fragment>
            <ToolbarView
                isActiveLayout={toolbarActive === TOOLBAR.LAYOUT}
                isActiveManageEmp={toolbarActive === TOOLBAR.MANAGE_EMP}
                isEnableSave={isEnableSave}
                isOrgSap={isOrgSap}
                sorce={orgState.sorce}
                countEmpChange={orgState.countEmpChange}

                onSave={onSave}
                onFit={handleFit}
                onRefresh={onRefresh}
                onClickLayout={handleClickLayout}
                onClickManageEmp={handleClickManageEmp}
            />
            {toolbarActive === TOOLBAR.MANAGE_EMP && <EmpToolbar onClose={handleCloseToolbar} />}
            <LayoutToolbar isOpenLayout={toolbarActive === TOOLBAR.LAYOUT} />
        </Fragment>
    )
}

export default Toolbar
