import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Field } from 'redux-form';
import { reduxForm } from "redux-form"
import { sortBy } from 'lodash'

import * as APILeaveSAP from '../../../../api/ApiLeaveSAP'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'
import { convertDateDisplay, displayDateRange } from '../../../../utilities/DatetimeUtils'
import { LeaveSAPContext } from './LeaveSAPProvider'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'

import UseDataTableState from '../../../../control/table/UseDataTableState'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseLoadingState from '../../../../control/loading/UseLoadingState'

import AutoCompleteTable from '../../../../control/autocomplete/AutoCompleteTable'
import AlertMessage from '../../../../control/alert/AlertMessage'
import Loading from '../../../../control/loading/Loading'
import LeaveSAPDetailTransactionView from '../../presentational/leavesap/LeaveSAPDetailTransactionView'

const LeaveSAPDetailTransaction = (props) => {

    const {
        initialize,
        onCancelClick,
        data,
        dataDetail,
        handleSubmit,
        profile,
        lutLeaveSapType,
        // onReSearch,
        callHistoryPage,
        onCancelAbsClick,
        onReload,
        refresh,
        callbackRefresh,
        dataHistory,
        dataAbsence
    } = props

    const alertMessageState = UseAlertMessageState()
    const loadingState = UseLoadingState()

    const {
        searchLeaveSAPState,
        permissionSystem
    } = useContext(LeaveSAPContext)

    const {
        lutResult,
    } = searchLeaveSAPState

    // const rowPerPage = 10
    const dataTableState = UseDataTableState({
        rowPerPage: 10,
        specialColumn: {
            leaveDate: 'leaveDateForSort',
            leaveSapType: 'leaveSapTypeName'
        }
    })

    const [luLeaveSapType, setLuLeaveSapType] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [init, setInit] = useState()

    useEffect(() => {
        if (refresh) {
            firstStep()
            callbackRefresh()
        }
    }, [refresh]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        firstStep()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (luLeaveSapType.length > 0) {
            const newData = formatViewLeaveSapDetail(dataDetail)
            setDataTable(newData)
            dataTableState.setInitialDataTable(newData)
        }
    }, [luLeaveSapType]) // eslint-disable-line react-hooks/exhaustive-deps

    const firstStep = () => {
        setLuLeaveSapType(lutLeaveSapType)

        data.sapError = dataDetail.leaveSapDetail.filter(item => item.leaveSap.status === 'E' || item.leaveSap.status === 'W').length
        data.sapSuccess = dataDetail.leaveSapDetail.filter(item => item.leaveSap.status === 'S').length
        data.sapCancel = dataDetail.leaveSapDetail.filter(item => item.leaveSap.status === 'C').length
        data.all = dataDetail.leaveSapDetail.length

        if (data.leaveStateId !== 6) {
            const init = {}
            dataDetail.leaveSapDetail.forEach(item => {
                const { leaveSap } = item

                if (leaveSap.leaveSapTransactionId !== 'S') {
                    init[`leaveSapType${leaveSap.leaveSapTransactionId}`] = leaveSap.leaveSapTypeId
                }
            });

            initialize(init)
            setInit(init)
        }
    }

    const formatViewLeaveSapDetail = (dataLeave) => {
        const isHaveHistory = dataHistory.length > 0
        const newFormat = dataLeave.leaveSapDetail.map(item => {
            const { leaveSap } = item

            const newItem = {
                transactionId: leaveSap.leaveSapTransactionId,
                leaveDate: displayDateRange(leaveSap.startDate, leaveSap.endDate),
                leaveDateForSort: leaveSap.startDate,
                submitDate: leaveSap.submitDate && convertDateDisplay(leaveSap.submitDate),
                leaveSapType: !permissionSystem.permisEdit ? `${leaveSap.leaveSapTypeName} (${leaveSap.leaveSapTypeId})` :
                    (data.leaveStateId === 6 || leaveSap.status === 'S' || leaveSap.status === 'C') ? `${leaveSap.leaveSapTypeName} (${leaveSap.leaveSapTypeId})` :
                        <Field component={AutoCompleteTable} name={`leaveSapType${leaveSap.leaveSapTransactionId}`} options={luLeaveSapType} />,
                leaveSapTranType: leaveSap.leaveSapTransactionTypeName,
                sapResponseMsg: leaveSap.status === 'S' ? 'สำเร็จ' : leaveSap.status === 'E' ? 'ไม่สำเร็จ' : leaveSap.status === 'W' ? 'รอส่งใหม่' : 'ยกเลิกการส่ง',
                reason: (leaveSap.status !== 'S' && leaveSap.status !== 'C') ? leaveSap.oldResponseMessage : leaveSap.status === 'S' ? '-' : leaveSap.responseMessage,
                leaveSapTypeName: leaveSap.leaveSapTypeName,
                leaveSapTypeId: leaveSap.leaveSapTypeId,
                status: leaveSap.status,
                detail: isHaveHistory ? true : false,
                history: isHaveHistory ? true : false,
                startDate: leaveSap.startDate,
                endDate: leaveSap.endDate,
                sortDate: new Date(leaveSap.startDate.replace(/-/g, '/'))
            }

            return newItem
        })

        const sortNewFormat = sortBy(newFormat, ['sortDate'])
        return sortNewFormat
    }

    const handleSubmitTransactions = (values) => {

        const valuesParams = {}
        for (const key in values) {
            if (values[key] !== init[key]) {
                valuesParams[key] = values[key]
            }
        }

        const newArr = []
        for (let item in valuesParams) {
            const newItem = {
                leaveSapTransactionId: item.slice(12, item.length),
                leaveSapTypeId: values[item]
            }

            newArr.push(newItem)
        }

        if (newArr.length > 0) {
            alertMessageState.showAlert({
                altMsg: 'คุณต้องการยืนยันการแก้ไข ?',
                altType: 2,
                callbackCancel: () => alertMessageState.hideAlert(),
                callbackConfirm: () => {
                    handleLeaveSapDetail(newArr)
                }
            })
        } else {
            alertMessageState.showAlert({
                altMsg: 'ยังไม่มีการเปลี่ยนแปลงข้อมูล'
            })
        }
    }

    const handleLeaveSapDetail = (newArr) => {
        loadingState.showLoading()

        const params = { empId: profile.empId, leaveSapTransaction: newArr }

        APILeaveSAP.updateLeaveSapTransaction(params).then(res => {
            loadingState.hideLoading()
            if (res && res.data && res.data[0].message.toLowerCase() === 'success') {
                alertMessageState.showAlert({
                    altMsg: 'บันทึกข้อมูลสำเร็จ',
                    callbackCancel: () => {
                        onReload()
                        alertMessageState.hideAlert()
                    }
                })
            } else {
                const msg = checkResStatusService(res)
                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
                    callbackCancel: () => {
                        alertMessageState.hideAlert()
                    }
                })
            }
        })
    }

    const handleChangeLeaveType = (values) => {
        if (values) {
            const filterData = dataTable.filter(item => item.status === values.id)
            dataTableState.setInitialDataTable(filterData)
        } else {
            dataTableState.setInitialDataTable(dataTable)
        }
    }

    const handleViewHistory = (values) => {
        callHistoryPage({ startDate: values.startDate, endDate: values.endDate })
    }

    return (
        <Fragment>
            <LeaveSAPDetailTransactionView
                data={data}
                dataAbsence={dataAbsence}
                dataTableState={dataTableState}
                dataDetail={dataDetail}
                onCancelClick={onCancelClick}
                luLeaveSapType={lutResult}
                onSubmitClick={handleSubmit(handleSubmitTransactions)}
                onLeaveSapChange={handleChangeLeaveType}
                onRowClick={handleViewHistory}
                onCancelAbsClick={onCancelAbsClick}
            />
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    )
}

const reduxFormParameter = {
    form: 'leaveSapDetail'
}

export default reduxForm(reduxFormParameter)(LeaveSAPDetailTransaction)