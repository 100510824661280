import React, { useContext } from 'react'
import { Prompt } from 'react-router-dom'

import { RouterContext } from '../RouterProvider'

const PromptRouter = () => {
    const { callbackRouteChange } = useContext(RouterContext)

    const handleRouteChange = location => {
        if (callbackRouteChange) {
            return callbackRouteChange(location)
        } else {
            return true
        }
    }

    return (
        <Prompt message={location => handleRouteChange(location)} />
    )
}

export default PromptRouter
