import React, { useContext, Fragment } from 'react'

import { BookingContext } from './BookingProvider'

import AlertMessage from '../../../../control/alert/AlertMessage'
import Loading from '../../../../control/loading/Loading'

const ManageBookingPage = () => {
    const { loadingState, alertMessageState } = useContext(BookingContext)

    return (
        <Fragment>
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    )
}

export default ManageBookingPage
