import React from 'react'

import ForceUpdateProvider from './ForceUpdateProvider'
import ForceUpdatePage from './ForceUpdatePage'

const ForceUpdateIndex = () => {

    return (
        <ForceUpdateProvider>
            <ForceUpdatePage />
        </ForceUpdateProvider>
    )
}

export default ForceUpdateIndex