import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { Scrollbars } from 'react-custom-scrollbars';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Autocomplete from '../../../../control/autocomplete/AutoComplete';
import Button from '../../../../control/button/Button'

import DataTable from '../../../../control/table/DataTable'
import { RESULT_SAP, SAP_COLUMN } from '../../../../constants/leave/LeaveSAPConstants'
import { checkShowCancelAbsence } from '../../container/absence/AbsenceUtils'

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '33px'
    },
    label: {
        color: '#F47B15'
    },
    rootContent: {
        paddingRight: '24px',
    },
    tableClass: {
        textAlign: 'center',
        width: '100%',
        minWidth: '910px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.02) 0px -1px 0px'
    },
    containerDetail: {
        marginTop: '10px'
    },
    btnGrid: {
        textAlign: 'center',
        paddingRight: '24px'
    },
    body: {
        height: 'calc(100% - 120px)',
        marginTop: '-10px'
    },
    btn: {
        marginLeft: '1%',
        marginRight: '1%',
        width: '15%'
    },
    btnCancel: {
        marginLeft: '1%',
        marginRight: '1%',
        width: '15%',
        backgroundColor: `${theme.mainTheme.color6} !important`
    },
    btnCancelAbsence: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginLeft: '1%',
            marginRight: '1%',
            width: '15%',
            backgroundColor: `${theme.mainTheme.color3} !important`
        }
    },
    tableCell: {
        minHeight: '48px',
        height: 'auto',
        display: 'inline-flex',
        padding: '10px',
        color: '#5B5B5B',
        fontSize: '18px !important',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        alignItems: 'center',
        background: 'inherit'
    }
}));

const containerDetail = ({ absenceId, duration, all, sapSuccess, sapError, sapCancel, classes, leaveTypeName }) => {

    return (
        <Grid container spacing={2}>

            <Grid item xs={12} md={4}>
                <span className={classes.label}>{RESULT_SAP.LABEL.ABSENCE_ID}: </span> {absenceId}
            </Grid>
            <Grid item xs={12} md={4}>
                <span className={classes.label}>{RESULT_SAP.LABEL.LEAVE_NAME}: </span> {leaveTypeName}
            </Grid>
            <Grid item xs={12} md={4}>
                <span className={classes.label}>{RESULT_SAP.LABEL.LEAVE_COUNT}: </span> {duration} {RESULT_SAP.UNIT.DATE}
            </Grid>
            <Grid item xs={12} md={12}>
                <span className={classes.label}>{RESULT_SAP.LABEL.ALL}: </span> {all} {RESULT_SAP.UNIT.ITEM}
            </Grid>
            <Grid item xs={12} md={4}>
                <span className={classes.label}>{RESULT_SAP.LABEL.SAP_SUCCESS}: </span> {sapSuccess} {RESULT_SAP.UNIT.ITEM}
            </Grid>
            <Grid item xs={12} md={4}>
                <span className={classes.label}>{RESULT_SAP.LABEL.SAP_ERROR}: </span> {sapError} {RESULT_SAP.UNIT.ITEM}
            </Grid>
            <Grid item xs={12} md={4}>
                <span className={classes.label}>{RESULT_SAP.LABEL.SAP_CANCEL}: </span> {sapCancel} {RESULT_SAP.UNIT.ITEM}
            </Grid>
        </Grid>
    )
}

const containerFilter = ({ options, onLeaveSapChange }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Field
                    component={Autocomplete}
                    options={options}
                    name={RESULT_SAP.FORM.NAME.LEAVE_SAP_TRANS_TYPE}
                    label={RESULT_SAP.FORM.LABEL.LEAVE_SAP_TRANS_TYPE}
                    onChangeDropDown={onLeaveSapChange}
                />
            </Grid>
        </Grid>
    )
}

const containerTale = ({ column, dataTableState, classes, onRowClick }) => {

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    column={column}
                    data={dataTableState.dataPage}
                    classesTable={classes.tableClass}
                    page={dataTableState.page}
                    rowPerPage={dataTableState.rowPerPage}
                    total={dataTableState.total}
                    sortColumn={dataTableState.sortColumn}
                    sortType={dataTableState.sortType}
                    classes={{ tableCell: classes.tableCell }}
                    handlePageChange={dataTableState.changePage}
                    handleRowClick={onRowClick}
                    handleSortExternal={dataTableState.sortData}
                    handleRowPerPage={() => { }}
                    notSort={true}
                />
            </Grid>
        </Grid>

    )
}

const LeaveSAPDetailTransactionView = props => {
    const classes = useStyles();
    const {
        data,
        luLeaveSapType,
        dataAbsence,

        onCancelClick,
        onSubmitClick,
        onLeaveSapChange,
        dataTableState,
        onRowClick,
        onCancelAbsClick
    } = props;

    return (
        <Grid container justify="center" style={{ height: '100%' }} >
            <Grid item xs={12}>
                <span className={classes.title}>{RESULT_SAP.TITLE}</span>
            </Grid>
            <Grid
                container
                className={classes.body}
                justify="flex-start"
                alignItems="flex-start"
            >
                <Scrollbars universal={true}>
                    <Grid container className={classes.rootContent}>
                        <Grid item xs={12} className={classes.containerDetail}>
                            {containerDetail({ ...data, classes, ...dataAbsence })}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '12px', marginBottom: '5px' }}>
                            <Grid item xs={12} md={4}>
                                {containerFilter({
                                    options: luLeaveSapType,
                                    onLeaveSapChange
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            {containerTale({
                                column: SAP_COLUMN,
                                dataTableState,
                                classes,
                                onRowClick
                            })}
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Grid>


            <Grid
                container
                alignItems="flex-end"
                justify="center"
                className={classes.btnGrid}
            >
                {checkShowCancelAbsence(dataAbsence.leaveStateId) && <Button
                    label="ยกเลิกใบลา"
                    className={classes.btnCancelAbsence}
                    onClick={onCancelAbsClick}
                />}
                {data.leaveStateId === 6 || data.responseTypeId === 'C' || data.responseTypeId === 'S' ?
                    < Button
                        label="ปิด"
                        className={classes.btn}
                        onClick={onCancelClick}
                    /> : <Fragment>
                        {/* {checkShowCancelAbsence(data.leaveStateId) && <Button
                            label="ยกเลิกใบลา"
                            className={classes.btnCancelAbsence}
                            onClick={onCancelAbsClick}
                        />} */}
                        <Button
                            label="ยกเลิก"
                            className={classes.btnCancel}
                            onClick={onCancelClick}
                        />
                        <Button
                            label="บันทึก"
                            className={classes.btn}
                            onClick={onSubmitClick}
                        />
                    </Fragment>
                }

            </Grid>
        </Grid>
    );
};

export default LeaveSAPDetailTransactionView;
