import { getTokenLocalStore } from './LocalStorageUtils';

const CreateRequest_ = reqType => {
    // const bearerKey = localStorage.getItem("token");
    const bearerKey = getTokenLocalStore();
    return {
        method: reqType,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'bearer ' + bearerKey,
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    };
};

const CreateRequest = reqType => {
    return {
        method: reqType,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'th',
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    };
};

const CreateRequestWithOutApiKey = reqType => {
    return {
        method: reqType,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'th'
        }
    };
};

export const fetchPostWithOutApiKey = (url, param) => {
    const request = {
        ...CreateRequestWithOutApiKey('POST'),
        body: JSON.stringify(param)
    };

    const promise = new Promise(resolve => {
        fetch(url, request)
            .then(res => {
                if (res.ok) {
                    resolve(res);
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .catch(res => {
                resolve(res);
            });
    });

    return promise;
};

export const fetchDelWithOutApiKey = (url, param) => {
    const request = {
        ...CreateRequestWithOutApiKey('DELETE'),
        body: JSON.stringify(param)
    };

    const promise = new Promise(resolve => {
        fetch(url, request)
            .then(res => {
                if (res.ok) {
                    resolve(res);
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .catch(res => {
                resolve(res);
            });
    });

    return promise;
};


export const createParamGet = objParam => {
    let strParam = '';

    Object.keys(objParam).forEach(key => {
        if (objParam[key]) {
            if (strParam === '') {
                strParam += `${key}=${objParam[key]}`;
            } else {
                strParam += `&${key}=${objParam[key]}`;
            }
        }
    });

    return strParam;
};

export const fetchGet = url => {
    const promise = new Promise((resolve, reject) => {
        fetch(url, CreateRequest('GET'))
            .then(res => {
                resolve(res);

            })
            .catch(res => {
                resolve(res);
            });
    });

    return promise;
};

export const fetchPost = (url, param) => {
    const request = {
        ...CreateRequest('POST'),
        body: JSON.stringify(param)
    };

    const promise = new Promise(resolve => {
        fetch(url, request)
            .then(res => {
                if (res.ok) {
                    resolve(res);
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .catch(res => {
                resolve(res);
            });
    });

    return promise;
};

export const fetchGet_ = url => {
    // สำหรับยิง service ที่ต้องมี bearer
    var promise = new Promise((resolve, reject) => {
        fetch(url, CreateRequest_('GET'))
            .then(res => {
                if (res.status === 401) {
                    resolve();
                } else {
                    resolve(res);
                }
            })
            .catch(res => {
                resolve('Error!');
            });
    });

    return promise;
};

export const fetchPost_ = (url, param) => {
    // สำหรับยิง service ที่ต้องมี bearer
    const request = { ...CreateRequest_('POST'), body: JSON.stringify(param) };
    var promise = new Promise((resolve, reject) => {
        fetch(url, request)
            .then(res => {
                if (res.status === 401) {
                    resolve();
                } else {
                    resolve(res);
                }
            })
            .catch(res => {
                resolve('Error!');
            });
    });

    return promise;
};

export const fetchDel = url => {
    return fetch(url, CreateRequest('DELETE'));
};

export const fetchDel_ = (url, param) => {
    const request = {
        ...CreateRequest('DELETE'),
        body: JSON.stringify(param)
    };

    const promise = new Promise(resolve => {
        fetch(url, request)
            .then(res => {
                if (res.ok) {
                    resolve(res);
                } else {
                    throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .catch(res => {
                resolve(res);
            });
    });

    return promise;
};

export const fetchPut = (url, param) => {
    const request = { ...CreateRequest('PUT'), body: JSON.stringify(param) };
    return fetch(url, request);
};

//for fetch mapservice
export const fetchMapService = (url, formData) => {
    const promise = new Promise((resolve, reject) => {
        fetch(url, { method: 'POST', body: formData })
            .then(res => {
                resolve(res);
            })
            .catch(res => {
                resolve('Error!');
            });
    });

    return promise;
};
