import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from "redux-form";

//import component
import MtRoomDescriptionView from '../../../presentational/manageMeetingRoom/formMeeting/MtRoomDescriptionView'
import { MEETING } from '../../../../../constants/APDConstants'

const { NAME } = MEETING.FORM

const MtRoomDescription = ({
    lup,
    maintainer,
    change,
    initialColor = '#F47B15',
    openTime,
    closeTime
}) => {
    const [lutMaintainer, setLutMaintainer] = useState([])

    const updateLutMaintainer = () => {
        if (maintainer && maintainer.length) {
            const maintainerUpdate = lup.emp.filter(lutMain => !maintainer.some(main => main.empId === lutMain.empId))
            setLutMaintainer(maintainerUpdate)
        } else {
            setLutMaintainer(lup.emp)
        }
    }

    useEffect(() => {
        updateLutMaintainer()
    }, [maintainer]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateLutMaintainer()
    }, [lup.emp]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddMaintainer = async (value) => {
        if (value) {
            const isNew = maintainer.filter(item => item.empId === value.empId).length < 1
            if (isNew) {
                value.isDelete = true
                value.isSuperAdmin = false
                const newValue = [...maintainer, value];
                change(NAME.MAINTAINER, newValue)
            }
            await change(MEETING.FORM.NAME.MAINTAINER_FORM, null)
            const el = document.getElementById("search-maintainer-emp")
            if (el) el.blur();
        }
    }

    const handleRemoveMaintainer = (value) => {
        const newValue = maintainer.filter(item => item.empId !== value.empId)
        change(NAME.MAINTAINER, newValue)
    }

    const handleColor = (color) => {
        change('color', color)
    }

    const handleChangeTime = filed => value => {
        if (value) {
            switch (filed) {
                case NAME.OPEN_TIME:
                    if (closeTime && value > closeTime) {
                        change(NAME.CLOSE_TIME, value)
                    }
                    break;
                case NAME.CLOSE_TIME:
                    if (openTime && value < openTime) {
                        change(NAME.OPEN_TIME, value)
                    }
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <MtRoomDescriptionView
            lu={{ ...lup, lutMaintainer }}
            maintainer={maintainer}
            callbackColor={handleColor}
            colorInit={initialColor}

            onChangeMaintainerForm={handleAddMaintainer}
            onClickRemoveMaintainer={handleRemoveMaintainer}
            onChangeTime={handleChangeTime}
        />
    )
}

const selector = formValueSelector('MtRoomForm')

const mapStateToProps = state => ({
    maintainer: selector(state, NAME.MAINTAINER),
    openTime: selector(state, NAME.OPEN_TIME),
    closeTime: selector(state, NAME.CLOSE_TIME)
})

export default connect(mapStateToProps)(MtRoomDescription)