import { fetchGet, fetchPost, fetchPut, fetchDelWithOutApiKey, fetchPostWithOutApiKey } from '../utilities/ServiceUtils'
// import { MEETING } from '../constants/APDConstants'
import * as ApiConstants from '../constants/ApiConstants'
import { API } from '../constants/ServiceMSGConstants'

export const callServiceMeetingRoom = async (uuid, statusId) => {
    try {
        // const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_MEETING_ROOM}uuid=${uuid}&statusId=${statusId}&available=${MEETING.ROOM_AVAILABLE}`);
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_MEETING_ROOM}?uuid=${uuid}`);
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.MEETING }
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceMeetingRoom : ', err)
        return err
    }
}

export const callServiceLuRoomType = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_LU_ROOM_TYPE}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceLuRoomType : ', err)
    }
}

export const callServiceLuEquipment = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_LU_EQUIPMENT}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceLuEquipment : ', err)
    }
}

export const callServiceMEADistrict = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_MEA_DISTRICT}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceMEADistrict : ', err)
    }
}

export const callServiceLuPublicityType = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_LU_PUBLICITY_TYPE}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceLuPublicityType : ', err)
    }
}

export const callServiceLuBookingType = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_LU_BOOKING_TYPE}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceLuBookingType : ', err)
    }
}

export const callServiceBuilding = async (meaDistrictId) => {
    //MOC
    const res = {
        data: [
            { id: 'อาคารชำระค่าไฟฟ้า', name: 'อาคารชำระค่าไฟฟ้า' },
            { id: 'อาคาร 1', name: 'อาคาร 1' },
            { id: 'อาคารชำระค่าไฟ', name: 'อาคารชำระค่าไฟ' },
            { id: 'อาคารสายไฟฟ้าแรงสูง', name: 'อาคารสายไฟฟ้าแรงสูง' },
        ]
    }

    return res
}

export const callServiceOrg = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_ALL_ORG)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceOrg : ', err)
    }
}

export const callServiceEmp = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_EMP)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceEmp : ', err)
    }
}

export const callServiceSaveMeetingRoom = async (params) => {
    try {
        const fetchData = await fetchPost(ApiConstants.SERVICE_ADD_ROOM, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.MEETING }
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceSaveMeetingRoom : ', err)
        return err
    }
}

export const callServiceGetMeetingRoomByID = async (meetingRoomID, uuid) => {
    try {
        const fetchData = await fetchPut(`${ApiConstants.SERVICE_GET_MEETINGROOM_BY_ID}/${meetingRoomID}`, {
            uuid: uuid,
            apd: true
        })
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.MEETING }
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceGetMeetingRoomByID : ', err)
        return err
    }
}

export const callServiceGetAllOrgtree = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_ALL_ORG_TREE)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceGetAllOrgtree : ', err)
    }
}

export const callServiceEditMeetingRoomByID = async (meetingRoomID, param) => {
    try {
        const fetchData = await fetchPut(`${ApiConstants.SERVICE_EDIT_MEETINGROOM_BY_ID}/${meetingRoomID}`, param)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.MEETING }
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceEditMeetingRoomByID : ', err)
        return err
    }
}

export const callServiceGetSuperAdmin = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_SUPER_ADMIN)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceGetSuperAdmin : ', err)
    }
}

export const callServiceOrgDep = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_ORG_DEP)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceOrgDep : ', err)
    }
}

export const callServiceDeleteMeetingRoom = async (roomID) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_DELETE_MEETINGROOM}?roomId=${roomID}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.MEETING }
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceDeleteMeetingRoom : ', err)
        return err
    }
}

export const callServiceUploadTempImage = async ({ type, filename, thumbnails, data }) => {
    try {
        const fetchData = await fetchPostWithOutApiKey(ApiConstants.SERVICE_UPLOAD_TEMPORARY_IMAGE, {
            attachments: [
                { type, filename, thumbnails, data }
            ]
        })
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceUploadTempImage : ', err)
    }
}

export const callServiceUploadImage = async ({ type, path, thumbnails, data }) => {
    try {
        const fetchData = await fetchPostWithOutApiKey(ApiConstants.SERVICE_UPLOAD_FILE, {
            files: [
                { type, path, thumbnails, data }
            ]
        })
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceUploadImage : ', err)
    }
}

export const callServiceDeleteImage = async (files = []) => {
    try {
        const fetchData = await fetchDelWithOutApiKey(ApiConstants.SERVICE_UPLOAD_FILE, { files })
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceDeleteImage : ', err)
    }
}

export const getLutEmpByPermission = ({ permissionOrgId, depId }) => {
    return fetchGet(
        `${ApiConstants.SERVICE_GET_LU_MEETING_EMP_BY_PERMISSION}?permissionOrgId=${permissionOrgId}&depId=${depId}`
    )
        .then(res => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch(ex => {
            console.log('ex getLutEmpByPermission', ex);
        });
};


export const callServiceUploadImageMeetingRoom = async ({ path, fileName, base64Image }) => {
    try {
        const fetchData = await fetchPost(ApiConstants.SERVICE_UPLOAD_IMAGE, { path, fileName, base64Image })
        if (!fetchData.ok) {
            throw fetchData.status
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceUploadImageMeetingRoom : ', err)
    }
}

export const callServiceCoppyImageMeetingRoom = async (images = []) => {
    try {
        const fetchData = await fetchPost(ApiConstants.SERVICE_COPPY_IMAGE, { images })
        if (!fetchData.ok) {
            throw fetchData.status
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceCoppyImageMeetingRoom : ', err)
    }
}

export const callServiceDeleteImageMeetingRoom = async (images = []) => {
    try {
        const fetchData = await fetchPost(ApiConstants.SERVICE_DELETE_IMAGE, { images })
        if (!fetchData.ok) {
            throw fetchData.status
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex callServiceDeleteImageMeetingRoom : ', err)
    }
}