import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    rootPaper: {
        minHeight: 'calc(100% - 48px)',
        margin: '24px',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(98% - 48px)',
        },
        ...theme.paperPadding,
    },
    title: {
        fontSize: '33px',
    },
    form: {
        marginTop: theme.spacing(2),
    },
    divider: {
        width: '100%',
        margin: '24px 0px',
    },
    result: {
        alignSelf: 'flex-end',
        textAlign: 'end',
    },
    buttonExportAll: {
        height: '40px',
        marginBottom: '5px',
        padding: '0px',
        marginLeft: '10px',
        paddingRight: '10px',
        paddingLeft: '10px',
        backgroundColor: '#016f01 !important',
        color: '#FFFFFF',
    },
}));

const AbsenceView = (props) => {
    const { children, cntResult, onExportAbsence } = props;
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Scrollbars universal={true}>
                <Paper className={classes.rootPaper}>
                    <Grid container justify="center">
                        <Grid item xs={12} className={classes.form}>
                            {children[0]}
                        </Grid>
                        <Grid item xs={12} className={classes.divider}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.title}>
                            ตรวจสอบใบลา
                            <Button
                                variant="contained"
                                className={classes.buttonExportAll}
                                startIcon={<GetAppIcon />}
                                onClick={onExportAbsence}
                            >
                                ออกรายงาน EXCEL
                            </Button>
                        </Grid>

                        <Grid item xs={12} md={6} className={classes.result}>
                            พบผลลัพธ์ทั้งหมด {cntResult} รายการ
                        </Grid>
                        <Grid item xs={12} className={classes.table}>
                            {children[1]}
                        </Grid>
                    </Grid>
                </Paper>
            </Scrollbars>
        </Grid>
    );
};

export default AbsenceView;
