import React, { useRef } from 'react'

import TextEditorView from './TextEditorView'

const TextEditor = (props) => {
    const { input, tools } = props
    let tmpTimeoutFocusInputStatement = null; //ใช้จัดการกับการ Focus และเลื่อน Cursor ของกล่อง SQL Statement
    let refInputStatement = useRef(null);

    const moveCursorPosition = (refElement, position = 0) => {
        if (tmpTimeoutFocusInputStatement) {
            clearTimeout(tmpTimeoutFocusInputStatement)
        }

        refElement.focus();
        tmpTimeoutFocusInputStatement = setTimeout(() => {
            refElement.setSelectionRange(position, position);
        }, 50)
    }

    const inserStringDetectByCursorPosition = (refElement, inputValue, insertValue = '') => {
        const startPos = refElement.selectionStart;
        const endPos = refElement.selectionEnd;
        const startText = inputValue.substring(0, startPos);
        const endText = inputValue.substring(endPos, inputValue.length);
        return {
            newText: `${startText}${insertValue}${endText}`,
            newCursorPosotion: startPos + insertValue.length
        }
    }

    const handleInsert = (value) => (event) => {
        const { newText, newCursorPosotion } = inserStringDetectByCursorPosition(refInputStatement.current, `${refInputStatement.current.value}`, `${value}`)
        input.onChange(newText)
        moveCursorPosition(refInputStatement.current, newCursorPosotion) //เลื่อน Cursor
    }

    const handleChange = e => {
        input.onChange(e.target.value.trimStart())
    }

    return <TextEditorView
        {...props}
        inputRef={refInputStatement}
        tools={tools}
        onInsert={handleInsert}
        onChange={handleChange}
    />
}

export default TextEditor
