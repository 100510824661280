import React, { Fragment, useState, useContext, useEffect } from 'react';
import { isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserGroupById } from '../../../../api/ApiUseGroup';
import { UM_USER_GROUP } from '../../../../constants/APDConstants';
import { updateUserGroup } from '../../../../api/ApiUseGroup';
import { checkSuccessResService } from '../../../../utilities/Utils';
import { RouterContext } from '../../../../RouterProvider';
import { UserGroupContext } from './UserGroupProvider';
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';
import RouteConfig from '../../../../config/appRoute/RouteConfig';

import EditUserGroupView from '../../presentational/usergroup/EditUserGroupView';
import FormUserGroup from './FormUserGroup';

import { checkResStatusService } from '../../../../utilities/ResServiceUtils';

const { NAME } = UM_USER_GROUP.FORM_USER_GROUP;

let dirtyGlobal = false;
let userGroupId = null;

const EditUserGroup = ({ dirty, history }) => {
    const { setCallbackRouteChange } = useContext(RouterContext);
    const {
        empId,
        profile,
        lutUserGroupState,
        setResearch,
        loadingState,
        alertMessageState,
    } = useContext(UserGroupContext);
    const [initialForm, setInitailForm] = useState(null);

    useEffect(() => {
        const { state } = { ...history.location };
        if (state.userGroupId) {
            userGroupId = state.userGroupId;
            getDataForm();
            setCallbackRouteChange(handleChangeRoutePath);
            clearState();
        } else {
            history.push(RouteConfig.UM_USER_GROUP);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dirtyGlobal = dirty;
    }, [dirty]); // eslint-disable-line react-hooks/exhaustive-deps

    const getDataForm = async () => {
        loadingState.showLoading();

        let error = null;
        if (!lutUserGroupState.isSetInit) {
            error = await lutUserGroupState.setInitailLut();
        }

        if (error) {
            loadingState.hideLoading();
            alertMessageState.showAlert({
                altMsg: error,
                callbackCancel: () => {
                    alertMessageState.hideAlert();
                    backToHomePage();
                },
            });
        } else {
            const resUsergroup = await getUserGroupById(userGroupId);
            let msg = checkResStatusService(resUsergroup);
            if (resUsergroup && resUsergroup.data && !resUsergroup.data.error) {
                const listSystemFunction = getSystemFunction(
                    resUsergroup.data.systems
                );
                const data = {
                    [NAME.GROUP_NAME]: resUsergroup.data.userGroupName,
                    [NAME.PERMISSION_ORG]: resUsergroup.data.permissionOrgId,
                    [NAME.FUNCTION]: listSystemFunction,
                    [NAME.FUNCTION_DISPLAY]:
                        filterSystemFunctionDisplay(listSystemFunction),
                    [NAME.LIST_USER]: getListUser(resUsergroup.data.users),
                };
                setInitailForm(data);
            } else {
                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
                    callbackCancel: () => {
                        alertMessageState.hideAlert();
                        backToHomePage();
                    },
                });
            }
            loadingState.hideLoading();
        }
    };

    const clearState = () => {
        if (history.location.state && history.location.state.userGroupId) {
            let state = { ...history.location.state };
            delete state.userGroupId;
            history.replace({ ...history.location, state });
        }
    };

    const handleChangeRoutePath = (location) => {
        if (dirtyGlobal) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: 'ยกเลิก',
                btnCancelName: 'ไม่ยกเลิก',
                callbackConfirm: async () => {
                    await setCallbackRouteChange();
                    alertMessageState.hideAlert();
                    history.push(location.pathname);
                },
            });
            return false;
        } else {
            return true;
        }
    };

    const getListUser = (users = []) => {
        return users.map((user) => ({
            empId: user.empId,
            empName: user.name,
            uuid: user.uuid,
            orgId: user.orgIdSAP ? user.orgIdSAP : user.orgId,
            orgName: user.orgName,
            orgShortName: user.orgShortName,
            depId: user.depId,
            depName: user.depName,
            depShortName: user.depShortName,
        }));
    };

    const filterSystemFunctionDisplay = (listSystemFunction) => {
        const subFunction = listSystemFunction.filter((sysFunc) => {
            const checkText = sysFunc.match(/-/g);
            if (checkText && checkText.length === 2) {
                return true;
            } else {
                return false;
            }
        });
        return subFunction;
    };

    const getSystemFunction = (systemFunctions = [], pid) => {
        let listSystemFunction = [];

        systemFunctions.forEach((sysFunc) => {
            if (sysFunc.systemId) {
                listSystemFunction.push(sysFunc.systemId.toString());
            }
            if (sysFunc.functionId) {
                listSystemFunction.push(`${pid}-${sysFunc.functionId}`);
            }
            if (sysFunc.subFunctionId) {
                listSystemFunction.push(`${pid}-${sysFunc.subFunctionId}`);
            }
            if (sysFunc.functions && sysFunc.functions.length) {
                const listFunc = getSystemFunction(
                    sysFunc.functions,
                    sysFunc.systemId
                );
                listSystemFunction = listSystemFunction.concat(listFunc);
            }
            if (sysFunc.subFunctions && sysFunc.subFunctions.length) {
                const listSys = getSystemFunction(
                    sysFunc.subFunctions,
                    `${pid}-${sysFunc.functionId}`
                );
                listSystemFunction = listSystemFunction.concat(listSys);
            }
        });

        return listSystemFunction;
    };

    const callBackSaveSuccess = async () => {
        alertMessageState.hideAlert();
        await setResearch(true);
        history.push(RouteConfig.UM_USER_GROUP);
    };

    const handleSubmit = async (values) => {
        console.log(values);
        loadingState.showLoading();
        values.empId = empId;
        values.userGroupName = values.userGroupName.trim();
        const result = await updateUserGroup(values, userGroupId);
        let msg = checkResStatusService(result);
        if (result && checkSuccessResService(result.data)) {
            const altMsg = result.data[1]
                ? AlertMSGConstants.UPDATE_UG_MAINTAINER_FAIL
                : AlertMSGConstants.SAVE_SUCCESS;
            setCallbackRouteChange();
            alertMessageState.showAlert({
                altMsg: altMsg,
                callbackCancel: callBackSaveSuccess,
            });
        } else {
            if (
                result &&
                result.data &&
                result.data[0] &&
                result.data[0].message === 'Duplicate'
            ) {
                alertMessageState.showAlert({
                    altMsg: AlertMSGConstants.DUPLICATE_USERGROUP_NAME,
                });
            } else {
                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SAVE_FAIL,
                });
            }
        }
        loadingState.hideLoading();
    };

    const backToHomePage = () => {
        history.push(RouteConfig.UM_USER_GROUP);
    };

    const callBackConfirmCancel = async () => {
        setCallbackRouteChange();
        loadingState.showLoading();
        alertMessageState.hideAlert();
        await lutUserGroupState.resetLutEmp();
        await loadingState.hideLoading();
        backToHomePage();
    };

    const handleCancel = () => {
        if (dirty) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: 'ยกเลิก',
                btnCancelName: 'ไม่ยกเลิก',
                callbackConfirm: callBackConfirmCancel,
            });
        } else {
            backToHomePage();
        }
    };

    return (
        <Fragment>
            <EditUserGroupView>
                <FormUserGroup
                    lutUserGroupState={lutUserGroupState}
                    profile={profile}
                    initialForm={initialForm}
                    userGroupId={userGroupId}
                    callBackSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            </EditUserGroupView>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    dirty: isDirty('formUserGroup')(state),
});

export default connect(mapStateToProps)(withRouter(EditUserGroup));
