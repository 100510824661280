import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import withWidth from '@material-ui/core/withWidth';

import Button from '../../../../control/button/Button'

// import { ABSENCE } from '../../../../constants/APDConstants'
import { checkStatusCancel, checkShowCancelAbsence } from '../../container/absence/AbsenceUtils'
import { checkWidthDown } from '../../../../utilities/Utils'

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: '24px 0px 24px 24px',
        [theme.breakpoints.down('sm')]: {
            height: '72px',
            padding: '24px 0px 0px 0px'
        }
    },
    rootPanel: {
        width: '100%',
    },
    rootTab: {
        maxWidth: '100%'
    },
    wrapper: {
        alignItems: 'flex-end',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    selected: {
        backgroundColor: theme.mainTheme.color1,
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'white',
            color: 'gray',
            borderBottom: '3px solid #F47B15',
        }
    },
    flexContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    indicator: {
        display: 'none'
    },
    btnCancel: {
        width: '90%',
        color: '#FFFFFF',
        backgroundColor: theme.mainTheme.color3,
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: theme.mainTheme.color3,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    canceledMsg: {
        color: theme.mainTheme.color3,
        [theme.breakpoints.down('sm')]: {
            marginTop: '12px'
        }
    },
    tabCancelAbsence: {
        color: theme.mainTheme.color3,
        opacity: '1 !important'
    }
}))

const a11yProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const PanelModalAbsenceView = ({
    width,
    isShowCancel,
    panelActive,
    onChangePanel,
    onCancelAbsence,
    leaveState
}) => {
    const classes = useStyle()
    const direction = width === 'xs' || width === 'sm' ? "column" : "row"
    const isSmDowm = checkWidthDown(width, 'sm')
    // const statusCancelBySign = [ABSENCE.LEAVE_STATE_ID.CANCEL_APPROVE, ABSENCE.LEAVE_STATE_ID.CANCEL_PERMIT]

    const renderPanelBtnCancel = () => {
        let component = null

        if (isShowCancel) {
            if (checkStatusCancel(leaveState.leaveStateId)) {
                component = <span className={classes.canceledMsg}>ใบลาถูกยกเลิกแล้ว</span>
                // } else if (statusCancelBySign.indexOf(leaveState.leaveStateId) !== -1) {
            } else if (!checkShowCancelAbsence(leaveState.leaveStateId)) {
                component = <span className={classes.canceledMsg}>{leaveState.leaveStateName}</span>
            } else {
                component = <Button label='ยกเลิกใบลา' onClick={onCancelAbsence} className={classes.btnCancel} />
            }
        }

        return component
    }

    const renderTabBtnCancel = () => {
        let component = null

        if (isShowCancel) {
            if (checkStatusCancel(leaveState.leaveStateId)) {
                component = <Tab
                    classes={{ wrapper: classes.wrapper, selected: classes.selected, root: classes.rootTab }}
                    className={classes.tabCancelAbsence}
                    disabled
                    label='ใบลาถูกยกเลิกแล้ว'
                    {...a11yProps(2)}
                />
                // } else if (statusCancelBySign.indexOf(leaveState.leaveStateId) !== -1) {
            } else if (!checkShowCancelAbsence(leaveState.leaveStateId)) {
                component = <Tab
                    classes={{ wrapper: classes.wrapper, selected: classes.selected, root: classes.rootTab }}
                    className={classes.tabCancelAbsence}
                    disabled
                    label={leaveState.leaveStateName}
                    {...a11yProps(2)}
                />
            }
        }

        return component
    }

    return (
        <Grid container className={classes.root}>
            <Grid container direction={direction} justify="space-between" >
                <Grid item xs={12} sm={12} md={12} className={classes.rootPanel}>
                    <Tabs
                        orientation={isSmDowm ? 'horizontal' : 'vertical'}
                        variant="scrollable"
                        role="tabpanel"
                        scrollButtons={isSmDowm ? 'on' : 'off'}
                        indicatorColor="primary"
                        value={panelActive}
                        onChange={onChangePanel}
                        className={classes.tabs}
                        classes={{ indicator: classes.indicator }}
                    >
                        <Tab
                            classes={{ wrapper: classes.wrapper, selected: classes.selected, root: classes.rootTab }}
                            label="รายละเอียดใบลา"
                            {...a11yProps(0)}
                        />
                        <Tab
                            classes={{ wrapper: classes.wrapper, selected: classes.selected, root: classes.rootTab }}
                            label="ลำดับการพิจารณา/อนุญาต"
                            {...a11yProps(1)}
                        />
                        {isSmDowm && renderTabBtnCancel()}
                    </Tabs>
                </Grid>

                <Grid item md={12} container justify="center" alignItems="flex-end">
                    {!isSmDowm && renderPanelBtnCancel()}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withWidth()(PanelModalAbsenceView)
