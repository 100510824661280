export const typeNumber = (value) => {
    const patt = new RegExp(/\D/g)
    const isNumber = !patt.test(value)

    return isNumber
}

export const normalizeNumber = value => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^0-9.]/g, '')
    return onlyNums
}