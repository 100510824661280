export const MANAGE_BOOKING_PAGE = {
    BOOKING_ROOM_PAGE: 'BOOKING_ROOM_PAGE',
    BOOKING_LIST_PAGE: 'BOOKING_LIST_PAGE'
};

export const BOOKING_ROOM_PAGE = {
    SEARCH_ROOM_PAGE: 'SEARCH_ROOM_PAGE',
    CREATE_BOOKING_PAGE: 'CREATE_BOOKING_PAGE'
};

export const BOOKING_LIST_PAGE = {
    CALENDAR_PAGE: 'CALENDAR_PAGE',
    TABLE_PAGE: 'TABLE_PAGE',
    EDIT_BOOKING_PAGE: 'EDIT_BOOKING_PAGE'
};

export const BOOKING_LIST_INDEX_PAGE = {
    BUTTON_BOOKING_LABEL: 'จองห้องประชุม',
    BUTTON_SWITCH_LABEL_CALENDAR: 'รายการจองรูปแบบปฏิทิน',
    BUTTON_SWITCH_LABEL_TABLE: 'รายการจองรูปแบบตาราง'
};

export const DATE_TIME = {
    START: 'start',
    END: 'end'
};

export const FORM_SEARCH_MEETING = {
    NAME: {
        ROOM_NAME: 'roomName',
        MEA_DISTRICT: 'meaDistrict',
        SEAT: 'seat',
        START_DATE_TIME: 'startDateTime',
        END_DATE_TIME: 'endDateTime'
    },
    LABEL: {
        ROOM_NAME: 'ชื่อห้องประชุม',
        MEA_DISTRICT: 'เขต',
        SEAT: 'จำนวนที่นั่ง',
        START_DATE_TIME: 'เริ่มวันที่',
        END_DATE_TIME: 'สิ้นสุดวันที่'
    }
};

export const FORM_SEARCH_BOOKING = {
    NAME: {
        START_DATE: 'startDatetime',
        END_DATE: 'endDatetime',
        ORG: 'orgId',
        ROOM_NAME: 'roomName',
        MAINTAINER: 'maintainer',
        BUILDING_NAME: 'buildingName',
        FLOOR: 'floor',
        COFFEE_BREAK: 'coffeeBreak',
        CHAIRMAN: 'chairman',
        ATTENDEES: 'numberOfAttendees',
        TOPIC: 'topic',
        REASON: 'reason'
    },
    LABEL: {
        START_DATE: 'วัน-เวลาเริ่มต้น',
        END_DATE: 'วัน-เวลาสิ้นสุด',
        ORG: 'หน่วยงานที่ดูแล',
        ROOM_NAME: 'ชื่อห้องประชุม',
        MAINTAINER: 'พนักงานดูแลห้อง',
        BUILDING_NAME: 'ชื่ออาคาร',
        FLOOR: 'ชั้น',
        COFFEE_BREAK: 'อาหารว่าง',
        CHAIRMAN: 'ประธาน',
        ATTENDEES: 'จำนวนผู้เข้าร่วมประชุม',
        TOPIC: 'หัวข้อการประชุม',
        REASON: 'กรอกเหตุผลที่ต้องการยกเลิกรายการจองห้องประชุม'
    },
    ERROR: 'error'
};

export const FORM_BOOKING = {
    START_DATE_TIME: 'วัน-เวลาที่เริ่มต้นใช้ห้องประชุม',
    END_DATE_TIME: 'วัน-เวลาที่สิ้นสุดใช้ห้องประชุม',
    EMP_NAME: 'ผู้จองห้องประชุม',
    ORG_NAME: 'หน่วยงานที่ดูแลห้องประชุม',
    ROOM_NAME: 'ชื่อห้องประชุม',
    SEAT: 'จำนวนที่นั่ง',
    DISTRICT: 'เขต',
    BUILDING_NAME: 'อาคาร',
    FLOOR: 'ชั้น',
    TOPIC: 'หัวข้อการประชุม',
    ATTENDEES: 'จำนวนผู้เข้าร่วม',
    CHAIRMAN: 'ประธาน',
    EQUIPTMENT: 'อุปกรณ์ภายในห้อง',
    WEEKLY_BOOKING: 'ช่วงวันที่ใช้งาน',
    DESCRIPTION: 'รายละเอียดห้องประชุม',
    MAINTAINER: 'พนักงานที่ดูแลห้อง',
    CONTACT: 'รายละเอียดการติดต่อหน่วยงานที่ดูแลห้อง',
    MODIFY: 'วัน-เวลาที่ทำรายการ',
    TEL: 'เบอร์โทรผู้จอง',
    FINAL_MODIFY: 'ผู้ที่แก้ไขรายการจองล่าสุด',
    FINAL_MODIFY_DATE: 'วัน-เวลาที่แก้ไขล่าสุด',

    CANCELLED_BY: 'ผู้ที่ยกเลิกการจอง',
    CANCELLED_DATE: 'วัน-เวลาที่ยกเลิกการจอง',
    CANCEL_REASON: 'เหตุผลในการยกเลิก',

    ORG_EMP_NAME: 'ฝ่าย/หน่วยงานผู้จอง',
    NO_ANSWER: 'ไม่ระบุ',
    NO_EQUIPMENT: 'ไม่มีอุปกรณ์ภายในห้อง'
};

const TABLE = {
    TITLE: {
        CHECK: 'เลือก',
        TOPIC: 'หัวข้อการประชุม',
        DATE_BOOKING: 'วันที่จองห้อง',
        TIME_BOOKING: 'เวลาที่จองห้อง',
        ROOM_NAME: 'ชื่อห้องประชุม',
        CHAIRMAN: 'ประธาน',
        ATTENDEES: 'ผู้เข้าร่วม',
        EMP_NAME: 'ผู้จอง/หน่วยงาน/เบอร์โทร',
        MAINTAINER: 'พนักงานดูแลห้องประชุม',
        COFFEE_BREAK: 'อาหารว่าง',
        DATE_TIME: 'ช่วงเวลาที่จองห้องประชุม',
    },
    DATA: {
        CHECK: 'check',
        TOPIC: 'topicDisplay',
        DATE_BOOKING: 'dateBookingDisplay',
        TIME_BOOKING: 'timeBooking',
        ROOM_NAME: 'roomName',
        CHAIRMAN: 'chairman',
        ATTENDEES: 'numberOfAttendees',
        EMP_NAME: 'emp',
        MAINTAINER: 'maintainer',
        COFFEE_BREAK: 'coffeeBreak',
        DATE_TIME: 'dateTime',
    }
};

export const COLUMN_BOOKING = [
    {
        title: TABLE.TITLE.DATE_TIME,
        data: TABLE.DATA.DATE_TIME,
        width: '27%',
        sort: 'asc',
        align: 'center',
        type: 'date'
    },
    {
        title: TABLE.TITLE.TOPIC,
        data: TABLE.DATA.TOPIC,
        width: '20%',
        sort: 'asc',
        align: 'left'
    },
    {
        title: TABLE.TITLE.ROOM_NAME,
        data: TABLE.DATA.ROOM_NAME,
        width: '15%',
        sort: 'asc',
        align: 'left'
    },
    {
        title: TABLE.TITLE.CHAIRMAN,
        data: TABLE.DATA.CHAIRMAN,
        width: '10%',
        sort: 'asc',
        align: 'left'
    },
    {
        title: TABLE.TITLE.ATTENDEES,
        data: TABLE.DATA.ATTENDEES,
        width: '8%',
        sort: 'asc',
        align: 'center',
        type: 'number'
    },
    {
        title: TABLE.TITLE.EMP_NAME,
        data: TABLE.DATA.EMP_NAME,
        width: '20%',
        sort: 'asc',
        align: 'left'
    }
];

const TABLE_MEETING = {
    TITLE: {
        ROOM_NAME: 'ชื่อห้องประชุม',
        MEA_DISTRICT: 'เขต',
        SEAT: 'จำนวนที่นั่ง',
        DESCRIPTION: 'รายละเอียด',
        ACTION: 'จัดการ'
    },
    DATA: {
        ROOM_NAME: 'roomName',
        MEA_DISTRICT: 'meaDistrictName',
        SEAT: 'seat',
        DESCRIPTION: 'description',
        ACTION: 'action'
    }
};

export const COLUMN_MEETING = [
    {
        title: TABLE_MEETING.TITLE.ROOM_NAME,
        data: TABLE_MEETING.DATA.ROOM_NAME,
        width: '25%',
        sort: 'asc',
        align: 'left'
    },
    {
        title: TABLE_MEETING.TITLE.MEA_DISTRICT,
        data: TABLE_MEETING.DATA.MEA_DISTRICT,
        width: '15%',
        sort: 'asc',
        align: 'left'
    },
    {
        title: TABLE_MEETING.TITLE.SEAT,
        data: TABLE_MEETING.DATA.SEAT,
        width: '15%',
        sort: 'asc',
        align: 'right',
        type: 'number'
    },
    {
        title: TABLE_MEETING.TITLE.DESCRIPTION,
        data: TABLE_MEETING.DATA.DESCRIPTION,
        width: '25%',
        sort: 'asc',
        align: 'left'
    },
    {
        title: TABLE_MEETING.TITLE.ACTION,
        data: TABLE_MEETING.DATA.ACTION,
        width: '20%',
        sort: 'asc',
        align: 'center'
    }
];
