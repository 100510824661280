import React, { Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
    containerSearch: {
        position: 'absolute',
        zIndex: '2',
        top: '30px',
        left: '30px',
        width: '300px',
        boxShadow: 'rgb(128, 128, 128) 0px 1px 2px 0px',
        borderRadius: '20px',
        border: '1px solid rgb(215, 215, 215)',
        display: 'none',
        backgroundColor: 'white',
        [theme.breakpoints.down('xs')]: {
            top: '86px'
        }
    },
    textField: {
        padding: '5px 15px',
        height: '50px',
        marginLeft: '25px',
        width: 'calc(100% - 25px)'
    },
    list: {
        width: '100%',
        padding: '0px',
    },
    listItem: {
        borderWidth: '1px 0px 0px 0px',
        borderStyle: 'solid',
        borderColor: 'rgb(215, 215, 215)'
    },
    searchIcon: {
        position: 'absolute',
        top: '35px',
        left: '35px',
        color: 'gray',
        zIndex: 3,
        [theme.breakpoints.down('xs')]: {
            top: '96px'
        }
    }
}))

const SearchToolbarView = ({
    isFocus,
    items,
    onChangeSearch,
    onClilckItem,
    onFocus,
    onBlur,
    onMouseOverIcon
}) => {
    const classes = useStyles()

    return (
        <Fragment>
            <SearchIcon className={classes.searchIcon} onMouseOver={onMouseOverIcon} />
            <Grid container className={classes.containerSearch} id="container-search">
                <TextField
                    id='search-orgLeave'
                    placeholder="ค้นหา"
                    InputProps={{ disableUnderline: true }}
                    onChange={onChangeSearch}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={classes.textField}
                />
                {(isFocus && items.length > 0) && <List component="nav" className={classes.list}>
                    {items.map((item, index) => (
                        <ListItem
                            key={`${index}-${item.id}`}
                            button
                            onClick={() => onClilckItem(item.value)}
                            className={classes.listItem}
                        >
                            <ListItemText primary={item.label} />
                        </ListItem>
                    ))}
                </List>}
            </Grid>
        </Fragment>
    )
}

export default SearchToolbarView
