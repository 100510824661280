import moment from 'moment';

export const newDateBytime = time => {
    //param 07:30:00 or 07:30 or 07, return new Date()
    const dateTime = new Date();
    const times = time.split(':');

    if (times[0]) {
        dateTime.setHours(times[0]);
    } else {
        dateTime.setHours(0);
    }

    if (times[1]) {
        dateTime.setMinutes(times[1]);
    } else {
        dateTime.setMinutes(0);
    }

    if (times[2]) {
        dateTime.setSeconds(times[2]);
    } else {
        dateTime.setSeconds(0);
    }

    return dateTime;
};

export const newDateByDate = date => {
    //param 2019-11-28, return new Date()
    const [year, month, day] = date.split('-');

    return new Date(year, month - 1, day);
};

export const convertTimeIsoToUtcDate = strDate => {
    // param 1970-01-01T20:30:00.000Z, return new Date()
    // หาแนวทางอื่นที่ดีกว่านี้
    const dateIso = new Date(strDate);
    const dateUtc = new Date();

    dateUtc.setHours(dateIso.getUTCHours());
    dateUtc.setMinutes(dateIso.getUTCMinutes());
    dateUtc.setMilliseconds(dateIso.getUTCSeconds());

    return dateUtc;
};

export const convertTimeIsoToUtcDisplay = strDate => {
    // param 1970-01-01T20:30:00.000Z, return 07:30
    const dateIso = new Date(strDate);
    const house = dateIso.getUTCHours().toString();
    const minutes = dateIso.getUTCMinutes().toString();
    const houseConvert = house.length === 1 ? `0${house}` : house; // หาทางที่ดีกว่านี้
    const minutesConvert = minutes.length === 1 ? `${minutes}0` : minutes; // หาทางที่ดีกว่านี้

    return `${houseConvert}:${minutesConvert}`;
};

export const getFirstDateOfFirstMonth = date => {
    // const date = new Date();
    // const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    // return firstDay;

    const day = moment(getFirstDateOfCurentMount(date))
        .subtract(6, 'days')
        .format('YYYY-MM-DD HH:mm:ss')

    return day
};
export const getLastDateOfLastMonth = date => {
    // const date = new Date();
    // const firstDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
    // return firstDay

    const day = moment(getLastDateOfCurentMount(date))
        .add(6, 'days')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .format('YYYY-MM-DD HH:mm:ss')

    return day
};

export const getFirstDateOfCurentMount = (date = new Date()) => {
    // const date = new Date();
    // const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    return firstDay;
};

export const getLastDateOfCurentMount = (date = new Date()) => {
    // const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay;
};

export const getFirstDayAndLastDayOfMount = date => {
    if (date) {
        return {
            firstDay: new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0),
            lastDay: new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                0,
                23,
                59,
                59
            )
        };
    }
};

const _extractDate = date => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return { year, month, day };
};

export const convertDateFormatService = date => {
    //return  2018-11-01
    const dateExtract = _extractDate(date);

    const strDate = `${dateExtract.year}-${Number(dateExtract.month) + 1}-${
        dateExtract.day
        }`;
    return strDate;
};

export const NAME_OF_MONTH = [
    { id: 0, name: 'มกราคม', abbr: 'ม.ค.' },
    { id: 1, name: 'กุมภาพันธ์', abbr: 'ก.พ.' },
    { id: 2, name: 'มีนาคม', abbr: 'มี.ค.' },
    { id: 3, name: 'เมษายน', abbr: 'เม.ย.' },
    { id: 4, name: 'พฤษภาคม', abbr: 'พ.ค.' },
    { id: 5, name: 'มิถุนายน', abbr: 'มิ.ย.' },
    { id: 6, name: 'กรกฎาคม', abbr: 'ก.ค.' },
    { id: 7, name: 'สิงหาคม', abbr: 'ส.ค.' },
    { id: 8, name: 'กันยายน', abbr: 'ก.ย.' },
    { id: 9, name: 'ตุลาคม', abbr: 'ต.ค.' },
    { id: 10, name: 'พฤศจิกายน', abbr: 'พ.ย.' },
    { id: 11, name: 'ธันวาคม', abbr: 'ธ.ค.' }
];

export const convertDateDisplay = (date, isAbbr = false) => {
    //return 26 พฤศจิกายน 2562
    const newDate = new Date(date.replace(/-/g, '/'));
    const dateExtract = _extractDate(newDate);

    if (isAbbr) {
        return `${dateExtract.day} ${NAME_OF_MONTH[dateExtract.month].abbr} ${Number(dateExtract.year) + 543}`;
    } else {
        return `${dateExtract.day} ${NAME_OF_MONTH[dateExtract.month].name} ${Number(dateExtract.year) + 543}`;
    }
};

export const setZeroSeconds = date => {
    if (date) {
        date.setSeconds(0);
        date.setMilliseconds(0);
    }
    return date;
};

export const formatDateTime = dateTime => {
    const date = dateTime.getDate();
    const month = dateTime.getMonth() + 1;
    const year = dateTime.getFullYear();

    const time = dateTime.toTimeString().slice(0, 8);

    const monthStr = month.toString().length > 1 ? month : `0${month}`;
    const dateStr = date.toString().length > 1 ? date : `0${date}`;
    const dateTimeStr = `${year}-${monthStr}-${dateStr} ${time}`;

    return dateTimeStr;
};

export const formatDate = dateTime => {
    const date = dateTime.getDate();
    const month = dateTime.getMonth() + 1;
    const year = dateTime.getFullYear();

    const monthStr = month.toString().length > 1 ? month : `0${month}`;
    const dateStr = date.toString().length > 1 ? date : `0${date}`;
    const dateTimeStr = `${year}-${monthStr}-${dateStr}`;

    return dateTimeStr;
};

export const splitDateWeekend = (startDate, endDate) => { // param 2020-03-02, return [{startDate: 2020-03-02, endDate: 2020-03-04}]
    const weekdays = ["6", "7"]
    const result = []
    let currentStartDate = startDate
    let tempStartDate = startDate
    let isLast = false

    do {
        const prevCurrentDate = currentStartDate

        if (weekdays.some(item => item === moment(currentStartDate).format('E')) && currentStartDate === endDate) { // กรณีเข้ามาวันเดียว และวันนั้นเป็นวันหยุด
            isLast = true
        } else if (weekdays.some(item => item === moment(currentStartDate).format('E')) && currentStartDate !== endDate) { //กรณีวันเริ่มต้นเป็นวันหยุด
            const currentStartDateAdd = moment(currentStartDate).add(1, 'days').format('YYYY-MM-DD')
            currentStartDate = currentStartDateAdd
            tempStartDate = currentStartDateAdd
        } else if (currentStartDate !== endDate) {
            currentStartDate = moment(currentStartDate).add(1, 'days').format('YYYY-MM-DD')
            if (weekdays.some(item => item === moment(currentStartDate).format('E'))) {
                result.push({
                    startDate: tempStartDate,
                    endDate: prevCurrentDate
                })
                const currentStartDateAdd = currentStartDate === endDate ? currentStartDate : moment(currentStartDate).add(1, 'days').format('YYYY-MM-DD')
                currentStartDate = currentStartDateAdd
                tempStartDate = currentStartDate
            }
        } else { // บล็อกสุดท้าย
            isLast = true
            result.push({
                startDate: tempStartDate,
                endDate: endDate
            })
        }
    }
    while (currentStartDate !== endDate || !isLast);

    return result
}

// display date rang
// input ('2020-01-01', '2020-02-01')
// output same year => 10 มีนาคม - 12 ธันวาคม 2562, same month => 10 - 11 มีนาคม 2562 , 1 day => 10 มีนาคม 2562
export const displayDateRange = (startDate, endDate) => {
    const newStDate = new Date(startDate.replace(/-/g, '/'))
    const newEnDate = new Date(endDate.replace(/-/g, '/'))

    const displayStDate = convertDateDisplay(startDate)
    const displayEnDate = convertDateDisplay(endDate)

    const str = newStDate.getFullYear() === newEnDate.getFullYear() ?
        newStDate.getMonth() + 1 === newEnDate.getMonth() + 1 ?
            newStDate.getDate() === newEnDate.getDate() ?
                `${displayStDate}` :
                `${newStDate.getDate()} - ${displayEnDate}` : `${displayStDate.slice(0, displayStDate.length - 5)} - ${displayEnDate}` : `${displayStDate} - ${displayEnDate}`

    return str
}

export const displayDateTimeRange = (startDate, endDate, startTime, endTime) => {
    const displayStDate = convertDateDisplay(startDate, true)
    const displayEnDate = endDate && convertDateDisplay(endDate, true)

    if (startDate === endDate || !endDate) {
        return `${displayStDate} ${startTime} - ${endTime} น.`
    } else {
        return `${displayStDate} ${startTime} น. - ${displayEnDate} ${endTime} น.`
    }
}

export const displayDateTimeAbbr = (date, time) => {
    const displayDate = convertDateDisplay(date, true)

    return `${displayDate} ${time} น.`
}

export const getCurrentDate = () => {
    return convertDateDisplay(moment().format('YYYY-MM-DD'))
}

export const getCurrentYearAndLastYear = (lastyear = 0) => {
    const year = []
    const currentDate = new Date().getFullYear() + 543

    if (lastyear > 0) {
        for (let i = lastyear; i > 0; i--) {
            year.push(currentDate - i)
        }
    }

    year.push(currentDate)

    return year
}