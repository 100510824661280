import React from 'react'

import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel'

const useStyles = makeStyles(theme => ({
    checked: {
        color: `${theme.mainTheme.color1} !important`
    },
    label: {
        color: theme.mainTheme.color1,
        fontSize: theme.fontSize.body1
    },
    styleMui: {
        '& svg': {
            width: '23px'
        }
    },
    fontLabel: {
        fontSize: theme.fontSize.body1
    },
    errorText: {
        color: theme.mainTheme.color5,
        marginTop: "1px",
        marginLeft: '10px',
    },
    error: {
        color: theme.mainTheme.color5,
    },
    formHelper: {
        color: theme.mainTheme.color5,
        marginTop: "1px",
        marginLeft: '10px',
    },
    fullWidth: {
        width: '100%'
    },
    colorErr: {
        color: theme.mainTheme.color5
    }
}))

const RadioButton = (props) => {
    const classes = { ...useStyles(), ...props.classes }
    const {
        options,
        input,
        label,
        disabled,
        isAllBooking,
        required,
        meta: { error, invalid, touched },
        xs,
        sm,
        md,
        lg
    } = props

    return (
        <Grid container className={classes.fullWidth}>
            <FormControl className={classes.fullWidth} >
                <FormLabel className={`${classes.label} ${classes.fullWidth}`}>
                    <span  className={`${touched && invalid && error && classes.colorErr}`}>{label}</span>
                    {" "}
                    {required && <span className={classes.colorErr}>*</span>}
                </FormLabel>
                <RadioGroup {...input} className={classes.fullWidth} >
                    <Grid container >
                        {options.map((item, index) => {
                            return (
                                <Grid
                                    key={`RadioGroup-${item.key}-${index}`}
                                    className={classes.rootItem}
                                    item xs={xs} sm={sm} md={md} lg={lg}
                                >
                                    <FormControlLabel value={item.id} control={
                                        <Radio
                                            classes={{ checked: classes.checked }}
                                            checked={Number(input.value) === Number(item.id) && !(isAllBooking && item.id === 1)}
                                            disabled={disabled || (isAllBooking && item.id === 1)}
                                            className={classes.styleMui}
                                        />
                                    } label={item.name} classes={{ label: classes.fontLabel }} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </RadioGroup>
                <FormHelperText className={classes.formHelper}>{touched && invalid && error}</FormHelperText>
            </FormControl>
        </Grid>
    )
}

export default RadioButton