import React from "react";

import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Clear";
import UploadIcon from "@material-ui/icons/CloudUpload";
import SwipeableViews from "react-swipeable-views";

const styles = theme => ({
    root: {
        maxWidth: '550px'
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '350px',
        width: '550px',
        whiteSpace: 'nowrap',
        backgroundColor: 'gainsboro',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            height: '250px',
            width: '100%',
        }
    },
    img: {
        maxHeight: '350px',
        maxWidth: '550px',
        overflow: "hidden",
        height: "350px",
        // width: "550px"
        // height: "300px",
        // width:'auto'
        [theme.breakpoints.down('xs')]: {
            height: '250px',
            width: '100%',
        }
    },
    controlSwiper: {
        paddingTop: '50px',
        [theme.breakpoints.down('xs')]: {
            width: '100% !important'
        }
    },
    button: {
        width: "100%",
        borderStyle: "dashed",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    deleteIcon: {
        cursor: "pointer",
        color: "rgba( 0, 0, 0, 0.5)",
        position: 'absolute',
        right: '20px',
        top: '20px'
    },
    noImage: {
        width: '550px',
        height: '350px',
        background: 'gainsboro',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '40px',
        [theme.breakpoints.down('xs')]: {
            height: '250px',
            width: '100%',
        }
    },
    iconUpload: {
        marginRight: '10px'
    }
});

const SwiperImageView = ({
    classes,
    theme,
    imageList = [],
    activeStep,
    disabled,
    refUpload,
    limit,

    onBack,
    onNext,
    onDelete,
    onUpload,
    onChangeUpload
}) => {
    return (
        <div className={classes.controlSwiper}>
            <input ref={refUpload} style={{ display: 'none' }} type="file" onChange={onChangeUpload} />
            <div className={classes.root}>
                {imageList.length > 0 ? (
                    <SwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={activeStep}
                        enableMouseEvents
                    >
                        {imageList.map((image, index) => {
                            const src = image.url ? image.url : `data:image/${image.type};base64,${image.data}`

                            return (
                                <div key={index} className={classes.containerImg}>
                                    <CloseIcon
                                        className={classes.deleteIcon}
                                        onClick={() => onDelete(index)}
                                    />
                                    <img
                                        className={classes.img}
                                        // src={image.url}
                                        src={src}
                                        align="center"
                                        alt=""
                                    />
                                </div>
                            );
                        })}
                    </SwipeableViews>
                ) : <div className={classes.noImage}>ไม่มีรูป</div>}

                <MobileStepper
                    steps={imageList.length}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={onNext}
                            disabled={imageList.length === 0 || activeStep === imageList.length - 1}
                        >
                            ถัดไป
                            {theme.direction === "rtl" ? (<KeyboardArrowLeft />) : (<KeyboardArrowRight />)}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={onBack}
                            disabled={imageList.length === 0 || activeStep === 0}
                        >
                            {theme.direction === "rtl" ? (<KeyboardArrowRight />) : (<KeyboardArrowLeft />)}
                            ย้อนกลับ
                        </Button>
                    }
                />

                <Button
                    variant="outlined"
                    color="default"
                    className={classes.button}
                    onClick={onUpload}
                    type="button"
                    disabled={disabled || imageList.length >= limit}
                >
                    <UploadIcon className={classes.iconUpload} />{"เพิ่มรูป"}
                </Button>
            </div>
        </div>

    );

}

export default withStyles(styles, { withTheme: true })(SwiperImageView);
