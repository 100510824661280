import { useState } from 'react'
import { PAGE } from '../../../../../constants/um/UserConstants'

const UseUserState = () => {
    const [data, setData] = useState({
        page: PAGE.USER_GROUP,
        initialData: null
    })

    const setPage = (page, initialData) => {
        if (!page) {
            page = PAGE.USER_GROUP
        }

        setData({
            page: page,
            initialData: initialData
        })
    }


    return {
        page: data.page,
        initialData: data.initialData,

        setPage
    }
}

export default UseUserState