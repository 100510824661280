import React, { useEffect, useContext, Fragment, useRef } from 'react'

import { ConnextContext } from './ConnextProvider'
import { MODE_MODAL, API_ID } from '../../../../constants/maintenance/ConnextConstants'
import { getListMaintenance, deleteMaintenance, searchListMaintenance } from '../../../../api/ApiMaintenance'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'

import ConnextView from '../../presentational/connext/ConnextView'
import TableConnext from './TableConnext'
import ModalConnextView from '../../presentational/connext/ModalConnextView'
import AddMaintenance from './AddMaintenance'
import EditMaintenance from './EditMaintenance'
import AlertMessage from '../../../../control/alert/AlertMessage'
import Loading from '../../../../control/loading/Loading'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import 'dayjs/locale/en-gb'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import FormSearchMaintenance from './form/FormSearchMaintenance'

dayjs.extend(buddhistEra)

const Connext = () => {
    const { dataTableState, modalState, alertMessageState, loadingState } = useContext(ConnextContext)
    const strParamsRef = useRef(null)
    useEffect(() => {
        getDataTable()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getDataTable = async () => {
        loadingState.showLoading()
        const res = await getListMaintenance()
        let altMsg = checkResStatusService(res)

        loadingState.hideLoading()
        if (res && res.data) {
            const dataTable = res.data.map((data, index) => ({
                ...data,
                startDateDisplay: dayjs(data.startDate).locale('th').format('D MMM BBBB H:mm'),
                endDateDisplay: dayjs(data.endDate).locale('th').format('D MMM BBBB H:mm'),
                displayDateDisplay: dayjs(data.displayDate).locale('th').format('D MMM BBBB H:mm'),
                edit: true,
                delete: data.apiId === API_ID.CONNEXT,
                no: index + 1
            }))
            dataTableState.setInitialDataTable(dataTable)
        } else {
            alertMessageState.showAlert({ altMsg: altMsg ? altMsg : AlertMSGConstants.SYSTEM_ERROR })
        }
    }

    const handleClickAddMaintenance = () => {
        modalState.setModal(MODE_MODAL.ADD_MAINTENANCE)
    }

    const handleEdit = values => {
        modalState.setModal(MODE_MODAL.EDIT_MAINTENANCE, values)
    }

    const callbackConfirmDelete = async (maId) => {
        loadingState.showLoading()
        const res = await deleteMaintenance(maId)
        let altMsg = checkResStatusService(res)
        loadingState.hideLoading()

        if (res && res.data && res.data.message === 'success') {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.DELETE_SUCCESS,
                callbackCancel: () => {
                    alertMessageState.hideAlert()
                    // getDataTable()
                    callbackSearch(null, true)
                }
            })
        } else {
            alertMessageState.showAlert({ altMsg: altMsg ? altMsg : AlertMSGConstants.SYSTEM_ERROR })
        }
    }

    const handleDelete = values => {
        alertMessageState.showAlert({
            altType: 2,
            altMsg: "คุณต้องการลบรายการนี้ ?",
            btnConfirmName: "ลบ",
            btnCancelName: "ยกเลิก",
            callbackConfirm: () => {
                alertMessageState.hideAlert()
                callbackConfirmDelete(values.maId)
            },
        })
    }

    const callbackSaveSuccess = () => {
        modalState.setModal()
        // getDataTable()
        callbackSearch(null, true)
    }

    const renderChildrenModal = () => {
        switch (modalState.mode) {
            case MODE_MODAL.ADD_MAINTENANCE:
                return <AddMaintenance callbackSaveSuccess={callbackSaveSuccess} />
            case MODE_MODAL.EDIT_MAINTENANCE:
                return <EditMaintenance callbackSaveSuccess={callbackSaveSuccess} />
            default:
                return null
        }
    }

    const renderModal = () => {
        return (
            <ModalConnextView>
                {renderChildrenModal()}
            </ModalConnextView >
        )
    }
    const callbackSearch = async (params, isCallbackSaveSuccess = false) => {
        let strParams;
        if (isCallbackSaveSuccess) { //กรณีเรียกมาจากการกดเพิ่ม/แก้ไข/ลบ รายการปิดระบบ
            strParams = strParamsRef.current
        } else {
            strParams = params
            strParamsRef.current = params
        }
        loadingState.showLoading()
        let res = await searchListMaintenance(strParams)
        loadingState.hideLoading()
        let altMsg = checkResStatusService(res)
        if (res && res.data) {
            const dataTable = res.data.map((data, index) => ({
                ...data,
                startDateDisplay: dayjs(data.startDate).locale('th').format('D MMM BBBB H:mm'),
                endDateDisplay: dayjs(data.endDate).locale('th').format('D MMM BBBB H:mm'),
                displayDateDisplay: dayjs(data.displayDate).locale('th').format('D MMM BBBB H:mm'),
                edit: true,
                delete: data.apiId === API_ID.CONNEXT,
                no: index + 1
            }))
            dataTableState.setInitialDataTable(dataTable)
        } else {
            alertMessageState.showAlert({ altMsg: altMsg ? altMsg : AlertMSGConstants.SYSTEM_ERROR })
        }

    };

    return (
        <Fragment>
            <ConnextView >
                <FormSearchMaintenance callbackSearch={callbackSearch} />
                <TableConnext
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    onClickAddMaintenance={handleClickAddMaintenance}
                />
                {renderModal()}
            </ConnextView>
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    )
}

export default Connext
