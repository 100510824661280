

export const FORM = {
    NAME: {
        MESSAGE_TH: 'messageTH',
        MESSAGE_EN: 'messageEN',
        ERROR: 'error'
    }
}

const TABLE_TEMPLATE = {
    TITLE: {
        NO: 'ลำดับ',
        SYSTEM: 'ระบบ',
        MSG_TH: 'ข้อความภาษาไทย',
        MSG_EN: 'ข้อความภาษาอังกฤษ'
    },
    DATA: {
        NO: 'no',
        SYSTEM: 'systemNameTH',
        MSG_TH: 'messageTH',
        MSG_EN: 'messageEN'
    }
}

export const COLUMN_TEMPLATE = [
    { title: TABLE_TEMPLATE.TITLE.NO, data: TABLE_TEMPLATE.DATA.NO, width: '6%', sort: 'asc', align: 'center', type: 'number' },
    { title: TABLE_TEMPLATE.TITLE.SYSTEM, data: TABLE_TEMPLATE.DATA.SYSTEM, width: '10%', sort: 'asc', align: 'left' },
    { title: TABLE_TEMPLATE.TITLE.MSG_TH, data: TABLE_TEMPLATE.DATA.MSG_TH, width: '42%', sort: 'asc', align: 'left' },
    { title: TABLE_TEMPLATE.TITLE.MSG_EN, data: TABLE_TEMPLATE.DATA.MSG_EN, width: '42%', sort: 'asc', align: 'left' }
]
