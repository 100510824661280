import { convertDateDisplay } from '../../../../utilities/DatetimeUtils';

export const formatResult = data => {
    const newFormat = data.map(item => {
        item.bookingList.map(itmB => {
            itmB.timeBooking = `${itmB.startTime} - ${itmB.endTime} น.`;
            itmB.internalTel =
                itmB.telInternalPrefix && itmB.telInternalSuffix
                    ? `${itmB.telInternalPrefix}-${itmB.telInternalSuffix}`
                    : '-';

            const splitModify = itmB.modifyDate.split(' ');
            itmB.dateModify = convertDateDisplay(splitModify[0]);
            itmB.timeModify = `${new Date(itmB.modifyDate.replace(/-/g, '/'))
                .toTimeString()
                .slice(0, 5)} น.`;

            itmB.numberOfAttendees = itmB.numberOfAttendees
                ? itmB.numberOfAttendees
                : '-';
            itmB.chairman = itmB.chairman ? itmB.chairman : '-';
            itmB.empBooking = `${itmB.empName} (${itmB.orgShtName}) ${
                itmB.telInternalPrefix && itmB.telInternalSuffix
                    ? `${itmB.telInternalPrefix}-${itmB.telInternalSuffix}`
                    : ''
                }`;
            itmB.orgName = itmB.orgName ? itmB.orgName : '-';

            return itmB;
        });

        return item;
    });

    console.log('newFormat', newFormat)
    return newFormat;
};
