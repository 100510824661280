const yearDropdownPlugin = function (pluginConfig) {
    // console.log('pluginConfig', pluginConfig)
    const defaultConfig = {
        text: '',
        theme: "light",
        date: new Date(),
        yearStart: new Date().getFullYear(),
        yearEnd: new Date().getFullYear() + 2,
    };

    const config = {}
    for (const key in defaultConfig) {
        config[key] = pluginConfig && pluginConfig[key] !== undefined ? pluginConfig[key] : defaultConfig[key]
    }

    const convertFormatDateToFunction = async (value) => {
        const day = value.getDate()
        const month = value.getMonth() + 1
        const year = value.getFullYear()

        return `${day}/${month}/${year}`
    }

    const getYear = async (value) => {
        const dt = typeof (value) === 'string' ? value : await convertFormatDateToFunction(value)
        const date = dt.split("/")

        return parseInt(date[2], 10)
    }

    const currYear = new Date().getFullYear()
    const selectedYear = getYear(config.date)

    let yearDropdown = document.createElement("select")
    yearDropdown.id = pluginConfig.name


    const createSelectElement = async (year) => {
        const start = new Date().getFullYear() - 5
        const end = currYear + 5

        for (let i = end; i >= start; i--) {
            let option = document.createElement("option")
            option.value = i
            option.text = i + 543
            option.id = i
            yearDropdown.appendChild(option)
        }
        yearDropdown.value = await selectedYear
        // yearDropdown.value = 2025
    };

    // console.log('pass')

    return function (fp) {
        fp.yearSelectContainer = fp._createElement(
            "div",
            "flatpickr-year-select " + config.theme + "Theme",
            config.text
        )

        fp.yearSelectContainer.tabIndex = -1
        createSelectElement(selectedYear)

        // console.log('yearSelectContainer', fp.yearSelectContainer)
        yearDropdown.addEventListener('change', function (evt) {
            const year = evt.target.options[evt.target.selectedIndex].value
            fp.changeYear(year)
        })

        fp.yearSelectContainer.append(yearDropdown)

        return {
            onReady: function onReady() {
                const name = fp.monthNav.className
                const yearInputCollection = fp.calendarContainer.getElementsByClassName(name)
                const el = yearInputCollection[0]
                el.parentNode.insertBefore(fp.yearSelectContainer, el.parentNode.firstChild)
            }
        };
    };
}

export default yearDropdownPlugin