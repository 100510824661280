import React from 'react'
import { Route, Redirect } from 'react-router-dom';

import LeaveCollectPage from './LeaveCollect'

import RouteConfig from '../../../../config/appRoute/RouteConfig'
import LeaveCollectProvider from './LeaveCollectProvider'

const LeaveCollectIndex = () => {
    return (
        <LeaveCollectProvider>
            <Route exact key={RouteConfig.LEAVE_COLLECT} path={RouteConfig.LEAVE_COLLECT} component={LeaveCollectPage} />
            <Redirect to={RouteConfig.LEAVE_COLLECT} />
        </LeaveCollectProvider> 
    )
}

export default LeaveCollectIndex
