import React, { createContext } from 'react';
import { connect } from 'react-redux';

import UseLutAbsenceState from './useAbsenceState/UseLutAbsenceState';
import UseDataTableState from '../../../../control/table/UseDataTableState';
import UseLoadingState from '../../../../control/loading/UseLoadingState';
import UseAbsenceState from './useAbsenceState/UseAbsenceState';
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';
import UsePanelState from './useAbsenceState/UsePanelState';
import { PermissionSubFuncUtils } from '../../../../utilities/PermissionUtils';
import { getDepId } from '../../../../utilities/Utils';

import { SUB_FUNCTION } from '../../../../constants/APDConstants';

export const AbsenceContext = createContext();

const AbsenceProvider = ({ children, authenRdc }) => {
    const { permissionOrgId, empId } = authenRdc.profile;

    const depIdEmp = getDepId(authenRdc.profile);
    const lutAbsenceState = UseLutAbsenceState({
        permissionOrgId,
        depId: depIdEmp,
    });
    const loadingState = UseLoadingState();
    const alertMessageState = UseAlertMessageState();
    const absenceState = UseAbsenceState();
    const panelState = UsePanelState();
    const dataTableState = UseDataTableState({
        rowPerPage: 10,
        specialColumn: {
            absenceId: 'numberOrder',
            leaveDate: 'startDate',
        },
    });
    const permissionSystem = {
        permisEdit: PermissionSubFuncUtils(SUB_FUNCTION.EDIT_ABSENCE),
        permisCancel: PermissionSubFuncUtils(SUB_FUNCTION.CANCEL_ABSENCE),
        permisDetail: PermissionSubFuncUtils(SUB_FUNCTION.VIEW_ABSENCE),
    };

    const value = {
        lutAbsenceState,
        dataTableState,
        loadingState,
        alertMessageState,
        absenceState,
        panelState,

        permissionSystem,
        permissionOrgId,
        depId: depIdEmp,
        empId,
    };

    return (
        <AbsenceContext.Provider value={value}>
            {children}
        </AbsenceContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    authenRdc: state.authen,
});

export default connect(mapStateToProps)(AbsenceProvider);
