import Excel from 'exceljs';
import { convertDateDisplay, getCurrentDate } from '../../../../../utilities/DatetimeUtils';

export default (datasource, date, type) => {
    const startRowHeader = 1; //ใช้กำหนดแถวที่เป็นหัวเรื่อง
    const startRowHeaderTable = 3; //ใช้กำหนดแถวที่จะเป็นหัวตาราง
    const workbook = new Excel.Workbook(); //สร้าง instance
    const worksheet = workbook.addWorksheet('sheet', {
        //จำเป็นต้องตั้งค่าส่วนนี้
        pageSetup: {
            paperSize: 9, //follow documentation the 9 value is A4
            orientation: 'landscape' //แนวตั้ง
        }
    });
    worksheet.name = 'รายงานใบลาที่ได้รับผลกระทบ';
    workbook.creator = 'APD-WEB'; //ผู้สร้าง
    workbook.lastModifiedBy = 'APD-WEB'; //ผู้แก้ไขล่าสุด

    const headerTable =
        type === 'log'
            ? [
                { key: 'absenceId', name: 'ใบลาที่', width: 15 },
                { key: 'empId', name: 'รหัสพนักงาน', width: 12 },
                { key: 'empName', name: 'ชื่อ-นามสกุล พนักงาน', width: 40 },
                { key: 'OrgName', name: 'หน่วยงาน', width: 30 },
                { key: 'deptOrgName', name: 'ฝ่าย/หน่วยงาน', width: 30 },
                { key: 'startDate', name: 'วันที่เริ่มลา', width: 20 },
                { key: 'endDate', name: 'วันที่สิ้นสุดลา', width: 20 },
                { key: 'leaveTypeName', name: 'ประเภทการลา', width: 20 },
                { key: 'impactDate', name: 'วันที่เปลี่ยนแปลง', width: 20 },
                { key: 'reason', name: 'สาเหตุ', width: 10 },
                { key: 'refHolidayId', name: 'รหัสอ้างอิงวันหยุด', width: 10 }
            ]
            : [
                { key: 'absenceId', name: 'ใบลาที่', width: 15 },
                { key: 'empId', name: 'รหัสพนักงาน', width: 12 },
                { key: 'empName', name: 'ชื่อ-นามสกุล พนักงาน', width: 40 },
                { key: 'deptOrgName', name: 'ฝ่าย/หน่วยงาน', width: 30 },
                { key: 'startDate', name: 'วันที่เริ่มลา', width: 20 },
                { key: 'endDate', name: 'วันที่สิ้นสุดลา', width: 20 },
                { key: 'leaveTypeName', name: 'ประเภทการลา', width: 20 },
                { key: 'impactDate', name: 'วันที่มีผลกระทบ', width: 20 }
            ];

    const downloadProcess = () => {
        workbook.xlsx.writeBuffer().then(function (xls64data) {
            const blob = new Blob([xls64data], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            document.body.appendChild(anchor); //for firefox ref: https://stackoverflow.com/questions/41805095/excel-export-not-working-on-firefox-but-working-fine-in-google-crome/41806544
            anchor.href = url;
            anchor.download = 'รายงานใบลาที่ได้รับผลกระทบ.xlsx';
            var isIE = /*@cc_on!@*/ false || !!document.documentMode;
            var isEdge = !isIE && !!window.StyleMedia;
            if (isEdge) {
                anchor.click();
                anchor.change();
            } else {
                anchor.click();
            }

            // if (window.navigator.msSaveBlob) {
            //     window.navigator.msSaveBlob(blob, 'LogAbsence')
            // } else {
            //     anchor.click()
            //     anchor.change();
            // }

            window.URL.revokeObjectURL(url); //เมื่อใช้ URL.createObjectURL แล้วต้องเรียกคำสั่งนี้เพื่อให้ Browser รู้ว่าจะไม่เก็บ Reference file อีกต่อไป
        });
    };

    const setCellBorder = (row, colKey) => {
        row.getCell(colKey).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    };

    const generateHeader = () => {
        const dateDisplay = convertDateDisplay(date);

        const columns = headerTable.length;

        worksheet.mergeCells(startRowHeader, 1, startRowHeader, columns);
        const row = worksheet.getRow(startRowHeader);
        // row.getCell(columns).value = `รายงานใบลาที่มีผลกระทบ`;
        row.getCell(columns).value = `รายงานใบลาที่มีผลกระทบกับวันหยุดที่ ${dateDisplay}`;
        row.getCell(columns).alignment = {
            vertical: 'middle',
            horizontal: 'center'
        };
        // worksheet.mergeCells(
        //     startRowHeader + 1,
        //     1,
        //     startRowHeader + 1,
        //     columns
        // );
        // const rowDate = worksheet.getRow(startRowHeader + 1);
        // rowDate.getCell(
        //     columns
        // ).value = `ใบลาที่มีผลกระทบวันหยุดที่ ${dateDisplay}`;
        // rowDate.getCell(columns).alignment = {
        //     vertical: 'middle',
        //     horizontal: 'center'
        // };
    };

    const generateColumns = () => {
        worksheet.columns = headerTable.map(item => {
            return {
                key: item.key,
                width: item.width
            };
        });
    };

    const generateTableHeader = () => {
        generateColumns();
        const startRowTableHeader = startRowHeaderTable;

        const row = worksheet.getRow(startRowTableHeader);
        headerTable.forEach(item => {
            row.getCell(item.key).value = item.name;
            row.getCell(item.key).alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            row.getCell(item.key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '004A4A4A'
                }
            };
            row.getCell(item.key).font = {
                color: { argb: '00FFFFFF' }
            };
            setCellBorder(row, item.key);
        });
    };

    const generateTableContent = () => {
        datasource.forEach((item, index) => {
            item.no = index + 1;
            worksheet.addRow(item);

            //set style
            const lastRowNumber = worksheet.lastRow._number;
            const row = worksheet.getRow(lastRowNumber);
            worksheet.lastRow._cells.forEach(cell => {
                setCellBorder(row, cell.col);
                row.getCell(cell.col).alignment = {
                    wrapText: true,
                    vertical: 'top'
                };
                if (item.no % 2 !== 0) {
                    row.getCell(cell.col).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: '00DCDCDC'
                        }
                    };
                }
            });
        });
    };

    const generateReportDate = () => {
        const columns = headerTable.length;
        const startRow = startRowHeader + 1

        worksheet.mergeCells(startRow, 1, startRow, columns);
        const row = worksheet.getRow(startRow);
        row.getCell(columns).value = `วันที่ออกรายงาน ${getCurrentDate()}`;
        row.getCell(columns).alignment = {
            vertical: 'middle',
            horizontal: 'center'
        };
    }

    const generateTable = () => {
        generateTableHeader();
        generateTableContent();
    };

    const generateContentProcess = () => {
        generateHeader();
        generateReportDate();
        generateTable();
    };

    const mainProcess = () => {
        generateContentProcess();
        downloadProcess();
    };

    mainProcess();
};
