import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { UM_USER_GROUP } from '../../../../constants/APDConstants';

import DataTable from '../../../../control/table/DataTable';

const { TABLE, DATA } = UM_USER_GROUP.TABLE_USER_GROUP

const column = [
    { title: TABLE.NAME, data: DATA.NAME, width: '20%', sort: 'asc', align: 'left' },
    { title: TABLE.LIST_SYSTEM, data: DATA.LIST_SYSTEM, width: '35%', sort: 'asc', align: 'left' },
    { title: TABLE.PERMISSION_ORG, data: DATA.PERMISSION_ORG, width: '35%', sort: 'asc', align: 'left' },
    { title: TABLE.MEMBER, data: DATA.MEMBER, width: '10%', sort: 'asc', type: 'number' },
]

const useStyles = makeStyles(theme => ({
    tableCell: {
        padding: '20px'
    }
}))

const TableUserGroupView = ({
    dataTableState,
    onRowClick
}) => {
    const classes = useStyles()

    return (
        <DataTable
            // notScroll={true}
            column={column}
            data={dataTableState.dataPage}
            page={dataTableState.page}
            rowPerPage={dataTableState.rowPerPage}
            total={dataTableState.total}
            sortColumn={dataTableState.sortColumn}
            sortType={dataTableState.sortType}
            classes={{ tableCell: classes.tableCell }}

            handlePageChange={dataTableState.changePage}
            handleRowClick={onRowClick}
            handleSortExternal={dataTableState.sortData}
            handleRowPerPage={() => { }}
        />
    )
}

export default TableUserGroupView 
