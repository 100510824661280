import React, { Fragment, useContext } from 'react'

import { UserGroupContext } from './UserGroupProvider'
import AlertMessage from '../../../../control/alert/AlertMessage'
import Loading from '../../../../control/loading/Loading'

const UtilsPage = () => {
    const { loadingState, alertMessageState } = useContext(UserGroupContext)

    return (
        <Fragment>
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    )
}

export default UtilsPage
