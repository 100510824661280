import React from 'react';

import RouteConfig from '../../config/appRoute/RouteConfig';
import { FUNCTIONS } from '../../constants/APDConstants';
import AssignmentIcon from '@material-ui/icons/Assignment';

const menu = [
    {
        functionId: FUNCTIONS.EMPLOYEE_PROFILE_EDITING_HISTORY.id,
        icon: <AssignmentIcon />,
        label: 'ประวัติการเปลี่ยนแปลงข้อมูล',
        to: RouteConfig.EMPLOYEE_PROFILE_EDITING_HISTORY,
    },
];

export default menu;
