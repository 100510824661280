import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReplyIcon from '@material-ui/icons/Reply';

const variantIcon = {
    EDIT_ORG: HighlightOffIcon,
    DETAIL_ORG: HighlightOffIcon,
    EDIT_EMP: ReplyIcon
};

const useStyle = makeStyles(theme => ({
    root: {
        position: 'absolute',
        width: '400px',
        backgroundColor: 'white',
        top: '0px',
        right: '0px',
        height: '100%',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.8)',
        display: 'block',
        zIndex: 201,
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none',
            zIndex: 201,
            width: '100%',
            height: 'calc(100% - 40px)'
        }
    },
    appBar: {
        boxShadow: 'none',
    },
    regularToolbar: {
        minHeight: '50px'
    },
    menuButton: {
        padding: '7px'
    },
    iconBack: {
        fontSize: '24px',
        color: 'white'
    },
    title: {
        width: 'calc(100% - 25px)',
        color: 'white',
        textAlign: 'center',
        fontSize: '22px',
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        maxWidth: '325px'
    },
    content: {
        height: 'calc(100% - 50px)'
    }
}))

const PanelView = ({
    panelActive,
    title,
    children,
    onClickBack,
}) => {
    const classes = useStyle();
    const Icon = panelActive ? variantIcon[panelActive] : HighlightOffIcon

    return (
        <Slide direction="left" timeout={300} in={!(panelActive === undefined)} mountOnEnter unmountOnExit>
            <Grid container className={classes.root} >
                <Grid xs={12} item>
                    <AppBar position="static" className={classes.appBar}>
                        <Toolbar classes={{ regular: classes.regularToolbar }}>
                            <IconButton edge="start" className={classes.menuButton} onClick={onClickBack} color="inherit" aria-label="menu">
                                <Icon className={classes.iconBack} />
                            </IconButton>
                            <div className={classes.title}>
                                {title}
                            </div>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Grid xs={12} item className={classes.content}>
                    {children}
                </Grid>
            </Grid>
        </Slide>
    )
}

export default PanelView
