import moment from 'moment';
import { sortBy } from 'lodash';
import { convertDateDisplay, displayDateTimeRange, displayDateTimeAbbr } from '../../../../utilities/DatetimeUtils';

const checkIsMaintainer = (empId, maintainers) => {
    const isMaintainer = maintainers.findIndex(item => {
        return item.empId === empId;
    });
    return isMaintainer === -1 ? false : true;
};

export const formatResult = (data, empId) => {
    const result = [];

    data.forEach(item => {
        if (checkIsMaintainer(empId, item.maintainers)) {
            const dateCancelBooking = moment(item.modifyDate).format('YYYY-MM-DD');
            const timeCancelBooking = moment(item.modifyDate).format('HH:mm')
            const startBookingTime = item.startDate === dateCancelBooking
                ? item.startTime
                : item.openTime;
            const endBookingTime = item.endDate === dateCancelBooking
                ? item.endTime
                : item.closeTime;

            result.push({
                ...item,
                dateForSort: moment(`${item.startDate} ${item.startTime}`),
                startTime:
                    item.startDate === dateCancelBooking
                        ? item.startTime
                        : item.openTime,
                endTime:
                    item.endDate === dateCancelBooking
                        ? item.endTime
                        : item.closeTime,
                timeBooking: `${startBookingTime} - ${endBookingTime} น.`,
                date: item.startDate,//ไม่แน่ใจใช้ที่ไหน??
                dateBookingDisplay: displayDateTimeRange(item.startDate, item.endDate, item.startTime, item.endTime),//displayDateRange(item.startDate, item.endDate),//`${convertDateDisplay(item.startDate)} - ${convertDateDisplay(item.endDate)}`,
                detail: true,
                equipment: item.equipments,
                maintainer: item.maintainers,
                startDateTime: `${convertDateDisplay(item.startDate)} เวลา ${item.startTime} น.`,
                endDateTime: `${convertDateDisplay(item.endDate)} เวลา ${item.endTime} น.`,
                empName: `${item.empNameTH} (${item.empId})`,
                empOrgName: (item.deptOrgFullNameTH && item.deptOrgFullNameTH !== '') ? item.deptOrgFullNameTH : item.empOrgNameTH,
                modifyEmp: `${item.modifyByEmpNameTH} (${item.modifyByEmpId})`, //ชื่อผู้ยกเลิก
                createDate: `${item.createDate.length > 0 &&
                    convertDateDisplay(
                        item.createDate
                    )} เวลา ${item.createDate.slice(
                        11,
                        item.createDate.length
                    )} น.`,
                modifyDate: `${convertDateDisplay(item.modifyDate)} เวลา ${item.modifyDate.slice(11, item.modifyDate.length)} น.`,
                modifyDateExport: displayDateTimeAbbr(dateCancelBooking, timeCancelBooking),
                roomName: item.roomNameTH,
                district: item.meaDistrictNameTH,
                orgName: item.roomOrgNameTH,
                contact: item.roomContact.length > 0 ? item.roomContact : '-',
                description: item.roomDescription.length > 0 ? item.roomDescription : '-',
                chairman: item.chairman ? item.chairman : "-",
                numberOfAttendees: item.numberOfAttendees ? item.numberOfAttendees : "-",
                // tel: item.tel ? item.tel : "-",
                tel: (item.telInternalPrefix && item.telInternalSuffix) ? `${item.telInternalPrefix}-${item.telInternalSuffix}` : '-',
                topic: item.topic ? item.topic : "-",
                reason: item.reason ? item.reason : "-",
                emp: `${item.empNameTH} (${
                    item.deptOrgId
                        ? item.deptOrgShortNameTH
                        : item.empOrgShortNameTH
                    }) ${
                    item.telInternalPrefix && item.telInternalSuffix
                        ? `${item.telInternalPrefix}-${item.telInternalSuffix}`
                        : ''
                    } `,

            });

        }
    });

    const sortData = sortBy(result, 'date', 'timeBooking');
    return sortData;
};


export const formatResultForExport = (data, empId) => {
    const result = [];

    data.forEach(item => {
        if (checkIsMaintainer(empId, item.maintainers)) {
            item.cancelDates.forEach(cancelDateItm => {

                const cancelBooking = moment(cancelDateItm.timestamp).format(
                    'YYYY-MM-DD'
                );
                const startBookingTime = item.startDate === cancelDateItm.date
                    ? item.startTime
                    : '00:00';
                const endBookingTime = item.endDate === cancelDateItm.date
                    ? item.endTime
                    : '23:59';

                result.push({
                    ...item,
                    dateBookingExport: convertDateDisplay(cancelDateItm.date),
                    dateCancelExport: convertDateDisplay(cancelBooking),

                    timeBooking: `${startBookingTime} - ${endBookingTime} น.`,
                    date: cancelDateItm.date,
                    dateBookingDisplay: convertDateDisplay(cancelDateItm.date),
                    detail: true,
                    equipment: item.equipments,
                    maintainer: item.maintainers,
                    startDateTime: `${convertDateDisplay(item.startDate)} เวลา ${item.startTime} น.`,
                    endDateTime: `${convertDateDisplay(item.endDate)} เวลา ${item.endTime} น.`,
                    empName: `${item.empNameTH} (${item.empId})`,
                    empOrgName: (item.deptOrgFullNameTH && item.deptOrgFullNameTH !== '') ? item.deptOrgFullNameTH : item.empOrgNameTH,
                    modifyEmp: `${cancelDateItm.empNameTH} (${cancelDateItm.cancelBy})`, //ชื่อผู้ยกเลิก
                    createDate: `${item.createDate.length > 0 &&
                        convertDateDisplay(
                            item.createDate
                        )} เวลา ${item.createDate.slice(
                            11,
                            item.createDate.length
                        )} น.`,
                    modifyDate: `${convertDateDisplay(
                        cancelDateItm.timestamp
                    )} เวลา ${cancelDateItm.timestamp.slice(
                        11,
                        cancelDateItm.timestamp.length
                    )} น.`,
                    roomName: item.roomNameTH,
                    district: item.meaDistrictNameTH,
                    orgName: item.roomOrgNameTH,
                    contact: item.roomContact.length > 0 ? item.roomContact : '-',
                    description: item.roomDescription.length > 0 ? item.roomDescription : '-',
                    chairman: item.chairman ? item.chairman : "-",
                    numberOfAttendees: item.numberOfAttendees ? item.numberOfAttendees : "-",
                    tel: item.tel ? item.tel : "-",
                    topic: item.topic ? item.topic : "-",
                    reason: item.reason ? item.reason : "-"

                });
            });
        }
    });

    const sortData = sortBy(result, 'date', 'timeBooking');

    return sortData;
};
