import React from 'react';

import FindInPageIcon from '@material-ui/icons/FindInPage'
import Calendar from '@material-ui/icons/CalendarToday'
import Table from '@material-ui/icons/TableChart'
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import RouteConfig from '../../config/appRoute/RouteConfig'
import { FUNCTIONS } from '../../constants/APDConstants'

const LeaveMenu = [
    {
        functionId: FUNCTIONS.ORG_LEAVE.id,
        icon: <NaturePeopleIcon />,
        label: 'จัดการโครงสร้างการลา',
        to: RouteConfig.LEAVE_ORG
    },
    {
        functionId: FUNCTIONS.HOLIDAY.id,
        icon: <Calendar />,
        label: 'จัดการวันหยุด',
        to: RouteConfig.LEAVE_HOLIDAY
    },
    {
        functionId: FUNCTIONS.LEAVE_SAP.id,
        icon: <Table />,
        label: 'ประวัติการนำเข้า SAP',
        to: RouteConfig.LEAVE_SAP,
        pathActive: [
            RouteConfig.LEAVE_SAP,
            RouteConfig.LEAVE_SAP_EDIT,
            RouteConfig.LEAVE_SAP_DETAIL
        ]
    },
    {
        functionId: FUNCTIONS.LEAVE_COLLECT.id,
        icon: <Table />,
        label: 'ประวัติการนำข้อมูลสะสมวันลาเข้า SAP',
        to: RouteConfig.LEAVE_COLLECT,
    },
    {
        functionId: FUNCTIONS.ABSENCE.id,
        icon: <FindInPageIcon />,
        label: 'จัดการใบลา',
        to: RouteConfig.LEAVE_ABSENCE
    },
    {
        functionId: '-1',
        icon: <LiveHelpIcon />,
        label: 'คู่มือ',
        to: window.URL_USERMANUAL.LEAVE
    },
];

export default LeaveMenu 
