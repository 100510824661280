import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Hidden from '@material-ui/core/Hidden';

import Button from '../../control/button/Button';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh'
    },
    image: {
        backgroundImage: 'url(./side-login.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    leftContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        padding: theme.spacing(0, 3)
    },
    rightLogin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        margin: theme.spacing(4, 0, 0, 0),
        textAlign: 'center'
    },
    subheader: {
        margin: theme.spacing(1, 0, 1, 0),
        textAlign: 'center'
    },
    card: {
        width: '50%',
        minWidth: 300,
        maxWidth: 420,
        borderRadius: '25px',
        boxShadow: '5px 5px 11px #dedddd'
    },
    cardContent: {
        padding: theme.spacing(2, 4)
    },
    forgot: {
        margin: theme.spacing(1, 0, 1, 0),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    link: {
        margin: theme.spacing(1),
        cursor: 'pointer'
    },
    linkAction: {
        width: '100%',
        textAlign: 'center',
        textDecoration: 'none'
    },
    button: {
        width: '100%'
    },
    rootCardAction: {
        alignItems: 'center',
        flexDirection: 'column'
    },
    headerText: {
        fontWeight: '600',
        fontSize: '60px',
        textShadow: '2px 2px #000000',
        [theme.breakpoints.down('sm')]: {

        }
    },
    HeaderStyle: {
        fontWeight: '600'
    },
    subHeaderStyle: {
        fontSize: '19px',
        fontWeight: '500'
    }
}));

function LoginView({ onSignInWithSmart, href }) {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Hidden xsDown>
                <Grid item xs={false} sm={5} md={4} className={classes.image}>
                    <div className={classes.leftContent}>
                        <Typography variant="h4" gutterBottom color="secondary" className={classes.headerText}>
                            ยินดีต้อนรับเข้าสู่ APD System Management
                        </Typography>
                        <Typography variant="h6" color="secondary">
                            แอพพลิเคชันสำหรับผู้ดูแลระบบ สามารถทำงานร่วมกันภายในองค์กรได้อย่างมีประสิทธิภาพ
                        </Typography>
                    </div>
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={7} md={8} className={classes.rightLogin}>
                <Card elevation={1} className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.header}>
                            <Typography variant="h4" component="h1" className={classes.HeaderStyle}>
                                LOGIN 
                            </Typography>
                        </div>
                        <div className={classes.subheader}>
                            <Typography variant="caption" className={classes.subHeaderStyle}>
                                กรุณากดที่ปุ่มด้านล่างนี้ เพื่อเข้าสู่ระบบผ่าน Smart Office
                            </Typography>
                        </div>
                        {/* <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <div className={classes.forgot}>
                            <div>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    forgot your password ?
                                </Typography>
                            </div>
                            <MaterialLink
                                color="inherit"
                                variant="body2"
                                className={classes.link}
                            >
                                click here
                            </MaterialLink>
                        </div> */}
                        <a href={href}>
                            <CardActions
                                classes={{ root: classes.rootCardAction }}
                            >
                                <Button
                                    label="LOGIN WITH SMART OFFICE"
                                    // onClick={onSignInWithSmart}
                                    className={classes.button}
                                />
                            </CardActions>
                        </a>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default LoginView;
