import React, { createContext, useState } from 'react'
import { connect } from 'react-redux'

import { PermissionSubFuncUtils } from '../../../../utilities/PermissionUtils'
import { SUB_FUNCTION } from '../../../../constants/APDConstants'

import UseDataTableState from '../../../../control/table/UseDataTableState'
import UseSearchLeaveSAPState from './useleavesap/UseSearchLeaveSAPState'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseLoadingState from '../../../../control/loading/UseLoadingState'

export const LeaveSAPContext = createContext()

const LeaveSAPProvider = ({ children, authenRdc }) => {
    const [isResearch, setIsReSearch] = useState(false);
    const rowPerPage = 10
    const searchLeaveSAPState = UseSearchLeaveSAPState()
    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();
    const dataTableState = UseDataTableState({
        rowPerPage: rowPerPage,
        specialColumn: {
            submitDateDisplay: 'submitDate',
            leaveDate: 'sortLeaveDate',
            responseTypeIdDisplay: 'responseTypeIdDisplaySort',
            absenceId: 'numberOrder'
        }
    });
    const permissionSystem = {
        permisEdit: PermissionSubFuncUtils(SUB_FUNCTION.EDIT_LEAVE_SAP),
        permisCancel: PermissionSubFuncUtils(SUB_FUNCTION.CANCEL_LEAVE_SAP),
        permisDetail: PermissionSubFuncUtils(SUB_FUNCTION.DETAIL_LEAVE_SAP)
    };

    const value = {
        isResearch,
        profile: authenRdc.profile,
        permissionSystem,
        setIsReSearch,

        searchLeaveSAPState,
        dataTableState,
        alertMessageState,
        loadingState
    }

    return (
        <LeaveSAPContext.Provider value={value} >
            {children}
        </LeaveSAPContext.Provider>
    )
}

const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default connect(mapStateToProps)(LeaveSAPProvider)
