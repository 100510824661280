import React from 'react'

import Absence from './Absence'
import AbsenceProvider from './AbsenceProvider'

const AbsenceIndex = () => {
    return (
        <AbsenceProvider>
            <Absence />
        </AbsenceProvider>
    )
}

export default AbsenceIndex
