import React from 'react'

import CopyrightIcon from '@material-ui/icons/Copyright'
import { makeStyles } from '@material-ui/core/styles'
// import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        fontSize: '15px',
        color: theme.mainTheme.color6,
        marginTop: '10px',
        marginBottom: '10px'
    },
    icon: {
        height: '12px !important',
        width: '12px !important'
    }
}))

const Footer = () => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div>
                <CopyrightIcon className={classes.icon} /> Copyright 2020
            </div>
            <div>
                The Metropolitan Electricity Authority. All rights reserved.
            </div>
            <div>
                version {process.env.REACT_APP_APD_VERSION}
            </div>
        </div>
    )
}

export default Footer