import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';

import { CONTROL_TEXTFIELD } from '../../constants/APDConstants';

import { usePrevious } from '../StateFunctions';
import Focus from '../focus/Focus';
import * as FocusAction from '../../actions/FocusAction';

const useStylesTextFields = makeStyles(theme => ({
    gridTextFields: {
        height: '90px'
    },
    root: {
        [`& input`]: {
            height: theme.field.height,
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        width: '100%',
        boxShadow: theme.field.boxShadow
    },
    rootError: {
        [`& fieldset`]: {
            borderColor: theme.mainTheme.color5
        },
        [`& input`]: {
            height: theme.field.height,
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        width: '100%',
        boxShadow: theme.field.boxShadow
    },
    errorText: {
        color: theme.mainTheme.color5,
        marginTop: '1px',
        marginLeft: '10px'
    },
    error: {
        color: theme.mainTheme.color5
    }
}));

export const TextFields = props => {
    const classes = useStylesTextFields();
    const {
        label,
        placeholder,
        type,
        focusName,
        input,
        labelColor,
        required,
        meta: { error, touched, submitError, active, submitFailed, invalid },
        isMultiline,
        row,
        maxLength,
        focusAction,
        disabled,
        onTextChange,
        onKeyDown,
        inputRef
    } = props;
    const isError = (error && submitError) || (touched && error) ? true : false;
    const prevProp = usePrevious(active);

    const focus = Focus();
    if (focusName === input.name) {
        const focusSuccess = focus.focusFn(
            !prevProp && !active && touched && invalid && submitFailed,
            focusName,
            input.name
        );
        if (focusSuccess) {
            focusAction.setDefault();
        }
    }

    const manageLength = value => {
        let newValue = value;
        if (value.length > maxLength) {
            newValue = value.slice(0, maxLength);
        }

        return newValue;
    };

    return (
        <Fragment>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    className={classes.gridTextFields}
                    style={row && { height: row * 45 }}
                >
                    {label && <Grid
                        item
                        xs={12}
                        style={{ color: isError ? 'red' : labelColor }}
                    >
                        {label}{' '}
                        {required && <span style={{ color: 'red' }}>*</span>}
                    </Grid>}
                    <Grid item xs={12}>
                        <TextField
                            {...input}
                            placeholder={placeholder.toString()}
                            type={type}
                            variant="outlined"
                            className={
                                isError ? classes.rootError : classes.root
                            }
                            multiline={isMultiline}
                            error={isError}
                            rows={row}
                            onKeyDown={onKeyDown}
                            onChange={({ target: { value } }) => {
                                const nv = value.trimStart()
                                input.onChange(manageLength(nv));
                                if (onTextChange) {
                                    onTextChange(nv);
                                }
                            }}
                            inputRef={inputRef ? inputRef : input => focus.manageDataFocus(input)}
                            disabled={disabled}
                        />
                    </Grid>
                    <FormHelperText
                        className={classes.errorText}
                        style={
                            (error && submitError) || (touched && error)
                                ? { display: 'block' }
                                : { display: 'none' }
                        }
                    >
                        {error}
                    </FormHelperText>
                </Grid>
            </Grid>
        </Fragment>
    );
};

TextFields.defaultProps = {
    label: CONTROL_TEXTFIELD.DEFAULT_LABEL,
    placeholder: CONTROL_TEXTFIELD.DEFAULT_PLACEHOLDER,
    type: CONTROL_TEXTFIELD.DEFAULT_TYPE,
    labelColor: CONTROL_TEXTFIELD.DEFAULT_LABEL_COLOR,
    typeText: CONTROL_TEXTFIELD.DEFAULT_TYPE_TEXT
};

const mapStateTopProps = state => ({
    focusName: state.focus.name
});

const mapDispatchToProps = dispatch => ({
    focusAction: bindActionCreators(FocusAction, dispatch)
});

export default connect(mapStateTopProps, mapDispatchToProps)(TextFields);

//-------------------------------------------------------------------------------------

const useStyleNormal = makeStyles(theme => ({
    normalTextFields: {
        '& input': {
            // height: '2px',
            width: '36px',
            height: theme.field.height,
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        }
    },
    inputProps: {
        '& input': {
            // height: theme.field.height,
            // padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        }
    },
    body1: {
        '& p': {
            fontSize: theme.fontSize.body1
        }
    }
}));

export const TextFieldWithUnit = props => {
    const { disabled, unit, input } = props;

    const classes = useStyleNormal();

    return (
        <Fragment>
            <Grid container>
                <TextField
                    {...input}
                    disabled={disabled}
                    className={classes.inputProps}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className={classes.body1}
                            >
                                {unit}
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
        </Fragment>
    );
};

export const NormalTextFields = props => {
    const classes = useStyleNormal();

    const { disabled, placeholder, input, onInputChange } = props;

    return (
        <Fragment>
            <TextField
                {...input}
                disabled={disabled}
                className={classes.normalTextFields}
                variant="outlined"
                onChange={({ target: { value } }) =>
                    input.onChange(onInputChange(value))
                }
                placeholder={placeholder}
            />
        </Fragment>
    );
};
