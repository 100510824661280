import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontSize: 20,
        fontFamily: 'DB_Ozone',
        useNextVariants: true
    },
    palette: {
        primary: {
            main: '#F47B15',
            dark: '#e46d08',

        },
        secondary: {
            main: '#ddd'
        }
    },
    mainTheme: {
        //สีทีมทั้งโปรเจค
        color1: "#F47B15",//ส้ม 
        color2: "#ddd",
        color3: "#862633", //แดงเลือดหมู
        color4: "#016f01", // เขียวเข้ม
        color5: '#FF0505', // error red
        color6: '#808080', //icon
        color7: '#0000FF', // link
    },
    fontSize: {
        h1: '',
        h2: '',
        h3: '',
        h4: '',

        subTitle1: '',
        subTitle2: '',

        body1: '20px',
        body2: '18px',
    },
    field: {
        height: '40px',
        padding: '0px 15px 0px 15px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 7px 0px'
    },
    form: {
        spacing: {
            paddingTop: `0 !important`,
            paddingBottom: `0 !important`
        }
    },
    paperPadding: {
        padding: '2.5%'
    }
});

export default theme;
