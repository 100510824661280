import { fetchGet, fetchPut, fetchDel_, fetchPost } from '../utilities/ServiceUtils'
import * as ApiConstants from '../constants/ApiConstants'
import { API } from '../constants/ServiceMSGConstants'

export const getAlluser = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_ALL_USER}?${new Date().getTime()}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.APD }
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex getAlluser : ', err)
        return err
    }
}

export const getAllUserGroup = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_ALL_USER_GROUP}?${new Date().getTime()}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex getAllUserGroup : ', err)
    }
}

export const updateUser = async (userId, userGroupId, empId) => {
    try {
        const fetchData = await fetchPut(`${ApiConstants.SERVICE_UPDATE_USER}/${userId}`, {
            userGroupId: userGroupId,
            empId: empId
        })
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.APD }
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex updateUser : ', err)
        return err
    }
}

export const deleteUser = async (userId, empId) => {
    try {
        const fetchData = await fetchDel_(`${ApiConstants.SERVICE_DELETE_USER}/${userId}`, {
            empId: empId
        })
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.APD }
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex deleteUser : ', err)
        return err
    }
}

export const getUserInGroup = async (userGroupId) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_USER_GROUP_BY_ID}?userGroupId=${userGroupId}&&${new Date().getTime()}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex getUserInGroup : ', err)
    }
}

export const insertUser = async (params) => {
    try {
        const fetchData = await fetchPost(`${ApiConstants.SERVICE_INSERT_USER}`, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.APD }
        }
        const res = await fetchData.json()
        return res
    } catch (err) {
        console.log('ex insertUser : ', err)
        return err
    }
}