import { APP } from '../constants/APDConstants';

export const getTokenLocalStore = () => {
    return sessionStorage.getItem(APP.KEY_TOKEN_LOCAL) //ถ้าไม่มีจะคืนค่าเป็น null
}

export const setTokenLocalStore = token => {
    sessionStorage.setItem(APP.KEY_TOKEN_LOCAL, token);
}

export const clearTokenLocalStore = () => {
    sessionStorage.removeItem(APP.KEY_TOKEN_LOCAL)
}