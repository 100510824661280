import { useState } from 'react'

const UseModalState = () => {
    const [dataEdit, setDataEdit] = useState()

    // const [dataEdit, setDataEdit] = useState({
    //     messageId: 1001,
    //     messageTH: 'ฝ่ายพัฒนาระบบงานประยุกต์ (ฝพป.) ขอแจ้งให้ทราบว่า ท่านจะไม่สามารถยื่นใบลา หรืออนุมัติใบลา ได้ {maintenanceDate} เนื่องจากมีการปรับปรุงระบบ ข้อมูลวันลาของท่าน ที่แสดงเป็นข้อมูลที่ท่านเคยโหลดไว้ล่าสุด จะอัพเดทอีกครั้งหลังจากเปิดระบบ จึงขออภัยมา ณ ที่นี้',
    //     messageEN: 'Please be informed that Leave Request and Leave Approve Function are out of service {maintenanceDate}, due to regular system maintenance. Your day-off information will update again after turning on the system. Please accept our apology for any inconveniences that may cause.'
    // })

    return {
        dataEdit,
        setDataEdit
    }
}

export default UseModalState
