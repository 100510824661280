export const TITLE_SEARCH = 'กำหนดการประชุม ประจำวัน';
export const DATE = 'date';
export const PREV = 'เมื่อวาน';
export const NEXT = 'พรุ่งนี้';
export const NOW = 'วันปัจจุบัน';

export const FILTER_FORM = {
    NAME: {
        BUILDING: 'building',
        FLOOR: 'floor',
        DISTRICT: 'district',
        SOURCE: 'source'
    },
    LABEL: {
        BUILDING: 'อาคาร',
        FLOOR: 'ชั้น',
        DISTRICT: 'เขตการไฟฟ้า'
    }
};

export const BUILDING = 'อาคาร';
export const FLOOR = 'ชั้น';

export const TABLE = {
    TITLE: {
        TIME: 'เวลาที่จอง',
        TOPIC: 'หัวข้อการประชุม',
        NUMBER_OF_ATTENDEES: 'จำนวนผู้เข้าร่วมประชุม',
        ROOM_NAME: 'ชื่อห้องประชุม',
        CHAIRMAN: 'ประธาน',
        EMP_BOOKING: 'ผู้จองห้องประชุม',
        MODIFY_DATE: 'วัน-เวลาที่แก้ไข',
        REMARK: 'หมายเหตุ',
        EMP_NAME: 'ผู้จองห้องประชุม'
    },
    DATA: {
        TIME: 'timeBooking',
        TOPIC: 'topic',
        NUMBER_OF_ATTENDEES: 'numberOfAttendees',
        CHAIRMAN: 'chairman',
        MODIFY_DATE: 'modifyDate',
        remark: 'remark',
        ROOM_NAME: 'roomNameTH',
        EMP_NAME: 'empBooking'
    }
};

export const COLUMN = [
    {
        title: TABLE.TITLE.TIME,
        data: TABLE.DATA.TIME,
        width: '10%',
        sort: 'asc',
        align: 'center'
    },
    {
        title: TABLE.TITLE.TOPIC,
        data: TABLE.DATA.TOPIC,
        width: '20%',
        sort: 'asc',
        align: 'center'
    },
    {
        title: TABLE.TITLE.NUMBER_OF_ATTENDEES,
        data: TABLE.DATA.NUMBER_OF_ATTENDEES,
        width: '10%',
        sort: 'asc',
        align: 'center'
    },
    {
        title: TABLE.TITLE.ROOM_NAME,
        data: TABLE.DATA.ROOM_NAME,
        width: '10%',
        sort: 'asc',
        align: 'center'
    },
    {
        title: TABLE.TITLE.CHAIRMAN,
        data: TABLE.DATA.CHAIRMAN,
        width: '10%',
        sort: 'asc',
        align: 'center'
    },
    {
        title: TABLE.TITLE.EMP_NAME,
        data: TABLE.DATA.EMP_NAME,
        width: '15%',
        sort: 'asc',
        align: 'center'
    },
    {
        title: TABLE.TITLE.MODIFY_DATE,
        data: TABLE.DATA.MODIFY_DATE,
        width: '15%',
        sort: 'asc',
        align: 'center'
    }
];

export const SOURCE = {
    all: 'ALL',
    maintainer: 'maintainer'
}

export const OPTION_SOURCE = [
    { label: 'ทั้งหมด', value: SOURCE.all },
    { label: 'เฉพาะที่ดูแล', value: SOURCE.maintainer }
]
