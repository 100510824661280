import { useState } from 'react';

import {
    getBookingList,
    // callServiceOrgDep
} from '../../../../../api/ApiBooking';
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'
import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'

const UseBookingSearchState = ({ loadingState, alertMessageState }) => {
    const [valueSearch, setValueSearch] = useState({
        values: {},
        strParams: ''
    });
    const [resultSearch, setResultSearch] = useState([]);
    const [dateTime, setDateTime] = useState({ start: null, end: null });
    const [durationHours, setDurationHours] = useState(null)

    const searchBooking = stringParams => {
        loadingState.showLoading();
        getBookingList(stringParams).then(res => {
            if (res && res.data) {
                setResultSearch(res.data);
            } else {
                const msg = checkResStatusService(res)
                alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
            }
            loadingState.hideLoading();
        });
    };

    return {
        valueSearch,
        resultSearch,
        dateTime,
        durationHours,

        searchBooking,
        setValueSearch,
        setDateTime,
        setDurationHours
    };
};

export default UseBookingSearchState;
