import React from 'react';
import { Field } from 'redux-form';
import { Grid, makeStyles } from '@material-ui/core';
import classnames from 'classnames';

import { ABSENCE, PERMISSION_ORG } from '../../../../../constants/APDConstants';
import { keyEnterSubmitForm } from '../../../../../utilities/FormUtils';

import TextFields from '../../../../../control/textfield/TextFields';
import Button from '../../../../../control/button/Button';
import AutoComplete from '../../../../../control/autocomplete/AutoComplete';
// import DatePicker from '../../../../../control/datetime/DatePicker';
import DatePicker from '../../../../../control/fdatetime/DateflatPicker';
import AutoCompleteMultiSelect from '../../../../../control/autocomplete/AutoCompleteMultiSelect';
import ErrorField from '../../../../../control/errorField/ErrorField';

const useStyles = makeStyles((theme) => ({
    btnSearch: {
        width: '105px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 0,
            marginBottom: '5px',
            marginTop: '5px',
        },
    },
    btnReset: {
        width: '105px',
        marginRight: '8px',
        backgroundColor: `${theme.mainTheme.color6} !important`,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 0,
            marginBottom: '5px',
            marginTop: '5px',
        },
    },
    spacing: {
        ...theme.form.spacing,
    },
    btnGrid: {
        textAlign: 'end',
        paddingTop: '10px',
        alignSelf: 'center',
    },
    btnGridMargin: {
        marginBottom: '14px',
    },
    fieldError: {
        marginRight: theme.spacing(2),
    },
}));

const FormSearchAbsenceView = ({
    permissionOrgId,
    lutLeaveState,
    lutLeaveType,
    lutOrg,
    lutLeaveFlag,
    lutYear,
    listSelectedLeaveSapWaitID = [],
    defaultValue,
    onSubmit,
    onResetClick,
    onChangeDate,
    onClickCheckbox,
}) => {
    const classes = useStyles();
    const isPersonalOrg = permissionOrgId === PERMISSION_ORG.PERSONAL_ORG;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={2} className={classes.spacing} container>
                <Grid item xs={5} >
                    <Field
                        name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.ABSENCE_ID}
                        label={ABSENCE.FORM_SEARCH_ABSENCE.LABEL.ABSENCE_ID}
                        component={TextFields}
                        placeholder="1"
                        onKeyDown={keyEnterSubmitForm(onSubmit)}
                        maxLength={20}
                    />
                </Grid>
                <Grid item xs={1} container justify='center' alignItems='center'>
                    {"/"}
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.YEAR}
                        options={lutYear}
                        component={AutoComplete}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} className={classes.spacing}>
                <Field
                    name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.LEAVE_STATE_ID}
                    label={ABSENCE.FORM_SEARCH_ABSENCE.LABEL.LEAVE_STATE_ID}
                    component={AutoComplete}
                    options={lutLeaveState}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.spacing}>
                <Field
                    name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.LEAVE_TYPE_ID}
                    label={ABSENCE.FORM_SEARCH_ABSENCE.LABEL.LEAVE_TYPE_ID}
                    options={lutLeaveType}
                    component={AutoComplete}
                />
            </Grid>
            {!isPersonalOrg && (
                <Grid item xs={12} md={6} lg={4} className={classes.spacing}>
                    <Field
                        name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.ORG_ID}
                        label={ABSENCE.FORM_SEARCH_ABSENCE.LABEL.ORG_ID}
                        component={AutoComplete}
                        optionKey={{
                            label: 'name',
                            value: 'orgId',
                            abbr: 'nameSht',
                        }}
                        options={lutOrg}
                    />
                </Grid>
            )}
            <Grid item xs={12} md={3} lg={2} className={classes.spacing}>
                <Field
                    id="start-date-search-absence"
                    name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.START_DATE}
                    label={ABSENCE.FORM_SEARCH_ABSENCE.LABEL.START_DATE}
                    component={DatePicker}
                    onChange={onChangeDate(
                        ABSENCE.FORM_SEARCH_ABSENCE.NAME.START_DATE
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2} className={classes.spacing}>
                <Field
                    id="end-date-search-absence"
                    name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.END_DATE}
                    label={ABSENCE.FORM_SEARCH_ABSENCE.LABEL.END_DATE}
                    component={DatePicker}
                    onChange={onChangeDate(
                        ABSENCE.FORM_SEARCH_ABSENCE.NAME.END_DATE
                    )}
                    rightMos={isPersonalOrg}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.spacing}>
                <Field
                    name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.NAME}
                    label={ABSENCE.FORM_SEARCH_ABSENCE.LABEL.NAME}
                    component={TextFields}
                    placeholder="รหัสพนักงานหรือชื่อ-นามสกุล ผู้ยื่นใบลา"
                    onKeyDown={keyEnterSubmitForm(onSubmit)}
                    maxLength={100}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.spacing}>
                <Field
                    name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.NAME_SIGN}
                    label={ABSENCE.FORM_SEARCH_ABSENCE.LABEL.NAME_SIGN}
                    component={TextFields}
                    onKeyDown={keyEnterSubmitForm(onSubmit)}
                    placeholder="รหัสพนักงานหรือชื่อ-นามสกุล ผู้พิจารณา/อนุญาต"
                    maxLength={100}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.spacing}>
                <Field
                    name={
                        ABSENCE.FORM_SEARCH_ABSENCE.NAME.LEAVE_WAIT_SAP_STATE_ID
                    }
                    label={
                        ABSENCE.FORM_SEARCH_ABSENCE.LABEL
                            .LEAVE_WAIT_SAP_STATE_ID
                    }
                    options={lutLeaveFlag}
                    component={AutoCompleteMultiSelect}
                    listSelectedCheckbox={listSelectedLeaveSapWaitID}
                    onClickCheckbox={onClickCheckbox}
                    defaultValue={defaultValue}
                    optionKey={{
                        label: 'name',
                        value: 'id',
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={isPersonalOrg ? 6 : 12}
                lg={isPersonalOrg ? 12 : 8}
                container
                justify="flex-end"
                alignItems="center"
                className={classnames(classes.btnGrid, {
                    [classes.btnGridMargin]: isPersonalOrg,
                })}
            >
                <Grid item className={classes.fieldError}>
                    <Field
                        name={ABSENCE.FORM_SEARCH_ABSENCE.NAME.ERROR}
                        component={ErrorField}
                    />
                </Grid>
                {/* <Grid item> */}
                <Button
                    className={classes.btnReset}
                    onClick={onResetClick}
                    label="ค่าเริ่มต้น"
                />
                <Button
                    className={classes.btnSearch}
                    onClick={onSubmit}
                    label="ค้นหา"
                />
                {/* </Grid> */}
            </Grid>
        </Grid >
    );
};

export default FormSearchAbsenceView;
