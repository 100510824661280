import { fetchGet, fetchPut } from '../utilities/ServiceUtils';
import * as ApiConstants from '../constants/ApiConstants';
import { API } from '../constants/ServiceMSGConstants'

export const getAppVersion = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_APP_VERSION}?${new Date().getTime()}`);
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.CONNEXT };
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex getAppVersion : ', err);
        return err
    }
};

export const updateAppVersion = async (params) => {
    try {
        const fetchData = await fetchPut(`${ApiConstants.SERVICE_UPDATE_APP_VERSION}`, params)
        if (!fetchData.ok) {
            throw { status: res.status, api: API.CONNEXT };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex updateAppVersion : ', err)
        return err
    }
}
