import React, { Fragment, useEffect, useContext } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { SUB_FUNCTION } from '../../../../../constants/APDConstants';
import FormModalSearchMeetingRoomView from '../../../presentational/booking/form/FormModalSearchMeetingRoomView';
import {
    getLutMeetingRoom,
    getLutDep
} from '../../../../../api/ApiLookup';
import { BookingContext } from '../BookingProvider'
import { PermissionSubFuncUtils } from '../../../../../utilities/PermissionUtils';

const FormModalSearchMeetingRoom = props => {
    const {
        isOpen,
        onCloseFormClick,
        handleSubmit,
        profile,
        // change,
        initialize,
        onClickSubmitFormSearch
    } = props;
    // const [lutOrgOption, setLutOrgOption] = useState([]);
    // const [lutMeetingRoomOption, setMeetingRoomOption] = useState([]);
    // const [listSelectedMeetingRoomID, setListSelectedMeetingRoomID] = useState(
    //     []
    // ); //useState([3470,2310])
    // const [
    //     defaultSelectedMeetingRoom,
    //     setDefaultSelectedMeetingRoom
    // ] = useState([]);

    const { formSearchCalendarState } = useContext(BookingContext)
    const {
        lutOrgOption,
        setLutOrgOption,
        lutMeetingRoomOption,
        setMeetingRoomOption,
        listSelectedMeetingRoomID,
        setListSelectedMeetingRoomID,
        defaultSelectedMeetingRoom,
        setDefaultSelectedMeetingRoom,
        searchData,
        setSearchData,
        lutMeetingRoomRef
    } = formSearchCalendarState

    const permissionSystem = {
        permisSearchByOrg: PermissionSubFuncUtils(
            SUB_FUNCTION.SEARCH_BOOKING_ROOM_BY_ORG
        )
    };

    // const lutMeetingRoomRef = useRef([]);

    useEffect(() => {
        if (!lutMeetingRoomRef.current || !lutMeetingRoomRef.current.length) {
            setLookup();
        }

        if (searchData) {
            initialize(searchData)
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     return () => {
    //         clearFormValues();
    //     };
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const setLookup = async () => {
        const { empId } = profile;
        const lutDepResponse = await getLutDep();
        const lutMeetingRoomResponse = await getLutMeetingRoom(empId);
        if (lutDepResponse && lutDepResponse.data) {
            lutDepResponse.data.forEach(org => {
                org.fullName = `${org.name} (${org.nameSht})`;
            });
            setLutOrgOption(lutDepResponse.data);
        }
        if (lutMeetingRoomResponse && lutMeetingRoomResponse.data) {
            lutMeetingRoomResponse.data.forEach(room => {
                room.nameForDisplay = `${room.roomName} (อาคาร${room.building} ชั้น${room.floorDisplay})`;
            });
            setMeetingRoomOption(lutMeetingRoomResponse.data);
            lutMeetingRoomRef.current = lutMeetingRoomResponse.data;
        }
    };
    const handleClickSearch = async formValues => {
        if (onClickSubmitFormSearch) {
            onClickSubmitFormSearch(formValues, listSelectedMeetingRoomID);
            setSearchData(formValues)
        }
    };
    const handleClickClose = () => {
        if (onCloseFormClick) {
            onCloseFormClick();
        }
    };

    const handleClickCheckbox = (
        listSelectedMeetingRoomID,
        listSelcectedMeetingRoom
    ) => {
        setDefaultSelectedMeetingRoom(listSelcectedMeetingRoom);
        setListSelectedMeetingRoomID(listSelectedMeetingRoomID);
    };

    // const clearFormValues = () => {
    //     //  clear meetingroom  //
    //     setListSelectedMeetingRoomID([]);
    //     setDefaultSelectedMeetingRoom([]);

    //     // clear org //
    //     change('org', null);
    // };

    const handleChangeOrg = value => {
        //  clear meetingroom  //
        setListSelectedMeetingRoomID([]);
        setDefaultSelectedMeetingRoom([]);

        if (value) {
            const filterMeetingRoomOption = lutMeetingRoomRef.current.filter(
                itm => itm.orgId === value.orgId
            );
            setMeetingRoomOption(filterMeetingRoomOption);
        } else {
            setMeetingRoomOption(lutMeetingRoomRef.current);
        }
    };

    return (
        <Fragment>
            <FormModalSearchMeetingRoomView
                isOpen={isOpen}
                onCloseFormClick={handleClickClose}
                onSubmitClick={handleSubmit(handleClickSearch)}
                lutOrgOption={lutOrgOption}
                lutMeetingRoomOption={lutMeetingRoomOption}
                listSelectedMeetingRoomID={listSelectedMeetingRoomID}
                onClickCheckbox={handleClickCheckbox}
                permisSearchByOrg={permissionSystem.permisSearchByOrg}
                defaultValue={defaultSelectedMeetingRoom}
                onChangeDropDown={handleChangeOrg}
            />
        </Fragment>
    );
};

// const validateExport = values => {
//     // const errors = {}
//     // if(!values.date) {
//     //     errors.date = ERRORS.REQUIRED
//     // }
//     // return errors
// };

const selector = formValueSelector('FormModalSearchMeetingRoom');

const mapStateToProps = state => ({
    orgValue: selector(state, 'org')
});

const reduxFormParameter = {
    form: 'FormModalSearchMeetingRoom'
    // validate: validateExport
};

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps)
)(FormModalSearchMeetingRoom);
