import * as actionTypes from '../constants/ActionTypes'

const initialState = {
    name: ''
}

export default ( state = initialState, action = {}) => {
    
    switch(action.type) {
        case actionTypes.SET_FOCUS_TEXTFIELD_NAME: {
            return {
                ...state, 
                name: action.name
            }
        }
        case actionTypes.SET_DEFAULT: {
            return {
                ...state,
                name: ''
            }
        }

        default: {
            return state
        }
    }

}