import React, { useContext, useEffect } from 'react';
import { isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { saveUserGroup } from '../../../../api/ApiUseGroup';
import { checkSuccessResService } from '../../../../utilities/Utils';
import { RouterContext } from '../../../../RouterProvider';
import { UserGroupContext } from './UserGroupProvider';
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';
import RouteConfig from '../../../../config/appRoute/RouteConfig';

import AddUserGroupView from '../../presentational/usergroup/AddUserGroupView';
import FormUserGroup from './FormUserGroup';

import { checkResStatusService } from '../../../../utilities/ResServiceUtils';

let dirtyGlobal = false;

const AddUserGroup = ({ dirty, history }) => {
    const { setCallbackRouteChange } = useContext(RouterContext);
    const {
        lutUserGroupState,
        empId,
        profile,
        loadingState,
        alertMessageState,
        setResearch,
    } = useContext(UserGroupContext);

    const setIniLut = async () => {
        loadingState.showLoading();
        const error = await lutUserGroupState.setInitailLut();
        loadingState.hideLoading();

        if (error) {
            alertMessageState.showAlert({
                altMsg: error,
                callbackCancel: () => {
                    alertMessageState.hideAlert();
                    backToHomePage();
                },
            });
        }
    };

    useEffect(() => {
        setCallbackRouteChange(handleChangeRoutePath);

        if (!lutUserGroupState.isSetInit) {
            setIniLut();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dirtyGlobal = dirty;
    }, [dirty]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeRoutePath = (location) => {
        if (dirtyGlobal) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: 'ยกเลิก',
                btnCancelName: 'ไม่ยกเลิก',
                callbackConfirm: async () => {
                    await setCallbackRouteChange();
                    alertMessageState.hideAlert();
                    history.push(location.pathname);
                },
            });
            return false;
        } else {
            return true;
        }
    };

    const callBackSaveSuccess = async () => {
        alertMessageState.hideAlert();
        await setResearch(true);
        history.push(RouteConfig.UM_USER_GROUP);
    };

    const handleSubmit = async (values) => {
        values.empId = empId;
        values.userGroupName = values.userGroupName.trim();

        loadingState.showLoading();
        const result = await saveUserGroup(values);
        let msg = checkResStatusService(result);
        if (checkSuccessResService(result.data)) {
            setCallbackRouteChange();
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.SAVE_SUCCESS,
                callbackCancel: callBackSaveSuccess,
            });
        } else {
            if (
                result.data &&
                result.data[0] &&
                result.data[0].message === 'Duplicate'
            ) {
                alertMessageState.showAlert({
                    altMsg: AlertMSGConstants.DUPLICATE_USERGROUP_NAME,
                });
            } else {
                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SAVE_FAIL,
                });
            }
        }
        loadingState.hideLoading();
    };

    const backToHomePage = () => {
        history.push(RouteConfig.UM_USER_GROUP);
    };

    const callBackConfirmCancel = async () => {
        loadingState.showLoading();
        alertMessageState.hideAlert();
        await setCallbackRouteChange();
        await lutUserGroupState.resetLutEmp();
        await loadingState.hideLoading();
        backToHomePage();
    };

    const handleCancel = () => {
        if (dirty) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: 'ยกเลิก',
                btnCancelName: 'ไม่ยกเลิก',
                callbackConfirm: callBackConfirmCancel,
            });
        } else {
            backToHomePage();
        }
    };

    return (
        <AddUserGroupView>
            <FormUserGroup
                lutUserGroupState={lutUserGroupState}
                profile={profile}
                callBackSubmit={handleSubmit}
                onCancel={handleCancel}
            />
        </AddUserGroupView>
    );
};

const mapStateToProps = (state) => ({
    dirty: isDirty('formUserGroup')(state),
});

export default connect(mapStateToProps)(withRouter(AddUserGroup));
