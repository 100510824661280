import React, { Fragment, useContext } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose'

import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants';
import {
    callServiceSaveMeetingRoom,
    callServiceDeleteImageMeetingRoom,
    callServiceUploadImageMeetingRoom
} from '../../../../../api/ApiMeeting'
import { RouterContext } from '../../../../../RouterProvider'
import { setFormatValueImage, getPathImageMeetingRoom, getFolderImageMeetingRoom } from '../MeetingUtils'
import RouteConfig from '../../../../../config/appRoute/RouteConfig'

import FormMeetingRoom from '../formMeeting/FormMeetingRoom'
import Loading from '../../../../../control/loading/Loading';
import AlertMessage from '../../../../../control/alert/AlertMessage'
import UseLoadingState from '../../../../../control/loading/UseLoadingState';
import UseAlertMessageState from '../../../../../control/alert/UseAlertMessageState';

import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'

const AddMeetingRoom = (props) => {
    const loadingState = UseLoadingState();
    const alertMessageState = UseAlertMessageState();
    const { setCallbackRouteChange } = useContext(RouterContext)
    const {
        history
    } = props

    const deleteImage = (images) => { // เอาไว้กรณี upload บางรูปไม่สำเร็จจะทำการยิงลบตัวที่สำเร็จทั้งหมด
        const param = images.map(item => `${process.env.REACT_APP_FOLDER_UPLOAD_IMAGE_MEETINGROOM}\\${item}`.replace(/\//g, '\\'))
        callServiceDeleteImageMeetingRoom(param)
    }

    const uploadImage = async ({ building, floor, roomNameTH, images = [] }) => {
        const promiseUploadImage = []
        const path = getPathImageMeetingRoom({ building, floor, roomNameTH })
        const folderName = getFolderImageMeetingRoom({ building, floor, roomNameTH })

        images.forEach((image, index) => {
            if (image.data) {
                const param = {
                    path: path,
                    fileName: `${new Date().getTime()}${index}.${image.type}`,
                    base64Image: image.data
                }

                promiseUploadImage.push(callServiceUploadImageMeetingRoom(param))
            }
        });

        const resUploadImages = await Promise.all(promiseUploadImage)
        const result = {
            isSuccessAll: true,
            data: []
        }

        resUploadImages.forEach(res => { //เช็คกรณีมีบางรูป upload ไม่สำเร็จ
            if (res && res.status === 'success') {
                result.data.push({
                    imageURI: `${folderName}/${res.origin}`,
                    thumbnailURI: `${folderName}/${res.thumbnail}`
                })
            } else {
                result.isSuccessAll = false
            }
        })

        return result
    }

    const handleSubmitAdd = async (values) => {
        loadingState.showLoading();
        const resImage = await uploadImage(values)
        let resSaveMeeting, msg = null

        if (resImage.isSuccessAll) { //เช็คก่อนกรณีมีบางรูป upload ไม่สำเร็จจะไม่ให้บันทึกห้องประชุม
            values.images = setFormatValueImage(resImage.data)
            resSaveMeeting = await callServiceSaveMeetingRoom(values)
            msg = checkResStatusService(resSaveMeeting)
        }
        loadingState.hideLoading();

        if (resSaveMeeting && resSaveMeeting.data && resSaveMeeting.data.roomId) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.SAVE_SUCCESS,
                callbackCancel: async () => {
                    await setCallbackRouteChange()
                    closePage()
                }
            })
        } else {
            const imageDel = resImage.data.filter(item => item.thumbnailURI).map(res => res.thumbnailURI)
            deleteImage(imageDel) // กรณีสร้างห้องประชุมไม่สำเร็จ จะยิงเพื่อลบรุปที่ upload ไป
            alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SAVE_FAIL })
        }
    }

    const closePage = () => {
        history.push(RouteConfig.MEETING_MANAGE)
    }

    return (
        <Fragment>
            <FormMeetingRoom
                callBackCancel={closePage}
                callBackSubmit={handleSubmitAdd}
                showLoading={loadingState.showLoading}
                hideLoading={loadingState.hideLoading}
            />
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default compose(
    connect(mapStateToProps, null),
    withRouter
)(AddMeetingRoom)