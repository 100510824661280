import { useState } from 'react'

/*
 const alertMessageState = UseAlertMessageState();
alertMessageState.showAlert({ // ไม่ต้องส่ง props ไปครบก็ได้ ถ้าเป็น alert ใส่แค่ altMsg ก็พอ
    altMsg: "",
    altTitle: "ข้อความจากระบบ",
    altType : "",
    language : undefined,
    btnCloseName : "",
    btnCancelName : "",
    btnConfirmName : "",
    param: ,
    callbackConfirm = null,
    callbackCancel = null,
})
alertMessageState.hideAlert()

<AlertMessage {...alertMessageState.altProps} />
*/

const UseAlertMessageState = () => {
    const initialAltProps = {
        altMsg: "",
        altMultiline: null,
        altTitle: "ข้อความจากระบบ",
        altType: "",
        language: undefined,
        btnCloseName: "",
        btnCancelName: "",
        btnConfirmName: "",
        param: null,

        callbackConfirm: null, //callback สำหรับกดยืนยัน 
        callbackCancel: null, //callback สำหรับยกเลิก หรือปิดหน้าต่าง
        callbackOnChange: null
    }
    const [altProps, setAltProps] = useState(initialAltProps)
    const [isOpen, setOpen] = useState(false)

    const showAlert = ({
        altMsg = "",
        altPs = null,
        altTextbox = false,
        altTitle = "ข้อความจากระบบ",
        altType = "",
        language = undefined,
        btnCloseName = "",
        btnCancelName = "",
        btnConfirmName = "",
        param = null,

        callbackConfirm = null,
        callbackCancel = null,
        callbackOnChange = null
    }) => {
        setAltProps({
            ...altProps,
            altMsg: altMsg,
            altPs: altPs,
            altTextbox: altTextbox,
            altTitle: altTitle,
            altType: altType,
            language: language,
            callbackConfirm: callbackConfirm,
            callbackCancel: callbackCancel,
            callbackOnChange:callbackOnChange,
            param: param,
            btnCloseName: btnCloseName,
            btnCancelName: btnCancelName,
            btnConfirmName: btnConfirmName,
        })
        setOpen(true)
    }

    const hideAlert = () => {
        setOpen(false)
    }

    const handleCancel = () => {
        if (altProps.callbackCancel) {
            altProps.callbackCancel(altProps.param)
        } else {
            hideAlert();
        }
    }

    const handleConfirm = () => {
        if (altProps.callbackConfirm) {
            altProps.callbackConfirm(altProps.param)
        } else {
            hideAlert();
        }
    }

    const handleChange = (input) => {
        if(altProps.callbackOnChange) {
            altProps.callbackOnChange(input)
        }
    }

    return {
        altProps: { ...altProps, isOpen: isOpen, onCancel: handleCancel, onConfirm: handleConfirm, onChange: handleChange },
        showAlert,
        hideAlert
    }
}

export default UseAlertMessageState
