import React, { Fragment, useEffect, useState, useRef } from 'react';
import { reduxForm } from 'redux-form';

import { uniq, sortBy } from 'lodash';

import UseLoadingState from '../../../../control/loading/UseLoadingState';
import Loading from '../../../../control/loading/Loading';
import LeaveSAPDetailHistoryView from '../../presentational/leavesap/LeaveSAPDetailHistoryView';
import { convertDateDisplay } from './LeaveSAPUtils';
import UseDataTableState from '../../../../control/table/UseDataTableState'
import moment from 'moment';
import { displayDateRange } from '../../../../utilities/DatetimeUtils'

// const dataHistory = [
//     {
//         transactionId: 374,
//         leaveSap: {
//             leaveSapTransactionId: 314,
//             absenceId: "2884/2563",
//             leaveSapTypeId: "L104",
//             leaveSapTypeName: "ลาพักผ่อนประจำปี",
//             startDate: "2020-03-16",
//             endDate: "2020-03-18",
//             remark: "bot_auto",
//             submitDate: "2020-03-15 22:57",
//             status: "E",
//             responseMessage: "sap ไม่คืนผลลัพธ์กลับมา",
//             leaveSapTransactionTypeId: 1,
//             leaveSapTransactionTypeName: "เพิ่ม",
//             leaveTypeForDisplay: "ลาพักผ่อนประจำปี (L104)",
//             submitDateForDisplay: "21 มีนาคม 2563",
//             leaveDateForDisplay: "16 มีนาคม 2563 - 18 มีนาคม 2563",
//             statusSapForDisplay: "ไม่สำเร็จ"
//         }
//     },
//     {
//         transactionId: 373,
//         leaveSap: {
//             leaveSapTransactionId: 313,
//             absenceId: "2884/2563",
//             leaveSapTypeId: "L104",
//             leaveSapTypeName: "ลาพักผ่อนประจำปี",
//             startDate: "2020-03-16",
//             endDate: "2020-03-18",
//             remark: "bot_auto",
//             submitDate: "2020-03-21 22:57",
//             status: "E",
//             responseMessage: "sap ไม่คืนผลลัพธ์กลับมา",
//             leaveSapTransactionTypeId: 1,
//             leaveSapTransactionTypeName: "เพิ่ม",
//             leaveTypeForDisplay: "ลาพักผ่อนประจำปี (L104)",
//             submitDateForDisplay: "21 มีนาคม 2563",
//             leaveDateForDisplay: "16 มีนาคม 2563 - 18 มีนาคม 2563",
//             statusSapForDisplay: "ไม่สำเร็จ"
//         }
//     },
//     {
//         transactionId: 372,
//         leaveSap: {
//             leaveSapTransactionId: 312,
//             absenceId: "2884/2563",
//             leaveSapTypeId: "L104",
//             leaveSapTypeName: "ลาพักผ่อนประจำปี",
//             startDate: "2020-03-16",
//             endDate: "2020-03-18",
//             remark: "bot_auto",
//             submitDate: "2020-03-21 22:57",
//             status: "E",
//             responseMessage: "sap ไม่คืนผลลัพธ์กลับมา",
//             leaveSapTransactionTypeId: 1,
//             leaveSapTransactionTypeName: "เพิ่ม",
//             leaveTypeForDisplay: "ลาพักผ่อนประจำปี (L104)",
//             submitDateForDisplay: "21 มีนาคม 2563",
//             leaveDateForDisplay: "16 มีนาคม 2563 - 18 มีนาคม 2563",
//             statusSapForDisplay: "ไม่สำเร็จ"
//         }
//     },
//     {
//         transactionId: 371,
//         leaveSap: {
//             leaveSapTransactionId: 311,
//             absenceId: "2884/2563",
//             leaveSapTypeId: "L104",
//             leaveSapTypeName: "ลาพักผ่อนประจำปี",
//             startDate: "2020-03-10",
//             endDate: "2020-03-14",
//             remark: "bot_auto",
//             submitDate: "2020-03-21 22:57",
//             status: "E",
//             responseMessage: "sap ไม่คืนผลลัพธ์กลับมา",
//             leaveSapTransactionTypeId: 1,
//             leaveSapTransactionTypeName: "เพิ่ม",
//             leaveTypeForDisplay: "ลาพักผ่อนประจำปี (L104)",
//             submitDateForDisplay: "21 มีนาคม 2563",
//             leaveDateForDisplay: "16 มีนาคม 2563 - 18 มีนาคม 2563",
//             statusSapForDisplay: "ไม่สำเร็จ"
//         }
//     },
//     {
//         transactionId: 300,
//         leaveSap: {
//             leaveSapTransactionId: 300,
//             absenceId: "2884/2563",
//             leaveSapTypeId: "L104",
//             leaveSapTypeName: "ลาพักผ่อนประจำปี",
//             startDate: "2020-03-10",
//             endDate: "2020-03-14",
//             remark: "bot_auto",
//             submitDate: "2020-03-12 22:57",
//             status: "E",
//             responseMessage: "sap ไม่คืนผลลัพธ์กลับมา",
//             leaveSapTransactionTypeId: 1,
//             leaveSapTransactionTypeName: "เพิ่ม",
//             leaveTypeForDisplay: "ลาพักผ่อนประจำปี (L104)",
//             submitDateForDisplay: "21 มีนาคม 2563",
//             leaveDateForDisplay: "16 มีนาคม 2563 - 18 มีนาคม 2563",
//             statusSapForDisplay: "ไม่สำเร็จ"
//         }
//     }


// ]
const LeaveSAPDetailHistory = props => {
    const {
        dataHistory,
        data, onCancelClick, handleSubmit, leaveDate, change } = props;

    const [optionsDateSap, setOptionsDateSap] = useState([]);
    const [optionsDateLeave, setOptionsDateLeave] = useState([]);

    // const [filterData, setFilterData] = useState([]);

    const loadingState = UseLoadingState();
    const dataHistoryRef = useRef([])
    const dataTableHistoryLeaveState = UseDataTableState({
        rowPerPage: 10,
    })


    useEffect(() => {
        const filterDateSapData = [];
        const filterDateLeaveData = []
        dataHistory.map(item => {
            const dateSap = item.leaveSap.submitDate;
            const startDateLeave = item.leaveSap.startDate;
            const endDateLeave = item.leaveSap.endDate;
            let newDateSapItem;
            let newStartDateLeaveItem;
            let newEndDateLeaveItem;
            if (dateSap) {
                // newDateSapItem = convertDateDisplay(new Date(dateSap.replace(/-/g, '/')));
                newDateSapItem = moment(dateSap).format('YYYY-MM-DD')
                filterDateSapData.push(newDateSapItem);
            }
            if (startDateLeave && endDateLeave) {

                newStartDateLeaveItem = moment(startDateLeave).format('YYYY-MM-DD')//convertDateDisplay(new Date(startDateLeave.replace(/-/g, '/')));
                newEndDateLeaveItem = moment(endDateLeave).format('YYYY-MM-DD')//convertDateDisplay(new Date(endDateLeave.replace(/-/g, '/')));

                if (startDateLeave === endDateLeave) {
                    filterDateLeaveData.push(newStartDateLeaveItem);
                } else {
                    filterDateLeaveData.push(`${newStartDateLeaveItem}/${newEndDateLeaveItem}`);

                }

            }
        });

        const uniqDateSapData = uniq(filterDateSapData);
        const uniqDateLeaveData = uniq(filterDateLeaveData);

        const dateSapOption = uniqDateSapData.map((item, index) => {
            const newItem = {
                id: item,//index + 1,
                dateForSort: item,
                value: convertDateDisplay(new Date(item.replace(/-/g, '/')))
            };

            return newItem;
        });

        const dateLeaveOption = uniqDateLeaveData.map((item, index) => {
            const dateArr = item.split("/")
            let newItem = {}
            if (dateArr.length === 1) {
                newItem = {
                    id: dateArr[0],
                    dateForSort: dateArr[0],
                    value: convertDateDisplay(new Date(dateArr[0].replace(/-/g, '/')))
                };
            } else { //กรณีเป็นช่วง
                newItem = {
                    id: `${dateArr[0]}/${dateArr[1]}`,
                    dateForSort: dateArr[0],
                    value: displayDateRange(dateArr[0], dateArr[1])//`${convertDateDisplay(new Date(dateArr[0].replace(/-/g, '/')))} - ${convertDateDisplay(new Date(dateArr[1].replace(/-/g, '/')))}`
                };
            }


            return newItem;
        });

        setOptionsDateSap(sortBy(dateSapOption, 'dateForSort'));
        setOptionsDateLeave(sortBy(dateLeaveOption, 'dateForSort'))
        const newFormatHistoryData = dataHistory.map((item) => {
            const newData = {
                ...item.leaveSap,
                leaveTypeForDisplay: `${item.leaveSap.leaveSapTypeName} (${item.leaveSap.leaveSapTypeId})`,
                submitDateForDisplay: item.leaveSap.submitDate ? convertDateDisplay(new Date(item.leaveSap.submitDate.replace(/-/g, '/'))) : '-',
                leaveDateForDisplay: (item.leaveSap.startDate === item.leaveSap.endDate) ? convertDateDisplay(new Date(item.leaveSap.startDate.replace(/-/g, '/'))) :
                    displayDateRange(item.leaveSap.startDate, item.leaveSap.endDate)
                // `${convertDateDisplay( new Date(item.leaveSap.startDate.replace(/-/g, '/')) ) } - ${convertDateDisplay(new Date(item.leaveSap.endDate.replace(/-/g, '/')))}`

            }
            switch (item.leaveSap.status) {
                case 'S': newData.statusSapForDisplay = 'สำเร็จ'
                    break;
                case 'E':
                    newData.statusSapForDisplay = 'ไม่สำเร็จ'
                    break;
                case 'W':
                    newData.statusSapForDisplay = 'รอส่งใหม่';
                    break;
                case 'C':
                    newData.statusSapForDisplay = 'ยกเลิกการส่ง';
                    break;

                default:
                    newData.statusSapForDisplay = '-';
                    break;

            }

            return newData
        })

        const sortData = sortBy(newFormatHistoryData, 'startDate');
        // setFilterData(sortData)
        dataTableHistoryLeaveState.setInitialDataTable(sortData);
        dataHistoryRef.current = sortData


        //=============== set initial value to leavedate dropdown =============//
        if (leaveDate && leaveDate.startDate && leaveDate.endDate) {
            if (leaveDate.startDate === leaveDate.endDate) { //เป็นวันเดียว
                change('dateLeave', leaveDate.startDate)
                handleSearchClick({ dateLeave: leaveDate.startDate })
            } else { //เป็นช่วง
                change('dateLeave', `${leaveDate.startDate}/${leaveDate.endDate}`)
                handleSearchClick({ dateLeave: `${leaveDate.startDate}/${leaveDate.endDate}` })
            }

        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchClick = value => {
        loadingState.showLoading()
        let newData = []

        if (value && value.dateLeave && value.dateSap) { //เลือกทั้งสองค่า

            const dateLeaveSplit = value.dateLeave.split("/")
            if (dateLeaveSplit.length === 1) {  //เป็นวันเดียว

                newData = dataHistoryRef.current.filter((itm) => {
                    return itm.startDate === dateLeaveSplit[0] && moment(itm.submitDate).format('YYYY-MM-DD') === value.dateSap
                })

            } else {//เป็นช่วง

                newData = dataHistoryRef.current.filter((itm) => {
                    return itm.startDate === dateLeaveSplit[0] && itm.endDate === dateLeaveSplit[1] && moment(itm.submitDate).format('YYYY-MM-DD') === value.dateSap
                })

            }

        } else { //เลือกค่าใดค่าหนึ่งหรืออาจไม่เลือกเลย

            if (value && value.dateLeave) { //เลือกวันที่ลา อย่างเดียว
                const dateLeaveSplit = value.dateLeave.split("/")
                if (dateLeaveSplit.length === 1) {  //เป็นวันเดียว
                    newData = dataHistoryRef.current.filter((itm) => {
                        return itm.startDate === dateLeaveSplit[0]
                    })


                } else {//เป็นช่วง

                    newData = dataHistoryRef.current.filter((itm) => {
                        return itm.startDate === dateLeaveSplit[0] && itm.endDate === dateLeaveSplit[1]
                    })

                }

            }
            else if (value && value.dateSap) { //เลือก sap อย่างเดียว

                newData = dataHistoryRef.current.filter((itm) => {
                    return moment(itm.submitDate).format('YYYY-MM-DD') === value.dateSap
                })

            }
            else { //ไม่เลือกอะไรเลย
                newData = dataHistoryRef.current

            }


        }

        setTimeout(() => {
            dataTableHistoryLeaveState.setInitialDataTable(newData);
            // setFilterData(newData)
            loadingState.hideLoading()
        }, 700);
    }
    return (
        <Fragment>
            <LeaveSAPDetailHistoryView
                // dataDetailHistory={filterData}
                optionsDateSap={optionsDateSap}
                // onSubmitDateChange={handleSubmitDate}
                onCancelClick={onCancelClick}
                leaveTypeId={data.leaveTypeId}
                onSearchClick={handleSubmit(handleSearchClick)}
                optionsDateLeave={optionsDateLeave}
                dataTableHistoryLeaveState={dataTableHistoryLeaveState}
            />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    );
};

const reduxFormParameter = {
    form: 'leaveSapDetailHistory'
};

export default reduxForm(reduxFormParameter)(LeaveSAPDetailHistory);
