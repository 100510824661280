import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { isDirty } from 'redux-form'

import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'
// import { ABSENCE } from '../../../../constants/APDConstants'
import { AbsenceContext } from './AbsenceProvider'

import PanelModalAbsenceView from '../../presentational/absence/PanelModalAbsenceView'

const PanelModalAbsence = ({ dirtyEditAbsence }) => {
    const { panelState, absenceState, alertMessageState, permissionSystem } = useContext(AbsenceContext)
    const { dataEditAbsence } = absenceState

    const handleChangePanel = (evt, value) => {
        if (dirtyEditAbsence) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: "ยกเลิก",
                btnCancelName: "ไม่ยกเลิก",
                callbackConfirm: () => {
                    alertMessageState.hideAlert()
                    panelState.changePanel(value)
                },
            })
        } else {
            panelState.changePanel(value)
        }
    }

    const handleCancelAbsence = () => {
        absenceState.setOpenCancelForm(true)
    }

    return (
        <PanelModalAbsenceView
            isShowCancel={permissionSystem.permisCancel}
            panelActive={panelState.panelActive}
            leaveState={{
                leaveStateId: dataEditAbsence.leaveStateId,
                leaveStateName: dataEditAbsence.leaveStateName
            }}

            onChangePanel={handleChangePanel}
            onCancelAbsence={handleCancelAbsence}
        />
    )
}

const mapStateToPros = state => ({
    dirtyEditAbsence: isDirty('formEditAbsence')(state)
})

export default connect(mapStateToPros)(PanelModalAbsence)
