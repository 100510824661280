import React, { Fragment, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose'

import { LeaveSAPContext } from './LeaveSAPProvider'
import { convertDateDisplay } from './LeaveSAPUtils';
import { checkStatusCancel } from '../absence/AbsenceUtils'
import RouteConfig from '../../../../config/appRoute/RouteConfig'

import LeaveSAPView from '../../presentational/leavesap/LeaveSAPView';
import LeaveSAPSearch from './LeaveSAPSearch';
import LeaveSAPTable from './LeaveSAPTable';
import Loading from '../../../../control/loading/Loading'
import AlertMessage from '../../../../control/alert/AlertMessage'

const LeaveSAP = ({
    history
}) => {
    const {
        dataTableState,
        permissionSystem,
        loadingState,
        alertMessageState
    } = useContext(LeaveSAPContext)

    const checkDisplayStatus = (responseTypeId) => {
        switch (responseTypeId) {
            case 'S':
                return {
                    text: 'สำเร็จ',
                    span: <span style={{ color: '#00c900' }}>สำเร็จ</span>
                }
            case 'E':
                return {
                    text: 'ไม่สำเร็จ',
                    span: <span style={{ color: '#ff0022' }}>ไม่สำเร็จ</span>
                }
            case 'W':
                return {
                    text: 'รอส่งใหม่',
                    span: <span style={{ color: '#bb970e' }}>รอส่งใหม่</span>
                }
            case 'M':
                return {
                    text: 'SAP ไม่คืนกลับมา',
                    span: <span style={{ color: '#ff0022' }}>SAP ไม่คืนกลับมา</span>
                }
            case 'C':
                return {
                    text: 'ยกเลิกการส่ง',
                    span: <span style={{ color: '#ff0022' }}>ยกเลิกการส่ง</span>
                }
            default:
                return { text: '', span: '' }
        }
    }

    const checkLeaveState = (leaveStateId) => {
        if (checkStatusCancel(leaveStateId)) {
            return <span style={{ color: '#ff0022' }}>ยกเลิกแล้ว</span>
        } else {
            return <span style={{ color: '#00c900' }}>ไม่ยกเลิก</span>
        }

    }

    const handleDataTable = (data) => {
        const dataFormat = data
            ? data.data.map(item => {
                const startMonth = new Date(item.startDate).getMonth();
                const endMonth = new Date(item.endDate).getMonth();
                const startDate = new Date(item.startDate).getDate();
                const responseTypeConvert = checkDisplayStatus(item.responseTypeId)

                item.sortLeaveDate = item.startDate
                item.startDate = convertDateDisplay(new Date(item.startDate));
                item.endDate = convertDateDisplay(new Date(item.endDate));
                item.leaveDate =
                    item.startDate === item.endDate
                        ? item.startDate
                        : startMonth === endMonth
                            ? `${startDate} - ${item.endDate}`
                            : `${item.startDate} - ${item.endDate}`;
                item.edit = checkStatusCancel(item.leaveStateId) ? false : (permissionSystem.permisEdit || permissionSystem.permisDetail || permissionSystem.permisCancel);
                item.detail = checkStatusCancel(item.leaveStateId) && (permissionSystem.permisEdit || permissionSystem.permisDetail || permissionSystem.permisCancel);
                item.responseTypeIdDisplay = responseTypeConvert.span
                item.responseTypeIdDisplaySort = responseTypeConvert.text
                item.leaveStateName = checkLeaveState(item.leaveStateId)
                item.submitDateDisplay = convertDateDisplay(new Date(item.submitDate));

                return item;
            })
            : [];

        dataTableState.setInitialDataTable(dataFormat);
    };

    const handleEdit = data => {
        const param = { ...data }
        delete param.responseTypeIdDisplay
        delete param.leaveStateName

        history.push(RouteConfig.LEAVE_SAP_EDIT, { data: param })
    };

    const handleDetail = data => {
        const param = { ...data }
        delete param.responseTypeIdDisplay
        delete param.leaveStateName

        history.push(RouteConfig.LEAVE_SAP_DETAIL, { data: param })
    }

    return (
        <Fragment>
            <LeaveSAPView>
                <LeaveSAPSearch
                    handleDataTable={handleDataTable}
                />
                <LeaveSAPTable
                    dataTableState={dataTableState}
                    onEdit={handleEdit}
                    onDetail={handleDetail}
                />
            </LeaveSAPView>
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    );
};

const mapStateToProps = state => ({
    profile: state.authen.profile
});

export default compose(
    connect(mapStateToProps, null),
    withRouter
)(LeaveSAP);
