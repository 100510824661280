import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import * as BookingConstants from '../../../../../constants/meeting/BookingConstants';
// import { normalizeNumber } from '../../../../../utilities/Validate';
import { keyEnterSubmitForm } from '../../../../../utilities/FormUtils'

// import TextFields from '../../../../../control/textfield/TextFields';
import Button from '../../../../../control/button/Button';
// import DateTimePicker from '../../../../../control/datetime/DateTimePicker';
import DateTimePicker from '../../../../../control/fdatetime/DateTimeFlatPicker'
import ErrorField from '../../../../../control/errorField/ErrorField'
import AutoComplete from '../../../../../control/autocomplete/AutoComplete'

const { NAME, LABEL } = BookingConstants.FORM_SEARCH_BOOKING;

const useStyles = makeStyles(theme => ({
    spacing: {
        ...theme.form.spacing
    },
    btnSearch: {
        width: '105px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    divider: {
        width: '100%',
        margin: '24px 0px'
    },
    btnReset: {
        backgroundColor: `${theme.mainTheme.color6} !important`,
        width: '105px',
        marginRight: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: '0px',
            marginBottom: '2%'
        }
    },
    btnExport: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            marginTop: '2%',
            display: 'block',
            backgroundColor: `${theme.mainTheme.color4} !important`,
            width: '100%'
        }
    },
    fieldError: {
        marginRight: theme.spacing(2),
        alignSelf: 'center'
    }
}));

const FormSearchMaintenanceView = props => {
    const classes = useStyles();
    const {
        onSearchClick,
        onResetClick,
        onChangeDateTime,
        lutSystemMaintenance = []
    } = props;

    return (
        <Fragment>
            <Grid container spacing={1} className={classes.containerSearch}>
                <Grid item xs={12} md={4} className={classes.spacing}>
                    <Field
                        id="start-date"
                        name={NAME.START_DATE}
                        label={LABEL.START_DATE}
                        component={DateTimePicker}
                        placeholder={LABEL.START_DATE}
                        onChange={dt =>
                            onChangeDateTime(
                                BookingConstants.DATE_TIME.START,
                                dt
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4} className={classes.spacing}>
                    <Field
                        id="end-date"
                        name={NAME.END_DATE}
                        label={LABEL.END_DATE}
                        component={DateTimePicker}
                        placeholder={LABEL.END_DATE}
                        onChange={dt =>
                            onChangeDateTime(BookingConstants.DATE_TIME.END, dt)
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4} className={classes.spacing}>
                    <Field
                        name={"apiName"}
                        label={"ชื่อระบบ"}
                        component={AutoComplete}
                        optionKey={{
                            label: 'systemNameEN',
                            value: 'id',
                        }}
                        options={lutSystemMaintenance}
                        onKeyDown={keyEnterSubmitForm(onSearchClick)}
                    />
                </Grid>

                <Grid item xs={12} container justify="flex-end">
                    <Grid item className={classes.fieldError}>
                        <Field
                            name={BookingConstants.FORM_SEARCH_BOOKING.ERROR}
                            component={ErrorField}
                        />
                    </Grid>
                    <Button
                        label="ค่าเริ่มต้น"
                        className={classes.btnReset}
                        onClick={onResetClick}
                    />
                    <Button
                        className={classes.btnSearch}
                        label="ค้นหา"
                        onClick={onSearchClick}
                    />

                </Grid>
            </Grid>
            <Divider className={classes.divider} />
        </Fragment>
    );
};

export default FormSearchMaintenanceView;
