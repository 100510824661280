import { fetchGet_, fetchGet, fetchPost_ } from '../utilities/ServiceUtils';
import { getTokenLocalStore } from '../utilities/LocalStorageUtils';
import * as ApiConstants from '../constants/ApiConstants'
import { API } from '../constants/ServiceMSGConstants'

export const getProfileSSO = () => {
    return fetchGet_(ApiConstants.SERVICE_GET_PROFILE_SSO)
        .then(res => {
            if (!res.ok) {
                throw { status: res.status, api: API.SMO };
            }
            return res.json();
        })
        .catch(ex => {
            console.log('ex getProfileSSO', ex)
            return ex
        })
}

export const getProfileAPD = (uuid) => {
    return fetchGet(`${ApiConstants.SERVICE_GET_PROFILE_APD}/${uuid}?${new Date().getTime()}`)
        .then(res => {
            if (!res.ok) {
                throw { status: res.status, api: API.APD };
            }
            return res.json();
        })
        .catch(ex => {
            console.log('ex getProfileAPD', ex)
            return ex
        })
}

export const logout = () => {
    return fetchPost_(ApiConstants.SERVICE_LOGOUT_SSO, { token: getTokenLocalStore() })
        .then(res => {
            if (!res.ok) {
                throw { status: res.status, api: API.SMO };
            }
            return res.status;
        })
        .catch(ex => {
            console.log('ex logout', ex)
            return ex
        })
}