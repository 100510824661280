import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    rootPaper: {
        margin: '24px',
        ...theme.paperPadding
    },
    table: {
        marginTop: theme.spacing(2)
    },
}))

const LeaveCollectView = (props) => {

    const classes = useStyles()
    const {
        children
    } = props

    return (
        <Grid container className={classes.root}>
            <Scrollbars universal={true}>
                <Grid item xs={12}>
                    <Paper className={classes.rootPaper}>
                        {children[0]}
                        {children[1]}
                    </Paper>
                </Grid>
            </Scrollbars>
        </Grid>
    )
}

export default LeaveCollectView