// GOT
const connextService = process.env.REACT_APP_CONNEXT_SERVICE;
// const connextService = "http://localhost:4000";

const apdService = process.env.REACT_APP_APD_SERVICE;
const leaveService = process.env.REACT_APP_LEAVE_SERVICE;
// const leaveService = "http://localhost:4003"

const meetingService = process.env.REACT_APP_MEETING_SERVICE;
// const meetingService = 'http://localhost:4004';

const uploadService = process.env.REACT_APP_UPLOAD_SERVICE;
const uploadImageService = process.env.REACT_APP_UPLOAD_IMAGE_SERVICE;
const searchService = process.env.REACT_APP_SEARCH_SERVICE;

// MB
const employeeService = process.env.REACT_APP_EMPLOYEE_SERVICE;
const ssoService = process.env.REACT_APP_SSO_SERVICE;
const pilotService = process.env.REACT_APP_PILOT_SERVICE;

//authen
export const SERVICE_GET_PROFILE_APD = `${apdService}/api/profile`;
export const SERVICE_GET_PROFILE_SSO = `${ssoService}/profile`;
export const SERVICE_LOGOUT_SSO = `${ssoService}/oauth2/logout`;

//org
export const SERVICE_GET_ALL_ORG = `${employeeService}/org/org-flat`;
export const SERVICE_GET_ALL_ORG_TREE = `${employeeService}/org/org-tree`;
export const SERVICE_GET_ORG_DEP = `${employeeService}/org/dep`;

//emp
export const SERVICE_GET_EMP = `${pilotService}/api/emp/employee`;

//smart search
export const SERVICE_SMART_SEARCH = `${searchService}/api/smartSearch`;

//meeting
export const SERVICE_GET_MEETING_ROOM = `${meetingService}/api/room/searchAPD`;
export const SERVICE_LU_ROOM_TYPE = `${meetingService}/api/lookup/roomType`;
export const SERVICE_LU_EQUIPMENT = `${meetingService}/api/lookup/equipment`;
export const SERVICE_LU_PUBLICITY_TYPE = `${meetingService}/api/lookup/publicityType`;
export const SERVICE_LU_BOOKING_TYPE = `${meetingService}/api/lookup/bookingType`;
export const SERVICE_ADD_ROOM = `${meetingService}/api/room`;
export const SERVICE_GET_MEETINGROOM_BY_ID = `${meetingService}/api/room`;
export const SERVICE_EDIT_MEETINGROOM_BY_ID = `${meetingService}/api/room/edit`;
export const SERVICE_DELETE_MEETINGROOM = `${meetingService}/api/room/delete`;
export const SERVICE_UPLOAD_TEMPORARY_IMAGE = `${uploadService}/api/temporary`;
export const SERVICE_UPLOAD_FILE = `${uploadService}/api/file`;
export const SERVICE_GET_LU_DEP = `${meetingService}/api/lookup/dep`;
export const SERVICE_UPLOAD_IMAGE = `${uploadImageService}/api/upload`;
export const SERVICE_COPPY_IMAGE = `${uploadImageService}/api/image/copy`;
export const SERVICE_DELETE_IMAGE = `${uploadImageService}/api/image/delete`;

//role
export const SERVICE_GET_SUPER_ADMIN = `${apdService}/api/user/getSuperAdmin`;

//leave
export const SERVICE_GET_YEAR_HOLIDAY = `${leaveService}/api/holiday/getYearHoliday`;
export const SERVICE_GET_HOLIDAY = `${leaveService}/api/holiday?year=`;
export const SERVICE_GET_STRUCTURE_ORG = `${leaveService}/api/leave/getStructureOrg`;
export const SERVICE_GET_LU_HOLIDAY_INFO = `${leaveService}/api/lookup/holidayInfo`;
export const SERVICE_ADD_HOLIDAY = `${leaveService}/api/holiday`;
export const SERVICE_ADD_HOLIDAY_INFO = `${leaveService}/api/holiday/holidayInfo`;
export const SERVICE_EDIT_HOLIDAY = `${leaveService}/api/holiday`;
export const SERVICE_DELETE_HOLIDAY = `${leaveService}/api/holiday`;
export const SERVICE_GET_LU_APPROVAL_TYPE = `${leaveService}/api/lookup/approvalType`;
export const SERVICE_LOG_ABSENCE = `${leaveService}/api/holiday/exportLogAbsence`;
export const SERVICE_LOG_ABSENCE_BY_DATE = `${leaveService}/api/holiday/exportLogAbsenceByDate`;
export const SERVICE_LOG_IMPACT_ABSENCE = `${leaveService}/api/holiday/exportImpactHoliday`;
export const SERVICE_LEAVE_SAP = `${leaveService}/api/leaveSAP/getLeaveSAP`;
export const SERVICE_GET_LEAVE_DETAIL_SAP = `${leaveService}/api/leaveSAP/getLeaveSAPDetail`;
export const SERVICE_GET_EMP_BY_ID = `${leaveService}/api/leave/getEmp`;
export const SERVICE_GET_LU_CUSTOM_ORG = `${leaveService}/api/leave/getOrgCustom`;
export const SERVICE_GET_LU_EXCLUSIVE_ORG = `${leaveService}/api/leave/getOrgExclusive`;
export const SERVICE_UPDATE_ORG_LEAVE = `${leaveService}/api/leave/updateOrgLeave`;
export const SERVICE_UPDATE_ORG_SAP = `${leaveService}/api/leave/updateOrgLeaveFromSap`;
export const SERVICE_GET_LU_LEAVE_TYPE = `${leaveService}/api/lookup/leaveType`;
export const SERVICE_GET_LU_LEAVE_STATE = `${leaveService}/api/lookup/leaveState`;
export const SERVICE_GET_LEAVE_ABSENCE = `${leaveService}/api/leaveAbsence/getLeaveAbsence`;
export const SERVICE_GET_LU_LEAVE_SAP_TYPE = `${leaveService}/api/lookup/leaveSapType`;
export const SERVICE_GET_LU_SICK_REASON = `${leaveService}/api/lookup/sickReason`;
export const SERVICE_UPDATE_LEAVE_SAP_TRANSACTION = `${leaveService}/api/leaveSAP/leaveSAPTransaction`;
export const SERVICE_DELETE_LEAVE = `${leaveService}/api/leaveSAP/cancelAbsence`;
export const SERVICE_UPDATE_LEAVE = `${leaveService}/api/leaveAbsence/updateLeaveAbsence`;
export const SERVICE_GET_LEAVE_ABSENCE_DETAIL = `${leaveService}/api/leaveAbsence/getLeaveAbsenceDetail`;
export const SERVICE_GET_LU_LEAVE_FLAG = `${leaveService}/api/lookup/leaveFlag`;
export const SERVICE_GET_LUT_LEAVE_SAP_RESULT = `${leaveService}/api/lookup/leaveSapResult`;
export const SERVICE_GET_LUT_SYNC_SAP_RESULT = `${leaveService}/api/lookup/syncSapResult`;
export const SERVICE_GET_LUT_ORGCHART = `${leaveService}/api/lookup/orgChart`;
export const SERVICE_GET_LUT_SPECIAL_LEAVE = `${leaveService}/api/lookup/specialLeave`;
export const SERVICE_LEAVE_COLLECT = `${leaveService}/api/leaveCollect/searchAPD`;

//um
export const SERVICE_GET_LU_SYSTEM_FUNCTION = `${apdService}/api/lookup/getSystemFunction`;
export const SERVICE_GET_LU_PERMISSION_ORG = `${apdService}/api/lookup/getPermissionOrg`;
export const SERVICE_GET_ALL_USER_GROUP = `${apdService}/api/userGroup/getAllUserGroup`;
export const SERVICE_GET_USER_GROUP_BY_ID = `${apdService}/api/userGroup/getUserGroup`;
export const SERVICE_GET_USER_GROUP_BY_EMP_ID = `${apdService}/api/userGroup/getUserGroupByEmpId`;
export const SERVICE_DELETE_USER_GROUP_BY_ID = `${apdService}/api/userGroup/deleteUserGroup`;
export const SERVICE_ADD_USER_GROUP = `${apdService}/api/userGroup/insertUserGroup`;
export const SERVICE_UPDATE_USER_GROUP = `${apdService}/api/userGroup/updateUserGroup`;
export const SERVICE_GET_ALL_USER = `${apdService}/api/user/getAllUser`;
export const SERVICE_UPDATE_USER = `${apdService}/api/user/updateUser`;
export const SERVICE_DELETE_USER = `${apdService}/api/user/deleteUser`;
export const SERVICE_INSERT_USER = `${apdService}/api/user/insertUser`;

//lookup
export const SERVICE_GET_LU_ORG_BY_PERMISSION = `${leaveService}/api/lookup/orgByPermission`;
export const SERVICE_GET_LU_LEAVE_EMP_BY_PERMISSION = `${leaveService}/api/lookup/empByPermission`;
export const SERVICE_GET_LU_EMP_APPROVE_JOB_ID = `${leaveService}/api/lookup/empApproveJobId`;
export const SERVICE_GET_LU_MEETING_EMP_BY_PERMISSION = `${meetingService}/api/lookup/empByPermission`;
export const SERVICE_MEA_DISTRICT = `${meetingService}/api/lookup/meaDistrict`;
export const SERVICE_GET_LU_MEETING_ROOM = `${meetingService}/api/lookup/room`;

//booking
export const SERVICE_GET_LU_ROOM_SIZE = `${meetingService}/api/lookup/roomSize`;
export const SERVICE_SEARCH_AVAILABLE_ROOM = `${meetingService}/api/room`;
export const SERVICE_BOOKING_LIST = `${meetingService}/api/booking/forMaintainer`;
export const SERVICE_BOOKING_CANCEL_BY_DATE = `${meetingService}/api/booking`;
export const SERVICE_BOOKING_DATE = `${meetingService}/api/booking`;
export const SERVICE_LUT_BUILDING = `${meetingService}/api/lookup/building`;
export const SERVICE_GET_BOOKING_BY_CON = `${meetingService}/api/booking/getBookingByCon`;

//Cancel Booking
export const SERVICE_GET_CANCEL_BOOKING = `${meetingService}/api/booking/cancel/forMaintainer`;

//maintenance
export const SERVICE_MAINTENANCE = `${connextService}/api/maintenance`;
export const SERVICE_GET_LUT_SYSTEM_MAINTENANCE = `${connextService}/api/maintenance/getLutSystemMaintenance`;

//template
export const GET_TEMPLATE = `${connextService}/api/template/getAllTemplate`;
export const UPDATE_TEMPLATE = `${connextService}/api/template`;

//force update
export const SERVICE_GET_APP_VERSION = `${connextService}/api/appVersion/getAllAppVersion`;
export const SERVICE_UPDATE_APP_VERSION = `${connextService}/api/appVersion/updateAppVersion`;

// user profile
export const SERVICE_SEARCH_PROFILE_EDITING_HISTORY = `${employeeService}/user-profile/history/search`;
export const SERVICE_GET_PROFILE_EDITING_TRANSACTION = `${employeeService}/user-profile/history/transaction`;
export const SERVICE_GET_MANAGEMENT_ORGANIZATION_LOOKUP = `${employeeService}/lookup/management-organization`;
