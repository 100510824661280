import React, { useContext, useEffect, useState } from 'react'
import { reduxForm, formValueSelector } from "redux-form"
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ABSENCE } from '../../../../../constants/APDConstants'
import { getFirstDateOfCurentMount, getLastDateOfCurentMount, getCurrentYearAndLastYear } from '../../../../../utilities/DatetimeUtils'
import { validateSearchAbsence } from '../AbsenceUtils'
import { AbsenceContext } from '../AbsenceProvider'
import { getUrlParam } from '../../../../../utilities/Utils'

import FormSearchAbsenceView from '../../../presentational/absence/form/FormSearchAbsenceView'

const FormSearchAbsence = ({
    startDate,
    endDate,
    callBackSubmit,
    handleSubmit,
    initialize,
    reset,
    change
}) => {
    const { lutAbsenceState, permissionOrgId } = useContext(AbsenceContext)
    const [listSelectedLeaveSapWaitID, setListSelectedLeaveSapWaitID] = useState([])
    const [defaultSelectedLeaveSapWait, setDefaultValue] = useState([])
    const lutYear = getCurrentYearAndLastYear(2).map(item => ({ id: item.toString(), name: item.toString() })).reverse()

    useEffect(() => {
        const nameSign = getUrlParam('nameSign')
        const defaultYear = lutYear && lutYear[0] && lutYear[0].id

        if (nameSign) {
            initialize({
                [ABSENCE.FORM_SEARCH_ABSENCE.NAME.NAME_SIGN]: nameSign,
                [ABSENCE.FORM_SEARCH_ABSENCE.NAME.LEAVE_STATE_ID]: ABSENCE.LEAVE_STATE_IN_PROGRESS.id,
                [ABSENCE.FORM_SEARCH_ABSENCE.NAME.YEAR]: defaultYear
            })
        } else {
            initialize({
                [ABSENCE.FORM_SEARCH_ABSENCE.NAME.START_DATE]: getFirstDateOfCurentMount(),
                [ABSENCE.FORM_SEARCH_ABSENCE.NAME.END_DATE]: getLastDateOfCurentMount(),
                [ABSENCE.FORM_SEARCH_ABSENCE.NAME.YEAR]: defaultYear
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleReset = () => {
        setListSelectedLeaveSapWaitID([])
        setDefaultValue([])
        reset('FormSearchAbsence')
    }

    const handleChangeDate = field => value => {
        if (value) {
            switch (field) {
                case ABSENCE.FORM_SEARCH_ABSENCE.NAME.START_DATE:
                    if (endDate && value > endDate) {
                        change(ABSENCE.FORM_SEARCH_ABSENCE.NAME.END_DATE, value)
                    }
                    break;
                case ABSENCE.FORM_SEARCH_ABSENCE.NAME.END_DATE:
                    if (startDate && value < startDate) {
                        change(ABSENCE.FORM_SEARCH_ABSENCE.NAME.START_DATE, value)
                    }
                    break;
                default:
                    break;
            }
        }
    }
    const handleClickCheckboxLeaveSapWait = (listSelectedID, listSelcectedArr) => {
        change(ABSENCE.FORM_SEARCH_ABSENCE.NAME.LEAVE_WAIT_SAP_STATE_ID, listSelectedID.join('|'))
        setListSelectedLeaveSapWaitID(listSelectedID)
        setDefaultValue(listSelcectedArr)
    }

    const handleClickSubmit = (value, event) => {
        if (callBackSubmit) {
            callBackSubmit(value, event)
        }
    }

    return (
        <FormSearchAbsenceView
            permissionOrgId={permissionOrgId}
            lutLeaveState={lutAbsenceState.lutLeaveState}
            lutLeaveType={lutAbsenceState.lutLeaveType}
            lutOrg={lutAbsenceState.lutOrg}
            lutLeaveFlag={lutAbsenceState.lutLeaveFlag}
            lutYear={lutYear}
            listSelectedLeaveSapWaitID={listSelectedLeaveSapWaitID}
            defaultValue={defaultSelectedLeaveSapWait}

            onSubmit={handleSubmit(handleClickSubmit)}
            onResetClick={handleReset}
            onChangeDate={handleChangeDate}
            onClickCheckbox={handleClickCheckboxLeaveSapWait}
        />
    )
}

const reduxFormParameter = {
    form: 'FormSearchAbsence',
    validate: validateSearchAbsence,
};

const selector = formValueSelector('FormSearchAbsence')

const mapStateToProps = state => ({
    startDate: selector(state, ABSENCE.FORM_SEARCH_ABSENCE.NAME.START_DATE),
    endDate: selector(state, ABSENCE.FORM_SEARCH_ABSENCE.NAME.END_DATE),
})

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps)
)(FormSearchAbsence)
