import { SAP } from '../../../../constants/APDConstants';
import { ERRORS } from '../../../../constants/APDConstants';

import { KEY_RESPONSE_MESSAGE } from '../../../../constants/leave/LeaveSAPConstants'

export const extractDate = date => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return { year, month, day };
};

export const convertDate = date => {
    const dateExtract = extractDate(date);

    const strDate = `${dateExtract.year}-${Number(dateExtract.month) + 1}-${
        dateExtract.day
        }`;
    return strDate;
};

export const convertDateDisplay = date => {
    const dateExtract = extractDate(date);
    const monthName = SAP.NAME_OF_MONTH.filter(
        item => item.id === dateExtract.month
    )[0].name;

    const strDateDisplay = `${dateExtract.day} ${monthName} ${Number(
        dateExtract.year
    ) + 543}`;
    return strDateDisplay;
};

export const manageDataToDisplay = (
    { permisDelete, permisEdit },
    data,
    nameField1,
    nameField2
) => {
    const mapData = data.map(item => {
        item.dateFormat = item.leaveDate;
        item[nameField1] = convertDateDisplay(new Date(item[nameField1]));
        if (nameField2) {
            item[nameField2] = convertDateDisplay(new Date(item[nameField2]));
        }

        if (item.leavePeriodId === 4) {
            item.startTime = convertTimeToDisplay(item.startTime);
            item.endTime = convertTimeToDisplay(item.endTime);
        }

        if (item.leaveTypeId === 6) {
            item.childBirthDate = convertDateDisplay(
                new Date(item.childBirthDate)
            );
        }

        item.edit = item.absenceId && permisEdit ? true : false;
        item.cancel = item.permisDelete;
        item.result = item.responseTypeId === 'E' ? 'Error' : 'Success';

        return item;
    });

    return mapData;
};

export const convertTimeToDisplay = time => {
    const dateTime = new Date(time);
    const hour = dateTime.getHours();
    const minute = dateTime.getMinutes();

    const localTime = `${hour}:${minute}`;
    return localTime;
};

export const validateLeaveSapSearch = (values = {}) => {
    // const key = [
    //     'startDate',
    //     'endDate',
    //     'startDateLeave',
    //     'endDateLeave',
    //     'responseTypeId',
    //     'keyword',
    //     'empId',
    //     'empName'
    // ];
    const errors = {};

    if (Object.keys(values).filter(item => values[item]).length === 0) {
        // key.forEach(k => {
        //     errors[k] = ERRORS.AT_LEAST_ONE_CONDITION;
        // });
        errors.error = ERRORS.AT_LEAST_ONE_CONDITION
    }

    return errors;
};

export const getSapMessage = (responseMessage = "") => {

    if (responseMessage) {
        const message = KEY_RESPONSE_MESSAGE.filter(key => responseMessage.indexOf(key) !== -1)
        return message.toString()
    }

}
