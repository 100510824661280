import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

import Button from '../../../../control/button/Button'

const useStyle = makeStyles(theme => ({
    root: {
        // padding: '24px 0px 24px 24px',
        height: '100%',
        width: '100%'
    },
    title: {
        fontSize: '33px'
    },
    body: {
        height: 'calc(100% - 108px)',
    },
    label: {
        color: theme.mainTheme.color1,
        marginRight: '10px'
    },
    tag: {
        color: theme.mainTheme.color4
    },
    tagCC: {
        color: theme.mainTheme.color3
    },
    divider: {
        width: '100%',
        marginBottom: '1%',
        marginTop: '1%'
    },
    paddingRight: {
        paddingRight: '24px'
    },
    footer: {
        paddingRight: '24px',
        marginTop: theme.spacing(1)
    },
    spacing: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    spacingTag: {
        paddingTop: theme.spacing(1),
    },
    btn: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        paddingRight: '24px'
    },
    button: {
        width: '15%'
    }
}))

const Label = ({ label }) => {
    const classes = useStyle()
    return <label className={classes.label}>{label}</label>
}

const SignItem = ({
    signName,
    signEmpId,
    approveTypeName,
    aditTenseName,
    representBy,
    requestGroupId,
    // result,
    approveDate,
    aDate,
    aTime
}) => {
    const classes = useStyle()
    return (
        <Grid container>
            <Grid item xs={12} className={classes.spacingTag}>
                <label className={requestGroupId === 1 ? classes.tag : classes.tagCC}>{`${approveTypeName} (${requestGroupId === 1 ? 'ยื่นอนุญาต' : 'ยื่นยกเลิก'})`}</label>
            </Grid>
            <Grid item xs={12} md={6} className={classes.spacing} >
                <Label label="ชื่อ-นามสกุล : " />
                {signName}
            </Grid>
            <Grid item xs={12} md={6} className={classes.spacing}>
                <Label label="รหัสพนักงาน : " />
                {signEmpId}
            </Grid>
            <Grid item xs={12} md={6} className={classes.spacing}>
                <Label label="สถานะ : " />
                {aditTenseName}
            </Grid>
            {representBy && <Grid item xs={12} md={6} className={classes.spacing}>
                <Label label="ถูกทำการแทนโดย : " />
                {representBy}
            </Grid>}
            {/* {result === 1 && */}
            {approveDate && <Grid container>
                <Grid item xs={12} md={6} className={classes.spacing} >
                    <Label label="วันที่พิจารณา/อนุญาต : " />
                    {aDate}
                </Grid>
                <Grid item xs={12} md={6} className={classes.spacing}>
                    <Label label="เวลาที่พิจารณา/อนุญาต : " />
                    {aTime}
                </Grid>
            </Grid>}
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
        </Grid>
    )
}

const LeaveSAPSignByView = ({
    signBy = [],
    onCancelClick
}) => {
    const classes = useStyle()

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <span className={classes.title}>ลำดับการพิจารณา/อนุญาต</span>
            </Grid>
            <Grid item xs={12} className={classes.body}>
                <Scrollbars universal={true}>
                    <Grid container className={classes.paddingRight}>
                        {signBy.length ? signBy.map(sign => <SignItem
                            key={`${sign.absenceId}-${sign.empId}`}
                            signName={sign.name}
                            signEmpId={sign.empId}
                            approveTypeName={sign.approveTypeName}
                            aditTenseName={sign.aditTenseName}
                            requestGroupId={sign.requestGroupId}
                            representBy={sign.representBy}
                            approveDate={sign.approveDate}
                            // result={sign.result}
                            approveDate={sign.approveDate}
                            aDate={sign.aDate}
                            aTime={sign.aTime}
                        />) : <label>ไม่พบข้อมูล</label>}
                    </Grid>
                </Scrollbars>
            </Grid>
            <Grid container alignItems='flex-end' justify='center' className={classes.btn}>
                <Button
                    label='ปิด'
                    className={classes.button}
                    onClick={onCancelClick}
                />
            </Grid>
        </Grid>
    )
}

export default LeaveSAPSignByView
