import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

import DescriptionIconView from './DescriptionIconView';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        // zIndex: 10
    },
    saveBtn: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        zIndex: 1
    },
    cancelBtn: {
        position: 'absolute',
        top: '20px',
        right: '80px',
        zIndex: 1,
    },
    saveIcon: {
        fontSize: '25px',
        color: 'white'
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    rootSnackbar: {
        zIndex: 2,
        bottom: '50px',
        // backgroundColor:'red'
    },
    rootSnackbarContent: {
        minWidth: '128px',
        height: '50px',
        padding: '0px 10px',
        zIndex: 2,
        backgroundColor: theme.mainTheme.color3

    }
}))

const SnackbarWarning = ({ classes, isEnableSave, message }) => (
    <Snackbar
        open={isEnableSave}
        variant="warning"
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        classes={{ root: classes.rootSnackbar }}
    >
        <SnackbarContent
            classes={{ root: classes.rootSnackbarContent }}
            message={
                <span className={classes.message}>
                    <WarningIcon className={classes.icon} />
                    {message}
                </span>
            }
        />
    </Snackbar>
)

const OrgLeaveView = ({
    children,
    isEnableSave,
    isOrgSap
}) => {
    const classes = useStyles();

    return (
        <div id='rootOrgLeave' className={classes.root}>
            <div id='OrgChartContainer' />
            {children}
            <SnackbarWarning
                classes={classes}
                isEnableSave={isEnableSave || isOrgSap}
                message={isOrgSap ? "โครงสร้างองค์กรมีการเปลี่ยนแปลงโปรดตรวจสอบ" : isEnableSave ? "ยังไม่บันทึก" : ""}
            />
            <DescriptionIconView />
        </div>
    )
}

export default OrgLeaveView
