import React, { useState, useEffect, useContext } from 'react';
import arraySort from 'array-sort';
import { reduxForm, isDirty } from "redux-form";
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { OrgLeaveContext } from '../OrgLeaveProvider'

import DetailOrgView from '../../../presentational/orgLeave/panel/DetailOrgView';

const DetailOrg = ({
    handleSubmit,
    dirty,
    initialize,
    reset
}) => {
    const [empDisplay, setEmpDisplay] = useState([]);
    const [settingMode, setSettingMode] = useState(null)
    const { panelState, orgState, slinksState, permissionSystem } = useContext(OrgLeaveContext)

    useEffect(() => {
        const empFilter = orgState.emp.filter(emp => emp.orgId === panelState.dataDetailOrg.id)
        sortPosition(empFilter)
        setSettingMode(null)
    }, [panelState.dataDetailOrg, orgState.emp]) // eslint-disable-line react-hooks/exhaustive-deps

    const sortPosition = data => {
        const sortDate = arraySort(data, ['startDate'])
        const sortData = arraySort(sortDate, ['isManager', 'cLevel'], { reverse: true })
        const setManager = []
        const setAssistant = []

        data.forEach(item => {
            if (item.isManager) {
                setManager.push(item.id)
            }
        })

        initialize({
            M: setManager,
            A: setAssistant
        })

        setEmpDisplay(sortData)
    }

    const handleEdit = data => {
        panelState.setPanel("EDIT_EMP", data)
    }

    const handleSettingManager = (mode) => {
        setSettingMode(mode)

        if (!mode) {
            reset()
        }
    }

    const handleSaveClick = ({ M, A }) => {
        if (dirty) {
            const values = settingMode === 'M' ? M : A
            const objectEmpUpdate = {} // key จะเป็น id พนักงาน

            empDisplay.forEach(emp => {
                const bool = (values.indexOf(emp.id) !== -1)

                if (settingMode === 'M') {
                    emp.isManager = bool
                }

                objectEmpUpdate[emp.id] = emp
            })
            orgState.updatePosition(objectEmpUpdate)
        }
        setSettingMode(null)
    }

    return (
        <DetailOrgView
            isOpenAbsence={permissionSystem.searchAbsence}
            isSetManager={permissionSystem.isSetManager}
            empDisplay={empDisplay}
            representEmpName={panelState.dataDetailOrg.representEmpName}
            visibleSlink={slinksState.visibleSlink}
            settingMode={settingMode}
            onEdit={handleEdit}
            onVisible={slinksState.setSlink}
            onSettingManagerClick={handleSettingManager}
            onSaveClick={handleSubmit(handleSaveClick)}
        />
    )
}

const reduxFormParameter = {
    form: 'detailOrg',
};

const mapStateToProps = state => ({
    dirty: isDirty('detailOrg')(state),
})

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps, null)
)(DetailOrg)
