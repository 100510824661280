import React, { useContext, useEffect } from 'react';
import { reduxForm } from 'redux-form';

import { BookingContext } from '../BookingProvider';

import FormSearchBookingView from '../../../presentational/booking/form/FormSearchBookingView';

import { DATE_TIME } from '../../../../../constants/meeting/BookingConstants';

const FormSearchBooking = props => {
    const {
        handleSubmit,
        change,
        callbackSearch,
        initialize,
        reset,
        onExportClick
    } = props;

    const { bookingSearchState } = useContext(BookingContext);
    const { dateTime, setDateTime, durationHours, setDurationHours } = bookingSearchState

    // const [durationHours, setDurationHours] = useState(null)
    // const [dateTime, setDateTime] = useState({
    //     start: null,
    //     end: null
    // });

    useEffect(() => {
        if (bookingSearchState.valueSearch.strParams) {
            const { startDatetime, endDatetime } = bookingSearchState.valueSearch.values

            initialize(bookingSearchState.valueSearch.values);

            if (startDatetime && endDatetime) {
                setDateTime({
                    start: startDatetime,
                    end: endDatetime
                })
                setDurationHours(endDatetime - startDatetime)
            }
        } else {
            const initDate = new Date().getDate();
            const initMonth = new Date().getMonth();
            const initYear = new Date().getFullYear();

            const startDate = new Date(initYear, initMonth, initDate, 7, 30);
            const endDate = new Date(initYear, initMonth, initDate, 15, 30);
            setDurationHours(endDate - startDate)

            initialize({
                startDatetime: startDate,
                endDatetime: endDate
            });

            setDateTime({
                start: startDate,
                end: endDate
            });

            const values = {
                startDatetime: startDate,
                endDatetime: endDate
            };

            handleSearch(values);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearch = values => {
        const params = { ...values };

        if (values.startDatetime && values.endDatetime) {
            params.startDatetime = formatDateTime(values.startDatetime);
            params.endDatetime = formatDateTime(values.endDatetime);
        }

        let stringFormat = ``;
        for (const key in params) {
            if (params[key]) {
                stringFormat = `${stringFormat}&${key}=${params[key]}`;
            }
        }

        const strParams = stringFormat.slice(1, stringFormat.length);
        bookingSearchState.setValueSearch({
            strParams: strParams,
            values: values
        });
        callbackSearch(strParams);
    };

    const formatDateTime = dateTime => {
        const date = dateTime.getDate();
        const month = dateTime.getMonth() + 1;
        const year = dateTime.getFullYear();

        const time = dateTime.toTimeString().slice(0, 8);

        const monthStr = month.toString().length > 1 ? month : `0${month}`;
        const dateStr = date.toString().length > 1 ? date : `0${date}`;

        const dateTimeStr = `${year}-${monthStr}-${dateStr} ${time}`;

        return dateTimeStr;
    };

    const handleResetForm = () => {
        const initDate = new Date().getDate();
        const initMonth = new Date().getMonth();
        const initYear = new Date().getFullYear();

        const startDate = new Date(initYear, initMonth, initDate, 7, 30);
        const endDate = new Date(initYear, initMonth, initDate, 15, 30);

        setDateTime({
            start: startDate,
            end: endDate
        })
        setDurationHours(endDate - startDate)
        reset('FormSearchBooking');
    };

    const handleChangeDate = (type, dt) => {
        const dateTimeStart = typeof (dateTime.start) === 'number' ? new Date(dateTime.start) : dateTime.start
        const dateTimeEnd = typeof (dateTime.end) === 'number' ? new Date(dateTime.end) : dateTime.end

        switch (type) {
            case DATE_TIME.START:
                if (dt && dt >= dateTimeEnd) {
                    const newEndDate = JSON.parse(JSON.stringify(dt));
                    const useDate = new Date(newEndDate).setMilliseconds(new Date(newEndDate).getMilliseconds() + durationHours)

                    const isWrong = new Date(useDate).getFullYear() > new Date().getFullYear() + 5
                    if (isWrong) {
                        const nd = new Date(`${new Date().getFullYear() + 5}-12-31 23:59`)

                        change(
                            'endDatetime',
                            nd
                        );
                        setDateTime({
                            start: dt,
                            end: nd
                        });
                    } else {
                        change(
                            'endDatetime',
                            new Date(useDate)
                        );
                        setDateTime({
                            start: dt,
                            end: new Date(useDate)
                        });
                    }
                } else {
                    const duration = dateTimeEnd - dt
                    setDurationHours(duration)
                    setDateTime({
                        ...dateTime,
                        start: dt
                    })
                }

                break;
            case DATE_TIME.END:
                if (dt && dt <= dateTimeStart) {
                    const newStartDate = JSON.parse(JSON.stringify(dt));
                    const useDate = new Date(newStartDate).setMilliseconds(new Date(newStartDate).getMilliseconds() - durationHours)

                    const isWrong = new Date(useDate).getFullYear() < new Date().getFullYear() - 5
                    if (isWrong) {

                        const nd = new Date(`${new Date().getFullYear() - 5}-01-01 00:00`)

                        change(
                            'startDatetime',
                            nd
                        );
                        setDateTime({
                            start: new Date(nd),
                            end: dt
                        });

                    } else {
                        change(
                            'startDatetime',
                            new Date(useDate)
                        );
                        setDateTime({
                            start: new Date(useDate),
                            end: dt
                        });
                    }

                } else {
                    const duration = dt - dateTimeStart
                    setDurationHours(duration)
                    setDateTime({
                        ...dateTime,
                        end: dt
                    })
                }

                break;

            default:
                break;
        }
    };

    return (
        <FormSearchBookingView
            onSearchClick={handleSubmit(handleSearch)}
            onResetClick={handleResetForm}
            onExportClick={onExportClick}
            onChangeDateTime={handleChangeDate}
        />
    );
};

const validateFormSearch = values => {
    const NO_VALUE = 'ระบุอย่างน้อย 1 เงื่อนไข';
    const NEED_VALUE = 'กรุณาระบุข้อมูล';
    const errors = {};

    if (
        !values.startDatetime &&
        !values.endDatetime &&
        !values.roomName &&
        !values.chairman &&
        !values.topic &&
        !values.buildingName &&
        !values.floor &&
        !values.numberOfAttendees
    ) {
        // errors.startDatetime = NO_VALUE;
        // errors.endDatetime = NO_VALUE;
        // errors.roomName = NO_VALUE;
        // errors.chairman = NO_VALUE;
        // errors.topic = NO_VALUE;
        // errors.buildingName = NO_VALUE;
        // errors.floor = NO_VALUE;
        // errors.numberOfAttendees = NO_VALUE;
        errors.errorField = NO_VALUE
    } else if (values.startDatetime && !values.endDatetime) {
        errors.endDatetime = NEED_VALUE;
    } else if (!values.startDatetime && values.endDatetime) {
        errors.startDatetime = NEED_VALUE;
    }

    return errors;
};

const reduxFormParameter = {
    form: 'FormSearchBooking',
    validate: validateFormSearch
};

export default reduxForm(reduxFormParameter)(FormSearchBooking);
