import React, { Fragment } from 'react';

import { Field } from 'redux-form';

import { makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import { useSpring, animated } from 'react-spring';
import Button from '../../../../../control/button/Button';
import AutoCompleteMultiSelect from '../../../../../control/autocomplete/AutoCompleteMultiSelect';
import AutoComplete from '../../../../../control/autocomplete/AutoComplete';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(5, 6, 5),
        padding: theme.spacing(3),
        width: '800px',
        [theme.breakpoints.down('xs')]: {
            padding: '20px',
            // width: '100%',
            maxWidth: '300px'
        }
    },
    title: {
        fontSize: '30px',
        textAlign: 'center',
        marginBottom: '21px'
    },
    btn: {
        paddingTop: '20px'
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    },
    containerSearch: {
        marginBottom: theme.spacing(2)
    },
    spacing: {
        ...theme.form.spacing
    }
}));

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        }
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const FormModalSearchMeetingRoomView = props => {
    const classes = useStyles();
    const {
        onCloseFormClick,
        onSubmitClick,
        isOpen,
        lutOrgOption,
        lutMeetingRoomOption,
        onClickCheckbox,
        listSelectedMeetingRoomID,
        permisSearchByOrg,
        defaultValue,
        onChangeDropDown
    } = props;
    return (
        <Fragment>
            <Modal
                className={classes.modal}
                open={isOpen}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 700
                }}
            >
                <Fade in={isOpen}>
                    <div className={classes.paper}>
                        <div className={classes.title}>ค้นหาเพิ่มเติม</div>

                        <Grid
                            container
                            spacing={1}
                            className={classes.containerSearch}
                        >
                            {permisSearchByOrg && (
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className={classes.spacing}
                                >
                                    <Field
                                        name={'org'}
                                        component={AutoComplete}
                                        onChangeDropDown={onChangeDropDown}
                                        options={lutOrgOption}
                                        label={'หน่วยงานรับผิดชอบ'}
                                        optionKey={{
                                            label: 'fullName',
                                            value: 'orgId'
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                md={permisSearchByOrg ? 6 : 12}
                                // xs={permisSearchByOrg ? 5 : 12}
                                className={classes.spacing}
                            >
                                <Field
                                    name={'meetingRoomName'}
                                    component={AutoCompleteMultiSelect}
                                    options={lutMeetingRoomOption}
                                    label={'ชื่อห้องประชุม'}
                                    requiredNumber={true}
                                    optionKey={{
                                        label: 'nameForDisplay',
                                        value: 'roomId'
                                    }}
                                    onClickCheckbox={onClickCheckbox}
                                    listSelectedCheckbox={
                                        listSelectedMeetingRoomID
                                    }
                                    defaultValue={defaultValue}
                                    isCircle
                                // required
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid container justify="space-around">
                            {permisSearchByOrg && (
                                <Grid item xs={5}>
                                    <Field
                                        name={'org'}
                                        component={AutoComplete}
                                        onChangeDropDown={onChangeDropDown}
                                        options={lutOrgOption}
                                        label={'หน่วยงานรับผิดชอบ'}
                                        optionKey={{
                                            label: 'orgName',
                                            value: 'orgId'
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={5} >
                                <Field
                                    name={'meetingRoomName'}
                                    component={AutoCompleteMultiSelect}
                                    options={lutMeetingRoomOption}
                                    label={'ชื่อห้องประชุม (81)'}
                                    optionKey={{
                                        label: 'roomName',
                                        value: 'roomId'
                                    }}
                                    onClickCheckbox={onClickCheckbox}
                                    listSelectedCheckbox={
                                        listSelectedMeetingRoomID
                                    }
                                    defaultValue={defaultValue}
                                    // required
                                />
                            </Grid>
                        </Grid> */}

                        <Grid
                            container
                            spacing={2}
                            justify="center"
                            className={classes.btn}
                        >
                            <Grid item>
                                <Button
                                    label="ยกเลิก"
                                    onClick={onCloseFormClick}
                                    className={classes.btnCancel}
                                />
                            </Grid>
                            <Grid item>
                                <Button label="ค้นหา" onClick={onSubmitClick} />
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </Fragment>
    );
};

export default FormModalSearchMeetingRoomView;
