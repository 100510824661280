import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'

import TableList from '../../../container/booking/bookingList/TableList'

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        marginBottom: '25px',
        ...theme.paperPadding
    }
}));

const TableListView = () => {
    const classes = useStyles()
    return (
        <Grid container justify='center'>
            <Paper className={classes.paper}>
                <TableList />
            </Paper>
        </Grid>
    )
}

export default TableListView