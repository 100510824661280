import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import RouteConfig from '../config/appRoute/RouteConfig';
import { FUNCTIONS } from '../constants/APDConstants';

// import Usergroup from '../components/um/container/usergroup/Usergroup';
import Usergroup from '../components/um/container/usergroup/UserGroupIndex';
import User from '../components/um/container/user/User';

function Um({ authenRdc }) {
    const router = [];
    authenRdc.profile.functions.forEach(func => {
        switch (func.functionId) {
            case FUNCTIONS.USER_GROUP.id:
                router.push(
                    <Route
                        // exact
                        key={RouteConfig.UM_USER_GROUP}
                        path={RouteConfig.UM_USER_GROUP}
                        component={Usergroup}
                    />
                );
                break;
            case FUNCTIONS.USER.id:
                router.push(
                    <Route
                        key={RouteConfig.UM_USER}
                        path={RouteConfig.UM_USER}
                        component={User}
                    />
                );
                break;
            default:
                break;
        }
    });

    return (
        <Switch>
            {router}
            <Route
                path={RouteConfig.UM}
                render={() => {
                    const pathRedirect = router.length ? router[0].key : '/';
                    return <Redirect to={pathRedirect} />;
                }}
            />
        </Switch>
    );
}

const mapStateToProps = state => ({
    authenRdc: state.authen
});

export default connect(mapStateToProps, null)(Um);
