import React, { createContext } from 'react'

import UseLoadingState from '../../../../control/loading/UseLoadingState'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseDataTableState from '../../../../control/table/UseDataTableState'
import UseForceUpdaeState from './useStateForceUpdate/UseForceUpdaeState'

export const ForceUpdateContext = createContext()

const ForceUpdateProvider = ({ children }) => {

    const loadingState = UseLoadingState()
    const alertMessageState = UseAlertMessageState()
    const forceUpdaeState = UseForceUpdaeState()
    const dataTableForceUpdateState = UseDataTableState({
        rowPerPage: 10
    })

    const value = {
        loadingState,
        alertMessageState,
        forceUpdaeState,
        dataTableForceUpdateState
    }

    return (

        <ForceUpdateContext.Provider value={value} >
            {children}
        </ForceUpdateContext.Provider>
    )
}

export default ForceUpdateProvider
