import React, { useState } from 'react'
import { reduxForm, formValueSelector } from "redux-form";
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { ERRORS } from '../../../../../constants/APDConstants'
import { getDepId } from '../../../../../utilities/Utils'
import { getLogAbsenceByDate } from '../../../../../api/ApiHoliday'
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'
import * as HolidayConstants from '../../../../../constants/leave/HolidayConstants'
import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'

import ExportHolidayView from '../../../presentational/holiday/ExportHolidayView'

const { FORM } = HolidayConstants

const ExportHoliday = (props) => {

    const [dataLogAbsence, setDataLogAbsence] = useState([])

    const {
        handleSubmit,
        logAbsence,
        lutHoliday,
        loadingState,
        alertMessageState,
        date,
        onCloseFormClick
    } = props

    // const handleExport = ({ date }) => {
    //     if (date) {
    //         logAbsence(date)
    //     }
    // }
    const handleExport = ({ date }) => {
        logAbsence(dataLogAbsence, date)
    }

    const logImpactAbsence = async date => {
        const { permissionOrgId } = props.authenRdc.profile
        const depId = getDepId(props.authenRdc.profile)

        loadingState.showLoading();
        const res = await getLogAbsenceByDate(date, { permissionOrgId, depId })
        loadingState.hideLoading()
        if (res && res.data) {
            const datasource = res && res.data ? res.data : [];
            setDataLogAbsence(datasource)
            // LogAbsenceExcel(datasource, date, 'export');
            // handleControlFormExport();
        } else {
            setDataLogAbsence([])
            const msg = res ? checkResStatusService(res) : null
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR
            });
        }
    };

    const handleChangeDate = values => {

        if (values && values.date) {
            logImpactAbsence(values.date)
        } else {
            setDataLogAbsence([])
        }
    }

    return (
        <ExportHolidayView
            lutHoliday={lutHoliday}
            date={date}
            countLogAbsence={dataLogAbsence.length}
            onSubmitClick={handleSubmit(handleExport)}
            onCloseFormClick={onCloseFormClick}
            onChangeDate={handleChangeDate}
        />
    )
}

const validateExport = (values) => {
    const errors = {}

    if (!values.date) {
        errors.date = ERRORS.REQUIRED
    }

    return errors
}

const reduxFormParameter = {
    form: 'exportHoliday',
    validate: validateExport
}
const selector = formValueSelector('exportHoliday')

const mapStateToProps = state => ({
    authenRdc: state.authen,
    date: selector(state, FORM.NAME.HOLIDAY_DAY),
});

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps, null)
)(ExportHoliday)