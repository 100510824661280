import React from 'react'

import LeaveSAPTableView from '../../presentational/leavesap/LeaveSAPTableView'

const LeaveSAPTable = (props) => {

    const {
        dataTableState,

        onEdit,
        onDetail
    } = props

    const handleRowClick = (data, type) => {
        if (type === 'edit') {
            onEdit(data)
        } else if (type === 'detail') {
            onDetail(data)
        }
    }

    return (
        <LeaveSAPTableView
            dataTableState={dataTableState}
            onRowClick={handleRowClick}
        />
    )
}

export default LeaveSAPTable