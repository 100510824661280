import {
    fetchGet,
    fetchPut
} from '../utilities/ServiceUtils';
import * as ApiConstants from '../constants/ApiConstants';

import { API } from '../constants/ServiceMSGConstants'

export const getAllTemplate = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.GET_TEMPLATE}?${new Date().getTime()}`);
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.CONNEXT };
        }
        const res = await fetchData.json();

        return res;
    } catch (err) {
        console.log('ex getAllTemplate :', err);
        return err
    }
};

export const updateTemplate = async ({ templateId, messageTH, messageEN }) => {
    try {
        const fetchData = await fetchPut(`${ApiConstants.UPDATE_TEMPLATE}/${templateId}`, { messageTH: messageTH && messageTH.trimEnd(), messageEN: messageEN && messageEN.trimEnd() });
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.CONNEXT };
        }
        const res = await fetchData.json();

        return res;
    } catch (err) {
        console.log('ex updateTemplate :', err);
        return err
    }
};