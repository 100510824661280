import React, { createContext, useState } from 'react'

export const RouterContext = createContext()

const RouterProvider = ({ children }) => {
    const [option, setOption] = useState({ callbackRouteChange: null })

    const handleSetCallbackRouteChange = (func) => {
        setOption({
            ...option,
            callbackRouteChange: func
        })
    }

    const value = {
        callbackRouteChange: option.callbackRouteChange,
        setCallbackRouteChange: handleSetCallbackRouteChange
    }

    return (
        <RouterContext.Provider value={value} >
            {children}
        </RouterContext.Provider>
    )
}

export default RouterProvider
