import React, { Fragment, useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';

import * as ApiUserProfile from '../../api/ApiUserProfile';
import * as ApiLookup from '../../api/ApiLookup';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

import { checkResStatusService } from '../../utilities/ResServiceUtils';
import { formatDate } from '../../utilities/DatetimeUtils';
import * as AlertMSGConstants from '../../constants/AlertMSGConstants';
import Loading from '../../control/loading/Loading';
import UseLoadingState from '../../control/loading/UseLoadingState';
import AlertMessage from '../../control/alert/AlertMessage';
import UseAlertMessageState from '../../control/alert/UseAlertMessageState';
import Button from '../../control/button/Button';
import DataTable from '../../control/table/DataTable';
import DatePicker from '../../control/fdatetime/DateflatPicker';
import AutoComplete from '../../control/autocomplete/AutoComplete';
import TextFields from '../../control/textfield/TextFields';

import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
dayjs.extend(buddhistEra);

const columns = [
    {
        title: 'วันที่ขอเปลี่ยนแปลง',
        data: 'createdDate',
        width: '15%',
    },
    {
        title: 'ประเภทข้อมูล',
        data: 'userInfoGroupNameFromSap',
        width: '15%',
    },
    {
        title: 'รหัสพนักงาน',
        data: 'empId',
        width: '10%',
    },
    {
        title: 'ชื่อ-นามสกุล',
        data: 'name',
        width: '20%',
        align: 'left',
    },
    {
        title: 'ฝ่าย',
        data: 'depName',
        width: '15%',
        align: 'center',
    },
    {
        title: 'หน่วยงาน',
        data: 'orgName',
        width: '15%',
    },
    {
        title: 'สถานะการส่ง SAP',
        data: 'sapResult',
        width: '10%',
    },
];

const pageSize = 10;

const changedDataColumns = [
    {
        title: 'หัวข้อ',
        data: 'fieldName',
        width: '30%',
        align: 'left',
    },
    {
        title: 'ข้อมูลก่อนการเปลี่ยนแปลง',
        data: 'oldValue',
        width: '35%',
        align: 'left',
    },
    {
        title: 'ข้อมูลที่ขอเปลี่ยนแปลง',
        data: 'newValue',
        width: '35%',
        align: 'left',
    },
];

const useStyles = makeStyles((theme) => ({
    spacing: {
        ...theme.form.spacing,
    },
    root: {
        padding: '24px',
    },
    rootPaper: {
        width: '100%',
        ...theme.paperPadding,
    },
    divider: {
        margin: '24px 0px',
    },
    tableCell: {
        padding: '20px',
    },
    btnSearch: {
        width: '105px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    btnReset: {
        backgroundColor: `${theme.mainTheme.color6} !important`,
        width: '105px',
        marginRight: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: '0px',
            marginBottom: '2%',
        },
    },
    btnCloseModal: {
        backgroundColor: '#808080 !important',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        maxHeight: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: theme.palette.background.paper,
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    body: {
        padding: theme.spacing(3),
    },
    label: {
        fontWeight: 'bold',
    },
    modalItemTable: {
        minWidth: 'auto',
    },
}));

const defaultValue = {
    empId: '',
    startDate: '',
    endDate: '',
    orgId: null,
    sapResult: null,
    userInfoGroupIdFromSap: null,
};

const EditingHistory = ({ initialize, reset }) => {
    const classes = useStyles();
    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();
    const formValues = useSelector((state) =>
        getFormValues('EditingHistorySearchForm')(state)
    );
    const [modalItem, setModalItem] = useState(undefined);
    const [tableStates, setTableStates] = useState({
        data: [],
        page: 1,
        total: 0,
    });
    const [lookups, setLookups] = useState({
        sapResult: [
            {
                id: '1',
                label: 'รอส่งSAP',
            },
            {
                id: '2',
                label: 'สำเร็จ',
            },
            {
                id: '3',
                label: 'ไม่สำเร็จ',
            },
        ],
        userInfoGroupIdFromSap: [
            {
                id: '2',
                label: 'ชื่อภาษาไทย',
            },
            {
                id: '6',
                label: 'ข้อมูลการติดต่อ',
            },
            {
                id: '0182',
                label: 'ชื่อภาษาอังกฤษ',
            },
            {
                id: '0185',
                label: 'ข้อมูลบัตรประชาชน',
            },
        ],
        managementOrganization: [],
    });

    useEffect(() => {
        getLookups();
        fetchData(1);
        initialize({ ...defaultValue });
    }, []);

    const getLookups = async () => {
        loadingState.showLoading();
        const response = await ApiLookup.getLutManagementOrganization();
        loadingState.hideLoading();
        if (response.data) {
            setLookups({
                ...lookups,
                managementOrganization: response.data,
            });
        } else {
            alertMessageState.showAlert({
                altMsg:
                    checkResStatusService(response) ||
                    AlertMSGConstants.SYSTEM_ERROR,
            });
        }
    };

    const fetchData = async (page) => {
        loadingState.showLoading();
        const {
            empId,
            startDate,
            endDate,
            orgId,
            sapResult,
            userInfoGroupIdFromSap,
        } = formValues || {};
        const response = await ApiUserProfile.searchEditingHistory({
            empId: empId || '',
            startDate: startDate ? formatDate(startDate) : '',
            endDate: endDate ? formatDate(endDate) : '',
            orgId: orgId || '',
            sapResult: sapResult || '',
            userInfoGroupIdFromSap: userInfoGroupIdFromSap || '',
            offset: (page - 1) * pageSize,
            limit: pageSize,
        });
        loadingState.hideLoading();
        if (response.data) {
            setTableStates({
                data: response.data.transactions.map((item) =>
                    getRowData(item)
                ),
                page,
                total: response.data.count,
            });
        } else {
            alertMessageState.showAlert({
                altMsg:
                    checkResStatusService(response) ||
                    AlertMSGConstants.SYSTEM_ERROR,
            });
        }
    };

    const getRowData = (item) => {
        return {
            ...item,
            createdDate: dayjs(item.createdDate)
                .locale('th')
                .format('D MMM BBBB'),
            name: [item.firstName, item.lastName]
                .filter((item) => !!item)
                .join(' '),
            detail: true,
        };
    };

    const fetchDetails = async (transactionId) => {
        loadingState.showLoading();
        const response = await ApiUserProfile.getTransaction(transactionId);
        loadingState.hideLoading();
        if (response.data) {
            setModalItem(response.data);
        } else {
            alertMessageState.showAlert({
                altMsg:
                    checkResStatusService(response) ||
                    AlertMSGConstants.SYSTEM_ERROR,
            });
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'รอส่ง SAP':
                return '#ffcc00';
            case 'สำเร็จ':
                return '#016f01';
            case 'ไม่สำเร็จ':
                return '#ff0505';
            default:
                return undefined;
        }
    };

    const handleClickReset = () => {
        reset('EditingHistorySearchForm');
    };

    const handleClickSearch = () => {
        fetchData(1);
    };

    const handleRowClick = (item) => {
        fetchDetails(item.transactionId);
    };

    const handleClickCloseModal = () => {
        setModalItem(undefined);
    };

    return (
        <Fragment>
            <Scrollbars universal={true}>
                <Grid container className={classes.root}>
                    <Paper className={classes.rootPaper}>
                        <Grid
                            container
                            spacing={1}
                            className={classes.containerSearch}
                        >
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={classes.spacing}
                            >
                                <Field
                                    component={DatePicker}
                                    name="startDate"
                                    label="วันที่ขอเปลี่ยนแปลง เริ่มต้น"
                                    placeholder="วันที่ขอเปลี่ยนแปลง เริ่มต้น"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={classes.spacing}
                            >
                                <Field
                                    component={DatePicker}
                                    name="endDate"
                                    label="วันที่ขอเปลี่ยนแปลง สิ้นสุด"
                                    placeholder="วันที่ขอเปลี่ยนแปลง สิ้นสุด"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={classes.spacing}
                            >
                                <Field
                                    component={TextFields}
                                    name="empId"
                                    label="รหัสพนักงาน"
                                    placeholder="รหัสพนักงาน"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={classes.spacing}
                            >
                                <Field
                                    component={AutoComplete}
                                    name="orgId"
                                    label="หน่วยงาน"
                                    optionKey={{
                                        value: 'orgId',
                                        label: 'name',
                                    }}
                                    options={lookups.managementOrganization}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={classes.spacing}
                            >
                                <Field
                                    component={AutoComplete}
                                    name="sapResult"
                                    label="สถานะการส่ง SAP"
                                    optionKey={{
                                        value: 'id',
                                        label: 'label',
                                    }}
                                    options={lookups.sapResult}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={classes.spacing}
                            >
                                <Field
                                    component={AutoComplete}
                                    name="userInfoGroupIdFromSap"
                                    label="ประเภทข้อมูล"
                                    optionKey={{
                                        value: 'id',
                                        label: 'label',
                                    }}
                                    options={lookups.userInfoGroupIdFromSap}
                                />
                            </Grid>

                            <Grid item xs={12} container justify="flex-end">
                                <Button
                                    label="ค่าเริ่มต้น"
                                    className={classes.btnReset}
                                    onClick={handleClickReset}
                                />
                                <Button
                                    className={classes.btnSearch}
                                    label="ค้นหา"
                                    onClick={handleClickSearch}
                                />
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item xs={12}>
                            <Grid
                                item
                                xs={12}
                                container
                                justify="space-between"
                                className={classes.titleResult}
                            >
                                <Grid
                                    item
                                    style={{
                                        fontSize: '33px',
                                        marginLeft: '5px',
                                    }}
                                >
                                    ผลลัพธ์การค้นหา (
                                    {`${tableStates.total} รายการ`})
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <DataTable
                                    classes={{ tableCell: classes.tableCell }}
                                    column={columns}
                                    rowPerPage={pageSize}
                                    notSort={true}
                                    page={tableStates.page}
                                    data={tableStates.data}
                                    total={tableStates.total}
                                    handlePageChange={fetchData}
                                    handleRowClick={handleRowClick}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Scrollbars>
            <Modal className={classes.modal} open={!!modalItem}>
                {modalItem && (
                    <Paper className={classes.paper}>
                        <Grid
                            container
                            justify="center"
                            style={{ overflow: 'hidden' }}
                        >
                            <Grid
                                container
                                className={classes.body}
                                spacing={2}
                            >
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2">
                                        <label
                                            className={classes.label}
                                            style={{
                                                width: 'fit-content',
                                                borderBottom:
                                                    'solid 2px #F47B15',
                                                marginBottom: '-1.25px',
                                            }}
                                        >
                                            รายละเอียดการเปลี่ยนแปลง:
                                        </label>{' '}
                                        {modalItem.userInfoGroupNameFromSap}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2">
                                        <label className={classes.label}>
                                            สถานะการส่ง SAP:
                                        </label>{' '}
                                        <label
                                            style={{
                                                paddingLeft: 4,
                                                paddingRight: 4,
                                                borderRadius: 4,
                                                border: 'solid 2px',
                                                borderColor: getStatusColor(
                                                    modalItem.sapResult
                                                ),
                                                color: getStatusColor(
                                                    modalItem.sapResult
                                                ),
                                            }}
                                        >
                                            {modalItem.sapResult}
                                        </label>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2">
                                        <label className={classes.label}>
                                            รหัสพนักงาน:
                                        </label>{' '}
                                        {modalItem.empId || ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2">
                                        <label className={classes.label}>
                                            ชื่อ-นามสกุล:
                                        </label>{' '}
                                        {[
                                            modalItem.firstName,
                                            modalItem.lastName,
                                        ]
                                            .filter((item) => !!item)
                                            .join(' ')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2">
                                        <label className={classes.label}>
                                            วันที่ขอเปลี่ยนแปลง:
                                        </label>{' '}
                                        {modalItem.createdDate
                                            ? dayjs(modalItem.createdDate)
                                                  .locale('th')
                                                  .format('D MMM BBBB')
                                            : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2">
                                        <label className={classes.label}>
                                            วันที่นำส่ง SAP:
                                        </label>{' '}
                                        {modalItem.sapImportedDate
                                            ? dayjs(modalItem.sapImportedDate)
                                                  .locale('th')
                                                  .format('D MMM BBBB')
                                            : ''}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2">
                                        <label className={classes.label}>
                                            เอกสารแนบ:
                                        </label>{' '}
                                        {modalItem.attachments.length > 0
                                            ? modalItem.attachments.map(
                                                  (item, index) => [
                                                      <a
                                                          key={index}
                                                          href={`${item.url}?apiKey=${process.env.REACT_APP_API_KEY}`}
                                                          target="blank"
                                                      >
                                                          {item.fileName}
                                                      </a>,
                                                      index <
                                                      modalItem.attachments
                                                          .length -
                                                          1
                                                          ? ', '
                                                          : undefined,
                                                  ]
                                              )
                                            : ''}
                                    </Typography>
                                </Grid>
                                {formValues.sapResult === '3' && (
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2">
                                            <label className={classes.label}>
                                                เหตุผลการนำเข้า SAP ไม่สำเร็จ:
                                            </label>{' '}
                                            {modalItem.sapResultMessage || ''}
                                        </Typography>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <DataTable
                                        classes={{
                                            table: classes.modalItemTable,
                                            tableCell: classes.tableCell,
                                        }}
                                        column={changedDataColumns}
                                        notSort={true}
                                        hidePagination={true}
                                        data={modalItem.changedData}
                                    />
                                </Grid>
                                <Grid container spacing={2} justify="center">
                                    <Grid item>
                                        <Button
                                            label="ปิด"
                                            className={classes.btnCloseModal}
                                            onClick={handleClickCloseModal}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </Modal>
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    );
};

export default reduxForm({
    form: 'EditingHistorySearchForm',
})(EditingHistory);
