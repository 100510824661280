import { isEqual, differenceWith } from 'lodash'

export const getHashValue = (key) => {
    const matches = window.location.hash.match(new RegExp(key + '=([^&]*)'));
    return matches ? matches[1] : null;
}

export const getUrlParam = key => {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(key)
}

export const checkSuccessResService = (data) => { // {error: [], data: [{message: "Success"}]}
    let isSuccess = false

    // if (data[0] && data[0].message === "Success") {
    if (data[0] && data[0].message.toUpperCase() === "SUCCESS") {
        isSuccess = true
    }

    return isSuccess;
}

export const checkDifJson = (Json1, Json2) => { // ถ้าไม่เหมือนกัน retrun true
    const checkDifJson = differenceWith(Json1, Json2, isEqual)

    return checkDifJson.length > 0
}

export const getDepId = (profile) => { // ส่ง authenRdc เข้ามา
    const { divId, depId, orgId } = profile

    if (depId) { // กรณีอยู่ตั้งแต่ฝ่ายลงมา
        return depId
    } else if (divId) { // กรณี สมส
        return divId
    } else { //อยู่หน่วยงานระดับสูง
        return orgId
    }
}

export const checkWidthDown = (width, size) => { // ส่ง sm มาจะ reture true เมื่อ with = xs, sm
    const breakPoint = ['xs', 'sm', 'md', 'lg', 'xl']
    let isRange = false
    let isBreak = false

    breakPoint.forEach(item => {
        if (!isBreak) {
            if (item === width) {
                isRange = true
            }
        }

        if (item === size) {
            isBreak = true
        }
    })

    return isRange
}

export const checkWidthUp = (width, size) => { // ส่ง sm มาจะ reture true เมื่อ with = sm, md, lg, xl
    const breakPoint = ['xs', 'sm', 'md', 'lg', 'xl'].reverse()
    let isRange = false
    let isBreak = false

    breakPoint.forEach(item => {
        if (!isBreak) {
            if (item === width) {
                isRange = true
            }
        }

        if (item === size) {
            isBreak = true
        }
    })

    return isRange
}