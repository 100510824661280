import React, { Fragment, useEffect } from 'react'
import { reduxForm } from 'redux-form'

//state
import UseLoadingState from '../../../../control/loading/UseLoadingState'
import Loading from '../../../../control/loading/Loading'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import AlertMessage from '../../../../control/alert/AlertMessage'

//component
import FormUserView from '../../presentational/user/FormUserView'

//api
import { updateUser } from '../../../../api/ApiUser'
import { logout } from '../../../../api/ApiAuthen'

//constants
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'
import { clearTokenLocalStore } from '../../../../utilities/LocalStorageUtils'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'
import { SERVICE_STATUS_CODE, SV_RES_SUCCESS } from '../../../../constants/APDConstants'
import RouteConfig from '../../../../config/appRoute/RouteConfig'

const EditUser = (props) => {

    const {
        open,
        textTitle,
        onCancelClick,
        lutUser,
        data,
        initialize,
        reset,
        handleSubmit,
        profile,
        callbackSaveSuccess
    } = props

    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();

    useEffect(() => {
        initialize(data)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //------------ when cancel editing -----------------------//
    const handleCancelEdit = () => {
        reset()
        onCancelClick()
    }

    //----------- when click save ---------------------------//
    const handleSubmitEdit = (values) => {
        const { userId, userGroupId, empId } = values
        const isMyUser = empId === profile.empId

        loadingState.showLoading()
        updateUser(userId, userGroupId, profile.empId).then(res => {
            loadingState.hideLoading()
            let msg = checkResStatusService(res)
            if (res && res.data) {
                const isSuccess = res.data[0].message.toLowerCase() === SV_RES_SUCCESS
                const altMsg = isSuccess ? res.data[1] ? AlertMSGConstants.UPDATE_U_MAINTAINER_FAIL : AlertMSGConstants.SAVE_SUCCESS : AlertMSGConstants.SAVE_FAIL

                alertMessageState.showAlert({
                    altMsg: altMsg,
                    callbackCancel: () => {
                        isMyUser ? handleLogout() : callbackSaveSuccess()
                    }
                })
            } else {
                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR
                    // callbackCancel: () => {
                    //     isMyUser ? handleLogout() : handleCancelEdit()
                    // }
                })
            }
        })
    }

    //----------------- เมื่อ user ที่แก้ไขเป็นตัวเอง จะต้อง logout ---------------------//
    const handleLogout = async () => {
        const statusLogout = await logout()
        if (statusLogout === SERVICE_STATUS_CODE.SUCCESS) {
            clearTokenLocalStore();
            window.location = process.env.REACT_APP_ROOT_URL + RouteConfig.LOGIN
        } else {
            const msg = checkResStatusService(statusLogout)
            alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.LOGOUT_FAIL })
        }
    }

    return (
        <Fragment>
            <FormUserView
                open={open}
                textTitle={textTitle}
                onCancelClick={handleCancelEdit}
                lutUser={lutUser}
                onSubmitClick={handleSubmit(handleSubmitEdit)}
            />
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    )
}

const reduxFormParameter = {
    form: 'editUser'
}

export default reduxForm(reduxFormParameter)(EditUser)