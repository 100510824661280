import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import DataTable from '../../../../control/table/DataTable'
import { COLUMN } from '../../../../constants/maintenance/ForceUpdateConstants'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    container: {
        padding: '24px'
    },
    rootPaper: {
        width: '100%',
        ...theme.paperPadding
    },
}))

const ForceUpdateTableView = ({
    dataTableState,
    onRowClick
}) => {
    const classes = useStyles()

    return (
        <Grid container justify='center' className={classes.root}>
            <Scrollbars universal={true}>
                <Grid container className={classes.container}>
                    <Paper className={classes.rootPaper}>
                        <DataTable
                            column={COLUMN}
                            data={dataTableState.dataPage}
                            page={dataTableState.page}
                            rowPerPage={dataTableState.rowPerPage}
                            total={dataTableState.total}
                            sortColumn={dataTableState.sortColumn}
                            sortType={dataTableState.sortType}
                            classes={{ tableCell: classes.tableCell }}

                            handlePageChange={dataTableState.changePage}
                            handleRowClick={onRowClick}
                            handleSortExternal={dataTableState.sortData}
                            handleRowPerPage={() => { }}
                        />
                    </Paper>
                </Grid>
            </Scrollbars>
        </Grid>
    )
}

export default ForceUpdateTableView