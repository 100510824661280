import React, { useState } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

// import { makeStyles } from '@material-ui/styles'

// const useStyles = makeStyles(theme => ({
//     div: {
//         '&:hover': {

//         }
//     }
// }))

const ColorPicker = (props) => {

    const { callbackColor } = props
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [cbColor, setCbColor] = useState(props.cbColor)
    // const [color, setColor] = useState({
    //     r: 244,
    //     g: 123,
    //     b: 21,
    //     a: 1
    // })

    const handleClick = () => {
        setDisplayColorPicker(true)
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
        callbackColor(cbColor)
    };

    const handleChange = (color) => {
        // setColor(color.rgb)
        setCbColor(color.hex)
    };

    const styles = reactCSS({
        'default': {
            root: {
                height: '90px',
                width: '100%'
            },
            span: {
                height: '28px',
                color: '#F47B15'
            },
            color: {
                borderRadius: '2px',
                // background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
                backgroundColor: `${cbColor}`,
                width: '100%',
                height: '100%'
            },
            sket: {
                width: '100%',
                height: '40px',
                border: '1px solid #E9E9E9',
                borderRadius: '5px'
            },
            swatch: {
                padding: '7px',
                background: '#fff',
                borderRadius: '5px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                width:'100%',
                height: '100%'
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        }
    });

    return (
        <div style={ styles.root }>
            <div style={ styles.span }>
                <span>สีห้องประชุม</span>
            </div>
            <div style={ styles.sket }>
                <div style={ styles.swatch } onClick={ handleClick }>
                    <div style={ styles.color } />
                </div>
                { displayColorPicker ? 
                    <div style={ styles.popover }>
                        <div style={ styles.cover } onClick={ handleClose }/>
                        <SketchPicker color={ cbColor } onChange={ handleChange } />
                    </div> : 
                    null 
                }
            </div>
        </div>
    )
}

export default ColorPicker