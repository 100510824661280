import React from 'react'
import { Field } from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { UM_USER_GROUP } from '../../../../constants/APDConstants'

import Button from '../../../../control/button/Button'
import TextFields from '../../../../control/textfield/TextFields'
import CheckboxTree from '../../../../control/checkboxTree/CheckboxTree'
import RadioButton from '../../../../control/radio/RadioButton'

const { NAME, LABEL } = UM_USER_GROUP.FORM_USER_GROUP

const useStyles = makeStyles(theme => ({
    footer: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    btnUser: {
        width: '100%',
        marginTop: '8px'
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    },
    btnGrid: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: '0 !important'
        }
    },
    rootItem: {
        height: '40px'
    }
}))

const FormUserGroupView = ({
    lutPermissionOrg,
    lutFunction,
    callBackCheckFunction,

    onOpenUserForm,
    onSubmit,
    onCancel,
}) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
                <Field
                    name={NAME.GROUP_NAME}
                    label={LABEL.GROUP_NAME}
                    component={TextFields}
                    placeholder={`ชื่อกลุ่ม`}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={3} container alignItems="center" className={classes.btnGrid}>
                <Button label="เพิ่มผู้ใช้งาน" onClick={onOpenUserForm} className={classes.btnUser} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Field
                    name={NAME.FUNCTION_DISPLAY}
                    label={LABEL.FUNCTION_DISPLAY}
                    component={CheckboxTree}
                    isSearch={false}
                    callBackCheck={callBackCheckFunction}
                    options={lutFunction}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid xs={12} item>
                    <Field
                        name={NAME.PERMISSION_ORG}
                        label={LABEL.PERMISSION_ORG}
                        component={RadioButton}
                        options={lutPermissionOrg}
                        classes={{ rootItem: classes.rootItem }}
                        xs={12}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center" spacing={2} className={classes.footer}>
                <Grid item><Button label="ยกเลิก" onClick={onCancel} className={classes.btnCancel} /></Grid>
                <Grid item><Button label="บันทึก" onClick={onSubmit} /></Grid>
            </Grid>
        </Grid>
    )
}

export default FormUserGroupView
