import React from 'react';

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

import { normalizeNumber } from '../../utilities/Validate'
import { Field } from 'redux-form';
import {TextFieldWithUnit} from '../textfield/TextFields'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        color: 'black'
    },
    rootWithText: {
        paddingRight: '10px'
    },
    label: {
        color: theme.mainTheme.color1,
        fontSize: theme.fontSize.body1
    },
    divStyle: {
        width: '100%',
        height: '30px'
    },
    labelName: {
        // cursor: 'pointer',
        fontSize: theme.fontSize.body1
    },
    labelRequire: {
        color: theme.mainTheme.color1,
    },
    formHelper: {
        marginTop: '1px',
        marginLeft: '10px',
        color: theme.mainTheme.color5
    },
    errorText: {
        color: theme.mainTheme.color5
    },
    subLabel: {
        marginLeft: '20px',
        marginTop: '10px',
        color: ' rgba(0, 0, 0, 0.54)'
    },
    textFieldWithUnit: {
        [`& input`]: {
            height: '10px',
        },
    },
    styleIcon: {
        '& svg' : {
            width: '23px',
            height: '23px',
            color: theme.mainTheme.color1
        }
    }
}));

export const CheckBoxWithText = ({
    isDisplayRow, // ถ้า true จะแสดงผลแนวนอน
    subLabel,
    required,
    options,// [{id,name}]
    input,
    meta: { touched, invalid, error },
    disabled,
    nameFields
}) => {

    const classes = useStyles();

    const checkFn = (evt,input,option) => {
        const newValue = [...input.value]

        const isNewValue = newValue.filter(item => item.equipmentId === option.id).length < 1

        if(isNewValue) {
            newValue.push({ equipmentId: option.id, quantity: ""})
        } else {
            newValue.splice(newValue.findIndex((item) => item.equipmentId === option.id), 1)
        }

        return input.onChange(newValue)
    }

    const textFn = (event,input,option) => {
        const newValue = [...input.value]

        const newValueArr = newValue.map(item => {
            if(item.equipmentId === option.id) {
                item.quantity = event.target.value
            }
            return item
        })

        return input.onChange(newValueArr)
    }

    return (
        <div className={classes.rootWithText}>
            <FormControl component="fieldset" style={{width: '100%'}}>
                <FormGroup row={isDisplayRow} >

                    {options.map((option, index) => (
                        <Grid item xs={12} md={6} key={`${nameFields}-${index}`}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Checkbox
                                        disabled={disabled}
                                        color="primary"
                                        checked={input.value && input.value.filter(item => item.equipmentId === option.id).length > 0 ? true : false}
                                        name={`${nameFields}[${index}][equipmentId]`}
                                        onChange={event => {
                                            checkFn(event,input,option)
                                        }}
                                        className={classes.styleIcon}
                                    />
                                    <label className={classes.labelName}>{option.name}</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <Field 
                                        name={input.value && `${nameFields}[${input.value.findIndex((item) => item.equipmentId === option.id)}][quantity]`} 
                                        component={TextFieldWithUnit} 
                                        disabled={!(input.value && input.value.filter(item => item.equipmentId === option.id).length > 0)}
                                        onChange={event => {
                                            textFn(event,input,option)
                                        }}
                                        unit={option.unit}
                                        normalize={normalizeNumber}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}

                    {options.length < 1 && <div className={classes.subLabel}>{subLabel}</div>}

                </FormGroup>
                <FormHelperText className={classes.formHelper}>{touched && invalid && error}</FormHelperText>
            </FormControl>
        </div>
    );
}

export default CheckBoxWithText