import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Button from '../../../../control/button/Button';
import Search from '../../../../control/search/Search';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '24px'
    },
    rootPaper: {
        width: '100%',
        ...theme.paperPadding
    },
    btn: {
        minWidth: '180px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    divider: {
        margin: '24px 0px'
    }
}));

const UsergroupView = ({
    children,
    txtSearch,

    onSearch,
    onAdd,
    permisAdd
}) => {
    const classes = useStyles();

    return (
        <Scrollbars universal={true}>
            <Grid container className={classes.root}>
                <Paper className={classes.rootPaper}>
                    <Search
                        onSearchClick={onSearch}
                        iniValue={txtSearch}
                        waterMark="ชื่อกลุ่มผู้ใช้งาน, ชื่อระบบ, สิทธิ์การเข้าถึงหน่วยงาน"
                    />
                    <Divider className={classes.divider} />
                    <Grid item xs={12}>
                        <Grid item xs={12} container justify="space-between">
                            <Grid item style={{ fontSize: '33px', marginLeft: '5px' }}>
                                ผลลัพธ์การค้นหา
                        </Grid>
                            {permisAdd && (
                                <Grid item>
                                    <Button
                                        label="เพิ่มกลุ่มผู้ใช้งาน"
                                        onClick={onAdd}
                                        className={classes.btn}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Scrollbars>
    );
};

export default UsergroupView;
