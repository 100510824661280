import React, { Fragment, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import { LeaveCollectContext } from './LeaveCollectProvider';
import { convertDateDisplay } from './LeaveCollectUtils';

import LeaveCollectView from '../../presentational/leavecollect/LeaveCollectView';
import LeaveCollectSearch from './LeaveCollectSearch';
import LeaveCollectTable from './LeaveCollectTable';
import Loading from '../../../../control/loading/Loading';
import AlertMessage from '../../../../control/alert/AlertMessage';

const LeaveCollect = () => {
    const { dataTableState, loadingState, alertMessageState } =
        useContext(LeaveCollectContext);

    const genTextResult = (result) => {
        return result ? (
            <span style={{ color: '#00c900' }}>สำเร็จ</span>
        ) : (
            <span style={{ color: '#ff0022' }}>ไม่สำเร็จ</span>
        );
    };

    const handleDataTable = (response) => {
        const customData = response.data.map((item) => {
            item.year =
                item.year === '9999' ? item.year : Number(item.year) + 543;
            if (item.sapModifyDate) {
                item.sapModifyDate = convertDateDisplay(
                    new Date(item.sapModifyDate)
                );
            }
            if (item.verifyDate) {
                item.verifyDate = convertDateDisplay(new Date(item.verifyDate));
            }
            item.resultMessage = item.resultMessage ? item.resultMessage : '-';
            item.result = genTextResult(item.result);
            return item;
        });
        dataTableState.setInitialDataTable(customData);
    };

    return (
        <Fragment>
            <LeaveCollectView>
                <LeaveCollectSearch handleDataTable={handleDataTable} />
                <LeaveCollectTable dataTableState={dataTableState} />
            </LeaveCollectView>
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    profile: state.authen.profile,
});

export default compose(
    connect(mapStateToProps, null),
    withRouter
)(LeaveCollect);
