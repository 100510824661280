import React, { useEffect, useContext } from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import * as ApiLeaveCollect from '../../../../api/ApiLeaveCollect';
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';
import { LeaveCollectContext } from './LeaveCollectProvider';
import { checkResStatusService } from '../../../../utilities/ResServiceUtils';

import LeaveCollectSearchView from '../../presentational/leavecollect/LeaveCollectSearchView';

const LeaveCollectSearch = (props) => {
    const { handleDataTable, handleSubmit, reset, initialize } = props;
    const {
        isResearch,
        setIsReSearch,
        searchLeaveCollectState,
        loadingState,
        alertMessageState,
        profile,
    } = useContext(LeaveCollectContext);
    const {
        valuesSearch,
        lutYear,
        lutResult,
        lutOrg,
        setValuesSearch,
        setInitLutYear,
        setInitLutResult,
        setInitLutOrg,
    } = searchLeaveCollectState;

    useEffect(() => {
        if (!valuesSearch) {
            firstStepLeaveSAP();
        } else {
            initialize(valuesSearch);
            checkResearch();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const firstStepLeaveSAP = async () => {
        loadingState.showLoading();
        let year = new Date().getFullYear();
        if (year < 2021) year = 2021;
        if (!lutResult.length) {
            await setInitLutYear();
            await setInitLutResult();
            await setInitLutOrg();
        }
        initialize({
            year: year,
        });
        handleSubmit(handleSearch)();
    };

    const checkResearch = () => {
        if (isResearch) {
            handleSearch(valuesSearch);
            setIsReSearch(false);
        }
    };

    const formatValuesToString = (values) => {
        let params = { ...values };
        let stringFormat = '';
        for (const key in params) {
            if (params[key] || params[key] === 0) {
                stringFormat = `${stringFormat}&${key}=${params[key]}`;
            }
        }
        return stringFormat;
    };

    const handleSearch = async (values) => {
        loadingState.showLoading();
        values.depId = profile.depId;
        values.permissionOrgId = profile.permissionOrgId;
        setValuesSearch(values);
        const stringParams = formatValuesToString(values);
        const res = await ApiLeaveCollect.getLeaveCollect(stringParams);
        loadingState.hideLoading();
        if (res && res.data && !res.error) {
            handleDataTable(res);
        } else {
            const msg = checkResStatusService(res);
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
            });
        }
    };

    const handleReset = () => {
        reset('leaveSapSearch');
    };

    return (
        <LeaveCollectSearchView
            enableLutOrg={profile.permissionOrgId === 1}
            optionsYear={lutYear}
            optionsResponse={lutResult}
            optionsOrg={lutOrg}
            onSearchClick={handleSubmit(handleSearch)}
            onResetClick={handleReset}
        />
    );
};

const reduxFormParameter = {
    form: 'leaveSapSearch',
};

const mapStateToProps = (state) => ({});

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps)
)(LeaveCollectSearch);
