import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import RouteConfig from '../../../../config/appRoute/RouteConfig'
import AddUserGroup from './AddUserGroup'
import EditUserGroup from './EditUserGroup'
import Usergroup from './Usergroup'
import UserGroupProvider from './UserGroupProvider'
import UtilsPage from './UtilsPage'

const UserGroupIndex = () => {

    const renderRoute = () => {
        return (
            <Switch>
                <Route exact key={RouteConfig.UM_USER_GROUP_ADD} path={RouteConfig.UM_USER_GROUP_ADD} component={AddUserGroup} />
                <Route exact key={RouteConfig.UM_USER_GROUP_EDIT} path={RouteConfig.UM_USER_GROUP_EDIT} component={EditUserGroup} />
                <Route exact key={RouteConfig.UM_USER_GROUP} path={RouteConfig.UM_USER_GROUP} component={Usergroup} />
                <Redirect to={RouteConfig.UM_USER_GROUP} />
            </Switch>
        )
    }

    return (
        <UserGroupProvider>
            {renderRoute()}
            <UtilsPage />
        </UserGroupProvider>
    )
}

export default UserGroupIndex
