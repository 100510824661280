import { useState } from 'react'

const UseModalState = () => {
    const [modalState, setModalState] = useState({ mode: null, initData: null })

    const handleSetModalState = (mode, initData) => {
        setModalState({
            mode: mode,
            initData: initData
        })
    }

    return {
        mode: modalState.mode,
        initData: modalState.initData,

        setModal: handleSetModalState
    }
}

export default UseModalState
