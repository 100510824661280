import { useState } from 'react';
import { sortBy } from 'lodash'

import { getEmpByEmpID } from '../../../../../api/ApiOrgLeave';
import { ORG_LEAVE, PERMISSION_ORG, USER_GROUP } from '../../../../../constants/APDConstants';
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'
import { checkEmpActive } from '../OrgLeaveUtils'
import * as ApiOrgLeave from '../../../../../api/ApiOrgLeave';

const UseOrgState = ({ permissionOrgId, userGroupId, lutEmpPermission, lutAllEmp }) => {
    const [isEnableSave, setEnableSave] = useState(false)
    const [lut, setLut] = useState({
        lutEmp: [],
        lutEmpApproveLimit: []
    })

    const [orgData, setOrgData] = useState({
        structureOrg: [],
        initialStructureOrg: [], // เอาไว้เปรียบเทียบเมื่อแก้ไข org
        emp: [],
        dataEmpInActive: [], // เก็บพนักงานที่เกษียณ
        empIdModify: [], // เก็บรหัสพนักงานที่ถูกแก้ไข เพราะตอน save จะไม่ส่งไปหมด
        orgID: null,
        centerOrgID: null,
        sorce: null,
    });

    const _getPermission = (emp) => {
        const orgDisplayApproveList = [];
        const orgDisplayAuditList = [];
        const orgDisplayRepersent = [];

        emp.forEach(emp => {
            if (emp.permission.indexOf(ORG_LEAVE.PERMISSION.APPROVE_ID) !== -1) {
                orgDisplayApproveList.push(emp.orgId)
            }

            if (emp.permission.indexOf(ORG_LEAVE.PERMISSION.AUDIT_ID) !== -1) {
                orgDisplayAuditList.push(emp.orgId)
            }

            if (emp.permission.indexOf(ORG_LEAVE.PERMISSION.REPRESENT_ID) !== -1) {
                orgDisplayRepersent.push(emp.orgId)
            }
        })

        return {
            orgDisplayApproveList,
            orgDisplayAuditList,
            orgDisplayRepersent
        }
    }

    const _countEmp = (structureOrg, emp) => {
        let tempEmp = [...emp]

        const structureOrgUpdateCountEmp = structureOrg.map(org => {
            let empCount = 0
            const empFilter = []

            tempEmp.forEach(empTmp => {
                if (empTmp.orgId !== org.id) {
                    empFilter.push(empTmp)
                } else {
                    empCount++
                }
            })
            tempEmp = empFilter
            org.empCount = empCount
            return org
        })

        return structureOrgUpdateCountEmp
    }

    const _checkDisplayPermission = (structureOrg, emp, isUpdateEmp) => {
        const permission = _getPermission(emp)
        const structureOrgUpdate = structureOrg.map(org => {
            const images = [];

            if (isUpdateEmp) { // กรณีแก้ไข emp
                const approveData = emp.filter(emp => emp.id === org.approveEmpId)
                if (approveData.length > 0) {
                    org.representEmpId = approveData[0].empIdApprRep
                    org.representEmpName = approveData[0].empNameApprRep
                }
            }

            if (permission.orgDisplayApproveList.indexOf(org.id) !== -1) {
                images.push(`${process.env.PUBLIC_URL}/img/leave/approve.svg`);
            }

            if (permission.orgDisplayRepersent.indexOf(org.id) !== -1) {
                images.push(`${process.env.PUBLIC_URL}/img/leave/represent.svg`);
            }

            if (permission.orgDisplayAuditList.indexOf(org.id) !== -1) {
                images.push(`${process.env.PUBLIC_URL}/img/leave/audit.svg`);
            }

            delete org.img_permission_0
            delete org.img_permission_1
            delete org.img_permission_2

            images.forEach((image, index) => org[`img_permission_${index}`] = image)
            return org
        })
        return structureOrgUpdate
    }

    const _checkHighlightOrg = (dataOrg, dataEmp) => {
        const dataOrgUpdate = dataOrg.map(org => {
            org.tags = null

            console.log('dataEmp', dataEmp)

            if (org.prevApproveName || org.prevAuditName || org.pid !== org.preParentOrgId) {
                org.tags = ["change"]
            } else {
                dataEmp.forEach(emp => {
                    // if (emp.orgId === org.id && (emp.prevOrgId || emp.isNewEmp || emp.empStatusId !== EMP_STATUS_ID.ACTIVE || emp.isChangeManager)) {
                    if (emp.orgId === org.id && (emp.prevOrgId || emp.isNewEmp || !checkEmpActive(emp.empStatusId, emp.effectiveDate) || emp.isChangeManager)) {
                        org.tags = ["change"]
                    }
                })
            }
            return org
        })
        // const countEmpChange = dataEmp.filter(emp => (emp.prevOrgId || emp.isNewEmp || emp.empStatusId !== EMP_STATUS_ID.ACTIVE || emp.isChangeManager)).length
        const countEmpChange = dataEmp.filter(emp => (emp.prevOrgId || emp.isNewEmp || !checkEmpActive(emp.empStatusId, emp.effectiveDate) || emp.isChangeManager)).length


        return { dataOrgUpdate, countEmpChange }
    }

    const _getEmpNotExits = (listEmpNotExits = []) => {
        const listFindEmp = [...new Set([...listEmpNotExits])]
        const lutEmpNotExits = []

        if (listFindEmp.length) {
            lutAllEmp.forEach(emp => {
                if (listFindEmp.indexOf(emp.empId) !== -1) {
                    lutEmpNotExits.push({
                        empId: emp.empId,
                        empName: emp.empName,
                        uuid: emp.uuid,
                        jobId: null,
                        orgId: null,
                        isApprove: false
                    })
                }
            })
        }

        return lutEmpNotExits
    }

    const _getLutEmpUpdatePermission = (structureOrg) => {
        const listEmpNotExits = []

        structureOrg.forEach(org => {
            if (org.approveEmpId && !lutEmpPermission.some(empPer => empPer.empId === org.approveEmpId)) {
                listEmpNotExits.push(org.approveEmpId)
            }

            if (org.auditEmpId && !lutEmpPermission.some(empPer => empPer.empId === org.auditEmpId)) {
                listEmpNotExits.push(org.auditEmpId)
            }

            if (org.representEmpId && !lutEmpPermission.some(empPer => empPer.empId === org.representEmpId)) {
                listEmpNotExits.push(org.representEmpId)
            }
        })
        const lutEmpNotExits = _getEmpNotExits(listEmpNotExits)
        const lutEmpUpdate = [...lutEmpPermission, ...lutEmpNotExits]
        const lutEmpUpdateSort = sortBy(lutEmpUpdate, ['empName'])

        return lutEmpUpdateSort
    }

    const setLutEmpBypermission = (initialStructureOrg) => {
        const listEmpByPermission = permissionOrgId === PERMISSION_ORG.ALL_ORG ? lutEmpPermission : _getLutEmpUpdatePermission(initialStructureOrg)
        let lutEmpApproveLimit = listEmpByPermission

        if (userGroupId !== USER_GROUP.SUPER_ADMIN_ID) {
            const listEmpApprove = initialStructureOrg.map(strOrg => strOrg.approveEmpId)
            const empIdApprove = [...new Set([...listEmpApprove])]
            lutEmpApproveLimit = listEmpByPermission.filter(emp => emp.isApprove || empIdApprove.indexOf(emp.empId) !== -1)
        }
        setLut({
            lutEmp: listEmpByPermission,
            lutEmpApproveLimit: lutEmpApproveLimit
        })
    }

    const getStructureOrg = async (orgID) => {
        const structureOrgResult = await ApiOrgLeave.getStructureLeave(orgID);
        const sorce = structureOrgResult && structureOrgResult.orgSap ? ORG_LEAVE.SORCE.ORG_SAP : ORG_LEAVE.SORCE.ORG_LEAVE
        let dataOrg = structureOrgResult && structureOrgResult[sorce] && structureOrgResult[sorce].dataOrg ? structureOrgResult[sorce].dataOrg : [];
        let dataEmp = structureOrgResult && structureOrgResult[sorce] && structureOrgResult[sorce].dataEmp ? structureOrgResult[sorce].dataEmp : [];
        let dataEmpInActive = []
        let countEmpChange = 0

        if (sorce === ORG_LEAVE.SORCE.ORG_SAP) {
            const dataOrgHighlight = _checkHighlightOrg(dataOrg, dataEmp)
            // dataEmpInActive = dataEmp.filter(emp => emp.empStatusId !== EMP_STATUS_ID.ACTIVE)
            // dataEmp = dataEmp.filter(emp => emp.empStatusId === EMP_STATUS_ID.ACTIVE)

            dataEmpInActive = dataEmp.filter(emp => !checkEmpActive(emp.empStatusId, emp.effectiveDate))
            dataEmp = dataEmp.filter(emp => checkEmpActive(emp.empStatusId, emp.effectiveDate))

            dataOrg = dataOrgHighlight.dataOrgUpdate
            countEmpChange = dataOrgHighlight.countEmpChange
        }

        const dataOrgUpdateEmpCount = _countEmp(dataOrg, dataEmp)
        const initialStructureOrg = _checkDisplayPermission(dataOrgUpdateEmpCount, dataEmp);

        setOrgData({
            ...orgData,
            structureOrg: initialStructureOrg,
            initialStructureOrg: [...initialStructureOrg],
            emp: dataEmp,
            dataEmpInActive: dataEmpInActive,
            empIdModify: [],
            orgID: orgID,
            centerOrgID: orgID,
            sorce: sorce,
            countEmpChange: countEmpChange
        })
        setEnableSave(false)
        await setLutEmpBypermission(initialStructureOrg)

        return { structureOrg: initialStructureOrg, msg: checkResStatusService(structureOrgResult) };
    }

    const _getChildrenOrg = (structureOrg, orgID) => {
        let results = [];

        const structureOrgFilter = structureOrg.filter(org => {
            if (org.pid === orgID) {
                results.push(org.id)
            }
            return org.pid !== orgID
        })

        results.forEach(result => {
            results = [...results, ..._getChildrenOrg(structureOrgFilter, result)]
        })

        return results
    }

    const _getEmpvalue = async (empID) => { // จะหาข้อมูลจาก emp ที่มีอยู่ก่อน แต่ถ้าหาไม่เจอจะไปยิง service 
        let empData = null;
        let isFetchNewData = false;

        for (let i = 0; i < orgData.emp.length; i++) { // กรณีจะไม่รองรับกรณีเลือกผู้อนุมัติที่อยู่นอก org ที่ display
            if (orgData.emp[i].id === empID) {
                empData = orgData.emp[i];
                break;
            }
        }

        if (!empData) { // ถ้าหาใน org ที่แสดงอยู่ไม่พบให้ไปยิง service
            const result = await getEmpByEmpID(empID)
            if (result && result.data && result.data.length > 0) {
                empData = result.data[0]
                isFetchNewData = true
            }
        }

        return { empData, isFetchNewData }
    }


    const _getEmpUpdatePermission = (param) => {
        const { structureOrg, orgIdUpdate, prevRepresentEmpId, prevApproveEmpId, empApproveData } = param
        const approveOrg = []
        const auditOrg = []

        structureOrg.forEach(org => {
            org.approveEmpId && approveOrg.push(org.approveEmpId);
            org.auditEmpId && auditOrg.push(org.auditEmpId);
        })

        const empUpdate = orgData.emp.map(emp => {
            let permissionUpdate = []

            if (approveOrg.indexOf(emp.id) !== -1) {
                permissionUpdate.push(ORG_LEAVE.PERMISSION.APPROVE_ID)
            }

            if (auditOrg.indexOf(emp.id) !== -1) {
                permissionUpdate.push(ORG_LEAVE.PERMISSION.AUDIT_ID)
            }

            if (emp.id === prevRepresentEmpId) { // กรณีเปลื่ยนผู้อนุมัติจะต้องเอาผู้อนุมัตคนนั้นออกจากผู้ทำการแทน
                const empApproveUpdate = emp.empApprove.filter(empAppr => orgIdUpdate.indexOf(empAppr.orgId) === -1);
                emp.empApprove = empApproveUpdate
                if (empApproveUpdate.length === 0) {
                    permissionUpdate = permissionUpdate.filter(permission => permission !== ORG_LEAVE.PERMISSION.REPRESENT_ID)
                }
            }

            // if (empApproveData && emp.id === empApproveData.empIdApprRep && prevApproveEmpId !== approveEmpId) { //กรณีแต่งตั้งผู้อนุมัติที่มีผู้ทำการแทนอยู่จะต้องเอาตามมาด้วย
            if (empApproveData && prevApproveEmpId && emp.id === empApproveData.empIdApprRep) { //กรณีแต่งตั้งผู้อนุมัติที่มีผู้ทำการแทนอยู่จะต้องเอาตามมาด้วย
                const empApproveNew = orgIdUpdate.map(orgId => ({
                    empId: empApproveData.id,
                    empName: empApproveData.name,
                    orgId: orgId
                }))
                emp.empApprove = emp.empApprove.concat(empApproveNew)
            }

            if (emp.empApprove.length > 0) { // เช็คว่าเป็นผู้ทำการแทนไหม
                permissionUpdate.push(ORG_LEAVE.PERMISSION.REPRESENT_ID)
            }

            const isRepresent = structureOrg.some(item => !item.approveEmpId && emp.id === item.representEmpId) // กรณีทำการแทนตำแหน่งที่ผู้อนุญาตหายไป
            if (isRepresent) {
                permissionUpdate.push(ORG_LEAVE.PERMISSION.REPRESENT_ID)
            }

            emp.permission = permissionUpdate

            return emp
        })

        return empUpdate
    }

    const updateStructerOrg = async (values, isReplaceAll = false) => {
        const childrenOrg = _getChildrenOrg(orgData.structureOrg, values.id)
        const empApproveData = values.approveEmpId && await _getEmpvalue(values.approveEmpId)

        const structureOrgUpdate = orgData.structureOrg.map(item => {
            if (item.id === values.id || (isReplaceAll && childrenOrg.indexOf(item.id) !== -1)) {
                item.approveEmpId = values.approveEmpId
                item.auditEmpId = values.auditEmpId

                if (values.representEmpId === values.approveEmpId) { // ถ้าผู้พิจารณากับผู้อนุมัตเป็นคนเดียวกันให้เคลียร์ผู้ทำการแทน
                    item.representEmpId = null
                    item.representEmpName = null
                } else if (empApproveData) { //กรณีในหน่วยงานไม่มีผู้อนุญาต แต่มีทำการแทน จะเปลื่ยนแค่ผู้พิจารณา
                    item.representEmpId = empApproveData.empData.empIdApprRep
                    item.representEmpName = empApproveData.empData.empNameApprRep
                }
            }
            return item
        })
        const prevRepresentEmpId = empApproveData && (empApproveData.empData.empIdApprRep !== values.prevRepresentEmpId) ? values.prevRepresentEmpId : null;

        const empUpdatePermission = _getEmpUpdatePermission({
            structureOrg: structureOrgUpdate,
            orgIdUpdate: isReplaceAll ? [...childrenOrg, values.id] : [values.id],
            prevRepresentEmpId: prevRepresentEmpId,
            prevApproveEmpId: values.prevApproveEmpId,
            approveEmpId: values.approveEmpId,
            empApproveData: empApproveData && empApproveData.empData
        })

        setOrgData({
            ...orgData,
            structureOrg: _checkDisplayPermission(structureOrgUpdate, empUpdatePermission),
            emp: empUpdatePermission,
            centerOrgID: values.id
        })
        setEnableSave(true)
    }

    const _getApproveOrgIDByEmpID = (EmpID) => { // ดูค่าคนนั้นเป็นผู้อนุมัติใน org ไหนอยู่บ้าง
        const orgID = []

        orgData.structureOrg.forEach(strOrg => {
            if (strOrg.approveEmpId === EmpID) {
                orgID.push(strOrg.id)
            }
        })

        return orgID
    }

    const updatePosition = (objectEmpUpdate) => {
        const empUpdatePosition = orgData.emp.map(emp => {
            if (objectEmpUpdate[emp.id]) {
                emp.isManager = objectEmpUpdate[emp.id].isManager;
                // emp.isAssistant = objectEmpUpdate[emp.id].isAssistant;
            }
            return emp
        })
        const empIdModifyUpdate = [...new Set([...orgData.empIdModify, ...Object.keys(objectEmpUpdate)])]
        setOrgData({
            ...orgData,
            emp: empUpdatePosition,
            empIdModify: empIdModifyUpdate
        })
        setEnableSave(true)
    }

    const updateEmp = async (values) => {
        const representData = values.isHaveRepresent ? await _getEmpvalue(values.empIdApprRep) : null
        const empUpdate = orgData.emp.map(emp => {
            if (emp.id === values.id) { // เคสอัพเดทข้อมูล emp
                emp.orgId = values.orgId
                emp.empIdApprRep = values.isHaveRepresent ? values.empIdApprRep : null
                emp.empNameApprRep = values.isHaveRepresent ? representData.empData.name : null;
                if (values.isChangeOrg) {
                    emp.isManager = false
                    // emp.isAssistant = false
                }
            } else if (emp.id === values.prevEmpIdApprRep) { // กรณีถูกลบออกจากผู้ทำการแทน
                const empApproveUpdate = emp.empApprove.filter(empAppr => empAppr.empId !== values.id)
                emp.empApprove = empApproveUpdate
                if (empApproveUpdate.length === 0) {
                    emp.permission = emp.permission.filter(permission => permission !== ORG_LEAVE.PERMISSION.REPRESENT_ID)
                }
                // } else if (emp.id === values.empIdApprRep) { // set permission ผู้ที่ถูกแต่งตั้งให้เป็นผู้ทำการแทน  
            } else if (emp.id === values.empIdApprRep && values.permission.indexOf(ORG_LEAVE.PERMISSION.APPROVE_ID) !== -1) { // set permission ผู้ที่ถูกแต่งตั้งให้เป็นผู้ทำการแทน (เช็คเพิ่มด้วยว่าคนถูกทำการแทนเป็นผู้อนุมัติอยู่ไหม ถ้าไม่เป็น permission จะไม่ขึ้น)  
                const orgIDs = _getApproveOrgIDByEmpID(values.id)
                const empApprNew = orgIDs.map(orgID => ({
                    empId: values.id,
                    empName: values.name,
                    orgId: orgID
                }))
                emp.permission = [...new Set([...emp.permission, ORG_LEAVE.PERMISSION.REPRESENT_ID])]
                emp.empApprove = [...new Set([...emp.empApprove, ...empApprNew])]
            }

            return emp
        })

        const empIdModifyUpdate = values.isChangeOrg ? [...new Set([...orgData.empIdModify, values.id])] : orgData.empIdModify
        const dataOrgUpdateEmpCount = _countEmp(orgData.structureOrg, empUpdate)

        setOrgData({
            ...orgData,
            structureOrg: _checkDisplayPermission(dataOrgUpdateEmpCount, empUpdate, true),
            emp: empUpdate,
            centerOrgID: values.orgId,
            empIdModify: empIdModifyUpdate
        })
        setEnableSave(true)
    }

    const updateAllEmp = (emp, empIdModifyUpdate) => {
        const { dataOrgUpdate } = _checkHighlightOrg(orgData.initialStructureOrg, emp)
        setOrgData({
            ...orgData,
            emp: emp,
            structureOrg: dataOrgUpdate,
            empIdModify: empIdModifyUpdate
        })
    }

    return {
        isEnableSave: isEnableSave,
        structureOrg: orgData.structureOrg,
        initialStructureOrg: orgData.initialStructureOrg,
        emp: orgData.emp,
        dataEmpInActive: orgData.dataEmpInActive,
        empIdModify: orgData.empIdModify,
        orgID: orgData.orgID,
        centerOrgID: orgData.centerOrgID,
        sorce: orgData.sorce,
        countEmpChange: orgData.countEmpChange,

        lutApprove: lut.lutEmpApproveLimit,
        lutAudit: lut.lutEmp,
        lutRepresent: lut.lutEmp,

        getStructureOrg,
        updateStructerOrg,
        updatePosition,
        updateEmp,
        updateAllEmp,
        setEnableSave: setEnableSave
    }
}

export default UseOrgState
