import React, { createContext } from 'react'
import { connect } from 'react-redux'

import UsePanelState from './useOrgState/UsePanelState';
import UseLoadingState from '../../../../control/loading/UseLoadingState';
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';
import UseSlinksState from './useOrgState/UseSlinksState';
import UseOrgState from './useOrgState/UseOrgState';
import UseLupOrgState from './useOrgState/UseLupOrgState';
import { PermissionFunction, PermissionSubFuncUtils } from '../../../../utilities/PermissionUtils'
import { getDepId } from '../../../../utilities/Utils'

import { PERMISSION_ORG, ORG_LEAVE, FUNCTIONS, SUB_FUNCTION } from '../../../../constants/APDConstants'

export const OrgLeaveContext = createContext()

const OrgLeaveProvider = ({ children, authenRdc }) => {
    const { permissionOrgId, uuid, userGroupId, empId } = authenRdc.profile
    const isPersonalOrg = permissionOrgId === PERMISSION_ORG.PERSONAL_ORG
    const initailOrgId = isPersonalOrg ? getDepId(authenRdc.profile) : ORG_LEAVE.DEFAULT_ORG_LEAVE_SUPERADMIN
    const panelState = UsePanelState();
    const loadingState = UseLoadingState();
    const alertMessageState = UseAlertMessageState();
    const slinksState = UseSlinksState();
    const lupOrgState = UseLupOrgState({ permissionOrgId, depId: getDepId(authenRdc.profile) })
    const permissionSystem = {
        searchAbsence: PermissionFunction(FUNCTIONS.ABSENCE.id),
        isChangeParentOrg: PermissionSubFuncUtils(SUB_FUNCTION.CHANGE_PARENT_ORG),
        isSetManager: PermissionSubFuncUtils(SUB_FUNCTION.SET_MANAGER)
    }
    const orgState = UseOrgState({
        permissionOrgId,
        lutEmpPermission: lupOrgState.lutEmp,
        lutAllEmp: lupOrgState.lutAllEmp,
        userGroupId
    });

    const isOrgSap = orgState.sorce === ORG_LEAVE.SORCE.ORG_SAP
    const isEnableSave = (orgState.isEnableSave || isOrgSap)
    const value = {
        panelState,
        loadingState,
        alertMessageState,
        slinksState,
        orgState,
        lupOrgState,

        isOrgSap,
        isPersonalOrg,
        isEnableSave,
        initailOrgId,
        permissionOrgId,
        permissionSystem,
        uuid,
        empId
    }

    return (
        <OrgLeaveContext.Provider value={value} >
            {children}
        </OrgLeaveContext.Provider>
    )
}

const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default connect(mapStateToProps)(OrgLeaveProvider)
