import React, { Fragment } from 'react';
import classnames from 'classnames'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

import * as BookingByDateConstants from '../../../../constants/meeting/BookingByDateConstants';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // padding: '24px',
        paddingTop: 0
    },
    containerTable: {
        marginBottom: '35px'
    },
    titleSpan: {
        fontSize: 30
    },
    rootTitle: {
        padding: 5
    },
    rootData: {
        padding: 3,
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    button: {
        height: '40px',
        marginBottom: '5px',
        padding: '0px',
        marginLeft: '10px',
        paddingRight: '10px',
        paddingLeft: '10px',
        backgroundColor: '#016f01 !important',
        color: '#FFFFFF'
    }
}));

const BookingByDateResultView = props => {
    const { result, onBtnClick } = props;

    const classes = useStyles();

    return (
        <Fragment>
            <Grid container className={classes.root}>
                <Grid item xs={12} container justify="center" >
                    {result.length === 0 ? <div>ไม่พบรายการ</div> :
                        result.map((item, index) => {
                            return (
                                <Grid container key={`listbooking-${index}-${item.building}`} className={classnames({ [classes.containerTable]: index + 1 !== result.length })}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <span
                                                className={classes.titleSpan}
                                            >{`${item.bookingList[0].meaDistrictName} ${BookingByDateConstants.BUILDING} ${item.building} ${BookingByDateConstants.FLOOR} ${item.floor}`}</span>
                                            <Button
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<GetAppIcon />}
                                                onClick={() =>
                                                    onBtnClick({
                                                        building: item.building,
                                                        floor: item.floor
                                                    })
                                                }
                                            >
                                                ออกรายงาน Excel
                                        </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table
                                            className={classes.table}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {BookingByDateConstants.COLUMN.map(
                                                        (item, index2) => {
                                                            return (
                                                                <TableCell
                                                                    key={`COLUMN-${index}-${index2}`}
                                                                    align="center"
                                                                    style={{
                                                                        border:
                                                                            '1px solid grey',
                                                                        width:
                                                                            item.width,
                                                                        backgroundColor:
                                                                            '#b4c7a9'
                                                                    }}
                                                                    classes={{
                                                                        root:
                                                                            classes.rootTitle
                                                                    }}
                                                                >
                                                                    {item.title}
                                                                </TableCell>
                                                            );
                                                        }
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {item.bookingList.map((row, index2) => (
                                                    <TableRow
                                                        key={`cell-${index}-${index2}`}
                                                        style={{
                                                            height:
                                                                '40px !important'
                                                        }}
                                                        classes={{
                                                            root: {
                                                                height:
                                                                    '40px !important'
                                                            }
                                                        }}
                                                    >
                                                        <TableCell
                                                            align="center"
                                                            classes={{
                                                                root:
                                                                    classes.rootData
                                                            }}
                                                            style={{
                                                                border:
                                                                    '1px solid #e0e0e0',
                                                                wordBreak:
                                                                    'break-all'
                                                            }}
                                                        >
                                                            {row.timeBooking}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            classes={{
                                                                root:
                                                                    classes.rootData
                                                            }}
                                                            style={{
                                                                border:
                                                                    '1px solid #e0e0e0',
                                                                wordBreak:
                                                                    'break-all'
                                                            }}
                                                        >
                                                            {row.topic}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            classes={{
                                                                root:
                                                                    classes.rootData
                                                            }}
                                                            style={{
                                                                border:
                                                                    '1px solid #e0e0e0',
                                                                wordBreak:
                                                                    'break-all'
                                                            }}
                                                        >
                                                            {row.numberOfAttendees}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            classes={{
                                                                root:
                                                                    classes.rootData
                                                            }}
                                                            style={{
                                                                border:
                                                                    '1px solid #e0e0e0',
                                                                wordBreak:
                                                                    'break-all'
                                                            }}
                                                        >
                                                            {row.roomNameTH}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            classes={{
                                                                root:
                                                                    classes.rootData
                                                            }}
                                                            style={{
                                                                border:
                                                                    '1px solid #e0e0e0',
                                                                wordBreak:
                                                                    'break-all'
                                                            }}
                                                        >
                                                            {row.chairman}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            classes={{
                                                                root:
                                                                    classes.rootData
                                                            }}
                                                            style={{
                                                                border:
                                                                    '1px solid #e0e0e0',
                                                                wordBreak:
                                                                    'break-all'
                                                            }}
                                                        >
                                                            {row.empBooking}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            classes={{
                                                                root:
                                                                    classes.rootData
                                                            }}
                                                            style={{
                                                                border:
                                                                    '1px solid #e0e0e0',
                                                                wordBreak:
                                                                    'break-all'
                                                            }}
                                                        >
                                                            {row.dateModify} เวลา{' '}
                                                            {row.timeModify}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default BookingByDateResultView;
