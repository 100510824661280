import { fetchGet } from '../utilities/ServiceUtils';
import * as ApiConstants from '../constants/ApiConstants';
import { API } from '../constants/ServiceMSGConstants';

export const getLutOrgByPermission = ({ permissionOrgId, depId }) => {
    return fetchGet(
        `${ApiConstants.SERVICE_GET_LU_ORG_BY_PERMISSION}?permissionOrgId=${permissionOrgId}&depId=${depId}`
    )
        .then((res) => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch((ex) => {
            console.log('ex getLutOrgByPermission', ex);
        });
};

export const getLutEmpByPermission = ({ permissionOrgId, depId }) => {
    return fetchGet(
        `${ApiConstants.SERVICE_GET_LU_LEAVE_EMP_BY_PERMISSION}?permissionOrgId=${permissionOrgId}&depId=${depId}`
    )
        .then((res) => {
            if (!res.ok) {
                // throw res.status;
                throw { status: res.status, api: API.LEAVE };
            }
            return res.json();
        })
        .catch((ex) => {
            console.log('ex getLutEmpByPermission', ex);
            return ex;
        });
};

export const getLutEmpApproveJobId = ({ permissionOrgId, depId }) => {
    return fetchGet(
        `${ApiConstants.SERVICE_GET_LU_EMP_APPROVE_JOB_ID}?permissionOrgId=${permissionOrgId}&depId=${depId}`
    )
        .then((res) => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch((ex) => {
            console.log('ex getLutEmpApproveJobId', ex);
        });
};

export const getLutMeetingRoom = (empId) => {
    return fetchGet(
        `${ApiConstants.SERVICE_GET_LU_MEETING_ROOM}?empId=${empId}`
    )
        .then((res) => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch((ex) => {
            console.log('ex getLutMeetingRoom', ex);
        });
};
export const getLutDep = () => {
    return fetchGet(`${ApiConstants.SERVICE_GET_LU_DEP}`)
        .then((res) => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch((ex) => {
            console.log('ex getLutDep', ex);
        });
};

export const getLutSystemMaintenance = () => {
    return fetchGet(`${ApiConstants.SERVICE_GET_LUT_SYSTEM_MAINTENANCE}`)
        .then((res) => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch((ex) => {
            console.log('ex getLutDep', ex);
        });
};

export const getLutManagementOrganization = () => {
    return fetchGet(
        `${ApiConstants.SERVICE_GET_MANAGEMENT_ORGANIZATION_LOOKUP}`
    )
        .then((res) => {
            if (!res.ok) {
                throw res.status;
            }
            return res.json();
        })
        .catch((ex) => {
            console.log('ex getLutManagementOrganization', ex);
        });
};
