import React, { useContext, useEffect } from 'react'
import { reduxForm, isDirty } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { TemplateContext } from './TemplateProvider'
import { validateEditTemplate } from './TemplateUtils'
import { checkSuccessResService } from '../../../../utilities/Utils'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'
import { updateTemplate } from '../../../../api/ApiTemplate'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'

import ModalTemplateView from '../../presentational/template/ModalTemplateView'

const ModalTemplate = ({
    dirty,
    handleSubmit,
    callbackSubmit,
    initialize
}) => {
    const { alertMessageState, loadingState, modalState } = useContext(TemplateContext)

    useEffect(() => {
        if (modalState.dataEdit) {
            initialize({ ...modalState.dataEdit })
        } else {
            initialize()
        }
    }, [modalState.dataEdit])

    const handleSaveTemplate = async values => {
        loadingState.showLoading()
        const res = await updateTemplate({ ...values, templateId: modalState.dataEdit.templateId })
        loadingState.hideLoading()

        if (res && res.data && checkSuccessResService(res.data)) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.SAVE_SUCCESS,
                callbackCancel: () => {
                    callbackSubmit()
                    alertMessageState.hideAlert()
                    modalState.setDataEdit()
                }
            })
        } else {
            const msg = checkResStatusService(res)
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.SAVE_FAIL
            })
        }
    }

    const handleCancel = () => {
        if (dirty) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: "ยกเลิก",
                btnCancelName: "ไม่ยกเลิก",
                callbackConfirm: () => {
                    alertMessageState.hideAlert()
                    modalState.setDataEdit()
                },
            })
        } else {
            modalState.setDataEdit()
        }
    }

    return (
        <ModalTemplateView
            isOpen={(modalState.dataEdit !== undefined)}
            onCancel={handleCancel}
            onSave={handleSubmit(handleSaveTemplate)}
        />
    )
}

const reduxFormParameter = {
    form: 'FormEditTemplate',
    validate: validateEditTemplate
};

const mapStateToPros = state => ({
    dirty: isDirty('FormEditTemplate')(state)
})

export default compose(
    connect(mapStateToPros),
    reduxForm(reduxFormParameter)
)(ModalTemplate)
