import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { formValueSelector } from "redux-form";

import { MEETING } from '../../../../../constants/APDConstants';

import MtRoomConditionView from '../../../presentational/manageMeetingRoom/formMeeting/MtRoomConditionView'

const { NAME } = MEETING.FORM
const MtRoomCondition = ({
    lup,
    change,
    permissionEmp,
    publicType,
    bookingType,
}) => {
    const [lutPermissionEmp, setLutPermissionEmp] = useState([])

    const updateLutMaintainer = () => {
        if (permissionEmp && permissionEmp.length) {
            const permissionEmpUpdate = lup.emp.filter(lutMain => !permissionEmp.some(emp => emp.empId === lutMain.empId))
            setLutPermissionEmp(permissionEmpUpdate)
        } else {
            setLutPermissionEmp(lup.emp)
        }
    }

    useEffect(() => {
        updateLutMaintainer()
    }, [permissionEmp]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateLutMaintainer()
    }, [lup.emp]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeEmp = async (value) => {
        if (value) {
            if (permissionEmp) {
                if (permissionEmp.indexOf(value) === -1) {
                    change(NAME.PERMISSION_EMP, [...permissionEmp, value]);
                }
            } else {
                change(NAME.PERMISSION_EMP, [value]);
            }
            await change(NAME.EMP, null)
            const el = document.getElementById("search-emp")
            if (el) el.blur();
        }
    }


    const searchTree = (element, matchingOrgId) => { // สำหรับ tree หน้าห้องประชุม
        if (element && element.orgId && element.orgId.toString() === matchingOrgId) {
            return element;
        } else if (element && element.children) {
            let i;
            let result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = searchTree(element.children[i], matchingOrgId);
            }

            return result;
        }
        return null;
    }

    const getParent = (element) => { // สำหรับ tree หน้าห้องประชุม
        const key = ['assistId', 'depId', 'divId', 'partId', 'pathId', 'secId']
        const values = []

        key.forEach(item => {
            if (element[item]) {
                values.push(element[item])
            }
        })

        return values
    }

    const getHalfChecked = (checked = [], options) => { // สำหรับ tree หน้าห้องประชุม
        let result = []

        if (checked.length) {
            result.push(2)
        }

        checked.forEach(item => {
            const data = searchTree(options[0], item)

            if (data) {
                const parent = getParent(data)
                result = [...result, ...parent]
            }
        })

        return result
    }

    return (
        <MtRoomConditionView
            isShowPermissionForm={MEETING.BOOKING_TYPE_ID_SHOW_PERMISSION.indexOf(Number(bookingType)) !== -1}
            isAllBooking={MEETING.PUBLICITY_TYPE_ID_NOT_ALL.indexOf(Number(publicType)) > -1}
            lupOrgTree={lup.orgTree}
            lupEmp={lutPermissionEmp}
            lupPublicityType={lup.publicityType}
            lupBookingType={lup.bookingType}
            permissionEmp={permissionEmp && permissionEmp}
            onChangeEmp={handleChangeEmp}
            getHalfChecked={getHalfChecked}
        />
    )
}

const selector = formValueSelector('MtRoomForm')

const mapStateToProps = state => ({
    permissionEmp: selector(state, NAME.PERMISSION_EMP),
    publicType: selector(state, NAME.PUBLICITY_TYPE_ID),
    bookingType: selector(state, NAME.BOOKING_TYPE_ID)
})

export default connect(mapStateToProps, null)(MtRoomCondition)