import React, { createContext, useEffect } from 'react'

import UseDataTableState from '../../../../control/table/UseDataTableState'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseLoadingState from '../../../../control/loading/UseLoadingState'
// import UseTemplateState from './useState/UseTemplateState'
import UseModalState from './useState/UseModalState'

export const TemplateContext = createContext()

const TemplateProvider = ({ children }) => {
    const alertMessageState = UseAlertMessageState()
    const loadingState = UseLoadingState()
    // const templateState = UseTemplateState({ loadingState: loadingState, alertMessageState: alertMessageState })
    const modalState = UseModalState()
    // const rowPerPage = Number((window.innerHeight - 500) / 48) - 1
    const rowPerPage = 10
    const dataTableState = UseDataTableState({
        rowPerPage: rowPerPage,
        specialColumn: {
            // startDateDisplay: 'startDate',
            // endDateDisplay: 'endDate',
            // displayDateDisplay: 'displayDate'
        }
    })

    const value = {
        dataTableState,
        alertMessageState,
        loadingState,
        // templateState,
        modalState
    }

    return (
        <TemplateContext.Provider value={value}>
            {children}
        </TemplateContext.Provider>
    )
}

export default TemplateProvider
