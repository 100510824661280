import * as actionTypes from "../constants/ActionTypes";

// const uuid_statgin = "C39BD466-469A-49E0-A8C9-325D1C872812"
// const uuid_production = "ADF12CF0-EF17-4715-B71A-E5F8CD6868EF" // stagin
// const uuid_dev = "B9B2351B-FB74-4570-9040-2DDF1D82E84B"
// const uuid = uuid_dev

// const profileMOC = {
// 	assistId: 1679,
// 	assistName: "ผู้ช่วยผู้ว่าการ (เทคโนโลยีสารสนเทศและระ",
// 	assistShortName: "ชวก.",
// 	birthDate: "1993-08-19T00:00:0,0.000Z",
// 	cLevel: "6",
// 	depId: 1667,
// 	depName: "ฝ่ายพัฒนาระบบงานประยุกต์",
// 	depShortName: "ฝพป.",
// 	divId: 3944,
// 	divName: "งานพัฒนาระบบงานสนับสนุนองค์กร (ระดับ 10)",
// 	divShortName: "*",
// 	email: "WARIT.KU@MEA.OR.TH",
// 	empId: "2414014",
// 	empPicture: "https://mea-so-sso.stage.mapboss.co.th/profile/B9B2351B-FB74-4570-9040-2DDF1D82E84B/picture",
// 	firstName: "วริษฐ์",
// 	functions: [
// 		{ functionId: 1, functionName: "จัดการโครงสร้างการลา", systemId: 1 },
// 		{ functionId: 10, functionName: "รายงานจองห้องประชุมประจำวัน", systemId: 2 },
// 		{ functionId: 2, functionName: "จัดการวันหยุด", systemId: 1 },
// 		{ functionId: 8, functionName: "จัดการข้อมูลการจองห้องประชุม", systemId: 2 },
// 		{ functionId: 9, functionName: "จัดการปิดระบบ", systemId: 6 },
// 		{ functionId: 3, functionName: "ประวัติการนำเข้า SAP", systemId: 1 },
// 		{ functionId: 6, functionName: "จัดการผู้ใช้งาน", systemId: 5 },
// 		{ functionId: 11, functionName: "รายงานยกเลิกจองห้องประชุม", systemId: 2 },
// 		{ functionId: 12, functionName: "จัดการข้อความปิดระบบ", systemId: 6 },
// 		{ functionId: 4, functionName: "จัดการใบลา", systemId: 1 },
// 		{ functionId: 5, functionName: "จัดการห้องประชุม", systemId: 2 },
// 		{ functionId: 7, functionName: "จัดการกลุ่มผู้ใช้งาน", systemId: 5 },
// 		{ functionId: 13, functionName: "จัดการ version force update", systemId: 6 }
// 	],
// 	isBirthDatePublic: true,
// 	isLineIdPublic: false,
// 	isMobilePublic: true,
// 	isStartDatePublic: false,
// 	jobId: 10000284,
// 	jobName: "นักประมวลผลข้อมูล 6",
// 	jobShortName: "นปข.6",
// 	lastName: "คุรุชาคร",

// 	lineId: "-",
// 	mobile: "-",
// 	nickName: "-",
// 	orgId: 3944,
// 	orgName: "งานพัฒนาระบบงานสนับสนุนองค์กร (ระดับ 10)",
// 	orgShortName: "*",
// 	partId: null,
// 	partName: null,
// 	partShortName: null,
// 	pathId: 1678,
// 	pathName: "สายงานรองผู้ว่าการเทคโนโลยีสารสนเทศฯ",
// 	pathShortName: "รผส.",
// 	permissionEmpId: 1,
// 	permissionOrgId: 1,
// 	posId: 20036758,
// 	posName: null,
// 	posShortName: null,
// 	prefix: "นาย",
// 	profilePicture: "https://mea-so-sso.stage.mapboss.co.th/profile/B9B2351B-FB74-4570-9040-2DDF1D82E84B/picture",
// 	secId: null,
// 	secName: null,
// 	secShortName: null,
// 	sex: 1,
// 	startDate: null,
// 	subFunctions: [
// 		{ subFunctionId: 1, subFunctionName: "ดูข้อมูล/แก้ไข ห้องประชุม", functionId: 5 },
// 		{ subFunctionId: 2, subFunctionName: "เพิ่มห้องประชุม", functionId: 5 },
// 		{ subFunctionId: 4, subFunctionName: "แก้ไขโครงสร้างการลา", functionId: 1 },
// 		{ subFunctionId: 5, subFunctionName: "เพิ่มวันหยุด", functionId: 2 },
// 		{ subFunctionId: 6, subFunctionName: "แก้ไขวันหยุด", functionId: 2 },
// 		{ subFunctionId: 7, subFunctionName: "ลบวันหยุด", functionId: 2 },
// 		{ subFunctionId: 8, subFunctionName: "แก้ไขข้อมูลใบลาจาก SAP", functionId: 3 },
// 		{ subFunctionId: 9, subFunctionName: "ยกเลิกข้อมูลใบลาจาก SAP", functionId: 3 },
// 		{ subFunctionId: 10, subFunctionName: "แก้ไขข้อมูลใบลา", functionId: 4 },
// 		{ subFunctionId: 11, subFunctionName: "ยกเลิกข้อมูลใบลา", functionId: 4 },
// 		{ subFunctionId: 12, subFunctionName: "เพิ่มกลุ่มผู้ใช้งาน", functionId: 7 },
// 		{ subFunctionId: 13, subFunctionName: "แก้ไขกลุ่มผู้ใช้งาน", functionId: 7 },
// 		{ subFunctionId: 14, subFunctionName: "ลบกลุ่มผู้ใช้งาน", functionId: 7 },
// 		{ subFunctionId: 15, subFunctionName: "เพิ่มผู้ใช้งาน", functionId: 6 },
// 		{ subFunctionId: 16, subFunctionName: "แก้ไขผู้ใช้งาน", functionId: 6 },
// 		{ subFunctionId: 17, subFunctionName: "ลบผู้ใช้งาน", functionId: 6 },
// 		{ subFunctionId: 18, subFunctionName: "ดูวันหยุด", functionId: 2 },
// 		{ subFunctionId: 19, subFunctionName: "ดูรายละเอียดข้อมูลใบลาจาก SAP", functionId: 3 },
// 		{ subFunctionId: 20, subFunctionName: "ดูข้อมูลใบลาจาก SAP", functionId: 3 },
// 		{ subFunctionId: 21, subFunctionName: "ดูข้อมูลใบลา", functionId: 4 },
// 		{ subFunctionId: 22, subFunctionName: "ดูข้อมูลผู้ใช้งาน", functionId: 6 },
// 		{ subFunctionId: 24, subFunctionName: "ดูข้อมูลกลุ่มผู้ใช้งาน", functionId: 7 },
// 		{ subFunctionId: 25, subFunctionName: "ออกรายงานใบลาที่มีผลกระทบ", functionId: 2 },
// 		{ subFunctionId: 31, subFunctionName: "ดู/ยกเลิกรายการจองห้องประชุม", functionId: 8 },
// 		{ subFunctionId: 33, subFunctionName: "ดู/เพิ่ม/แก้ไข/ยกเลิก ข้อมูลการปิดระบบ", functionId: 9 },
// 		{ subFunctionId: 34, subFunctionName: "ค้นหารายการจองห้องประชุมด้วยหน่วยงาน", functionId: 8 },
// 		{ subFunctionId: 36, subFunctionName: "ดู/ออกรายงานการจองห้องประชุมรายวัน", functionId: 10 },
// 		{ subFunctionId: 37, subFunctionName: "ดู/ออกรายงานการยกเลิกการจองห้องประชุม", functionId: 11 },
// 		{ subFunctionId: 38, subFunctionName: "ดู/แก้ไข ข้อความปิดระบบ", functionId: 12 },
// 		{ subFunctionId: 39, subFunctionName: "ดู/แก้ไข version force update", functionId: 13 }
// 	],
// 	systems: [
// 		{ systemId: 1, systemName: "ระบบลา" },
// 		{ systemId: 2, systemName: "ระบบห้องประชุม" },
// 		{ systemId: 5, systemName: "ระบบจัดการผู้ใช้งาน" },
// 		{ systemId: 6, systemName: "ระบบจัดการบำรุงรักษา" }
// 	],
// 	tel: "2",
// 	telExtension: "2",
// 	telInternalPrefix: "1115",
// 	telInternalSuffix: "556",
// 	userGroupId: 1,
// 	userGroupName: "Super Admin",
// 	uuid: uuid
// }

// const initialState = { //MOC PROFILE
// 	isLogin: true,
// 	profile: profileMOC
// };

const initialState = {
	isLogin: false,
	profile: null
};

const authenReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_PROFILE:
			return {
				...state,
				isLogin: action.isLogin,
				profile: action.profile,
			};
		case actionTypes.CLEAR_PROFILE:
			return initialState;
		default:
			return state;
	}
};

export default authenReducer;