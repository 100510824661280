import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        // padding: theme.spacing(8, 0, 6),
        padding: '1% 0 3%',
        fontSize: '48px',
    },
    title: {
        width: '100%',
        textAlign: 'center'
    },
    line: {
        width: '20%',
        height: '4px',
        backgroundColor: theme.mainTheme.color1,
        marginTop: '20px'
    },
}));

function CenterContent() {
    const classes = useStyles();
    return (
        <Grid container justify="center" className={classes.root}>
            <div className={classes.title}>ระบบจัดการ APD</div>
            <div className={classes.line} />
        </Grid>
    );
}

export default CenterContent;
