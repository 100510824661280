import { UM_USER_GROUP, ERRORS } from '../../../../constants/APDConstants';

const { NAME } = UM_USER_GROUP.FORM_USER_GROUP;

export const validateFormUserGroup = (values) => {
    const errors = {};

    if (!values[NAME.GROUP_NAME]) {
        errors[NAME.GROUP_NAME] = ERRORS.REQUIRED;
    }

    if (!values[NAME.PERMISSION_ORG]) {
        errors[NAME.PERMISSION_ORG] = ERRORS.REQUIRED;
    }

    if (
        !values[NAME.FUNCTION_DISPLAY] ||
        !values[NAME.FUNCTION_DISPLAY].length
    ) {
        errors[NAME.FUNCTION_DISPLAY] = ERRORS.REQUIRED;
    }

    return errors;
};

const _getSystem = (functions) => {
    const listSystems = [];
    const listFunctions = [];
    const listSubFuncions = [];
    const system = [];

    functions.forEach((func) => {
        const [systemId, functionId, subFunctionId] = func.split('-');

        if (subFunctionId) {
            listSubFuncions.push({ subFunctionId, pid: functionId });
        } else if (functionId) {
            listFunctions.push({ functionId, pid: systemId });
        } else if (systemId) {
            listSystems.push({ systemId });
        }
    });

    listSystems.forEach((sys) => {
        const funData = listFunctions
            .filter((fun) => fun.pid === sys.systemId)
            .map((fun) => {
                const subFunData = listSubFuncions
                    .filter((subFunc) => subFunc.pid === fun.functionId)
                    .map((subFunc) => ({
                        subFunctionId: subFunc.subFunctionId,
                    }));
                return {
                    functionId: fun.functionId,
                    subFunctions: subFunData,
                };
            });

        const sysData = {
            systemId: sys.systemId,
            functions: funData,
        };

        system.push(sysData);
    });

    return system;
};

export const makeDataSaveUserGroup = ({
    userGroupName,
    functions = [],
    permissionOrgId,
    listEmp = [],
}) => {
    const users = listEmp.map((emp) => ({
        empId: emp.empId,
        name: emp.empName,
        uuid: emp.uuid,
        orgId: emp.orgIdSAP ? emp.orgIdSAP : emp.orgId,
        orgName: emp.orgName,
        orgShortName: emp.orgShortName,
        depId: emp.depId,
        depName: emp.depName,
        depShortName: emp.depShortName,
    }));
    const system = _getSystem(functions);

    return {
        userGroupName: userGroupName,
        permissionOrgId: permissionOrgId,
        users: users,
        systems: system,
    };
};
