import React from 'react'

import LeaveSAPSignByView from '../../presentational/leavesap/LeaveSAPSignByView'

const LeaveSAPSignBy = (props) => {
    const { signBy, onCancelClick } = props

    return (
        <LeaveSAPSignByView
            signBy={signBy}
            onCancelClick={onCancelClick}
        />
    )
}

export default LeaveSAPSignBy
