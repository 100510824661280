import React from 'react'

import { makeStyles, Grid } from '@material-ui/core'

const useStylesFormat = makeStyles(theme => ({
    root:{
        paddingTop : theme.spacing(1),
        paddingBottom : theme.spacing(1)
    },
    labelTitle: {
        color: '#F47B15', //orange color label display
        fontSize: '20px',
        paddingRight:'10px'
    }
}))

const FormatDisplayDetail = ({xs, sm, md, label, data, requiredColor}) => {

    const classes = useStylesFormat()
    
    return (
        <Grid item xs={xs} sm={sm} md={md} className={classes.root}>
            <span className={classes.labelTitle}>{label}: </span>{requiredColor ? <span style={{color: requiredColor, fontSize:"20px"}}>{data}</span>:<span style={{fontSize:"20px"}}>{data}</span>}
        </Grid>
    )
}

export default FormatDisplayDetail