import React from 'react'

import LayoutToolbarView from '../../../presentational/orgLeave/toolbar/LayoutToolbarView'

const LayoutToolbar = React.memo(({ isOpenLayout }) => {

    const handleChangeLayout = layout => {
        window.chart.config.layout = layout
        window.chart.draw();
    }

    return (
        <LayoutToolbarView
            isOpenLayout={isOpenLayout}
            onChangeLayout={handleChangeLayout}
        />
    )
})

export default LayoutToolbar
