import * as actionType from '../../constants/ActionTypes';
import * as ApiAuthen from '../../api/ApiAuthen';
import * as AlertMSGConstants from '../../constants/AlertMSGConstants';
import { clearTokenLocalStore } from '../../utilities/LocalStorageUtils';
import { checkResStatusService } from '../../utilities/ResServiceUtils'

export const getProfile = ({ callbackError }) => async dispatch => {
    const profileSSO = await ApiAuthen.getProfileSSO();
    let profileAPD = null;
    let altMsg = checkResStatusService(profileSSO)

    if (profileSSO && profileSSO.data && profileSSO.data.uuid) {
        profileAPD = await ApiAuthen.getProfileAPD(profileSSO.data.uuid);
        altMsg = checkResStatusService(profileAPD)
    } else {
        clearTokenLocalStore();
    }

    if (profileAPD && profileAPD.data && !profileAPD.data.error) {
        dispatch({
            type: actionType.SET_PROFILE,
            isLogin: true,
            profile: { ...profileSSO.data, ...profileAPD.data },
        })
    } else {
        if (!altMsg) {
            altMsg = (!profileSSO || !profileAPD) ? AlertMSGConstants.SYSTEM_ERROR : AlertMSGConstants.NOT_ACCESS_TO_APD_WEB
        }
        // let altMsg = (!profileSSO || !profileAPD) ? AlertMSGConstants.SYSTEM_ERROR : AlertMSGConstants.NOT_ACCESS_TO_APD_WEB
        callbackError(altMsg);
    }
}