import React, { useContext, useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import FormSearchMaintenanceView from '../../../presentational/connext/form/FormSearchMaintenanceView'
import { formatDateTime } from '../../../../../utilities/DatetimeUtils'
import Loading from '../../../../../control/loading/Loading'
import { DATE_TIME } from '../../../../../constants/meeting/BookingConstants';
import { ConnextContext } from '../ConnextProvider'
import { getLutSystemMaintenance } from '../../../../../api/ApiLookup'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import 'dayjs/locale/en-gb'
import buddhistEra from 'dayjs/plugin/buddhistEra'

dayjs.extend(buddhistEra)

const FormSearchMaintenance = props => {
    const {
        handleSubmit,
        change,
        callbackSearch,
        // initialize,
        reset
    } = props;

    const { loadingState } = useContext(ConnextContext);
    const [dateTime, setDateTime] = useState({
        start: null,
        end: null
    });
    const [lutSystemMaintenance, setLutSystemMaintenance] = useState([])
    const [durationHours, setDurationHours] = useState(null)

    useEffect(() => {
        const initDate = new Date().getDate();
        const initMonth = new Date().getMonth();
        const initYear = new Date().getFullYear();

        const startDate = new Date(initYear, initMonth, initDate);
        const endDate = new Date(initYear, initMonth, initDate);

        // const startDate = new Date(initYear, initMonth, initDate, 7, 30);
        // const endDate = new Date(initYear, initMonth, initDate, 15, 30);

        setDurationHours(endDate - startDate)

        // initialize({
        //     startDatetime: startDate,
        //     endDatetime: endDate
        // });

        // setDateTime({
        //     start: startDate,
        //     end: endDate
        // });

        // const values = {
        //     startDatetime: startDate,
        //     endDatetime: endDate
        // };
        getLutSystemMaintenanceFunc()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const getLutSystemMaintenanceFunc = async () => {
        let res = await getLutSystemMaintenance()
        if (res && res.data) {
            res.data.forEach((itm) => {
                itm.id = itm.systemNameEN
            })
            setLutSystemMaintenance(res.data)
        }
    }
    const handleSearch = async values => {
        // console.log("values::", values)
        // loadingState.showLoading()
        let params = ""
        if (values && values.apiName) {
            params += `apiName=${values.apiName}&`
        }
        if (values && values.startDatetime) {
            params += `startDate=${formatDateTime(values.startDatetime)}&`
        }
        if (values && values.endDatetime) {
            params += `endDate=${formatDateTime(values.endDatetime)}&`
        }
        // let res = await searchListMaintenance(params)
        // // loadingState.hideLoading()
        // let altMsg = checkResStatusService(res)
        // if (res && res.data) {
        //     const dataTable = res.data.map((data, index) => ({
        //         ...data,
        //         startDateDisplay: dayjs(data.startDate).locale('th').format('D MMM BBBB H:mm'),
        //         endDateDisplay: dayjs(data.endDate).locale('th').format('D MMM BBBB H:mm'),
        //         displayDateDisplay: dayjs(data.displayDate).locale('th').format('D MMM BBBB H:mm'),
        //         edit: true,
        //         delete: data.apiId === API_ID.CONNEXT,
        //         no: index + 1
        //     }))
        //     dataTableState.setInitialDataTable(dataTable)
        // } else {
        //     alertMessageState.showAlert({ altMsg: altMsg ? altMsg : AlertMSGConstants.SYSTEM_ERROR })
        // }

        callbackSearch(params);
    };


    const handleResetForm = () => {
        // const initDate = new Date().getDate();
        // const initMonth = new Date().getMonth();
        // const initYear = new Date().getFullYear();

        // const startDate = new Date(initYear, initMonth, initDate, 7, 30);
        // const endDate = new Date(initYear, initMonth, initDate, 15, 30);
        // const startDate = new Date(initYear, initMonth, initDate);
        // const endDate = new Date(initYear, initMonth, initDate);
        setDateTime({
            start: null,
            end: null
        })
        // setDurationHours(endDate - startDate)
        reset('FormSearchMaintenance');
    };

    const handleChangeDate = (type, dt) => {

        const dateTimeStart = typeof (dateTime.start) === 'number' ? new Date(dateTime.start) : dateTime.start
        const dateTimeEnd = typeof (dateTime.end) === 'number' ? new Date(dateTime.end) : dateTime.end
        switch (type) {
            case DATE_TIME.START:
                setDateTime({
                    ...dateTime,
                    start: dt

                });
                if (dateTimeEnd) {
                    if (dt && dt >= dateTimeEnd) {
                        const newEndDate = JSON.parse(JSON.stringify(dt));
                        const useDate = new Date(newEndDate).setMilliseconds(new Date(newEndDate).getMilliseconds() + durationHours)
                        change(
                            'endDatetime',
                            new Date(useDate)
                        );
                        setDateTime({
                            start: dt,
                            end: new Date(useDate)
                        });
                    } else {
                        const duration = dateTimeEnd - dt
                        setDurationHours(duration)
                        setDateTime({
                            ...dateTime,
                            start: dt
                        })
                    }
                }

                break;
            case DATE_TIME.END:
                setDateTime({
                    ...dateTime,
                    end: dt

                });
                if (dateTimeStart) {
                    if (dt && dt <= dateTimeStart) {
                        const newStartDate = JSON.parse(JSON.stringify(dt));
                        const useDate = new Date(newStartDate).setMilliseconds(new Date(newStartDate).getMilliseconds() - durationHours)

                        change(
                            'startDatetime',
                            new Date(useDate)
                        );
                        setDateTime({
                            start: new Date(useDate),
                            end: dt
                        });

                    } else {
                        const duration = dt - dateTimeStart
                        setDurationHours(duration)
                        setDateTime({
                            ...dateTime,
                            end: dt
                        })
                    }
                }

                break;

            default:
                break;
        }
    };

    return (
        <>
            <FormSearchMaintenanceView onSearchClick={handleSubmit(handleSearch)}
                onChangeDateTime={handleChangeDate}
                onResetClick={handleResetForm}
                lutSystemMaintenance={lutSystemMaintenance}
            />

            <Loading isShowLoading={loadingState.isShowLoading} />

        </>

    );
};

// const validateFormSearch = values => {
//     // const NO_VALUE = 'ระบุอย่างน้อย 1 เงื่อนไข';
//     // const NEED_VALUE = 'กรุณาระบุข้อมูล';
//     const errors = {};

//     if (values.startDatetime && values.endDatetime) {
//         if (values.endDatetime < values.startDatetime) {
//             errors.endDatetime = "วัน-เวลาสิ้นสุด น้อยกว่า วัน-เวลาเริ่มต้น";
//         }
//     }
//     return errors;
// };

const reduxFormParameter = {
    form: 'FormSearchMaintenance',
    // validate: validateFormSearch
};

export default reduxForm(reduxFormParameter)(FormSearchMaintenance);
