import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import * as UserConstants from '../../../../constants/um/UserConstants'

import DataTable from '../../../../control/table/DataTable';

const useStyles = makeStyles(theme => ({
    tableCell: {
        padding: '20px'
    }
}))


const TableUserGroupView = ({
    dataTableState,
    onRowClick
}) => {
    const classes = useStyles()

    return (
        <DataTable
            // notScroll={true}
            column={UserConstants.TABLE_COLUMN}
            data={dataTableState.dataPage}
            page={dataTableState.page}
            rowPerPage={dataTableState.rowPerPage}
            total={dataTableState.total}
            sortColumn={dataTableState.sortColumn}
            sortType={dataTableState.sortType}
            classes={{ tableCell: classes.tableCell }}

            handlePageChange={dataTableState.changePage}
            handleRowClick={onRowClick}
            handleSortExternal={dataTableState.sortData}
            handleRowPerPage={() => { }}
        />
    )
}

export default TableUserGroupView 