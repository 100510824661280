import React, { Fragment, useEffect, useState } from 'react'

import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseLoadingState from '../../../../control/loading/UseLoadingState'

import AlertMessage from '../../../../control/alert/AlertMessage'
import Loading from '../../../../control/loading/Loading'

import LeaveSAPDetailAbsenceView from '../../presentational/leavesap/LeaveSAPDetailAbsenceView'

const LeaveSAPDetailAbsence = (props) => {

    const {
        onCancelClick,
        dataAbsence,
        info
    } = props

    const alertMessageState = UseAlertMessageState()
    const loadingState = UseLoadingState()

    const [absenceData, setAbsenceData] = useState({})

    const exportAttachment = (attachment, uri) => {
        fetch(attachment)
            .then(res => res.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = uri;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    }


    useEffect(() => {
        dataAbsence && setAbsenceData(dataAbsence)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <LeaveSAPDetailAbsenceView
                data={absenceData}
                onCancelClick={onCancelClick}
                onClickDownload={exportAttachment}
                info={info}
            />
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    )
}

export default LeaveSAPDetailAbsence