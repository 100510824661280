import { fetchGet } from '../utilities/ServiceUtils'
import * as ApiConstants from '../constants/ApiConstants'
import { API } from '../constants/ServiceMSGConstants'

export const getLeaveCollect = async (params) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_LEAVE_COLLECT}?${params}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        return err
    }
}

export const callServiceGetLutOrg = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LU_CUSTOM_ORG)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) { 
        return err
    }
}
