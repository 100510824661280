import { useState, useRef } from 'react'

const UseFormSearchCalendarState = () => {
    const [lutOrgOption, setLutOrgOption] = useState([]);
    const [lutMeetingRoomOption, setMeetingRoomOption] = useState([]);
    const [listSelectedMeetingRoomID, setListSelectedMeetingRoomID] = useState([]); //useState([3470,2310])
    const [defaultSelectedMeetingRoom, setDefaultSelectedMeetingRoom] = useState([]);
    const [searchData, setSearchData] = useState();
    const lutMeetingRoomRef = useRef([]);


    return {
        lutOrgOption,
        setLutOrgOption,
        lutMeetingRoomOption,
        setMeetingRoomOption,
        listSelectedMeetingRoomID,
        setListSelectedMeetingRoomID,
        defaultSelectedMeetingRoom,
        setDefaultSelectedMeetingRoom,
        searchData,
        setSearchData,
        lutMeetingRoomRef
    }
}

export default UseFormSearchCalendarState
