import React, { useEffect, Fragment, useContext } from 'react'
import { withRouter } from 'react-router';

import { isFindString } from '../../../../control/ManageDataFunctions'
import { getAllUserGroup, deleteUserGroup } from '../../../../api/ApiUseGroup'
import { UserGroupContext } from './UserGroupProvider'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'
import RouteConfig from '../../../../config/appRoute/RouteConfig'

import UsergroupView from '../../presentational/usergroup/UsergroupView'
import TableUserGroup from './TableUserGroup'

import { checkResStatusService } from '../../../../utilities/ResServiceUtils'

const Usergroup = ({ history }) => {
    const {
        isReSearch,
        empId,
        listUserGroup,
        setListUserGroup,
        txtSearch,
        setTxtSearch,
        permissionSystem,

        loadingState,
        alertMessageState,
        dataTableState,
        setResearch
    } = useContext(UserGroupContext)

    const getUserGroup = async () => {
        const userGroup = await getAllUserGroup()
        let altMsg = checkResStatusService(userGroup)
        let initListUserGroup = []

        if (userGroup && userGroup.data) {
            initListUserGroup = userGroup.data.map(userGroup => {
                userGroup.edit = permissionSystem.permisEdit
                userGroup.delete = permissionSystem.permisDelete && (!userGroup.member || userGroup.member === 0) && userGroup.userGroupId !== 1
                return userGroup
            })
        } else {
            alertMessageState.showAlert({ altMsg: altMsg ? altMsg : AlertMSGConstants.SYSTEM_ERROR })
        }

        const initialDataTable = filterDataTable(txtSearch, initListUserGroup)
        dataTableState.setInitialDataTable(initialDataTable)
        setListUserGroup(initListUserGroup)
    }

    const initailData = async () => {
        if (isReSearch || !dataTableState.dataTable.length) {
            loadingState.showLoading()
            await getUserGroup()
            setResearch(false)
            loadingState.hideLoading()
        }
    }

    useEffect(() => {
        initailData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const filterDataTable = (textSearch, data) => {
        if (textSearch) {
            const dataFilter = data.filter(usergroup => {
                const name = isFindString(usergroup.userGroupName, textSearch)
                const listSystem = isFindString(usergroup.listSystem, textSearch)
                const permissionOrg = isFindString(usergroup.permissionOrgName, textSearch)

                return (name || listSystem || permissionOrg)
            })

            return dataFilter
        } else {
            return data
        }
    }

    const handleSearch = (textSearch) => {
        const dataTableSearch = filterDataTable(textSearch, listUserGroup)
        setTxtSearch(textSearch)
        dataTableState.setInitialDataTable(dataTableSearch)
    }

    const handleAdd = () => {
        history.push(RouteConfig.UM_USER_GROUP_ADD)
    }

    const handleEdit = (data) => {
        history.push(RouteConfig.UM_USER_GROUP_EDIT, { userGroupId: data.userGroupId })
    }

    const handleDelete = (data) => {
        if (data.member > 0) {
            alertMessageState.showAlert({
                altMsg: `${AlertMSGConstants.DELETE_FAIL_IS_MEMBER} ${data.userGroupName}`,
            })
        } else {
            alertMessageState.showAlert({
                altMsg: `${AlertMSGConstants.CONFIRM_DELETE_USERGROUP} ${data.userGroupName} ?`,
                altType: 2,
                btnConfirmName: "ลบ",
                callbackConfirm: () => {
                    alertMessageState.hideAlert();
                    handleDeleteUserGroup(data.userGroupId)
                },
            })
        }
    }

    const handleDeleteUserGroup = (userGroupId) => {
        deleteUserGroup(userGroupId, empId).then(res => {
            let msg = checkResStatusService(res)
            const altMsg = res && !res.error ? `${AlertMSGConstants.DELETE_SUCCESS}` : msg ? msg : `${AlertMSGConstants.DELETE_FAIL}`
            alertMessageState.showAlert({
                altMsg: altMsg,
                callbackCancel: () => {
                    alertMessageState.hideAlert();
                    callBackSaveSuccess()
                }
            })
        })
    }

    const callBackSaveSuccess = async () => {
        loadingState.showLoading()
        await getUserGroup()
        loadingState.hideLoading()
    }

    return (
        <Fragment>
            <UsergroupView
                txtSearch={txtSearch}
                onSearch={handleSearch}
                onAdd={handleAdd}
                permisAdd={permissionSystem.permisAdd}
            >
                <TableUserGroup
                    dataTableState={dataTableState}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            </UsergroupView>
        </Fragment>
    )
}

export default withRouter(Usergroup)
