import React, { Fragment, useEffect, useContext } from 'react'
import { reduxForm, isDirty, formValueSelector } from "redux-form";
import { bindActionCreators } from 'redux';
import { compose } from "recompose";
import { connect } from 'react-redux';
import { sortBy } from 'lodash'
import { withRouter } from 'react-router';

import { MEETING } from '../../../../../constants/APDConstants';
import { setFormatValue, validateMeeting } from '../MeetingUtils';
// import { callServiceDeleteImage } from '../../../../../api/ApiMeeting'
import { RouterContext } from '../../../../../RouterProvider'
import { getDepId } from '../../../../../utilities/Utils'
import { newDateBytime } from '../../../../../utilities/DatetimeUtils';

import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'
import * as FocusActions from '../../../../../actions/FocusAction'

import UseLoadingState from '../../../../../control/loading/UseLoadingState';
import UseLupMeetingState from '../useMeetingState/UseLupMeetingState';
import UseAlertMessageState from '../../../../../control/alert/UseAlertMessageState';

import FormMeetingRoomView from '../../../presentational/manageMeetingRoom/formMeeting/FormMeetingRoomView';
import MtRoomUpload from './MtRoomUpload'
import MtRoomDescription from './MtRoomDescription';
import MtRoomCondition from './MtRoomCondition';
import Loading from '../../../../../control/loading/Loading';
import AlertMessage from '../../../../../control/alert/AlertMessage';

const { NAME } = MEETING.FORM
let dirtyGlobal = false
let imageGlobal = []

const FormMeetingRoom = ({
    dirtyForm,
    change,
    initialValue,
    initialize,
    authenRdc,
    handleSubmit,
    callBackSubmit,
    callBackCancel,
    // funcUploadImage,
    // funcDeleteImage,
    images,
    roomId,
    history
}) => {
    const { permissionOrgId } = authenRdc.profile
    const { setCallbackRouteChange } = useContext(RouterContext)
    const loadingState = UseLoadingState();
    const alertMessageState = UseAlertMessageState()
    const lupMeeting = UseLupMeetingState({ depId: getDepId(authenRdc.profile), permissionOrgId });

    const setInitailForm = async () => {
        loadingState.showLoading();
        const resLut = await lupMeeting.getLupMeeting();
        const superAdmin = resLut[9] && resLut[9].data ? resLut[9].data : []
        let initial = {}

        if (initialValue) {
            const maintainerInitial = initialValue[NAME.MAINTAINER]
            const maintainer = maintainerInitial.map(mainInit => {
                mainInit.isDelete = !superAdmin.some(superAdmin => superAdmin.empId === mainInit.empId)
                return mainInit
            })
            initialValue[NAME.MAINTAINER] = sortBy(maintainer, ['isDelete'])
            initial = initialValue
        } else {
            initial[NAME.MAINTAINER] = superAdmin.map(sa => ({ ...sa, isSuperAdmin: true, isDelete: false }))
            initial[NAME.BOOKING_WEEKEND] = true
            initial[NAME.COLOR] = "#F47B15"
            initial[NAME.RESERVATION_DATE] = 365
            initial[NAME.OPEN_TIME] = newDateBytime('7:30')
            initial[NAME.CLOSE_TIME] = newDateBytime('15:30')
            initial[NAME.AVAILABLE] = true
        }
        initialize(initial)
        loadingState.hideLoading();
    }

    useEffect(() => {
        setInitailForm()
        setCallbackRouteChange(handleChangeRoutePath)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dirtyGlobal = dirtyForm
    }, [dirtyForm])

    useEffect(() => {
        imageGlobal = images
    }, [images])

    useEffect(() => {
        return () => { setCallbackRouteChange() }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeRoutePath = location => {
        if (dirtyGlobal) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: "ยกเลิก",
                btnCancelName: "ไม่ยกเลิก",
                callbackConfirm: async () => {
                    // delTempImage(imageGlobal)
                    await setCallbackRouteChange()
                    alertMessageState.hideAlert()
                    history.push(location.pathname)
                }
            })
            return false
        } else {
            return true
        }
    }

    // const delTempImage = (images) => {
    //     const listImgDel = images ? images.filter(img => img.absolutePath).map(img => ({ absolutePath: img.absolutePath })) : []
    //     listImgDel.length && callServiceDeleteImage(listImgDel)
    // }

    const handleCancel = () => {
        if (dirtyForm) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: "ยกเลิก",
                btnCancelName: "ไม่ยกเลิก",
                callbackConfirm: async () => {
                    await setCallbackRouteChange()
                    alertMessageState.hideAlert()
                    // delTempImage(images)
                    callBackCancel && callBackCancel(MEETING.PAGE.MEETING);
                },
            })
        } else {
            callBackCancel && callBackCancel(MEETING.PAGE.MEETING);
        }
    }


    const handleFormSubmit = async (values) => {
        loadingState.showLoading();
        const result = { ...values }
        result.maintainer = values.maintainer.map(maintainer => ({ empId: maintainer.empId, isSuperAdmin: maintainer.isSuperAdmin }))
        result.uuid = authenRdc.profile.uuid;
        result.permissionOrg = values.permissionOrg ? values.permissionOrg : []
        result.permissionEmp = values.permissionEmp ? values.permissionEmp : []
        loadingState.hideLoading()
        callBackSubmit(setFormatValue(result))
    }

    return (
        <Fragment>
            <FormMeetingRoomView
                handleSubmit={handleSubmit}
                onCancel={handleCancel}
                onSubmit={handleFormSubmit}
            >
                <MtRoomUpload
                // roomId={roomId}
                // funcUploadImage={funcUploadImage}
                // funcDeleteImage={funcDeleteImage}
                />
                <MtRoomDescription
                    key="MtRoomDescription"
                    lup={lupMeeting.lup}
                    initialMaintainer={initialValue && initialValue.maintainer}
                    initialColor={initialValue && initialValue.color}
                    change={change}
                />
                <MtRoomCondition
                    key="MtRoomCondition"
                    lup={lupMeeting.lup}
                    change={change}
                />
            </FormMeetingRoomView>
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment >
    )
}

const reduxFormParameter = {
    form: 'MtRoomForm',
    validate: validateMeeting,
    onSubmitFail: (errors, dispatch, submitError, props) => dispatch(FocusActions.setFocusName(errors, props))
};

const selector = formValueSelector('MtRoomForm')

const mapStateToProps = state => ({
    focus: state.focus.name,
    authenRdc: state.authen,
    dirtyForm: isDirty('MtRoomForm')(state),
    images: selector(state, NAME.IMAGES)
})

const mapDispatchToProps = dispatch => ({
    focusActions: bindActionCreators(FocusActions, dispatch)
})

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(FormMeetingRoom)
