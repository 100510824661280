import { useState } from 'react'
import { sortBy } from 'lodash'

import { getUserInGroup } from '../../../../../api/ApiUser'
import { getLutEmpByPermission } from '../../../../../api/ApiLookup'
import { getLutExclusiveOrgLeave, getCustomLutOrgLeave } from '../../../../../api/ApiOrgLeave'
import { PERMISSION_ORG } from '../../../../../constants/APDConstants'
import { getDepId } from '../../../../../utilities/Utils'
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'
import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'

const UseAddUserState = ({ profile, listUser }) => {

    const [empInGroup, setEmpInGroup] = useState([])
    const [empStock, setEmpStock] = useState([])
    const [userGroupIdForUse, setUserGroupIdForUse] = useState(null)
    const [filterOrgId, setFilterOrgId] = useState(null) // เก็บหน่วยงานสำหรับ filte พนักงาน
    const [lut, setLut] = useState({
        lutEmp: [],
        lutEmpDisplay: [],
        lutOrg: [],
        lutAllUser: listUser
    })

    const _filterLutEmpDisplay = (lutEmp, user, orgId) => {
        // const empFilter = lutEmp.filter(emp => (!orgId || emp.orgId === orgId) && !user.some(user => user.empId === emp.empId))
        const empFilter = lutEmp.filter(emp => (!orgId || (emp.depId === orgId) || (emp.orgIdSAP === orgId)) && !user.some(user => user.empId === emp.empId))

        return empFilter
    }

    const setInitailLut = () => {
        const { permissionOrgId } = profile
        const depId = getDepId(profile)
        const getLutOrg = (permissionOrgId === PERMISSION_ORG.EXCLUSIVE_ORG) ? getLutExclusiveOrgLeave(depId) : getCustomLutOrgLeave()

        return Promise.all([
            getLutEmpByPermission({ permissionOrgId, depId: depId }),
            getLutOrg
        ]).then(([resEmp, resOrg]) => {
            if (resEmp && resEmp.data) {
                // const lutEmp = resEmp && resEmp.data ? resEmp.data : []
                const lutEmp = resEmp.data
                setLut({
                    ...lut,
                    lutEmp: lutEmp,
                    lutEmpDisplay: _filterLutEmpDisplay(lutEmp, lut.lutAllUser, filterOrgId),
                    lutOrg: resOrg && resOrg.data ? resOrg.data : []
                })
            } else {
                const msg = checkResStatusService(resEmp)
                return msg ? msg : AlertMSGConstants.SYSTEM_ERROR
            }

        })
    }

    const getEmpInGroup = (userGroupId) => {
        getUserInGroup(userGroupId).then(res => {
            if (res && res.data) {
                setEmpStock(res.data.users)
                manageEmpInGroup(res.data.users, userGroupId)
                setUserGroupIdForUse(userGroupId)
            }
        })
    }

    const manageEmpInGroup = (user, userGroupId) => {

        const userEmp = user.map(item => {
            if (item.empId === profile.empId) {
                item.notDelete = true
            }

            if (userGroupId === 1 && user.length === 1) {
                item.notDelete = true
            }

            return item
        })

        setEmpInGroup(userEmp)
    }

    const _insertUserInUserGroup = (user) => {
        const notDuplicateEmp = empInGroup.filter(item => item.empId === user.empId).length < 1
        if (notDuplicateEmp) {
            const newEmpInGroup = [...empInGroup, user]
            const lutAllUserUpdate = [...lut.lutAllUser, { empId: user.empId }]

            if (userGroupIdForUse === 1) {
                newEmpInGroup.map(item => {
                    if (newEmpInGroup.length === 1 || item.empId === profile.empId) {
                        item.notDelete = true
                    } else {
                        item.notDelete = false
                    }

                    return item
                })
            }

            setLut({
                ...lut,
                lutEmpDisplay: _filterLutEmpDisplay(lut.lutEmp, lutAllUserUpdate, filterOrgId),
                lutAllUser: lutAllUserUpdate
            })

            setEmpInGroup(newEmpInGroup)
        }
    }

    const _deleteUserInUserGroup = (user) => {
        const deleteEmp = empInGroup.filter(item => item.empId !== user.empId)

        if (userGroupIdForUse === 1) {
            deleteEmp.map(item => {
                if (deleteEmp.length === 1 || item.empId === profile.empId) {
                    item.notDelete = true
                } else {
                    item.notDelete = false
                }

                return item
            })
        }

        const lutAllUserUpdate = lut.lutAllUser.filter(lutUser => lutUser.empId !== user.empId)
        const lutEmpDisplayUpdate = _filterLutEmpDisplay(lut.lutEmp, lutAllUserUpdate, filterOrgId)
        const lutEmpDisplaySort = sortBy(lutEmpDisplayUpdate, ['empName'])

        setLut({
            ...lut,
            lutAllUser: lutAllUserUpdate,
            lutEmpDisplay: lutEmpDisplaySort
        })
        setEmpInGroup(deleteEmp)
    }

    const resetLutEmp = () => {
        setLut({
            ...lut,
            lutEmpDisplay: _filterLutEmpDisplay(lut.lutEmp, listUser, filterOrgId),
            lutAllUser: listUser
        })
    }

    const changeOrg = orgId => { // change org filter emp
        setLut({
            ...lut,
            lutEmpDisplay: _filterLutEmpDisplay(lut.lutEmp, lut.lutAllUser, orgId)
        })
        setFilterOrgId(orgId)
    }

    return {
        empInGroup,
        empStock,
        lutEmpDisplay: lut.lutEmpDisplay,
        lutOrg: lut.lutOrg,

        getEmpInGroup,
        setInitailLut,
        resetLutEmp,
        changeOrg,
        _insertUserInUserGroup,
        _deleteUserInUserGroup
    }
}

export default UseAddUserState