import { useState } from 'react'

const UseTableBookingState = () => {
    const [exportData, setExportData] = useState(null)

    return {
        exportData,
        setExportData
    }
}

export default UseTableBookingState
