import { useState } from 'react';

const UsePanelState = () => {
    const [panelActive, setPanelActive] = useState();
    const [dataEditEmp, setDataEditEmp] = useState(null);
    const [dataEditOrg, setDataEditOrg] = useState(null);
    const [dataDetailOrg, setDataDetailOrg] = useState(null);

    const setPanel = (mode, data) => { // mode = EDIT_ORG, EDIT_EMP, DETAIL_ORG
        switch (mode) {
            case "EDIT_ORG":
                setDataEditOrg(data)
                break;
            case "EDIT_EMP":
                setDataEditEmp(data)
                break;
            case "DETAIL_ORG":
                setDataDetailOrg(data)
                break;
            default:
                break;
        }
        setPanelActive(mode)
    }

    return {
        panelActive,
        dataEditEmp,
        dataEditOrg,
        dataDetailOrg,

        setPanel,
    }
}

export default UsePanelState
