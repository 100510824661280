import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import RouteConfig from '../config/appRoute/RouteConfig'
import { FUNCTIONS } from '../constants/APDConstants'

// import OrgLeave from '../components/leave/container/orgLeave/OrgLeave'
import OrgLeaveIndex from '../components/leave/container/orgLeave/OrgLeaveIndex'
import Holiday from '../components/leave/container/holiday/Holiday'
// import Sap from '../components/leave/container/leavesap/LeaveSAP'
import Sap from '../components/leave/container/leavesap/LeaveSAPIndex'
import LeaveCollect from '../components/leave/container/leavecollect/LeaveCollectIndex'
import Absence from '../components/leave/container/absence/AbsenceIndex'

function Leave({ authenRdc }) {
    const router = []

    authenRdc.profile.functions.forEach(func => {
        switch (func.functionId) {
            case FUNCTIONS.ORG_LEAVE.id:
                router.push(<Route exact key={RouteConfig.LEAVE_ORG} path={RouteConfig.LEAVE_ORG} component={OrgLeaveIndex} />)
                break;
            case FUNCTIONS.HOLIDAY.id:
                router.push(<Route exact key={RouteConfig.LEAVE_HOLIDAY} path={RouteConfig.LEAVE_HOLIDAY} component={Holiday} />)
                break;
            case FUNCTIONS.LEAVE_SAP.id:
                router.push(<Route key={RouteConfig.LEAVE_SAP} path={RouteConfig.LEAVE_SAP} component={Sap} />)
                break;
            case FUNCTIONS.LEAVE_COLLECT.id:
                router.push(<Route key={RouteConfig.LEAVE_COLLECT} path={RouteConfig.LEAVE_COLLECT} component={LeaveCollect} />)
                break;
            case FUNCTIONS.ABSENCE.id:
                router.push(<Route exact key={RouteConfig.LEAVE_ABSENCE} path={RouteConfig.LEAVE_ABSENCE} component={Absence} />)
                break;
            default:
                break;
        }
    })

    return (
        <Switch>
            {router}
            <Route path={RouteConfig.LEAVE} render={() => {
                const pathRedirect = router.length ? router[0].key : '/'
                return <Redirect to={pathRedirect} />
            }} />
        </Switch>
    );
}

const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default connect(mapStateToProps)(Leave);