import React, { useContext } from 'react'

import { ConnextContext } from './ConnextProvider'
import { addMaintenance } from '../../../../api/ApiMaintenance'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'

import FormMaintenance from './form/FormMaintenance'
import ModalConnextView from '../../presentational/connext/ModalConnextView'

import { checkResStatusService } from '../../../../utilities/ResServiceUtils'

const AddMaintenance = ({
    callbackSaveSuccess
}) => {
    const { modalState, alertMessageState, loadingState } = useContext(ConnextContext)

    const handleSubmit = async values => {
        loadingState.showLoading()
        const res = await addMaintenance(values)
        let altMsg = checkResStatusService(res)
        loadingState.hideLoading()

        if (res && res.data && res.data.maId) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.SAVE_SUCCESS,
                callbackCancel: () => {
                    alertMessageState.hideAlert()
                    callbackSaveSuccess()
                }
            })
        } else {
            alertMessageState.showAlert({ altMsg: altMsg ? altMsg : AlertMSGConstants.SYSTEM_ERROR })
        }
    }

    const handleCancel = () => {
        modalState.setModal()
    }

    return (
        <ModalConnextView>
            <FormMaintenance
                title="สร้างรายการแจ้งเตือนปิดระบบ"
                callBackSubmit={handleSubmit}
                callBackCancel={handleCancel}
            />
        </ModalConnextView>
    )
}

export default AddMaintenance
