import React, { Fragment, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getHashValue } from '../../utilities/Utils';
import {
    getTokenLocalStore,
    setTokenLocalStore,
    clearTokenLocalStore,
} from '../../utilities/LocalStorageUtils';
// import * as AlertMSGConstants from '../../constants/AlertMSGConstants';
import * as AuthenAction from '../../actions/authentications/AuthenAction';
import routeConfig from './RouteConfig';
import UseAlertMessageState from '../../control/alert/UseAlertMessageState';

import AlertMessage from '../../control/alert/AlertMessage';
import Loading from '../../control/loading/Loading';

const PrivateRoute = ({
    component: Component,
    authenRdc,
    authenAction,
    ...PrivateRouteProps
}) => {
    const alertMessageState = UseAlertMessageState();
    const [isGetProfile, setGetProfile] = useState(false);
    const [isGetProfileFail, setGetProfileFail] = useState(false);

    const renderRoute = (props) => {
        const access_token = getHashValue('access_token');
        const tokenLocal = getTokenLocalStore();

        if (authenRdc.isLogin) {
            return <Component {...props} />;
        } else if (tokenLocal || access_token) {
            const token = tokenLocal ? tokenLocal : access_token;
            setTokenLocalStore(token);

            if (access_token) {
                // ลบ access_token ออกจาก url
                // window.history.replaceState({}, '/#access_token', '/')
                window.history.replaceState(
                    {},
                    '/#access_token',
                    process.env.REACT_APP_ROOT_URL
                );
            }

            if (!isGetProfile) {
                authenAction.getProfile({
                    callbackError: callbackGetProfileFail,
                });
                setGetProfile(true);
            }

            return isGetProfileFail ? <Fragment /> : <Loading />;
        } else {
            return <Redirect to={routeConfig.LOGIN} />;
        }
    };

    const callbackGetProfileFail = (errMsg) => {
        setGetProfileFail(true);
        alertMessageState.showAlert({
            altMsg: errMsg,
            callbackCancel: () => {
                alertMessageState.hideAlert();
                clearTokenLocalStore();
                // window.location = process.env.REACT_APP_ROOT_URL + RouteConfig.LOGIN
            },
        });
    };

    return (
        <Fragment>
            <Route
                {...PrivateRouteProps}
                render={(props) => {
                    return renderRoute(props);
                }}
            />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    authenRdc: state.authen,
});

const mapDispatchToProps = (dispatch) => ({
    authenAction: bindActionCreators(AuthenAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
