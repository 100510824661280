import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'

import Authen from "./AuthenReducer";
import Focus from './FocusReducer'

const rootReducer = combineReducers({
	form: formReducer,
	authen: Authen,
	focus: Focus
});

export default rootReducer;