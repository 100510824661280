import React, { useContext, useEffect } from 'react'
import { reduxForm } from 'redux-form'

import ForceUpdateEditView from '../../../presentational/forceUpdate/ForceUpdateEditView'

import { ForceUpdateContext } from '../ForceUpdateProvider'

const ForceUpdateEdit = ({ initialize, handleSubmit, handleUpdateAppVersion, handleCloseForm }) => {

    const { forceUpdaeState } = useContext(ForceUpdateContext)

    useEffect(() => {
        initialize({
            defaultPlatform: forceUpdaeState.editData.defaultPlatform,
            platform: forceUpdaeState.editData.platform,
            version: forceUpdaeState.editData.version
        })
    }, [])

    return (
        <ForceUpdateEditView
            editData={forceUpdaeState.editData}
            onClickClose={handleCloseForm}
            onClickSubmit={handleSubmit(handleUpdateAppVersion)}
        />
    )
}

const isInt = (val) => {
    let int = false

    if ((Number(val) || Number(val) === 0) && val.trim() !== "") {
        int = true
    }

    return int
}

const isFloat = (val) => {
    let float = false

    const splitVal = val.split(".")

    if (splitVal.length === 3) {
        const sp_0 = isInt(splitVal[0])
        const sp_1 = isInt(splitVal[1])
        const sp_2 = isInt(splitVal[2])

        if (sp_0 && sp_1 && sp_2) {
            float = true
        }
    }

    return float
}

const validateForceUpdate = (values) => {
    const errors = {}
    const REQUIRED_VALUE = 'กรุณาระบุ version'
    const INCORRECT = 'กรุณาระบุข้อมูลให้ถูกต้อง'

    if (!values.version) {
        errors.version = REQUIRED_VALUE
    } else {
        const isFl = isFloat(values.version)

        if (!isFl) {
            errors.version = INCORRECT
        }
    }

    return errors
}

const reduxFormParameters = {
    form: 'forceUpdate',
    validate: validateForceUpdate
}

export default reduxForm(reduxFormParameters)(ForceUpdateEdit)