import React, { Fragment, useContext } from 'react'
import { reduxForm } from 'redux-form'

import CancelBookingView from '../../../presentational/booking/bookingList/CancelBookingView'

import { BookingContext } from '../BookingProvider'

const CancelBooking = (props) => {

    const {
        myProfile,
        onClickClose,
        handleSubmit,
        callbackCancelBooking
    } = props

    const { alertMessageState } = useContext(BookingContext)

    const handleCancelBooking = (values) => {
        alertMessageState.showAlert({
            altMsg: 'คุณต้องการยืนยันยกเลิกรายการจองห้องประชุม ?',
            altType: 2,
            callbackCancel: () => alertMessageState.hideAlert(),
            callbackConfirm: () => {
                callbackCancelBooking(values.reason)
            }
        })
    }

    return (
        <Fragment>
            <CancelBookingView 
                myProfile={myProfile}
                onClickClose={onClickClose}
                onSubmitClick={handleSubmit(handleCancelBooking)}
            />
        </Fragment>
    )
}

const cancelBookingValidate = (values) => {
    const errors = {}

    if(!values.reason) {
        errors.reason = 'กรุณาระบุข้อมูล'
    }

    return errors
}

const reduxFormParameter = {
    form: 'CancelBookingReason',
    validate: cancelBookingValidate
}

export default reduxForm(reduxFormParameter)(CancelBooking)