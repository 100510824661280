import { useState, useEffect } from 'react';

import { getLutBooking } from '../../../../../api/ApiBooking';

import { formatResult } from '../DataManageBookingByDate';
import { callServiceMEADistrict } from '../../../../../api/ApiMeeting';

const UseBookingByDateState = ({ loadingState }) => {
    const [valueSearch, setValueSearch] = useState({
        date: null,
        floor: null,
        building: null
    });
    const [resultSearch, setResultSearch] = useState([]);
    const [lut, setLut] = useState({
        lutBuilding: [],
        lutMeaDistrict: []
    });

    useEffect(() => {
        // callServiceGetBuiding();
        // callServiceGetMeaDistrict();

        getLut();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getLut = async () => {
        Promise.all([
            getLutBooking(),
            callServiceMEADistrict()
        ])
            .then(([resBuilding, resMeaDistrict]) => {
                let lutBuilding = [], lutMeaDistrict = []

                if (resBuilding && resBuilding.data) {
                    lutBuilding = formatLut({
                        data: resBuilding.data,
                        keyId: 'building',
                        keyName: 'building'
                    })
                }

                if (resMeaDistrict && resMeaDistrict.data) {
                    lutMeaDistrict = resMeaDistrict.data
                }

                setLut({
                    ...lut,
                    lutBuilding: lutBuilding,
                    lutMeaDistrict: lutMeaDistrict
                })
            })
    }

    // const callServiceGetBuiding = () => {
    //     getLutBooking().then(res => {
    //         if (res && res.data) {
    //             setLut({
    //                 ...lut,
    //                 lutBuilding: formatLut({
    //                     data: res.data,
    //                     keyId: 'building',
    //                     keyName: 'building'
    //                 })
    //             });
    //         }
    //     });
    // };

    // const callServiceGetMeaDistrict = async () => {
    //     const res = await callServiceMEADistrict();
    //     if (res && res.data) {
    //         setLut({
    //             ...lut,
    //             lutMeaDistrict: res.data
    //         });
    //     }
    // };

    const formatLut = ({ data, keyId, keyName }) => {
        const newFormat = data.map(item => {
            item.id = item[keyId];
            item.name = item[keyName];

            return item;
        });

        return newFormat;
    };

    const setResult = result => {
        const newResult = formatResult(result);
        setResultSearch(newResult);
    };

    return {
        valueSearch,
        lut,
        resultSearch,

        setValueSearch,
        setResult
    };
};

export default UseBookingByDateState;
