import React, { useContext } from 'react'

import { AbsenceContext } from './AbsenceProvider'

import TableAbsenceView from '../../presentational/absence/TableAbsenceView';

const TableAbsence = ({
    onEdit
}) => {
    const { dataTableState } = useContext(AbsenceContext)

    const handleRowClick = (data, type) => {
        onEdit(data, type)
    }

    return (
        <TableAbsenceView
            dataTableState={dataTableState}
            onRowClick={handleRowClick}
        />
    )
}

export default TableAbsence
