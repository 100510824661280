import React, { Fragment } from 'react'
import { Field } from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import Autocomplete from '../../../../control/autocomplete/AutoComplete'
import TextFields from '../../../../control/textfield/TextFields'

import Button from '../../../../control/button/Button'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '24px',
        width: '50%',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('md')]: {
            width: '80%'
        }
    },
    gridTitle: {
        fontSize: '33px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    }
}))

const FormUserView = (props) => {

    const classes = useStyles()
    const {
        textTitle,
        onCancelClick,
        lutUser,
        onSubmitClick
    } = props

    return (
        <Fragment>
            <Modal
                className={classes.modal}
                open={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <span className={classes.gridTitle}>{textTitle}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name='name'
                            component={TextFields}
                            // options={lutUser.lutEmp}
                            // optionKey={{ label: 'empName', value: "empId", abbr: 'empId' }}
                            label='พนักงาน'
                            disabled={true}
                        />
                        <Field
                            name='userGroupId'
                            component={Autocomplete}
                            options={lutUser.lutUserGroup}
                            label='กลุ่มผู้ใช้งาน'
                            optionKey={{ label: "userGroupName", value: "userGroupId", abbr: null }}
                            required
                        />
                    </Grid>
                    <Grid container justify='center' spacing={2}>
                        <Grid item>
                            <Button label='ยกเลิก' onClick={onCancelClick} className={classes.btnCancel} />
                        </Grid>
                        <Grid item>
                            <Button label='บันทึก' onClick={onSubmitClick} />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </Fragment>
    )
}

export default FormUserView