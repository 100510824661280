import React, { createContext } from 'react'

import UseLoadingState from '../../../../control/loading/UseLoadingState'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseCancelBookingState from './useCancelBookingState.js/UseCancelBookingState'
import UseDataTableState from '../../../../control/table/UseDataTableState'

export const CancelBookingContext = createContext()

const CancelBookingProvider = ({ children }) => {

    const loadingState = UseLoadingState()
    const alertMessageState = UseAlertMessageState()
    const cancelBookingState = UseCancelBookingState({ loadingState: loadingState, alertMessageState: alertMessageState })
    const dataTableCancelBookingState = UseDataTableState({
        rowPerPage: 10,
        specialColumn: {
            dateBookingDisplay: 'dateForSort'
        }
    })

    const value = {
        loadingState,
        alertMessageState,
        cancelBookingState,
        dataTableCancelBookingState
    }

    return (

        <CancelBookingContext.Provider value={value} >
            {children}
        </CancelBookingContext.Provider>
    )
}

export default CancelBookingProvider
