import React, { useContext, useEffect, useState } from 'react'
import { reduxForm, formValueSelector } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { OrgLeaveContext } from '../OrgLeaveProvider'
import { ORG_LEAVE } from '../../../../../constants/APDConstants'
import { isFindString } from '../../../../../control/ManageDataFunctions'

import EmpToolbarView from '../../../presentational/orgLeave/toolbar/EmpToolbarView'

const EmpToolbar = React.memo(({
    change,
    initialize,
    type,
    onClose,
    handleSubmit
}) => {
    const { orgState } = useContext(OrgLeaveContext)
    const [empDisplays, setEmpDisplay] = useState()
    const [textSearch, setTextSearch] = useState()

    const getEmpChanged = (emp, dataEmpInActive = []) => {
        const empChange = emp.filter(emp => emp.isNewEmp || emp.prevOrgId || emp.isChangeManager)

        return [...empChange, ...dataEmpInActive]
    }

    useEffect(() => {
        const emps = getEmpChanged(orgState.emp, orgState.dataEmpInActive).map(item => ({ ...item, isDisplay: true }))
        initialize({
            [ORG_LEAVE.FORM_EDIT_TOOLBAR.NAME.TYPE]: 1,
            [ORG_LEAVE.FORM_EDIT_TOOLBAR.NAME.EMPS]: emps
        })
        setEmpDisplay(emps)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChageType = ({ id }) => {
        let emps = orgState.emp
        if (id === 1) { // เฉพาะพนักงานที่เปลื่ยนแปลง
            emps = getEmpChanged(orgState.emp, orgState.dataEmpInActive)
        }
        const empFilter = filterData(textSearch, emps)
        setEmpDisplay(empFilter)
        change([ORG_LEAVE.FORM_EDIT_TOOLBAR.NAME.EMPS], emps)
    }

    const mergeEmp = (empUpdate) => {
        return orgState.emp.map(emp => {
            const [empFilter] = empUpdate.filter(empUpdate => empUpdate.id === emp.id)

            if (empFilter) {
                return empFilter
            } else {
                return emp
            }
        })
    }

    const handleSubmitForm = async ({ emps }) => {
        const empUpdate = type === 1 ? mergeEmp(emps) : emps
        const empIdModifyUpdate = emps.map(emp => emp.id)
        await orgState.updateAllEmp(empUpdate, empIdModifyUpdate)
        window.chart.draw();
        onClose()
    }

    const filterData = (textSearch, data) => {
        if (textSearch) {
            return data.map(item => {
                const id = isFindString(item.id, textSearch)
                const name = isFindString(item.name, textSearch)
                const orgName = isFindString(item.orgName, textSearch)
                const prevOrgName = isFindString(item.prevOrgName, textSearch)

                item.isDisplay = (id || name || orgName || prevOrgName)

                return item
            })
        } else {
            return data.map(item => ({ ...item, isDisplay: true }))
        }
    }

    const handleSearch = (textSearch) => {
        const textSearchTrim = textSearch ? textSearch.trim() : null
        const empFilter = filterData(textSearchTrim, empDisplays)
        setEmpDisplay(empFilter)
        setTextSearch(textSearchTrim)
    }

    return (
        <EmpToolbarView
            emps={empDisplays}
            lutOrg={orgState.structureOrg}
            type={type}
            change={change}

            onChangeType={handleChageType}
            onClose={onClose}
            onSubmit={handleSubmit(handleSubmitForm)}
            onSearchClick={handleSearch}
        />
    )
})

const reduxFormParam = {
    form: 'manageEmp',
    initialValues: {
        [ORG_LEAVE.FORM_EDIT_TOOLBAR.NAME.TYPE]: 1,
    }
}

const selector = formValueSelector('manageEmp')

const mapStateToProps = state => ({
    type: selector(state, ORG_LEAVE.FORM_EDIT_TOOLBAR.NAME.TYPE)
})

export default compose(
    reduxForm(reduxFormParam),
    connect(mapStateToProps)
)(EmpToolbar)
