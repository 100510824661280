const TABLE = {
    DATA: {
        NO: 'no',
        PLATFORM: 'platform',
        VERSION: 'version',
    },
    TITLE: {
        NO: 'ลำดับ',
        PLATFORM: 'ระบบปฏิบัติการ',
        VERSION: 'Version',
    },
};

export const COLUMN = [
    {
        title: TABLE.TITLE.NO,
        data: TABLE.DATA.NO,
        width: '20%',
        sort: 'asc',
        align: 'center',
    },
    {
        title: TABLE.TITLE.PLATFORM,
        data: TABLE.DATA.PLATFORM,
        width: '40%',
        sort: 'asc',
        align: 'center',
    },
    {
        title: TABLE.TITLE.VERSION,
        data: TABLE.DATA.VERSION,
        width: '40%',
        sort: 'asc',
        align: 'center',
    },
];

export const TITLE = 'จัดการ Version Force Update';
export const PLATFORM = 'ระบบปฏิบัติการ';
export const VERSION = 'version';
