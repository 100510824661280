import React, { useContext } from 'react'

import { ConnextContext } from './ConnextProvider'

import TableConnextView from '../../presentational/connext/TableConnextView'

const TableConnext = ({
    onEdit,
    onDelete,
    onClickAddMaintenance
}) => {
    const { dataTableState } = useContext(ConnextContext)

    const handleRowClick = (data, type) => {
        if (type === 'edit') {
            onEdit(data)
        } else {
            onDelete(data)
        }
    }


    return (
        <TableConnextView
            dataTableState={dataTableState}
            onRowClick={handleRowClick}
            onClickAddMaintenance={onClickAddMaintenance}
        />
    )
}

export default TableConnext
