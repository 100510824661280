export const NAME = 'จัดการวันหยุด'
export const NAME_YEAR = 'วันหยุดประจำปี'
export const BACK_YEAR = 'back'
export const NEXT_YEAR = 'next'
export const ADD_HOLIDAY = 'เพิ่มวันหยุด'
export const EXPORT = 'ออกรายงานที่ใบลามีผลกระทบ'

export const NAME_OF_DAY = [
    { id: 0, name: 'อาทิตย์' },
    { id: 1, name: 'จันทร์' },
    { id: 2, name: 'อังคาร' },
    { id: 3, name: 'พุธ' },
    { id: 4, name: 'พฤหัสบดี' },
    { id: 5, name: 'ศุกร์' },
    { id: 6, name: 'เสาร์' }
]

export const NAME_OF_MONTH = [
    { id: 0, name: 'มกราคม' },
    { id: 1, name: 'กุมภาพันธ์' },
    { id: 2, name: 'มีนาคม' },
    { id: 3, name: 'เมษายน' },
    { id: 4, name: 'พฤษภาคม' },
    { id: 5, name: 'มิถุนายน' },
    { id: 6, name: 'กรกฎาคม' },
    { id: 7, name: 'สิงหาคม' },
    { id: 8, name: 'กันยายน' },
    { id: 9, name: 'ตุลาคม' },
    { id: 10, name: 'พฤศจิกายน' },
    { id: 11, name: 'ธันวาคม' }
]

export const TABLE = {
    ASC: 'asc',
    DESC: 'desc',
    LABEL: {
        HOLIDAY_ID: 'ลำดับ',
        DAY_NAME: 'วัน',
        // DAY: 'วันที่',
        // MONTH: 'เดือน',
        // YEAR: 'ปี',
        DATE: 'วันที่',
        DESCRIPTION: 'ชื่อวันหยุด',
        ENABLED: 'สถานะการใช้งาน'
    },
    DATA: {
        HOLIDAY_ID: 'holidayId',
        DAY_NAME: 'dayName',
        // DAY: 'day',
        // MONTH: 'month',
        // YEAR: 'year',
        DATE: 'dateDisplay',
        DESCRIPTION: 'description',
        ENABLED: 'enabled'
    },
}

export const FORM = {
    LABEL: {
        HOLIDAY_DAY: 'วันที่',
        INFO: 'ชื่อวันหยุด',
        DES: 'จากคำสั่ง',
        ENABLED: 'สถานะการใช้งาน'
    },
    NAME: {
        HOLIDAY_DAY: 'date',
        INFO: 'info',
        DES: 'description',
        ENABLED: 'enabled'
    },
    ADD_TITLE: 'เพิ่มวันหยุดประจำปี',
    EDIT_TITLE: 'แก้ไขวันหยุดประจำปี',
}

export const COLUMN = [
    { title: TABLE.LABEL.HOLIDAY_ID, data: 'id', width: '10%', sort: TABLE.ASC },
    { title: TABLE.LABEL.DAY_NAME, data: TABLE.DATA.DAY_NAME, width: '10%', sort: TABLE.ASC },
    // { title: TABLE.LABEL.DAY, data: TABLE.DATA.DAY, width: '6%', sort: TABLE.ASC },
    // { title: TABLE.LABEL.MONTH, data: TABLE.DATA.MONTH, width: '11%', sort: TABLE.ASC },
    // { title: TABLE.LABEL.YEAR, data: TABLE.DATA.YEAR, width: '7%', sort: TABLE.ASC },
    { title: TABLE.LABEL.DATE, data: TABLE.DATA.DATE, width: '20%', sort: TABLE.ASC },
    { title: TABLE.LABEL.DESCRIPTION, data: 'holidayInfoName', width: '50%', sort: TABLE.ASC, align: 'left' },
    { title: TABLE.LABEL.ENABLED, data: TABLE.DATA.ENABLED, width: '10%', sort: TABLE.ASC },
]

export const STR_ADD = 'เพิ่ม'
export const STR_CANCEL = 'ยกเลิก'
export const STR_DELETE = 'ลบ'

export const EXPORT_EXCEL = 'ออกรายงานใบลาที่มีผลกระทบ'
export const REPORT = 'ออกรายงาน'