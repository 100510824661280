//default
export const SAVE_SUCCESS = 'บันทึกข้อมูลสำเร็จ';
export const SAVE_FAIL = 'บันทึกข้อมูลไม่สำเร็จ';
export const DELETE_SUCCESS = 'ลบข้อมูลสำเร็จ';
export const DELETE_FAIL = 'ลบข้อมูลไม่สำเร็จ';
export const SYSTEM_ERROR = 'เกิดข้อผิดพลาดจากระบบ';
export const ERROR_EXPORT = 'เกิดข้อผิดพลาดในการออกรายงาน'
export const CONFIRM_CANCEL_EDIT =
    'ข้อมูลยังไม่ถูกบันทึก คุณต้องการยกเลิกการเปลี่ยนแปลง ?';

//authen
export const NOT_ACCESS_TO_APD_WEB = 'คุณไม่มีสิทธิ์ใช้งานระบบ APD';
export const LOGOUT_FAIL = 'ออกจากระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง';

// MEETING
export const GET_MEETING_BY_ID_FIELD = 'ดึงข้อมูลห้องประชุมไม่สำเร็จ';
export const UPLOAD_IMAGE_TYPE_FAIL =
    'รองรับรูปภาพที่มีนามสกุล .png .jpg .jpeg เท่านั้น';
export const UPLOAD_IMAGE_LIMIT_FILE_SIZE = 'ขนาดไฟล์ต้องไม่เกิน 5 MB';
export const SAVE_IMAGE_SUCCESS = 'บันทึกรูปภาพสำเร็จ';
export const SAVE_IMAGE_FAIL = 'บันทึกรูปภาพไม่สำเร็จ';
export const CONFIRM_DEL_MEETINGROOM = 'คุณต้องการลบ';
export const CONFIRM_CANCEL_EDIT_ORG =
    'ข้อมูลยังไม่ถูกบันทึก คุณต้องการยกเลิกการเปลี่ยนแปลง ?';
export const UPLOAD_IMAGE_FAIL = 'อัพโหลดรูปภาพไม่สำเร็จ';
export const DELETE_IMAGE_FAIL = 'ลบรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง';

//UM
export const DELETE_FAIL_IS_MEMBER =
    'ไม่สามารถลบได้เนื่องจากยังมีสมาชิกในกลุ่ม';
export const CONFIRM_DELETE_USERGROUP = 'คุณต้องการลบ กลุ่มผู้ใช้งาน';
export const DUPLICATE_USERGROUP_NAME = 'ชื่อกลุ่มผู้ใช้งานนี้ถูกใช้งานแล้ว';
export const COMFIRM_DELETE_USER = 'คุณต้องการลบ ผู้ใช้งาน';
export const UPDATE_UG_MAINTAINER_FAIL =
    'บันทึกกลุ่มผู้ใช้งานสำเร็จ แต่อัพเดทผู้ดูแลห้องประชุมไม่สำเร็จกรุณาตรวจสอบ';
export const INSERT_U_MAINTAINER_FAIL =
    'เพิ่มผู้ใช้งานสำเร็จ แต่อัพเดทผู้ดูแลห้องประชุมไม่สำเร็จกรุณาตรวจสอบ';
export const UPDATE_U_MAINTAINER_FAIL =
    'บันทึกผู้ใช้งานสำเร็จ แต่อัพเดทผู้ดูแลห้องประชุมไม่สำเร็จกรุณาตรวจสอบ';
export const DELETE_U_MAINTAINER_FAIL =
    'ลบผู้ใช้งานสำเร็จ แต่อัพเดทผู้ดูแลห้องประชุมไม่สำเร็จกรุณาตรวจสอบ';
export const CANCEL_CHANGE_USER_GROUP =
    'คุณต้องการยกเลิกการเปลี่ยนแปลงกลุ่มผู้ใช้งาน';
// export const CONFIRM_CANCEL_EDIT_USER_GROUP = "ข้อมูลยังไม่ถูกบันทึก คุณต้องการยกเลิกการเปลี่ยนแปลง ?"

//ABSENCE
export const CANCEL_ABSENCE_SUCCESS = 'ยกเลิกใบลาสำเร็จ';

//BOOKING
export const CANCEL_BOOKING_SUCCESS = 'ยกเลิกรายการจองสำเร็จ';
