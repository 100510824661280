export const isFindString = (str, searching) => {
    let checking = false;
    if (str) {
        checking = str.toUpperCase().indexOf(searching.toUpperCase()) !== -1;
    }
    return checking;

};

const compareObjSortASC = attrName => (a, b) => {
    const attrA = a[attrName] ? a[attrName] : ""
    const attrB = b[attrName] ? b[attrName] : ""
    return attrA.toString().localeCompare(attrB.toString(), "th", {
        sensitivity: "base"
    });
};

const compareObjSortDESC = attrName => (a, b) => {
    const attrA = a[attrName] ? a[attrName] : ""
    const attrB = b[attrName] ? b[attrName] : ""
    return attrB.toString().localeCompare(attrA.toString(), "th", {
        sensitivity: "base"
    });
};

export const sortObjASC = (arrObjData, attrName) => {
    const result = arrObjData.sort(compareObjSortASC(attrName));
    return result;
};

export const sortObjDESC = (arrObjData, attrName) => {
    const result = arrObjData.sort(compareObjSortDESC(attrName));
    return result;
};

export const compareObjNumberSortASC = attrName => (a, b) => {
    return Number(b[attrName]) - Number(a[attrName])
}

export const compareObjNumberSortDESC = attrName => (a, b) => {
    return Number(a[attrName]) - Number(b[attrName])
}

export const sortObjNumberASC = (arrObjData, attrName) => {
    const result = arrObjData.sort(compareObjNumberSortASC(attrName))
    return result
}

export const sortObjNumberDESC = (arrObjData, attrName) => {
    const result = arrObjData.sort(compareObjNumberSortDESC(attrName))
    return result
}

export const compareObjDateSortASC = attrName => (a, b) => {
    return new Date(b[attrName]) - new Date(a[attrName])
}

export const sortObjDateASC = (arrObjData, attrName) => {
    const result = arrObjData.sort(compareObjDateSortASC(attrName))
    return result
}

export const compareObjDateSortDESC = attrName => (a, b) => {
    return new Date(a[attrName]) - new Date(b[attrName])
}

export const sortObjDateDESC = (arrObjData, attrName) => {
    const result = arrObjData.sort(compareObjDateSortDESC(attrName))
    return result
}
