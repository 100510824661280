import React from 'react'
import { getTokenLocalStore } from '../../utilities/LocalStorageUtils';
import { Route, Redirect } from 'react-router-dom';

const PrivateRouteLogin = ({
    component: Component,
    ...PrivateRouteProps
}) => {
    const tokenLocal = getTokenLocalStore();

    return <Route
        {...PrivateRouteProps}
        render={props => {
            if (tokenLocal) {
                return <Redirect to="/" />
            } else {
                return <Component {...props} />
            }
        }}
    />
}

export default PrivateRouteLogin
