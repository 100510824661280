import * as actionTypes from '../constants/ActionTypes'

export const setFocusName = (error, props) => {

    let name = ''

    for(let i in error) {
        if(typeof error[i] === 'string') {
            if(error[i]) {
                name = i

                let find = false
                for(let m in props.syncErrors) {
                    if(i === m) {
                        find = true
                        break;
                    }
                }

                if(find) break;
            }
        }
        else {
            let find = false

            for(let j in error[i]) {
                if(typeof error[i][j]  === 'string') {
                    if(error[i][j]) {
                        name = i + '.' + j
                        find = true
                        break;
                    }
                }
                else {
                    for(let k in error[i][j]) {
                        if(typeof error[i][j][k] === 'string') {
                            if(error[i][j][k]) {
                                name = i + '[' + j + '].' + k
                                find = true
                                break;
                            }
                        }
                    }
                }
                if(find) break;
            }
            if(find) break;
        }
    }
    
    return {
        type: actionTypes.SET_FOCUS_TEXTFIELD_NAME,
        name: name
    }
}

export const setDefault = () => {
    return {
        type: actionTypes.SET_DEFAULT
    }
}
