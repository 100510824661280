import React, { useState } from 'react'
import { withStyles, TextField, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

import { CONTROL_SEARCH } from '../../constants/APDConstants'
import Button from '../button/Button'

const Search = (props) => {

    const {
        waterMark,
        classes,
        iniValue,
        onSearchClick
    } = props

    // const [valueChange, setValueChange] = useState('')
    const [valueChange, setValueChange] = useState(iniValue)
    const [btnClear, setBtnClear] = useState(false)

    const handleChangeValue = (value) => {
        setValueChange(value)
        if (value.length === 0) {
            onSearchClick(null)
            setBtnClear(false)
        }

        if (value.length > 0 && !btnClear) {
            setBtnClear(true)
        }
    }

    const handleKey = (evt) => {
        if (evt.key === CONTROL_SEARCH.KEY_ENTER && valueChange !== '') {
            onSearchClick(valueChange)
        }
    }

    return (
        <TextField
            className={classes.root}
            placeholder={waterMark}
            variant='outlined'
            onChange={({ target: { value } }) => { handleChangeValue(value) }}
            onKeyPress={handleKey}
            value={valueChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {btnClear &&
                            <IconButton onClick={() => handleChangeValue('')} className={classes.btnClear}>
                                <ClearIcon />
                            </IconButton>
                        }
                        <Button
                            label={CONTROL_SEARCH.DEFAULT_WATERMARK}
                            className={classes.btnStyle}
                            onClick={() => onSearchClick(valueChange)}
                        />
                    </InputAdornment>
                ),
            }}
        />
    )
}

Search.defaultProps = {
    waterMark: CONTROL_SEARCH.DEFAULT_WATERMARK,
    resetButton: false
}

const styles = theme => ({
    root: {
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.1) 0px -1px 0px',
        backgroundColor: '#FFFFFF',
        '& input': {
            // padding: 0,
            padding: '13px'
        }
    },
    btnStyle: {
        height: '40px',
        width: '150px',
        borderRadius: '10px',
        fontSize: '18px !important',
        [theme.breakpoints.down('xs')]: {
            width: '80px'
        }
    },
    btnClear: {
        padding: '4px',
        marginRight: '5px',
    }
})

export default withStyles(styles)(Search)