import React, {  useContext } from 'react';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants';
import { OrgLeaveContext } from '../OrgLeaveProvider'

import PanelView from '../../../presentational/orgLeave/panel/PanelView';
import DetailOrg from './DetailOrg';
import EditOrg from './EditOrg';
import EditEmp from './EditEmp';

const Panel = ({
    dirtyEditOrg,
    dirtyEditEmp,
}) => {
    const {
        panelState,
        slinksState,
        alertMessageState
    } = useContext(OrgLeaveContext)

    const setBackToPage = () => {
        if (panelState.panelActive === 'EDIT_EMP') {
            panelState.setPanel('DETAIL_ORG', panelState.dataDetailOrg)
        } else {
            panelState.setPanel()
        }
        slinksState.clearSlink()
    }

    const handleBack = () => {
        if (dirtyEditOrg || dirtyEditEmp) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.CONFIRM_CANCEL_EDIT,
                altType: 2,
                btnConfirmName: "ยกเลิก",
                btnCancelName: "ไม่ยกเลิก",
                callbackConfirm: () => {
                    alertMessageState.hideAlert()
                    setBackToPage();
                },
            })
        } else {
            setBackToPage();
        }
    }

    const renderChildren = () => {
        switch (panelState.panelActive) {
            case 'DETAIL_ORG':
                return <DetailOrg />
            case 'EDIT_ORG':
                return <EditOrg panelState={panelState} />
            case 'EDIT_EMP':
                return <EditEmp />
            default:
                break;
        }
    }

    const renderTitleText = () => {
        switch (panelState.panelActive) {
            case 'EDIT_ORG':
                return "แก้ไขข้อมูลหน่วยงาน"
            case 'DETAIL_ORG':
                return panelState.dataDetailOrg && panelState.dataDetailOrg.name
            case 'EDIT_EMP':
                return "แก้ไขข้อมูลพนักงาน"
            default:
                return "";
        }
    }

    return (
        <PanelView
            title={renderTitleText()}
            panelActive={panelState.panelActive}
            onClickBack={handleBack}
        >
            {renderChildren()}
        </PanelView>
    )
}


const mapStateToProps = state => ({
    dirtyEditOrg: isDirty('OrgLeaveEditOrg')(state),
    dirtyEditEmp: isDirty('OrgLeaveEditEmp')(state)
})

export default connect(mapStateToProps, null)(Panel)
