import React, { createContext } from 'react';

import UseLoadingState from '../../../../control/loading/UseLoadingState';
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';
import UseBookingByDateState from './useBookingByDateState/UseBookingByDateState';

export const BookingByDateContext = createContext();

const BookingByDateProvider = ({ children }) => {
    const loadingState = UseLoadingState();
    const alertMessageState = UseAlertMessageState();
    const bookingState = UseBookingByDateState({ loadingState: loadingState });

    const value = {
        loadingState,
        alertMessageState,

        bookingState
    };

    return (
        <BookingByDateContext.Provider value={value}>
            {children}
        </BookingByDateContext.Provider>
    );
};

export default BookingByDateProvider;
