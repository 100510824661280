import React, { Fragment, useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CancelBookingContext } from '../CancelBookingProvider';
import DetailBookingList from '../../booking/bookingList/DetailBookingList';
import { formatResult } from '../DataCancelBookingManagement';
import CancelBookingPageView from '../../../presentational/cancelBooking/CancelBookingPageView'
import CancelBookingSearch from './CancelBookingSearch'
import CancelBookingResult from './CancelBookingResult'
import Loading from '../../../../../control/loading/Loading'
import AlertMessage from '../../../../../control/alert/AlertMessage'
import ExportCancelBooking from '../ExportCancelBooking'

const CancelBookingPage = props => {
    const { profile } = props;

    const [openDetail, setOpenDetail] = useState(false);
    const [data, setData] = useState(null);
    const [checkData, setCheckData] = useState([]);
    // const [disabledCheck, setDisabledCheck] = useState(false);
    // const [openCancelPage, setOpenCancelPage] = useState(false);
    // const [allData, setAllData] = useState([]);
    // const [selectAll, setSelectAll] = useState(false);
    const {
        cancelBookingState,
        dataTableCancelBookingState,
        loadingState,
        alertMessageState
    } = useContext(CancelBookingContext)

    useEffect(() => {
        if (cancelBookingState.resultSearch) {
            setCheckData([]);
            const data = formatResult(
                cancelBookingState.resultSearch,
                profile.empId,
                cancelBookingState.valueSearch
            );
            // const dataForExport = formatResultForExport(
            //     cancelBookingState.resultSearch,
            //     profile.empId,
            //     cancelBookingState.valueSearch
            // )
            // setAllData(dataForExport);

            // const isCanCheck = data.filter(item => item.checkbox).length > 0;
            // setDisabledCheck(!isCanCheck);
            dataTableCancelBookingState.setInitialDataTable(data);
        }
    }, [cancelBookingState.resultSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    //----------------- table click action for open detail booking ------------------
    const handleRowClick = values => {
        setOpenDetail(true);
        setData(values);
    };

    //---------------- set data detail for use detail booking and action state open detail booking ---------
    const handleClose = () => {
        setOpenDetail(false);
        setData(null);
    };

    //---------------- when button search click --------------------
    const callbackSearch = strParams => {
        cancelBookingState.searchCancelBooking(strParams)
    };

    //-------------- export excel -----------------
    const handleExportExcel = () => {
        loadingState.showLoading();
        ExportCancelBooking(dataTableCancelBookingState.dataTable);
        loadingState.hideLoading();
    };

    return (
        <Fragment>
            <CancelBookingPageView>

                <CancelBookingSearch callbackSearch={callbackSearch} />

                <CancelBookingResult
                    dataTableState={dataTableCancelBookingState}
                    onRowClick={handleRowClick}
                    dataCheck={checkData}
                    onExportClick={handleExportExcel}
                // disabledCheck={disabledCheck}
                />

                {openDetail && (
                    <DetailBookingList isCancelled={true}
                        onClickClose={handleClose} data={data} />
                )}


            </CancelBookingPageView>
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />

        </Fragment>
    );
};

const mapStateToProps = state => ({
    profile: state.authen.profile
});

export default connect(mapStateToProps, null)(CancelBookingPage);
