import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/th';
import classnames from 'classnames'

import './Calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { makeStyles } from '@material-ui/core/styles';
import CustomToolBar from './CustomToolbar';
import { convertDateDisplay, formatDate } from '../../utilities/DatetimeUtils';
import CustomAgenda from './CustomAgenda/CustomAgenda';
import { sortBy } from 'lodash';

moment.locale('th');
const localizer = momentLocalizer(moment);

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // height: '700pt'
        height: '100%'
    },
    rootCalendar: {
        height: '690pt',
        marginBottom: '24px'
    },
    circle: {
        height: '12px',
        width: '12px',
        // backgroundColor: '#bbb',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '5px'
    },
    spanLabel: {
        color: theme.mainTheme.color1
    },
    spanColor: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    pTag: {
        marginBottom: '0px'
    }
}));

const CalendarControl = props => {
    const {
        events,
        currentDateObj,
        onChangeMonth,
        onClickSearchMeetingRoom,
        agendaEventData
    } = props;

    const classes = useStyles();

    //==== State ====//
    // const [date, setDate] = useState(moment().year('year').month('month').date(1).toDate())
    const initDate = currentDateObj ? currentDateObj : moment().year('year').month('month').date(1).toDate()
    const [date, setDate] = useState(initDate)
    const [agendaLength, setAgendaLength] = useState(30); //วันที่สิ้นสุดที่จะให้หน้า agenda แสดงรายการ (นับจากวันที่ของ props date)
    const [currentView, setCurrentView] = useState('month'); //set view ที่จะให้แสดง
    const [isShowButtonNextPrev, setIsShowButtonNextPrev] = useState(true); // flag show/hide left-right icon in toolbar
    const [eventData, setEventData] = useState([]); //data for display in calendar
    const [isEventAgendaView, setIsEventAgendaView] = useState(false); // flag สำหรับบอกว่าเป็นการเข้าหน้า agenda จากการคลิ๊กที่ event

    //==== Ref ====//
    // const currentCalendarDateObj = useRef(new Date()); //วันปัจจุบันที่เลือก
    const currentCalendarDateObj = useRef(new Date(initDate)); //วันปัจจุบันที่เลือก
    const eventDataRef = useRef([]); //เก็บ event ทั้งหมดเป็นตัวแปร ref ไว้
    const selectedEventRef = useRef({}); //เก็บ event ที่ถูกคลิ๊กจากบนปฏิธิน

    useEffect(() => {
        let sortEventData = sortBy(events, ['startTime', 'endTime']);
        eventDataRef.current = sortEventData;
        getTotalEventCurrentMonth(currentDateObj ? currentDateObj : new Date());

        if (currentView === 'agenda') {
            setEventData(
                sortBy(agendaEventData, [
                    'startTime',
                    'endTime',
                    'meaDistrictNameTH',
                    'building',
                    'floor',
                    'roomNameTH'
                ])
            );
        } else {
            setEventData(sortEventData);
        }
    }, [events, agendaEventData]); // eslint-disable-line react-hooks/exhaustive-deps

    const event = ({ event }) => {
        return (
            <>
                <span
                    className={classes.circle}
                    style={{
                        backgroundColor: event.circleColor
                            ? event.circleColor
                            : '#ddd'
                    }}
                ></span>
                <span>
                    ( {event.startTime} - {event.endTime} ) {event.title}
                    {event.desc && ':  ' + event.desc}
                </span>
            </>
        );
    };

    const eventAgenda = ({ event }) => {
        return (
            <span>
                {/* //////////////////// Test Sort /////////////////////// */}
                {/* <p className={classes.pTag}>
                    <span className={classes.spanLabel}>ID: </span>

                    <span> {event.id}</span>
                </p> */}
                {/* <p className={classes.pTag}>
                    <span className={classes.spanLabel}>
                        meaDistrictNameTH:
                    </span>
                    {event.meaDistrictNameTH ? event.meaDistrictNameTH : '-'}
                </p>
                <p className={classes.pTag}>
                    <span className={classes.spanLabel}>building: </span>
                    {event.building ? event.building : '-'}
                </p>

                <p className={classes.pTag}>
                    <span className={classes.spanLabel}>floor: </span>
                    {event.floor ? event.floor : '-'}
                </p>
                <p className={classes.pTag}>
                    <span className={classes.spanLabel}>roomNameTH: </span>
                    {event.roomNameTH ? event.roomNameTH : '-'}
                </p> */}

                {/* //////////////////////// */}

                <p className={classes.pTag}>
                    <span className={classes.spanLabel}>ห้องประชุม: </span>
                    <span
                        className={classes.spanColor}
                        style={{ backgroundColor: event.circleColor }}
                    />
                    <span> {event.roomNameTH} </span>
                    <span>
                        {event.floorDisplay ? ` ชั้น ${event.floorDisplay}` : ''}
                    </span>
                    <span>
                        {event.building ? ` อาคาร${event.building}` : ''}
                    </span>
                </p>

                <p className={classes.pTag}>
                    <span className={classes.spanLabel}>หัวข้อการประชุม: </span>
                    {event.topic ? event.topic : '-'}
                </p>
                <p className={classes.pTag}>
                    <span className={classes.spanLabel}>ประธาน: </span>
                    {event.chairman ? event.chairman : '-'}
                </p>
                <p className={classes.pTag}>
                    <span className={classes.spanLabel}>จำนวนผู้เข้าร่วมประชุม: </span>
                    {event.numberOfAttendees ? event.numberOfAttendees : '-'}
                </p>
                <p className={classes.pTag}>
                    <span className={classes.spanLabel}>ผู้จอง: </span>
                    {event.emp} {event.empOrgNameTH} {event.internalTel}
                </p>
            </span>
        );
    };

    const dateAgenda = ({ day, label }) => {
        const getDate = day.getDate();
        const getMonth = day.getMonth() + 1;
        const getYear = day.getFullYear();

        const displayDate = convertDateDisplay(
            `${getYear}-${getMonth}-${getDate}`
        );

        if (isEventAgendaView) {
            // const getEndDate = selectedEventRef.current.end.getDate();
            // const getEndMonth = selectedEventRef.current.end.getMonth() + 1;
            // const getEndYear = selectedEventRef.current.end.getFullYear();

            // const getEndDate = new Date(
            //     selectedEventRef.current.oldBookingEndTime
            // ).getDate();
            // const getEndMonth =
            //     new Date(
            //         selectedEventRef.current.oldBookingEndTime
            //     ).getMonth() + 1;
            // const getEndYear = new Date(
            //     selectedEventRef.current.oldBookingEndTime
            // ).getFullYear();

            // const displayEndDate = convertDateDisplay(
            //     `${getEndYear}-${getEndMonth}-${getEndDate}`
            // );
            return <span>{displayDate}</span>
            // if (displayEndDate === displayDate) {
            //     return (
            //         <span>
            //             <em>{displayDate}</em>
            //         </span>
            //     );
            // } else {
            //     return (
            //         <span>
            //             <em>
            //                 {displayDate} - {displayEndDate}
            //             </em>
            //         </span>
            //     );
            // }
        } else {
            return (
                <span>
                    <em>{displayDate}</em>
                </span>
            );
        }
    };

    const timeAgenda = ({ event, day }) => {
        const { startTime, endTime } = event;

        return (
            <span>
                {/* <p>{strTime}</p> */}
                <p>
                    {`${startTime} น.`}-{`${endTime} น.`}
                </p>
            </span>
        );
    };

    const customSlotPropGetter = date => {
        if (date.getDate() === 7 || date.getDate() === 15)
            return {
                className: 'special-day'
            };
        else return {};
    };

    const eventPropGetter = (event, start, end, isSelected) => {
        return {
            className: 'special-day',
            style: {
                color: event.color ? event.color : '#000000'
            }
        };
    };
    const handleEventClick = (event, e) => {
        // console.log("event:::", event)
        // console.log("agendaEventData", agendaEventData)
        ///////////////// filter event form agendaData //////////
        const filterData = agendaEventData.filter(item => {
            return item.id === event.id;
        });
        // console.log("filterData::", filterData)

        let lengthCalendar = (new Date(filterData[filterData.length - 1].start) - new Date(filterData[0].start)) / (1000 * 3600 * 24)
        // console.log("lengthCalendar:::", lengthCalendar)
        /////////////// find startdate - enddate ///////////////
        // let eventForDisplayAgenda = {};
        // filterData.forEach((item, idx) => {
        //     if (idx === 0) {
        //         eventForDisplayAgenda = item;
        //         eventForDisplayAgenda.oldBookingStartTime = formatDate(
        //             item.start
        //         );
        //     }
        //     if (idx === filterData.length - 1) {
        //         eventForDisplayAgenda.oldBookingEndTime = formatDate(item.end);
        //     }
        // });
        filterData.forEach((item, idx) => {
            item.oldBookingStartTime = formatDate(
                item.start
            );
            item.oldBookingEndTime = formatDate(item.end);

        });
        ////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////
        // console.log("filterData::", filterData)
        ////////////// new //////////////////////////
        selectedEventRef.current = filterData[0];
        setIsEventAgendaView(true);
        setEventData(filterData);
        setDate(
            new Date(
                filterData[0].start.getFullYear(),
                filterData[0].start.getMonth(),
                filterData[0].start.getDate()
            )
        );
        /////////////////////////////////////////////

        ////////////// old //////////////////////////
        // selectedEventRef.current = event;
        // setIsEventAgendaView(true);
        // setEventData([event]);
        // setDate(
        //     new Date(
        //         event.start.getFullYear(),
        //         event.start.getMonth(),
        //         event.start.getDate()
        //     )
        // );
        /////////////////////////////////////////////

        setAgendaLength(lengthCalendar);
        setIsShowButtonNextPrev(false);
        setCurrentView('agenda');
    };
    const handleOnview = currentView => {
        setCurrentView(currentView);
    };

    const handleChangeMonth = currentDate => {
        //มีการไป call api เพื่อเอา event ใหม่ทำให้ทุกครั้งที่กดเปลี่ยนเดือนจะเข้า useeffect ใหม่และจะมีการ set event ใหม่ที่ useEffect***
        currentCalendarDateObj.current = currentDate;
        setDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
        // getTotalEventCurrentMonth(currentDate);
        if (onChangeMonth) {
            onChangeMonth(currentDate);
        }
    };

    const handleClickAgenda = (month, year) => {
        if (currentView !== 'agenda') {
            setDate(new Date(year, month, 1));
            getTotalEventCurrentMonth(new Date(year, month, 1));
            //==== set agenda event ====//
            setEventData(
                sortBy(agendaEventData, [
                    'startTime',
                    'endTime',
                    'meaDistrictNameTH',
                    'building',
                    'floor',
                    'roomNameTH'
                ])
            );
            //=========================//
        }
    };
    const handleDrillDownClick = (selectDate, view) => {
        //click showmore,click day in calendar

        //==== set agenda event ====//
        setEventData(
            sortBy(agendaEventData, [
                'startTime',
                'endTime',
                'meaDistrictNameTH',
                'building',
                'floor',
                'roomNameTH'
            ])
        );
        //=========================//

        setCurrentView(view);
        setDate(
            new Date(
                selectDate.getFullYear(),
                selectDate.getMonth(),
                selectDate.getDate()
            )
        );
        setAgendaLength(0);
        setIsShowButtonNextPrev(false);
    };
    const handleClickMonth = () => {
        if (currentView !== 'month') {
            setEventData(eventDataRef.current);
            setDate(
                new Date(
                    currentCalendarDateObj.current.getFullYear(),
                    currentCalendarDateObj.current.getMonth(),
                    1
                )
            );
            setIsEventAgendaView(false);
        }

        setIsShowButtonNextPrev(true);
    };

    const getTotalEventCurrentMonth = currentDate => {
        let allEvent = [];
        events.forEach(event => {
            event.allEventDateList.forEach(eventDate => {
                if (eventDate.getMonth() === currentDate.getMonth()) {
                    allEvent.push(eventDate.getDate());
                }
            });
        });

        //remove duplicate value
        allEvent = allEvent.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) === index;
        });
        if (allEvent.length > 0) {
            setAgendaLength(Math.max(...allEvent) - 1);
        }
    };
    const handleClickDateBox = (slotInfo, view = "agenda") => {
        let selectDate = new Date()
        if (slotInfo.slots && slotInfo.slots.length > 0) {
            selectDate = slotInfo.slots[0]
        }
        // console.log("slotInfo", slotInfo)
        // console.log("selectDate", selectDate)
        // console.log("view", view)

        //==== set agenda event ====//
        setEventData(
            sortBy(agendaEventData, [
                'startTime',
                'endTime',
                'meaDistrictNameTH',
                'building',
                'floor',
                'roomNameTH'
            ])
        );
        //=========================//

        setCurrentView(view);
        setDate(
            new Date(
                selectDate.getFullYear(),
                selectDate.getMonth(),
                selectDate.getDate()
            )
        );
        setAgendaLength(0);
        setIsShowButtonNextPrev(false);

    };

    return (
        <div className={classnames(classes.root, { [classes.rootCalendar]: currentView === 'month' })}>
            <Calendar
                events={eventData}
                startAccessor="start"
                endAccessor="end"
                defaultDate={moment().toDate()}
                date={date}
                localizer={localizer}
                // views={['month', 'agenda']}
                views={{
                    month: true,
                    agenda: CustomAgenda
                }}
                length={agendaLength} //set date range of agenda
                onDrillDown={handleDrillDownClick} //click showmore,click day in calendar
                selectable={true}
                onSelectSlot={handleClickDateBox}
                // getDrilldownView={(
                //     targetDate,
                //     currentViewName,
                //     configuredViewNames
                // ) =>
                //     handleGetDrilldownView(
                //         targetDate,
                //         'agenda',
                //         configuredViewNames
                //     )
                // }
                onSelectEvent={handleEventClick}
                drilldownView="agenda"
                view={currentView}
                onView={handleOnview}
                slotPropGetter={customSlotPropGetter}
                eventPropGetter={eventPropGetter}
                min={4}
                culture={'th-TH'}
                components={{
                    event: event,
                    agenda: {
                        event: eventAgenda,
                        date: dateAgenda,
                        time: timeAgenda
                    },
                    toolbar: props => {
                        props.localizer.formats.eventTimeRangeStartFormat = data => { };
                        props.localizer.messages.noEventsInRange =
                            'ไม่พบข้อมูล';
                        props.localizer.messages.date = 'วัน';
                        props.localizer.messages.time =
                            'เวลาเริ่มต้น - สิ้นสุด';
                        props.localizer.messages.event = 'เหตุการณ์';
                        props.localizer.messages.showMore = count => {
                            if (count) {
                                return `+${count} เพิ่มเติม`;
                            }
                        };
                        return (
                            <CustomToolBar
                                currentView={currentView}
                                onChangeMonth={handleChangeMonth}
                                onClickSearchMeetingRoom={
                                    onClickSearchMeetingRoom
                                }
                                toolbar={props}
                                onClickAgenda={handleClickAgenda}
                                onClickMonth={handleClickMonth}
                                isShowButtonNextPrev={isShowButtonNextPrev}
                            />
                        );
                    }
                }}
            />
        </div>
    );
};

export default CalendarControl;
