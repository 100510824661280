import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '../../../../control/button/Button';
import DataTable from '../../../../control/table/DataTable';
import * as CancelBookingConstants from '../../../../constants/meeting/CancelBookingConstants';
const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '33px'
    },
    summary: {
        textAlign: 'end',
        alignSelf: 'flex-end'
    },
    btnCancel: {
        // marginLeft: '10px',
        backgroundColor: `${theme.mainTheme.color3} !important`,
        marginRight: '10px',
        marginBottom: '5px'
    },
    btnUnCancel: {
        // marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '5px'
    },
    unCheckbox: {
        ['& svg']: {
            width: '20px',
            height: '20px'
        }
    },
    checkbox: {
        color: `${theme.mainTheme.color3} !important`,
        ['& svg']: {
            width: '20px',
            height: '20px'
        }
    },
    formControl: {
        marginBottom: '-15px',
        ['& span']: {
            fontSize: '20px !important'
        }
    },
    btnExport: {
        marginBottom: '5px',
        backgroundColor: `${theme.mainTheme.color4} !important`,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    spanClass: {
        marginRight: '10px',
        verticalAlign: 'bottom',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    tableCell: {
        minHeight: '48px',
        height: 'auto',
        display: 'inline-flex',
        padding: '10px',
        color: '#5B5B5B',
        fontSize: '18px !important',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        alignItems: 'center',
        background: 'inherit'
    },
}));

const CancelBookingResultView = props => {
    const classes = useStyles();
    const {
        dataTableState,
        onRowClick,
        dataCheck,
        onExportClick
        // disabledCheck
    } = props;

    return (
        <Grid container>
            <Grid container style={{ marginBottom: '5px' }}>
                <Grid item xs={6} md={6}>
                    <span className={classes.title}>ผลลัพธ์การค้นหา</span>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                   
                </Grid> */}
                <Grid item xs={6} md={6} className={classes.summary}>
                    <span className={classes.spanClass}>
                        {`พบผลลัพธ์ทั้งหมด ${dataTableState.dataTable.length} รายการ`}
                    </span>

                    <Button
                        label={'ออกรายงานการยกเลิกการจองห้องประชุม'}
                        className={classes.btnExport}
                        onClick={onExportClick}
                    />
                </Grid>
            </Grid>

            <DataTable
                column={CancelBookingConstants.COLUMN_CANCEL_BOOKING}
                data={dataTableState.dataPage}
                page={dataTableState.page}
                rowPerPage={dataTableState.rowPerPage}
                total={dataTableState.total}
                sortColumn={dataTableState.sortColumn}
                sortType={dataTableState.sortType}
                handlePageChange={dataTableState.changePage}
                handleRowClick={onRowClick}
                dataCheck={dataCheck}
                handleSortExternal={dataTableState.sortData}
                classes={{ tableCell: classes.tableCell }}
                handleRowPerPage={() => { }}
            // disabledCheck={disabledCheck}
            />
        </Grid>
    );
};

export default CancelBookingResultView;
