
export const API = {
    CONNEXT: 'CONNEXT',
    LEAVE: 'LEAVE',
    APD: 'APD',
    MEETING: 'MEETING',
    SMO: 'SMO'
}

export const STATUS = {
    503: {
        [API.CONNEXT]: 'ระบบ Connext อยู่ระหว่างการปิดปรับปรุงระบบ',
        [API.LEAVE]: 'ระบบ Leave อยู่ระหว่างการปิดปรับปรุงระบบ',
        [API.APD]: 'ระบบ APD อยู่ระหว่างการปิดปรับปรุงระบบ',
        [API.MEETING]: 'ระบบ Meeting Room อยู่ระหว่างการปิดปรับปรุงระบบ',
        [API.SMO]: 'ระบบ Smart Office อยู่ระหว่างการปิดปรับปรุงระบบ',
    }
}