import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Field } from 'redux-form'
import classnames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete';

import { UM_USER_GROUP, PERMISSION_ORG } from '../../../../constants/APDConstants'

import Button from '../../../../control/button/Button'
import AutoComplete from '../../../../control/autocomplete/AutoComplete'

const { NAME, LABEL } = UM_USER_GROUP.FORM_USER_GROUP

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '33px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        height: '90%',
        maxHeight: '625px',
        width: '60%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%'
        }
    },
    rootPersonal: {
        maxHeight: '535px',
    },
    rootMadal: {
        width: '50%',
        [theme.breakpoints.up('lg')]: {
            height: 'calc(100% - 290px)',
        },
        [theme.breakpoints.down('lg')]: {
            height: 'calc(100% - 150px)',
        },
        [theme.breakpoints.down('md')]: {
            height: 'calc(100% - 750px)',
            width: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            alignContent: 'flex-start'
        }
    },
    children: {
        height: '100%'
    },
    containerEmp: {
        height: '227px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 7px 0px',
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    label: {
        color: '#F47B15'
    },
    deleteIcon: {
        fontSize: '22px'
    },
    list: {
        padding: '0px',
        fontSize: '20px'
    },
    footer: {
        marginTop: '24px',
        padding: 24,
        paddingTop: 0
    },
    primaryListText: {
        fontSize: '20px'
    },
    paddingT: {
        padding: 24,
        paddingBottom: 0
    },
    paddingM: {
        paddingRight: 24,
        paddingLeft: 24,
    },
    paddingB: {
        padding: 24,
        paddingTop: 0
    }
}))

const ListEmp = ({ classes, emp, index, onDelete }) => (
    <ListItem divider >
        <ListItemText
            classes={{ primary: classes.primaryListText }}
            primary={`${emp.empName} (${emp.empId})`}
        />
        {!emp.notDelete &&
            <ListItemSecondaryAction>
                <IconButton edge="end" onClick={onDelete(index)}>
                    <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
            </ListItemSecondaryAction>
        }
    </ListItem>
)

const FormUserView = ({
    isOpen,
    listEmp = [],
    lutEmp = [],
    lutOrg = [],
    permissionOrgId,

    onChangeEmp,
    onChangeOrg,
    onDelete,
    onClose
}) => {
    const classes = useStyles();
    const isPersonalOrg = permissionOrgId === PERMISSION_ORG.PERSONAL_ORG

    return (
        <Modal
            open={isOpen}
            className={classes.modal}
            closeAfterTransition
            BackdropProps={{
                timeout: 500
            }}
        >
            <Grid container className={classnames(classes.root, { [classes.rootPersonal]: isPersonalOrg })}>
                <Grid item xs={12} className={classes.paddingT}>
                    <span className={classes.title}>จัดการผู้ใช้งาน</span>
                </Grid>
                <Grid item xs={12} style={{ height: 'calc(100% - 190px)' }}>
                    <Scrollbars universal={true}>
                        {!isPersonalOrg && <Grid item xs={12} className={classes.paddingM}>
                            <Field
                                name={NAME.ORG}
                                label={LABEL.ORG}
                                component={AutoComplete}
                                options={lutOrg}
                                onChangeDropDown={onChangeOrg}
                                optionKey={{ label: "name", value: "orgId", abbr: "nameSht" }}
                            />
                        </Grid>}
                        <Grid item xs={12} className={classes.paddingM}>
                            <Field
                                id="search-emp"
                                isShowNoSelect={false}
                                name={NAME.SEARCH_EMP}
                                label={LABEL.SEARCH_EMP}
                                component={AutoComplete}
                                options={lutEmp}
                                onChangeDropDown={onChangeEmp}
                                optionKey={{ label: "empName", value: "empId", abbr: "empId" }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.paddingM}>
                            <span className={classes.label}>{LABEL.LIST_USER}</span>
                            <Grid item xs={12} className={classes.containerEmp}>
                                <Scrollbars universal={true}>
                                    <List className={classes.list}>
                                        {listEmp.map((emp, index) => <ListEmp
                                            key={`listemp-${emp.empId}`}
                                            classes={classes}
                                            emp={emp}
                                            index={index}
                                            onDelete={onDelete}
                                        />)}
                                    </List>
                                </Scrollbars>
                            </Grid>
                        </Grid>
                    </Scrollbars>
                </Grid>
                <Grid item xs={12} container spacing={2} justify="center" className={classes.footer}>
                    <Grid item >
                        <Button label="ตกลง" onClick={onClose} />
                    </Grid>
                </Grid>
            </Grid>
        </Modal >
    )
}

export default FormUserView