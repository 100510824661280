import React from 'react';
import { Field } from 'redux-form';
import { Scrollbars } from 'react-custom-scrollbars';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { ORG_LEAVE } from '../../../../../constants/APDConstants';

import TextFields from '../../../../../control/textfield/TextFields';
import Autocomplete from '../../../../../control/autocomplete/AutoComplete'
import Button from '../../../../../control/button/Button';
import CheckboxOne from '../../../../../control/checkbox/Checkbox'

const useStyle = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    body: {
        padding: theme.spacing(3)
    },
    form: {
        // marginTop: theme.spacing(2)
    },
    action: {
        marginTop: theme.spacing(1) / 2
    },
    button: {
        width: '100px'
    },
    dividerTop: {
        width: '100%',
        marginBottom: '10px',
        marginTop: '10px'
    },
    dividerBottom: {
        width: '100%',
        marginTop: '7px'
    },
    title: {
        color: '#F47B15'
    },
    permission: {
        border: '1px solid #bdbdbd',
        boxShadow: theme.field.boxShadow,
        padding: '5px',
        borderRadius: '4px',
        minHeight: theme.field.height
    },
    img: {
        width: '100%',
    },
    imgContainer: {
        height: 'auto',
        width: '120px'
    },
    gridControl: {
        // height: '90px'
        minHeight: '90px'
    }
}))

const EditEmpView = ({
    img,
    lupEmp,
    lupOrg,
    isShowRepresent,
    isDisableOrg,
    isHaveRepresent,
    empApprove = [],
    permission = [],

    handleSubmit,
    onSubmit
}) => {
    const classes = useStyle();

    return (
        <Grid container className={classes.root}>
            <Scrollbars universal={true}>
                <Grid className={classes.body} >
                    <Grid container justify="center">
                        <Grid item className={classes.imgContainer}>
                            <img
                                src={img}
                                alt="img"
                                className={classes.img}
                                onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/img/profile/no_pic.png` }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container className={classes.form}>
                        <Grid item xs={12}>
                            <Field
                                name={ORG_LEAVE.FORM_EDIT_EMP.NAME.NAME}
                                component={TextFields}
                                label={ORG_LEAVE.FORM_EDIT_EMP.LABEL.NAME}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name={ORG_LEAVE.FORM_EDIT_EMP.NAME.ID}
                                component={TextFields}
                                label={ORG_LEAVE.FORM_EDIT_EMP.LABEL.ID}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name={ORG_LEAVE.FORM_EDIT_EMP.NAME.ORG}
                                component={Autocomplete}
                                label={ORG_LEAVE.FORM_EDIT_EMP.LABEL.ORG}
                                options={lupOrg}
                                disabled={isDisableOrg}
                                optionKey={{ label: "name", value: 'id', abbr: "shortName" }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name={ORG_LEAVE.FORM_EDIT_EMP.NAME.POSITION}
                                component={TextFields}
                                label={ORG_LEAVE.FORM_EDIT_EMP.LABEL.POSITION}
                                disabled={true}
                            />
                        </Grid>
                        {permission.length > 0 && <Grid item xs={12} className={classes.gridControl}>
                            <Grid container style={{ marginBottom: '10px' }}>
                                <Grid item xs={12} className={classes.title}>
                                    {ORG_LEAVE.FORM_EDIT_EMP.LABEL.PERMISSION}
                                </Grid>
                                <Grid item xs={12} className={classes.permission}>
                                    {permission.map((item, index) => {
                                        return (
                                            <Grid item xs={4} key={`permission-img-${index}`}>
                                                {item.img}{" "}{item.name}
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>}
                        {isShowRepresent && <Grid item xs={12}>
                            <Field
                                name={ORG_LEAVE.FORM_EDIT_EMP.NAME.IS_HAVE_REPRESENT}
                                component={CheckboxOne}
                                label={ORG_LEAVE.FORM_EDIT_EMP.LABEL.IS_HAVE_REPRESENT}
                            />
                        </Grid>}
                        {isShowRepresent && <Grid item xs={12}>
                            <Field
                                name={ORG_LEAVE.FORM_EDIT_EMP.NAME.REPRESENT}
                                component={Autocomplete}
                                optionKey={{ label: "empName", value: "empId", abbr: "empId" }}
                                label={ORG_LEAVE.FORM_EDIT_EMP.LABEL.REPRESENT}
                                options={lupEmp}
                                disabled={!isHaveRepresent}
                                required={isHaveRepresent}
                            />
                        </Grid>}
                        {empApprove.length > 0 && <Grid container>
                            <Divider className={classes.dividerTop} />
                            <Grid item xs={3} className={classes.title}>
                                {ORG_LEAVE.FORM_EDIT_EMP.REPRESENT_TITLE}
                            </Grid>
                            <Grid item xs={9}>
                                {empApprove.map((empAppr, index) => {
                                    return <p key={`represent-${index}`} style={{ marginBottom: '0px' }}>{empAppr}</p>
                                })}
                            </Grid>
                            <Divider className={classes.dividerBottom} />
                        </Grid>}
                    </Grid>

                    <Grid container className={classes.action} justify="center" >
                        <Grid item>
                            <Button className={classes.button} onClick={handleSubmit(onSubmit)} label="ตกลง" />
                        </Grid>
                    </Grid>

                </Grid>
            </Scrollbars>
        </Grid>
    )
}

export default EditEmpView
