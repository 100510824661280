import React, { Fragment, useContext, useEffect } from 'react'

import Loading from '../../../../control/loading/Loading'
import AlertMessage from '../../../../control/alert/AlertMessage'

import * as ApiForceUpdate from '../../../../api/ApiForceUpdate'

import { ForceUpdateContext } from './ForceUpdateProvider'

import ForceUpdateTable from './form/ForceUpdateTable'
import ForceUpdateEdit from './form/ForceUpdateEdit'

import { checkResStatusService } from '../../../../utilities/ResServiceUtils'
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'

const ForceUpdatePage = () => {
    const { dataTableForceUpdateState, forceUpdaeState, alertMessageState, loadingState } = useContext(ForceUpdateContext)

    useEffect(() => {
        getAppVersionTable()
    }, [])

    const getAppVersionTable = () => {
        loadingState.showLoading()
        ApiForceUpdate.getAppVersion()
            .then(res => {
                loadingState.hideLoading()
                let msg = checkResStatusService(res)
                if (res && res.data) {
                    const formatForTable = formatTable(res.data)
                    dataTableForceUpdateState.setInitialDataTable(formatForTable)
                } else {
                    alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
                }
            })
    }

    const formatTable = (data) => {
        const newFormat = data.map((item, index) => {
            item.no = index + 1
            item.defaultPlatform = item.platform
            item.platform = item.platform.replace('MinVersion', '')
            item.edit = true

            return item
        })

        return newFormat
    }

    const handleUpdateAppVersion = (values) => {
        loadingState.showLoading()
        const params = {
            platform: values.defaultPlatform,
            version: values.version
        }
        ApiForceUpdate.updateAppVersion(params)
            .then(res => {
                loadingState.hideLoading()
                let msg = checkResStatusService(res)

                if (res && res.data && res.data[0].msg.toLowerCase() === 'success') {
                    alertMessageState.showAlert({
                        altMsg: AlertMSGConstants.SAVE_SUCCESS,
                        callbackCancel: () => {
                            alertMessageState.hideAlert()
                            handleCloseForm()
                            getAppVersionTable()
                        }
                    })
                } else {
                    alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
                }
            })
    }

    const handleCloseForm = () => {
        forceUpdaeState.setEditData(null)
        forceUpdaeState.setEditPage(false)
    }

    return (
        <Fragment>
            <ForceUpdateTable />
            {forceUpdaeState.editPage &&
                <ForceUpdateEdit
                    handleUpdateAppVersion={handleUpdateAppVersion}
                    handleCloseForm={handleCloseForm}
                />
            }
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    )
}

export default ForceUpdatePage