import React, { Fragment } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { Field } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { ABSENCE } from '../../../../constants/APDConstants';

import Button from '../../../../control/button/Button';
import Period from '../../../../control/period/Period';
import FormTimeAbsenceView from './form/FormTimeAbsenceView';
import FormByLeaveTypeView from './form/FormByLeaveTypeView';
import FieldTextDisplayView from './form/FieldTextDisplayView';
import { convertDateDisplay } from '../../container/leavesap/LeaveSAPUtils';

import Info from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

const { NAME, LABEL } = ABSENCE.FORM_EDIT_ABSENCE;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '24px 0px 24px 24px',
        width: '100%',
        height: '100%',
    },
    title: {
        fontSize: '33px',
    },
    btnCancel: {
        backgroundColor: '#808080 !important',
        marginLeft: '1%',
        marginRight: '1%',
        width: '15%',
    },
    btnClose: {
        marginLeft: '1%',
        marginRight: '1%',
        width: '15%',
    },
    body: {
        height: 'calc(100% - 108px)',
        width: '100%',
        wordBreak: 'break-all',
    },
    paddingRight: {
        paddingRight: '24px',
        marginLeft: '-8px',
    },
    footer: {
        marginTop: theme.spacing(1),
        paddingRight: '24px',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% + 24px)',
            paddingRight: '0px',
            marginLeft: '-24px',
        },
    },
    btnCancelAbsence: {
        color: '#FFFFFF',
        backgroundColor: `${theme.mainTheme.color3} !important`,
        width: '20%',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    period: {
        padding: theme.spacing(1),
    },
    canceledMsg: {
        color: theme.mainTheme.color3,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    btnSubmit: {
        width: '15%',
    },
    isSpecial: {
        color: theme.mainTheme.color5,
        padding: '8px',
    },
    iconInfo: {
        width: '20px',
        height: '20px',
        position: 'relative',
        top: '4px',
        cursor: 'pointer',
        color: theme.mainTheme.color6,
    },
    tagP: {
        marginBottom: '0px',
    },
    tooltipPlacementRight: {
        fontSize: '20px',
    },
}));

const EditAbsenceView = ({
    initailData,
    leavePeriodId,
    leaveTypeId,
    isCancel,
    isShowCancel,
    info = { listSpecialLeaveName: [], leaveTypeName: '' },

    onClose,
    onSubmit,
    onClickFile,
    onCancelAbsence,
}) => {
    const classes = useStyles();
    const isDetailMode = initailData.type === 'detail';

    const isCancelProgress =
        ABSENCE.LEAVE_CANCEL_IN_PROGRESS.indexOf(initailData.leaveStateId) > -1;

    // console.log('isShowCancel', isShowCancel)
    // console.log('isCancel', isCancel)

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <span className={classes.title}>
                    {isDetailMode
                        ? 'รายละเอียดใบลา'
                        : 'แก้ไขข้อมูลรายละเอียดใบลา'}
                </span>
            </Grid>

            <Grid container className={classes.body}>
                <Scrollbars universal={true}>
                    <Grid container className={classes.paddingRight}>
                        {initailData.isSpecial && info && (
                            <Grid item xs={12}>
                                <span className={classes.isSpecial}>
                                    {'ใบลานี้ไม่เป็นไปตามข้อบังคับฯ'}
                                    {/* <Tooltip className={classes.tooltips} title={
                                        <Fragment>
                                            <p className={classes.tagP}>{`ประเภทการลา: ${info.leaveTypeName}`}</p>
                                            {`การลากรณีพิเศษ​: `}
                                            {info.listSpecialLeaveName.map(item => {
                                                return <p className={classes.tagP}>- {item}</p>
                                            })}
                                        </Fragment>
                                    } placement="right" classes={{ tooltipPlacementRight: classes.tooltipPlacementRight }}>
                                        <Info className={classes.iconInfo} />
                                    </Tooltip> */}
                                </span>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.ABSENCE_ID}
                                data={initailData.absenceId}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.LEAVE_TYPE_ID}
                                data={initailData.leaveTypeName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.EMP_NAME}
                                data={initailData.empName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.EMP_ID}
                                data={initailData.empId}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.DEP_NAME}
                                data={
                                    initailData.depName
                                        ? initailData.depName
                                        : !initailData.divName &&
                                          initailData.orgName
                                } // ดักกรณี สมส ที่มี div แต่ไม่มี dep
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.DIV_NAME}
                                data={initailData.divName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.SEC_NAME}
                                data={initailData.secName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.PART_NAME}
                                data={initailData.partName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.LEAVE_STATE_NAME}
                                data={initailData.leaveStateName}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.LEAVE_REQUEST_DATE} //วันที่ยื่นลา
                                data={convertDateDisplay(
                                    new Date(initailData.createDate)
                                )}
                            />
                        </Grid>

                        {initailData.referenceId && (
                            <Grid item xs={12} md={6}>
                                <FieldTextDisplayView
                                    label={LABEL.REFERENCE_ID} //เลขที่ใบลาจากระบบเก่า
                                    data={initailData.referenceId}
                                />
                            </Grid>
                        )}

                        {initailData.signRejectReason && (
                            <Grid item xs={12} md={6}>
                                <FieldTextDisplayView
                                    label={LABEL.LEAVE_RESULT}
                                    data={initailData.signRejectReason}
                                />
                            </Grid>
                        )}

                        {initailData.updateDateDisplay && (
                            <Grid item xs={12} md={6}>
                                <FieldTextDisplayView
                                    label={
                                        isCancelProgress
                                            ? LABEL.REJECT_DATE
                                            : LABEL.UPDATE_DATE
                                    } //วันที่ขอยกเลิก : วันที่แก้ไข
                                    data={initailData.updateDateDisplay}
                                />
                            </Grid>
                        )}

                        {initailData.updateBy && (
                            <Grid item xs={12} md={6}>
                                <FieldTextDisplayView
                                    label={
                                        isCancelProgress
                                            ? LABEL.REJECT_BY
                                            : LABEL.UPDATE_BY
                                    } //ผู้ยกเลิก : ผู้แก้ไข
                                    data={initailData.updateBy}
                                />
                            </Grid>
                        )}

                        {initailData.cancelBy && (
                            <Grid item xs={12} md={6}>
                                <FieldTextDisplayView
                                    label={LABEL.REJECT_WAY} //ยกเลิกจากระบบ
                                    data={initailData.cancelBy}
                                />
                            </Grid>
                        )}

                        {isCancelProgress && (
                            <Grid item xs={12} md={6}>
                                <FieldTextDisplayView
                                    label={LABEL.REJECT_REASON} //เหตุผลการยกเลิกใบลา
                                    data={
                                        initailData.rejectReason
                                            ? initailData.rejectReason
                                            : '-'
                                    }
                                />
                            </Grid>
                        )}

                        {initailData.syncSapResultMsg && (
                            <Grid item xs={12} md={6}>
                                <FieldTextDisplayView
                                    label={LABEL.SAP_MESSAGE}
                                    data={initailData.syncSapResultMsg}
                                />
                            </Grid>
                        )}
                        <Grid container className={classes.period}>
                            <Field
                                name={NAME.LEAVE_PERIOD_ID}
                                label={LABEL.LEAVE_PERIOD_ID}
                                component={Period}
                                disabled={true}
                            />
                        </Grid>
                        <FormTimeAbsenceView
                            initailData={initailData}
                            leavePeriodId={leavePeriodId}
                        />
                        <Grid item xs={12} md={6}>
                            <FieldTextDisplayView
                                label={LABEL.DURATION} //จำนวนวันลา
                                data={`${initailData.duration} วัน`}
                            />
                        </Grid>
                        <FormByLeaveTypeView
                            initailData={initailData}
                            leaveTypeId={leaveTypeId}
                            onClickFile={onClickFile}
                        />
                    </Grid>
                </Scrollbars>
            </Grid>

            <Grid
                container
                justify="center"
                spacing={2}
                className={classes.footer}
            >
                {isShowCancel && !isCancel && (
                    <Button
                        label="ยกเลิกใบลา"
                        onClick={onCancelAbsence}
                        className={classes.btnCancelAbsence}
                    />
                )}
                <Button
                    label={isCancel || isDetailMode ? 'ปิด' : 'ยกเลิก'}
                    onClick={onClose}
                    className={
                        isCancel || isDetailMode
                            ? classes.btnClose
                            : classes.btnCancel
                    }
                />
                {!isCancel && !isDetailMode && (
                    <Button
                        label="บันทึก"
                        onClick={onSubmit}
                        className={classes.btnSubmit}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default EditAbsenceView;
