import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { keyEnterSubmitForm } from '../../../../utilities/FormUtils';

import Autocomplete from '../../../../control/autocomplete/AutoComplete';
import TextField from '../../../../control/textfield/TextFields';
import Button from '../../../../control/button/Button';
import ErrorField from '../../../../control/errorField/ErrorField';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '33px',
    },
    btnGrid: {
        textAlign: 'end',
    },
    divider: {
        width: '100%',
        margin: '24px 0px',
    },
    spacing: {
        ...theme.form.spacing,
    },
    btnSearch: {
        width: '105px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 0,
            marginBottom: '5px',
            marginTop: '5px',
        },
    },
    btnReset: {
        backgroundColor: `${theme.mainTheme.color6} !important`,
        width: '105px',
        marginRight: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 0,
            marginBottom: '5px',
            marginTop: '5px',
        },
    },
    label: {
        color: theme.mainTheme.color1,
    },
    fieldError: {
        marginRight: theme.spacing(2),
    },
}));

const LeaveCollectSearchView = (props) => {
    const classes = useStyles();
    const {
        enableLutOrg,
        optionsYear,
        optionsResponse,
        optionsOrg,
        onSearchClick,
        onResetClick,
    } = props;

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={enableLutOrg ? 3 : 4}
                    className={classes.spacing}
                >
                    <Field
                        component={Autocomplete}
                        name="year"
                        label="ปีที่ทำรายการสะสม"
                        options={optionsYear}
                        optionKey={{ label: 'name', value: 'id', abbr: null }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={enableLutOrg ? 3 : 4}
                    className={classes.spacing}
                >
                    <Field
                        component={Autocomplete}
                        name="result"
                        label="สถานะการส่ง SAP"
                        options={optionsResponse}
                        optionKey={{ label: 'name', value: 'id', abbr: null }}
                    />
                </Grid>
                {enableLutOrg && (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        className={classes.spacing}
                    >
                        <Field
                            component={Autocomplete}
                            name="orgId"
                            label="หน่วยงานผู้ยื่นสะสม"
                            options={optionsOrg}
                            optionKey={{
                                label: 'name',
                                value: 'orgId',
                                abbr: 'nameSht',
                            }}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={enableLutOrg ? 3 : 4}
                    className={classes.spacing}
                >
                    <Field
                        component={TextField}
                        name="keyword"
                        label="รหัสพนักงานหรือชื่อ ผู้ยื่นสะสม"
                        placeholder={'รหัสพนักงานหรือชื่อ ผู้ยื่นสะสม'}
                        maxLength={100}
                        onKeyDown={keyEnterSubmitForm(onSearchClick)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={classes.btnGrid}
                >
                    <Grid item className={classes.fieldError}>
                        <Field component={ErrorField} name="error" />
                    </Grid>
                    <Button
                        label="ค่าเริ่มต้น"
                        className={classes.btnReset}
                        onClick={onResetClick}
                    />
                    <Button
                        label="ค้นหา"
                        className={classes.btnSearch}
                        onClick={onSearchClick}
                    />
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
        </Fragment>
    );
};

export default LeaveCollectSearchView;
