import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';

//placement
// top-start
// top
// top-end
// right-start
// right
// right-end
// bottom-start
// bottom
// bottom-end
// left-start
// left
// left-end

const TooltipControl = ({
    classes,
    children,
    title,
    disable,
    placement = "bottom"
}) => {
    return (
        <Tooltip
            title={title}
            placement={placement}
            disableHoverListener={disable}
            classes={{
                tooltip: classes.root
            }}
        >
            {children}
        </Tooltip>
    )
}

export default withStyles(theme => ({
    root: {
        fontSize: "20px"
    }
}))(TooltipControl);
