import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import Button from '../button/Button';
import { BTN } from '../../constants/APDConstants';

const useStyles = makeStyles(theme => ({
    root: {
        width: 500
    },
    control: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    image: {
        width: 100
    },
    dialogRadius: {
        borderRadius: '2px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        textAlign: 'left'
    },
    btn: {
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        padding: '0px 24px 10px 0px'
    },
    altPs: {
        fontSize: '20px',
        color: '#FF0000'
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    }
}));

const ActionAlert = ({ language, btnCloseName, onCancel }) => (
    <Grid container justify={'flex-end'}>
        <Button
            onClick={onCancel}
            label={btnCloseName ? btnCloseName : BTN[language].CLOSE}
        />
    </Grid>
);

const ActionConfirm = ({
    language,
    btnCancelName,
    btnConfirmName,
    onCancel,
    onConfirm,
    classes
}) => (
        <Grid container justify={'flex-end'}>
            <Grid item style={{ marginRight: '20px' }}>
                <Button
                    onClick={onCancel}
                    label={btnCancelName ? btnCancelName : BTN[language].CANCEL}
                    className={classes.btnCancel}
                />
            </Grid>
            <Grid item>
                <Button
                    onClick={onConfirm}
                    label={btnConfirmName ? btnConfirmName : BTN[language].CONFIRM}
                />
            </Grid>
        </Grid>
    );

const AlertMessage = ({
    isOpen,
    altMsg,
    altTitle = 'ข้อความจากระบบ',
    altType,
    language = 'TH',
    btnCloseName,
    btnCancelName,
    btnConfirmName,
    altPs,
    hideDialog,
    onCancel,
    onConfirm,
    altTextbox,
    onChange
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            onClose={hideDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: `${classes.root} ${classes.dialogRadius}` }}
        >
            <DialogTitle id="alert-dialog-title">{altTitle}</DialogTitle>
            <DialogContent className={classes.content}>
                <DialogContentText id="alert-dialog-description">
                    {altMsg}
                    {altPs && <p className={classes.altPs}>{altPs}</p>}
                    {altTextbox && (
                        <Fragment>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows="4"
                                margin="normal"
                                variant="outlined"
                                style={
                                    altPs
                                        ? { marginTop: '-20px', width: '100%' }
                                        : { width: '100%' }
                                }
                                onChange={onChange}
                            />
                            <p>ยกเลิกโดย</p>
                            <TextField
                                id="outlined-multiline-static"
                                margin="normal"
                                variant="outlined"
                                style={
                                    altPs
                                        ? { marginTop: '-20px', width: '100%' }
                                        : { width: '100%' }
                                }
                                defaultValue={'ยกเลิกโดย บท'}
                            />
                        </Fragment>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.btn}>
                {altType === 2 ? (
                    <ActionConfirm
                        language={language}
                        btnCancelName={btnCancelName}
                        btnConfirmName={btnConfirmName}
                        onCancel={onCancel}
                        onConfirm={onConfirm}
                        classes={classes}
                    />
                ) : (
                        <ActionAlert
                            language={language}
                            btnCloseName={btnCloseName}
                            onCancel={onCancel}
                        />
                    )}
            </DialogActions>
        </Dialog>
    );
};

export default AlertMessage;
