import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    errorField: {
        color: 'red',
        fontSize: "17px"
    }
}));

const ErrorField = (props) => {
    const classes = useStyles();
    const {
        meta: { error, touched, submitError }
    } = props
    const isError = (error && submitError) || (touched && error) ? true : false;
    return <span className={classes.errorField}>{isError && `*${error}`}</span>
}
export default ErrorField;