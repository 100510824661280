import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import NatureIcon from '@material-ui/icons/Nature';
import SaveIcon from '@material-ui/icons/Save';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import Badge from '@material-ui/core/Badge';
import SyncIcon from '@material-ui/icons/Sync';
import classnames from 'classnames';

import { ORG_LEAVE } from '../../../../../constants/APDConstants';
import Tooltip from '../../../../../control/tooltip/Tooltip';

const useStyle = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: '135px',
        right: '35px',
        zIndex: 200,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            top: '188px'
        }
    },
    button: {
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        boxShadow: '0px 1px 4px rgba(0,0,0,0.3)',
        marginRight: '5px',
        backgroundColor: 'white',
    },
    buttonEnable: {
        color: '#757575',
        cursor: 'pointer',
        '&:hover': {
            color: 'white',
            backgroundColor: '#F47B15'
        },
    },
    buttonSaveEnable:{
        color: theme.mainTheme.color3,
        cursor: 'pointer',
        '&:hover': {
            color: 'white',
            backgroundColor: '#F47B15'
        },
    },
    buttonDisable: {
        color: '#BABABA',
        backgroundColor: '#F1F1F1'
    },
    buttonActive: {
        color: 'white !important',
        backgroundColor: '#F47B15 '
    },
    buttonOrgLeave: {
        borderRadius: '5px 0px 0px 5px',
        margin: '0px',
        cursor: 'default',
        boxShadow: 'none',
        color: '#757575',
    },
    buttonOrgSap: {
        borderRadius: '0px 5px 5px 0px',
        margin: '0px',
        cursor: 'default',
        boxShadow: 'none',
        color: '#757575',
    },
    buttonSorceGroup: {
        boxShadow: '0px 1px 4px rgba(0,0,0,0.3)',
        display: 'flex',
        borderRadius: '5px',
        height: '35px'
    },
    buttonToolGroup: {
        marginLeft: '20px',
        display: 'flex'
    },
    badge: {
        marginRight: '15px',
        // color: 'white'
    },
    colorPrimary: {
        color: 'white',
        // backgroundColor: 'red'
        backgroundColor: theme.mainTheme.color3
    }
}))

const ToolbarView = ({
    isActiveLayout,
    isEnableSave,
    isActiveManageEmp,
    isOrgSap,
    sorce,
    countEmpChange,

    onSave,
    onFit,
    onRefresh,
    onClickLayout,
    onClickManageEmp
}) => {
    const classes = useStyle();
    return (
        <Grid className={classes.root}>
            <div className={classes.buttonSorceGroup}>
                <Tooltip title="โครงสร้างปัจจุบัน" placement="bottom">
                    <div
                        className={classnames(classes.button, classes.buttonOrgLeave, {
                            [classes.buttonActive]: sorce === ORG_LEAVE.SORCE.ORG_LEAVE,
                        })}
                    >
                        <SupervisorAccountIcon />
                    </div>
                </Tooltip>
                <Tooltip title="โครงสร้างรอแต่งตั้ง/โยกย้าย" placement="bottom">
                    <div
                        className={classnames(classes.button, classes.buttonOrgSap, {
                            [classes.buttonActive]: sorce === ORG_LEAVE.SORCE.ORG_SAP,
                        })}
                    >
                        <SwapCallsIcon />
                    </div>
                </Tooltip>
            </div>
            <div className={classes.buttonToolGroup}>
                {isOrgSap && <Badge
                    badgeContent={countEmpChange}
                    color="primary"
                    className={classes.badge}
                    classes={{ colorPrimary: classes.colorPrimary }}
                >
                    <Tooltip title="จัดการพนักงาน" placement="bottom">
                        <div
                            className={classnames(classes.button, classes.buttonEnable, {
                                [classes.buttonActive]: isActiveManageEmp,
                            })}
                            onClick={onClickManageEmp}
                        >
                            <AccessibilityIcon />
                        </div>
                    </Tooltip>
                </Badge>}
                <Tooltip title="รีเฟรชข้อมูล" placement="bottom">
                    <div
                        className={classnames(classes.button, classes.buttonEnable)}
                        onClick={onRefresh}
                    >
                        <SyncIcon />
                    </div>
                </Tooltip>
                <Tooltip title="รูปแบบการแสดงผล" placement="bottom">
                    <div
                        className={classnames(classes.button, classes.buttonEnable, {
                            [classes.buttonActive]: isActiveLayout
                        })}
                        onClick={onClickLayout}
                    >
                        <NatureIcon />
                    </div>
                </Tooltip>
                <Tooltip title="ปรับตำแหน่งการแสดงผล" placement="bottom">
                    <div
                        className={classnames(classes.button, classes.buttonEnable)}
                        onClick={onFit}
                    >
                        <CenterFocusStrongIcon />
                    </div>
                </Tooltip>
                <Tooltip disable={!isEnableSave} title="บันทึก" placement="bottom">
                    <div
                        className={classnames(classes.button, {
                            [classes.buttonSaveEnable]: isEnableSave,
                            [classes.buttonDisable]: !isEnableSave,
                        })}
                        onClick={isEnableSave ? onSave : undefined}
                    >
                        <SaveIcon />
                    </div>
                </Tooltip>
            </div>
        </Grid>
    )
}

export default ToolbarView
