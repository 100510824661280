import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { Scrollbars } from 'react-custom-scrollbars';

import { makeStyles } from '@material-ui/core/styles';
// import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Reply from '@material-ui/icons/Reply';
import Grid from '@material-ui/core/Grid';

import * as AlertMSGConstants from '../../constants/AlertMSGConstants';
import RouteConfig from '../../config/appRoute/RouteConfig';
import { SYSTEM, SERVICE_STATUS_CODE } from '../../constants/APDConstants';
import { logout } from '../../api/ApiAuthen';
import { clearTokenLocalStore } from '../../utilities/LocalStorageUtils';
import { checkResStatusService } from '../../utilities/ResServiceUtils';

import LeaveMenu from './LeaveMenu';
import MeetingMenu from './MeetingMenu';
import UmMenu from './UmMenu';
import PortalMenu from './PortalMenu';
import MaintenanceMenu from './MaintenanceMenu';
import EmployeeProfileManagementMenu from './EmployeeProfileManagementMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        // flexDirection: 'column',
        // justifyContent: 'space-between',
        // display: 'flex',
        padding: '0px',
    },
    listItem: {
        '&:hover': {
            backgroundColor: '#e6e6e6',
        },
    },
    listItemActive: {
        backgroundColor: '#d9d9d9',
    },
    link: {
        textDecoration: 'none',
    },
    listItemText: {
        color: '#000',
    },
    primaryListItem: {
        fontSize: '21px',
    },
}));

const ListMenu = ({
    classes,
    icon,
    label,
    to,
    pathActive,
    functionId,
    history,
    onClick,
}) => {
    let isActive = to && history && to === history.location.pathname;

    if (pathActive) {
        isActive = pathActive.indexOf(history.location.pathname) !== -1;
    }

    const handleClickMenu = () => {
        if (to && to !== history.location.pathname) {
            if (functionId === '-1') {
                window.open(to);
            } else {
                history.push(to);
                onClick();
            }
        } else {
            onClick();
        }
    };

    return (
        <ListItem
            onClick={handleClickMenu}
            button
            className={classnames(classes.listItem, {
                [classes.listItemActive]: isActive,
            })}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
                primary={label}
                className={classes.listItemText}
                classes={{ primary: classes.primaryListItem }}
            />
        </ListItem>
    );
};

// const filterMenu = (functionsId, menu) => {
//     const result = []

//     functionsId.forEach(func => {
//         const [menuFilter] = menu.filter(menu => menu.functionId === func)

//         if (menuFilter) {
//             result.push(menuFilter)
//         }
//     })

//     return result
// }

const getMenu = ({ pathname, systems = [], functions = [] }) => {
    const functionsId = functions.map((func) => func.functionId);

    if (pathname.indexOf(SYSTEM.LEAVE.path) !== -1) {
        return LeaveMenu.filter(
            (menu) =>
                functionsId.indexOf(menu.functionId) !== -1 ||
                menu.functionId === '-1'
        );
        // return filterMenu(functionsId, LeaveMenu)
    } else if (pathname.indexOf(SYSTEM.MEETING.path) !== -1) {
        return MeetingMenu.filter(
            (menu) =>
                functionsId.indexOf(menu.functionId) !== -1 ||
                menu.functionId === '-1'
        );
        // return filterMenu(functionsId, MeetingMenu)
    } else if (pathname.indexOf(SYSTEM.UM.path) !== -1) {
        return UmMenu.filter(
            (menu) =>
                functionsId.indexOf(menu.functionId) !== -1 ||
                menu.functionId === '-1'
        );
        // return filterMenu(functionsId, UmMenu)
    } else if (pathname.indexOf(SYSTEM.MAINTENANCE.path) !== -1) {
        return MaintenanceMenu.filter(
            (menu) =>
                functionsId.indexOf(menu.functionId) !== -1 ||
                menu.functionId === '-1'
        );
        // return filterMenu(functionsId, MaintenanceMenu)
    } else if (
        pathname.indexOf(SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.path) !== -1
    ) {
        return EmployeeProfileManagementMenu.filter(
            (menu) =>
                functionsId.indexOf(menu.functionId) !== -1 ||
                menu.functionId === '-1'
        );
    } else {
        const systemsId = systems.map((sys) => sys.systemId);
        return PortalMenu.filter(
            (menu) => systemsId.indexOf(menu.systemId) !== -1
        );
    }
};

const Menu = ({ authenRdc, history, alertMessageState, onDrawerClose }) => {
    const { pathname } = history.location;
    const { systems, functions } = authenRdc.profile;
    const classes = useStyles();
    const menus = getMenu({ pathname, systems, functions });
    const isHomePage = pathname === '/';
    const heightBottomMenu = isHomePage ? 55 : 110;

    const handleLogout = async () => {
        const statusLogout = await logout();
        if (statusLogout === SERVICE_STATUS_CODE.SUCCESS) {
            clearTokenLocalStore();
            window.location.href =
                process.env.REACT_APP_ROOT_URL + RouteConfig.LOGIN;
        } else {
            const msg = checkResStatusService(statusLogout);
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.LOGOUT_FAIL,
            });
        }
    };

    return (
        // <List className={classes.root} >
        <Grid
            container
            justify="space-between"
            direction="row"
            className={classes.root}
        >
            <Grid
                item
                xs={12}
                style={{ height: `calc(100% - ${heightBottomMenu}px)` }}
            >
                <Scrollbars>
                    {menus.map((menu, index) => (
                        <ListMenu
                            key={`menu-${index}`}
                            classes={classes}
                            to={menu.to}
                            functionId={menu.functionId}
                            pathActive={menu.pathActive}
                            icon={menu.icon}
                            label={menu.label}
                            history={history}
                            onClick={onDrawerClose}
                        />
                    ))}
                </Scrollbars>
            </Grid>
            <Grid item xs={12}>
                {!isHomePage && (
                    <ListMenu
                        classes={classes}
                        to="/"
                        icon={<Reply />}
                        label="กลับสู่หน้าหลัก"
                        history={history}
                        onClick={onDrawerClose}
                    />
                )}
                <ListMenu
                    classes={classes}
                    icon={<ExitToApp />}
                    label="ออกจากระบบ"
                    onClick={handleLogout}
                />
            </Grid>
        </Grid>
        // </List >
    );
};

const mapStateToProps = (state) => ({
    authenRdc: state.authen,
});

export default compose(connect(mapStateToProps), withRouter)(Menu);
