import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import PeriodDisplayDetail from '../../components/leave/presentational/leavesap/PeriodDisplayDetail';

const useStyles = makeStyles(theme => ({
    label: {
        color: '#F47B15'
    }
}))

const Period = ({
    input,
    label,
    disabled
}) => {
    const classes = useStyles()

    const handleChange = value => () => {
        input.onChange(value)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <label className={classes.label}>{label}</label>
            </Grid>
            <Grid item xs={12}>
                <PeriodDisplayDetail
                    disabled={disabled}
                    leavePeriodId={input.value}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )
}

export default Period
