import React from 'react'

import OrgLeaveProvider from './OrgLeaveProvider'
import OrgLeave from './OrgLeave'


const OrgLeaveIndex = () => {
    return (
        <OrgLeaveProvider>
            <OrgLeave />
        </OrgLeaveProvider>
    )
}

export default OrgLeaveIndex
