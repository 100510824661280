import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import LeaveSAPPage from './LeaveSAP'
import LeaveSAPEditPage from './LeaveSAPEditPage'

import RouteConfig from '../../../../config/appRoute/RouteConfig'
import LeaveSAPProvider from './LeaveSAPProvider'

const LeaveSAPIndex = () => {
    return (
        <LeaveSAPProvider>
            <Switch>
                <Route exact key={RouteConfig.LEAVE_SAP} path={RouteConfig.LEAVE_SAP} component={LeaveSAPPage} />
                <Route exact key={RouteConfig.LEAVE_SAP_EDIT} path={RouteConfig.LEAVE_SAP_EDIT} component={LeaveSAPEditPage} />
                <Route exact key={RouteConfig.LEAVE_SAP_DETAIL} path={RouteConfig.LEAVE_SAP_DETAIL} component={LeaveSAPEditPage} />
                <Redirect to={RouteConfig.LEAVE_SAP} />
            </Switch>
        </LeaveSAPProvider>
    )
}

export default LeaveSAPIndex
