import React, { createContext, useState } from 'react'
import { connect } from 'react-redux'

import UseDataTableState from '../../../../control/table/UseDataTableState'
import UseSearchLeaveCollectState from './useleavecollect/UseSearchLeaveCollectState'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseLoadingState from '../../../../control/loading/UseLoadingState'

export const LeaveCollectContext = createContext()

const LeaveCollectProvider = ({ children, authenRdc }) => {
    const rowPerPage = 10
    const [isResearch, setIsReSearch] = useState(false);
    const searchLeaveCollectState = UseSearchLeaveCollectState()
    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();
    const dataTableState = UseDataTableState({
        rowPerPage: rowPerPage
    });
    const value = {
        isResearch,
        setIsReSearch,
        profile: authenRdc.profile,
        searchLeaveCollectState,
        dataTableState,
        alertMessageState,
        loadingState
    }
    return (
        <LeaveCollectContext.Provider value={value} >
            {children}
        </LeaveCollectContext.Provider>
    )
}

const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default connect(mapStateToProps)(LeaveCollectProvider)
