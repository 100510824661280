import React from 'react'

import BookingProvider from './BookingProvider'
import BookingListIndex from './bookingList/BookingListIndex'
import UtilsPage from './UtilsPage'

const BookingIndex = () => {
    return (
        <BookingProvider>
            <BookingListIndex />
            <UtilsPage />
        </BookingProvider>
    )
}

export default BookingIndex
