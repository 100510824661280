import { fetchGet, createParamGet, fetchDel_ } from '../utilities/ServiceUtils';
import { API } from '../constants/ServiceMSGConstants'
import * as ApiConstants from '../constants/ApiConstants';

export const callServiceOrgDep = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_ORG_DEP);
        if (!fetchData.ok) {
            throw fetchData.statusText;
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex callServiceOrgDep : ', err);
    }
};

export const getLutRoomSize = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LU_ROOM_SIZE);
        if (!fetchData.ok) {
            throw fetchData.statusText;
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex getLutRoomSize : ', err);
    }
};

//{ roomName, startDate, endDate, startTime, endTime,  roomSizeId, uuid }
export const getAvailableRoom = async param => {
    try {
        const fetchData = await fetchGet(
            `${ApiConstants.SERVICE_SEARCH_AVAILABLE_ROOM}?${createParamGet(
                param
            )}`
        );
        if (!fetchData.ok) {
            throw fetchData.statusText;
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex getAvailableRoom : ', err);
    }
};

export const getBookingList = async params => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_BOOKING_LIST}?${params}`);
        if (!fetchData.ok) {
            // throw fetchData.statusText;
            throw { status: fetchData.status, api: API.MEETING }
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex getBookingList : ', err);
        return err
    }
};

export const getBookingDate = async ({ bookingId, uuid }) => {
    try {
        const fetchData = await fetchGet(
            `${ApiConstants.SERVICE_BOOKING_DATE}/${bookingId}?uuid=${uuid}`
        );
        if (!fetchData.ok) {
            throw fetchData.statusText;
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex getBookingDate : ', err);
    }
};

export const cancelBookingByDate = async (id, params) => {
    try {
        const fetchData = await fetchDel_(`${ApiConstants.SERVICE_BOOKING_CANCEL_BY_DATE}/${id}`, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.MEETING }
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex cancelBookingByDate : ', err)
        return err
    }
};

export const getLutBooking = async () => {
    try {
        const fetchData = await fetchGet(
            `${ApiConstants.SERVICE_LUT_BUILDING}`
        );
        if (!fetchData.ok) {
            throw fetchData.statusText;
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex getLutBooking : ', err);
    }
};

export const getBookingByCon = async params => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_BOOKING_BY_CON}?${params}`);
        if (!fetchData.ok) {
            // throw fetchData.statusText;
            throw { status: fetchData.status, api: API.MEETING }
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex getBookingByCon : ', err);
        return err
    }
};

export const getCancelBooking = async params => {
    try {
        const fetchData = await fetchGet(
            `${ApiConstants.SERVICE_GET_CANCEL_BOOKING}?${params}`
        );
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.MEETING };
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex getCancelBooking : ', err);
        return err
    }
};
