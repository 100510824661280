import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import classnames from 'classnames';
import FormControl from '@material-ui/core/FormControl';

import Focus from '../focus/Focus';
import * as FocusAction from '../../actions/FocusAction';
import { usePrevious } from '../StateFunctions';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    listbox: {
        '& ul': {
            padding: 0,
            margin: 0
        }
    },
    root: {
        height: theme.field.height
    },
    input: {
        boxShadow: theme.field.boxShadow,
        height: theme.field.height,
        '& input': {
            height: theme.field.height,
            // paddingTop: '0px',
            // paddingBottom: '0px',
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        '& svg': {
            width: '20px',
            height: '20px'
        }
    },
    inputError: {
        boxShadow: theme.field.boxShadow,
        height: theme.field.height,
        '& input': {
            height: theme.field.height,
            // paddingTop: '0px',
            // paddingBottom: '0px',
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        '& svg': {
            width: '20px',
            height: '20px'
        },
        '& fieldset': {
            borderColor: `${theme.mainTheme.color5} !important`
        }
    },
    labelStyle: {
        fontSize: '20px',
        color: theme.mainTheme.color1
    },
    control: {
        width: '100%'
        // height: '70px'
    },
    marginNormal: {
        marginTop: '0px !important',
        marginBottom: '0px !important'
    },
    formHelper: {
        marginLeft: '10px',
        color: theme.mainTheme.color5,
        marginTop: '1px'
    },
    endAdornment: {
        display: 'contents'
    },
    divControl: {
        height: '90px'
    },
    txtErr: {
        color: 'red'
    },
    autocompleteOption: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
        boxSizing: 'border-box !important',
        fontSize: '20px',
        padding: '0px',
        height: '40px',
        /// ต้องการให้สีตอน selected list หายไป อาจจะมีวิธีที่ดีกว่านี้??
        backgroundColor: 'white !important',
        '&:Hover': {
            backgroundColor: '#00000014 !important'
        }
    },

    circle: {
        height: '10px',
        width: '10px',
        // backgroundColor: "#bbb",
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '5px'
    },
    checkboxRoot: {
        height: '40px'
    }
}));

const optionRef = React.createRef();

let bt;
let optionKeyBackup;
let isCanInsertConstant = false;

function MultiSelect(props) {
    const classes = useStyles();
    let { options = [], id = 'autocomplete' } = props;

    const [lenOptions, setLenOptions] = useState(0);

    useEffect(() => {
        setLenOptions(options.length);
    }, [options]);

    const refInput = React.createRef();

    optionRef.current = options;
    setTimeout(() => {
        if (refInput.current) {
            bt = refInput.current.getElementsByClassName(
                'MuiButtonBase-root MuiIconButton-root MuiAutocomplete-clearIndicator MuiAutocomplete-clearIndicatorDirty'
            );
            let btAction = refInput.current.getElementsByClassName(
                'MuiAutocomplete-popupIndicator'
            );

            if (btAction) {
                btAction[0].getElementsByTagName('svg')[0].style =
                    'width: 25px; height: 25px';
            }

            if (bt && bt.length > 0 && bt[0].name === '') {
                bt[0].name = input.name;
                bt[0].getElementsByTagName('svg')[0].style =
                    'width: 15px; height: 15px';
                bt[0].style = 'display: none';
            }
        }
    }, 300);

    const {
        optionKey = { label: 'name', value: 'id', abbr: null, abbr2: null },
        isCanInsert = false,
        isHideFormHelperText,
        isShowNoSelect = true,
        required,
        input,
        label,
        meta: { touched, invalid, error, active },
        isDisabled,
        disabled,
        width,
        focusAction,
        focusName,
        onClickCheckbox,
        listSelectedCheckbox,
        defaultValue = [],
        requiredNumber = false,
        isCircle
    } = props;

    optionKeyBackup = optionKey;
    isCanInsertConstant = isCanInsert;

    const asterisk = required ? (
        <span style={{ color: '#FF0000' }}> *</span>
    ) : (
            ''
        );
    const errors = touched && invalid && error ? error : undefined;
    const isError = errors ? true : false;
    const prevProp = usePrevious(active);

    if (!required && isShowNoSelect) {
        options = [...options];
    }

    const renderTagsFunc = (value, getTagProps) => {
        let str = '';
        value.forEach(
            (option, index) =>
                (str =
                    index === 0
                        ? (str += `${option[optionKey.label]}`)
                        : (str += `, ${option[optionKey.label]}`))
        );

        return (
            <span
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'block',
                    boxSizing: 'border-box !important',
                    fontSize: '20px',
                    paddingLeft: '0px'
                }}
            >
                {str}
            </span>
        );
    };

    const onClickList = (event, listSelectedValue) => {
        let listRoomIdSelected = listSelectedValue.map(itm => {
            return itm[optionKey.value];
        });

        if (onClickCheckbox) {
            onClickCheckbox(listRoomIdSelected, listSelectedValue);
        }
    };

    const focus = Focus();
    if (focusName === input.name) {
        const focusSuccess = focus.focusFn(
            !prevProp && !active && touched && invalid,
            focusName,
            input.name
        );
        if (focusSuccess) {
            focusAction.setDefault();
        }
    }

    const filterOptionsFunction = (options, { inputValue }) => {
        if (options && inputValue) {
            const filterOptions = options.filter(filterItem => {
                const strRoomName = filterItem[optionKey.label].toLowerCase();
                const strSearch = inputValue.toLowerCase();

                return strRoomName.indexOf(strSearch) > -1;
            });

            setLenOptions(filterOptions.length);

            return filterOptions;
        } else {
            setLenOptions(options.length);
            return options;
        }
    };

    return (
        <Fragment>
            <div className={classes.divControl}>
                <FormControl
                    className={classes.control}
                    error={isError}
                    disabled={isDisabled || disabled}
                >
                    <span className={classes.labelStyle}>
                        {label && (
                            <label
                                className={classnames({
                                    [classes.txtErr]: isError
                                })}
                                required
                            >
                                {requiredNumber
                                    ? `${label} (จำนวน ${lenOptions} ห้อง)`
                                    : label}
                                {asterisk}
                            </label>
                        )}
                    </span>

                    <Autocomplete
                        multiple
                        id={id}
                        style={
                            width
                                ? { width: `${parseInt(width)}px` }
                                : { width: '100%' }
                        }
                        classes={{
                            popup: classes.popup,
                            root: classes.root,
                            option: classes.autocompleteOption
                        }}
                        className={classes.autoComplete}
                        options={options}
                        disableCloseOnSelect
                        defaultValue={defaultValue}
                        value={defaultValue}
                        filterOptions={filterOptionsFunction}
                        onChange={onClickList}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    classes={{ root: classes.checkboxRoot }}
                                    color="primary"
                                    size="small"
                                    checked={
                                        listSelectedCheckbox.indexOf(
                                            option[optionKey.value]
                                        ) !== -1
                                            ? true
                                            : false
                                    }
                                />
                                {isCircle && <span
                                    className={classes.circle}
                                    style={{
                                        backgroundColor: option.color
                                            ? `#${option.color}`
                                            : '#ddd'
                                    }}
                                ></span>}
                                {option[optionKey.label]}
                            </React.Fragment>
                        )}
                        renderTags={renderTagsFunc}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                InputProps={
                                    errors
                                        ? {
                                            ...params.InputProps,
                                            type: 'search',
                                            className: classes.inputError
                                        }
                                        : {
                                            ...params.InputProps,
                                            type: 'search',
                                            className: classes.input
                                        }
                                }
                                placeholder={
                                    listSelectedCheckbox.length !== 0
                                        ? ' '
                                        : required
                                            ? 'กรุณาเลือก'
                                            : 'ไม่ระบุ'
                                }
                                inputProps={{ ...params.inputProps, autocomplete: 'off' }}
                            />
                        )}
                    />
                </FormControl>
                {!isHideFormHelperText && (
                    <FormHelperText className={classes.formHelper}>
                        {errors}
                    </FormHelperText>
                )}
            </div>
        </Fragment>
    );
}

const mapStateTopProps = state => ({
    focusName: state.focus.name
});

const mapDispatchToProps = dispatch => ({
    focusAction: bindActionCreators(FocusAction, dispatch)
});

export default connect(mapStateTopProps, mapDispatchToProps)(MultiSelect);
