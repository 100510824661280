import React, { useState } from 'react'
import { Field } from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import classnames from 'classnames'

import { ORG_LEAVE } from '../../../../../constants/APDConstants'
import { checkEmpActive } from '../../../container/orgLeave/OrgLeaveUtils'

import AutoComplete from '../../../../../control/autocomplete/AutoComplete'

const { NAME } = ORG_LEAVE.FORM_EDIT_TOOLBAR

const useStyles = makeStyles(theme => ({
    root: {
        borderStyle: 'solid',
        borderColor: '#C4C4C4',
        borderWidth: '0px 1px 1px 1px',
        height: '80px',
        padding: '10px'
    },
    columnLabel: {
        height: '60px'
    },
    columnField: {
        marginTop: '7px'
    },
    hide: {
        display: 'none'
    }
}))

const renderOrgName = ({ input }) => (<label>{input.value}</label>)

const { AUDIT_ID, APPROVE_ID } = ORG_LEAVE.PERMISSION

const EmpItemView = ({
    field,
    emp,
    lutOrg,
    change,
    index
}) => {
    const [isEdit, setEdit] = useState(false)
    const classes = useStyles()
    // const isShowEdit =  emp.empStatusId === EMP_STATUS_ID.ACTIVE && (emp.permission.length === 0 || (emp.permission.indexOf(AUDIT_ID) !== -1 && emp.permission.indexOf(APPROVE_ID) !== -1))
    const isShowEdit = checkEmpActive(emp.empStatusId, emp.effectiveDate) && (emp.permission.length === 0 || (emp.permission.indexOf(AUDIT_ID) !== -1 && emp.permission.indexOf(APPROVE_ID) !== -1))

    const handleEdit = () => {
        setEdit(!isEdit)
    }

    const handleChangeOrg = (value) => {
        value && value.name && change(`${NAME.EMPS}[${index}].${NAME.CURRENT_ORG_NAME}`, value.name)
    }

    return (
        <Grid container className={classnames(classes.root, { [classes.hide]: !emp.isDisplay })} >
            <Grid item xs={3} className={classes.columnLabel} container alignItems="center">{emp.name}</Grid>
            <Grid item xs={1} className={classes.columnLabel} container alignItems="center">
                {emp.empStatusName ? emp.empStatusName : "-"}
            </Grid>
            <Grid item xs={4} className={classes.columnLabel} container alignItems="center">
                {emp.prevOrgName ? emp.prevOrgName : "-"}
            </Grid>

            {isEdit ? (
                <Grid item xs={3} className={classes.columnField} >
                    <Field
                        name={`${field}.${NAME.CURRENT_ORG}`}
                        component={AutoComplete}
                        options={lutOrg}
                        onChangeDropDown={handleChangeOrg}
                        isHideFormHelperText={true}
                        required
                    />
                </Grid>
            ) : (
                    <Grid item xs={3} className={classes.columnLabel} container alignItems="center">
                        {field.orgName}
                        <Field
                            name={`${field}.${NAME.CURRENT_ORG_NAME}`}
                            component={renderOrgName}
                        />
                    </Grid>
                )
            }

            <Grid
                item xs={1}
                container
                className={classes.columnLabel}
                alignItems="center"
                justify="center"
            >
                {isShowEdit && <IconButton size="small" onClick={handleEdit}>
                    {isEdit ? <CheckIcon fontSize="inherit" /> : <EditIcon fontSize="inherit" />}
                </IconButton>}
            </Grid>
        </Grid>
    )
}

export default EmpItemView
