import { ERRORS } from '../../../../constants/APDConstants'
import { FORM } from '../../../../constants/leave/HolidayConstants'
import { convertDateDisplay } from '../../../../utilities/DatetimeUtils'

const { NAME } = FORM

export const validateHoliday = values => {
    const errors = {}

    if (!values[NAME.HOLIDAY_DAY]) {
        errors[NAME.HOLIDAY_DAY] = ERRORS.REQUIRED
    }

    if (!values[NAME.INFO]) {
        errors[NAME.INFO] = ERRORS.REQUIRED
    }

    if (!values[NAME.ENABLED]) {
        errors[NAME.ENABLED] = ERRORS.REQUIRED
    }

    return errors
}


export const setFormatDataExport = (data = []) => {
    const newData = data.map(item => ({
        ...item,
        startDate: convertDateDisplay(item.startDate),
        endDate: convertDateDisplay(item.endDate),
        impactDate: convertDateDisplay(item.impactDate)
    }))


    return newData
}