import React, { Fragment } from 'react'

import { makeStyles, Grid } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ButtonMui from '@material-ui/core/Button'

import { SAP, ABSENCE } from '../../../../constants/APDConstants'

const { DETAIL_LEAVE } = SAP
const { LEAVE_PERIOD_ID } = ABSENCE.FORM_EDIT_ABSENCE

const useStyles = makeStyles(theme => ({
    grid: {
        marginBottom: '2%'
    },
    btnGroup: {
        width: '100%',
        height: '100px'
    },
    btnMuiGroup: {
        width: '25%',
        borderRadius: '5px !important',
        border: '0px !important',
        color: '#8c8c8c'
    },
    choosePeriod: {
        width: '25%',
        borderRadius: '5px !important',
        border: '0px !important',
        // color: '#FFFFFF'
        backgroundColor: '#0072BC',
        color: 'white !important',
        '&:hover': {
            backgroundColor: '#0072BC',
            color: 'white !important',
        },
    },
    containerImage: {
        height: '45px'
    },
    img: {
        margin: '10px'
    }
}))

const displayImage = (leavePeriodId) => {
    const image = {
        morning: 'ic_morning.png',
        afternoon: 'ic_dawn.png',
        allDay: 'ic_sun.png',
        specificTime: 'ic_iden_time.png'
    }

    switch (leavePeriodId) {
        case LEAVE_PERIOD_ID.MORNING:
            image.morning = 'ic_morning_select.png'
            break;
        case LEAVE_PERIOD_ID.AFTERNOON:
            image.afternoon = 'ic_dawn_select.png'
            break;
        case LEAVE_PERIOD_ID.ALL_DAY:
            image.allDay = 'ic_sun_select.png'
            break;
        case LEAVE_PERIOD_ID.SPECIFIC_TIME:
            image.specificTime = 'ic_iden_time_select.png'
            break;
        default:
            break;
    }

    return image
}

const PeriodDisplayDetail = (props) => {

    const classes = useStyles()
    const { leavePeriodId, disabled, onChange } = props
    const image = displayImage(leavePeriodId)

    return (
        <Fragment>
            <Grid container className={classes.grid}>
                <ButtonGroup className={classes.btnGroup}>
                    <ButtonMui
                        className={leavePeriodId === 1 ? classes.choosePeriod : classes.btnMuiGroup}
                        disabled={disabled}
                        onClick={onChange && onChange(LEAVE_PERIOD_ID.MORNING)}
                    >
                        <Grid container justify='center' className={classes.img} >
                            <Grid item xs={12} className={classes.containerImage}>
                                <img src={`${process.env.PUBLIC_URL}/img/detailLeave/${image.morning}`} height="45" alt={DETAIL_LEAVE.PERIOD.MORNING} />
                            </Grid>
                            <Grid item xs={12}>
                                {DETAIL_LEAVE.PERIOD.MORNING}
                            </Grid>
                        </Grid>
                    </ButtonMui>
                    <ButtonMui
                        className={leavePeriodId === 2 ? classes.choosePeriod : classes.btnMuiGroup}
                        disabled={disabled}
                        onClick={onChange && onChange(LEAVE_PERIOD_ID.AFTERNOON)}
                    >
                        <Grid container justify='center' className={classes.img}>
                            <Grid item xs={12} className={classes.containerImage}>
                                <img src={`${process.env.PUBLIC_URL}/img/detailLeave/${image.afternoon}`} height="45" alt={DETAIL_LEAVE.PERIOD.AFTERNOON} />
                            </Grid>
                            <Grid item xs={12}>
                                {DETAIL_LEAVE.PERIOD.AFTERNOON}
                            </Grid>
                        </Grid>
                    </ButtonMui>
                    <ButtonMui
                        className={leavePeriodId === 3 ? classes.choosePeriod : classes.btnMuiGroup}
                        disabled={disabled}
                        onClick={onChange && onChange(LEAVE_PERIOD_ID.ALL_DAY)}
                    >
                        <Grid container justify='center' className={classes.img}>
                            <Grid item xs={12} className={classes.containerImage}>
                                <img src={`${process.env.PUBLIC_URL}/img/detailLeave/${image.allDay}`} height="40" alt={DETAIL_LEAVE.PERIOD.ALL_DAY} />
                            </Grid>
                            <Grid item xs={12}>
                                {DETAIL_LEAVE.PERIOD.ALL_DAY}
                            </Grid>
                        </Grid>
                    </ButtonMui>
                    <ButtonMui
                        className={leavePeriodId === 4 ? classes.choosePeriod : classes.btnMuiGroup}
                        disabled={disabled}
                        onClick={onChange && onChange(LEAVE_PERIOD_ID.SPECIFIC_TIME)}
                    >
                        <Grid container justify='center' className={classes.img}>
                            <Grid item xs={12} className={classes.containerImage}>
                                <img src={`${process.env.PUBLIC_URL}/img/detailLeave/${image.specificTime}`} style={{ marginTop: '5px' }} height="35" alt={DETAIL_LEAVE.PERIOD.TIME} />
                            </Grid>
                            <Grid item xs={12}>
                                {DETAIL_LEAVE.PERIOD.TIME}
                            </Grid>
                        </Grid>
                    </ButtonMui>
                </ButtonGroup>
            </Grid>
        </Fragment>
    )
}

export default PeriodDisplayDetail