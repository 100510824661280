import React from 'react';
// import { Field } from 'redux-form';

import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles, Paper, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LeftIcon from '@material-ui/icons/ArrowLeft';
import RightIcon from '@material-ui/icons/ArrowRight';

import DataTable from '../../../../control/table/DataTable';
import Button from '../../../../control/button/Button';
// import { NormalTextFields } from '../../../../control/textfield/TextFields'
import * as HolidayConstants from '../../../../constants/leave/HolidayConstants';

// import { normalizeNumber } from '../../../../utilities/Validate'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '24px',
        width: '100%'
    },
    paper: {
        width: '100%',
        ...theme.paperPadding
    },
    nameTitle: {
        fontSize: '33px'
    },
    setYear: {
        fontSize: '25px',
        alignSelf: 'center'
    },
    btnIcon: {
        padding: '0px',
        color: '#F47B15'
    },
    inputYear: {
        '& input': {
            height: '2px',
            width: '36px'
        }
    },
    positionAddHoliday: {
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'start',
            marginTop: '1%',
            marginBottom: '1%'
        }
    },
    btnAdd: {
        textAlign: 'end',
        marginLeft: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: '0px'
        }
    },
    btnExport: {
        backgroundColor: `${theme.mainTheme.color4} !important`,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '10px'
        }
    }
}));

const HolidayView = props => {
    const classes = useStyles();
    const {
        column,
        data = [],
        page,
        rowPerPage,
        total,
        sortColumn,
        sortType,
        handlePageChange,
        handleSortExternal,
        handleRowPerPage,
        handleRowClick,
        year,
        isBack,
        isNext,
        onYearChange,
        onAddHolidayClick,
        notScroll,
        isSuperAdmin,
        onExportClick
    } = props;

    return (
        <Scrollbars universal={true}>
            <Grid container className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.setYear}
                        >
                            {HolidayConstants.NAME_YEAR}{' '}
                            <IconButton
                                className={classes.btnIcon}
                                disabled={!isBack}
                                onClick={() =>
                                    onYearChange(
                                        HolidayConstants.BACK_YEAR
                                    )
                                }
                            >
                                <LeftIcon />
                            </IconButton>
                            <span>{year}</span>
                            <IconButton
                                className={classes.btnIcon}
                                disabled={!isNext}
                                onClick={() =>
                                    onYearChange(
                                        HolidayConstants.NEXT_YEAR
                                    )
                                }
                            >
                                <RightIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid
                                container
                                className={
                                    classes.positionAddHoliday
                                }
                            >
                                <Button
                                    onClick={onExportClick}
                                    label={
                                        HolidayConstants.EXPORT_EXCEL
                                    }
                                    className={classes.btnExport}
                                />
                                {isSuperAdmin && (
                                    <Button
                                        onClick={onAddHolidayClick}
                                        label={
                                            HolidayConstants.ADD_HOLIDAY
                                        }
                                        className={classes.btnAdd}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '16px' }}>
                        <Grid
                            container
                            justify="flex-end"
                            className={classes.textSummary}
                        >
                            {`จำนวนวันหยุดของปี ${year} มีทั้งหมด ${
                                data.length
                                } วัน เปิดใช้งาน ${
                                data.filter(
                                    item => item.enabled !== false
                                ).length
                                } วัน`}
                        </Grid>
                    </Grid>
                    <DataTable
                        notScroll={notScroll}
                        column={column}
                        data={data}
                        page={page}
                        rowPerPage={rowPerPage}
                        total={total}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        handlePageChange={handlePageChange}
                        handleSortExternal={handleSortExternal}
                        handleRowPerPage={handleRowPerPage}
                        handleRowClick={handleRowClick}
                    />
                </Paper>
            </Grid>
        </Scrollbars>
    );
};

export default HolidayView;
