import React, { Fragment } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CardHeader from '@material-ui/core/CardHeader';

import { getTokenLocalStore } from '../../utilities/LocalStorageUtils';
import { SYSTEM_DISPLAY } from '../../constants/APDConstants';
import routeConfig from '../../config/appRoute/RouteConfig';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        justifyContent: 'space-between',
    },
    avatarImage: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    avatar: {
        display: 'flex',
    },
    position: {
        color: theme.mainTheme.color1,
    },
    menuButton: {
        zIndex: theme.zIndex.appBar + 200,
    },
    leftHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    root: {
        display: 'unset',
    },
    title: {
        width: '100%',
        minWidth: '350px',
        fontSize: '22px',
        color: theme.mainTheme.color1,
        fontWeight: 'bold',
    },
    subMenu: {
        fontSize: '20px',
        color: 'black',
    },
    content: {
        flex: 'unset',
    },
}));

const ProfileCard = ({ title, subTitle, image }) => {
    const classes = useStyles();
    return (
        <CardHeader
            avatar={
                <img
                    src={image}
                    alt="img"
                    className={classes.avatarImage}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `${process.env.PUBLIC_URL}/img/profile/no_pic.png`;
                    }}
                />
            }
            title={title}
            classes={{ subheader: classes.position, avatar: classes.avatar }}
            subheader={subTitle}
        />
    );
};

const getSystemName = (pathName) => {
    let system = null;

    switch (pathName) {
        case routeConfig.LEAVE_ORG:
            system = SYSTEM_DISPLAY.LEAVE_ORG;
            break;
        case routeConfig.LEAVE_HOLIDAY:
            system = SYSTEM_DISPLAY.LEAVE_HOLIDAY;
            break;
        case routeConfig.LEAVE_SAP:
        case routeConfig.LEAVE_SAP_EDIT:
        case routeConfig.LEAVE_SAP_DETAIL:
            system = SYSTEM_DISPLAY.LEAVE_SAP;
            break;
        case routeConfig.LEAVE_COLLECT:
            system = SYSTEM_DISPLAY.LEAVE_COLLECT;
            break;
        case routeConfig.LEAVE_ABSENCE:
            system = SYSTEM_DISPLAY.LEAVE_ABSENCE;
            break;
        case routeConfig.MEETING_CREATE:
        case routeConfig.MEETING_MANAGE:
        case routeConfig.MEETING_MANAGE_ADD:
        case routeConfig.MEETING_MANAGE_EDIT:
            system = SYSTEM_DISPLAY.MEETING_CREATE;
            break;
        case routeConfig.MEETING_BOOKING:
        case routeConfig.MEETING_BOOKING_CALENDAR:
        case routeConfig.MEETING_BOOKING_TABLE:
            system = SYSTEM_DISPLAY.MEETING_BOOKING;
            break;
        case routeConfig.MEETING_DAILY_BOOKING:
            system = SYSTEM_DISPLAY.MEETING_DAILY_BOOKING;
            break;
        case routeConfig.MEETING_CANCELLED_BOOKING:
            system = SYSTEM_DISPLAY.MEETING_CANCELLED_BOOKING;
            break;
        case routeConfig.UM_USER_GROUP:
        case routeConfig.UM_USER_GROUP_ADD:
        case routeConfig.UM_USER_GROUP_EDIT:
            system = SYSTEM_DISPLAY.UM_USER_GROUP;
            break;
        case routeConfig.UM_USER:
            system = SYSTEM_DISPLAY.UM_USER;
            break;
        case routeConfig.MAINTENANCE_CONNEXT:
            system = SYSTEM_DISPLAY.MAINTAINER_CONNEXT;
            break;
        case routeConfig.MAINTENANCE_TEMPLATE:
            system = SYSTEM_DISPLAY.MANAGE_TEMPLATES;
            break;
        case routeConfig.MAINTENANCE_FORCE_UPDATE:
            system = SYSTEM_DISPLAY.FORCE_UPDATE;
            break;
        case routeConfig.EMPLOYEE_PROFILE_EDITING_HISTORY:
            system = SYSTEM_DISPLAY.EMPLOYEE_PROFILE_EDITING_HISTORY;
            break;
        // case routeConfig.MAINTENANCE_TEMPLATE:
        //     system = SYSTEM_DISPLAY.MANAGE_TEMPLATES
        //     break;
        default:
            break;
    }

    return system;
};

const SystemCart = ({ pathname }) => {
    const classes = useStyles();
    const system = getSystemName(pathname);

    return system ? (
        <CardHeader
            title={system.title}
            classes={{
                title: classes.title,
                subheader: classes.subMenu,
                root: classes.root,
                content: classes.content,
            }}
            subheader={system.subMenu}
        />
    ) : null;
};

const Header = ({
    open,
    handleDrawerOpen,
    handleDrawerClose,
    authenRdc,
    location,
}) => {
    const classes = useStyles();
    const profile = authenRdc.profile;

    return (
        <Fragment>
            <AppBar
                postion="static"
                color="default"
                elevation={0}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.leftHeader}>
                        <IconButton
                            className={classes.menuButton}
                            onClick={
                                open ? handleDrawerClose : handleDrawerOpen
                            }
                        >
                            <MenuIcon />
                        </IconButton>
                        <SystemCart pathname={location.pathname} />
                    </div>
                    {location.pathname === '/' && (
                        <ProfileCard
                            title={`${
                                profile.firstName ? profile.firstName : ''
                            } ${profile.lastName ? profile.lastName : ''}`}
                            subTitle={profile.jobName ? profile.jobName : ''}
                            image={`${
                                profile.empPicture
                            }?access_token=${getTokenLocalStore()}`}
                        />
                    )}
                </Toolbar>
            </AppBar>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    authenRdc: state.authen,
});

export default connect(mapStateToProps, null)(withRouter(Header));
