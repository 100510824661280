import React, { Fragment } from 'react'
import { Field } from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import TextFields from '../../../../control/textfield/TextFields'
import Button from '../../../../control/button/Button'

import * as ForceUpdateConstants from '../../../../constants/maintenance/ForceUpdateConstants'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '24px',
        width: '50%',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('md')]: {
            width: '80%'
        }
    },
    gridTitle: {
        marginBottom: '10px'
    },
    title: {
        fontSize: '33px'
    },
    gridLabel: {
        color: theme.mainTheme.color1
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    }
}))

const ForceUpdateEditView = ({
    editData,
    onClickClose,
    onClickSubmit
}) => {

    const classes = useStyles()

    return (
        <Fragment>
            <Modal
                className={classes.modal}
                open={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Grid container className={classes.root}>
                    <Grid item xs={12} className={classes.gridTitle}>
                        <span className={classes.title}>{ForceUpdateConstants.TITLE}</span>
                    </Grid>

                    <Grid item xs={12}>
                        <span className={classes.gridLabel}>{ForceUpdateConstants.PLATFORM}: </span> {editData.platform}
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            component={TextFields}
                            name={ForceUpdateConstants.VERSION}
                            label={ForceUpdateConstants.VERSION}
                            placeholder={editData.platform}
                            required={true}
                            maxLength={10}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justify='center' spacing={2}>
                            <Grid item>
                                <Button
                                    label='ยกเลิก'
                                    className={classes.btnCancel}
                                    onClick={onClickClose}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    label='บันทึก'
                                    onClick={onClickSubmit}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Modal>
        </Fragment>
    )
}

export default ForceUpdateEditView