//-------------------------- table ---------------------------------
const TABLE = {
    TITLE: {
        EMP_ID: 'รหัสพนักงาน',
        NAME: 'ชื่อ - นามสกุล',
        USER_GROUP_NAME: 'กลุ่มผู้ใช้งาน',
        PERMISSION_ORG: 'สิทธิ์การเข้าถึงหน่วยงาน',
        ORG_NAME: 'หน่วยงาน',
        DEP_NAME: 'ฝ่าย',
    },
    DATA: {
        EMP_ID: 'empId',
        NAME: 'name',
        USER_GROUP_NAME: 'userGroupName',
        PERMISSION_ORG: 'permissionOrgName',
        ORG_NAME: 'orgName',
        DEP_NAME: 'depName',
    },
};

export const TABLE_COLUMN = [
    {
        title: TABLE.TITLE.EMP_ID,
        data: TABLE.DATA.EMP_ID,
        width: '10%',
        sort: 'asc',
    },
    {
        title: TABLE.TITLE.NAME,
        data: TABLE.DATA.NAME,
        width: '20%',
        sort: 'asc',
        align: 'left',
    },
    {
        title: TABLE.TITLE.ORG_NAME,
        data: TABLE.DATA.ORG_NAME,
        width: '25%',
        sort: 'asc',
        align: 'left',
    },
    {
        title: TABLE.TITLE.DEP_NAME,
        data: TABLE.DATA.DEP_NAME,
        width: '25%',
        sort: 'asc',
        align: 'left',
    },
    {
        title: TABLE.TITLE.USER_GROUP_NAME,
        data: TABLE.DATA.USER_GROUP_NAME,
        width: '20%',
        sort: 'asc',
        align: 'left',
    },
];

//----------------------------------- page -----------------------------------

export const PAGE = {
    USER: 'USER',
    EDIT_USER: 'EDIT_USER',
    ADD_USER: 'ADD_USER',
};

export const TITLE = {
    INSERT: 'เพิ่มผู้ใช้งาน',
    EDIT: 'แก้ไขผู้ใช้งาน',
};
