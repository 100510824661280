import React, { Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { SYSTEM } from './constants/APDConstants';

import PortalPage from './pages/Portal';
import LeavePage from './pages/Leave';
import MeetingPage from './pages/Meeting';
import RegistrationPage from './pages/Registration';
import UmPage from './pages/Um';
import MaintenancePage from './pages/Maintenance';
import EmployeeProfileManagementPage from './pages/employee-profile-management';

const routes = [
    {
        systemId: 0,
        path: '/',
        component: PortalPage,
        exact: true,
    },
    {
        systemId: SYSTEM.LEAVE.id,
        path: SYSTEM.LEAVE.path,
        component: LeavePage,
    },
    {
        systemId: SYSTEM.MEETING.id,
        path: SYSTEM.MEETING.path,
        component: MeetingPage,
    },
    {
        systemId: SYSTEM.REGISTRATION.id,
        path: SYSTEM.REGISTRATION.path,
        component: RegistrationPage,
    },
    {
        systemId: SYSTEM.UM.id,
        path: SYSTEM.UM.path,
        component: UmPage,
    },
    {
        systemId: SYSTEM.MAINTENANCE.id,
        path: SYSTEM.MAINTENANCE.path,
        component: MaintenancePage,
    },
    {
        systemId: SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.id,
        path: SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.path,
        component: EmployeeProfileManagementPage,
    },
];

function Page({ authenRdc }) {
    const systemProfileID = authenRdc.profile.systems.map(
        (system) => system.systemId
    );
    const systemList = routes.filter(
        (route) =>
            systemProfileID.indexOf(route.systemId) !== -1 ||
            route.systemId === 0
    );

    return (
        <Fragment>
            <Switch>
                {systemList.map((page, index) => (
                    <Route
                        exact={page.exact}
                        path={page.path}
                        key={`route_${index}`}
                        component={page.component}
                    />
                ))}
                <Route component={() => <Redirect to="/" />} />
            </Switch>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    authenRdc: state.authen,
});

export default connect(mapStateToProps, null)(Page);
