import { convertDateDisplay, displayDateTimeRange } from '../../../../../utilities/DatetimeUtils';

import { flatten, sortBy } from 'lodash';
import moment from 'moment';

export const formatResult = (data) => {
    const newFormatResult = data.map((item, index) => {
        const newItem = {
            bookingId: item.bookingId,
            dateRange: displayDateTimeRange(item.startDate, item.endDate, item.startTime, item.endTime),
            topic: item.topic,
            roomName: item.roomNameTH,
            floor: item.floor,
            building: item.building,
            district: item.meaDistrictNameTH,
            chairman: item.chairman ? item.chairman : '-',
            numberOfAttendees: item.numberOfAttendees ? item.numberOfAttendees : '-',
            empName: `${item.empNameTH} (${item.empId})`,
            empOrgName: (item.deptOrgFullNameTH && item.deptOrgFullNameTH !== '') ? item.deptOrgFullNameTH : item.empOrgNameTH,//item.empOrgNameTH,
            tel: `${item.telInternalPrefix}-${item.telInternalSuffix}`,
        };

        return newItem;
    });
    const dataFormat = flatten(newFormatResult);
    const sortData = sortBy(dataFormat, 'dateBooking', 'timeBooking');
    return sortData;
};

export const formatCancelBookingService = ({ dataDate, reason, empId }) => {
    const newFormat = dataDate.map(item => {
        const newItem = {
            bookingId: item.bookingId,
            date: item.dateOld
        };

        return newItem;
    });

    const result = {
        dates: newFormat,
        reason: reason,
        empId: empId
    };

    return result;
};

export const isMoreThanPresent = (date) => {
    const presentDate = new Date(new Date().setDate(new Date().getDate() - 1))
    const dataDate = new Date(date.replace(/-/g, "/"))

    const isMoreThan = presentDate <= dataDate;

    return isMoreThan
}

export const formatResultTable = (data, profile, valuesSearch) => {
    const newFormatResult = data.map((item, index) => {

        const isMaintainer = item.maintainers.filter(
            itemMain => itemMain.empId === profile.empId
        ).length > 0

        const isMoreThan = isMoreThanPresent(item.endDate.slice(0, 10));

        const newItem = {
            bookingId: item.bookingId,
            dateBooking: moment(`${item.startDate} ${item.startTime}`),
            dateTime: displayDateTimeRange(item.startDate, item.endDate, item.startTime, item.endTime),
            timeBooking: `${item.startTime} - ${item.endTime} น.`,
            topic: item.topic,
            topicDisplay:
                item.topic.length > 35
                    ? `${item.topic.slice(0, 35)}...`
                    : item.topic,
            roomName: item.roomNameTH,
            chairman: item.chairman ? item.chairman : '-',
            numberOfAttendees: item.numberOfAttendees
                ? item.numberOfAttendees
                : '-',
            numberOfAttendeesSort: item.numberOfAttendees
                ? item.numberOfAttendees
                : 0,
            empName: `${item.empNameTH} (${item.empId})`,
            emp: `${item.empNameTH} (${
                item.deptOrgId
                    ? item.deptOrgShortNameTH
                    : item.empOrgShortNameTH
                }) ${
                item.telInternalPrefix && item.telInternalSuffix
                    ? `${item.telInternalPrefix}-${item.telInternalSuffix}`
                    : ''
                } `,

            startDateTime: `${convertDateDisplay(item.dates[0])} เวลา ${
                item.startTime
                } น.`,
            endDateTime: `${convertDateDisplay(
                item.dates[item.dates.length - 1]
            )} เวลา ${item.endTime} น.`,

            empOrgName: (item.deptOrgFullNameTH && item.deptOrgFullNameTH !== '') ? item.deptOrgFullNameTH : item.empOrgNameTH,//item.empOrgNameTH,
            tel: `${item.telInternalPrefix}-${item.telInternalSuffix}`,
            createDate: `${item.createDate.length > 0 &&
                convertDateDisplay(
                    item.createDate
                )} เวลา ${item.createDate.slice(
                    11,
                    item.createDate.length
                )} น.`,
            modifyEmp: `${item.modifyByEmpNameTH} (${item.modifyByEmpId})`,
            modifyDate: `${convertDateDisplay(
                item.modifyDate
            )} เวลา ${item.modifyDate.slice(
                11,
                item.modifyDate.length
            )} น.`,
            district: item.meaDistrictNameTH,
            building: item.building,
            floor: item.floor,
            seat: item.seat,
            bookingWeekend: item.bookingWeekend,
            equipment: item.equipments,
            description:
                item.roomDescription.length > 0
                    ? item.roomDescription
                    : '-',
            orgName: item.roomOrgNameTH,
            maintainer: item.maintainers,
            contact: item.roomContact.length > 0 ? item.roomContact : '-',

            detail: isMaintainer,
            delete: isMaintainer && isMoreThan
        };

        return newItem;
    });

    const dataFormat = flatten(newFormatResult);
    const sortData = sortBy(dataFormat, 'dateBooking', 'timeBooking');

    return sortData;
};