import React, { useState, createContext } from 'react'
import { connect } from 'react-redux'

import { SUB_FUNCTION } from '../../../../constants/APDConstants'

import UseDataTableState from '../../../../control/table/UseDataTableState'
import UseLutUserGroupState from './useUserGroupState/UseLutUserGroupState'
import UseLoadingState from '../../../../control/loading/UseLoadingState'
import UseUserGroupState from './useUserGroupState/UseUserGroupState'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import { PermissionSubFuncUtils } from '../../../../utilities/PermissionUtils'
import { getDepId } from '../../../../utilities/Utils'

export const UserGroupContext = createContext()

const UserGroupProvider = ({ authenRdc, children }) => {
    const { permissionOrgId, empId } = authenRdc.profile
    const [listUserGroup, setListUserGroup] = useState([])
    const [txtSearch, setTxtSearch] = useState()
    const [isReSearch, setResearch] = useState(false)
    const rowPerPage = 10
    const dataTableState = UseDataTableState({ rowPerPage: rowPerPage })
    // เอาค่า div มาเช็คด้วยเพราะ สมส จะเป็น div
    const lutUserGroupState = UseLutUserGroupState({ permissionOrgId, depId: getDepId(authenRdc.profile) })
    const loadingState = UseLoadingState()
    const alertMessageState = UseAlertMessageState();
    const userGroupState = UseUserGroupState()
    const permissionSystem = {
        permisAdd: PermissionSubFuncUtils(SUB_FUNCTION.ADD_USER_GROUP),
        permisEdit: PermissionSubFuncUtils(SUB_FUNCTION.EDIT_USER_GROUP),
        permisDelete: PermissionSubFuncUtils(SUB_FUNCTION.DELETE_USER_GROUP)
    }

    const value = {
        isReSearch,
        empId,
        userGroupState,
        listUserGroup,
        txtSearch,
        permissionSystem,
        loadingState,
        dataTableState,
        alertMessageState,
        lutUserGroupState,
        profile: authenRdc.profile,

        setListUserGroup,
        setTxtSearch,
        setResearch
    }

    return (
        <UserGroupContext.Provider value={value} >
            {children}
        </UserGroupContext.Provider>
    )
}

const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default connect(mapStateToProps)(UserGroupProvider)

