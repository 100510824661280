import React, { useContext } from 'react';

import { getBookingByCon } from '../../../../../api/ApiBooking';
import { BookingByDateContext } from '../BookingByDateProvider';
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'
import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'

import BookingByDateSearch from './BookingByDateSearch';
import BookingByDateResult from './BookingByDateResult';
import BookingByDateView from '../../../presentational/bookingByDate/BookingByDateView';

const BookingByDate = props => {
    const { bookingState, loadingState, alertMessageState } = useContext(BookingByDateContext);

    const handleSearch = params => {
        loadingState.showLoading();
        let dateFM = params.date;

        if (params.date) {
            params.date = `${params.date.getFullYear()}-${params.date.getMonth() + 1}-${params.date.getDate()}`;
        }

        let stringFormat = '';
        for (const key in params) {
            if (params[key]) {
                stringFormat = `${stringFormat}&${key}=${params[key]}`;
            }
        }
        const stringParams = stringFormat.slice(1, stringFormat.length);
        params.date = dateFM;

        getBookingByCon(stringParams).then(res => {
            loadingState.hideLoading();
            if (res && res.data) {
                bookingState.setResult(res.data);
            } else {
                const msg = checkResStatusService(res)
                alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
            }
        });
        bookingState.setValueSearch(params);
    };

    return (
        <BookingByDateView>
            <BookingByDateSearch callbackSearch={handleSearch} />
            <BookingByDateResult />
        </BookingByDateView>
    );
};

export default BookingByDate;
