import React, { useEffect, useState, useContext } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { BookingByDateContext } from '../BookingByDateProvider';

import BookingByDateSearchView from '../../../presentational/bookingByDate/BookingByDateSearchView';

import * as BookingByDateConstants from '../../../../../constants/meeting/BookingByDateConstants';
import BookingByDateExportAll from '../BookingByDateExportAll';
import { convertDateDisplay } from '../../../../../utilities/DatetimeUtils';

let timeOut;

const BookingByDateSearch = props => {
    const { initialize, dateRedux, change, callbackSearch, profile } = props;
    const { bookingState } = useContext(BookingByDateContext);
    const { DATE, PREV, NOW, NEXT } = BookingByDateConstants;

    const [selectBtn, setSelectBtn] = useState(true);
    const minMaxDate = {
        min: new Date('2018/01/01'),
        max: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    };

    useEffect(() => {
        initialize({
            [BookingByDateConstants.DATE]: new Date(),
            [BookingByDateConstants.FILTER_FORM.NAME.SOURCE]: BookingByDateConstants.SOURCE.all
        });
        callbackSearch({
            ...bookingState.valueSearch,
            date: new Date()
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //--------------------- control date's value in redux form ----------------//
    const formatDateForSet = number => {
        const newDate = JSON.parse(JSON.stringify(dateRedux));
        const useDate = new Date(newDate).setDate(
            new Date(newDate).getDate() + number
        );

        change(DATE, new Date(useDate));
        checkDateNow(new Date(useDate));
        // s(false);
        callbackSearch({
            ...bookingState.valueSearch,
            date: new Date(useDate)
        });
    };

    //------------------ control button with datePicker --------------------//
    const handleDate = type => {
        switch (type) {
            case PREV:
                formatDateForSet(-1);
                break;
            case NOW:
                change(DATE, new Date());
                setSelectBtn(true);
                callbackSearch({
                    ...bookingState.valueSearch,
                    date: new Date()
                });
                break;
            case NEXT:
                formatDateForSet(+1);
                break;

            default:
                change(DATE, new Date());
                formatDateForSet();
                break;
        }
    };

    //--------------- check date -------------------//
    const checkDateNow = date => {
        const conDate = new Date();
        const dateChange = `${date.getFullYear()}/${date.getMonth() +
            1}/${date.getDate()}`;
        const dateNow = `${conDate.getFullYear()}/${conDate.getMonth() +
            1}/${conDate.getDate()}`;

        if (dateChange === dateNow && selectBtn !== NOW) {
            setSelectBtn(true);
        } else if (dateChange !== dateNow) {
            setSelectBtn(false);
        }
    };

    //----------------- control datePicker ------------------------//
    const handleDateChange = date => {
        checkDateNow(date);

        callbackSearch({
            ...bookingState.valueSearch,
            date: date
        });
    };

    const handleTextChange = value => {
        if (timeOut) {
            clearTimeout(timeOut);
        }

        if (value === '') {
            timeOut = setTimeout(() => {
                callbackSearch({
                    ...bookingState.valueSearch,
                    floor: null
                });
            }, 500);
        } else {
            timeOut = setTimeout(() => {
                callbackSearch({
                    ...bookingState.valueSearch,
                    floor: value
                });
            }, 500);
        }
    };

    const handleDropdownChange = value => {
        if (value && value.length) {
            callbackSearch({
                ...bookingState.valueSearch,
                building: value.map(v => v.building).join('|')
            });
        } else {
            callbackSearch({
                ...bookingState.valueSearch,
                building: null
            });
        }
    };
    const handleChangeMeaDistrictDropdown = (value) => {
        if (value && value.length) {
            callbackSearch({
                ...bookingState.valueSearch,
                meaDistrictId: value.map(v => v.id).join('|')
            });
        } else {
            callbackSearch({
                ...bookingState.valueSearch,
                meaDistrictId: null
            });
        }

    }

    const handleExportAll = () => {
        const dataExport = bookingState.resultSearch
        const { date } = bookingState.valueSearch;
        const data = dataExport.map(item => {
            item.date = convertDateDisplay(
                `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            );
            item.meaDistrictName = item.bookingList[0].meaDistrictName
            item.bookingList.map(itemList => {
                itemList.modify = `${itemList.dateModify} เวลา ${itemList.timeModify}`;

                return itemList;
            });

            return item;
        });
        const reportDate = convertDateDisplay(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`); //วีนที่ของรายงาน
        BookingByDateExportAll(data, reportDate);
    };
    const handleChangeSource = value => {
        if (value === BookingByDateConstants.SOURCE.maintainer) {
            callbackSearch({
                ...bookingState.valueSearch,
                empId: profile.empId
            });
        } else {
            callbackSearch({
                ...bookingState.valueSearch,
                empId: null
            });
        }
    }

    return (
        <BookingByDateSearchView
            select={selectBtn}
            minDate={minMaxDate.min}
            maxDate={minMaxDate.max}
            lutBuilding={bookingState.lut.lutBuilding}
            lutMeaDistrict={bookingState.lut.lutMeaDistrict}

            onBtnClick={handleDate}
            onDateChange={handleDateChange}
            onDropdownChange={handleDropdownChange}
            onTextChange={handleTextChange}
            onChangeMeaDistrictDropdown={handleChangeMeaDistrictDropdown}
            onExportAll={handleExportAll}
            onChangeSource={handleChangeSource}
        />
    );
};

const selector = formValueSelector('BookingByDateSearch');

const reduxFormParameter = {
    form: 'BookingByDateSearch'
};

const mapStateToProps = state => ({
    dateRedux: selector(state, BookingByDateConstants.DATE),
    profile: state.authen.profile
});

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps, null)
)(BookingByDateSearch);
