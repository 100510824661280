import React, { useState } from 'react'
import Flatpickr from 'react-flatpickr';
import { Thai } from 'flatpickr/dist/l10n/th.js'

import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'

import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import { getYear, addYears } from 'date-fns';

import yearDropdownPlugin from './YearDropdownPlugin'
import FormHelperText from '@material-ui/core/FormHelperText';

import './Themeflat.css'

const UseStyles = makeStyles(theme => ({
    textField: {
        [`& input`]: {
            height: theme.field.height,
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        width: '100%',
        boxShadow: theme.field.boxShadow
    },
    formHelper: {
        color: theme.mainTheme.color5,
        marginTop: '1px',
        marginLeft: '10px'
    },
    label: {
        color: theme.mainTheme.color1
    },
    error: {
        color: theme.mainTheme.color5
    },
    badge: {
        top: '25px',
        right: '14px',
        fontSize: '12px'
    },
    icon: {
        color: theme.mainTheme.color6,
        width: '25px',
        height: '25px'
    },
    asterisk: {
        color: '#FF0000'
    },
    inputFlat: {
        width: '100%',
        borderRadius: '4px',
        height: '40px',
        border: '1px solid #cbcbcb',
        padding: '0px 15px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 7px 0px',
        '&:hover': {
            border: '1px solid #000000',
        },
        '&:focus': {
            outlineColor: 'unset',
            outline: 'unset',
            border: `2px solid ${theme.mainTheme.color1}`
        }
    },
    inputFlatErr: {
        width: '100%',
        borderRadius: '4px',
        height: '40px',
        border: `1px solid ${theme.mainTheme.color5}`,
        padding: '0px 15px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 7px 0px',
        '&:hover': {
            border: '1px solid #000000',
        },
        '&:focus': {
            outlineColor: 'unset',
            outline: 'unset',
            border: `2px solid ${theme.mainTheme.color5}`
        }
    }
}));

const Dateflat = ({
    label,
    input,
    required,
    meta: { error, touched, invalid, active, submitFailed },
    placeholder = 'วัน/เดือน/ปี',
    notClear = false,
    onDateChange,
    pinDay,
    dateDup,
    changeYear,
    id,
    rightMos = false
}) => {

    const classes = UseStyles()
    const asterisk = required ? (
        <span className={classes.asterisk}> *</span>
    ) : (
            ''
        );
    const isError = touched && invalid && error ? true : false;

    const [date, setDate] = useState()
    const pin = pinDay && `${pinDay.date}`
    const pinDate = pin && new Date(new Date(pin).setHours('00'))

    if (input.value !== '' && !date) {
        setDate(input.value)
    } else if (input.value !== "" && input.value !== date) {
        setDate(input.value)
    }

    if (input.value === '' && date) {
        setDate()
    }

    const handleChange = (dt) => {
        if (dt[0]) {
            input.onChange(dt[0])
            setDate(dt[0])
            onDateChange && onDateChange(dt[0])
        } else {
            input.onChange('')
            setDate('')
        }

    }

    const convertYearToBD = (dt) => {
        return addYears(dt, 543)
    }

    const returnDateDup = () => {
        if (pinDate) {
            const tD = dateDup.filter(item => item.toString() !== pinDate.toString())
            const formatArr = tD.map(item => {
                return new Date(item)
            })

            return formatArr
        } else {
            const formatArrE = dateDup.map(item => {
                return new Date(item)
            })

            return formatArrE
        }
    }

    const onChangeYear = (evt) => {
        changeYear(evt)
    }

    const options = {
        plugins: notClear ?
            [
                new yearDropdownPlugin({
                    date: Array.isArray(date) ? date[0] : date,
                    yearStart: new Date(date).getFullYear() - 10,
                    yearEnd: new Date(date).getFullYear() + 10,
                    name: id
                })
            ]
            :
            [
                new yearDropdownPlugin({
                    date: Array.isArray(date) ? date[0] : date,
                    yearStart: new Date(date).getFullYear() - 10,
                    yearEnd: new Date(date).getFullYear() + 10,
                    name: id
                }),
                ShortcutButtonsPlugin({
                    button: {
                        label: 'ล้างค่า',
                    },
                    onClick: (index, fp) => {
                        fp.clear();
                        fp.close()
                    }
                })
            ]
        ,
        enableTime: false,
        dateFormat:
            date
                ? `d M ${getYear(convertYearToBD(date))}`
                : `d M YY`
        ,
        time_24hr: true,
        locale: Thai,
        static: true,
        disable: dateDup ? returnDateDup() : [],
        disableMobile: "true",
        onYearChange: function (selectedDates, dateStr, instance) {
            dateDup && onChangeYear(instance.currentYear)
        },
        onDayCreate: function (dObj, dStr, fp, dayElem) {
            if (pinDay || dateDup) {
                if (dateDup.includes(dayElem.dateObj.toString())) {
                    if (pinDate && pinDate.toString() === dayElem.dateObj.toString()) {
                        dayElem.innerHTML += `<i class="fa fa-thumb-tack" name="icon"></i>`
                    } else {
                        dayElem.innerHTML += `<i class="fa fa-check" name="icon"></i>`
                    }
                }
            }
        },
        onOpen: [
            function (selectedDates, dateStr, instance) {
                if (selectedDates && selectedDates.length > 0) {
                    if (instance.yearSelectContainer) {
                        document.getElementById(id).value = selectedDates[0].getFullYear()
                    }
                    instance.changeMonth(selectedDates[0].getMonth(), false)
                    instance.changeYear(selectedDates[0].getFullYear())
                } else {
                    let currentYear = new Date()
                    if (instance.yearSelectContainer && document.getElementById(id)) {
                        document.getElementById(id).value = currentYear.getFullYear()
                    }
                    instance.changeMonth(currentYear.getMonth(), false)
                    instance.changeYear(currentYear.getFullYear())
                }
            }
        ]
    }

    if (rightMos) {
        const getParentEle = document.getElementById(id)
        if (getParentEle) {
            const ele = getParentEle.getElementsByClassName('flatpickr-calendar')
            if (ele && ele.length > 0) {
                const parentWidth = getParentEle.offsetWidth
                const eleWidth = ele[0].offsetWidth

                if (parentWidth < eleWidth) {
                    ele[0].style = 'right: 0px'
                }

            }
        }
    }

    return (
        <div id='source' style={{ height: '90px' }} id={id}>
            <label
                className={classnames(classes.label, {
                    [classes.error]: isError
                })}
            >
                {label}
                {asterisk}
            </label>
            <Flatpickr
                id={`flat_${id}`}
                data-enable-time
                value={date}
                options={options}
                onChange={handleChange}

                render={({ defaultValue }, ref) => {
                    return (
                        <div>
                            <input className={touched && invalid && error ? classes.inputFlatErr : classes.inputFlat} value={date} ref={ref} placeholder={placeholder} placeholder={'วัน/เดือน/ปี'} />
                        </div>
                    )
                }} />

            <FormHelperText className={classes.formHelper}>
                {touched && invalid && error}
            </FormHelperText>
        </div>

    )
}

export default Dateflat

