export const LUT_ACTION = [
    { id: false, name: 'ตรวจสอบสำเร็จ' },
    { id: true, name: 'ส่ง Transaction นี้ใหม่' }
];

export const KEY_RESPONSE_MESSAGE = [
    'โควต้า 01ไม่พอสำหรับการมาทำงาน/หยุดงาน',
    'โควต้า 02ไม่พอสำหรับการมาทำงาน/หยุดงาน',
    'โควต้า 03ไม่พอสำหรับการมาทำงาน/หยุดงาน',
    'โควต้า 04ไม่พอสำหรับการมาทำงาน/หยุดงาน',
    'โควต้า 05ไม่พอสำหรับการมาทำงาน/หยุดงาน',
    'โควต้า 06ไม่พอสำหรับการมาทำงาน/หยุดงาน',
    'ไม่มีโควต้าอยู่สำหรับการมาทำ/หยุดงาน',
    'ประเภทการมาปฏิบัติงาน/หยุดงาน L204ต้องการจำนวนต่ำสุดของ วันตามปฏิทิน 30',
    'ไม่มีการป้อนข้อมูลนำเข้าแบบแบทช์สำหรับจอภาพ SAPMP50A 1000',
    'ไม่สามารถแทรกได้เนื่องจากซ้ำซ้อนกัน',
    'ไม่มีโควต้าอยู่สำหรับการมาทำ/หยุดงาน L503 ส่วนที่เกิน 30 วัน',
    'ไม่มีโควต้าอยู่สำหรับการมาทำ/หยุดงาน L616 ส่วนที่เกิน 82 วัน',
    'ไม่มีการเก็บข้อมูลสำหรับ การหยุดงาน ในช่วงเวลาที่เลือก',
    'sap ไม่คืนผลลัพธ์กลับมา'
];

export const RESULT_SAP = {
    TITLE: 'ผลการนำเข้า SAP ล่าสุด',
    UNIT: {
        ITEM: 'รายการ',
        DATE: 'วัน'
    },
    LABEL: {
        ALL: 'จำนวนข้อมูลที่นำเข้า SAP',
        LEAVE_NAME: 'ประเภทการลา',
        ABSENCE_ID: 'เลขที่ใบลา',
        LEAVE_COUNT: 'จำนวนวันลา',
        SAP_SUCCESS: 'นำเข้าสำเร็จ',
        SAP_ERROR: 'นำเข้าไม่สำเร็จ',
        SAP_CANCEL: 'ยกเลิกการส่ง'
    },
    FORM: {
        LABEL: {
            LEAVE_SAP_TRANS_TYPE: 'สถานะการส่ง SAP'
        },
        NAME: {
            LEAVE_SAP_TRANS_TYPE: 'leaveSapTransType'
        }
    },
    TABLE: {
        DATA: {
            TRANSACTION_ID: 'transactionId',
            LEAVE_DATE: 'leaveDate',
            ENTRANCE_SAP_DATE: 'submitDate',
            LEAVE_SAP_TYPE: 'leaveSapType',
            LEAVE_SAP_TRAN_TYPE: 'leaveSapTranType',
            SAP_RESPONSE_MSG: 'sapResponseMsg',
            REASON: 'reason',
            HISTORY_SAP: 'historySap'
        },
        TITLE: {
            TRANSACTION_ID: 'รหัสนำเข้า SAP',
            LEAVE_DATE: 'วันที่ลา',
            ENTRANCE_SAP_DATE: 'วันที่นำเข้า SAP',
            LEAVE_SAP_TYPE: 'ประเภทการลา',
            LEAVE_SAP_TRAN_TYPE: 'ประเภทส่งเข้า SAP',
            SAP_RESPONSE_MSG: 'สถานะส่งเข้า SAP',
            REASON: 'เหตุผลการส่งใหม่',
            HISTORY_SAP: 'ประวัติ'
        },
    },
}

export const SAP_COLUMN = [
    { title: RESULT_SAP.TABLE.TITLE.TRANSACTION_ID, data: RESULT_SAP.TABLE.DATA.TRANSACTION_ID, width: '10%', sort: 'asc' },
    { title: RESULT_SAP.TABLE.TITLE.LEAVE_DATE, data: RESULT_SAP.TABLE.DATA.LEAVE_DATE, width: '15%', sort: 'asc' },
    { title: RESULT_SAP.TABLE.TITLE.ENTRANCE_SAP_DATE, data: RESULT_SAP.TABLE.DATA.ENTRANCE_SAP_DATE, width: '13%', sort: 'asc' },
    { title: RESULT_SAP.TABLE.TITLE.LEAVE_SAP_TYPE, data: RESULT_SAP.TABLE.DATA.LEAVE_SAP_TYPE, width: '25%', sort: 'asc' },
    { title: RESULT_SAP.TABLE.TITLE.LEAVE_SAP_TRAN_TYPE, data: RESULT_SAP.TABLE.DATA.LEAVE_SAP_TRAN_TYPE, width: '11%', sort: 'asc' },
    { title: RESULT_SAP.TABLE.TITLE.SAP_RESPONSE_MSG, data: RESULT_SAP.TABLE.DATA.SAP_RESPONSE_MSG, width: '11%', sort: 'asc' },
    { title: RESULT_SAP.TABLE.TITLE.REASON, data: RESULT_SAP.TABLE.DATA.REASON, width: '15%', sort: 'asc' },
]



export const RESULT_LEAVE_SAP = {
    TABLE: {
        DATA: {
            TRANSACTION_ID: 'leaveSapTransactionId',
            LEAVE_DATE: 'leaveDateForDisplay',
            ENTRANCE_SAP_DATE: 'submitDateForDisplay',
            LEAVE_TYPE: 'leaveTypeForDisplay',
            LEAVE_SAP_TYPE: 'leaveSapTransactionTypeName',
            SAP_RESPONSE_MSG: 'statusSapForDisplay',
            SAP_MESSAGE: 'responseMessage',
        },
        TITLE: {
            TRANSACTION_ID: 'รหัสนำเข้า SAP',
            LEAVE_DATE: 'วันที่ลา',
            ENTRANCE_SAP_DATE: 'วันที่นำเข้า SAP',
            LEAVE_TYPE: 'ประเภทการลา',
            LEAVE_SAP_TYPE: 'ประเภทส่งเข้า SAP',
            SAP_RESPONSE_MSG: 'สถานะส่งเข้า SAP',
            SAP_MESSAGE: 'ข้อความจาก SAP',
        },
    },
}

export const LEAVE_SAP_DETAIL_COLUMN = [
    { title: RESULT_LEAVE_SAP.TABLE.TITLE.TRANSACTION_ID, data: RESULT_LEAVE_SAP.TABLE.DATA.TRANSACTION_ID, width: '10%', sort: 'asc' },
    { title: RESULT_LEAVE_SAP.TABLE.TITLE.LEAVE_DATE, data: RESULT_LEAVE_SAP.TABLE.DATA.LEAVE_DATE, width: '15%', sort: 'asc' },
    { title: RESULT_LEAVE_SAP.TABLE.TITLE.ENTRANCE_SAP_DATE, data: RESULT_LEAVE_SAP.TABLE.DATA.ENTRANCE_SAP_DATE, width: '15%', sort: 'asc' },
    { title: RESULT_LEAVE_SAP.TABLE.TITLE.LEAVE_TYPE, data: RESULT_LEAVE_SAP.TABLE.DATA.LEAVE_TYPE, width: '15%', sort: 'asc' },
    { title: RESULT_LEAVE_SAP.TABLE.TITLE.LEAVE_SAP_TYPE, data: RESULT_LEAVE_SAP.TABLE.DATA.LEAVE_SAP_TYPE, width: '10%', sort: 'asc' },
    { title: RESULT_LEAVE_SAP.TABLE.TITLE.SAP_RESPONSE_MSG, data: RESULT_LEAVE_SAP.TABLE.DATA.SAP_RESPONSE_MSG, width: '10%', sort: 'asc' },
    { title: RESULT_LEAVE_SAP.TABLE.TITLE.SAP_MESSAGE, data: RESULT_LEAVE_SAP.TABLE.DATA.SAP_MESSAGE, width: '25%', sort: 'asc', align: 'left' },
]

