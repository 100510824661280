import React, { Fragment, useContext } from 'react';

import BookingByDateResultView from '../../../presentational/bookingByDate/BookingByDateResultView';

import { BookingByDateContext } from '../BookingByDateProvider';

import { convertDateDisplay } from '../../../../../utilities/DatetimeUtils';

import BookingByDateExport from '../BookingByDateExport';

const BookingByDateResult = props => {
    const { bookingState } = useContext(BookingByDateContext);

    const handleExportClick = values => {
        const dataExport = bookingState.resultSearch.filter(
            item =>
                item.building === values.building && item.floor === values.floor
        );

        const { date } = bookingState.valueSearch;
        const data = dataExport.map(item => {
            item.date = convertDateDisplay(
                `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            );
            item.meaDistrictName = item.bookingList[0].meaDistrictName
            item.bookingList.map(itemList => {
                itemList.modify = `${itemList.dateModify} เวลา ${itemList.timeModify}`;

                return itemList;
            });

            return item;
        });

        BookingByDateExport(data[0]);
    };

    return (
        <Fragment>
            <BookingByDateResultView
                result={bookingState.resultSearch}
                onBtnClick={handleExportClick}
            />
        </Fragment>
    );
};

export default BookingByDateResult;
