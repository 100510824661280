import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { getTokenLocalStore } from '../../utilities/LocalStorageUtils';
import { SYSTEM } from '../../constants/APDConstants';
import UseAlertMessageState from '../../control/alert/UseAlertMessageState';

import Menu from '../menu/Menu';
import AlertMessage from '../../control/alert/AlertMessage';

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: drawerWidth,
        height: 'calc(100% - 2px)',
    },
    drawer: {
        height: '100%',
        width: drawerWidth,
        flexShrink: 0,
        zIndex: '2000 !important',
    },
    drawerHeader: {
        ...theme.mixins.toolbar,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '260px',
    },
    hamburger: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    profile: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: theme.spacing(2),
        color: '#fff',
        height: 'calc( 100% - 70px )',
        justifyContent: 'space-evenly',
    },
    profileImage: {
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    icon: {
        color: '#fff',
    },
    position: {
        color: theme.mainTheme.color1,
        textShadow: '0 0 3px #000000, 0 0 5px #111111',
    },
    userGroupName: {
        color: 'white',
        textShadow: '0 0 3px #000000, 0 0 5px #111111',
    },
    name: {
        color: 'white',
        textShadow: '0 0 3px #000000, 0 0 5px #111111',
    },
}));

function checkImage(pathname) {
    if (pathname.indexOf(SYSTEM.MEETING.path) !== -1) {
        return `url(${SYSTEM.MEETING.navImage})`;
    } else if (pathname.indexOf(SYSTEM.LEAVE.path) !== -1) {
        return `url(${SYSTEM.LEAVE.navImage})`;
    } else if (pathname.indexOf(SYSTEM.EV.path) !== -1) {
        return `url(${SYSTEM.EV.navImage})`;
    } else if (pathname.indexOf(SYSTEM.REGISTRATION.path) !== -1) {
        return `url(${SYSTEM.REGISTRATION.navImage})`;
    } else if (pathname.indexOf(SYSTEM.UM.path) !== -1) {
        return `url(${SYSTEM.UM.navImage})`;
    } else if (pathname.indexOf(SYSTEM.MAINTENANCE.path) !== -1) {
        return `url(${SYSTEM.MAINTENANCE.navImage})`;
    } else if (
        pathname.indexOf(SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.path) !== -1
    ) {
        return `url(${SYSTEM.EMPLOYEE_PROFILE_MANAGEMENT.navImage})`;
    } else {
        return `url(./side-login.png)`;
    }
}
// function checkImage2(pathname) {
//     if (pathname.indexOf(SYSTEM.MEETING.path) !== -1) {
//         return SYSTEM.MEETING.image
//     } else if (pathname.indexOf(SYSTEM.LEAVE.path) !== -1) {
//         return SYSTEM.LEAVE.image
//     } else if (pathname.indexOf(SYSTEM.EV.path) !== -1) {
//         return SYSTEM.EV.image
//     } else if (pathname.indexOf(SYSTEM.REGISTRATION.path) !== -1) {
//         return SYSTEM.REGISTRATION.image
//     } else if (pathname.indexOf(SYSTEM.UM.path) !== -1) {
//         return SYSTEM.UM.image
//     } else {
//         return `url(./side-login.png)`
//     }
// }

function Nav({ open, handleDrawerClose, authenRdc, location }) {
    const classes = useStyles();
    const alertMessageState = UseAlertMessageState();
    const profile = authenRdc.profile;

    return (
        <Fragment>
            <Drawer
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
                open={open}
                onClose={handleDrawerClose}
            >
                <div
                    className={classes.drawerHeader}
                    style={{ backgroundImage: checkImage(location.pathname) }}
                >
                    <div className={classes.hamburger}>
                        <IconButton onClick={handleDrawerClose}>
                            <MenuIcon className={classes.icon} />
                        </IconButton>
                    </div>
                    <div className={classes.profile}>
                        <div>
                            <img
                                src={`${
                                    profile.empPicture
                                }?access_token=${getTokenLocalStore()}`}
                                alt="img"
                                className={classes.profileImage}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.PUBLIC_URL}/img/profile/no_pic.png`;
                                }}
                            />
                        </div>
                        <div>
                            <Typography
                                className={classes.name}
                                variant="h5"
                            >{`${profile.firstName ? profile.firstName : ''} ${
                                profile.lastName ? profile.lastName : ''
                            }`}</Typography>
                            <Typography
                                className={classes.position}
                                variant="body1"
                            >
                                {profile.jobName ? profile.jobName : ''}
                            </Typography>
                            <Typography
                                className={classes.userGroupName}
                                variant="body1"
                            >
                                {profile.userGroupName
                                    ? profile.userGroupName
                                    : ''}
                            </Typography>
                        </div>
                    </div>
                </div>
                {/* <Divider /> */}
                <div style={{ width: '100%', height: 'calc(100% - 260px)' }}>
                    <Menu
                        onDrawerClose={handleDrawerClose}
                        alertMessageState={alertMessageState}
                    />
                </div>
            </Drawer>
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    authenRdc: state.authen,
});

const enhance = compose(connect(mapStateToProps, null), withRouter);

export default enhance(Nav);
