import { useState } from 'react';

import * as CenterMeetingFunctions from '../../../../../api/ApiMeeting'
import * as ApiLookup from '../../../../../api/ApiLookup'
import { MEETING } from '../../../../../constants/APDConstants'
import { sortObjASC } from '../../../../../control/ManageDataFunctions'

const UseLupMeetingState = ({ depId, permissionOrgId }) => {
    const [lup, setLup] = useState({
        roomType: [],
        equipment: [],
        meaDistrict: [],
        publicityType: [],
        permissionType: [],
        bookingWeekend: MEETING.LUP_BOOKING_WEEKEND,
        org: [],
        emp: [],
        building: [],
        orgTree: [],
        bookingType: [],
        superAdmin: []
    })

    const getLupMeeting = () => {
        const serviceList = [
            CenterMeetingFunctions.callServiceLuRoomType(),
            CenterMeetingFunctions.callServiceLuEquipment(),
            CenterMeetingFunctions.callServiceMEADistrict(),
            CenterMeetingFunctions.callServiceLuPublicityType(),
            CenterMeetingFunctions.callServiceLuBookingType(),
            // CenterMeetingFunctions.callServiceOrgDep(),
            ApiLookup.getLutDep(),
            CenterMeetingFunctions.getLutEmpByPermission({ depId, permissionOrgId }),
            CenterMeetingFunctions.callServiceBuilding(),
            CenterMeetingFunctions.callServiceGetAllOrgtree(),
            CenterMeetingFunctions.callServiceGetSuperAdmin()
        ]

        return Promise.all(serviceList).then(result => {
            setLup({
                ...lup,
                roomType: result[0] ? result[0].data : [],
                equipment: result[1] ? result[1].data : [],
                meaDistrict: result[2] ? result[2].data : [],
                publicityType: result[3] ? result[3].data : [],
                bookingType: result[4] ? result[4].data : [],
                org: result[5] && result[5].data ? sortObjASC(result[5].data, "name") : [],
                emp: result[6] ? result[6].data : [],
                building: result[7] ? result[7].data : [],
                orgTree: result[8] ? result[8].data : [],
                superAdmin: result[9] ? result[9].data : [],
            })
            return result
        })
    }

    return { lup, setLup, getLupMeeting }
}

export default UseLupMeetingState
