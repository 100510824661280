import { useState } from 'react'
import { callServiceGetLutOrg } from '../../../../../api/ApiLeaveCollect'

const UseSearchLeaveCollectState = () => {
    const [valuesSearch, setValuesSearch] = useState(null);
    const [lutResult, setLutResult] = useState([])
    const [lutYear, setLutYear] = useState([])
    const [lutOrg, setLutOrg] = useState([])

    const setInitLutYear = () => {
        const initYear = 2021
        const curYear = new Date().getFullYear()
        const count = curYear - initYear
        const data = []
        for (let i = 0; i <= count; i++) {
            const year = initYear + i;
            data.push({
                id: year,
                name: `${year + 543}`
            })
        }
        setLutYear(data)
        return { data }
    }

    const setInitLutResult = () => {
        const result = {
            data: [
                { id: 0, name: 'นำเข้าไม่สำเร็จ' },
                { id: 1, name: 'นำเข้าสำเร็จ' }
            ]
        }
        setLutResult(result.data)
        return result
    }

    const setInitLutOrg = async () => {
        const result = await callServiceGetLutOrg()
        if (result && result.data) {
            setLutOrg(result.data)
        }
    }

    return {
        valuesSearch,
        lutResult,
        lutOrg,
        lutYear,
        setValuesSearch,
        setLutResult,
        setInitLutResult,
        setInitLutOrg,
        setInitLutYear
    }
}

export default UseSearchLeaveCollectState
