import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { ABSENCE } from '../../../../../constants/APDConstants';
import { checkStatusCancel } from '../../../container/absence/AbsenceUtils';

import TextFields from '../../../../../control/textfield/TextFields';
import UploadMultiFile from '../../../../../control/uploadMultiFile/UploadMultiFile';
import FieldTextDisplayView from './FieldTextDisplayView';
import FieldTextReduxDisplay from './FieldTextReduxDisplay'

const {
    NAME,
    LABEL,
    LEAVE_TYPE_ID,
    ATTACHMENT_SUPPORT,
    MAXIMUM_ATTACHMENT,
    MAXIMUM_SIZE_ATTACHMENT,
    PALCE_CONTACT_ADDRESS,
    PLACE_REASON
} = ABSENCE.FORM_EDIT_ABSENCE;

const styleTextArea = { height: '145px' }
const propsTextArea = { isMultiline: true, row: 2 }

const ContainerField = ({ children, isCancelled = true }) => {
    const style = isCancelled ? {} : styleTextArea

    return (
        <Grid item xs={12} md={6} style={{ padding: '0px 8px', ...style }}>
            {children}
        </Grid>
    );
};

const Sick = ({ initailData, isCancelled, onClickFile }) => {
    //ลาป่วย
    const {
        sickReasonName,
        duration,
        leaveReason,
        inAdvance,
        attachments,
        sickReasonId,
        sickOther
    } = initailData;

    return (
        <Fragment>
            <Grid item xs={12} md={6}>
                <FieldTextDisplayView
                    label={LABEL.SICK_REASON_ID}
                    data={ sickReasonId && sickReasonId.toString() === '13' ? sickOther : sickReasonName}
                />
            </Grid>
            <Grid container style={{ marginTop: '10px' }}>
                {(leaveReason || inAdvance > 0) && (
                    <ContainerField isCancelled={isCancelled}>
                        <Field
                            name={NAME.LEAVE_REASON}
                            label={LABEL.LEAVE_REASON}
                            component={isCancelled ? FieldTextReduxDisplay : TextFields}
                            maxLength={500}
                            placeholder={PLACE_REASON}
                            required={inAdvance > 0}
                            {...propsTextArea}
                        />
                    </ContainerField>
                )}
                <ContainerField isCancelled={isCancelled}>
                    <Field
                        name={NAME.CONTACT_ADDRESS}
                        label={LABEL.CONTACT_ADDRESS}
                        component={isCancelled ? FieldTextReduxDisplay : TextFields}
                        maxLength={500}
                        placeholder={PALCE_CONTACT_ADDRESS}
                        required
                        {...propsTextArea}
                    />
                </ContainerField>
                {(attachments.length || duration > 2) && (
                    <ContainerField>
                        <Field
                            name={NAME.ATTACHMENT}
                            label={LABEL.ATTACHMENT}
                            component={UploadMultiFile}
                            onClickFile={onClickFile}
                            supportFiles={ATTACHMENT_SUPPORT}
                            maximumFile={MAXIMUM_ATTACHMENT}
                            maxSize={MAXIMUM_SIZE_ATTACHMENT}
                            disabled={isCancelled}
                        />
                    </ContainerField>
                )}
            </Grid>
        </Fragment>
    );
};

const Personal = ({ isCancelled }) => {
    //ลากิจส่วนตัว
    return (
        <Grid container style={{ marginTop: '10px' }}>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.LEAVE_REASON}
                    label={LABEL.LEAVE_REASON}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    maxLength={500}
                    placeholder={PLACE_REASON}
                    required
                    {...propsTextArea}
                />
            </ContainerField>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.CONTACT_ADDRESS}
                    label={LABEL.CONTACT_ADDRESS}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    maxLength={500}
                    placeholder={PALCE_CONTACT_ADDRESS}
                    required
                    {...propsTextArea}
                />
            </ContainerField>
        </Grid>
    );
};

const Vacation = ({ initailData, isCancelled }) => {
    //ลาพักผ่อนประจำปี
    const { inAdvance } = initailData;

    return (
        <Grid container style={{ marginTop: '10px' }}>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.LEAVE_REASON}
                    label={LABEL.LEAVE_REASON}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    required={inAdvance < 7}
                    maxLength={500}
                    placeholder={PLACE_REASON}
                    {...propsTextArea}
                />
            </ContainerField>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.CONTACT_ADDRESS}
                    label={LABEL.CONTACT_ADDRESS}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    maxLength={500}
                    placeholder={PALCE_CONTACT_ADDRESS}
                    required
                    {...propsTextArea}
                />
            </ContainerField>
        </Grid>
    );
};

const Maternity = ({ isCancelled, onClickFile }) => {
    //ลาคลอดบุตร
    return (
        <Grid container style={{ marginTop: '10px' }}>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.CONTACT_ADDRESS}
                    label={LABEL.CONTACT_ADDRESS}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    maxLength={500}
                    placeholder={PALCE_CONTACT_ADDRESS}
                    required
                    {...propsTextArea}
                />
            </ContainerField>
            <ContainerField>
                <Field
                    name={NAME.ATTACHMENT}
                    label={LABEL.ATTACHMENT}
                    component={UploadMultiFile}
                    onClickFile={onClickFile}
                    supportFiles={ATTACHMENT_SUPPORT}
                    maximumFile={MAXIMUM_ATTACHMENT}
                    maxSize={MAXIMUM_SIZE_ATTACHMENT}
                    disabled={isCancelled}
                    required
                />
            </ContainerField>
        </Grid>
    );
};

const ChildSupport = ({ isCancelled }) => {
    //ลาเพื่อเลี้ยงดูบุตร
    return (
        <Grid container style={{ marginTop: '10px' }}>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.LEAVE_REASON}
                    label={LABEL.LEAVE_REASON}
                    maxLength={500}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    placeholder={PLACE_REASON}
                    required
                    {...propsTextArea}
                />
            </ContainerField>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.CONTACT_ADDRESS}
                    label={LABEL.CONTACT_ADDRESS}
                    maxLength={500}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    placeholder={PALCE_CONTACT_ADDRESS}
                    required
                    {...propsTextArea}
                />
            </ContainerField>
        </Grid>
    );
};

const AssistBirth = ({ initailData, isCancelled, onClickFile }) => {
    //ลาไปช่วยเหลือภริยาที่คลอดบุตร
    const { childName, childBirthDateDisplay, wifeName } = initailData;

    return (
        <Fragment>
            <Grid item xs={12} md={6}>
                <FieldTextDisplayView
                    label={LABEL.CHILD_NAME}
                    data={childName}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FieldTextDisplayView
                    label={LABEL.CHILD_BIRTH_DATE}
                    data={childBirthDateDisplay}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FieldTextDisplayView label={LABEL.WIFE_NAME} data={wifeName} />
            </Grid>
            <Grid container style={{ marginTop: '10px' }}>
                <ContainerField isCancelled={isCancelled}>
                    <Field
                        name={NAME.LEAVE_REASON}
                        label={LABEL.LEAVE_REASON}
                        maxLength={500}
                        component={isCancelled ? FieldTextReduxDisplay : TextFields}
                        placeholder={PLACE_REASON}
                        {...propsTextArea}
                    />
                </ContainerField>
                <ContainerField isCancelled={isCancelled}>
                    <Field
                        name={NAME.CONTACT_ADDRESS}
                        label={LABEL.CONTACT_ADDRESS}
                        maxLength={500}
                        component={isCancelled ? FieldTextReduxDisplay : TextFields}
                        placeholder={PALCE_CONTACT_ADDRESS}
                        required
                        {...propsTextArea}
                    />
                </ContainerField>
                <ContainerField>
                    <Field
                        name={NAME.ATTACHMENT}
                        label={LABEL.ATTACHMENT}
                        component={UploadMultiFile}
                        onClickFile={onClickFile}
                        supportFiles={ATTACHMENT_SUPPORT}
                        maximumFile={MAXIMUM_ATTACHMENT}
                        maxSize={MAXIMUM_SIZE_ATTACHMENT}
                        disabled={isCancelled}
                        required
                    />
                </ContainerField>
            </Grid>
        </Fragment>
    );
};

const Ordination = ({ initailData, isCancelled, onClickFile }) => {
    //ลาอุปสมบท
    const {
        ordinationDateDisplay,
        ordinationHall,
        ordinationAddress,
        temple,
        templeAddress,
        inAdvance
    } = initailData;

    return (
        <Fragment>
            <Grid item xs={12} md={6}>
                <FieldTextDisplayView
                    label={LABEL.ORDINATION_DATE}
                    data={ordinationDateDisplay}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FieldTextDisplayView
                    label={LABEL.ORDINATION_HALL}
                    data={ordinationHall}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FieldTextDisplayView
                    label={LABEL.ORDINATION_ADDRESS}
                    data={ordinationAddress}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FieldTextDisplayView label={LABEL.TEMPLE} data={temple} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FieldTextDisplayView
                    label={LABEL.TEMPLE_ADDRESS}
                    data={templeAddress}
                />
            </Grid>
            <Grid container style={{ marginTop: '10px' }}>
                <ContainerField isCancelled={isCancelled}>
                    <Field
                        name={NAME.LEAVE_REASON}
                        label={LABEL.LEAVE_REASON}
                        maxLength={500}
                        required={inAdvance < 60}
                        placeholder={PLACE_REASON}
                        component={isCancelled ? FieldTextReduxDisplay : TextFields}
                        {...propsTextArea}
                    />
                </ContainerField>
                <ContainerField>
                    <Field
                        name={NAME.ATTACHMENT}
                        label={LABEL.ATTACHMENT}
                        component={UploadMultiFile}
                        onClickFile={onClickFile}
                        supportFiles={ATTACHMENT_SUPPORT}
                        maximumFile={MAXIMUM_ATTACHMENT}
                        maxSize={MAXIMUM_SIZE_ATTACHMENT}
                        disabled={isCancelled}
                        required
                    />
                </ContainerField>
            </Grid>
        </Fragment>
    );
};

const Hajj = ({ initailData, isCancelled, onClickFile }) => {
    //ลาไปประกอบพิธีฮัจย์
    const { inAdvance, attachments } = initailData;

    return (
        <Grid container style={{ marginTop: '10px' }}>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.LEAVE_REASON}
                    label={LABEL.LEAVE_REASON}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    maxLength={500}
                    placeholder={PLACE_REASON}
                    required={inAdvance < 60}
                    {...propsTextArea}
                />
            </ContainerField>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.CONTACT_ADDRESS}
                    label={LABEL.CONTACT_ADDRESS}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    maxLength={500}
                    placeholder={PALCE_CONTACT_ADDRESS}
                    required
                    {...propsTextArea}
                />
            </ContainerField>
            <ContainerField>
                {(attachments.length || inAdvance < 60) && (
                    <Field
                        name={NAME.ATTACHMENT}
                        label={LABEL.ATTACHMENT}
                        component={UploadMultiFile}
                        onClickFile={onClickFile}
                        supportFiles={ATTACHMENT_SUPPORT}
                        maximumFile={MAXIMUM_ATTACHMENT}
                        maxSize={MAXIMUM_SIZE_ATTACHMENT}
                        disabled={isCancelled}
                        required={inAdvance < 60}
                    />
                )}
            </ContainerField>
        </Grid>
    );
};

const WomanOrdination = ({ initailData, isCancelled, onClickFile }) => {
    //สตรีลาไปถือศีลและปฎิบัติธรรม
    const { inAdvance } = initailData;

    return (
        <Grid container style={{ marginTop: '10px' }}>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.LEAVE_REASON}
                    label={LABEL.LEAVE_REASON}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    maxLength={500}
                    placeholder={PLACE_REASON}
                    required={inAdvance < 30}
                    {...propsTextArea}
                />
            </ContainerField>
            <ContainerField isCancelled={isCancelled}>
                <Field
                    name={NAME.CONTACT_ADDRESS}
                    label={LABEL.CONTACT_ADDRESS}
                    component={isCancelled ? FieldTextReduxDisplay : TextFields}
                    maxLength={500}
                    placeholder={PALCE_CONTACT_ADDRESS}
                    required
                    {...propsTextArea}
                />
            </ContainerField>
            <ContainerField>
                <Field
                    name={NAME.ATTACHMENT}
                    label={LABEL.ATTACHMENT}
                    component={UploadMultiFile}
                    onClickFile={onClickFile}
                    supportFiles={ATTACHMENT_SUPPORT}
                    maximumFile={MAXIMUM_ATTACHMENT}
                    maxSize={MAXIMUM_SIZE_ATTACHMENT}
                    disabled={isCancelled}
                    required
                />
            </ContainerField>
        </Grid>
    );
};

const FormByLeaveTypeView = ({ initailData, leaveTypeId, onClickFile }) => {
    const isCancelled = initailData && (checkStatusCancel(initailData.leaveStateId) || initailData.type === "detail")

    switch (leaveTypeId) {
        case LEAVE_TYPE_ID.SICK:
            return <Sick
                isCancelled={isCancelled}
                initailData={initailData}
                onClickFile={onClickFile}
            />
        case LEAVE_TYPE_ID.PERSONAL:
            return <Personal
                isCancelled={isCancelled}
            />;
        case LEAVE_TYPE_ID.VACATION:
            return <Vacation
                initailData={initailData}
                isCancelled={isCancelled}
            />
        case LEAVE_TYPE_ID.MATERNITY:
            return <Maternity
                onClickFile={onClickFile}
                isCancelled={isCancelled}
            />
        case LEAVE_TYPE_ID.CHILD_SUPPORT:
            return <ChildSupport
                isCancelled={isCancelled}
            />;
        case LEAVE_TYPE_ID.ASSIST_BIRTH:
            return <AssistBirth
                initailData={initailData}
                isCancelled={isCancelled}
                onClickFile={onClickFile}
            />
        case LEAVE_TYPE_ID.ORDINATION:
            return <Ordination
                initailData={initailData}
                isCancelled={isCancelled}
                onClickFile={onClickFile}
            />
        case LEAVE_TYPE_ID.HAJJ:
            return <Hajj
                initailData={initailData}
                isCancelled={isCancelled}
                onClickFile={onClickFile}
            />
        case LEAVE_TYPE_ID.WOMAN_ORDINATION:
            return <WomanOrdination
                initailData={initailData}
                isCancelled={isCancelled}
                onClickFile={onClickFile}
            />
        default:
            return <Fragment />;
    }
};

export default FormByLeaveTypeView;
