import React, { createContext } from 'react'

import UseDataTableState from '../../../../control/table/UseDataTableState'
import UseModalState from './useState/UseModalState'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseLoadingState from '../../../../control/loading/UseLoadingState'

export const ConnextContext = createContext()

const ConnextProvider = ({ children }) => {
    const rowPerPage = 10
    const modalState = UseModalState()
    const alertMessageState = UseAlertMessageState()
    const loadingState = UseLoadingState()
    const dataTableState = UseDataTableState({
        rowPerPage: rowPerPage,
        specialColumn: {
            startDateDisplay: 'startDate',
            endDateDisplay: 'endDate',
            displayDateDisplay: 'displayDate'
        }
    })

    const value = {
        dataTableState,
        modalState,
        alertMessageState,
        loadingState
    }

    return (
        <ConnextContext.Provider value={value}>
            {children}
        </ConnextContext.Provider>
    )
}

export default ConnextProvider
