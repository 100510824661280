import { fetchGet, fetchPost, fetchPut, fetchDel_ } from '../utilities/ServiceUtils'
import * as ApiConstants from '../constants/ApiConstants'
import { API } from '../constants/ServiceMSGConstants'

export const callServiceGetHolidayByYear = async (year) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_HOLIDAY}${year}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceGetHolidayByYear : ', err)
        return err
    }
}

export const callServiceGetLutHolidayInfo = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_LU_HOLIDAY_INFO}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex callServiceLutHolidayInfo : ', err)
        return err
    }
}

export const postHolidayInfo = async (params) => {
    try {
        const fetchData = await fetchPost(ApiConstants.SERVICE_ADD_HOLIDAY_INFO, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex postHolidayInfo : ', err)
        return err
    }
}

export const postHoliday = async (params) => {
    try {
        const fetchData = await fetchPost(ApiConstants.SERVICE_ADD_HOLIDAY, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex postHoliday : ', err)
        return err
    }
}

export const putHoliday = async (params, holidayId) => {
    try {
        const fetchData = await fetchPut(`${ApiConstants.SERVICE_EDIT_HOLIDAY}/${holidayId}`, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex putHoliday : ', err)
        return err
    }
}

export const deleteHoliday = async (id, params) => {
    try {
        const fetchData = await fetchDel_(`${ApiConstants.SERVICE_DELETE_HOLIDAY}/${id}`, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex deleteHoliday : ', err)
        return err
    }
}

export const getAllEmp = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_EMP)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getAllEmp :', err)
    }
}

export const getLogAbsence = async (date, holidayId, reason) => {
    const params = holidayId ? `date=${date}&reason=${reason}&refHolidayId=${holidayId}` : `date=${date}&reason=${reason}`
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_LOG_ABSENCE}?${params}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLogAbsence :', err)
    }
}

export const getLogAbsenceByDate = async (date, { permissionOrgId, depId }) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_LOG_ABSENCE_BY_DATE}?date=${date}&permissionOrgId=${permissionOrgId}&depId=${depId}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLogAbsenceByDate :', err)
        return err
    }
}

export const getExportHoliday = async (date) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_LOG_IMPACT_ABSENCE}?date=${date}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getExportHoliday :', err)
        return err
    }
}

export const getYearHoliday = async (date) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_YEAR_HOLIDAY}`)
        if (!fetchData.ok) {
            // throw fetchData.statusText
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getYearHoliday :', err)
        return err
    }
}