import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

// import Button from '../../../../control/button/Button'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '24px'
    },
    rootPaper: {
        ...theme.paperPadding
    },
    btn: {
        width: '225px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))

const ConnextView = ({
    children
}) => {
    const classes = useStyles();

    return (
        <Scrollbars universal={true}>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Paper className={classes.rootPaper}>
                        {children}
                    </Paper>
                </Grid>
            </Grid>
        </Scrollbars>

    )
}

export default ConnextView
