import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import DataTable from '../../../../control/table/DataTable';

const column = [
    { title: 'ปีที่ทำรายการสะสม', data: 'year', width: '10%', sort: 'asc' },
    { title: 'รหัสพนักงาน', data: 'empId', width: '8%', sort: 'asc' },
    {
        title: 'ชื่อ-นามสกุล',
        data: 'empName',
        width: '15%',
        sort: 'asc',
        align: 'left',
    },
    { title: 'หน่วยงาน', data: 'orgShortName', width: '8%', sort: 'asc' },
    {
        title: 'สะสมปีถัดไป',
        data: 'collectNextYear',
        width: '8%',
        sort: 'asc',
        type: 'number',
    },
    {
        title: 'สะสมปีเกษียณ',
        data: 'collectRetire',
        width: '10%',
        sort: 'asc',
        type: 'number',
    },
    { title: 'สถานะการส่ง SAP', data: 'result', width: '12%', sort: 'asc' },
    {
        title: 'วันที่บันทึกใน SAP',
        data: 'sapModifyDate',
        width: '12%',
        sort: 'asc',
        type: 'date',
    },
    {
        title: 'วันที่ตรวจสอบกับ SAP',
        data: 'verifyDate',
        width: '13%',
        sort: 'asc',
        type: 'date',
    },
    {
        title: 'เหตุผล',
        data: 'resultMessage',
        width: '20%',
        sort: 'asc',
        align: 'left',
    },
];

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '33px',
    },
    summary: {
        textAlign: 'end',
        alignSelf: 'flex-end',
    },
    tableCell: {
        height: 'auto',
        padding: '0px 20px',
    },
}));

const LeaveCollectTableView = (props) => {
    const classes = useStyles();
    const { dataTableState } = props;
    const all = dataTableState ? dataTableState.dataTable.length : 0;

    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <span className={classes.title}>
                            ตรวจสอบผลลัพธ์การนำข้อมูลสะสมวันลาเข้า SAP
                        </span>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.summary}>
                        <span>{`พบผลลัพธ์ทั้งหมด ${all} รายการ`}</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    column={column}
                    data={dataTableState.dataPage}
                    page={dataTableState.page}
                    rowPerPage={dataTableState.rowPerPage}
                    total={dataTableState.total}
                    sortColumn={dataTableState.sortColumn}
                    sortType={dataTableState.sortType}
                    classes={{ tableCell: classes.tableCell }}
                    handlePageChange={dataTableState.changePage}
                    handleSortExternal={dataTableState.sortData}
                />
            </Grid>
        </Fragment>
    );
};

export default LeaveCollectTableView;
