import React from 'react';
import { Field } from 'redux-form';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '../../../../control/autocomplete/AutoComplete';
import Button from '../../../../control/button/Button';
import DataTable from '../../../../control/table/DataTable'
import { LEAVE_SAP_DETAIL_COLUMN } from '../../../../constants/leave/LeaveSAPConstants'

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '33px'
    },
    label: {
        color: '#F47B15'
    },
    rootContent: {
        paddingRight: '24px',
    },
    tableClass: {
        textAlign: 'center',
        width: '100%',
        minWidth: '910px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.02) 0px -1px 0px'
    },
    containerDetail: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    btnGrid: {
        textAlign: 'center',
        paddingRight: '24px'
    },
    body: {
        height: 'calc(100% - 120px)',
        marginTop: '-10px'
    },
    btn: {
        marginLeft: '1%',
        marginRight: '1%',
        width: '15%'
    },
    btnCancel: {
        marginLeft: '1%',
        marginRight: '1%',
        width: '15%',
        backgroundColor: `${theme.mainTheme.color6} !important`
    },
    tableCell: {
        minHeight: '48px',
        height: 'auto',
        display: 'inline-flex',
        padding: '10px',
        color: '#5B5B5B',
        fontSize: '18px !important',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        alignItems: 'center',
        background: 'inherit'
    },
    divBtnSearch: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '10px'
        }
    }
}));

const LeaveSAPDetailHistoryView = props => {
    const classes = useStyles();
    const {
        onCancelClick,
        optionsDateSap,
        optionsDateLeave,
        onSubmitDateChange,
        onSearchClick,
        dataTableHistoryLeaveState
    } = props;

    const containerFilter = () => {
        return (
            <Grid container spacing={2} alignItems="center"  >
                <Grid item xs={12} sm={4} >
                    <Field
                        component={Autocomplete}
                        options={optionsDateLeave}
                        label="วันที่ลา"
                        name="dateLeave"
                        optionKey={{ label: 'value', value: 'id', abbr: null }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Field
                        component={Autocomplete}
                        options={optionsDateSap}
                        label="วันที่นำเข้า SAP"
                        name="dateSap"
                        optionKey={{ label: 'value', value: 'id', abbr: null }}
                        onChangeDropDown={onSubmitDateChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.divBtnSearch}>
                    <Button
                        label="ค้นหา"
                        className={classes.btn}
                        onClick={onSearchClick}
                    />
                </Grid>
            </Grid>
        )
    }

    const containerTale = ({ dataTableState, classes }) => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <DataTable
                        column={LEAVE_SAP_DETAIL_COLUMN}
                        data={dataTableState.dataPage}
                        classesTable={classes.tableClass}
                        page={dataTableState.page}
                        rowPerPage={dataTableState.rowPerPage}
                        total={dataTableState.total}
                        sortColumn={dataTableState.sortColumn}
                        sortType={dataTableState.sortType}
                        handlePageChange={dataTableState.changePage}
                        classes={{ tableCell: classes.tableCell }}
                        handleSortExternal={dataTableState.sortData}
                        handleRowPerPage={() => { }}
                        notSort={true}
                    />
                </Grid>
            </Grid>

        )
    }

    return (
        <Grid container justify="center" style={{ height: '100%' }} >
            <Grid item xs={12}>
                <span className={classes.title}>{'ประวัติการนำเข้า SAP'}</span>
            </Grid>
            <Grid
                container
                className={classes.body}
                justify="flex-start"
                alignItems="flex-start"
            >
                <Scrollbars universal={true}>
                    <Grid container className={classes.rootContent}>
                        <Grid item xs={12} >
                            <Grid container>
                                <Grid item xs={12}>
                                    {containerFilter()}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {containerTale({
                                column: null,
                                dataTableState: dataTableHistoryLeaveState,
                                classes,
                            })}
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Grid>


            <Grid
                container
                alignItems="flex-end"
                justify="center"
                className={classes.btnGrid}
            >
                <Button
                    label="ปิด"
                    className={classes.btn}
                    onClick={onCancelClick}
                />

            </Grid>
        </Grid>
    );
};

export default LeaveSAPDetailHistoryView;
