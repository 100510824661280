import  { useState } from 'react'

const UsePanelState = () => {
    const [panelActive, setPanelActive] = useState(0)

    const changePanel = (value) => {
        setPanelActive(value)
    }

    return {
        panelActive,
        changePanel
    }
}

export default UsePanelState
