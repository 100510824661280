import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import UseLoadingState from '../../../../control/loading/UseLoadingState';
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';

import { UM_USER_GROUP } from '../../../../constants/APDConstants';

import FormUserView from '../../presentational/usergroup/FormUserView';
import Loading from '../../../../control/loading/Loading';
import AlertMessage from '../../../../control/alert/AlertMessage';

const { NAME } = UM_USER_GROUP.FORM_USER_GROUP;

const FormUser = ({
    isOpen,
    listEmp,
    lutUserGroupState,
    change,
    profile,
    userGroupId,

    onClose,
}) => {
    const loadingState = UseLoadingState();
    const alertMessageState = UseAlertMessageState();
    const [empList, setEmpList] = useState([]);

    useEffect(() => {
        if (listEmp) {
            const newList = listEmp.map((item) => {
                if (
                    item.empId === profile.empId ||
                    (userGroupId === 1 && listEmp.length === 1)
                ) {
                    item.notDelete = true;
                } else {
                    item.notDelete = false;
                }

                return item;
            });

            setEmpList(newList);
        }
    }, [listEmp]); // eslint-disable-line react-hooks/exhaustive-deps

    const checkDuplicateEmp = (newEmpId) => {
        let isFound = false;
        if (listEmp) {
            isFound = listEmp.some((emp) => emp.empId === newEmpId);
        }
        return isFound;
    };

    const handleChangeEmp = async (value) => {
        console.log(value);
        if (value && !checkDuplicateEmp(value.empId)) {
            const preListEmp = listEmp ? listEmp : [];
            const listEmpUpadate = [
                ...new Set([
                    ...preListEmp,
                    {
                        empId: value.empId,
                        empName: value.empName,
                        uuid: value.uuid,
                        orgId: value.orgIdSAP ? value.orgIdSAP : value.orgId,
                        orgName: value.orgName,
                        orgShortName: value.orgShortName,
                        depId: value.depId,
                        depName: value.depName,
                        depShortName: value.depShortName,
                    },
                ]),
            ];
            change(NAME.LIST_USER, listEmpUpadate);
            change(NAME.SEARCH_EMP, '');
            await lutUserGroupState.deleteLutEmp(value.empId);
        }

        const el = document.getElementById('search-emp');
        if (el) el.blur();
    };

    const handleChangeOrg = async (value) => {
        const orgId = value && value.orgId ? value.orgId : null;
        lutUserGroupState.changeOrg(orgId);
    };

    const onDelete = (indexDelete) => () => {
        const listEmpUpadate = listEmp.filter(
            (emp, index) => index !== indexDelete
        );
        change(NAME.LIST_USER, listEmpUpadate);
        lutUserGroupState.addLutEmp(listEmp[indexDelete]);
    };

    return (
        <Fragment>
            <FormUserView
                isOpen={isOpen}
                listEmp={empList}
                lutEmp={lutUserGroupState.lutEmpDisplay}
                lutOrg={lutUserGroupState.lutOrg}
                permissionOrgId={profile.permissionOrgId}
                onChangeEmp={handleChangeEmp}
                onChangeOrg={handleChangeOrg}
                onDelete={onDelete}
                onClose={onClose}
            />
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    );
};

const selector = formValueSelector('formUserGroup');

const mapStateToProps = (state) => ({
    listEmp: selector(state, NAME.LIST_USER),
});

export default connect(mapStateToProps)(FormUser);
