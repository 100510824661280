import React, { Fragment, useEffect, useState } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { difference } from 'lodash';

import UseAddUserState from './useUserState/UseAddUserState';

import UseLoadingState from '../../../../control/loading/UseLoadingState';
import Loading from '../../../../control/loading/Loading';
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';
import AlertMessage from '../../../../control/alert/AlertMessage';

import FormAddUserView from '../../presentational/user/FormAddUserView';

import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';
import { insertUser } from '../../../../api/ApiUser';
import * as Constants from '../../../../constants/APDConstants';

import { checkResStatusService } from '../../../../utilities/ResServiceUtils';

const AddUser = (props) => {
    const {
        onCancelClick,
        lutUser,
        profile,
        listUser,
        change,
        handleSubmit,
        callbackSaveSuccess,
        userGroupId,
    } = props;

    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();
    const addUserState = UseAddUserState({ profile, listUser });

    console.log(addUserState);

    const [oldGroup, setOldGroup] = useState();

    const setInilForm = async () => {
        loadingState.showLoading();
        const error = await addUserState.setInitailLut();
        loadingState.hideLoading();

        if (error) {
            alertMessageState.showAlert({
                altMsg: error,
                callbackCancel: () => {
                    alertMessageState.hideAlert();
                    onCancelClick();
                },
            });
        }
    };

    useEffect(() => {
        setInilForm();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const setChangeNull = () => {
        change('userId', null);
    };

    const handleChangeUserGroup = (userGroupObj) => {
        const { userGroupId } = userGroupObj;

        if (oldGroup) {
            const { getEmpInGroup, empStock, empInGroup } = addUserState;

            const lenOfEmp = empStock.length === empInGroup.length;
            const differenceArr = lenOfEmp
                ? difference(empStock, empInGroup).length > 0
                : true;

            if (differenceArr) {
                alertMessageState.showAlert({
                    altMsg: `${AlertMSGConstants.CANCEL_CHANGE_USER_GROUP} ${oldGroup.userGroupName} ?`,
                    altType: 2,
                    btnConfirmName: 'ยกเลิก',
                    btnCancelName: 'ไม่ยกเลิก',
                    callbackConfirm: () => {
                        alertMessageState.hideAlert();
                        getEmpInGroup(userGroupId);
                        setOldGroup(userGroupObj);
                        addUserState.resetLutEmp();
                    },
                    callbackCancel: () => {
                        alertMessageState.hideAlert();
                        change('userGroupId', oldGroup.userGroupId);
                    },
                });
            } else {
                getEmpInGroup(userGroupId);
                setOldGroup(userGroupObj);
                addUserState.resetLutEmp();
            }
        } else {
            addUserState.getEmpInGroup(userGroupObj.userGroupId);
            setOldGroup(userGroupObj);
            addUserState.resetLutEmp();
        }
    };

    const handleChangeEmp = async (user) => {
        if (user) {
            const userFormat = {
                empId: user.empId,
                uuid: user.uuid,
                name: user.empName,
                orgId: user.orgId,
                orgIdSAP: user.orgIdSAP,
                orgName: user.orgName,
                orgShortName: user.orgShortName,
                depId: user.depId,
                depName: user.depName,
                depShortName: user.depShortName,
            };
            addUserState._insertUserInUserGroup(userFormat);
            await setChangeNull();

            const el = document.getElementById('search-emp');
            if (el) el.blur();
        }
    };

    const handleDeleteEmp = (user) => {
        if (user) {
            const userFormat = {
                empId: user.empId,
                uuid: user.uuid,
                empName: user.name,
                orgId: user.orgId,
                orgIdSAP: user.orgIdSAP,
                orgName: user.orgName,
                orgShortName: user.orgShortName,
                depId: user.depId,
                depName: user.depName,
                depShortName: user.depShortName,
            };
            addUserState._deleteUserInUserGroup(userFormat);
            setChangeNull();
        }
    };

    const handleSubmitUser = (values) => {
        const formatUsers = addUserState.empInGroup.map((item) => {
            const newItem = {
                empId: item.empId,
                uuid: item.uuid,
                name: item.name,
                orgId: item.orgIdSAP ? item.orgIdSAP : item.orgId,
                orgName: item.orgName,
                orgShortName: item.orgShortName,
                depId: item.depId,
                depName: item.depName,
                depShortName: item.depShortName,
            };

            return newItem;
        });

        const params = {
            userGroupId: values.userGroupId,
            empId: profile.empId,
            users: formatUsers,
        };

        loadingState.showLoading();
        insertUser(params).then((res) => {
            loadingState.hideLoading();
            let msg = checkResStatusService(res);
            if (res && res.data) {
                const isSuccess =
                    res.data[0].message.toLowerCase() === 'success';
                const altMsg = isSuccess
                    ? res.data[1]
                        ? AlertMSGConstants.INSERT_U_MAINTAINER_FAIL
                        : AlertMSGConstants.SAVE_SUCCESS
                    : AlertMSGConstants.SAVE_FAIL;

                alertMessageState.showAlert({
                    altMsg: altMsg,
                    callbackCancel: () => {
                        alertMessageState.hideAlert();
                        callbackSaveSuccess();
                    },
                });
            } else {
                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
                    callbackCancel: () => {
                        alertMessageState.hideAlert();
                    },
                });
            }
        });
    };

    const handleChangeOrg = async (value) => {
        const orgId = value && value.orgId ? value.orgId : null;
        addUserState.changeOrg(orgId);
    };

    return (
        <Fragment>
            <FormAddUserView
                lutUser={lutUser}
                lutOrg={addUserState.lutOrg}
                listEmp={addUserState.empInGroup}
                listEmpInPermission={addUserState.lutEmpDisplay}
                permissionOrgId={profile.permissionOrgId}
                userGroupId={userGroupId}
                onCancelClick={onCancelClick}
                onUserGroupChange={handleChangeUserGroup}
                onChangeEmp={handleChangeEmp}
                onDeleteClick={handleDeleteEmp}
                onSubmitClick={handleSubmit(handleSubmitUser)}
                onChangeOrg={handleChangeOrg}
            />
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    );
};

const validateUser = (values) => {
    const { ERRORS } = Constants;
    const errors = {};

    if (!values.userGroupId) {
        errors.userGroupId = ERRORS.REQUIRED;
    }

    return errors;
};

const reduxFormParameter = {
    form: 'addUser',
    validate: validateUser,
};

const selector = formValueSelector('addUser');

const mapStateToProps = (state) => ({
    userGroupId: selector(state, 'userGroupId'),
});

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps)
)(AddUser);
