import React from 'react'

import { Scrollbars } from 'react-custom-scrollbars'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider';

import Search from '../../../../control/search/Search'
import Button from '../../../../control/button/Button'
import DataTable from '../../../../control/table/DataTable'

import { MEETING } from '../../../../constants/APDConstants'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
    },
    paper: {
        width: '100%',
        ...theme.paperPadding
    },
    paperBtn: {
        textAlign: 'right',
        marginBottom: '0px'
    },
    textStyle: {
        fontSize: '33px',
        marginLeft: '5px',
    },
    tableCell: {
        padding: '20px'
    },
    divider: {
        margin: '24px 0px'
    }
}))

const MeetingView = (props) => {
    const {
        column,
        data,
        page,
        rowPerPage,
        total,
        sortColumn,
        sortType,
        handlePageChange,
        handleSortExternal,
        handleRowPerPage,
        handleRowClick,
        onSearchClick,
        isAddMeeting,
        onAddMeetingClick,
        txtSearch,
        dataCount
    } = props
    const classes = useStyles()
    const waterMark = `${MEETING.SEARCH.ROOM_NAME}, ${MEETING.SEARCH.MEA_DISTRICT_NAME}, ${MEETING.SEARCH.BUILDING_NAME}, ${MEETING.SEARCH.ADMIN_ORG_NAME}, ${MEETING.SEARCH.EQUIPMENT_NAME}`

    return (
        <Scrollbars universal={true}>
            <Grid container className={classes.root}>
                <Paper className={classes.paper}>
                    <Search
                        iniValue={txtSearch}
                        onSearchClick={onSearchClick}
                        waterMark={waterMark}
                    />
                    <Divider className={classes.divider} />
                    <Grid container justify="space-between" className={classes.titleResult}>
                        <Grid item xs={5}>
                            <span className={classes.textStyle}>
                                {data.length > 0 ? MEETING.SEARCH.RESULT_TABLE : MEETING.SEARCH.NO_RESULT_TABLE}
                            </span>
                        </Grid>

                        <Grid item xs={7} container justify="flex-end" spacing={2} alignItems="flex-end" className={classes.paperBtn}>
                            <Grid item style={{ padding: 0, marginRight: '10px', marginBottom: '2px' }}>
                                {`พบผลลัพธ์ทั้งหมด ${dataCount} รายการ`}
                            </Grid>
                            <Grid item>
                                {isAddMeeting &&
                                    <Button
                                        label={MEETING.BUTTON.ADD_MEETING}
                                        onClick={onAddMeetingClick}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <DataTable
                        column={column}
                        data={data}
                        page={page}
                        rowPerPage={rowPerPage}
                        total={total}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        classes={{ tableCell: classes.tableCell }}

                        handlePageChange={handlePageChange}
                        handleSortExternal={handleSortExternal}
                        handleRowPerPage={handleRowPerPage}
                        handleRowClick={handleRowClick}
                    />
                </Paper>
            </Grid>
        </Scrollbars>
    )
}

export default MeetingView