import React from 'react'

import DetailBookingListView from '../../../presentational/booking/bookingList/DetailBookingListView'


const DetailBookingList = (props) => {

    const { isCancelled, data, onClickClose } = props

    return (
        <DetailBookingListView
            isCancelled={isCancelled}
            data={data}
            onClickClose={onClickClose}
        />
    )
}

export default DetailBookingList