import React, { useState } from 'react'

import AutoCompleteMultiSelect from './AutoCompleteMultiSelect'

const AutoCompleteMultiSelectForm = (props) => {
    const [selectItem, setSelectItem] = useState('')
    const { input, options, onChangeDropDown } = props

    const handleSelect = (listSelectedID = [], listSelcectedArr) => {
        input.onChange(listSelcectedArr)
        setSelectItem(listSelectedID)

        if (onChangeDropDown) {
            onChangeDropDown(listSelcectedArr)
        }
    }

    return (
        <AutoCompleteMultiSelect
            {...props}
            options={options}
            listSelectedCheckbox={selectItem}
            defaultValue={input.value}

            onClickCheckbox={handleSelect}
        />
    )
}

export default AutoCompleteMultiSelectForm
