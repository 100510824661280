import React from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        color: 'black'
    },
    rootWithText: {
        paddingRight: '10px'
    },
    label: {
        color: theme.mainTheme.color1,
        fontSize: '20px'
    },
    divStyle: {
        width: '100%',
        height: '30px'
    },
    labelName: {
        cursor: 'pointer'
    },
    labelRequire: {
        color: theme.mainTheme.color1,
    },
    formHelper: {
        marginTop: '0px',
        marginLeft: '15px',
        color: 'red'
    },
    errorText: {
        color: 'red'
    },
    subLabel: {
        marginLeft: '20px',
        marginTop: '10px',
        color: ' rgba(0, 0, 0, 0.54)'
    },
    fontLabel: {
        fontSize: '20px'
    },
}))

const CheckboxOne = (props) => {

    const classes = useStyles()
    const { label, meta: {error, invalid, touched}, input, disabled, id, className } = props

    const manageCheck = (evt) => {
        const newValue = [...input.value, id]
        const filterDuplicate = newValue.filter(item => item === id).length < 2
        if (filterDuplicate) {
            input.onChange(newValue)
        } else {
            const filterValue = input.value.filter(item => item !== id)
            input.onChange(filterValue)
        }
    }

    return(
        <div className={classes.root}>
            <FormControlLabel
                control = {
                    <Checkbox
                        color="primary"
                        checked={Array.isArray(input.value) ? input.value.filter(item => item === id).length > 0: input.value ? true : false}
                        onChange={id? manageCheck:input.onChange}
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 23 }} />}
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 23 }} />}
                        disabled = {disabled}
                    />
                }
                label = {label}
                classes={{label: classes.fontLabel}}
                className={className}
            />
            <FormHelperText className={classes.formHelper}>{touched && invalid && error}</FormHelperText>
        </div>
    )
}

export default CheckboxOne

    