import React, { createContext } from 'react'

import UseLoadingState from '../../../../control/loading/UseLoadingState'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import UseBookingSearchState from './useBookingState/UseBookingSearchState'
import UseDataTableState from '../../../../control/table/UseDataTableState'
import UseCalendarBookingState from './useBookingState/UseCalendarBookingState'
import UseFormSearchCalendarState from './useBookingState/UseFormSearchCalendarState'
import UseTableBookingState from './useBookingState/UseTableBookingState'

export const BookingContext = createContext()

const BookingProvider = ({ children }) => {

    const loadingState = UseLoadingState()
    const alertMessageState = UseAlertMessageState()
    const bookingSearchState = UseBookingSearchState({ loadingState, alertMessageState })
    const calendarBookingState = UseCalendarBookingState()
    const formSearchCalendarState = UseFormSearchCalendarState()
    const tableBookingState = UseTableBookingState()
    const dataTableMeetingState = UseDataTableState({
        rowPerPage: 10,
        specialColumn: {
            dateTime: 'dateBooking',
            numberOfAttendees: 'numberOfAttendeesSort'
        }
    })

    const value = {
        loadingState,
        alertMessageState,
        bookingSearchState,
        dataTableMeetingState,
        calendarBookingState,
        formSearchCalendarState,
        tableBookingState
    }

    return (
        <BookingContext.Provider value={value} >
            {children}
        </BookingContext.Provider>
    )
}

export default BookingProvider
