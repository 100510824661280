import React, { Component } from 'react'
import './css/pagination.css'
import Typography from '@material-ui/core/Typography'

import * as arrowLeftDisabled from './pagination/page_arrow_left_disabled.png';
import * as arrowLeftLastDisabled from './pagination/page_arrow_left_last_disabled.png';
import * as arrowLeftLastNormal from './pagination/page_arrow_left_last_normal.png';
import * as arrowLeftNormal from './pagination/page_arrow_left_normal.png';
import * as arrowRightDisabled from './pagination/page_arrow_right_disabled.png';
import * as arrowRightLastDisabled from './pagination/page_arrow_right_last_disabled.png';
import * as arrowRightLastNormal from './pagination/page_arrow_right_last_normal.png';
import * as arrowRightNormal from './pagination/page_arrow_right_normal.png';

const images = {
    arrowLeftDisabled : arrowLeftDisabled,
    arrowLeftLastDisabled : arrowLeftLastDisabled,
    arrowLeftLastNormal : arrowLeftLastNormal,
    arrowLeftNormal : arrowLeftNormal,
    arrowRightDisabled : arrowRightDisabled,
    arrowRightLastDisabled : arrowRightLastDisabled,
    arrowRightLastNormal : arrowRightLastNormal,
    arrowRightNormal : arrowRightNormal,
}

class Pagination extends Component {

    onPageChange = (page) => {
        const { onPageChange, count } = this.props
        if(page >= 1 && page <= count )
            onPageChange(page)
    }

    createGoToFirstPageButton = () => {
        const {  page } = this.props
        const imagePath = `arrowLeftLast${page === 1 ? 'Disabled':'Normal'}`
        return (
            <div
                className="pagination-button button-prev"
                onClick={() => this.onPageChange(1)}
            >
                <img src={images[imagePath]} width='20px' alt=""/>
            </div>
        )
    }

    createGoToPrevPageButton = () => {
        const { page } = this.props
        const imagePath = `arrowLeft${page === 1 ? 'Disabled':'Normal'}`
        return (
            <div
                className="pagination-button button-prev"
                onClick={() => this.onPageChange(page - 1)}
            >
                <img src={images[imagePath]} width='20px' alt=""/>
            </div>
        )
    }

    createGoToNextPageButton = () => {
        const { page, count } = this.props
        const imagePath = `arrowRight${page === count ? 'Disabled':'Normal'}`
        return (
            <div
                className="pagination-button button-prev"
                onClick={() => this.onPageChange(page + 1)}
            >
                <img src={images[imagePath]} width='20px' alt=""/>
            </div>
        )
    }

    createGoToLastPageButton = () => {
        const { page, count } = this.props
        const imagePath = `arrowRightLast${page === count ? 'Disabled':'Normal'}`
        return (
            <div
                className="pagination-button button-prev"
                onClick={() => this.onPageChange(count)}
            >
                <img src={images[imagePath]} width='20px' alt=""/>
            </div>
        )
    }

    createPages = () => {
        const { total, rowPerPage, page } = this.props
        let pages = []
        for (let i = 0; i <= total/parseInt(rowPerPage); i++) {
            pages.push(
                <div
                    key={i}
                    onClick={() => this.onPageChange(i+1)}
                    className={`pagination-list-item pagination-button ${{active: i+1 === page}}`}
                >
                    {i+1}
                </div>
            )}
        return pages.filter( ( item, index ) => index + 1 === page )
    }

    render() {
        const { 
            count = 0
        } = this.props

        const pages = this.createPages()
        const firstPage = this.createGoToFirstPageButton()
        const prevPage = this.createGoToPrevPageButton()
        const nextPage = this.createGoToNextPageButton()
        const lastPage = this.createGoToLastPageButton()
        return (
            <div id="pagination-container">
                <div id="pagination" style={window.innerWidth < 376 ? {width: '2%', maxWidth: 0, minWidth: 0}: {width: '100%'}}>
                    {firstPage}
                    {prevPage}
                    <div className="pagination-text page-label">
                        <Typography variant="body1">
                            หน้า
                        </Typography>
                        {/* <div className="count-lable"> */}
                            {pages}
                        {/* </div> */}
                    </div>
                    <div className="pagination-text">
                        <Typography variant="body1">
                            จาก
                        </Typography>
                        <div className="count-lable">
                            {count.toString()}
                        </div>
                    </div>
                    {nextPage}
                    {lastPage}
                </div>
            </div>
        );
    }
}

export default Pagination;