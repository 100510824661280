import { useState, useEffect } from 'react'

import { getLutEmpByPermission } from '../../../../../api/ApiLookup'
import { getAllUserGroup } from '../../../../../api/ApiUser'

const LutUserState = ({ depId, permissionOrgId }) => {

    const [lutEmp, setLutEmp] = useState([])
    const [lutUserGroup, setLutUserGroup] = useState([])

    useEffect(() => {
        getUser()
        getUserGroup()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUser = () => {
        getLutEmpByPermission({ permissionOrgId, depId }).then(res => {
            if (res && res.data) {
                setLutEmp(res.data)
            }
        })
    }

    const getUserGroup = () => {
        getAllUserGroup().then(res => {
            if (res && res.data) {
                setLutUserGroup(res.data)
            }
        })
    }

    return {
        lutEmp,
        lutUserGroup
    }
}

export default LutUserState