import { SAP } from '../../../../constants/APDConstants';

export const extractDate = date => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return { year, month, day };
};

export const convertDateDisplay = date => {
    const dateExtract = extractDate(date);
    const monthName = SAP.NAME_OF_MONTH.filter(
        item => item.id === dateExtract.month
    )[0].name;
    const strDateDisplay = `${dateExtract.day} ${monthName} ${Number(dateExtract.year) + 543}`;
    return strDateDisplay;
};
