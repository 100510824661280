
import React, { Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    inputRoot: {
        fontSize: '20px !important',
        width: '100%',
        height: '30px',
        padding: "0 !important",
        ['& input']: {
            width: '70%',
            padding: '0 !important',
            paddingLeft: '10px !important',

        },
        ['& button']: {
            right: '5px'
        },
        ['& svg']: {
            height: '20px',
            width: '20px'
        }
    },
    listbox: {
        fontSize: '20px !important'
    }
}));

function Virtualize(props) {
    const classes = useStyles();

    const {
        optionKey = { label: "name", value: "id", abbr: null, abbr2: null },
        input,
        options
    } = props

    const onChange = (evt, value) => {
        input.onChange(value.id)
    }

    const value = options.filter(option => option[optionKey.value] === input.value || option[optionKey.value] === input.value[optionKey.value] || option[optionKey.label] === input.value)[0];


    return (
        <Fragment>
            <Autocomplete
                onChange={onChange}
                value={value}
                options={options}
                classes={{
                    inputRoot: classes.inputRoot,
                    listbox: classes.listbox
                }}
                getOptionLabel={option => `${option.name} (${option.id})`}
                disableClearable={true}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        style={{ width: '100%' }}
                        inputProps={{ ...params.inputProps, autocomplete: 'off' }}
                    />
                )}
            />
        </Fragment>
    );
}
export default Virtualize

