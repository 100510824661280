
import React, { Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FixedSizeList } from 'react-window';
import classnames from 'classnames';
import FormControl from '@material-ui/core/FormControl';

import Focus from '../focus/Focus'
import * as FocusAction from '../../actions/FocusAction'
import { usePrevious } from '../StateFunctions'

const useStyles = makeStyles(theme => ({
    listbox: {
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
    root: {
        height: theme.field.height
    },
    input: {
        boxShadow: theme.field.boxShadow,
        height: theme.field.height,
        '& input': {
            height: theme.field.height,
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        '& svg': {
            width: '20px',
            height: '20px'
        },
    },
    inputError: {
        boxShadow: theme.field.boxShadow,
        height: theme.field.height,
        '& input': {
            height: theme.field.height,
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        '& svg': {
            width: '20px',
            height: '20px'
        },
        '& fieldset': {
            borderColor: `${theme.mainTheme.color5} !important`
        }
    },
    labelStyle: {
        fontSize: "20px",
        color: theme.mainTheme.color1
    },
    control: {
        width: '100%',
    },
    marginNormal: {
        marginTop: '0px !important',
        marginBottom: '0px !important'
    },
    formHelper: {
        marginLeft: '10px',
        color: theme.mainTheme.color5,
        marginTop: '1px'
    },
    endAdornment: {
        display: 'contents'
    },
    divControl: {
        height: '90px'
    },
    txtErr: {
        color: 'red'
    }
}));
// let dupValueInsert;

const renderRow = (props) => {
    const { data, index, style } = props;

    return React.cloneElement(data[index], {
        style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
            boxSizing: 'border-box !important',
            fontSize: '20px !important',
            ...style,
        },
    });
}

let optionnInsertBackup = null;

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    let { children, ...other } = props;

    if (children[0].props['children'] === 'Enter หรือ Click เพื่อสร้างรายการ' && !optionnInsertBackup) {
        optionnInsertBackup = children[0]
    }
    const classes = useStyles()

    const outerElementType = React.useMemo(() => {
        return React.forwardRef((props2, ref2) => <div ref={ref2} {...props2} {...other} className={classes.listbox} />);
    }, []);

    const itemCount = Array.isArray(children) ? children.length : 0
    const itemSize = 40

    return (
        <div ref={ref} style={{ display: 'block', }}>
            <FixedSizeList
                itemData={children}
                height={250}
                width="100%"
                outerElementType={outerElementType}
                innerElementType="ul"
                itemSize={itemSize}
                overscanCount={10}
                itemCount={itemCount}
                style={{ padding: 0, height: Math.min(6, itemCount) * itemSize, maxHeight: 'auto' }}
            >
                {renderRow &&
                    renderRow
                }
            </FixedSizeList>
        </div>
    );
});

let bt;
let inputBackup = null;

function Virtualize(props) {
    const classes = useStyles();
    let { options, id = 'autocomplete' } = props

    useEffect(() => {
        inputBackup = null
    }, [])

    const refInput = React.createRef()

    setTimeout(() => {
        if (refInput.current) {
            bt = refInput.current.getElementsByClassName("MuiButtonBase-root MuiIconButton-root MuiAutocomplete-clearIndicator MuiAutocomplete-clearIndicatorDirty");
            let btAction = refInput.current.getElementsByClassName('MuiAutocomplete-popupIndicator')

            if (btAction) {
                btAction[0].getElementsByTagName('svg')[0].style = 'width: 25px; height: 25px'
            }

            if (bt && bt.length > 0 && bt[0].name === "") {
                bt[0].name = input.name
                bt[0].getElementsByTagName('svg')[0].style = 'width: 15px; height: 15px'
                bt[0].style = 'display: none'
            }
        }
    }, 300)

    const {
        optionKey = { label: "name", value: "id", abbr: null },
        isCanInsert = false,
        isHideFormHelperText,
        // isShowNoSelect = true,
        required,
        input,
        onChangeDropDown,
        label,
        meta: { touched, invalid, error, active },
        isDisabled,
        disabled,
        isEditValue,
        insertOptionFn,
        width,
        focusAction,
        focusName
    } = props

    const asterisk = required ? <span style={{ color: '#FF0000' }} > *</span> : "";
    const errors = touched && invalid && error ? error : undefined;
    const optionInsert = { [optionKey.value]: -2, [optionKey.label]: "Enter หรือ Click เพื่อสร้างรายการ" }

    const [isReady, setIsReady] = React.useState(true)
    const prevProp = usePrevious(active)

    const getOptionLabel = option => {
        if (option[optionKey.label] === undefined && isCanInsert) {
            return option
        } else if (optionKey.abbr && option[optionKey.abbr] !== -1) {
            const abbrOption = option[optionKey.abbr] ? `(${option[optionKey.abbr]})` : ""
            return `${option[optionKey.label]} ${abbrOption}`
        } else {
            return option[optionKey.label]
        }
    }

    const bfValue = isEditValue && isReady ?
        isEditValue.info :
        options.filter(option => input.value && option[optionKey.label] === input.value.name)

    const value = bfValue.length >= 1 ? bfValue[0] : null
    const handleChange = (evt, values) => {

        if (!values) {
            input.onChange(null)
            onChangeDropDown && onChangeDropDown()
            inputBackup = null
        } else {
            if (values.id === -2) {
                insertOption()
            } else {
                input.onChange(values)
                onChangeDropDown && onChangeDropDown(values)
                inputBackup = values[optionKey.label]
            }
        }
    }

    if (isEditValue && isReady) {
        input.onChange(isEditValue.info)
        setIsReady(false)
    }

    const insertOption = () => {
        const newOptions = options.filter(item => item[optionKey.value] === inputBackup || item[optionKey.label] === inputBackup).length === 0
        if (newOptions) {
            options = [{ [optionKey.label]: inputBackup }, ...options]
            insertOptionFn && insertOptionFn(options)
            input.onChange({ [optionKey.label]: inputBackup })
        }
    }

    const controlButton = (type) => {
        if (refInput.current && bt && bt.length > 0) {
            if (type === 'open') {
                bt[0].style = 'display: block'
            } else {
                bt[0].style = 'display: none'
            }
        }
    }

    const gotoSavePoint = (evt) => {
        if (isCanInsert) {
            inputBackup = evt.target.value
        }
    }

    const gotoClearPoint = () => {
        if (isCanInsert) {
            inputBackup = null
        }
    }

    const focus = Focus()
    if (focusName === input.name) {
        const focusSuccess = focus.focusFn(!prevProp && !active && touched && invalid, focusName, input.name)
        if (focusSuccess) {
            focusAction.setDefault()
        }
    }

    return (
        <Fragment>
            <div className={classes.divControl}>
                <FormControl className={classes.control} error={errors ? true : false} disabled={isDisabled || disabled} >
                    <span className={classes.labelStyle}>
                        {label && <label className={classnames({ [classes.txtErr]: (errors) })} required>{label}{asterisk}</label>}
                    </span>

                    <Autocomplete
                        id={id}
                        style={width ? { width: `${parseInt(width)}px` } : { width: '100%' }}
                        classes={{ popup: classes.popup, root: classes.root }}
                        className={classes.autoComplete}
                        getOptionDisabled={options => required && options[optionKey.value] === -1}
                        options={options}
                        getOptionLabel={getOptionLabel}
                        ListboxComponent={ListboxComponent}
                        onChange={handleChange}
                        value={value ? value : null}
                        ref={refInput}
                        onOpen={() => controlButton('open')}
                        onClose={() => controlButton('close')}
                        autoSelect={true}
                        onBlur={gotoClearPoint}
                        filterOptions={(options) => {
                            let newOptions = options

                            if (inputBackup && inputBackup !== '') {
                                let isDontHave = options.filter(item => item[optionKey.label] === inputBackup).length === 0
                                newOptions = options.filter(item => item[optionKey.label].indexOf(inputBackup) !== -1)

                                if (newOptions.length === 0) {
                                    newOptions = [optionInsert]
                                } else if (isDontHave) {
                                    newOptions = [...newOptions, optionInsert]
                                }
                            }

                            return newOptions
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                InputProps={
                                    errors ?
                                        { ...params.InputProps, type: 'search', className: classes.inputError, } :
                                        { ...params.InputProps, type: 'search', className: classes.input, }
                                }
                                inputProps={{ ...params.inputProps, autocomplete: 'off', maxLength: 100 }}
                                onKeyPress={(evt) => {
                                    if (evt.charCode === 13) { insertOption() }
                                }}
                                placeholder={required ? 'กรุณาเลือก' : 'ไม่ระบุ'}
                                onChange={gotoSavePoint}
                                inputRef={(ref) => {
                                    if (ref) {
                                        ref.setAttribute('name', input.name)
                                        focus.manageDataFocus(ref)
                                    }
                                }}

                            />
                        )}
                        disabled={disabled || isDisabled}
                    />
                </FormControl>
                {!isHideFormHelperText && <FormHelperText className={classes.formHelper}>{errors}</FormHelperText>}
            </div>

        </Fragment>
    );
}

const mapStateTopProps = (state) => ({
    focusName: state.focus.name
})

const mapDispatchToProps = (dispatch) => ({
    focusAction: bindActionCreators(FocusAction, dispatch)
})

export default connect(mapStateTopProps, mapDispatchToProps)(Virtualize)

