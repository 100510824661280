import React from 'react'

import { makeStyles, Grid } from '@material-ui/core'

const useStylesFormat = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    labelTitle: {
        color: theme.mainTheme.color1, //orange color label display
        fontSize: '20px'
    },
    labelData: {
        fontSize: '20px',
        marginLeft: '10px',
        color: 'black'
    }
}))

const FieldTextDisplayView = ({ label, data, classes }) => {
    const classes2 = { ...useStylesFormat(), ...classes }

    return (
        <Grid container className={classes2.root} >
            <span className={classes2.labelTitle}>{label}:  <span className={classes2.labelData}>{data ? data : '-'}</span></span>
        </Grid >
    )
}

export default FieldTextDisplayView