import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'

import { ABSENCE } from '../../../../../constants/APDConstants'

import FieldTextDisplayView from './FieldTextDisplayView'

const { LABEL, LEAVE_PERIOD_ID } = ABSENCE.FORM_EDIT_ABSENCE

const FormTimeAbsenceView = ({
    initailData,
    leavePeriodId
}) => {
    switch (leavePeriodId) {
        case LEAVE_PERIOD_ID.ALL_DAY:
            return (
                <Fragment>
                    <Grid item xs={12} md={6}>
                        <FieldTextDisplayView
                            label={LABEL.START_DATE}
                            data={initailData.startDateDisplay}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldTextDisplayView
                            label={LABEL.END_DATE}
                            data={initailData.endDateDisplay}
                        />
                    </Grid>
                </Fragment>
            )
        case LEAVE_PERIOD_ID.MORNING:
        case LEAVE_PERIOD_ID.AFTERNOON:
            return (
                <Grid item xs={12} md={6}>
                    <FieldTextDisplayView
                        label={LABEL.DATE}
                        data={initailData.startDateDisplay}
                    />
                </Grid>
            )
        case LEAVE_PERIOD_ID.SPECIFIC_TIME:
            return (
                <Fragment>
                    <Grid item xs={12} md={6}>
                        <FieldTextDisplayView
                            label={LABEL.DATE_TIME}
                            data={initailData.startDateDisplay}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FieldTextDisplayView
                            label={LABEL.STATR_TIME}
                            data={initailData.startTimeDisplay}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FieldTextDisplayView
                            label={LABEL.END_TIME}
                            data={initailData.endTimeDisplay}
                        />
                    </Grid>
                </Fragment>
            )
        default:
            return <Fragment />;
    }
}

export default FormTimeAbsenceView
