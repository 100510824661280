import React, { Fragment } from 'react'
import { Field } from 'redux-form'
// import reactCSS from 'reactcss'

import { makeStyles } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Grid from '@material-ui/core/Grid'
import { useSpring, animated } from 'react-spring'

import RadioButton from '../../../../control/radio/RadioButton'
// import DatePicker from '../../../../control/datetime/DatePicker'
import DatePicker from '../../../../control/fdatetime/DateflatPicker'
import Button from '../../../../control/button/Button'
import AutoCompleteInsert from '../../../../control/autocomplete/AutoCompleteInsert'
import TextFields from '../../../../control/textfield/TextFields'

import { FORM } from '../../../../constants/leave/HolidayConstants'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(5, 3, 5),
        width: '560px',
        [theme.breakpoints.down('xs')]: {
            padding: '20px',
            width: '100%',
        }
    },
    title: {
        fontSize: '30px',
        textAlign: 'center'
    },
    label: {
        color: '#F47B15',
        paddingRight: '20px'
    },
    btnCheck: {
        backgroundColor: '#F5F5F5',
        borderRadius: '30px',
        padding: '0px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 7px 0px',
        width: '50px',
        height: '50px'
    },
    btn: {
        paddingTop: '20px',
    },
    divEnabled: {
        paddingTop: '30px'
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    },
    spanAbsence: {
        color: theme.mainTheme.color5,
        fontSize: theme.fontSize.body2
    },
    preview: {
        color: `${theme.mainTheme.color7} !important`,
        textDecoration: 'underline !important'
    },
    rootSpan: {
        position: 'relative',
        top: '-10px'
    }
}));

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const FormHolidayView = (props) => {

    const classes = useStyles();
    const {
        open,
        lutInfo,
        onCancelClick,
        onSubmitClick,
        isEditValue,
        insertOptionFn,
        dateDup,
        countExport = 0,
        onDateChange,
        onLinkClick,
        enabled,
        isHoliday,
        changeYear
    } = props

    return (
        <Fragment>
            <Modal
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 700,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.title}>
                            {isEditValue ? FORM.EDIT_TITLE : FORM.ADD_TITLE}
                        </div>
                        <div>
                            <Field
                                id={FORM.NAME.HOLIDAY_DAY}
                                name={FORM.NAME.HOLIDAY_DAY}
                                component={DatePicker}
                                label={FORM.LABEL.HOLIDAY_DAY}
                                dateDup={dateDup}
                                pinDay={isEditValue}
                                onDateChange={onDateChange}
                                changeYear={changeYear}
                                required
                            />
                        </div>
                        <div>
                            <Field
                                name={FORM.NAME.INFO}
                                component={AutoCompleteInsert}
                                options={lutInfo}
                                label={FORM.LABEL.INFO}
                                isEditValue={isEditValue}
                                isCanInsert={true}
                                optionKey={{ label: "name", value: "id" }}
                                insertOptionFn={insertOptionFn}
                                required
                            />
                        </div>
                        <div>
                            <Field
                                name={FORM.NAME.DES}
                                component={TextFields}
                                options={lutInfo}
                                label={FORM.LABEL.DES}
                                isMultiline={true}
                                row={3}
                                maxLength={200}
                                placeholder={`คำสั่ง`}
                            />
                        </div>
                        <div className={classes.divEnabled}>
                            <Field
                                name={FORM.NAME.ENABLED}
                                label={FORM.LABEL.ENABLED}
                                component={RadioButton}
                                options={[{ id: 1, name: 'เปิดใช้งาน' }, { id: 2, name: 'ปิดใช้งาน' }]}
                                required
                            />
                        </div>
                        <div className={classes.rootSpan} style={(enabled && isHoliday) ? { display: 'block' } : { display: 'none' }}>
                            <span className={classes.spanAbsence} >
                                {`พบใบลาที่ได้รับผลกระทบ ทั้งหมด ${countExport} ใบ `}
                                {countExport > 0 && <a className={classes.preview} onClick={onLinkClick}>กดที่นี่เพื่อดูใบลาที่มีผลกระทบ</a>}
                            </span>
                        </div>
                        <Grid container spacing={2} justify="center" className={classes.btn}>
                            <Grid item>
                                <Button
                                    label='ยกเลิก'
                                    onClick={onCancelClick}
                                    className={classes.btnCancel}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    label='บันทึก'
                                    onClick={onSubmitClick}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </Fragment>
    );
}

export default FormHolidayView