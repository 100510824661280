import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import UseLutUserState from './useUserState/UseLutUserState';
import UseUserState from './useUserState/UseUserState';
import UseDataTableState from '../../../../control/table/UseDataTableState';

import UseLoadingState from '../../../../control/loading/UseLoadingState';
import Loading from '../../../../control/loading/Loading';
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';
import AlertMessage from '../../../../control/alert/AlertMessage';
import { isFindString } from '../../../../control/ManageDataFunctions';
import { getDepId } from '../../../../utilities/Utils';

import UserView from '../../presentational/user/UserView';
import TableUser from './TableUser';
import EditUser from './EditUser';
import AddUser from './AddUser';

import { PermissionSubFuncUtils } from '../../../../utilities/PermissionUtils';
import {
    SUB_FUNCTION,
    SV_RES_SUCCESS,
} from '../../../../constants/APDConstants';
import * as UserConstants from '../../../../constants/um/UserConstants';
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';

import * as APIuser from '../../../../api/ApiUser';

import { checkResStatusService } from '../../../../utilities/ResServiceUtils';

const User = (props) => {
    const { profile } = props;

    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();
    const lutUserState = UseLutUserState({
        depId: getDepId(profile),
        permissionOrgId: profile.permissionOrgId,
    });
    const userState = UseUserState();

    const rowPerPage = 10;
    const dataTableState = UseDataTableState({ rowPerPage: rowPerPage });

    const [listDataTable, setListDataTable] = useState([]);
    const [txtSearch, setTxtSearch] = useState();
    const permissionSystem = {
        permisAdd: PermissionSubFuncUtils(SUB_FUNCTION.ADD_USER),
        permisEdit: PermissionSubFuncUtils(SUB_FUNCTION.EDIT_USER),
        permisDelete: PermissionSubFuncUtils(SUB_FUNCTION.DELETE_USER),
    };

    const getUser = async () => {
        loadingState.showLoading();
        const resGetUser = await APIuser.getAlluser();
        let msg = checkResStatusService(resGetUser);
        loadingState.hideLoading();
        if (resGetUser && resGetUser.data) {
            const userData = await managePermissionInUserTable(resGetUser.data);
            const initialDataTable = filterDataTable(txtSearch, userData);
            dataTableState.setInitialDataTable(initialDataTable);
            setListDataTable(userData);
        } else {
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
            });
        }
    };

    const managePermissionInUserTable = (data) => {
        const userEmpId = profile.empId;
        const filterSuperAdmin = data.filter((item) => item.userGroupId === 1);

        if (filterSuperAdmin.length === 1) {
            filterSuperAdmin[0].isNotDelete = true;
        }

        data.map((item) => {
            if (userEmpId === item.empId) {
                item.edit = item.isNotDelete
                    ? false
                    : permissionSystem.permisEdit;
                item.delete = false;
            } else {
                item.edit = item.isNotDelete
                    ? false
                    : permissionSystem.permisEdit;
                item.delete = item.isNotDelete
                    ? false
                    : permissionSystem.permisDelete;
            }

            return item;
        });

        return data;
    };

    useEffect(() => {
        getUser();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const filterDataTable = (textSearch, data) => {
        if (textSearch) {
            const dataFilter = data.filter((item) => {
                const name = isFindString(item.name, textSearch);
                const userGroupName = isFindString(
                    item.userGroupName,
                    textSearch
                );
                const empId = isFindString(item.empId, textSearch);
                const orgName = isFindString(item.orgName, textSearch);
                const depName = isFindString(item.depName, textSearch);

                return name || userGroupName || empId || orgName || depName;
            });

            return dataFilter;
        } else {
            return data;
        }
    };

    const handleSearch = (textSearch) => {
        // let dataTable = listDataTable

        // if (textSearch) {
        //     dataTable = listDataTable.filter(item => {
        //         const name = isFindString(item.name, textSearch)
        //         const userGroupName = isFindString(item.userGroupName, textSearch)
        //         const empId = isFindString(item.empId, textSearch)

        //         return (name || userGroupName || empId)
        //     })
        // }

        const dataTableSearch = filterDataTable(textSearch, listDataTable);
        dataTableState.setInitialDataTable(dataTableSearch);
        setTxtSearch(textSearch);
    };

    const handleDelete = (obj) => {
        alertMessageState.showAlert({
            altMsg: AlertMSGConstants.COMFIRM_DELETE_USER,
            altType: 2,
            btnConfirmName: 'ลบ',
            callbackConfirm: () => confirmDelete(obj),
            callbackCancel: () => alertMessageState.hideAlert(),
        });
    };

    const confirmDelete = async (obj) => {
        loadingState.showLoading();
        APIuser.deleteUser(obj.userId, profile.empId).then((res) => {
            loadingState.hideLoading();
            let msg = checkResStatusService(res);
            if (res && res.data) {
                const isSuccess =
                    res.data[0].message.toLowerCase() === SV_RES_SUCCESS;
                const altMsg = isSuccess
                    ? res.data[1]
                        ? AlertMSGConstants.DELETE_U_MAINTAINER_FAIL
                        : AlertMSGConstants.SAVE_SUCCESS
                    : AlertMSGConstants.SAVE_FAIL;

                alertMessageState.showAlert({
                    altMsg: altMsg,
                    callbackCancel: () => {
                        alertMessageState.hideAlert();
                        getUser();
                    },
                });
            } else {
                alertMessageState.showAlert({
                    altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
                    callbackCancel: () => {
                        alertMessageState.hideAlert();
                        getUser();
                    },
                });
            }
        });
    };

    const handleEdit = (obj) => {
        userState.setPage(UserConstants.PAGE.EDIT_USER, obj);
    };

    const handleAddClick = () => {
        userState.setPage(UserConstants.PAGE.ADD_USER);
    };

    const handleCancelPage = () => {
        userState.setPage(UserConstants.PAGE.USER);
        // getUser()
    };

    const callBackSaveSuccess = () => {
        getUser();
        userState.setPage(UserConstants.PAGE.USER);
    };

    return (
        <Fragment>
            <UserView
                txtSearch={txtSearch}
                permisAdd={permissionSystem.permisAdd}
                onAddClick={handleAddClick}
                onSearch={handleSearch}
            >
                <TableUser
                    dataTableState={dataTableState}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            </UserView>
            {userState.page === UserConstants.PAGE.EDIT_USER && (
                <EditUser
                    textTitle={UserConstants.TITLE.EDIT}
                    lutUser={lutUserState}
                    data={userState.initialData}
                    profile={profile}
                    onCancelClick={handleCancelPage}
                    callbackSaveSuccess={callBackSaveSuccess}
                />
            )}
            {userState.page === UserConstants.PAGE.ADD_USER && (
                <AddUser
                    textTitle={UserConstants.TITLE.INSERT}
                    lutUser={lutUserState}
                    profile={profile}
                    listUser={listDataTable}
                    onCancelClick={handleCancelPage}
                    callbackSaveSuccess={callBackSaveSuccess}
                />
            )}
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    profile: state.authen.profile,
});

export default connect(mapStateToProps, null)(User);
