import React, { useState, Fragment, useEffect } from 'react';
import classnames from 'classnames';
import 'antd/dist/antd.css';
import './CheckboxTree.css';

import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextFieldSearch from './TextFieldSearch';

import { Tree } from 'antd';
const { TreeNode } = Tree;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        color: 'black'
    },
    label: {
        color: theme.mainTheme.color1,
        fontSize: theme.fontSize.body1,
        height: '23px',
        marginBottom: '1px',
    },
    divStyle: {
        width: '100%',
        height: '30px'
    },
    labelName: {
        cursor: 'pointer'
    },
    labelRequire: {
        color: theme.mainTheme.color1,
    },
    formHelper: {
        marginTop: '1px',
        marginLeft: '15px',
        color: theme.mainTheme.color5
    },
    errorText: {
        color: theme.mainTheme.color5
    },
    subLabel: {
        marginLeft: '20px',
        marginTop: '10px',
        color: ' rgba(0, 0, 0, 0.54)'
    }
}));

const dataList = [];
let timeout = null;

const generateList = (data, keyData) => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        dataList.push({ [keyData.value]: node[keyData.value], [keyData.label]: node[keyData.label], [keyData.abbr]: node[keyData.abbr] });
        if (node.children) {
            generateList(node.children, keyData);
        }
    }
};

const CheckboxTree = ({
    options,
    label,
    input,
    required,
    meta: { invalid, error, touched },
    keyData = { label: "name", value: "id", abbr: null },
    isSearch,
    checkStrictly, // ถ้าเป็น true เมื่อเลือก parent จะไม่ default เลือก children ให้
    callBackCheck,
    getHalfChecked
}) => {
    const classes = useStyles();
    const asterisk = required ? <span className={classes.errorText} >*</span> : "";

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        generateList(options, keyData)
    }, [options]) // eslint-disable-line react-hooks/exhaustive-deps

    const onExpand = expandedKeys => {
        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    };

    const onCheck = (checkedKeys, e) => {
        if (checkStrictly) {
            input.onChange(checkedKeys.checked)
            callBackCheck && callBackCheck(checkedKeys.checked)
        } else {
            input.onChange(checkedKeys)
            callBackCheck && callBackCheck(checkedKeys, e.halfCheckedKeys)
        }
    };

    const onChange = e => {
        const { value } = e.target;

        if (timeout) {
            clearTimeout(timeout)
        }

        if (value) {
            timeout = setTimeout(() => {
                const expandedKeys = dataList.map(item => {
                    const label = keyData.abbr ? `${item[keyData.label]} (${item[keyData.abbr]})` : item[keyData.label]
                    if (label.indexOf(value) > -1) {
                        return getParentKey(item[keyData.value], options);
                    }
                    return null;
                }).filter((item, i, self) => item && self.indexOf(item) === i);
                setExpandedKeys(expandedKeys)
            }, 500)
        } else {
            setExpandedKeys([])
        }
        setAutoExpandParent(true)
        setSearchValue(value)
    };

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item[keyData.value] === key)) {
                    parentKey = node[keyData.value];
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const renderTreeNodes = data => data.map(item => {
        const label = keyData.abbr ? `${item[keyData.label]} (${item[keyData.abbr]})` : item[keyData.label]
        const index = label.indexOf(searchValue);
        const beforeStr = label.substr(0, index);
        const afterStr = label.substr(index + searchValue.length);
        const title = index > -1 ? (<span>{beforeStr}<span style={{ color: '#f50' }}>{searchValue}</span>{afterStr}</span>) : (<span>{label}</span>);

        if (item.children) {
            return (
                <TreeNode key={item[keyData.value]} title={title}>
                    {renderTreeNodes(item.children)}
                </TreeNode>
            );
        }
        return <TreeNode key={item[keyData.value]} title={title} />;
    });

    return (
        <Fragment>
            {label && <FormLabel focused={false} className={classnames(classes.label, { [classes.errorText]: touched && invalid && error })} component="legend">{label}{" "}{asterisk}</FormLabel>}
            {isSearch && <TextFieldSearch placeholder="ค้นหา" onChange={onChange} />}

            <Tree
                checkable
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={input.value ? { checked: input.value, halfChecked: getHalfChecked && getHalfChecked(input.value, options) } : []}
                selectedKeys={[]}
                checkStrictly={checkStrictly}
            >
                {renderTreeNodes(options)}
            </Tree>
            <FormHelperText className={classes.formHelper}>{touched && invalid && error}</FormHelperText>
        </Fragment>
    )
}

export default CheckboxTree
