import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '24px'
    },
    button: {
        textAlign: 'right',
        margin: theme.spacing(1) / 2
    },
    buttonSwith: {
        textAlign: 'right',
        margin: theme.spacing(1) / 2,
        marginTop: '0px',
        paddingTop: '2px'
    },
    btnSwitch: {
        backgroundColor: '#1890ff !important',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    paper: {
        width: '100%',
        ...theme.paperPadding
    }
}));

const CancelBookingPageView = props => {
    const classes = useStyles();
    const { children } = props

    return (
        <Fragment>
            <Scrollbars universal={true}>
                <Grid container justify="center" id="buttonPage">
                    <Grid item xs={12} className={classes.root}>
                        <Paper className={classes.paper}>
                            {children[0]}
                            {children[1]}
                        </Paper>
                    </Grid>
                </Grid>
            </Scrollbars>
            {children[2]}
        </Fragment>
    );
};

export default CancelBookingPageView;
