import { fetchGet, fetchPost, createParamGet } from '../utilities/ServiceUtils';
import * as ApiConstants from '../constants/ApiConstants'
import { API } from '../constants/ServiceMSGConstants'

export const getStructureLeave = async (orgID) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_STRUCTURE_ORG}?orgId=${orgID}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getStructureLeave', err)
        return err
    }
}

export const getLutApprovalType = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LU_APPROVAL_TYPE)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getLutApprovalType :', err)
        return err
    }
}

export const getEmpByEmpID = async (empID) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_EMP_BY_ID}/?empId=${empID}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getEmpByEmpID :', err)
        return err
    }
}

export const getCustomLutOrgLeave = async () => { // ผู้ว่าการ, สมส
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LU_CUSTOM_ORG)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()

        return res
    }
    catch (err) {
        console.log('ex getLutCustomOrg :', err)
        return err
    }
}

export const getLutExclusiveOrgLeave = async (depId) => { // org ของผู้บริหารระดับสูง + ฝ่ายตัวเอง
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_LU_EXCLUSIVE_ORG}?depId=${depId}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()

        return res
    }
    catch (err) {
        console.log('ex getLutExclusiveOrgLeave :', err)
        return err
    }
}

export const callServiceUpdateOrgLeave = async ({ uuid, updateBy, dataOrg = [], dataEmp = [] }) => {
    try {
        const fetchData = await fetchPost(ApiConstants.SERVICE_UPDATE_ORG_LEAVE, {
            uuid: uuid,
            updateBy: updateBy,
            dataOrg: dataOrg,
            dataEmp: dataEmp
        })
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }

        const res = await fetchData.json()
        return res;
    }
    catch (err) {
        console.log('ex callServiceUpdateOrgLeave :', err)
        return err
    }
}

export const callServiceUpdateOrgSap = async ({ uuid, updateBy, dataOrg = [], dataEmp = [] }) => {
    try {
        const fetchData = await fetchPost(ApiConstants.SERVICE_UPDATE_ORG_SAP, {
            uuid: uuid,
            updateBy: updateBy,
            dataOrg: dataOrg,
            dataEmp: dataEmp
        })
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }

        const res = await fetchData.json()
        return res;
    }
    catch (err) {
        console.log('ex callServiceUpdateOrgSap :', err)
        return err
    }
}

export const getLutOrgchart = async (param) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_LUT_ORGCHART}?${createParamGet(param)}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.LEAVE };
        }
        const res = await fetchData.json()

        return res
    }
    catch (err) {
        console.log('ex getLutOrgchart :', err)
        return err
    }
}