import { useState } from 'react'

import { CONTROL_TABLE } from '../../constants/APDConstants'
import { sortObjASC, sortObjDESC, sortObjNumberASC, sortObjNumberDESC, sortObjDateASC, sortObjDateDESC } from '../ManageDataFunctions';

const UseDataTableState = ({ rowPerPage, specialColumn }) => {
    const [dataTable, setDataTable] = useState([])
    const [dataPage, setDataPage] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState()
    const [sortColumn, setSortColumn] = useState()
    const [sortType, setSortType] = useState('asc')

    const sortASC = (column, typeData) => {
        switch (typeData) {
            case 'number':
                return sortObjNumberASC(dataTable, column)
            case 'date':
                return sortObjDateASC(dataTable, column)
            default:
                return sortObjASC(dataTable, column)
        }
    }

    const sortDESC = (column, typeData) => {
        switch (typeData) {
            case 'number':
                return sortObjNumberDESC(dataTable, column)
            case 'date':
                return sortObjDateDESC(dataTable, column)
            default:
                return sortObjDESC(dataTable, column)
        }
    }

    const _handleSortData = (column, type, typeData) => {
        if (type === CONTROL_TABLE.SORT.ASC) {
            // const newDataSort = typeData === "number" ? sortObjNumberASC(dataTable, column) : sortObjASC(dataTable, column)
            const newDataSort = sortASC(column, typeData)
            const newData = newDataSort.slice(0, rowPerPage * 1);
            changePage(1)
            setTotal(newDataSort.length)
            setDataPage(newData)
        } else {
            // const newDataSort = typeData === "number" ? sortObjNumberDESC(dataTable, column) : sortObjDESC(dataTable, column)
            const newDataSort = sortDESC(column, typeData)
            const newData = newDataSort.slice(0, rowPerPage * 1);
            changePage(1)
            setTotal(newDataSort.length)
            setDataPage(newData)
        }
    }

    const sortData = (column, type, typeData) => {
        let sortColumn = column
        if (specialColumn && specialColumn[column]) {
            sortColumn = specialColumn[column]
        }
        setSortColumn(column)
        setSortType(type)
        _handleSortData(sortColumn, type, typeData)
    }

    const changePage = (page, initailData) => {
        const startSlice = page === 1 ? 0 : parseInt((page * parseInt(rowPerPage))) - parseInt(rowPerPage)
        const data = initailData ? initailData : dataTable;
        const newData = data.slice(startSlice, parseInt(rowPerPage) * page);
        setDataPage(newData)
        setPage(page)
    }

    const setInitialDataTable = (data) => {
        setDataTable(data)
        setPage(1)
        setTotal(data.length)
        changePage(1, data)
        setSortColumn()
        setSortType()
    }

    return {
        dataTable,
        dataPage,
        page,
        total,
        sortColumn,
        sortType,
        rowPerPage,

        setInitialDataTable,
        sortData,
        changePage
    }
}

export default UseDataTableState
