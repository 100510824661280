import  { useState } from 'react'

import { UM_USER_GROUP } from '../../../../../constants/APDConstants'

const { PAGE } = UM_USER_GROUP

const UseUserGroupState = () => {
    const [data, setData] = useState({
        page: PAGE.USER_GROUP,
        initialDate: null
    })

    const setPage = (page, initialDate) => {
        if (!page) {
            page = PAGE.USER_GROUP
        }

        setData({
            page: page,
            initialDate: initialDate
        })
    }


    return {
        page: data.page,
        initialDate: data.initialDate,

        setPage
    }
}

export default UseUserGroupState
