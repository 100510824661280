import { useState } from 'react'
import { sortBy } from 'lodash'

import { getSystemFunction, getPermissionOrg } from '../../../../../api/ApiUseGroup'
import {
    getLutEmpByPermission
} from '../../../../../api/ApiLookup'
import { getLutExclusiveOrgLeave, getCustomLutOrgLeave } from '../../../../../api/ApiOrgLeave'
import { getAlluser } from '../../../../../api/ApiUser'
import { PERMISSION_ORG } from '../../../../../constants/APDConstants'
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'
import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'

const UseLutUserGroupState = ({ permissionOrgId, depId }) => {
    const [lut, setLut] = useState({
        lutPermissionOrg: [],
        lutFunction: [],
        lutEmp: [],
        lutEmpDisplay: [],
        lutOrg: [],
        lutAllUser: [],
        isSetInit: false
    })
    const [filterOrgId, setFilterOrgId] = useState(null) // เก็บหน่วยงานสำหรับ filte พนักงาน

    const _pareSystemFunction = (listData, pid) => { // add prefix
        const dataUpdate = listData.map(data => {
            if (pid) {
                data.id = `${pid}-${data.id}`
            }
            if (data.children && data.children.length) {
                data.children = _pareSystemFunction(data.children, data.id)
            }
            return data
        })
        return dataUpdate
    }

    const _filterLutEmpDisplay = (lutEmp, user, orgId) => {
        // const empFilter = lutEmp.filter(emp => (!orgId || emp.orgId === orgId) && !user.some(user => user.empId === emp.empId))
        const empFilter = lutEmp.filter(emp => (!orgId || (emp.depId === orgId) || (emp.orgIdSAP === orgId)) && !user.some(user => user.empId === emp.empId))

        return empFilter
    }

    const setInitailLut = () => { // รอยิง service
        const getLutOrg = (permissionOrgId === PERMISSION_ORG.EXCLUSIVE_ORG) ? getLutExclusiveOrgLeave(depId) : getCustomLutOrgLeave()

        return Promise.all([
            getSystemFunction(),
            getPermissionOrg(),
            getLutEmpByPermission({ permissionOrgId, depId }),
            getLutOrg,
            getAlluser()
        ]).then(([
            resSystemFunction,
            resPermissionOrg,
            resEmp,
            resOrg,
            resAllUser
        ]) => {
            if (resEmp && resEmp.data) {
                const lutAllUser = resAllUser && resAllUser.data ? resAllUser.data : []
                const lutEmp = resEmp && resEmp.data ? resEmp.data : []

                setLut({
                    lutPermissionOrg: resPermissionOrg && resPermissionOrg.data ? resPermissionOrg.data : [],
                    lutFunction: resSystemFunction && resSystemFunction.data ? _pareSystemFunction(resSystemFunction.data) : [],
                    lutEmp: lutEmp,
                    lutEmpDisplay: _filterLutEmpDisplay(lutEmp, lutAllUser, filterOrgId),
                    lutOrg: resOrg && resOrg.data ? resOrg.data : [],
                    lutAllUser: lutAllUser,
                    isSetInit: true
                })
            } else {
                const msg = checkResStatusService(resEmp)
                return msg ? msg : AlertMSGConstants.SYSTEM_ERROR
            }

        })
    }

    const deleteLutEmp = (empId) => { // selected user
        const lutAllUserUpdate = [...lut.lutAllUser, { empId: empId }]

        setLut({
            ...lut,
            lutEmpDisplay: _filterLutEmpDisplay(lut.lutEmp, lutAllUserUpdate, filterOrgId),
            lutAllUser: lutAllUserUpdate
        })
    }

    const addLutEmp = (newEmp) => { // delete user
        const lutAllUserUpdate = lut.lutAllUser.filter(user => user.empId !== newEmp.empId)
        const lutEmpDisplayUpdate = _filterLutEmpDisplay(lut.lutEmp, lutAllUserUpdate, filterOrgId)
        const lutEmpDisplaySort = sortBy(lutEmpDisplayUpdate, ['empName'])

        setLut({
            ...lut,
            lutAllUser: lutAllUserUpdate,
            lutEmpDisplay: lutEmpDisplaySort
        })
    }

    const changeOrg = orgId => { // change org filter emp
        setLut({
            ...lut,
            lutEmpDisplay: _filterLutEmpDisplay(lut.lutEmp, lut.lutAllUser, orgId)
        })
        setFilterOrgId(orgId)
    }

    const resetLutEmp = () => {
        return Promise.all([
            getAlluser()
        ]).then(([
            resAllUser
        ]) => {
            const lutAllUser = resAllUser && resAllUser.data ? resAllUser.data : []

            setLut({
                ...lut,
                lutEmpDisplay: _filterLutEmpDisplay(lut.lutEmp, lutAllUser, null),
                lutAllUser: lutAllUser
            })
            setFilterOrgId(null)
        })
    }

    return {
        lutPermissionOrg: lut.lutPermissionOrg,
        lutFunction: lut.lutFunction,
        lutEmp: lut.lutEmp,
        lutOrg: lut.lutOrg,
        lutEmpDisplay: lut.lutEmpDisplay,
        isSetInit: lut.isSetInit,

        setInitailLut,
        deleteLutEmp,
        addLutEmp,
        resetLutEmp,
        changeOrg
    }
}

export default UseLutUserGroupState
