import React, { useState, useRef, Fragment } from "react";

import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';

import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';
import UseLoadingState from '../../../../control/loading/UseLoadingState'

import SwiperImageView from '../../../../control/swiperimg/SwiperImageView';
import AlertMessage from '../../../../control/alert/AlertMessage';
import Loading from '../../../../control/loading/Loading';

const SwiperImage = ({
    input,
    limit,
    // funcUploadImage,
    // funcDeleteImage,
    // roomId
}) => {
    const [activeStep, setActiveStep] = useState(0);
    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();
    let refUpload = useRef(null);

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }

    const handleUpload = (e) => {
        refUpload.click();
    }

    const getFileExtension = filename => {
        const filenameSplit = filename.split('.')
        let fileExtension = ""

        if (filenameSplit.length > 0) {
            fileExtension = filenameSplit.pop()
        }

        return fileExtension
    }

    // const uploadImage = async (imageData) => {
    //     loadingState.showLoading()
    //     const resUploadImage = await funcUploadImage(imageData)
    //     loadingState.hideLoading()

    //     if (!resUploadImage || !resUploadImage.data) {
    //         alertMessageState.showAlert({ altMsg: AlertMSGConstants.UPLOAD_IMAGE_FAIL })
    //     } else {
    //         const result = resUploadImage.data[0]
    //         const thumbnail = result.thumbnails.length && result.thumbnails[0]

    //         return {
    //             url: result.url,
    //             imageURI: result.uri,
    //             thumbnailURI: thumbnail && thumbnail.uri,
    //             absolutePath: result.absolutePath
    //         }
    //     }
    // }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const result = reader.result.split("base64,")
                const imageBase64 = result.length > 1 ? result[1] : reader.result
                resolve(imageBase64);
            };
            reader.onerror = reject;
        })
    }

    const handleChangeUpload = async (e) => {
        const fileName = e.target.value.toLowerCase();
        const fileTypes = ["jpeg", "jpg", "png"]
        const fileExtension = getFileExtension(fileName)

        if (fileTypes.indexOf(fileExtension) !== -1 && e.target.files[0]) {
            //เช็คไฟล์ upload ไม่เกิน 5mb (1 mb = 1,048,576)
            if (e.target.files[0].size <= 5266467) {
                const file = e.target.files[0]
                const imageBase64 = await getBase64(file)
                const imageData = {
                    encoding: "base64",
                    filename: file.name.split(".")[0],
                    type: fileExtension,
                    data: imageBase64,
                    thumbnails: [{ width: 800, height: 600, quality: 60 }],
                    // path: roomId && `Meeting\\${roomId}`
                }
                // const newImage = await uploadImage(imageData)
                // if (newImage) {
                const imageUpdate = input.value ? [...input.value, imageData] : [imageData];
                input.onChange(imageUpdate)
                setActiveStep(input.value.length)
                // }
            } else {
                alertMessageState.showAlert({ altMsg: AlertMSGConstants.UPLOAD_IMAGE_LIMIT_FILE_SIZE })
            }
        } else if (e.target.files && e.target.files.length) {
            alertMessageState.showAlert({ altMsg: AlertMSGConstants.UPLOAD_IMAGE_TYPE_FAIL })
        }
    }

    const handleDelete = (index) => {
        // const [imageDel] = input.value.filter((item, indexFilter) => indexFilter === index)
        // if (imageDel && imageDel.absolutePath) { // ยิง service ลบ temp image
        //     funcDeleteImage && funcDeleteImage([{ absolutePath: imageDel.absolutePath }])
        // }

        const newImageList = input.value.filter((item, indexFilter) => indexFilter !== index)
        const newActiveStep = index - 1 > 0 ? index - 1 : 0;
        setActiveStep(newActiveStep)
        input.onChange(newImageList)
    }

    return (
        <Fragment>
            <SwiperImageView
                imageList={input.value ? input.value : []}
                activeStep={activeStep}
                disabled={false}
                refUpload={node => refUpload = node}
                limit={limit}
                onBack={handleBack}
                onNext={handleNext}
                onDelete={handleDelete}
                onUpload={handleUpload}
                onChangeUpload={handleChangeUpload}
            />
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    );
}

SwiperImage.defaultProps = {

}

export default SwiperImage;
