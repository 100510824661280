import React, { Fragment, useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose'

import { setFormatInitialValues } from '../MeetingUtils';
import {
    callServiceGetMeetingRoomByID,
    callServiceEditMeetingRoomByID,
    callServiceCoppyImageMeetingRoom,
    callServiceDeleteImageMeetingRoom,
    callServiceUploadImageMeetingRoom
} from '../../../../../api/ApiMeeting';
import { checkSuccessResService } from '../../../../../utilities/Utils'
import { setFormatValueImage, getPathImageMeetingRoom, getFolderImageMeetingRoom } from '../MeetingUtils'
import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants';
import RouteConfig from '../../../../../config/appRoute/RouteConfig'
import UseLoadingState from '../../../../../control/loading/UseLoadingState';
import UseAlertMessageState from '../../../../../control/alert/UseAlertMessageState';
import { RouterContext } from '../../../../../RouterProvider'

import Loading from '../../../../../control/loading/Loading';
import AlertMessage from '../../../../../control/alert/AlertMessage';
import FormMeetingRoom from '../formMeeting/FormMeetingRoom';

import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'

let roomId = null
const EditMeetingRoom = ({
    authenRdc: { profile },
    history
}) => {
    const { setCallbackRouteChange } = useContext(RouterContext)
    const loadingState = UseLoadingState();
    const alertMessageState = UseAlertMessageState();
    const [initialValue, setInitialValue] = useState(null)
    const locationClone = { ...history.location }
    const { state } = locationClone
    const editMeetingRoomID = state && state.editMeetingRoomID ? state.editMeetingRoomID : null

    useEffect(() => {
        if (editMeetingRoomID) {
            loadingState.showLoading();
            callServiceGetMeetingRoomByID(editMeetingRoomID, profile.uuid).then(res => {
                let msg = checkResStatusService(res)
                if (res && res.data && res.data[0]) {
                    setInitialValue(setFormatInitialValues(res.data[0]))
                } else {
                    const altMsg = res.errors && res.errors[0].detail ? res.errors[0].detail : msg ? msg : AlertMSGConstants.SYSTEM_ERROR;
                    alertMessageState.showAlert({
                        altMsg: altMsg,
                        callbackCancel: async () => {
                            alertMessageState.hideAlert();
                            await setCallbackRouteChange()
                            closePage()
                        }
                    })
                }
            }).then(() => { loadingState.hideLoading(); })
            roomId = editMeetingRoomID
            clearState()
        } else {
            history.push(RouteConfig.MEETING_MANAGE)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const clearState = () => {
        if (history.location.state && history.location.state.editMeetingRoomID) {
            let state = { ...history.location.state };
            delete state.editMeetingRoomID;
            history.replace({ ...history.location, state });
        }
    }

    const closePage = () => {
        history.push(RouteConfig.MEETING_MANAGE)
    }

    const getParam = values => {
        const param = values
        // กรณีแก้ไขจะไม่ส่ง maintainer super admin ไป
        const maintainerUpdate = values.maintainer.filter(maintainer => !maintainer.isSuperAdmin).map(maintainer => maintainer.empId);
        param.maintainerEmpId = maintainerUpdate.toString()
        delete param.maintainer

        return param
    }

    const uploadImage = async ({ building, floor, roomNameTH, images }) => {
        const promiseUploadImage = []
        const path = getPathImageMeetingRoom({ building, floor, roomNameTH })
        const folderName = getFolderImageMeetingRoom({ building, floor, roomNameTH })

        images.forEach((image, index) => {
            if (image.data) {
                const param = {
                    path: path,
                    fileName: `${new Date().getTime()}${index}.${image.type}`,
                    base64Image: image.data
                }

                promiseUploadImage.push(callServiceUploadImageMeetingRoom(param))
            }
        });

        const resUploadImages = await Promise.all(promiseUploadImage)
        const result = {
            isSuccessAll: true,
            data: []
        }

        resUploadImages.forEach(res => { //เช็คกรณีมีบางรูป upload ไม่สำเร็จ
            if (res && res.status === 'success') {
                result.data.push({
                    imageURI: `${folderName}/${res.origin}`,
                    thumbnailURI: `${folderName}/${res.thumbnail}`
                })
            } else {
                result.isSuccessAll = false
            }
        })

        return result
    }

    const checkIsChangePathImage = ({ building, floor, roomNameTH }) => {
        return `${initialValue.building}\\${initialValue.floor}\\${initialValue.roomNameTH}` !== `${building}\\${floor}\\${roomNameTH}`
    }

    const coppyFile = async ({ building, floor, roomNameTH }, originalImage) => {
        const imageCopy = []
        const images = []

        originalImage.forEach((item, index) => {
            const fileExtension = item.imageURI.split(".").pop()
            const name = `${new Date().getTime()}${index}`
            const fileNameOrigin = `${name}_origin.${fileExtension}` // เปลื่ยนชื่อไฟล์เสมอ
            const fileName = `${name}.${fileExtension}` // เปลื่ยนชื่อไฟล์เสมอ
            const path = getPathImageMeetingRoom({ building, floor, roomNameTH }) // สำหรับ copy
            const folderName = getFolderImageMeetingRoom({ building, floor, roomNameTH }) // สำหรับส่งไปบันทึก

            // จังหวะ copy ให้ copy แค่ orgigin เดี๋ยวจะไปสร้างตัว thumnail ให้เอง
            imageCopy.push({
                old: `${process.env.REACT_APP_FOLDER_UPLOAD_IMAGE_MEETINGROOM}\\${item.thumbnailURI}`.replace(/\//g, '\\'),
                new: `${path}\\${fileName}`
            })

            images.push({
                imageURI: `${folderName}/${fileNameOrigin}`,
                thumbnailURI: `${folderName}/${fileName}`
            })
        })

        const resCoppyFile = await callServiceCoppyImageMeetingRoom(imageCopy)
        const isSuccess = (resCoppyFile && resCoppyFile.status === 'success')
        return { isSuccess, images }
    }

    const deleteOldImage = (imageUpdate = [], isChangePathImage) => { // สำหรับลบรูปเก่า
        const { images = [] } = initialValue
        let imageDel = []

        if (isChangePathImage) { // กรณีมีการเปลื่ยน path ให้ลบของเก่าทิ้งทั้งหมด
            imageDel = images.map(item => item.thumbnailURI)
        } else { // กรณ๊ไม่เปลื่ยน path จะลบเฉพาะตัวที่มีการลบเท่านั้น
            imageDel = images.filter(item => !imageUpdate.some(subitem => subitem.imageURI === item.imageURI)).map(item => item.thumbnailURI)
        }

        if (imageDel.length) {
            const param = imageDel.map(item => `${process.env.REACT_APP_FOLDER_UPLOAD_IMAGE_MEETINGROOM}\\${item}`.replace(/\//g, '\\'))
            callServiceDeleteImageMeetingRoom(param)
        }
    }

    const deleteImage = (images) => { // เอาไว้กรณี upload บางรูปไม่สำเร็จจะทำการยิงลบตัวที่สำเร็จทั้งหมด
        const param = images.map(item => `${process.env.REACT_APP_FOLDER_UPLOAD_IMAGE_MEETINGROOM}\\${item}`.replace(/\//g, '\\'))
        callServiceDeleteImageMeetingRoom(param)
    }

    const handleSubmit = async (values) => {
        loadingState.showLoading();

        // upload new images
        const resImage = await uploadImage(values) // กรณีไม่มีรูปภาพก็จะคืน isSuccessAll มา
        const isChangePathImage = checkIsChangePathImage(values)
        let resEditMeetingRoom, msg = null
        let originalImage = values.images.filter(item => item.imageURI).map(item => ({ imageURI: item.imageURI, thumbnailURI: item.thumbnailURI }))
        let copyImage = [] // สำหรับเก็บรูปที่ copy เพื่อเอาไว้ลบกรณีบันทึกข้อมูลไม่สำเร็จ

        if (resImage.isSuccessAll) {
            const param = getParam(values)
            let oldImage = [...originalImage]
            let isCoppyFileSuccess = true

            // coppy old image
            if (originalImage.length && isChangePathImage) {
                const resCopyFile = await coppyFile(values, originalImage)
                isCoppyFileSuccess = resCopyFile.isSuccess

                if (resCopyFile.isSuccess) { // กรณ๊มีการเปลื่ยน path จะส่ง path ใหม่ไปอัพเดท
                    oldImage = resCopyFile.images
                    copyImage = [...resCopyFile.images]
                }
            }

            // update meeting room
            if (isCoppyFileSuccess) {
                param.images = [...oldImage, ...setFormatValueImage(resImage.data)]
                resEditMeetingRoom = await callServiceEditMeetingRoomByID(roomId, param)
                msg = checkResStatusService(resEditMeetingRoom)
            }
        }

        loadingState.hideLoading()

        if (resEditMeetingRoom && resEditMeetingRoom.data && checkSuccessResService(resEditMeetingRoom.data)) {
            // ลบรูปเก่าทิ้ง
            deleteOldImage(values.images, isChangePathImage)
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.SAVE_SUCCESS,
                callbackCancel: async () => {
                    await setCallbackRouteChange()
                    closePage()
                }
            })
        } else {
            const listNewImage = [...resImage.data, ...copyImage]
            const imageDel = listNewImage.filter(item => item.thumbnailURI).map(item => item.thumbnailURI)
            deleteImage(imageDel) // กรณีสร้างห้องประชุมไม่สำเร็จ จะยิงเพื่อลบรุปที่ upload ไป
            alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SAVE_FAIL })
        }
    }

    return (
        <Fragment>
            {initialValue && <FormMeetingRoom
                roomId={roomId}
                initialValue={initialValue}
                callBackSubmit={handleSubmit}
                callBackCancel={closePage}
                showLoading={loadingState.showLoading}
                hideLoading={loadingState.hideLoading}
            />}
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default compose(
    connect(mapStateToProps, null),
    withRouter
)(EditMeetingRoom)
