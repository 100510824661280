import React, { Fragment } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { makeStyles, Grid } from '@material-ui/core'
import FormatDisplayDetail from './FormatDisplayDetail'
import PeriodDisplayDetail from './PeriodDisplayDetail'
import { SAP } from '../../../../constants/APDConstants'
import Button from '../../../../control/button/Button'
import { convertDateDisplay } from '../../container/leavesap/LeaveSAPUtils'

import Info from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip'

const { DETAIL_LEAVE } = SAP

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    titleDetailLeave: {
        fontSize: '33px'
    },
    labelForm: {
        color: '#F47B15',
        paddingRight: '10px'
    },
    linkDownload: {
        textDecoration: 'underline',
        color: '#0072bc',
        '&:hover': {
            color: 'red',
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    btn: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        paddingRight: '24px'
    },
    content: {
        height: 'calc(100% - 108px)',
    },
    attachments: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    paddingRight: {
        paddingRight: '24px'
    },
    button: {
        width: '15%'
    },
    isSpecial: {
        color: theme.mainTheme.color5,
    },
    iconInfo: {
        width: '20px',
        height: '20px',
        position: 'relative',
        top: '4px',
        cursor: 'pointer',
        color: theme.mainTheme.color6
    },
    tagP: {
        marginBottom: '0px'
    },
    tooltipPlacementRight: {
        fontSize: '20px'
    }
}))

const LeaveSAPDetailAbsenceView = (props) => {

    const classes = useStyles()
    const { data = {}, onClickDownload, onCancelClick, info = { listSpecialLeaveName: '', leaveTypeName: '' } } = props
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <span className={classes.titleDetailLeave}>{DETAIL_LEAVE.TITLE}</span>
                {data.isSpecial &&
                    <Grid item xs={12}>
                        <span className={classes.isSpecial}>
                            {'ใบลานี้ไม่เป็นไปตามข้อบังคับฯ'}
                            {/* <Tooltip className={classes.tooltips} title={
                                <Fragment>
                                    <p className={classes.tagP}>{`ประเภทการลา: ${info.leaveTypeName}`}</p>
                                    {`การลากรณีพิเศษ​: `}
                                    {info.listSpecialLeaveName.map(item => {
                                        return <p className={classes.tagP}>- {item}</p>
                                    })}
                                </Fragment>
                            } placement="right" classes={{ tooltipPlacementRight: classes.tooltipPlacementRight }}>
                                <Info className={classes.iconInfo} />
                            </Tooltip> */}
                        </span>
                    </Grid>
                }
            </Grid>
            {data.startDate && <Grid container className={classes.content}>
                <Scrollbars universal={true}>
                    <Grid container className={classes.paddingRight}>
                        <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.APSENCE_ID} data={data.absenceId} />
                        <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.LEAVE_NAME} data={data.leaveTypeName} />
                        <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.EMP_NAME} data={data.empName} />
                        <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.EMP_ID} data={data.empId} />

                        <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail
                                label={DETAIL_LEAVE.DEP_NAME}
                                data={data.depName ? data.depName : !data.divName && data.orgName} // ดักกรณี สมส ที่มี div แต่ไม่มี dep
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail
                                label={DETAIL_LEAVE.DIV_NAME}
                                data={data.divName ? data.divName : '-'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail
                                label={DETAIL_LEAVE.SEC_NAME}
                                data={data.secName ? data.secName : '-'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail
                                label={DETAIL_LEAVE.PART_NAME}
                                data={data.partName ? data.partName : '-'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail
                                label={DETAIL_LEAVE.LEAVE_STATE_NAME}
                                data={data.leaveStateName}
                            />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail
                                label={DETAIL_LEAVE.LEAVE_DURATION} //จำนวนวันลา
                                data={`${data.duration} วัน`}
                            />
                        </Grid> */}

                        <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail
                                label={DETAIL_LEAVE.LEAVE_REQUEST_DATE} //วันเวลาที่ยื่นใบลา
                                data={convertDateDisplay(new Date(data.createDate))}
                            />
                        </Grid>
                        {data.referenceId && <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail
                                label={DETAIL_LEAVE.LEAVE_REFERENCEID} //เลขที่ใบลาจากระบบเก่า
                                data={data.referenceId}
                            />
                        </Grid>}



                        {(data.leaveStateId === 6 || data.leaveStateId === 7 || data.leaveStateId === 8) ? /* สถานะใบลาเป็น ยกเลิก, รอพิจารณายกเลิก, รออนุญาตยกเลิก */

                            <Fragment>
                                {data.updateDate && <Grid item xs={12} sm={6} md={6}>
                                    <FormatDisplayDetail
                                        label={DETAIL_LEAVE.LEAVE_CANCEL_REQUEST_DATE} //วันที่ขอยกเลิก
                                        data={convertDateDisplay(new Date(data.updateDate))}
                                    />
                                </Grid>}

                                {data.updateBy && <Grid item xs={12} sm={6} md={6}>
                                    <FormatDisplayDetail
                                        label={DETAIL_LEAVE.LEAVE_CANCEL_BY_NAME} //ผู้ยกเลิก
                                        data={data.updateBy}
                                    />
                                </Grid>}


                                <Grid item xs={12} sm={6} md={6}>
                                    <FormatDisplayDetail
                                        label={DETAIL_LEAVE.LEAVE_CANCEL_REASON} //เหตุผลการยกเลิกใบลา
                                        data={data.rejectReason ? data.rejectReason : '-'}
                                    />
                                </Grid>
                                {data.cancelBy && <Grid item xs={12} sm={6} md={6}>
                                    <FormatDisplayDetail
                                        label={DETAIL_LEAVE.LEAVE_CANCEL_BY_SYSTEM} //ยกเลิกผ่านระบบ
                                        data={data.cancelBy}
                                    />
                                </Grid>}




                            </Fragment> :

                            /* สถานะใบลาที ***ไม่ใช่*** ยกเลิก, รอพิจารณายกเลิก, รออนุญาตยกเลิก */

                            <Fragment>
                                {data.updateDate && <Grid item xs={12} sm={6} md={6}>
                                    <FormatDisplayDetail
                                        label={DETAIL_LEAVE.LEAVE_EDIT_DATE} //วันที่แก้ไข
                                        data={convertDateDisplay(new Date(data.updateDate))}
                                    />
                                </Grid>}

                                {data.updateBy && <Grid item xs={12} sm={6} md={6}>
                                    <FormatDisplayDetail
                                        label={DETAIL_LEAVE.LEAVE_EDIT_BY} //ผู้แก้ไข
                                        data={data.updateBy}
                                    />
                                </Grid>}

                            </Fragment>


                        }


                        <FormatDisplayDetail xs={12} sm={12} md={12} label={DETAIL_LEAVE.PERIOD.NAME} />
                        <PeriodDisplayDetail leavePeriodId={data.leavePeriodId} disabled={true} />

                        {/* กรณีเงื่อนไข เลือกเป็นช่วงเวลา ให้แสดง */}
                        {data.leavePeriodId === 4 &&
                            <LeavePeriodTime startTime={`${data.startTime ? data.startTime.slice(0, 5) : `-`} น.`} endTime={`${data.endTime ? data.endTime.slice(0, 5) : `-`} น.`} />
                        }


                        <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.LEAVE_START_DATE} data={convertDateDisplay(new Date(data.startDate))} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.LEAVE_END_DATE} data={convertDateDisplay(new Date(data.endDate))} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormatDisplayDetail label={DETAIL_LEAVE.LEAVE_DURATION} data={`${data.duration} วัน`} />
                        </Grid>

                        {data.leaveTypeId === 1 &&
                            <Grid item xs={12} sm={6} md={6}>
                                <LeaveSick sickReasonName={ data.sickReasonId && data.sickReasonId.toString() === '13' ? data.sickOther : data.sickReasonName} />
                            </Grid>
                        }

                        {/* เข้าหมด ยกเว้นลาคลอด */}
                        {data.leaveTypeId !== 4 &&
                            <Grid item xs={12} sm={6} md={6}>
                                <FormatDisplayDetail xs={12} md={12} label={DETAIL_LEAVE.LEAVE_REASON} data={data.leaveReason ? data.leaveReason : '-'} />
                            </Grid>
                        }

                        {/* กรณีลาช่วยภรรยาเลี้ยงดูบุตร */}
                        {data.leaveTypeId === 6 &&
                            <Grid item xs={12} sm={6} md={6}>
                                <LeaveCareChild wifeName={data.wifeName} childName={data.childName} childBirthDate={data.childBirthDate} />
                            </Grid>
                        }

                        {/* ลาบวช */}
                        {data.leaveTypeId === 7 &&

                            <Grid item xs={12} sm={6} md={6}>
                                <LeaveOrdain
                                    ordinationHall={data.ordinationHall}
                                    ordinationAddress={data.ordinationAddress}
                                    temple={data.temple}
                                    templeAddress={data.templeAddress}
                                /></Grid>

                        }

                        {data.leaveTypeId !== 7 &&
                            <Grid item xs={12} sm={6} md={6}>
                                <FormatDisplayDetail xs={12} md={12} label={DETAIL_LEAVE.LEAVE_CONTACT_ADDRESS} data={data.contactAddress ? data.contactAddress : '-'} />
                            </Grid>
                        }

                        {/* เข้าหมดยกเว้นลากิจ กับเลี้ยงดูบุตร */}
                        {data.leaveTypeId !== 2 && data.leaveTypeId !== 5 &&
                            <Grid item xs={12} className={classes.attachments} >
                                <span className={classes.labelForm}>ไฟล์แนบ:</span>{data.attachments.length === 0 ? '-' : " "}
                                {data.attachments &&
                                    data.attachments.map((item, index) => {
                                        if (data.attachments.length - 1 > index) {
                                            return (
                                                <Fragment>
                                                    <span className={classes.linkDownload} onClick={() => onClickDownload(item.attachment, item.uri)}>{item.name}</span>
                                                    <span>{` , `}</span>
                                                </Fragment>
                                            )
                                        } else {
                                            return <span className={classes.linkDownload} onClick={() => onClickDownload(item.attachment, item.uri)}>{item.name}</span>
                                        }
                                    })
                                }
                            </Grid>
                        }
                    </Grid>
                </Scrollbars>
            </Grid >}
            <Grid container alignItems='flex-end' justify='center' className={classes.btn}>
                <Button
                    label='ปิด'
                    className={classes.button}
                    onClick={onCancelClick}
                />
            </Grid>
        </Grid >
    )
}

export default LeaveSAPDetailAbsenceView

const LeavePeriodTime = ({ startTime, endTime }) => {
    return (
        <Fragment>
            <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.LEAVE_START_TIME} data={startTime} />
            <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.LEAVE_END_TIME} data={endTime} />
        </Fragment>
    )
}

const LeaveSick = ({ sickReasonName }) => {
    return (
        <Fragment>
            <FormatDisplayDetail xs={12} md={12} label={DETAIL_LEAVE.LEAVE_SICK} data={sickReasonName} />
        </Fragment>
    )
}

const LeaveCareChild = ({ wifeName, childName, childBirthDate }) => {
    return (
        <Fragment>
            <FormatDisplayDetail xs={12} md={12} label={DETAIL_LEAVE.LEAVE_WIFE_NAME} data={wifeName} />
            <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.LEAVE_CHILD_NAME} data={childName} />
            <FormatDisplayDetail xs={12} sm={6} md={6} label={DETAIL_LEAVE.LEAVE_CHILD_BIRTH_DATE} data={childBirthDate ? convertDateDisplay(new Date(childBirthDate)) : childBirthDate} />
        </Fragment>
    )
}

const LeaveOrdain = ({ ordinationHall, ordinationAddress, temple, templeAddress }) => {
    return (
        <Fragment>
            <FormatDisplayDetail xs={12} md={12} label={DETAIL_LEAVE.LEAVE_ORDINATION_HALL} data={ordinationHall} />
            <FormatDisplayDetail xs={12} md={12} label={DETAIL_LEAVE.LEAVE_ORDINATION_ADDRESS} data={ordinationAddress} />
            <FormatDisplayDetail xs={12} md={12} label={DETAIL_LEAVE.LEAVE_TEMPLE} data={temple} />
            <FormatDisplayDetail xs={12} md={12} label={DETAIL_LEAVE.LEAVE_TEMPLE_ADDRESS} data={templeAddress} />
        </Fragment>
    )
}