import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    content: {
        padding: '24px'
    },
    paper: {
        width: '100%',
        ...theme.paperPadding
    },
    divStyle: {
        width: '100%',
    }
}));

const BookingByDateView = ({ children }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Scrollbars universal={true}>
                <Grid container className={classes.content}>
                    <Paper className={classes.paper}>
                        {children[0]}
                        {children[1]}
                    </Paper>
                </Grid>
            </Scrollbars>
        </Grid>
    );
};

export default BookingByDateView;
