import React from 'react';

import BookingByDateProvider from './BookingByDateProvider';
import BookingByDate from './form/BookingByDate';
import UtilsPage from './UtilsPage'

const BookingByDateIndex = () => {
    return (
        <BookingByDateProvider>
            <BookingByDate />
            <UtilsPage />
        </BookingByDateProvider>
    );
};

export default BookingByDateIndex;
