
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FixedSizeList } from 'react-window';
import classnames from 'classnames';
import FormControl from '@material-ui/core/FormControl'

import Focus from '../focus/Focus'
import * as FocusAction from '../../actions/FocusAction'
import { usePrevious } from '../StateFunctions'

const useStyles = makeStyles(theme => ({
    listbox: {
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
    root: {
        height: theme.field.height
    },
    input: {
        boxShadow: theme.field.boxShadow,
        height: theme.field.height,
        '& input': {
            height: theme.field.height,
            // paddingTop: '0px',
            // paddingBottom: '0px',
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        '& svg': {
            width: '20px',
            height: '20px'
        },
    },
    inputError: {
        boxShadow: theme.field.boxShadow,
        height: theme.field.height,
        '& input': {
            height: theme.field.height,
            // paddingTop: '0px',
            // paddingBottom: '0px',
            padding: theme.field.padding,
            fontSize: theme.fontSize.body1
        },
        '& svg': {
            width: '20px',
            height: '20px'
        },
        '& fieldset': {
            borderColor: `${theme.mainTheme.color5} !important`
        }
    },
    labelStyle: {
        fontSize: "20px",
        color: theme.mainTheme.color1
    },
    control: {
        width: '100%',
        // height: '70px'
    },
    marginNormal: {
        marginTop: '0px !important',
        marginBottom: '0px !important'
    },
    formHelper: {
        marginLeft: '10px',
        color: theme.mainTheme.color5,
        marginTop: '1px'
    },
    endAdornment: {
        display: 'contents'
    },
    divControl: {
        height: '90px'
    },
    txtErr: {
        color: 'red'
    }
}));
let dupValueInsert;

const renderRow = (props) => {
    const { data, index, style } = props;

    return React.cloneElement(data[index], {
        style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
            boxSizing: 'border-box !important',
            fontSize: '20px',
            ...style,
        },
    });
}

let optionnInsertBackup = null;

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    let { children, ...other } = props;

    if (children[0].props['aria-disabled'] && !optionnInsertBackup) {
        optionnInsertBackup = children[0]
    }
    const classes = useStyles()

    const outerElementType = React.useMemo(() => {
        return React.forwardRef((props2, ref2) => <div ref={ref2} {...props2} {...other} className={classes.listbox} />);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isCanInsertConstant) {
        const enterToInsert = children.filter(item => item.props.children === inputBackup).length === 0
        if (enterToInsert) {
            if (!children[0].props['aria-disabled'] && (inputBackup !== null || inputBackup !== "")) {
                // children = [optionnInsertBackup, ...children]
                children.push(optionnInsertBackup)
                children = children.map((item, index) => {
                    const newItem = {
                        ...item,
                        key: index,
                        props: {
                            ...item.props,
                            id: `autocomplete-option-${index}`,
                            'data-option-index': index
                        }
                    }

                    return newItem
                })
            } else {
                children.shift()
            }
        } else {
            children = [...children]
        }
    }

    const itemCount = Array.isArray(children) ? children.length : 0
    const itemSize = 40

    return (
        <div ref={ref} style={{ display: 'block', }}>
            <FixedSizeList
                itemData={children}
                height={250}
                width="100%"
                outerElementType={outerElementType}
                innerElementType="ul"
                itemSize={itemSize}
                overscanCount={10}
                itemCount={itemCount}
                style={{ padding: 0, height: Math.min(6, itemCount) * itemSize, maxHeight: 'auto' }}
            >
                {renderRow &&
                    renderRow
                }
            </FixedSizeList>
        </div>
    );
});

let bt;
let optionKeyBackup;
let inputBackup;
let isCanInsertConstant = false

function Virtualize(props) {
    const classes = useStyles();
    let { options, id = 'autocomplete' } = props

    const refInput = React.createRef()

    setTimeout(() => {
        if (refInput.current) {
            bt = refInput.current.getElementsByClassName("MuiButtonBase-root MuiIconButton-root MuiAutocomplete-clearIndicator MuiAutocomplete-clearIndicatorDirty");
            let btAction = refInput.current.getElementsByClassName('MuiAutocomplete-popupIndicator')

            if (btAction) {
                btAction[0].getElementsByTagName('svg')[0].style = 'width: 25px; height: 25px'
            }

            if (bt && bt.length > 0 && bt[0].name === "") {
                bt[0].name = input.name
                bt[0].getElementsByTagName('svg')[0].style = 'width: 15px; height: 15px'
                bt[0].style = 'display: none'
            }
        }
    }, 300)

    const {
        optionKey = { label: "name", value: "id", abbr: null, abbr2: null },
        isCanInsert = false,
        isHideFormHelperText,
        isHideLabel = false,
        isShowNoSelect = true,
        required,
        input,
        onChangeDropDown,
        label,
        meta: { touched, invalid, error, active },
        isDisabled,
        disabled,
        isEditValue,
        insertOptionFn,
        width,
        focusAction,
        focusName
    } = props

    optionKeyBackup = optionKey
    isCanInsertConstant = isCanInsert

    const asterisk = required ? <span style={{ color: '#FF0000' }} > *</span> : "";
    const errors = touched && invalid && error ? error : undefined;
    const isError = errors ? true : false
    const noSelect = { [optionKey.value]: -1, [optionKey.label]: "ไม่ระบุ" }
    const optionInsert = { [optionKey.value]: -1, [optionKey.label]: "Enter เพื่อสร้างรายการตัวเลือก" }

    const [isReady, setIsReady] = React.useState(true)
    const prevProp = usePrevious(active)

    if (!required && isShowNoSelect) {
        options = [noSelect, ...options]
    }

    if (isCanInsert) {
        options = [optionInsert, ...options]
    }

    const getOptionLabel = option => {
        if (option[optionKey.label] === undefined && isCanInsert) {
            return option
        } else if (optionKey.abbr && option[optionKey.abbr] !== -1) {
            let abbrOption = option[optionKey.abbr] ? `(${option[optionKey.abbr]})` : ""

            if (optionKey.abbr2 && option[optionKey.abbr2] !== -1) {
                abbrOption = option[optionKey.abbr2] ? `${abbrOption} (${option[optionKey.abbr2]})` : abbrOption
            }

            return `${option[optionKey.label]} ${abbrOption}`
        } else {
            return option[optionKey.label]
        }
    }

    const value = isEditValue && isReady ?
        isEditValue.info : options.filter(option => option[optionKey.value] === input.value || option[optionKey.value] === input.value[optionKey.value] || option[optionKey.label] === input.value)[0];

    const handleChange = (evt, values) => {
        if (!values) {
            input.onChange(null)
            onChangeDropDown && onChangeDropDown()
        } else {
            if (isCanInsert && (values === undefined || values === null || !values)) {
                input.onChange(evt.target.value)
            } else {
                if (values[optionKey.value] === -1) {
                    input.onChange(null)
                    onChangeDropDown && onChangeDropDown()
                } else {
                    if (isCanInsert) {
                        input.onChange(values)
                    } else {
                        input.onChange(values[optionKey.value])
                    }
                    onChangeDropDown && onChangeDropDown(values)
                }
            }
        }
    }

    if (isEditValue && isReady) {
        input.onChange(isEditValue.info)
        setIsReady(false)
    }

    const insertOption = () => {
        const newOptions = options.filter(item => item[optionKey.value] === dupValueInsert || item[optionKey.label] === dupValueInsert).length < 1
        if (newOptions) {
            options = [{ [optionKey.label]: dupValueInsert }, ...options]
            insertOptionFn && insertOptionFn(options)
            input.onChange(dupValueInsert)
        }
    }

    const controlButton = (type) => {
        if (refInput.current && bt && bt.length > 0) {
            if (type === 'open') {
                bt[0].style = 'display: block'
            } else {
                bt[0].style = 'display: none'
            }
        }
    }

    const gotoSavePoint = (evt) => {
        if (isCanInsert) {
            inputBackup = evt.target.value
        }
    }

    const focus = Focus()
    if (focusName === input.name) {
        const focusSuccess = focus.focusFn(!prevProp && !active && touched && invalid, focusName, input.name)
        if (focusSuccess) {
            focusAction.setDefault()
        }
    }

    return (
        <Fragment>
            <div className={classes.divControl}>
                <FormControl className={classes.control} error={isError} disabled={isDisabled || disabled} >
                    {!isHideLabel && <span className={classes.labelStyle}>
                        {label ? <label className={classnames({ [classes.txtErr]: isError })} required>{label}{asterisk}</label> : <div style={{ height: '28px' }} />}
                    </span>}

                    <Autocomplete
                        id={id}
                        style={width ? { width: `${parseInt(width)}px` } : { width: '100%' }}
                        classes={{ popup: classes.popup, root: classes.root }}
                        className={classes.autoComplete}
                        getOptionDisabled={options => required && options[optionKey.value] === -1}
                        options={options}
                        getOptionLabel={getOptionLabel}
                        ListboxComponent={ListboxComponent}
                        onChange={handleChange}
                        value={value ? value : null}
                        ref={refInput}
                        noOptionsText={isCanInsert ? "Enter เพื่อสร้างรายการตัวเลือก" : 'ไม่พบข้อมูล'}
                        onOpen={() => controlButton('open')}
                        onClose={() => controlButton('close')}
                        autoSelect={true}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                InputProps={
                                    errors ?
                                        { ...params.InputProps, type: 'search', className: classes.inputError } :
                                        { ...params.InputProps, type: 'search', className: classes.input }
                                }
                                inputProps={{ ...params.inputProps, autocomplete: 'off' }}
                                onKeyPress={(evt) => {
                                    if (evt.charCode === 13 && isCanInsert) { insertOption() }
                                }}
                                onKeyDown={evt => dupValueInsert = evt.target.value}
                                placeholder={required ? 'กรุณาเลือก' : 'ไม่ระบุ'}
                                onChange={gotoSavePoint}
                                inputRef={(ref) => {
                                    if (ref) {
                                        ref.setAttribute('name', input.name)
                                        focus.manageDataFocus(ref)
                                    }
                                }}
                            />
                        )}
                        disabled={disabled || isDisabled}
                    />
                </FormControl>
                {!isHideFormHelperText && <FormHelperText className={classes.formHelper}>{errors}</FormHelperText>}
            </div>
        </Fragment>
    );
}

const mapStateTopProps = (state) => ({
    focusName: state.focus.name
})

const mapDispatchToProps = (dispatch) => ({
    focusAction: bindActionCreators(FocusAction, dispatch)
})

export default connect(mapStateTopProps, mapDispatchToProps)(Virtualize)

