export default {
    LOGIN: '/login',
    LEAVE: '/leave',
    LEAVE_ORG: '/leave/orgLeave',
    LEAVE_HOLIDAY: '/leave/holiday',
    LEAVE_SAP: '/leave/leavesap',
    LEAVE_SAP_EDIT: '/leave/leavesap/edit',
    LEAVE_SAP_DETAIL: '/leave/leavesap/detail',
    LEAVE_ABSENCE: '/leave/absence',
    LEAVE_COLLECT: '/leave/leavecollect',
    MEETING: '/meeting',

    MEETING_MANAGE: '/meeting/management',
    MEETING_MANAGE_ADD: '/meeting/management/add',
    MEETING_MANAGE_EDIT: '/meeting/management/edit',
    MEETING_BOOKING: '/meeting/booking',
    MEETING_BOOKING_TABLE: '/meeting/booking/table',
    MEETING_BOOKING_CALENDAR: '/meeting/booking/calendar',
    MEETING_DAILY_BOOKING: '/meeting/dailybooking',
    MEETING_CANCELLED_BOOKING: '/meeting/cancelledBooking',

    REGISTRATION: '/registration',
    EV: '/ev',
    UM: '/usermanagement',
    UM_USER_GROUP: '/usermanagement/usergroup',
    UM_USER_GROUP_ADD: '/usermanagement/usergroup/add',
    UM_USER_GROUP_EDIT: '/usermanagement/usergroup/edit',
    UM_USER: '/usermanagement/user',

    MAINTENANCE: '/maintenance',
    MAINTENANCE_CONNEXT: '/maintenance/connext',
    MAINTENANCE_TEMPLATE: '/maintenance/template',
    MAINTENANCE_FORCE_UPDATE: '/maintenance/forceupdate',

    EMPLOYEE_PROFILE_MANAGEMENT: '/employee-profile-management',
    EMPLOYEE_PROFILE_EDITING_HISTORY:
        '/employee-profile-management/editing-history',

    PUBLIC_MEETING: '/booking',
    PUBLIC_MEETING_DAILY_BOOKING: '/booking/dailybooking',
};
