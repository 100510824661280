import React from 'react'
import { Field } from 'redux-form'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css';

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid'

import { FORM } from '../../../../constants/maintenance/TemplateConstants'

import Button from '../../../../control/button/Button'
import TextEditor from '../../../../control/textEditor/TextEditor'
// import ErrorField from '../../../../control/errorField/ErrorField'

const { NAME } = FORM

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: '50%',
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        fontSize: '33px'
    },
    body: {
        padding: theme.spacing(3)
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    },
    errorField: {
        color: 'red',
        fontSize: "17px"
    },
    conError: {
        height: '20px',
        marginLeft: '10px'
    }
}));

const ErrorField = (props) => {
    const classes = useStyles();
    const { meta: { error } } = props

    return <span className={classes.errorField}>{error}</span>
}

const tools = [{ label: 'แทรกวันที่และเวลา', value: '{maintenanceDate}' }]

const ModalTemplateView = ({
    isOpen,
    onCancel,
    onSave
}) => {
    const classes = useStyles()

    return (
        <Modal
            className={classes.modal}
            open={isOpen}
        >
            <Grid
                container
                className={classes.paper}
                justify="center"
            >
                <Grid container className={classes.body}>
                    <Grid item xs={12} >
                        <span className={classes.title}>
                            จัดการข้อความปิดระบบ
                        </span>
                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                    <Grid item xs={12} >
                        <Tabs>
                            <TabList>
                                <Tab>TH</Tab>
                                <Tab>EN</Tab>
                            </TabList>

                            <TabPanel>
                                <Field
                                    name={NAME.MESSAGE_TH}
                                    component={TextEditor}
                                    tools={tools}
                                    maxLength={500}
                                    required
                                />
                            </TabPanel>
                            <TabPanel>
                                <Field
                                    name={NAME.MESSAGE_EN}
                                    component={TextEditor}
                                    tools={tools}
                                    maxLength={500}
                                    required
                                />
                            </TabPanel>
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} className={classes.conError}>
                        <Field
                            name={NAME.ERROR}
                            component={ErrorField}
                        />
                    </Grid>
                    <Grid item xs={12} container justify="center" spacing={2} >
                        <Grid item>
                            <Button
                                label="ยกเลิก"
                                onClick={onCancel}
                                className={classes.btnCancel}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                label="บันทึก"
                                onClick={onSave}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default ModalTemplateView
