import React from 'react';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import RouteConfig from '../../config/appRoute/RouteConfig';
import { FUNCTIONS } from '../../constants/APDConstants';

const MaintenanceMenu = [
    {
        functionId: FUNCTIONS.MAINTAINER_CONNEXT.id,
        icon: <AccessAlarmIcon />,
        label: 'จัดการปิดระบบ',
        to: RouteConfig.MAINTENANCE_CONNEXT,
    },
    {
        functionId: FUNCTIONS.MANAGE_TEMPLATES.id,
        icon: <InsertDriveFileIcon />,
        label: 'จัดการข้อความปิดระบบ',
        to: RouteConfig.MAINTENANCE_TEMPLATE,
    },
    {
        functionId: FUNCTIONS.FORCE_UPDATE.id,
        icon: <SystemUpdateIcon />,
        label: 'จัดการ Version Force Update',
        to: RouteConfig.MAINTENANCE_FORCE_UPDATE,
    },
    {
        functionId: '-1',
        icon: <LiveHelpIcon />,
        label: 'คู่มือ',
        to: window.URL_USERMANUAL.MAINTENANCE,
    },
];

export default MaintenanceMenu;
