// import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';
import { MEETING, ERRORS } from '../../../../constants/APDConstants';
import { newDateBytime } from '../../../../utilities/DatetimeUtils';
import { format } from 'date-fns';
// import { callServiceAddImage, callServiceEditImage } from '../../../../api/ApiMeeting';

export const setFormatInitialValues = (initialValue) => {
    return {
        [MEETING.FORM.NAME.MEA_DIST_ID]: `${initialValue.meaDistrictId}`,
        [MEETING.FORM.NAME.BD_NAME]: initialValue.building,
        [MEETING.FORM.NAME.ROOM_TYPE_ID]: initialValue.roomTypeId,
        [MEETING.FORM.NAME.ROOM_NAME_TH]: initialValue.roomNameTH,
        [MEETING.FORM.NAME.ROOM_NAME_EN]: initialValue.roomNameEN,
        [MEETING.FORM.NAME.FLOOR]: initialValue.floor,
        [MEETING.FORM.NAME.FLOOR_DISPLAY]: initialValue.floorDisplay,
        [MEETING.FORM.NAME.SEAT]: initialValue.seat,
        [MEETING.FORM.NAME.ADDITIONAL_SEAT]: initialValue.additionalSeat,
        [MEETING.FORM.NAME.ROOM_NO]: initialValue.roomNo,
        [MEETING.FORM.NAME.BOOKING_WEEKEND]: initialValue.bookingWeekend,
        [MEETING.FORM.NAME.OPEN_TIME]: newDateBytime(initialValue.openTime),
        [MEETING.FORM.NAME.CLOSE_TIME]: newDateBytime(initialValue.closeTime),
        [MEETING.FORM.NAME.AVAILABLE]: initialValue.available,
        [MEETING.FORM.NAME.DESCRIPTION]: initialValue.description,
        [MEETING.FORM.NAME.EQUIPMEMT]: initialValue.equipments,
        [MEETING.FORM.NAME.ORG_ID]: initialValue.orgId,
        [MEETING.FORM.NAME.MAINTAINER]: initialValue.maintainer,
        [MEETING.FORM.NAME.ONLY_MAINTAIN_ORG_ID]: initialValue.onlyMaintainOrgId,
        [MEETING.FORM.NAME.CONTACT]: initialValue.contact,
        [MEETING.FORM.NAME.PUBLICITY_TYPE_ID]: initialValue.publicityTypeId,
        [MEETING.FORM.NAME.PERMISSION_ORG]: initialValue.permissionOrg && initialValue.permissionOrg.map(permission => permission.orgId),
        [MEETING.FORM.NAME.PERMISSION_EMP]: initialValue.permissionEmp,
        // [MEETING.FORM.NAME.IMAGES]: initialValue.images && initialValue.images.map(image => ({ url: image.imageURL, imageURI: image.imageURI, thumbnailURI: image.thumbnailURI })),
        [MEETING.FORM.NAME.IMAGES]: initialValue.images && setFormatInitImage(initialValue.images),
        [MEETING.FORM.NAME.BOOKING_TYPE_ID]: initialValue.bookingTypeId,
        [MEETING.FORM.NAME.RESERVATION_DATE]: initialValue.reservationDate,
        [MEETING.FORM.NAME.COLOR]: initialValue.color
    }
}

export const setFormatInitImage = (images = []) => {
    return images.map(image => ({ url: getPathDisplayImageMeetingRoom(image.imageURI), imageURI: image.imageURI, thumbnailURI: image.thumbnailURI }))
}

const convertDataEquipment = (equipments) => {
    let equipmentData = ""

    const filterEquipments = equipments.filter(item => item.quantity > 0)

    filterEquipments.forEach((equipment, index) => {
        if (index !== 0) {
            equipmentData += "|"
        }
        equipmentData += `${equipment.equipmentId},${equipment.quantity}`
    });

    return equipmentData
}

export const setFormatValue = (value) => {
    return {
        uuid: value.uuid,
        roomNameTH: value.roomNameTH && value.roomNameTH.trimEnd(),
        roomNameEN: value.roomNameEN && value.roomNameEN.trimEnd(), // not require
        roomNo: value.roomNo && value.roomNo.trimEnd(),
        floor: value.floor,
        floorDisplay: value.floorDisplay && value.floorDisplay.trimEnd(), // not require
        building: value.building,
        meaDistrictId: value.meaDistrictId,
        seat: value.seat && value.seat, // not require
        additionalSeat: value.additionalSeat && value.additionalSeat, // not require
        openTime: format(value.openTime, 'HH:mm:ss'),
        closeTime: format(value.closeTime, 'HH:mm:ss'),
        available: value.available ? value.available : false,
        reservationDate: value.reservationDate,
        roomTypeId: value.roomTypeId,
        orgId: value.orgId && value.orgId, // not require
        bookingWeekend: value.bookingWeekend ? value.bookingWeekend : false,
        description: value.description && value.description.trimEnd(), // not require
        publicityTypeId: value.publicityTypeId, // ถ้าเลือกใช้ได่เฉพาะหน่วยงานจะส่ง 3 ไป
        bookingTypeId: value.bookingTypeId,
        contact: value.contact && value.contact.trimEnd(), // not require
        equipment: value.equipments && convertDataEquipment(value.equipments),
        maintainer: value.maintainer,
        permissionOrg: Number(value.bookingTypeId) === 3 ? value.permissionOrg.toString() : "", // not require
        permissionEmp: Number(value.bookingTypeId) === 3 ? value.permissionEmp.map(permission => permission.empId).toString() : "", // not require
        images: value.images,
        color: value.color
    }
}

export const setFormatValueImage = (images = []) => {
    // return images.map(image => ({ imageURI: image.imageURI, thumbnailURI: image.thumbnailURI }))
    return images.map(image => ({ imageURI: image.imageURI, thumbnailURI: image.thumbnailURI }))
}

const { NAME } = MEETING.FORM

export const validateMeeting = (values) => {
    const errors = {}

    if (!values[NAME.MEA_DIST_ID]) {
        errors[NAME.MEA_DIST_ID] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.BD_NAME]) {
        errors[NAME.BD_NAME] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.ROOM_TYPE_ID] || !values[NAME.ROOM_TYPE_ID].length === 0) {
        errors[NAME.ROOM_TYPE_ID] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.ROOM_NAME_TH]) {
        errors[NAME.ROOM_NAME_TH] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.ROOM_NAME_EN]) {
        errors[NAME.ROOM_NAME_EN] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.FLOOR]) {
        errors[NAME.FLOOR] = ERRORS.MEETING.REQUIRED
    } else if (parseInt(values[NAME.FLOOR]) > ERRORS.VALIDATE.LIMIT_FLOOR) {
        errors[NAME.FLOOR] = ERRORS.MEETING.LIMIT_FLOOR
    }

    if (!values[NAME.SEAT]) {
        errors[NAME.SEAT] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.ADDITIONAL_SEAT] && values[NAME.ADDITIONAL_SEAT] !== 0) {
        errors[NAME.ADDITIONAL_SEAT] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.ROOM_NO]) {
        errors[NAME.ROOM_NO] = ERRORS.MEETING.REQUIRED
    }

    if (values[NAME.BOOKING_WEEKEND] === undefined) {
        errors[NAME.BOOKING_WEEKEND] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.OPEN_TIME]) {
        errors[NAME.OPEN_TIME] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.CLOSE_TIME]) {
        errors[NAME.CLOSE_TIME] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.RESERVATION_DATE] && (values[NAME.RESERVATION_DATE] !== 0 || values[NAME.RESERVATION_DATE] === '0')) {
        errors[NAME.RESERVATION_DATE] = ERRORS.MEETING.REQUIRED
    } else if (values[NAME.RESERVATION_DATE] < 0 || values[NAME.RESERVATION_DATE] > 365) {
        errors[NAME.RESERVATION_DATE] = 'กรุณาระบุข้อมูล 0 - 365 วัน'
    }

    if (!values[NAME.ORG_ID]) {
        errors[NAME.ORG_ID] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.MAINTAINER]) {
        errors[NAME.MAINTAINER_FORM] = ERRORS.MEETING.REQUIRED
    } else if (values[NAME.MAINTAINER].length < 1) {
        errors[NAME.MAINTAINER_FORM] = ERRORS.MEETING.REQUIRED
    }

    if (!values[NAME.PUBLICITY_TYPE_ID]) {
        errors[NAME.PUBLICITY_TYPE_ID] = ERRORS.MEETING.REQUIRED
    }

    if (values[NAME.BOOKING_TYPE_ID] && values[NAME.BOOKING_TYPE_ID] === "3") {
        if ((!values[NAME.PERMISSION_EMP] || !values[NAME.PERMISSION_EMP].length) && (!values[NAME.PERMISSION_ORG] || !values[NAME.PERMISSION_ORG].length)) {
            errors[NAME.EMP] = ERRORS.MEETING.REQUIRED
            errors[NAME.PERMISSION_ORG] = ERRORS.MEETING.REQUIRED
        }
    }

    if (!values[NAME.BOOKING_TYPE_ID]) {
        errors[NAME.BOOKING_TYPE_ID] = ERRORS.MEETING.REQUIRED
    } else if (Number(values[NAME.BOOKING_TYPE_ID]) === 1 && Number(values[NAME.PUBLICITY_TYPE_ID]) === 2) {
        errors[NAME.BOOKING_TYPE_ID] = ERRORS.MEETING.REQUIRED
    }

    return errors
}

export const legalPath = (path) => {
    let legalPath = path;
    legalPath = legalPath.split('.').join('');
    legalPath = legalPath.split(':').join('');
    legalPath = legalPath.split('|').join('');
    legalPath = legalPath.split('<').join('');
    legalPath = legalPath.split('>').join('');
    legalPath = legalPath.split('"').join('');
    legalPath = legalPath.split('?').join('');
    legalPath = legalPath.replace(/\(\d*\)+$/, '');

    if (legalPath.charAt(0) === '\\') legalPath = legalPath.substr(1);
    return legalPath;
};


export const getPathDisplayImageMeetingRoom = (imageURI) => { // path ตอนแสดงผล
    return `${process.env.REACT_APP_ROOT_IMAGE_MEETINGROOM}/${imageURI}`
}

export const getFolderImageMeetingRoom = ({ building, floor, roomNameTH }) => { // path ตอนเก็บใน db
    // const path = `meeting/${building}/${floor}/${roomNameTH}`.replace(/\./g, '_')
    const path = legalPath(`meeting/${building}/${floor}/${roomNameTH}`)

    return path
}

export const getPathImageMeetingRoom = ({ building, floor, roomNameTH }) => { // path ตอน upload
    // const path = `meeting\\${building}\\${floor}\\${roomNameTH}`.replace(/\./g, '_')
    const path = legalPath(`meeting\\${building}\\${floor}\\${roomNameTH}`)

    return `${process.env.REACT_APP_FOLDER_UPLOAD_IMAGE_MEETINGROOM}\\${path}`
}