import React, { useEffect, useContext } from 'react';
import { reduxForm, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ORG_LEAVE } from '../../../../../constants/APDConstants';
import { validateEditOrg } from '../OrgLeaveUtils';
import { OrgLeaveContext } from '../OrgLeaveProvider'

import EditOrgView from '../../../presentational/orgLeave/panel/EditOrgView';

const EditOrg = ({
    initialize,
    handleSubmit,
    dirty,
    panelState
}) => {
    const { orgState, alertMessageState } = useContext(OrgLeaveContext)

    useEffect(() => {
        if (panelState.dataEditOrg) {
            initialize({
                [ORG_LEAVE.FORM_EDIT_ORG.NAME.NAME]: panelState.dataEditOrg.name,
                [ORG_LEAVE.FORM_EDIT_ORG.NAME.PERMISSION_AUDIT]: panelState.dataEditOrg.auditEmpId,
                [ORG_LEAVE.FORM_EDIT_ORG.NAME.PERMISSION_APPROVE]: panelState.dataEditOrg.approveEmpId,
            })
        }
    }, [panelState.dataEditOrg]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitOrg = (values) => {
        if (dirty) {
            if (panelState.dataEditOrg.isLeaveNode) { // เช็คว่าเป็น leave node
                saveOrgLeave(values)
            } else {
                alertMessageState.showAlert({
                    altMsg: `คุณต้องการแทนที่ผู้อนุญาตและผู้พิจารณาในหน่วยงานที่อยู่ภายใต้ ${panelState.dataEditOrg.name} ?`,
                    altType: 2,
                    btnCancelName: "ไม่แทนที่",
                    btnConfirmName: "แทนที่",
                    callbackConfirm: () => {
                        alertMessageState.hideAlert()
                        saveOrgLeave(values, true)
                    },
                    callbackCancel: () => {
                        alertMessageState.hideAlert()
                        saveOrgLeave(values, false)
                    }
                })
            }
        } else {
            panelState.setPanel()
        }
    }

    const saveOrgLeave = async (values, isReplaceAll) => {
        const { representEmpId, approveEmpId } = panelState.dataEditOrg;
        const orgUpdate = {
            ...panelState.dataEditOrg,
            ...values,
            prevRepresentEmpId: representEmpId,
            prevApproveEmpId: values.approveEmpId !== approveEmpId ? approveEmpId : null
        }
        await orgState.updateStructerOrg(orgUpdate, isReplaceAll)
        window.chart.draw()
        panelState.setPanel()
    }

    return (
        <EditOrgView
            isHaveRepresent={panelState.dataEditOrg.representEmpId}
            lutApprove={orgState.lutApprove}
            lutAudit={orgState.lutAudit}
            prevApproveName={panelState.dataEditOrg.prevApproveName}
            prevAuditName={panelState.dataEditOrg.prevAuditName}
            onSubmit={handleSubmit(handleSubmitOrg)}
        />
    )
}

const reduxFormParameter = {
    form: 'OrgLeaveEditOrg',
    validate: validateEditOrg,
};

const mapStateToProps = state => ({
    dirty: isDirty('OrgLeaveEditOrg')(state)
})

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps, null)
)(EditOrg)

