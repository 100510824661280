import React from 'react'
import TableUserGroupView from '../../presentational/usergroup/TableUserGroupView'

const TableUserGroup = ({
    dataTableState,

    onEdit,
    onDelete
}) => {

    const handleRowClick = (data, type) => {
        if (type === 'edit') {
            onEdit(data)
        } else {
            onDelete(data)
        }
    }

    return (
        <TableUserGroupView
            dataTableState={dataTableState}
            onRowClick={handleRowClick}
        />
    )
}

export default TableUserGroup
