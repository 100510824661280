import { useState } from 'react'

import { getLutLeaveState, getLutLeaveType, getLutSickReason, getLutSyncSapResult } from '../../../../../api/ApiAbsence';
import { getLutExclusiveOrgLeave, getCustomLutOrgLeave } from '../../../../../api/ApiOrgLeave'
import { ABSENCE, PERMISSION_ORG } from '../../../../../constants/APDConstants'

const UseLutSearchAbsenceState = ({ permissionOrgId, depId }) => {
    const [lut, setLut] = useState({
        lutLeaveState: [],
        lutLeaveType: [],
        lutOrg: [],
        lutSickReason: [],
        lutLeaveFlag: []
    })

    const setInitialLut = async () => {
        const getLutOrg = (permissionOrgId === PERMISSION_ORG.EXCLUSIVE_ORG) ? getLutExclusiveOrgLeave(depId) : getCustomLutOrgLeave()

        return Promise.all([
            getLutLeaveState(),
            getLutLeaveType(),
            getLutOrg,
            getLutSickReason(),
            getLutSyncSapResult()
        ]).then(([
            resLeaveState,
            resLeaveType,
            resOrg,
            resSickReason,
            resSyncSapResult
        ]) => {
            setLut({
                lutLeaveState: resLeaveState && resLeaveState.data ? [...resLeaveState.data, ABSENCE.LEAVE_STATE_IN_PROGRESS] : [],
                lutLeaveType: resLeaveType && resLeaveType.data ? resLeaveType.data : [],
                lutOrg: resOrg && resOrg.data ? resOrg.data : [],
                lutSickReason: resSickReason && resSickReason.data ? resSickReason.data : [],
                lutLeaveFlag: resSyncSapResult && resSyncSapResult.data ? resSyncSapResult.data : [],
            })
        })
    }

    return {
        lutLeaveState: lut.lutLeaveState,
        lutLeaveType: lut.lutLeaveType,
        lutOrg: lut.lutOrg,
        lutSickReason: lut.lutSickReason,
        lutLeaveFlag: lut.lutLeaveFlag,

        setInitialLut: setInitialLut
    }
}

export default UseLutSearchAbsenceState
