import React, { Fragment, useState, useEffect, useContext } from 'react';
import postscribe from 'postscribe';
import Script from 'react-load-script';
import '../../presentational/orgLeave/OrgLeave.css';

import { ORG_LEAVE } from '../../../../constants/APDConstants';
import { callServiceUpdateOrgLeave, callServiceUpdateOrgSap } from '../../../../api/ApiOrgLeave';
import { checkSuccessResService } from '../../../../utilities/Utils';
import { convertFormatUpdateOrgLeave, convertFormatUpdateOrgSap } from './OrgLeaveUtils';
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'
import { OrgLeaveContext } from './OrgLeaveProvider';
import * as alertMSGConstants from '../../../../constants/AlertMSGConstants';

import OrgLeaveView from '../../presentational/orgLeave/OrgLeaveView';
import Loading from '../../../../control/loading/Loading';
import AlertMessage from '../../../../control/alert/AlertMessage';
import ChildrenOrgLeaveView from '../../presentational/orgLeave/ChildrenOrgLeaveView';
import Temp from './Temp';

const OrgLeave = () => {
    const [isLoadComplete, setLoadComplete] = useState(false)

    const {
        panelState,
        loadingState,
        alertMessageState,
        orgState,
        slinksState,
        lupOrgState,

        isOrgSap,
        isPersonalOrg,
        initailOrgId,
        uuid,
        empId,
        permissionSystem
    } = useContext(OrgLeaveContext)

    useEffect(() => {
        window.setPanel = setPanel;
        window.setEnableSave = orgState.setEnableSave;
        window.onEditOrg = handleEdit
        loadingState.showLoading();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // clear chart
        return () => {
            window.setPanel = null;
            window.setEnableSave = null;
            window.chart = null;
            window.onEditOrg = null;
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // กรณีเปลื่ยน org ที่ display
        if (window.chart && orgState.structureOrg.length) {
            loadDataOrg(orgState.structureOrg)
        }
        panelState.setPanel();
    }, [orgState.orgID]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lupOrgState.isLoadLutComplate) {
            initialOrgChart()
        }
    }, [lupOrgState.isLoadLutComplate]) // eslint-disable-line react-hooks/exhaustive-deps

    const initialOrgChart = async () => {
        const resGetStructureOrg = await orgState.getStructureOrg(initailOrgId)
        loadingState.hideLoading();
        if (resGetStructureOrg.msg) {
            alertMessageState.showAlert({ altMsg: resGetStructureOrg.msg })
        }
    }

    const loadDataOrg = (structureOrg) => {
        window.chart.load(structureOrg)
        window.chart.draw();
        window.chart.fit();
    }

    const handleScriptError = () => {
        console.log('Load script error....')
        loadingState.hideLoading();
    }

    const handleScriptLoad = async () => {
        await postscribe('#rootOrgLeave', `<script language="javascript">
        OrgChart.templates.olivia.size = [300, 120];
        OrgChart.templates.olivia.node = '<rect class="box-node" x="0" y="0" width="300" height="120" rx="5" ry="5" fill="#444444" stroke="#cccccc" stroke-width="2"></rect><rect x="100" y="40" width="190" height="25" fill="#333333"></rect></rect>';
        OrgChart.templates.olivia.field_0 = '<text class="field_0" style="font-size: 20px;" fill="#ffffff" x="150" y="60" text-anchor="middle">{val}</text>';
        OrgChart.templates.olivia.field_1 = '<text class="field_1" style="font-size: 20px;" fill="#ffffff" x="150" y="80" text-anchor="middle">({val})</text>';  
        OrgChart.templates.olivia.img_0 = '<image xlink:href="{val}" x="245" y="10" width="15" height="15"></image>';
        OrgChart.templates.olivia.img_1 = '<image xlink:href="{val}" x="225" y="10" width="15" height="15"></image>';
        OrgChart.templates.olivia.img_2 = '<image xlink:href="{val}" x="205" y="10" width="15" height="15"></image>';

        OrgChart.templates.olivia.nodeMenuButton = '<g style="cursor:pointer;" transform="matrix(1,0,0,1,270,10)" onclick="{window.onEditOrg({id})}"  control-node-menu-id="{id}"><image xlink:href="${process.env.PUBLIC_URL}/img/leave/edit.svg" x="0" y="0" width="15" height="15"></image></g>';

        OrgChart.templates.change = Object.assign({}, OrgChart.templates.olivia);
        OrgChart.templates.change.node = '<rect class="box-node" x="0" y="0" width="300" height="120" rx="5" ry="5" fill="#444444" stroke="#737373" stroke-width="2"></rect><rect x="100" y="40" width="190" height="25" fill="#333333"></rect></rect>';

        OrgChart.templates.highlight = Object.assign({}, OrgChart.templates.olivia);
        OrgChart.templates.highlight.node = '<rect class="box-node" x="0" y="0" width="300" height="120" rx="5" ry="5" fill="#444444" stroke="#F47B15" stroke-width="2"></rect><rect x="100" y="40" width="190" height="25" fill="#333333"></rect></rect>';
        
        OrgChart.templates.tempOrg = Object.assign({}, OrgChart.templates.olivia);
        OrgChart.templates.tempOrg.field_0 = '<text class="field_0" style="font-size: 20px;" fill="#ffffff" x="150" y="70" text-anchor="middle">{val}</text>';
        OrgChart.templates.tempOrg.nodeMenuButton = ''
        OrgChart.templates.tempOrg.field_1 = ''

        OrgChart.slinkTemplates.children = Object.assign({}, OrgChart.slinkTemplates.orange)
        OrgChart.slinkTemplates.children.link  = '<path stroke-dasharray="4, 2" marker-start="url(#dotSlinkOrange)" marker-end="url(#arrowSlinkOrange)" stroke-linejoin="round" stroke="#63666a" stroke-width="2" fill="none" d="{d}" />';
        OrgChart.slinkTemplates.children.label = '<text dominant-baseline="middle" fill="#ff3300" alignment-baseline="middle" text-anchor="middle" x="{x}" y="{y}">{val}</text>';
        OrgChart.slinkTemplates.children.defs = '<marker id="arrowSlinkOrange" viewBox="0 0 10 10" refX="8" refY="5" markerWidth="6" markerHeight="6" orient="auto-start-reverse"><path fill="#862633" d="M 0 0 L 10 5 L 0 10 z" /></marker><marker id="dotSlinkOrange" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="5" markerHeight="5"> <circle cx="5" cy="5" r="5" fill="#862633" /></marker>';        

        OrgChart.slinkTemplates.parent = Object.assign({}, OrgChart.slinkTemplates.yellow);
        OrgChart.slinkTemplates.parent.link = '<path stroke-dasharray="4, 2" marker-start="url(#arrowSlinkYellow)" marker-end="url(#dotSlinkYellow)" stroke-linejoin="round" stroke="#00A49D" stroke-width="2" fill="none" d="{d}" />';
        OrgChart.slinkTemplates.parent.label = '<text dominant-baseline="middle" fill="#ff3300" alignment-baseline="middle" text-anchor="middle" x="{x}" y="{y}">{val}</text>';
        OrgChart.slinkTemplates.parent.defs = '<marker id="arrowSlinkYellow" viewBox="0 0 10 10" refX="8" refY="5" markerWidth="6" markerHeight="6" orient="auto-start-reverse"><path fill="#ff3300" d="M 0 0 L 10 5 L 0 10 z" /></marker><marker id="dotSlinkYellow" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="5" markerHeight="5"> <circle cx="5" cy="5" r="5" fill="#ff3300" /></marker>';        

        window.chart = new OrgChart(document.getElementById('OrgChartContainer'),
        {
            template: "olivia",
            nodeMenu: {
                edit: { text: "แก้ไข", onClick: ${handleEdit} ,icon: '<img src="${process.env.PUBLIC_URL}/img/leave/edit.svg" width="20" height="20" alt="edit">'},
            },
            tags: {
                change : { template: "change"},
                highlight : { template: "highlight"},
                tempOrg: {template:"tempOrg"}
            },
            expand: {
                allChildren: false
            },
            nodeBinding: {
                field_0: "name",
                field_1: "empCount",
                // field_1: "id",
                img_0: "img_permission_0",
                img_1: "img_permission_1",
                img_2: "img_permission_2",
            },
            searchFields: ["id", "name"],
            nodes: ${JSON.stringify(orgState.structureOrg)},
            enableDragDrop: ${permissionSystem.isChangeParentOrg}, 
            onUpdate: ${handleUpdate},
            onAdd:${handleAdd},
            onRemove: ${handleRemove},
            onRedraw:${handleRedraw},
            onClick:${handleClicknode},
            nodeMouseClick: null,
            layout: OrgChart.treeLeftOffset,
            sticky: false,
            enableSearch: false
        });
        window.chart.on('drop',${handleDrop});
        </script>`)
        setLoadComplete(true)
    }

    const setPanel = (active, initialData) => {
        panelState.setPanel(active, initialData)
    }

    const handleDrop = (sender, draggedNodeId, droppedNodeId) => {
        let isDrop = true

        sender.config.nodes.forEach(node => {
            if ((node.id === draggedNodeId || node.id === droppedNodeId) && node.isTempNode) {
                isDrop = false
            }
        })
        isDrop && window.setEnableSave(true)
        return isDrop
    }

    const handleClicknode = (sender, node) => {
        const id = node.id;
        const initialData = window.chart.config.nodes.filter(node => node.id == id)[0];
        if (!initialData.isTempNode) {
            window.setPanel("DETAIL_ORG", initialData)
        }
    }

    const handleEdit = (id) => {
        let initialData = null;
        let isLeaveNode = true;
        const nodeId = id ? id.toString() : null

        window.chart.config.nodes.forEach(node => {
            if (node.id === nodeId) {
                initialData = node;
            }

            if (node.pid === nodeId) {
                isLeaveNode = false;
            }
        });

        initialData.isLeaveNode = isLeaveNode;
        window.setPanel("EDIT_ORG", initialData)
    }

    const handleAdd = (id) => {
        window.chart.draw();
        return true
    }

    const handleRedraw = (sender) => {
        const elSearch = document.getElementById("search-orgLeave")
        const elConSearch = document.getElementById("container-search")

        if (elSearch) {
            elSearch.blur();
        }

        if (elConSearch) {
            document.getElementById('container-search').style.display = 'none';
        }
    }

    const handleRemove = async (sender, nodeId) => {
        window.chart.draw();
        return true
    }

    const handleUpdate = (sender, oldNode, newNode) => {
        window.chart.draw();
        return true
    }

    const callBackUpdateOrg = async () => {
        alertMessageState.hideAlert();
        loadingState.showLoading();
        const resGetStructureOrg = await orgState.getStructureOrg(orgState.orgID)
        loadDataOrg(resGetStructureOrg.structureOrg);
        loadingState.hideLoading();
        if (resGetStructureOrg.msg) {
            alertMessageState.showAlert({ altMsg: resGetStructureOrg.msg })
        }
    }

    const callServiceSaveOrg = async () => {
        loadingState.showLoading();
        let res = null;
        const structureOrg = window.chart.config.nodes

        if (orgState.sorce === ORG_LEAVE.SORCE.ORG_SAP) {
            const param = convertFormatUpdateOrgSap({
                uuid: uuid,
                updateBy: empId,
                empInActive: orgState.dataEmpInActive,
                structureOrg: structureOrg,
                emp: orgState.emp,
                empIdModify: orgState.empIdModify
            })
            res = await callServiceUpdateOrgSap(param)
        } else {
            const param = convertFormatUpdateOrgLeave({
                uuid: uuid,
                updateBy: empId,
                structureOrg: structureOrg,
                emp: orgState.emp,
                empIdModify: orgState.empIdModify
            })
            res = await callServiceUpdateOrgLeave(param)
        }

        loadingState.hideLoading();

        if (res && res.data && checkSuccessResService(res.data)) {
            alertMessageState.showAlert({ altMsg: alertMSGConstants.SAVE_SUCCESS, callbackCancel: callBackUpdateOrg })
        } else {
            const msg = checkResStatusService(res)
            alertMessageState.showAlert({ altMsg: msg ? msg : alertMSGConstants.SAVE_FAIL })
        }
    }

    const validateOrg = (structureOrg) => {
        const listOrgNoApprove = []

        structureOrg.forEach(org => {
            if (!org.approveEmpId && !org.representEmpId && !org.isTempNode) {
                listOrgNoApprove.push(org.name)
            }
        })

        return listOrgNoApprove
    }

    const handleSave = () => {
        if (orgState.sorce === ORG_LEAVE.SORCE.ORG_SAP) {
            const listOrgNoApprove = validateOrg(orgState.structureOrg)

            if (listOrgNoApprove.length) {
                alertMessageState.showAlert({ altMsg: `กรุณาระบุผู้อนุญาตที่หน่วยงาน ${listOrgNoApprove.toString()}` })
            } else {
                alertMessageState.showAlert({
                    altMsg: "คุณต้องการบันทึกการเปลี่ยนแปลงโครงสร้างที่แต่งตั้ง/โยกย้าย ?",
                    altType: 2,
                    btnConfirmName: "บันทึก",
                    callbackConfirm: callServiceSaveOrg
                })
            }
        } else {
            alertMessageState.showAlert({
                altMsg: "คุณต้องการบันทึกการเปลี่ยนแปลงโครงสร้าง ?",
                altType: 2,
                btnConfirmName: "บันทึก",
                callbackConfirm: callServiceSaveOrg
            })
        }
    }

    const refreshOrg = async () => {
        loadingState.showLoading();
        slinksState.clearSlink();
        const resGetStructureOrg = await orgState.getStructureOrg(orgState.orgID);
        loadDataOrg(resGetStructureOrg.structureOrg)
        loadingState.hideLoading();
        if (resGetStructureOrg.msg) {
            alertMessageState.showAlert({ altMsg: resGetStructureOrg.msg })
        }
    }

    const handleRefresh = () => {
        if (orgState.isEnableSave) {
            alertMessageState.showAlert({
                altMsg: " ข้อมูลยังไม่ถูกบันทึก คุณต้องการรีเฟรช ?",
                altType: 2,
                btnConfirmName: "รีเฟรช",
                callbackConfirm: () => {
                    alertMessageState.hideAlert()
                    refreshOrg()
                }
            })
        } else {
            refreshOrg()
        }
    }

    const renderOrgLeaveView = () => {
        return (
            <OrgLeaveView
                isEnableSave={orgState.isEnableSave}
                isOrgSap={isOrgSap}
            >
                <ChildrenOrgLeaveView
                    isPersonalOrg={isPersonalOrg}

                    onSave={handleSave}
                    onRefresh={handleRefresh}
                />
            </OrgLeaveView>
        )
    }

    const renderUtils = () => {
        return (
            <Fragment>
                {!isLoadComplete && <Script
                    url={`${process.env.PUBLIC_URL}/orgchart.js`}
                    onError={handleScriptError}
                    onLoad={handleScriptLoad}
                />}
                <Loading isShowLoading={loadingState.isShowLoading} />
                <AlertMessage {...alertMessageState.altProps} />
                <Temp />
            </Fragment>
        )
    }

    return (
        <Fragment>
            {renderOrgLeaveView()}
            {renderUtils()}
        </Fragment>
    )
}

export default OrgLeave
