import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import RouteConfig from '../config/appRoute/RouteConfig';
import { FUNCTIONS } from '../constants/APDConstants';

import EditingHistory from '../components/employee-profile-management/EditingHistory';

function Component({ authenRdc }) {
    const router = [];
    authenRdc.profile.functions.forEach((func) => {
        switch (func.functionId) {
            case FUNCTIONS.EMPLOYEE_PROFILE_EDITING_HISTORY.id:
                router.push(
                    <Route
                        key={RouteConfig.EMPLOYEE_PROFILE_EDITING_HISTORY}
                        path={RouteConfig.EMPLOYEE_PROFILE_EDITING_HISTORY}
                        component={EditingHistory}
                    />
                );
                break;
            default:
                break;
        }
    });
    return (
        <Switch>
            {router}
            <Route
                path={RouteConfig.EMPLOYEE_PROFILE_MANAGEMENT}
                render={() => {
                    const pathRedirect = router.length ? router[0].key : '/';
                    return <Redirect to={pathRedirect} />;
                }}
            />
        </Switch>
    );
}

const mapStateToProps = (state) => ({
    authenRdc: state.authen,
});

export default connect(mapStateToProps)(Component);
