import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import DataTable from '../../../../control/table/DataTable';

const column = [
    { title: 'เลขที่ใบลา', data: 'absenceId', width: '10%', sort: 'asc', align: 'left', type: 'number' },
    { title: 'ช่วงวันที่ลา', data: 'leaveDate', width: '23%', sort: 'asc', type: 'date' },
    { title: 'วันที่เข้า SAP', data: 'submitDateDisplay', width: '18%', sort: 'asc', type: 'date' },
    { title: 'สถานะการส่ง SAP', data: 'responseTypeIdDisplay', width: '14%', sort: 'asc' },
    { title: 'รหัสพนักงาน', data: 'empId', width: '10%', sort: 'asc' },
    { title: 'ชื่อ-นามสกุล ผู้ยื่นใบลา', data: 'empName', width: '25%', sort: 'asc', align: 'left' },
];

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '33px'
    },
    summary: {
        textAlign: 'end',
        alignSelf: 'flex-end'
    },
    tableCell: {
        height: 'auto',
        padding: '0px 20px'
    }
}));

const LeaveSAPTableView = props => {
    const classes = useStyles();
    const { dataTableState, onRowClick } = props;

    const succ = dataTableState ? dataTableState.dataTable.filter(item => item.responseTypeId === 'S').length : 0;
    const err = dataTableState ? dataTableState.dataTable.filter(item => item.responseTypeId === 'E').length : 0;
    const other = dataTableState ? dataTableState.dataTable.filter(item => item.responseTypeId !== 'S' && item.responseTypeId !== 'E').length : 0;
    const all = dataTableState ? dataTableState.dataTable.length : 0;

    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <span className={classes.title}>
                            ตรวจสอบผลลัพธ์การนำเข้า SAP
                        </span>
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.summary}>
                        <span>{`พบผลลัพธ์ทั้งหมด ${all} รายการ นำเข้าสำเร็จ ${succ} รายการ นำเข้าไม่สำเร็จ ${err} รายการ และอื่นๆ ${other} รายการ`}</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    column={column}
                    data={dataTableState.dataPage}
                    page={dataTableState.page}
                    rowPerPage={dataTableState.rowPerPage}
                    total={dataTableState.total}
                    sortColumn={dataTableState.sortColumn}
                    sortType={dataTableState.sortType}
                    classes={{ tableCell: classes.tableCell }}
                    handlePageChange={dataTableState.changePage}
                    handleRowClick={onRowClick}
                    handleSortExternal={dataTableState.sortData}
                    handleRowPerPage={() => { }}
                />
            </Grid>
        </Fragment>
    );
};

export default LeaveSAPTableView;
