import React, { Fragment, useState, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import CheckIcon from '@material-ui/icons/Check';


import HolidayView from '../../presentational/holiday/HolidayView';
import AddHoliday from './add/AddHoliday';
import EditHoliday from './edit/EditHoliday';
import ExportHoliday from './export/ExportHoliday';

import { setFormatDataExport } from './HolidayUtils'
import AlertMessage from '../../../../control/alert/AlertMessage';
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';
import Loading from '../../../../control/loading/Loading';
import UseLoadingState from '../../../../control/loading/UseLoadingState';

import {
    callServiceGetHolidayByYear,
    deleteHoliday,
    getLogAbsence,
    getYearHoliday
} from '../../../../api/ApiHoliday';
import {
    sortObjASC,
    sortObjDESC,
    sortObjNumberASC,
    sortObjNumberDESC,
    sortObjDateASC,
    sortObjDateDESC
} from '../../../../control/ManageDataFunctions';

import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';
import {
    CONTROL_TABLE,
    SUB_FUNCTION
} from '../../../../constants/APDConstants';
import * as HolidayConstants from '../../../../constants/leave/HolidayConstants';
import { PermissionSubFuncUtils } from '../../../../utilities/PermissionUtils';
import { convertDateDisplay } from '../../../../utilities/DatetimeUtils'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'

import LogAbsenceExcel from './report/LogAbsenceExcel';

const Holiday = props => {
    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();

    const defaultYear = new Date().getFullYear() + 543;
    const rowPerPage =
        window.innerHeight > 800
            ? Number((window.innerHeight - 453) / 48) - 1
            : Number((window.innerHeight - 400) / 48) - 1;
    const [year, setYear] = useState(defaultYear);
    const [isBack, setIsBack] = useState(true);
    const [isNext, setIsNext] = useState(true);
    const [formAdd, setFormAdd] = useState(false);
    const [formEdit, setFormEdit] = useState(false);
    const [formExport, setFormExport] = useState(false);
    const [data, setData] = useState([]);
    const [lutHoliday, setLutHoliday] = useState([]);
    const [total, setTotal] = useState();
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState('asc');
    const [initialEdit, setInitialEdit] = useState({});
    const [yearsList, setYearsList] = useState([]);
    const specialColumn = {
        dateDisplay: 'date',
        dayName: 'dayofweek'
    }

    const permissionSystem = {
        permisAdd: PermissionSubFuncUtils(SUB_FUNCTION.ADD_HILIDAY),
        permisEdit: PermissionSubFuncUtils(SUB_FUNCTION.EDIT_HOLIDAY),
        permisDelete: PermissionSubFuncUtils(SUB_FUNCTION.DELETE_HOLIDAY),
        permisExport: PermissionSubFuncUtils(SUB_FUNCTION.EXPORT_HOLIDAY)
    };

    const [dateDup, setDateDup] = useState([]);

    useEffect(() => {
        callServiceGetHolidayYear()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const callServiceGetHolidayYear = () => {
        loadingState.showLoading();
        getYearHoliday().then(res => {
            if (res && res.data && res.data.year) {
                manageYear(res.data.year);
            } else {
                loadingState.hideLoading();
                const msg = checkResStatusService(res)
                alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
            }
        });
    }

    const manageYear = data => {
        const newFormat = data.map(item => {
            const newItem = {
                en: Number(item),
                th: Number(item) + 543
            };

            return newItem;
        });

        const useDefault = newFormat.filter(item => item.th === year);

        if (useDefault.length > 0) {
            setYear(useDefault[0].th)
            props.change('year', useDefault[0].th);
            callServiceGet(useDefault[0].th);
            manageBtn(useDefault[0], newFormat);
        } else {
            //ปีปัจจุบัน
            const presentYear = newFormat.filter(item => item.th === defaultYear)

            if (presentYear.length > 0) {
                setYear(presentYear[0].th)
                props.change('year', presentYear[0].th);
                callServiceGet(presentYear[0].th);
                manageBtn(presentYear[0], newFormat);
            } else {
                const yearOther = newFormat[newFormat.length - 1].th
                setYear(yearOther)
                props.change('year', yearOther);
                callServiceGet(yearOther);
                manageBtn(newFormat[newFormat.length - 1], newFormat);
            }

        }

        setYearsList(newFormat);
    };



    const callServiceGet = async (yearBD = year) => {
        const patt = new RegExp(/\D/g);
        const isString = patt.test(yearBD);
        if (!isString) {
            loadingState.showLoading();
            const yearAD = yearBD - 543;

            const res = await callServiceGetHolidayByYear(yearAD)
            const dateUsed = [];
            let dataHoliday = [];
            let lutHoliday = [];

            loadingState.hideLoading();

            if (res && res.data) {
                dataHoliday = res.data.filter(item => item.isActive).map((item, index) => {
                    const date = new Date(item.date);
                    date.setHours('00');
                    dateUsed.push(date.toString());

                    item.id = index + 1;
                    item.dayName = HolidayConstants.NAME_OF_DAY.filter(
                        itemFilter =>
                            itemFilter.id ===
                            new Date(item.date).getDay()
                    )[0].name;
                    item.enabled = item.enabled && (
                        <CheckIcon style={{ color: '#02A200' }} />
                    );
                    item.edit = permissionSystem.permisEdit; //wait
                    item.delete = permissionSystem.permisDelete; //wait
                    item.dateDisplay = convertDateDisplay(item.date)
                    item.dayofweek = moment(item.date).format('E')

                    return item;
                });
                lutHoliday = res.data.map(item => ({ ...item, dateDisplay: convertDateDisplay(item.date) }))
            } else {
                const msg = checkResStatusService(res)
                alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
            }

            setDateDup(dateUsed);
            setData(dataHoliday);
            setLutHoliday(lutHoliday);
        }
    };

    const changeYear = int => {
        const filterYear = yearsList.filter(item => item.th === year);
        const indexInList = yearsList.indexOf(filterYear[0]);
        const useYear = yearsList.filter(
            (item, index) => index === indexInList + int
        );
        callServiceGet(useYear[0].th);
        props.change('year', useYear[0].th);
        setYear(useYear[0].th);

        manageBtn(useYear[0]);
    };

    const manageBtn = (useYear, yl) => {
        const next = yl ? yl.indexOf(useYear) === yl.length - 1 : yearsList.indexOf(useYear) === yearsList.length - 1;
        const back = yl ? yl.indexOf(useYear) === 0 : yearsList.indexOf(useYear) === 0;

        next ? setIsNext(false) : setIsNext(true);
        back ? setIsBack(false) : setIsBack(true);
    };

    const handleYearChange = type => {
        switch (type) {
            case HolidayConstants.BACK_YEAR:
                changeYear(-1);
                break;
            case HolidayConstants.NEXT_YEAR:
                changeYear(+1);
                break;
            default:
                break;
        }
    };

    const handleYearInputChange = value => {
        if (value.length >= 4) {
            const sliceValue = value.slice(0, 4);
            if (Number(sliceValue) !== Number(year)) {
                callServiceGet(sliceValue);
                setYear(Number(sliceValue));
            }

            return value.slice(0, 4);
        } else {
            return value;
        }
    };

    const handleSort = (column, type) => {
        setSortColumn(column);
        setSortType(type);
        handleSortData(column, type);
    };

    const handleSortData = (column, type) => {

        if (specialColumn[column]) {
            column = specialColumn[column]
        }

        switch (column) {
            case 'id':
            case HolidayConstants.TABLE.DATA.DAY_NAME:
                if (type === CONTROL_TABLE.SORT.ASC) {
                    const newDataSort = sortObjNumberASC(data, column);
                    setTotal(newDataSort.length);
                    setData(newDataSort);
                } else {
                    const newDataSort = sortObjNumberDESC(data, column);
                    setTotal(newDataSort.length);
                    setData(newDataSort);
                }
                break;
            case HolidayConstants.TABLE.DATA.DATE:
                if (type === CONTROL_TABLE.SORT.ASC) {
                    const newDataSort = sortObjDateASC(data, column);
                    setTotal(newDataSort.length);
                    setData(newDataSort);
                } else {
                    const newDataSort = sortObjDateDESC(data, column);
                    setTotal(newDataSort.length);
                    setData(newDataSort);
                }
                break
            default:
                if (type === CONTROL_TABLE.SORT.ASC) {
                    const newDataSort = sortObjASC(data, column);
                    setTotal(newDataSort.length);
                    setData(newDataSort);
                } else {
                    const newDataSort = sortObjDESC(data, column);
                    setTotal(newDataSort.length);
                    setData(newDataSort);
                }
                break;
        }
    };

    const handleDeleteHoliday = async (holidayId, date, description) => {
        loadingState.showLoading();
        const count = data.length
        const params = {
            empId: props.authenRdc.profile.empId,
            description: description
        };
        const res = await deleteHoliday(holidayId, params);
        loadingState.hideLoading();

        if (res && res.data && !res.error) {
            alertMessageState.showAlert({
                altMsg: AlertMSGConstants.DELETE_SUCCESS,
                callbackCancel: () => {
                    if (res.data[0].countAbsence > 0) {
                        logAbsence(date, null, 'ลบ');
                    }

                    if (count === 1) {
                        callServiceGetHolidayYear()
                    } else {
                        callServiceGet();
                    }
                    alertMessageState.hideAlert();
                }
            });
        } else {
            const msg = checkResStatusService(res)
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.DELETE_FAIL
            });
        }
    };

    const handlecConfirmAlert = value => {
        alertMessageState.showAlert({
            altMsg: `${AlertMSGConstants.CONFIRM_DEL_MEETINGROOM} ${value.holidayInfoName} ?`,
            altType: 2,
            btnConfirmName: 'ลบ',
            callbackConfirm: () => {
                handleDeleteHoliday(
                    value.holidayId,
                    value.date,
                    value.description
                );
                alertMessageState.hideAlert();
            }
        });
    };

    const handleRowClick = (value, action) => {
        switch (action) {
            case 'edit':
                setFormEdit(true);
                const newValue = data.filter(
                    item => item.holidayId === value.holidayId
                );
                setInitialEdit(newValue[0]);
                break;
            case 'delete':
                handlecConfirmAlert(value);
                break;

            default:
                break;
        }
    };

    const handleAddHoliday = () => {
        setFormAdd(true);
    };

    const closeFormHoliday = () => {
        setFormAdd(false);
        setFormEdit(false);
    };

    const logAbsence = (date, holidayId, reason) => {
        loadingState.showLoading();
        getLogAbsence(date, holidayId, reason)
            .then(res => {
                if (res && res.data) {
                    const datasource = res && res.data ? res.data : [];
                    const dataExport = setFormatDataExport(datasource)
                    LogAbsenceExcel(dataExport, date, 'log');
                    // LogAbsenceExcel(datasource, date, 'log');
                } else {
                    // const msg = checkResStatusService(res)
                    alertMessageState.showAlert({
                        altMsg: AlertMSGConstants.ERROR_EXPORT
                    });
                }

            })
            .then(() => loadingState.hideLoading());
    };

    const logImpactAbsence = async (dataLogAbsence, date) => {
        const dataExport = setFormatDataExport(dataLogAbsence)
        LogAbsenceExcel(dataExport, date, 'export');
        // LogAbsenceExcel(dataLogAbsence, date, 'export');
        handleControlFormExport();
    };

    const handleControlFormExport = () => {
        setFormExport(!formExport);
    };

    const handleChangeYear = async (year) => {
        const res = await callServiceGetHolidayByYear(year)

        if (res && res.data) {
            const dataHoliday = res.data.filter(item => item.isActive).map((item, index) => {
                const date = new Date(item.date);
                date.setHours('00');

                item = date.toString()
                return item
            })

            setDateDup(dataHoliday)
        }
    }

    return (
        <Fragment>
            <HolidayView
                notScroll={true}
                column={HolidayConstants.COLUMN}
                data={data}
                rowPerPage={rowPerPage}
                total={total}
                sortColumn={sortColumn}
                sortType={sortType}
                handleSortExternal={handleSort}
                handleRowClick={handleRowClick}
                year={year}
                isBack={isBack}
                isNext={isNext}
                onYearChange={handleYearChange}
                onAddHolidayClick={handleAddHoliday}
                onYearInputChange={handleYearInputChange}
                isSuperAdmin={permissionSystem.permisAdd}
                permisExport={permissionSystem.permisExport}
                onExportClick={handleControlFormExport}
            />
            {formAdd && (
                <AddHoliday
                    closeFormHoliday={closeFormHoliday}
                    callServiceGet={callServiceGet}
                    logAbsence={logAbsence}
                    dateDup={dateDup}
                    empId={props.authenRdc.profile.empId}
                    yearState={year}
                    callServiceGetHolidayYear={callServiceGetHolidayYear}
                    handleChangeYear={handleChangeYear}
                />
            )}
            {formEdit && (
                <EditHoliday
                    closeFormHoliday={closeFormHoliday}
                    callServiceGet={callServiceGet}
                    initialEdit={initialEdit}
                    logAbsence={logAbsence}
                    dateDup={dateDup}
                    empId={props.authenRdc.profile.empId}
                    yearState={year}
                    callServiceGetHolidayYear={callServiceGetHolidayYear}
                    handleChangeYear={handleChangeYear}
                />
            )}
            {formExport && (
                <ExportHoliday
                    closeFormHoliday={closeFormHoliday}
                    logAbsence={logImpactAbsence}
                    lutHoliday={lutHoliday}
                    loadingState={loadingState}
                    alertMessageState={alertMessageState}
                    onCloseFormClick={handleControlFormExport}
                />
            )}
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    );
};

const reduxFormParameter = {
    form: 'holidayHome'
};

const mapStateToProps = state => ({
    authenRdc: state.authen
});

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps, null)
)(Holiday);
