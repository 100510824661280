import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose'

import { isFindString, sortObjASC, sortObjDESC, sortObjNumberASC, sortObjNumberDESC } from '../../../../control/ManageDataFunctions';
import { CONTROL_TABLE, MEETING, SUB_FUNCTION } from '../../../../constants/APDConstants';
import { callServiceMeetingRoom, callServiceDeleteMeetingRoom } from '../../../../api/ApiMeeting';
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';
import RouteConfig from '../../../../config/appRoute/RouteConfig';
import UseLoadingState from '../../../../control/loading/UseLoadingState';
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';

import Loading from '../../../../control/loading/Loading';
import AlertMessage from '../../../../control/alert/AlertMessage';
import MeetingView from '../../presentational/manageMeetingRoom/MeetingView';

import { PermissionSubFuncUtils } from '../../../../utilities/PermissionUtils';

import { checkResStatusService } from '../../../../utilities/ResServiceUtils'

const Meeting = (props) => {
    const {
        authenUUID,
        // onSetPage,
        txtSearch,
        setTxtSearch,
        history
    } = props

    const loadingState = UseLoadingState();
    const alertMessageState = UseAlertMessageState();
    const permission = {
        isAddMeetingRoom: PermissionSubFuncUtils(SUB_FUNCTION.ADD_MEETING_ROOM)
    }

    const column = [
        { title: MEETING.TABLE.TITLE.MEA_DISTRICT_NAME, data: MEETING.TABLE.DATA_NAME.MEA_DISTRICT_NAME, width: '16%', sort: MEETING.TABLE.TITLE.ASC, align: 'left' },
        { title: MEETING.TABLE.TITLE.BUILDING_NAME, data: MEETING.TABLE.DATA_NAME.BUILDING_NAME, width: '15%', sort: MEETING.TABLE.TITLE.ASC, align: 'left' },
        { title: MEETING.TABLE.TITLE.FLOOR, data: MEETING.TABLE.DATA_NAME.FLOOR, width: '7%', sort: MEETING.TABLE.TITLE.ASC, type: 'number' },
        { title: MEETING.TABLE.TITLE.ROOM_NAME, data: MEETING.TABLE.DATA_NAME.ROOM_NAME, width: '23%', sort: MEETING.TABLE.TITLE.ASC, align: 'left' },
        { title: MEETING.TABLE.TITLE.ADMIN_ORG_NAME, data: MEETING.TABLE.DATA_NAME.ADMIN_ORG_NAME, width: '17%', sort: MEETING.TABLE.TITLE.ASC },
        { title: MEETING.TABLE.TITLE.SEAT, data: MEETING.TABLE.DATA_NAME.SEAT, width: '9%', sort: MEETING.TABLE.TITLE.ASC, type: 'number' },
        { title: MEETING.TABLE.TITLE.BOOK_DAY, data: MEETING.TABLE.DATA_NAME.BOOK_DAY, width: '13%', sort: MEETING.TABLE.TITLE.ASC },
    ]

    const rowPerPage = 10
    const [allData, setAllData] = useState([]) //load only frist times
    const [useData, setUseData] = useState([]) //array object of allData or searchData
    const [data, setData] = useState([]) //array object for display each page
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState()
    const [sortColumn, setSortColumn] = useState()
    const [sortType, setSortType] = useState('asc')

    useEffect(() => {
        startMeeting()
    }, [authenUUID, rowPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const startSlice = page === 1 ? 0 : parseInt((page * parseInt(rowPerPage))) - parseInt(rowPerPage)
        const newData = useData.slice(startSlice, parseInt(rowPerPage) * page);
        setData(newData)
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    const startMeeting = async () => {
        loadingState.showLoading();
        // const res = await callServiceMeetingRoom(authenUUID, MEETING.ROOM_STATUS.FREE_BUSY_ID)
        const res = await callServiceMeetingRoom(authenUUID)
        let msg = checkResStatusService(res)
        if (res && res.data) {
            const dataManage = res.data.filter(item => item.isMaintainer).map(item => {
                item.edit = item.isMaintainer ? true : false
                item.delete = false
                item.bookingWeekendTable = item.bookingWeekend ? 'ทุกวัน' : 'จันทร์-ศุกร์'
                item.orgFullName = `${item.orgFullName} (${item.orgShortName})`

                return item
            })

            const sortRoomName = sortObjASC(dataManage, MEETING.TABLE.DATA_NAME.ROOM_NAME)
            const sortFloor = sortObjASC(sortRoomName, MEETING.TABLE.DATA_NAME.FLOOR)
            const sortBuilding = sortObjASC(sortFloor, MEETING.TABLE.DATA_NAME.BUILDING_NAME)
            const sortMesDistrictName = sortObjASC(sortBuilding, MEETING.TABLE.DATA_NAME.MEA_DISTRICT_NAME)
            const sortMesDistrictNameFilter = filterDataTable(txtSearch, sortMesDistrictName)

            setAllData(dataManage)
            setUseData(sortMesDistrictNameFilter)
            setTotal(sortMesDistrictNameFilter.length)
            const newData = sortMesDistrictNameFilter.slice(0, rowPerPage * 1);
            setData(newData)
        } else {
            alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
        }
        loadingState.hideLoading();
    }


    const handlePageChange = (evt) => {
        setPage(evt)
    }

    const handleSort = (column, type, typeData) => {
        setSortColumn(column)
        setSortType(type)
        handleSortData(column, type, typeData)
    }

    const handleSortData = (column, type, typeData) => {
        if (type === CONTROL_TABLE.SORT.ASC) {
            const newDataSort = typeData === "number" ? sortObjNumberASC(useData, column) : sortObjASC(useData, column)
            // const newDataSort = sortObjASC(useData, column)
            const newData = newDataSort.slice(0, rowPerPage * 1);
            handlePageChange(1)
            setTotal(newDataSort.length)
            setData(newData)
        } else {
            const newDataSort = typeData === "number" ? sortObjNumberDESC(useData, column) : sortObjDESC(useData, column)
            // const newDataSort = sortObjDESC(useData, column)
            const newData = newDataSort.slice(0, rowPerPage * 1);
            handlePageChange(1)
            setTotal(newDataSort.length)
            setData(newData)
        }
    }

    const filterData = (filterArr = useData) => {
        const startSlice = page === 1 ? 0 : (page * rowPerPage) - rowPerPage
        const newData = filterArr.slice(startSlice, rowPerPage * page);
        setData(newData)
    }

    const filterDataTable = (textSearch, data) => {
        if (textSearch) {
            const dataFilter = data.filter(item => {
                const roomNameTH = isFindString(item.roomNameTH, textSearch)
                const roomNameEN = isFindString(item.roomNameEN, textSearch)
                const meaDistrict = isFindString(item.meaDistrictName, textSearch)
                const building = isFindString(item.building, textSearch)
                const orgFullName = isFindString(item.orgFullName, textSearch)
                const orgShortName = isFindString(item.orgShortName, textSearch)
                const equipments = item.equipments.filter((itemEq) => {
                    const find = isFindString(itemEq.name, textSearch)
                    return find
                })

                const checkEquip = equipments.length > 0

                return (roomNameTH || roomNameEN || meaDistrict || building || orgFullName || orgShortName || checkEquip)
            })

            return dataFilter
        } else {
            return data
        }
    }

    const handleSearch = (textSearch) => {
        if (!textSearch) {
            setUseData(allData)
            filterData(allData)
            setPage(1)
            setTotal(allData.length)
        } else {
            const searchData = filterDataTable(textSearch, allData)

            filterData(searchData)
            setUseData(searchData)
            setPage(1)
            setTotal(searchData.length)
        }
        setTxtSearch(textSearch)
    }

    const handleAddMeeting = () => {
        // onSetPage(MEETING.PAGE.ADD)
        history.push(RouteConfig.MEETING_MANAGE_ADD)
    }

    const handleDeleteMeetingRoom = async (roomID) => {
        loadingState.showLoading();
        const res = await callServiceDeleteMeetingRoom(roomID)
        loadingState.hideLoading();
        // let msg = checkResStatusService(res)
        alertMessageState.showAlert({
            altMsg: (res && !res.error) ? AlertMSGConstants.DELETE_SUCCESS : AlertMSGConstants.DELETE_FAIL
        })

        if (res && !res.error) {
            alertMessageState.showAlert({ altMsg: AlertMSGConstants.DELETE_SUCCESS })
            startMeeting()
        } else {
            alertMessageState.showAlert({ altMsg: AlertMSGConstants.DELETE_FAIL })
        }
    }

    const handleRowClick = (value, action) => {
        switch (action) {
            case 'edit':
                history.push(RouteConfig.MEETING_MANAGE_EDIT, { editMeetingRoomID: value.roomId })
                break;
            case 'delete':
                alertMessageState.showAlert({
                    altMsg: `${AlertMSGConstants.CONFIRM_DEL_MEETINGROOM} ${value.roomNameTH} ?`,
                    altType: 2,
                    btnConfirmName: "ลบ",
                    callbackConfirm: () => {
                        handleDeleteMeetingRoom(value.roomId)
                        alertMessageState.hideAlert();
                    }
                })
                break;
            default:
                break;
        }
    }

    return (
        <Fragment>
            <MeetingView
                txtSearch={txtSearch}
                column={column}
                data={data}
                page={page}
                rowPerPage={rowPerPage}
                total={total}
                sortColumn={sortColumn}
                sortType={sortType}
                handlePageChange={handlePageChange}
                handleSortExternal={handleSort}
                handleRowClick={handleRowClick}
                onSearchClick={handleSearch}
                isAddMeeting={permission.isAddMeetingRoom}
                onAddMeetingClick={handleAddMeeting}
                dataCount={useData.length}
            />
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    authenRdc: state.authen
})

export default compose(
    connect(mapStateToProps, null),
    withRouter
)(Meeting)