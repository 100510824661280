import React from 'react';
import ButtonView from './ButtonView'

import { CONTROL_BUTTON } from '../../constants/APDConstants' 

const Button = (props) => {
   
    const {
        className,
        label,
        color,
        type,
        onClick,
        disabled,
        startIcon,
        endIcon
    } = props;

    return (
        <ButtonView
            className={className}
            label={label}
            color={color}
            type={type}
            onClick={onClick}
            disabled={disabled}
            startIcon={startIcon}
            endIcon={endIcon}
        />
    )
}

Button.defaultProps = {
    label: CONTROL_BUTTON.DEFAULT_NAME,
    color: CONTROL_BUTTON.DEFAULT_COLOR,
    type: CONTROL_BUTTON.DEFAULT_TYPE
}

export default Button