import React, { Fragment } from 'react'
import { Field } from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'

import TextField from '../../../../control/textfield/TextFields'
import Button from '../../../../control/button/Button'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerPaper: {
        width: '50%',
        minHeight: '30%',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    conGrid: {
        paddingTop: '1%',
        paddingBottom: '1%'
    },
    title: {
        fontSize: '33px'
    },
    button: {
        marginLeft: '1%',
        marginRight: '1%'
    },
    gridBtn: {
        textAlign: 'center',
        padding: '10px'
    },
    msg: {
        color: '#e80d18',
        textAlign: 'end'
    },
    buttonCancel: {
        marginLeft: '1%',
        marginRight: '1%',
        backgroundColor: '#808080 !important'
    },
}))

const LeaveSAPCancelView = (props) => {

    const classes = useStyles()
    const {
        isOpenCancelForm,
        onClickClose,
        onSubmitClick
    } = props

    return (
        <Fragment>
            <Modal
                open={isOpenCancelForm}
                className={classes.modal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Grid container justify="center" className={classes.containerPaper} >
                    <Grid item xs={11} className={classes.conGrid}>
                        <Grid item xs={12}>
                            <span className={classes.title}>ยกเลิกใบลา</span>
                        </Grid>
                        <Grid item xs={12}>
                            <Field 
                                component={TextField}
                                isMultiline={true}
                                row={4}
                                name='cancelReason'
                                label='กรุณากรอกเหตุผลที่ต้องยกเลิกใบลา'
                                required={true}
                                placeholder={'เหตุผลที่ต้องการยกเลิกใบลา'}
                                maxLength={500}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.msg}>
                            <span>ยกเลิกโดย บท</span>
                        </Grid>
                        <Grid item xs={12} className={classes.gridBtn}>
                            <Button 
                                label='ยกเลิก'
                                className={classes.buttonCancel}
                                onClick={onClickClose}
                            />
                            <Button 
                                label='ยืนยัน'
                                className={classes.button}
                                onClick={onSubmitClick}
                            />
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Modal>
        </Fragment>
    )
}

export default LeaveSAPCancelView
