import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        height: '30px',
        width: '355px',
        position: 'absolute',
        left: '24px',
        bottom: '10px',
        zIndex: '2',
        [theme.breakpoints.down('sm')]: {
            left: '7px'
        },
        [theme.breakpoints.down('xs')]: {
            left: '5px'
        }
    },
    icon: {
        width: '15px',
        height: '15px',
        margin: '2px'
    },
    iconManager: {
        fontWeight: 'bold',
        fontSize: '26px',
        color: '#F47B15',
        marginRight: '5px'
    },
    itemManager: {
        marginTop: '-5px'
    }
}))

const DescriptionIconView = () => {
    const classes = useStyles()

    return (
        <Grid className={classes.root} container justify="space-around">
            <Grid item>
                <img alt="img" key="approve" className={classes.icon} src={`${process.env.PUBLIC_URL}/img/leave/approve.svg`} /> ผู้อนุญาต
            </Grid>
            <Grid item>
                <img alt="img" key="audit" className={classes.icon} src={`${process.env.PUBLIC_URL}/img/leave/audit.svg`} /> ผู้พิจารณา
            </Grid>
            <Grid item>
                <img alt="img" key="represent" className={classes.icon} src={`${process.env.PUBLIC_URL}/img/leave/represent.svg`} /> ผู้ทำการแทน
            </Grid>
            <Grid item className={classes.itemManager}>
                {/* <img alt="img" key="represent" className={classes.icon} src={`${process.env.PUBLIC_URL}/img/leave/manager.svg`} /> ผู้จัดการ */}
                <label className={classes.iconManager}>M</label>
                <label>ผู้จัดการ</label>
            </Grid>
        </Grid>
    )
}

export default DescriptionIconView
