import { useEffect, useState } from 'react'

import { PERMISSION_ORG } from '../../../../../constants/APDConstants'
import { getLutApprovalType, getLutOrgchart } from '../../../../../api/ApiOrgLeave'
import { getLutEmpApproveJobId, getLutEmpByPermission } from '../../../../../api/ApiLookup'

const UseLupOrgState = ({ depId, permissionOrgId }) => {
    const [lut, setLup] = useState({
        lutPermission: [],
        lutOrg: [],
        lutEmp: [],
        lutAllEmp: [],
        isLoadLutComplate: false
    })

    useEffect(() => {
        const listService = [
            getLutApprovalType(),
            getLutEmpApproveJobId({ depId, permissionOrgId }),
            getLutEmpByPermission({ depId, permissionOrgId: PERMISSION_ORG.ALL_ORG }), // เนื่องจากจะเอาพนักงานทั้งหมด
            getLutOrgchart({ depId, permissionOrgId })
        ]

        // if (permissionOrgId === PERMISSION_ORG.EXCLUSIVE_ORG) {
        //     listService.push(getLutExclusiveOrgLeave(depId))
        // } else {
        //     listService.push(getCustomLutOrgLeave())
        // }

        Promise.all(listService).then(([resLutApprovalType, resLutEmp, reSLutAllEmp, resLutOrg]) => {
            setLup({
                lutPermission: resLutApprovalType ? resLutApprovalType.data : [],
                lutOrg: resLutOrg && resLutOrg.data ? resLutOrg.data : [],
                lutEmp: resLutEmp && resLutEmp.data ? resLutEmp.data : [],
                lutAllEmp: reSLutAllEmp && reSLutAllEmp.data ? reSLutAllEmp.data : [],
                isLoadLutComplate: true
            })
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return {
        lupPermission: lut.lutPermission,
        lutOrg: lut.lutOrg,
        lutEmp: lut.lutEmp,
        lutAllEmp: lut.lutAllEmp,
        isLoadLutComplate: lut.isLoadLutComplate
    }
}

export default UseLupOrgState
