import React from 'react'
import LeaveCollectTableView from '../../presentational/leavecollect/LeaveCollectTableView'

const LeaveCollectTable = (props) => {
    const { dataTableState } = props
    return (
        <LeaveCollectTableView
            dataTableState={dataTableState}
        />
    )
}

export default LeaveCollectTable