// import React from 'react'
import { useSelector } from 'react-redux'


export const PermissionSubFuncUtils = (subFunctionId) => {
    const authenPofile = useSelector(state => state.authen.profile)
    const isPermission = authenPofile.subFunctions.some(item => item.subFunctionId === subFunctionId)

    return isPermission
}

export const PermissionFunction = (functionId) => {
    const authenPofile = useSelector(state => state.authen.profile)
    const isPermission = authenPofile.functions.some(item => item.functionId === functionId)

    return isPermission
}



