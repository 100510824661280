import React from 'react';
import CancelBookingResultView from '../../../presentational/cancelBooking/CancelBookingResultView'
const CancelBookingResult = (props) => {
    const {
        dataTableState,
        onRowClick,
        dataCheck,
        onExportClick,
        // disabledCheck,
    } = props
    return (
        <CancelBookingResultView
            dataTableState={dataTableState}
            onRowClick={onRowClick}
            dataCheck={dataCheck}
            onExportClick={onExportClick}
        // disabledCheck={disabledCheck}
        />


    );
};

export default CancelBookingResult;