import React, { useContext, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import * as DatetimeUtils from '../../../../../utilities/DatetimeUtils';
import * as AlertMSGConstants from '../../../../../constants/AlertMSGConstants'
import { BookingContext } from '../BookingProvider';
import { getBookingList } from '../../../../../api/ApiBooking';
import { checkResStatusService } from '../../../../../utilities/ResServiceUtils'

import FormModalSearchMeetingRoom from '../form/FormModalSearchMeetingRoom';
import CalendarListView from '../../../presentational/booking/bookingList/CalendarListView';

const CalendarList = ({ authenRdc }) => {

    // const formValuesRef = useRef({});
    // const listSelectedMeetingRoomIDRef = useRef([]);
    // const [events, setEvents] = useState([]);
    // const [agendaEventData, setAgendaEventData] = useState([]);
    // const [currentDateObj, setCurrentDateObj] = useState(null);
    // const [
    //     isOpenModalFormSearchMeetingRoom,
    //     setIsOpenModalFormSearchMeetingRoom
    // ] = useState(false);

    const { loadingState, alertMessageState, calendarBookingState } = useContext(BookingContext);
    const {
        events,
        setEvents,
        agendaEventData,
        setAgendaEventData,
        currentDateObj,
        setCurrentDateObj,
        isOpenModalFormSearchMeetingRoom,
        setIsOpenModalFormSearchMeetingRoom,
        formValuesRef,
        listSelectedMeetingRoomIDRef,
        isSearch
    } = calendarBookingState

    useEffect(() => {
        if (!isSearch) {
            handleClickSubmitFormSearch();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var getDateArray = function (start, end) {
        //YYYY-MM-DD
        var arr = new Array();
        var dt = new Date(start);
        while (dt <= end) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    };

    const handleChangeMonth = date => {
        setEvents([])
        handleClickSubmitFormSearch(
            formValuesRef.current,
            listSelectedMeetingRoomIDRef.current,
            date
        );
        setCurrentDateObj(date);
    };

    const handleClickSearchMeetingRoom = () => {
        setIsOpenModalFormSearchMeetingRoom(true);
    };

    const handleCloseFormClick = () => {
        setIsOpenModalFormSearchMeetingRoom(false);
    };

    const handleDataForDisplayCalendar = data => {
        let eventsList = [];
        data.forEach(bookingItm => {
            let eventsObj = {
                id: bookingItm.bookingId,
                title: bookingItm.topic,
                roomNameTH: bookingItm.roomNameTH,
                allEventDateList: getDateArray(
                    new Date(bookingItm.startDate),
                    new Date(bookingItm.endDate)
                ),
                start: new Date(bookingItm.startDate),
                end: new Date(bookingItm.endDate),
                desc:
                    bookingItm.roomDescription &&
                        bookingItm.roomDescription.length > 0
                        ? bookingItm.roomDescription
                        : '-',
                circleColor: `#${bookingItm.color}`,
                roomNumber: bookingItm.roomNo,
                topic: bookingItm.topic,
                chairman:
                    bookingItm.chairman.length > 0 ? bookingItm.chairman : '-',
                emp: `${bookingItm.empNameTH}`,
                internalTel:
                    bookingItm.telInternalPrefix && bookingItm.telInternalSuffix
                        ? `${bookingItm.telInternalPrefix} - ${bookingItm.telInternalSuffix}`
                        : '',
                startTime: bookingItm.startTime,
                endTime: bookingItm.endTime,
                openTime: bookingItm.openTime,
                closeTime: bookingItm.closeTime,
                floorDisplay: bookingItm.floorDisplay,
                building: bookingItm.building,
                numberOfAttendees: bookingItm.numberOfAttendees
                    ? bookingItm.numberOfAttendees
                    : '-',
                timeBooking: `${bookingItm.startTime}-${bookingItm.endTime}`,
                oldBookingEndTime: bookingItm.oldBookingEnd,
                oldBookingStartTime: bookingItm.oldBookingStart,
                empOrgNameTH: `${bookingItm.empOrgNameTH}`
            };
            eventsList.push(eventsObj);
        });
        setEvents(eventsList);
    };

    const handleDataForDisplayAgenda = data => {
        data.forEach(agendaItm => {
            agendaItm.start = new Date(agendaItm.start);
            agendaItm.end = new Date(agendaItm.end);
            agendaItm.circleColor = `#${agendaItm.color}`;
            agendaItm.internalTel =
                agendaItm.telInternalPrefix && agendaItm.telInternalSuffix
                    ? `${agendaItm.telInternalPrefix} - ${agendaItm.telInternalSuffix}`
                    : '';
            agendaItm.empOrgNameTH =
                agendaItm.deptOrgFullNameTH &&
                    agendaItm.deptOrgFullNameTH !== ''
                    ? agendaItm.deptOrgFullNameTH
                    : agendaItm.empOrgNameTH;
        });
        setAgendaEventData(data);
    };

    const handleClickSubmitFormSearch = async (
        formValues = {},
        listSelectedMeetingRoomID = [],
        date = currentDateObj ? currentDateObj : new Date()
    ) => {

        formValuesRef.current = formValues;
        listSelectedMeetingRoomIDRef.current = listSelectedMeetingRoomID;

        // let firstMonth = DatetimeUtils.getFirstDateOfFirstMonth(date);
        // let lastMonth = DatetimeUtils.getLastDateOfLastMonth(date);

        // let dateFirstMonthForService = formatDateTime(firstMonth);
        // let dateLastMonthForService = formatDateTime(lastMonth);

        let dateFirstMonthForService = DatetimeUtils.getFirstDateOfFirstMonth(date);
        let dateLastMonthForService = DatetimeUtils.getLastDateOfLastMonth(date);

        let paramsStr = `uuid=${authenRdc.profile.uuid}&`;
        if (dateFirstMonthForService) {
            paramsStr = paramsStr += `startDatetime=${dateFirstMonthForService}&`;
        }
        if (dateLastMonthForService) {
            paramsStr = paramsStr += `endDatetime=${dateLastMonthForService}&`;
        }
        if (formValues && formValues.org) {
            paramsStr = paramsStr += `orgId=${formValues.org}&`;
        }
        if (
            listSelectedMeetingRoomID &&
            listSelectedMeetingRoomID.length !== 0
        ) {
            paramsStr = paramsStr += `roomId=${listSelectedMeetingRoomID.join(
                '|'
            )}&`;
        }

        // let callApiSearchBooking = getBookingList(paramsStr);

        // paramsStr = paramsStr += 'isAgendar=true';
        // let callApiSearchBookingForAgenda = getBookingList(paramsStr);

        // const [
        //     responseSearchBooking,
        //     responseSearchBookingForAgenda
        // ] = await Promise.all([
        //     callApiSearchBooking,
        //     callApiSearchBookingForAgenda
        // ]);

        loadingState.showLoading();
        paramsStr = paramsStr += 'isAgendar=true'
        const resSearchBookingList = await getBookingList(paramsStr)
        loadingState.hideLoading();

        let filterMeetingRoom = [];
        let filterBookingDataForAgenda = [];

        if (resSearchBookingList && resSearchBookingList.data) {
            const { dataAgenda = [], dataCalendar = [] } = resSearchBookingList.data

            dataCalendar.forEach(bookingItm => {
                bookingItm.empOrgNameTH = bookingItm.deptOrgFullNameTH
                    ? bookingItm.deptOrgFullNameTH
                    : bookingItm.empOrgNameTH;
                bookingItm.maintainers.forEach(maintainersItm => {
                    if (maintainersItm.empId === authenRdc.profile.empId) {
                        filterMeetingRoom.push(bookingItm);
                    }
                });
            });

            const result = [];
            filterMeetingRoom.forEach(itm => {
                if (!itm.bookingWeekend) {
                    const dates = DatetimeUtils.splitDateWeekend(
                        itm.startDate,
                        itm.endDate
                    );

                    dates.forEach((itm2, index) => {
                        let startTime = itm.startTime;
                        let endTime = itm.endTime;

                        // if (itm.startDate === itm.endDate) {
                        //     startTime = itm.startTime;
                        //     endTime = itm.endTime;
                        // } else {
                        //     if (itm.startDate === itm2.startDate) {
                        //         // first
                        //         endTime = itm.closeTime;
                        //     } else if (itm.endDate === itm2.endDate) {
                        //         //last
                        //         startTime = itm.openTime;
                        //     } else {
                        //         startTime = itm.openTime;
                        //         endTime = itm.closeTime;
                        //     }
                        // }

                        if (dates.length > 1) { //กรณีเป็นช่วง
                            if (index === 0) {
                                // first
                                endTime = "23.59";
                            } else if (dates.length === index + 1) {
                                //last
                                startTime = "00.00";
                            } else {
                                //between
                                startTime = "00.00";
                                endTime = "23.59";
                            }
                        }

                        //////////////// ICE /////////////////
                        // if (dates.length > 1) { //กรณีเป็นช่วง
                        //     if (index === 0) {
                        //         // first
                        //         endTime = itm.closeTime;
                        //     } else if (dates.length === index + 1) {
                        //         //last
                        //         startTime = itm.openTime;
                        //     } else {
                        //         //between
                        //         startTime = itm.openTime;
                        //         endTime = itm.closeTime;
                        //     }
                        // }
                        /////////////////////////////////////

                        result.push({
                            ...itm,
                            startDate: itm2.startDate,
                            endDate: itm2.endDate,
                            oldBookingEnd: itm.endDate,
                            oldBookingStart: itm.startDate,
                            startTime,
                            endTime,
                            empOrgNameTH: itm.deptOrgId ? itm.deptOrgFullNameTH : itm.empOrgNameTH
                        });
                    });
                } else {
                    result.push({
                        ...itm,
                        oldBookingEnd: itm.endDate,
                        oldBookingStart: itm.startDate
                    });
                }
            });

            handleDataForDisplayCalendar(result);

            dataAgenda.forEach(bookingItm => {
                bookingItm.maintainers.forEach(maintainersItm => {
                    if (maintainersItm.empId === authenRdc.profile.empId) {
                        filterBookingDataForAgenda.push(bookingItm);
                    }
                });
            });

            handleDataForDisplayAgenda(filterBookingDataForAgenda);
        } else {
            const msg = checkResStatusService(resSearchBookingList)
            alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
        }

        handleCloseFormClick();
    };

    return (
        <Fragment>
            <CalendarListView
                events={events}
                onChangeMonth={handleChangeMonth}
                onClickSearchMeetingRoom={handleClickSearchMeetingRoom}
                currentDateObj={currentDateObj}
                agendaEventData={agendaEventData}
            />
            <FormModalSearchMeetingRoom
                isOpen={isOpenModalFormSearchMeetingRoom}
                profile={authenRdc.profile}
                onCloseFormClick={handleCloseFormClick}
                onClickSubmitFormSearch={handleClickSubmitFormSearch}
            />
        </ Fragment>
    );
};

const mapStateToProps = state => ({
    authenRdc: state.authen
});

export default connect(mapStateToProps)(CalendarList);
