import React from 'react';
import LoginView from './LoginView';

const Login = () => {
    const handleSignInWithSmart = () => {
        // window.location = window.URL_LOGIN;
        window.location = `${process.env.REACT_APP_MAP_BOSS_LOGIN}${process.env.REACT_APP_ROOT_URL}`;
    };
    
    return (
        <LoginView
            onSignInWithSmart={handleSignInWithSmart}
            href={process.env.REACT_APP_MAP_BOSS_LOGIN + process.env.REACT_APP_ROOT_URL}
        // href={"http://localhost:3000/"} // MOC PROFILE
        />
    );
};

export default Login;
