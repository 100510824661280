import React, { Fragment } from 'react'
import { Field, FieldArray } from 'redux-form'
import { Scrollbars } from 'react-custom-scrollbars';

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import withWidth from '@material-ui/core/withWidth';

import { ORG_LEAVE } from '../../../../../constants/APDConstants'

import ModalToolbarView from './ModalToolbarView'
import EmpItemView from './EmpItemView'
import AutoComplete from '../../../../../control/autocomplete/AutoComplete'
import Button from '../../../../../control/button/Button'
import Search from '../../../../../control/search/Search'

const { NAME, LABEL } = ORG_LEAVE.FORM_EDIT_TOOLBAR

const useStyles = makeStyles(theme => ({
    root: {
        margin: '24px',
        width: 'calc(100% - 48px)',
    },
    title: {
        fontSize: '33px'
    },
    headerTable: {
        borderColor: '#C4C4C4',
        borderWidth: '1px',
        backgroundColor: '#d5d5d5',
        padding: '10px'
    },
    footer: {
        marginTop: '20px'
    },
    notFound: {
        padding: '10px'
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    },
    search: {
        marginBottom: '10px'
    }
}))

const renderEmpItem = ({ fields, emps, lutOrg, change, type }) => {
    return (
        <Fragment>
            {fields.map((field, index) => (
                <EmpItemView
                    field={field}
                    emp={emps[index]}
                    lutOrg={lutOrg}
                    key={`${type}-empItem-${index}`}
                    change={change}
                    index={index}
                />
            ))}
        </Fragment>
    )
}

const EmpToolbarView = ({
    emps,
    lutOrg,
    width,
    type,
    change,

    onChangeType,
    onClose,
    onSubmit,
    onSearchClick
}) => {
    const classes = useStyles()
    const maxItem = width === 'xl' || width === 'lg' ? 4 : width === 'xs' ? 2 : 3
    const lengthItem = emps.filter(emp => emp.isDisplay).length

    return (
        <ModalToolbarView>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <span className={classes.title}>จัดการพนักงาน</span>
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name={NAME.TYPE}
                        label={LABEL.TYPE}
                        component={AutoComplete}
                        onChangeDropDown={onChangeType}
                        options={ORG_LEAVE.TYPE}
                        required
                    />
                </Grid>
                <Grid item xs={6} />

                <Grid item xs={12} className={classes.search}>
                    <Search
                        onSearchClick={onSearchClick}
                        waterMark={'รหัสพนักงาน, ชื่อพนักงาน, หน่วยงาน'}
                    />
                </Grid>

                <Grid item xs={12} container className={classes.headerTable}>
                    <Grid item xs={3}>ชื่อ - นามสกุล</Grid>
                    <Grid item xs={1}>สถานะ</Grid>
                    <Grid item xs={4}>หน่วยงานเดิม</Grid>
                    <Grid item xs={4}>หน่วยงานปัจจุบัน</Grid>
                </Grid>
                {lengthItem ? (
                    // <Grid item xs={12} style={{ height: emps.length <= maxItem ? emps.length * 80 : maxItem * 80 }} >
                    <Grid item xs={12} style={{ height: lengthItem <= maxItem ? lengthItem * 80 : maxItem * 80 }} >
                        <Scrollbars universal={true}>
                            <FieldArray
                                name={NAME.EMPS}
                                component={renderEmpItem}
                                emps={emps}
                                lutOrg={lutOrg}
                                change={change}
                                type={type}
                            />
                        </Scrollbars>
                    </Grid>) : (<Grid item className={classes.notFound}>ไม่พบข้อมูล</Grid>)}

                <Grid item xs={12} className={classes.footer} container justify="center" spacing={2} >
                    <Grid item>
                        <Button label="ยกเลิก" onClick={onClose} className={classes.btnCancel} />
                    </Grid>
                    <Grid item>
                        <Button label="ตกลง" onClick={onSubmit} />
                    </Grid>
                </Grid>

            </Grid>
        </ModalToolbarView>
    )
}

export default withWidth()(EmpToolbarView)
