import React from 'react'
import parse from 'html-react-parser';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
// import FormHelperText from '@material-ui/core/FormHelperText';

import './TextEditorStyle.css'

// import TextFields from '../textfield/TextFields'

const useStyles = makeStyles((theme) => ({
    tools: {
        margin: '10px 0px 20px 0px'
    },
    errorText: {
        color: theme.mainTheme.color5,
        marginTop: '1px',
        marginLeft: '10px',
        height: '20px'
    }
}));


const TextEditorView = (props) => {
    const {
        tools,
        // meta: { error },
        onInsert,
    } = props
    const classes = useStyles()

    const handleScroll = (e) => {
        const textarea = document.getElementById("textarea");
        const backdrop = document.getElementById("backdrop")
        backdrop.scrollTop = textarea.scrollTop
    }

    // รอทำ dynamic
    const txtHighligh = props.input.value.replace(/{maintenanceDate}/g, `<label>{maintenanceDate}</label>`)

    return (
        <Grid container>
            <Grid item xs={12} className={classes.tools}>
                {tools.map((tool) => (
                    <Button
                        key={tool.value}
                        variant="outlined"
                        onClick={onInsert(tool.value)}
                    >
                        {tool.label}
                    </Button>
                ))}
            </Grid>
            <Grid item xs={12}>
                {/* <TextFields
                    {...props}
                    isMultiline={true}
                    row={4}
                /> */}
                <div className="container" >
                    <div className="backdrop" id="backdrop">
                        <div className="highlights">{parse(txtHighligh)}</div>
                    </div>
                    <textarea
                        class="textareaEdit"
                        ref={props.inputRef}
                        onChange={props.onChange}
                        value={props.input.value}
                        onScroll={handleScroll}
                    />
                </div>
                {/* <FormHelperText className={classes.errorText} >
                    {error}
                </FormHelperText> */}
            </Grid>
        </Grid >
    )
}

export default TextEditorView
