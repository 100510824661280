import React, { Fragment, useState, useEffect } from 'react'
import { reduxForm, formValueSelector } from "redux-form";
import { compose } from 'recompose'
import { connect } from 'react-redux'

import FormHolidayView from '../../presentational/holiday/FormHolidayView'

import { callServiceGetLutHolidayInfo, postHolidayInfo, getExportHoliday } from '../../../../api/ApiHoliday'
import { checkResStatusService } from '../../../../utilities/ResServiceUtils'
import { validateHoliday, setFormatDataExport } from './HolidayUtils';
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'

import AlertMessage from '../../../../control/alert/AlertMessage'
import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState'
import Loading from '../../../../control/loading/Loading'
import UseLoadingState from '../../../../control/loading/UseLoadingState'

import LogAbsenceExcel from './report/LogAbsenceExcel'


const FormHoliday = (props) => {

    const {
        closeFormHoliday,
        handleSubmit,
        saveHoliday,
        isEdit,
        isEditValue,
        dateDup,
        isFail,
        editDate,
        enabled,
        holiday,
        handleChangeYear
    } = props

    const [openForm, setOpenForm] = useState(true)
    const [lutInfo, setLutInfo] = useState(null)
    const [isFailSave, setIsFailSave] = useState(false)

    const [dataExport, setDataExport] = useState({ data: [], date: null })

    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();

    if (isFail !== isFailSave) {
        setIsFailSave(isFail)
    }

    useEffect(() => {
        if (editDate) {
            handleDateChange(new Date(editDate))
        }

        if (isEdit) {
            setLutInfo(isEdit)
        } else {
            loadingState.showLoading()
            callServiceGetLutHolidayInfo().then(res => {
                loadingState.hideLoading()
                if (res && res.data) {
                    setLutInfo(res.data)
                } else {
                    const msg = checkResStatusService(res)
                    setLutInfo([])
                    alertMessageState.showAlert({
                        altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
                        callbackCancel: () => {
                            alertMessageState.hideAlert()
                            handleCancel()
                        }
                    })
                }
            })
            props.change('enabled', 1)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleCancel = () => {
        setOpenForm(false)
        setTimeout(() => {
            closeFormHoliday()
        }, 600)
    }

    const handleSubmitForm = async (values) => {
        const newValue = {
            ...values,
            enabled: values.enabled.toString() === '1' ? true : false
        }

        const info = values.info.id ?
            values.info.id : values.info.name ?
                values.info.name : values.info

        // setNameInfo(info)
        const newInfo = lutInfo.filter(item => item.id === info).length < 1
        if (newInfo) {
            loadingState.showLoading()
            const res = await postHolidayInfo({ name: info })
            loadingState.hideLoading()
            if (res && res.data) {
                saveHoliday(res.data[0].holidayInfoId, newValue)
            } else {
                const msg = checkResStatusService(res)
                alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
            }
        } else {
            saveHoliday(info, newValue)
        }
    }

    const handleInsert = (values) => {
        const filterOptions = values.filter(item => item.id !== -1)
        setLutInfo(filterOptions)
    }

    const handleDateChange = (values) => {
        if (values) {
            loadingState.showLoading()
            const year = values.getFullYear()
            const month = values.getMonth() + 1
            const day = values.getDate()

            const date = `${year}-${month}-${day}`
            getExportHoliday(date).then(res => {
                loadingState.hideLoading()
                if (res && res.data) {
                    setDataExport({ data: res.data, date: date })
                } else {
                    const msg = checkResStatusService(res)
                    alertMessageState.showAlert({ altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR })
                }
            })
        } else {
            setDataExport({ data: [], date: null })
        }
    }

    const handleLink = () => {
        const { data, date } = dataExport
        LogAbsenceExcel(setFormatDataExport(data), date, 'export')
        // LogAbsenceExcel(data, date, 'export')
    }

    return (
        <Fragment>
            {lutInfo &&
                <FormHolidayView
                    open={openForm}
                    lutInfo={lutInfo}
                    onCancelClick={handleCancel}
                    onSubmitClick={handleSubmit(handleSubmitForm)}
                    isEditValue={isEditValue}
                    insertOptionFn={handleInsert}
                    dateDup={dateDup}
                    onDateChange={handleDateChange}
                    countExport={dataExport.data.length}
                    onLinkClick={handleLink}
                    enabled={(Number(enabled) === 1) || editDate}
                    isHoliday={(holiday)}
                    changeYear={handleChangeYear}
                />
            }
            <AlertMessage {...alertMessageState.altProps} />
            <Loading isShowLoading={loadingState.isShowLoading} />
        </Fragment>
    )
}

const selector = formValueSelector('holidayForm')

const mapStateToProps = state => ({
    enabled: selector(state, 'enabled'),
    holiday: selector(state, 'date')
})

const reduxFormParameter = {
    form: 'holidayForm',
    validate: validateHoliday,
    // onSubmitFail: (errors, dispatch, submitError, props) => dispatch(setFocusName(errors, props))
};

export default compose(
    reduxForm(reduxFormParameter),
    connect(mapStateToProps)
)(FormHoliday)