import { fetchGet } from '../utilities/ServiceUtils';
import * as ApiConstants from '../constants/ApiConstants';

export const searchEditingHistory = async (parameters) => {
    try {
        const fetchData = await fetchGet(
            `${
                ApiConstants.SERVICE_SEARCH_PROFILE_EDITING_HISTORY
            }?${Object.keys(parameters)
                .map((key) => `${key}=${parameters[key]}`)
                .join('&')}`
        );
        if (!fetchData.ok) {
            throw fetchData.statusText;
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex searchEditingHistory : ', err);
    }
};

export const getTransaction = async (transactionId) => {
    try {
        const fetchData = await fetchGet(
            `${ApiConstants.SERVICE_GET_PROFILE_EDITING_TRANSACTION}/${transactionId}`
        );
        if (!fetchData.ok) {
            throw fetchData.statusText;
        }
        const res = await fetchData.json();
        return res;
    } catch (err) {
        console.log('ex getTransaction : ', err);
    }
};
