import {
    fetchGet,
    fetchDel,
    fetchPut,
    fetchPost
} from '../utilities/ServiceUtils';
import { setZeroSeconds } from '../utilities/DatetimeUtils';
import * as ApiConstants from '../constants/ApiConstants';
import { format } from 'date-fns';

import { API } from '../constants/ServiceMSGConstants'

export const getListMaintenance = async () => {
    try {
        const fetchData = await fetchGet(
            `${ApiConstants.SERVICE_MAINTENANCE}/?${new Date().getTime()}`
        );
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.CONNEXT };
        }
        const res = await fetchData.json();

        return res;
    } catch (err) {
        console.log('ex getListMaintenance :', err);
        return err
    }
};

export const addMaintenance = async ({
    startDate,
    endDate,
    note,
    displayDate
}) => {
    try {
        const fetchData = await fetchPost(
            `${ApiConstants.SERVICE_MAINTENANCE}/`,
            {
                startDate: format(setZeroSeconds(startDate), 'yyyy-MM-dd HH:mm:ss'),
                endDate: format(setZeroSeconds(endDate), 'yyyy-MM-dd HH:mm:ss'),
                displayDate: format(setZeroSeconds(displayDate), 'yyyy-MM-dd HH:mm:ss'),
                note: note && note.trimEnd(),
                apiId: 4,
                api: 'connext'
            }
        );
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.CONNEXT };
        }
        const res = await fetchData.json();

        return res;
    } catch (err) {
        console.log('ex addMaintenance :', err);
        return err
    }
};

export const updateMaintenance = async ({
    maId,
    startDate,
    endDate,
    note,
    displayDate,
    apiName,
    eventId,
    apiId
}) => {
    try {
        const fetchData = await fetchPut(
            `${ApiConstants.SERVICE_MAINTENANCE}/${maId}`,
            {
                startDate: format(setZeroSeconds(startDate), 'yyyy-MM-dd HH:mm:ss'),
                endDate: format(setZeroSeconds(endDate), 'yyyy-MM-dd HH:mm:ss'),
                displayDate: format(setZeroSeconds(displayDate), 'yyyy-MM-dd HH:mm:ss'),
                note: note && note.trimEnd(),
                api: apiName,
                apiId,
                eventId
            }
        );
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.CONNEXT };
        }
        const res = await fetchData.json();

        return res;
    } catch (err) {
        console.log('ex updateMaintenance :', err);
        return err
    }
};

export const deleteMaintenance = async maId => {
    try {
        const fetchData = await fetchDel(
            `${ApiConstants.SERVICE_MAINTENANCE}/${maId}`
        );
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.CONNEXT };
        }
        const res = await fetchData.json();

        return res;
    } catch (err) {
        console.log('ex deleteMaintenance :', err);
        return err
    }
};

export const searchListMaintenance = async (params = null) => {
    try {
        const fetchData = await fetchGet(
            `${ApiConstants.SERVICE_MAINTENANCE}?${new Date().getTime()}&${params}`
        );
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.CONNEXT };
        }
        const res = await fetchData.json();

        return res;
    } catch (err) {
        console.log('ex getListMaintenance :', err);
        return err
    }
};