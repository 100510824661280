import React from 'react';

// Material UI Importing
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';

import { APP } from '../../constants/APDConstants';

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: '100%',
        borderRadius: '20px'
    },
    media: {
        height: 200,
    },
    cardContent: {
        color: '#F47B15',
        fontSize: '30px',
        textAlign: 'center',
        minHeight: '150px',
        fontFamily: `${APP.FONT_FAMILY_APP} !important`,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    line: {
        width: '20px',
        height: '4px',
        backgroundColor: theme.mainTheme.color1,
        display: 'none'
    },
    title: {
        width: '100%',
        marginTop: '16px',
        fontWeight: 'bold'
    },
    subTitle: {
        width: '100%', 
        marginTop: '-2px'
    }
}));

function CommunityCard({ title, subTitle, image }) {
    const classes = useStyles();
    return (
        <Grid container justify="center">
            <Card className={classes.card}>
                <CardActionArea>
                    <CardMedia className={classes.media} image={image} />
                    <CardContent className={classes.cardContent}>
                        <div className={classes.line} />
                        <div className={classes.title}>{title}</div>
                        <div className={classes.subTitle}>{subTitle}</div>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

export default CommunityCard;
