import { fetchGet, fetchDel_, fetchPost, fetchPut } from '../utilities/ServiceUtils';
import * as ApiConstants from '../constants/ApiConstants'
import { API } from '../constants/ServiceMSGConstants'

export const getAllUserGroup = async () => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_ALL_USER_GROUP}?${new Date().getTime()}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.APD }
        }

        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getAllUserGroup :', err)
        return err
    }
}

export const deleteUserGroup = async (userGroupId, empId) => {
    try {
        const fetchData = await fetchDel_(`${ApiConstants.SERVICE_DELETE_USER_GROUP_BY_ID}/${userGroupId}`, { empId })
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.APD }
        }
        const res = await fetchData.json()

        return res
    }
    catch (err) {
        console.log('ex deleteUserGroup :', err)
        return err
    }
}

export const getUserGroupById = async (usergroupId) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_USER_GROUP_BY_ID}/?userGroupId=${usergroupId}&&${new Date().getTime()}`)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.APD }
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getUserGroupById :', err)
        return err
    }
}

export const getPermissionOrg = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LU_PERMISSION_ORG)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getPermissionOrg :', err)
    }
}

export const getSystemFunction = async () => {
    try {
        const fetchData = await fetchGet(ApiConstants.SERVICE_GET_LU_SYSTEM_FUNCTION)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getSystemFunction :', err)
    }
}

export const getUserGroupByEmpId = async (empId) => {
    try {
        const fetchData = await fetchGet(`${ApiConstants.SERVICE_GET_USER_GROUP_BY_EMP_ID}?empId=${empId}`)
        if (!fetchData.ok) {
            throw fetchData.statusText
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex getUserGroupByEmpId :', err)
    }
}

export const saveUserGroup = async (params) => {
    try {
        const fetchData = await fetchPost(ApiConstants.SERVICE_ADD_USER_GROUP, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.APD }
        }

        const res = await fetchData.json()
        return res;
    }
    catch (err) {
        console.log('ex saveUserGroup :', err)
        return err
    }
}

export const updateUserGroup = async (params, userGroupId) => {
    try {
        const fetchData = await fetchPut(`${ApiConstants.SERVICE_UPDATE_USER_GROUP}/${userGroupId}`, params)
        if (!fetchData.ok) {
            throw { status: fetchData.status, api: API.APD }
        }
        const res = await fetchData.json()
        return res
    }
    catch (err) {
        console.log('ex updateUserGroup : ', err)
        return err
    }
}
