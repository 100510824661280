const TABLE = {
    TITLE: {
        CHECK: 'เลือก',
        TOPIC: 'หัวข้อการประชุม',
        DATE_BOOKING: 'วันที่จองห้อง',
        TIME_BOOKING: 'เวลาที่จองห้อง',
        ROOM_NAME: 'ชื่อห้องประชุม',
        CHAIRMAN: 'ประธาน',
        ATTENDEES: 'ผู้เข้าร่วม',
        EMP_NAME: 'ผู้จอง/หน่วยงาน/เบอร์โทร',
        MAINTAINER: 'พนักงานดูแลห้องประชุม',
        COFFEE_BREAK: 'อาหารว่าง'
    },
    DATA: {
        CHECK: 'check',
        TOPIC: 'topic',
        DATE_BOOKING: 'dateBookingDisplay',
        TIME_BOOKING: 'timeBooking',
        ROOM_NAME: 'roomNameTH',
        CHAIRMAN: 'chairman',
        ATTENDEES: 'numberOfAttendees',
        EMP_NAME: 'emp',
        MAINTAINER: 'maintainer',
        COFFEE_BREAK: 'coffeeBreak'
    }
};

export const COLUMN_CANCEL_BOOKING = [
    {
        title: "ช่วงเวลาที่จองห้องประชุม",//TABLE.TITLE.DATE_BOOKING,
        data: TABLE.DATA.DATE_BOOKING, //'dateBookingDisplay',
        width: '27%',
        sort: 'asc',
        align: 'center',
        type: 'date'
    },
    // {
    //     title: "ช่วงเวลาที่จองห้องประชุม",//TABLE.TITLE.TIME_BOOKING,
    //     data: TABLE.DATA.TIME_BOOKING,
    //     width: '10%',
    //     sort: 'asc',
    //     align: 'center'
    // },
    {
        title: TABLE.TITLE.TOPIC,
        data: TABLE.DATA.TOPIC,
        width: '20%',
        sort: 'asc',
        align: 'left'
    },
    {
        title: TABLE.TITLE.ROOM_NAME,
        data: TABLE.DATA.ROOM_NAME,
        width: '15%',
        sort: 'asc',
        align: 'left'
    },
    {
        title: TABLE.TITLE.CHAIRMAN,
        data: TABLE.DATA.CHAIRMAN,
        width: '10%',
        sort: 'asc',
        align: 'left'
    },
    {
        title: TABLE.TITLE.ATTENDEES,
        data: TABLE.DATA.ATTENDEES,
        width: '8%',
        sort: 'asc',
        align: 'center'
    },
    {
        title: TABLE.TITLE.EMP_NAME,
        data: TABLE.DATA.EMP_NAME,
        width: '20%',
        sort: 'asc',
        align: 'left'
    }
];
