import RouteConfig from '../config/appRoute/RouteConfig';
//text ทุกอย่างให้เป็น Constant
//connstants แยกเป็นเรื่องๆ ไม่ใช้ซ้ำกัน

export const APP = {
    FONT_FAMILY_APP: 'DB_Ozone',
    KEY_TOKEN_LOCAL: 'token',
    SYSTEM_LEAVE_ID: 1,
    SYSTEM_MEETING_ID: 2,
    SYSTEM_REGIS_ID: 3,
    SYSTEM_EV_ID: 4,
};

export const PERMISSION_MEETING_FUNCTION = {
    ADD_ID: 1,
    EDIT_ID: 2,
    DELETE_ID: 3,
    VIEW_ID: 4,
};

export const PERMISSION_ORG = {
    ALL_ORG: 1,
    EXCLUSIVE_ORG: 2,
    PERSONAL_ORG: 3,
};

export const USER_GROUP = {
    SUPER_ADMIN_ID: 1,
    ADMIN_ID: 2,
    EXCLUSIVE_ID: 3,
};

export const CONTROL_TABLE = {
    NO_DATA_MSG_DEFAULT: 'ไม่พบรายการ',
    SORT: {
        DESC: 'desc',
        ASC: 'asc',
    },
    SLIDE: {
        SLIDE_IN: 'slideIn',
        SLIDE_IN_DELETE: 'slideIn_delete',
        TABLE_ROW_DELETE: 'table_row_delete',
        SLIDE_IN_DETAIL: 'slideIn_detail',
        SLIDE_2_ROW: 'slideIn_delete_detail',
    },
};

export const CONTROL_BUTTON = {
    DEFAULT_NAME: 'Button',
    DEFAULT_COLOR: '#FFFFFF',
    DEFAULT_TYPE: 'button',
    DEFAULT_BG_COLOR: '#F47B15',
    TYPE_SUBMIT: 'submit',
};

export const CONTROL_SEARCH = {
    DEFAULT_WATERMARK: 'ค้นหา',
    KEY_ENTER: 'Enter',
};

export const CONTROL_TEXTFIELD = {
    DEFAULT_LABEL: '',
    DEFAULT_PLACEHOLDER: 'ตัวอย่างข้อความ',
    DEFAULT_TYPE: 'text',
    DEFAULT_LABEL_COLOR: '#F47B15',
    TYPE_NUMBER: 'number',
    DEFAULT_TYPE_TEXT: true,
};

// export const EMP_STATUS_ID = {
//     ACTIVE: '1'
// };

export const EMP_STATUS_ACTIVE = ['1', '2'];

export const SYSTEM = {
    LEAVE: {
        id: 1,
        name: 'Leave',
        path: RouteConfig.LEAVE,
        image: `${process.env.PUBLIC_URL}/img/portal/leave.png`,
        navImage: `${process.env.PUBLIC_URL}/img/portal/navLeave.png`,
    },
    MEETING: {
        id: 2,
        name: 'Meeting',
        path: RouteConfig.MEETING,
        image: `${process.env.PUBLIC_URL}/img/portal/meeting.jpg`,
        navImage: `${process.env.PUBLIC_URL}/img/portal/meeting.jpg`,
    },
    REGISTRATION: {
        id: 3,
        name: 'Registration',
        path: RouteConfig.REGISTRATION,
        image: `${process.env.PUBLIC_URL}/img/portal/registration.jpg`,
        navImage: `${process.env.PUBLIC_URL}/img/portal/registration.jpg`,
    },
    EV: {
        id: 4,
        name: 'EV',
        path: RouteConfig.EV,
        image: `${process.env.PUBLIC_URL}/img/portal/ev.jpeg`,
        navImage: `${process.env.PUBLIC_URL}/img/portal/ev.jpeg`,
    },
    UM: {
        id: 5,
        name: 'UM',
        path: RouteConfig.UM,
        image: `${process.env.PUBLIC_URL}/img/portal/um.jpg`,
        navImage: `${process.env.PUBLIC_URL}/img/portal/um.jpg`,
    },
    MAINTENANCE: {
        id: 6,
        name: 'Maintenance',
        path: RouteConfig.MAINTENANCE,
        image: `${process.env.PUBLIC_URL}/img/portal/maintenance.jpg`,
        navImage: `${process.env.PUBLIC_URL}/img/portal/maintenance.jpg`,
    },
    EMPLOYEE_PROFILE_MANAGEMENT: {
        id: 7,
        name: 'Employee Profile Management',
        path: RouteConfig.EMPLOYEE_PROFILE_MANAGEMENT,
        image: `${process.env.PUBLIC_URL}/img/portal/employee-profile-management.jpg`,
        navImage: `${process.env.PUBLIC_URL}/img/portal/employee-profile-management.jpg`,
    },
};

export const FUNCTIONS = {
    ORG_LEAVE: { id: 1 },
    HOLIDAY: { id: 2 },
    LEAVE_SAP: { id: 3 },
    ABSENCE: { id: 4 },
    MEETING_ROOM: { id: 5 },
    USER: { id: 6 },
    USER_GROUP: { id: 7 },
    BOOKING_ROOM: { id: 8 },
    MAINTAINER_CONNEXT: { id: 9 },
    DAILY_BOOKING: { id: 10 },
    EXPORT_CANCELLED_BOOKING: { id: 11 },
    MANAGE_TEMPLATES: { id: 12 },
    FORCE_UPDATE: { id: 13 },
    LEAVE_COLLECT: { id: 14 },
    EMPLOYEE_PROFILE_EDITING_HISTORY: { id: 15 },
};

export const SYSTEM_DISPLAY = {
    LEAVE_ORG: {
        title: 'ระบบลา (Leave System)',
        subMenu: 'จัดการโครงสร้างการลา',
    },
    LEAVE_HOLIDAY: {
        title: 'ระบบลา (Leave System)',
        subMenu: 'จัดการวันหยุด',
    },
    LEAVE_SAP: {
        title: 'ระบบลา (Leave System)',
        subMenu: 'ประวัติการนำเข้า SAP',
    },
    LEAVE_COLLECT: {
        title: 'ระบบลา (Leave System)',
        subMenu: 'ประวัติการนำข้อมูลสะสมวันลาเข้า SAP',
    },
    LEAVE_ABSENCE: {
        title: 'ระบบลา (Leave System)',
        subMenu: 'จัดการใบลา',
    },
    MEETING_CREATE: {
        title: 'ระบบห้องประชุม (Meeting Room System)',
        subMenu: 'จัดการห้องประชุม',
    },
    MEETING_BOOKING: {
        title: 'ระบบห้องประชุม (Meeting Room System)',
        subMenu: 'จัดการข้อมูลการจองห้องประชุม',
    },
    MEETING_DAILY_BOOKING: {
        title: 'ระบบห้องประชุม (Meeting Room System)',
        subMenu: 'รายงานจองห้องประชุมประจำวัน',
    },
    MEETING_CANCELLED_BOOKING: {
        title: 'ระบบห้องประชุม (Meeting Room System)',
        subMenu: 'รายงานการยกเลิกการจองห้องประชุม',
    },
    UM_USER_GROUP: {
        title: 'ระบบจัดการผู้ใช้งาน (APD User Management)',
        subMenu: 'จัดการกลุ่มผู้ใช้งาน',
    },
    UM_USER: {
        title: 'ระบบจัดการผู้ใช้งาน (APD User Management)',
        subMenu: 'จัดการผู้ใช้งาน',
    },
    MAINTAINER_CONNEXT: {
        title: 'ระบบจัดการบำรุงรักษา (Maintenance System)',
        subMenu: 'จัดการปิดระบบ',
    },
    MANAGE_TEMPLATES: {
        title: 'ระบบจัดการบำรุงรักษา (Maintenance System)',
        subMenu: 'จัดการข้อความปิดระบบ',
    },
    FORCE_UPDATE: {
        title: 'ระบบจัดการบำรุงรักษา (Maintenance System)',
        subMenu: 'จัดการ Version Force Update',
    },
    EMPLOYEE_PROFILE_EDITING_HISTORY: {
        title: 'ระบบจัดการข้อมูลพนักงาน (Employee Profile Management)',
        subMenu: 'ประวัติการเปลี่ยนแปลงข้อมูล',
    },
};

export const SUB_FUNCTION = {
    MANAGE_MEETING_ROOM: 1, //แสดง/แก้ไข ห้องประชุม
    ADD_MEETING_ROOM: 2, //เพิ่มห้องประชุม

    CHANGE_PARENT_ORG: 3, //เปลื่ยน parent หน่วยงาน
    EDIT_ORG_LEAVE: 4, //แก้ไขโครงสร้างองค์กร

    ADD_HILIDAY: 5, //เพิ่มวันหยุด
    EDIT_HOLIDAY: 6, //แก้ไขวันหยุด
    DELETE_HOLIDAY: 7, //ลบวันหยุด

    EDIT_LEAVE_SAP: 8, //แก้ไขข้อมูลใบลาจาก SAP
    CANCEL_LEAVE_SAP: 9, //ยกเลิกข้อมูลใบลาจาก SAP
    DETAIL_LEAVE_SAP: 19, // ดูรายละเอียดข้อมูลใบลาจาก SAP

    VIEW_ABSENCE: 21, //ดูข้อมูลใบลา
    EDIT_ABSENCE: 10, //แก้ไขข้อมูลใบลา
    CANCEL_ABSENCE: 11, //ยกเลิกข้อมูลใบลา

    ADD_USER_GROUP: 12, //เพิ่มกลุ่มผู้ใช้งาน
    EDIT_USER_GROUP: 13, //แก้ไขกลุ่มผู้ใช้งาน
    DELETE_USER_GROUP: 14, //ลบกลุ่มผู้ใช้งาน

    ADD_USER: 15, //เพิ่มผู้ใช้งาน
    EDIT_USER: 16, //แก้ไขผู้ใช้งาน
    DELETE_USER: 17, //ลบผู้ใช้งาน

    VIEW_HILIDAY: 18, //ดูวันหยุด
    EXPORT_HOLIDAY: 25, //ออกรายงานวันหยุด

    CREATE_BOOKING_ROOM: 28,
    EDIT_BOOKING_ROOM: 30,
    VIEW_BOOKING_ROOM: 31,
    SEARCH_BOOKING_ROOM_BY_ORG: 34,

    MANAGE_TEMPLATES: 38,
    SET_MANAGER: 40,
};

export const SV_RES_SUCCESS = 'success';

export const BTN = {
    TH: {
        BACK: 'ก่อนหน้า',
        NEXT: 'หน้าต่อไป',
        OK: 'ตกลง',
        CONFIRM: 'ยืนยัน',
        CANCEL: 'ยกเลิก',
        FINISH: 'เสร็จสิ้น',
        CONFIRM_LOCATION: 'ยืนยันตำแหน่ง',
        SEND_EMAIL: 'ส่ง Email ใหม่อีกครั้ง',
        CLOSE: 'ปิดหน้าต่าง',
        SEARCH: 'ค้นหา',
        EXPORT_EXCEL: 'EXCEL',
        EXPORT_PDF: 'PDF',
        SAVE: 'บันทึก',
    },
    EN: {
        BACK: 'Previous Page ',
        NEXT: 'Next Page',
        OK: 'OK',
        CONFIRM: 'Confirm',
        CANCEL: 'Cancel',
        FINISH: 'Finish',
        CONFIRM_LOCATION: 'Confirm Location',
        SEND_EMAIL: 'Send Email',
        CLOSE: 'Close',
        SEARCH: 'Search',
        EXPORT_EXCEL: 'EXCEL',
        EXPORT_PDF: 'PDF',
    },
};

export const ERRORS = {
    REQUIRED: 'กรุณาระบุข้อมูล',
    AT_LEAST_ONE_CONDITION: 'ระบุอย่างน้อย 1 เงื่อนไข',
    VALIDATE: {
        LIMIT_FLOOR: 25,
        BASE_FLOOR: 0,
    },
    MEETING: {
        REQUIRED: 'กรุณาระบุข้อมูล',
        WRONG_PATTREN: 'รูปแบบข้อมูลไม่ถูกต้อง',
        LIMIT_FLOOR: 'ระบุได้ไม่เกิน 25 ชั้น',
    },
};

export const SERVICE_STATUS_CODE = {
    SUCCESS: 200,
};

// ###################### MEETING ######################

export const MEETING = {
    SUBJECT: 'ข้อมูลห้องประชุม',
    SUBJECT_DESCRIPTION: 'รายละเอียดห้องประชุม',
    SUBJECT_EQUIPMENT: 'อุปกรณ์ภายในห้องประชุม',
    SUBJECT_MAINTAINER: 'ข้อมูลผู้ดูแลห้องประชุม',
    SUBJECT_CONDITION: 'เงื่อนไขการจองห้องประชุม',
    NAME: {
        EN: 'Meeting Room',
        TH: 'ห้องประชุม',
    },
    SEARCH: {
        ROOM_NAME: 'ชื่อห้องประชุม',
        MEA_DISTRICT_NAME: 'ชื่อเขตการไฟฟ้า',
        BUILDING_NAME: 'ชื่ออาคาร',
        ADMIN_ORG_NAME: 'หน่วยงานที่ดูแล',
        EQUIPMENT_NAME: 'อุปกรณ์ภายในห้อง',
        RESULT_TABLE: 'ผลลัพธ์การค้นหา',
        NO_RESULT_TABLE: 'ไม่พบผลลัพธ์การค้นหา',
        NO_RESULT: 'ไม่พบข้อมูล',
    },
    TABLE: {
        TITLE: {
            ROOM_NAME: 'ชื่อห้องประชุม',
            BUILDING_NAME: 'ชื่ออาคาร',
            FLOOR: 'ชั้น',
            ADMIN_ORG_NAME: 'หน่วยงานที่ดูแล',
            MEA_DISTRICT_NAME: 'ชื่อเขตการไฟฟ้า',
            SEAT: 'จำนวนที่นั่ง',
            ADDITION_SEAT: 'จำนวนที่นั่งสำรอง',
            BOOK_DAY: 'ช่วงวันที่ใช้ได้',
            ASC: 'asc',
            DESC: 'desc',
        },
        DATA_NAME: {
            ROOM_NAME: 'roomNameTH',
            BUILDING_NAME: 'building',
            FLOOR: 'floor',
            ADMIN_ORG_NAME: 'orgFullName',
            MEA_DISTRICT_NAME: 'meaDistrictName',
            SEAT: 'seat',
            ADDITION_SEAT: 'additionalSeat',
            BOOK_DAY: 'bookingWeekendTable',
            ASC: 'asc',
            DESC: 'desc',
        },
        WEEKDAY: 'Weekday',
        EVERYDAY: 'Everyday',
    },
    PAGING: {
        FROM: 'จาก',
    },
    BUTTON: {
        SEARCH: {
            EN: 'SEARCH',
            TH: 'ค้นหา',
        },
        ADD_MEETING: 'เพิ่มห้องประชุม',
    },
    ROOM_STATUS: {
        FREE_ID: 1,
        BUSY_ID: 2,
        FREE_BUSY_ID: 3,
    },
    ROOM_AVAILABLE: 3,
    PAGE: {
        MEETING: 'meeting',
        ADD: 'add',
        EDIT: 'edit',
    },
    FORM: {
        LABEL: {
            MEA_DIST_ID: 'ชื่อเขตการไฟฟ้า',
            BD_NAME: 'ชื่ออาคาร',
            ROOM_TYPE_ID: 'ประเภทห้องประชุม',
            ROOM_NAME_TH: 'ชื่อห้อง (ไทย)',
            ROOM_NAME_EN: 'ชื่อห้อง (EN)',
            FLOOR: 'ชั้น',
            FLOOR_DISPLAY: 'ชั้นสำหรับแสดง',
            SEAT: 'จำนวนที่นั่ง',
            ADDITIONAL_SEAT: 'จำนวนที่นั่งสำรอง',
            BOOKING_WEEKEND: 'ช่วงวันใช้งาน',
            OPEN_TIME: 'เวลาเริ่มต้น',
            CLOSE_TIME: 'เวลาสิ้นสุด',
            DESCRIPTION: 'รายละเอียด',
            EQUIPMEMT: 'อุปกรณ์',
            ROOM_NO: 'หมายเลขห้อง',
            AVAILABLE: 'เปิดใช้งานห้องประชุม',
            ORG_ID: 'หน่วยงานที่ดูแล',
            PUBLICITY_TYPE_ID: 'เงื่อนไขการจองห้องประชุม',
            CONTACT: 'รายละเอียดการติดต่อ',
            ONLY_MAINTAIN_ORG_ID: 'ใช่ได้เฉพาะหน่วยงาน',
            MAINTAINER_FORM: 'พนักงานที่ดูแล',
            MAINTAINER: 'พนักงานที่ดูแล',
            PERMISSION_ORG: 'หน่วยงานที่ใช้งาน',
            PERMISSION_EMP: 'พนักงานที่ใช้งาน',
            PERMISSION_SEE: 'สิทธิ์ในการมองเห็นห้องประชุม',
            BOOKING_TYPE_ID: 'สิทธิ์ในการจองห้องประชุม',
            RESERVATION_DATE: 'จำนวนวันที่สามารถจองล่วงหน้า',
            COLOR: 'สีห้องประชุม',
        },
        NAME: {
            MEA_DIST_ID: 'meaDistrictId',
            BD_NAME: 'building',
            ROOM_NAME_TH: 'roomNameTH',
            ROOM_NAME_EN: 'roomNameEN',
            FLOOR: 'floor',
            FLOOR_DISPLAY: 'floorDisplay',
            SEAT: 'seat',
            ADDITIONAL_SEAT: 'additionalSeat',
            BOOKING_WEEKEND: 'bookingWeekend',
            OPEN_TIME: 'openTime',
            CLOSE_TIME: 'closeTime',
            ROOM_TYPE_ID: 'roomTypeId',
            DESCRIPTION: 'description',
            EQUIPMEMT: 'equipments',
            ROOM_NO: 'roomNo',
            AVAILABLE: 'available',
            ORG_ID: 'orgId',
            PUBLICITY_TYPE_ID: 'publicityTypeId',
            CONTACT: 'contact',
            ONLY_MAINTAIN_ORG_ID: 'onlyMaintainOrgId',
            MAINTAINER_FORM: 'maintainerFORM',
            MAINTAINER: 'maintainer',
            PERMISSION_ORG: 'permissionOrg',
            PERMISSION_EMP: 'permissionEmp',
            IMAGES: 'images',
            EMP_ID: 'empId',
            EMP: 'emp',
            PERMISSION_SEE: 'publicityTypeId',
            BOOKING_TYPE_ID: 'bookingTypeId',
            RESERVATION_DATE: 'reservationDate',
            COLOR: 'color',
        },
    },
    LUP_BOOKING_WEEKEND: [
        { id: true, name: 'ทุกวัน' },
        { id: false, name: 'จันทร์ - ศุกร์' },
    ],
    PUBLICITY_TYPE_ID_SHOW_PERMISSION: [3, 4],
    BOOKING_TYPE_ID_SHOW_PERMISSION: [3],
    PUBLICITY_TYPE_ID_NOT_ALL: [2],
};

// ########################################## LEAVE ######################################
// export const LEAVE = {
//     NAME: 'จัดการวันหยุด',
//     NAME_YEAR: 'วันหยุดประจำปี',
//     BACK_YEAR: 'back',
//     NEXT_YEAR: 'next',
//     ADD_HOLIDAY: 'เพิ่มวันหยุด',
//     EXPORT: 'ออกรายงานที่ใบลามีผลกระทบ',
//     NAME_OF_DAY: [
//         { id: 0, name: 'อาทิตย์' },
//         { id: 1, name: 'จันทร์' },
//         { id: 2, name: 'อังคาร' },
//         { id: 3, name: 'พุธ' },
//         { id: 4, name: 'พฤหัสบดี' },
//         { id: 5, name: 'ศุกร์' },
//         { id: 6, name: 'เสาร์' }
//     ],
//     NAME_OF_MONTH: [
//         { id: 0, name: 'มกราคม' },
//         { id: 1, name: 'กุมภาพันธ์' },
//         { id: 2, name: 'มีนาคม' },
//         { id: 3, name: 'เมษายน' },
//         { id: 4, name: 'พฤษภาคม' },
//         { id: 5, name: 'มิถุนายน' },
//         { id: 6, name: 'กรกฎาคม' },
//         { id: 7, name: 'สิงหาคม' },
//         { id: 8, name: 'กันยายน' },
//         { id: 9, name: 'ตุลาคม' },
//         { id: 10, name: 'พฤศจิกายน' },
//         { id: 11, name: 'ธันวาคม' }
//     ],
//     TABLE: {
//         ASC: 'asc',
//         DESC: 'desc',
//         TITLE: {
//             HOLIDAY_ID: 'ลำดับ',
//             DAY_NAME: 'วัน',
//             DAY: 'วันที่',
//             MONTH: 'เดือน',
//             YEAR: 'ปี',
//             DESCRIPTION: 'รายละเอียด',
//             ENABLED: 'สถานะการใช้งาน'
//         },
//         DATA_NAME: {
//             HOLIDAY_ID: 'holidayId',
//             DAY_NAME: 'dayName',
//             DAY: 'day',
//             MONTH: 'month',
//             YEAR: 'year',
//             DESCRIPTION: 'description',
//             ENABLED: 'enabled'
//         },
//     },
//     FORM: {
//         LABEL: {
//             HOLIDAY_DAY: 'วันที่',
//             INFO: 'รายละเอียด',
//             ENABLED: 'สถานะการใช้งาน'
//         },
//         NAME: {
//             HOLIDAY_DAY: 'date',
//             INFO: 'info',
//             ENABLED: 'enabled'
//         },
//         ADD_TITLE: 'เพิ่มวันหยุดประจำปี',
//         EDIT_TITLE: 'แก้ไขวันหยุดประจำปี',
//     },

// }

export const ORG_LEAVE = {
    FORM_EDIT_EMP: {
        LABEL: {
            NAME: 'ชื่อ-นามสกุล',
            ID: 'รหัสพนักงาน',
            POSITION: 'ตำแหน่ง',
            ORG: 'หน่วยงาน',
            PERMISSION: 'สิทธิ์ในการพิจารณา',
            REPRESENT: 'พนักงานทำการแทน',
            IS_HAVE_REPRESENT: 'มีพนักงานทำการแทน',
        },
        NAME: {
            NAME: 'name',
            ID: 'empId',
            POSITION: 'position',
            ORG: 'orgId',
            PERMISSION: 'permission',
            REPRESENT: 'empIdApprRep',
            IS_HAVE_REPRESENT: 'isHaveRepresent',
        },
        REPRESENT_TITLE: 'ทำการแทน',
    },
    FORM_EDIT_TOOLBAR: {
        LABEL: {
            TYPE: 'รายชื่อพนักงาน',
            CURRENT_ORG: 'orgId',
        },
        NAME: {
            TYPE: 'type',
            CURRENT_ORG: 'orgId',
            CURRENT_ORG_NAME: 'orgName',
            EMPS: 'emps',
        },
    },
    FORM_EDIT_ORG: {
        LABEL: {
            NAME: 'ชื่อหน่วยงาน',
            PERMISSION_AUDIT: 'ผู้พิจารณา',
            PERMISSION_APPROVE: 'ผู้อนุญาต',
        },
        NAME: {
            NAME: 'name',
            PERMISSION_AUDIT: 'auditEmpId',
            PERMISSION_APPROVE: 'approveEmpId',
        },
    },
    DEFAULT_ORG_LEAVE_SUPERADMIN: '2',
    // DEFAULT_ORG_LEAVE_SUPERADMIN: 1093,
    PERMISSION: {
        NO_PERMISSION: 0,
        AUDIT_ID: 1,
        APPROVE_ID: 2,
        REPRESENT_ID: 3,
        IS_HAVE_REPRESENT: true,
    },
    LAYOUT: [
        {
            id: '',
            name: 'normal',
            label: 'Normal',
            image: `${process.env.PUBLIC_URL}/img/leave/normal.png`,
        },
        {
            id: 1,
            name: 'mixed',
            label: 'Mixed',
            image: `${process.env.PUBLIC_URL}/img/leave/mixed.png`,
        },
        {
            id: 2,
            name: 'tree',
            label: 'Tree',
            image: `${process.env.PUBLIC_URL}/img/leave/tree.png`,
        },
        {
            id: 3,
            name: 'treeLeftOffset',
            label: 'TreeLeftOffset',
            image: `${process.env.PUBLIC_URL}/img/leave/treeLeftOffset.png`,
        },
        {
            id: 4,
            name: 'treeRightOffset',
            label: 'TreeRightOffset',
            image: `${process.env.PUBLIC_URL}/img/leave/treeRightOffset.png`,
        },
    ],
    SORCE: {
        ORG_LEAVE: 'orgLeave',
        ORG_SAP: 'orgSap',
    },
    TYPE: [
        { id: 1, name: 'เฉพาะพนักงานที่เปลี่ยนแปลง' },
        { id: 2, name: 'พนักงานภายในหน่วยงาน' },
    ],
};

export const SAP = {
    SAP_FORM: {
        TITLE: 'ตรวจสอบ SAP Transaction',
        DATE: {
            START_DATE: 'วันที่เข้า SAP เริ่มต้น',
            END_DATE: 'วันที่เข้า SAP สุดท้าย',
            START_LEAVE_DATE: 'วันลาเริ่มต้น',
            END_LEAVE_DATE: 'วันลาสิ้นสุด',
        },
        RESULT_TYPE: {
            TITLE: 'ประเภทผลลัพธ์',
            LU_RESULT_TYPE: [
                { id: 'S', name: 'Success' },
                { id: 'E', name: 'Error' },
            ],
        },
        SEARCH: {
            ABSENSE_ID: 'เลขที่ใบลา',
            LEAVE_SAP_TYPE: 'ประเภทการลา SAP',
        },
        keyword: 'keyword',
        NAME_FORM: {
            DATE: {
                START_DATE: 'startDate',
                END_DATE: 'endDate',
                START_LEAVE_DATE: 'startDateLeave',
                END_LEAVE_DATE: 'endDateLeave',
            },
            RESULT_TYPE: 'responseTypeId',
            KEYWORD: 'keyword',
        },
    },
    TABLE: {
        NAME: {
            DATE: 'leaveDate',
            ABSENSE_ID: 'absenceId',
            LEAVE_SAP_TYPE: 'leaveSapTypeId',
            RESULT: 'result',
            TEXT: 'responseMessage',
        },
        LABEL: {
            DATE: 'วันที่',
            ABSENSE_ID: 'เลขที่ใบลา',
            LEAVE_SAP_TYPE: 'ประเภทการลา SAP',
            RESULT: 'ผลลัพธ์',
            TEXT: 'ข้อความ',
        },
        ASC: 'asc',
        DESC: 'desc',
    },
    DETAIL: {
        ABSENSE_ID: 'หมายเลขใบลา',
        TIME: 'ช่วงเวลา',
        START_DATE: 'วันที่เริ่มลา',
        END_DATE: 'วันที่สิ้นสุดการลา',
        LEAVE_TYPE: 'ประเภทการลา',
        REASON: 'ความจำเป็นในการลา',
        CONTACT: 'ที่อยู่ที่ติดต่อได้',
    },
    NAME_OF_MONTH: [
        { id: 0, name: 'มกราคม' },
        { id: 1, name: 'กุมภาพันธ์' },
        { id: 2, name: 'มีนาคม' },
        { id: 3, name: 'เมษายน' },
        { id: 4, name: 'พฤษภาคม' },
        { id: 5, name: 'มิถุนายน' },
        { id: 6, name: 'กรกฎาคม' },
        { id: 7, name: 'สิงหาคม' },
        { id: 8, name: 'กันยายน' },
        { id: 9, name: 'ตุลาคม' },
        { id: 10, name: 'พฤศจิกายน' },
        { id: 11, name: 'ธันวาคม' },
    ],
    DETAIL_SAP: {
        TITLE: 'รายละเอียด SAP',
        LEAVE_SAP_TYPE_NAME: 'ชื่อประเภทการลา SAP',
        LEAVE_SAP_TYPE_ID: 'ประเภทการลา SAP',
        LEAVE_SAP_TRANS: 'ประเภทการส่งเข้า SAP',
        LEAVE_SAP_RESULT: 'ผลลัพธ์',
        LEAVE_SAP_DATE: 'วันที่',
        LEAVE_SAP_MSG_RESULT: 'ผลลัพธ์ข้อความ',
    },
    DETAIL_LEAVE: {
        TITLE: 'รายละเอียดใบลา',
        APSENCE_ID: 'เลขที่ใบลา',
        EMP_NAME: 'ชื่อ-นามสกุล พนักงาน',
        EMP_ID: 'รหัสพนักงาน',
        DEP_NAME: 'หน่วยงาน/ฝ่าย',
        DIV_NAME: 'กอง/งาน',
        SEC_NAME: 'แผนก',
        PART_NAME: 'กลุ่ม',
        LEAVE_STATE_NAME: 'สถานะใบลา',
        LEAVE_NAME: 'ประเภทการลา',
        LEAVE_CANCEL_REQUEST_DATE: 'วันที่ขอยกเลิกใบลา',
        LEAVE_CANCEL_BY_NAME: 'ผู้ยกเลิกใบลา',
        LEAVE_CANCEL_BY_SYSTEM: 'ยกเลิกจากระบบ',
        PERIOD: {
            NAME: 'ช่วงเวลา',
            MORNING: 'เช้า',
            AFTERNOON: 'บ่าย',
            ALL_DAY: 'เต็มวัน',
            TIME: 'ระบุเวลา',
        },
        LEAVE_START_TIME: 'เวลาเริ่มต้น',
        LEAVE_END_TIME: 'เวลาสิ้นสุด',
        LEAVE_START_DATE: 'วันที่เริ่มต้นลา',
        LEAVE_END_DATE: 'วันที่สิ้นสุดลา',
        LEAVE_REASON: 'ความจำเป็นในการลา',
        LEAVE_CONTACT_ADDRESS: 'ที่อยู่ที่ติดต่อได้',
        LEAVE_ATTACHMENT: 'ไฟล์แนบ',
        LEAVE_WIFE_NAME: 'ชื่อ-นามสกุล ภรรยา',
        LEAVE_CHILD_NAME: 'ชื่อ-นามสกุล บุตร',
        LEAVE_CHILD_BIRTH_DATE: 'วันเกิดบุตร',
        LEAVE_ORDINATION_HALL: 'ชื่อวัดที่บวช',
        LEAVE_ORDINATION_ADDRESS: 'ที่อยู่วัดที่บวช',
        LEAVE_TEMPLE: 'ชื่อวัดที่จำพรรษา',
        LEAVE_TEMPLE_ADDRESS: 'ที่อยู่วัดที่จำพรรษา',
        LEAVE_SICK: 'อาการ/โรค',
        LEAVE_REQUEST_DATE: 'วันที่ยื่นใบลา',
        LEAVE_DURATION: 'จำนวนวันลา',
        LEAVE_REFERENCEID: 'เลขที่ใบลาจากระบบเก่า',
        LEAVE_CANCEL_REASON: 'เหตุผลการยกเลิกใบลา',
        LEAVE_EDIT_DATE: 'วันที่แก้ไข',
        LEAVE_EDIT_BY: 'ผู้แก้ไข',
    },
};

export const ABSENCE = {
    FORM_SEARCH_ABSENCE: {
        NAME: {
            ABSENCE_ID: 'absenceId',
            YEAR: 'year',
            NAME: 'name',
            ORG_ID: 'orgId',
            START_DATE: 'startDate',
            END_DATE: 'endDate',
            LEAVE_STATE_ID: 'leaveStateId',
            LEAVE_WAIT_SAP_STATE_ID: 'flag',
            LEAVE_TYPE_ID: 'leaveTypeId',
            NAME_SIGN: 'nameSign',
            ERROR: 'error',
        },
        LABEL: {
            ABSENCE_ID: 'เลขที่ใบลา',
            NAME: 'รหัสพนักงานหรือชื่อ-นามสกุล ผู้ยื่นใบลา',
            ORG_ID: 'หน่วยงาน',
            START_DATE: 'วันที่เริ่มต้นลา',
            END_DATE: 'วันที่สิ้นสุดลา',
            LEAVE_STATE_ID: 'สถานะใบลา',
            LEAVE_WAIT_SAP_STATE_ID: 'ผลการตรวจสอบจาก SAP',
            LEAVE_TYPE_ID: 'ประเภทการลา',
            NAME_SIGN: 'รหัสพนักงานหรือชื่อ-นามสกุล ผู้พิจารณา/อนุญาต',
        },
    },
    FORM_EDIT_ABSENCE: {
        NAME: {
            ABSENCE_ID: 'absenceId',
            LEAVE_TYPE_ID: 'leaveTypeId',
            EMP_NAME: 'empName',
            EMP_ID: 'empId',
            ORG_ID: 'orgId',
            DEP_NAME: 'depName',
            DIV_NAME: 'divName',
            SEC_NAME: 'secName',
            PART_NAME: 'partName',
            LEAVE_STATE_NAME: 'leaveStateName',
            LEAVE_PERIOD_ID: 'leavePeriodId', // ช่วงเวลา
            STATR_TIME: 'startTime',
            END_TIME: 'endTime',
            START_DATE: 'startDate',
            END_DATE: 'endDate',
            DATE: 'date',
            DATE_TIME: 'dateTime',
            SICK_REASON_ID: 'sickReasonId',
            LEAVE_REASON: 'leaveReason',
            WIFE_NAME: 'wifeName',
            CHILD_NAME: 'childName',
            CHILD_BIRTH_DATE: 'childBirthDate',
            ORDINATION_HALL: 'ordinationHall', // วัดที่บวช
            ORDINATION_ADDRESS: 'ordinationAddress', // ที่อยุ่วัดที่บวช
            ORDINATION_DATE: 'ordinationDate',
            TEMPLE: 'temple', // วัดที่จำพรรษา
            TEMPLE_ADDRESS: 'templeAddress', // วัดที่จำพรรษา
            CONTACT_ADDRESS: 'contactAddress',
            ATTACHMENT: 'attachment', //เอกสารแนบ
            LINK_DOWNLOAD: 'linkDownload',
            IN_ADVANCE: 'inAdvance',
        },
        LABEL: {
            ABSENCE_ID: 'เลขที่ใบลา',
            LEAVE_TYPE_ID: 'ประเภทการลา',
            EMP_NAME: 'ชื่อ-นามสกุล พนักงาน',
            EMP_ID: 'รหัสพนักงาน',
            ORG_ID: 'ชื่อหน่วยงาน/ฝ่าย',
            DEP_NAME: 'หน่วยงาน/ฝ่าย',
            DIV_NAME: 'กอง/งาน',
            SEC_NAME: 'แผนก',
            PART_NAME: 'กลุ่ม',
            LEAVE_STATE_NAME: 'สถานะใบลา',
            LEAVE_PERIOD_ID: 'ช่วงเวลา', // ช่วงเวลา
            STATR_TIME: 'เวลาเริ่มต้น',
            END_TIME: 'เวลาสิ้นสุด',
            START_DATE: 'วันที่เริ่มต้นลา',
            END_DATE: 'วันที่สิ้นสุดลา',
            DATE: 'วันที่ลา',
            DATE_TIME: 'วันที่',
            SICK_REASON_ID: 'อาการ(โรค)',
            LEAVE_REASON: 'ความจำเป็นในการลา',
            WIFE_NAME: 'ชื่อ-สกุลภรรยา',
            CHILD_NAME: 'ชื่อ-สกุลบุตร',
            CHILD_BIRTH_DATE: 'วันเกิดบุตร',
            ORDINATION_HALL: 'วัดที่บวช',
            ORDINATION_ADDRESS: 'ที่อยู่ของวัดที่บวช',
            ORDINATION_DATE: 'วันที่บวช',
            TEMPLE: 'วัดที่จำพรรษา',
            TEMPLE_ADDRESS: 'ที่อยู่ของวัดที่จำพรรษา',
            CONTACT_ADDRESS: 'ที่อยู่ที่ติดต่อได้',
            ATTACHMENT: 'เอกสารแนบ',
            LINK_DOWNLOAD: 'linkDownload',
            LEAVE_RESULT: 'ผลการพิจารณา/อนุญาต',
            REJECT_REASON: 'เหตุผลการยกเลิกใบลา',
            SAP_MESSAGE: 'ผลการตรวจสอบกับระบบ SAP',
            REJECT_DATE: 'วันที่ขอยกเลิกใบลา',
            REJECT_BY: 'ผู้ยกเลิกใบลา',
            REJECT_WAY: 'ยกเลิกจากระบบ',
            IS_SPECIAL: 'ลากรณีพิเศษ',
            UPDATE_DATE: 'วันที่แก้ไข',
            UPDATE_BY: 'ผู้แก้ไข',
            DURATION: 'จำนวนวันลา',
            LEAVE_REQUEST_DATE: 'วันที่ยื่นใบลา',
            REFERENCE_ID: 'เลขที่ใบลาจากระบบเก่า',
        },
        LEAVE_PERIOD_ID: {
            MORNING: 1,
            AFTERNOON: 2,
            ALL_DAY: 3,
            SPECIFIC_TIME: 4,
        },
        LEAVE_TYPE_ID: {
            SICK: 1, //ลาป่วย
            PERSONAL: 2, //ลากิจส่วนตัว
            VACATION: 3, //ลาพักผ่อนประจำปี
            MATERNITY: 4, //ลาคลอดบุตร
            CHILD_SUPPORT: 5, //ลาเพื่อเลี้ยงดูบุตร
            ASSIST_BIRTH: 6, //ลาไปช่วยเหลือภริยาที่คลอดบุตร
            ORDINATION: 7, //ลาอุปสมบท
            HAJJ: 8, //ลาไปประกอบพิธีฮัจย์
            WOMAN_ORDINATION: 9, //สตรีลาไปถือศีลและปฎิบัติธรรม
        },
        ATTACHMENT_SUPPORT: ['png', 'jpg', 'jpeg', 'pdf'],
        MAXIMUM_ATTACHMENT: 3,
        MAXIMUM_SIZE_ATTACHMENT: 5,
        PALCE_CONTACT_ADDRESS: 'ระบุที่อยู่ที่ติดต่อได้',
        PLACE_REASON: 'ระบุความจำเป็นในการลา',
    },
    TABLE_ABSENCE: {
        TABLE: {
            ABSENCE_ID: 'เลขที่ใบลา',
            LEAVE_TYPE: 'ประเภทการลา',
            EMP_ID: 'รหัสพนักงาน',
            NAME: 'ชื่อ-สกุล ผู้ยื่นใบลา',
            ORG: 'หน่วยงาน',
            START_DATE: 'วันที่เริ่มต้นลา',
            END_DATE: 'วันที่สิ้นสุดลา',
            LEAVE_STATE: 'สถานะใบลา',
            DURATION: 'จำนวนวันลา',
            LEAVE_DATE: 'ช่วงวันที่ลา',
        },
        DATA: {
            ABSENCE_ID: 'absenceId',
            LEAVE_TYPE: 'leaveType',
            EMP_ID: 'empId',
            NAME: 'name',
            ORG: 'orgName',
            START_DATE: 'startDateDisplay',
            END_DATE: 'endDateDisplay',
            LEAVE_STATE: 'leaveState',
            DURATION: 'duration',
            LEAVE_DATE: 'leaveDate',
        },
    },
    LEAVE_TYPE_R_ATTACHMENT: [1, 4, 6, 7, 8, 9],
    LEAVE_STATE_ID: {
        CANCEL: 6,
        CANCEL_APPROVE: 4,
        CANCEL_PERMIT: 5,
        CANCEL_SAP: 9,

        WAITING_APPROVE: 1,
        WAITING_PERMIT: 2,
        PERMITTED: 3,

        WAITING_CANCEL_PERMIT: 7,
        WAITING_CANCEL_APPROVE: 8,
    },
    LEAVE_STATE_IN_PROGRESS: {
        id: 99,
        name: 'อยู่ระหว่างดำเนินการ',
        displayName: 'อยู่ระหว่างดำเนินการ',
    },
    LEAVE_CANCEL_IN_PROGRESS: [6, 7, 8],
};

export const UM_USER_GROUP = {
    TABLE_USER_GROUP: {
        TABLE: {
            NAME: 'กลุ่มผู้ใช้งาน',
            LIST_SYSTEM: 'ระบบ',
            MEMBER: 'จำนวนพนักงาน',
            PERMISSION_EMP: 'สิทธิ์การเข้าถึงผู้ใช้งาน',
            PERMISSION_ORG: 'สิทธิ์การเข้าถึงหน่วยงาน',
        },
        DATA: {
            NAME: 'userGroupName',
            LIST_SYSTEM: 'listSystem',
            MEMBER: 'member',
            PERMISSION_EMP: 'permissionEmpName',
            PERMISSION_ORG: 'permissionOrgName',
        },
    },
    FORM_USER_GROUP: {
        NAME: {
            GROUP_NAME: 'userGroupName',
            // PERMISSION_EMP: 'permissionEmpId',
            PERMISSION_ORG: 'permissionOrgId',
            FUNCTION: 'functions',
            FUNCTION_DISPLAY: 'functionDisplay',
            LIST_USER: 'listEmp',
            SEARCH_EMP: 'searchEmp',
            ORG: 'org',
        },
        LABEL: {
            GROUP_NAME: 'ชื่อกลุ่มผู้ใช้งาน',
            PERMISSION_EMP: 'สิทธิ์การเข้าถึงผู้ใช้งาน',
            PERMISSION_ORG: 'สิทธิ์การเข้าถึงหน่วยงาน',
            FUNCTION_DISPLAY: 'ฟังก์ชัน',
            LIST_USER: 'รายการพนักงานที่เลือก',
            SEARCH_EMP: 'ค้นหาพนักงาน',
            ORG: 'หน่วยงาน',
        },
    },
    PAGE: {
        USER_GROUP: 'USER_GROUP',
        EDIT_USER_GROUP: 'EDIT_USER_GROUP',
        ADD_USER_GROUP: 'ADD_USER_GROUP',
    },
};
