import React from 'react'
import classNames from 'classnames'

// Material UI Importing
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: "20px"
    },
    fullWidth: {
        width: '100%',
    },
    title: {
        width: 'fit-content',
        // marginLeft: theme.spacing.unit * 5,
        // marginLeft: props => props.marginLeft,
        // paddingLeft: theme.spacing.unit * 3,
        // paddingRight: theme.spacing.unit * 3,
        // paddingBottom: theme.spacing.unit,
        borderBottom: 'solid 2px #F47B15',
        marginBottom: '-1.25px'
        // fontSize: '20px'
    },
    subtitle: {
        lineHeight: 1.25
    },
    subLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // marginLeft: theme.spacing.unit
        marginLeft: theme.spacing(1)
    },
    textColor: {
        color: theme.palette.primary.main
    },
    red: {
        color: 'red'
    }
}))

export const Subject = (props) => {

    const classes = useStyles()
    const {
        label,
        styleSubject,
        subLabel,
        tooltip,
        endAdornment,
        lebel2,
        required,
        disabled
    } = props
    
    return (
        <div className={classes.fullWidth}>
            <Grid container>
                <Grid className={
                    classNames(classes.title, {
                        [styleSubject]: styleSubject,
                    })}
                >
                    <Typography variant="subtitle1" className={classes.subtitle}>
                        {label}
                        {label && required && disabled !== true && <label className={classes.red}>*</label>}
                        {lebel2? <span className={classes.textColor}>{lebel2}</span>:null} 
                        {tooltip}
                    </Typography>
                </Grid>
                <Grid item className={classes.subLabel}>
                    { subLabel &&  
                        <Typography variant="caption" className={classes.root}>
                            {subLabel} 
                        </Typography>
                    }
                </Grid>
                { endAdornment &&  endAdornment }
            </Grid>
        </div>
    );
}

export default Subject