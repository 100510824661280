import { useState, useRef } from 'react'

const UseCalendarBookingState = () => {
    const [events, setEvents] = useState([]);
    const [agendaEventData, setAgendaEventData] = useState([]);
    const [currentDateObj, setCurrentDateObj] = useState(null);
    const [isSearch, setIsSearch] = useState(false);
    const [isOpenModalFormSearchMeetingRoom, setIsOpenModalFormSearchMeetingRoom] = useState(false);
    const formValuesRef = useRef({});
    const listSelectedMeetingRoomIDRef = useRef([]);

    const handleSetAgendaEventData = (data) => {
        setAgendaEventData(data)
        !isSearch && setIsSearch(true)
    }

    return {
        events,
        setEvents,
        agendaEventData,
        setAgendaEventData: handleSetAgendaEventData,
        currentDateObj,
        setCurrentDateObj,
        isOpenModalFormSearchMeetingRoom,
        setIsOpenModalFormSearchMeetingRoom,
        formValuesRef,
        listSelectedMeetingRoomIDRef,
        isSearch
    }
}

export default UseCalendarBookingState
