import React, { Fragment, useEffect, useState } from 'react';

import UseAlertMessageState from '../../../../control/alert/UseAlertMessageState';
import UseLoadingState from '../../../../control/loading/UseLoadingState';

import AlertMessage from '../../../../control/alert/AlertMessage';
import Loading from '../../../../control/loading/Loading';

import LeaveSAPDetailView from '../../presentational/leavesap/LeaveSAPDetailView';
import LeaveSAPDetailTransaction from './LeaveSAPDetailTransaction';
import LeaveSAPDetailAbsence from './LeaveSAPDetailAbsence';
import LeaveSAPDetailHistory from './LeaveSAPDetailHistory';
import LeaveSAPCancel from './LeaveSAPCancel';
import LeaveSAPSignBy from './LeaveSAPSignBy';

import * as APILeaveSAP from '../../../../api/ApiLeaveSAP';
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants'
import { convertDateDisplay } from './LeaveSAPUtils';
import { checkResStatusService } from '../../../../utilities/ResServiceUtils';
import { checkStatusCancel } from '../absence/AbsenceUtils'


const LeaveSAPDetail = props => {
    const { onCancelClick, data, profile, permisCancel, onReSearch } = props;

    const alertMessageState = UseAlertMessageState();
    const loadingState = UseLoadingState();

    const [dataDetail, setDataDetail] = useState(null);
    const [dataHistory, setDataHistory] = useState([]);
    const [dataAbsence, setDataAbsence] = useState([]);
    const [dataSignBy, setDataSignBy] = useState([]);
    const [lutLeaveSapType, setLuLeaveSapType] = useState(null);
    const [lutLeaveSpecial, setLutLeaveSpecial] = useState()
    const [isOpenCancelForm, setIsOpenCancelForm] = useState(false);
    const [refresh, setRefresh] = useState(false)

    const [dateHistory, setDateHistory] = useState({
        startDate: null,
        endDate: null,
        ready: false
    })

    const setFormatSignBy = data => {
        const newData = data.map(item => {
            if (item.approveDate) {
                const splitDateTime = item.approveDate.split(' ');
                const dateFormat = convertDateDisplay(
                    new Date(splitDateTime[0])
                );
                const timeFormat = `${splitDateTime[1].slice(0, 5)} น.`;

                item.aDate = dateFormat;
                item.aTime = timeFormat;
            } else {
                item.aDate = null;
                item.aTime = null;
            }

            return item;
        });

        return newData
    };

    useEffect(() => {
        loadingState.showLoading();
        Promise.all([
            APILeaveSAP.getLeaveSapDetail(data.absenceId),
            APILeaveSAP.getLeaveAbsence(data.absenceId),
            APILeaveSAP.getLuLeaveSapType(data.leaveTypeId),
            APILeaveSAP.callServiceGetLutSpecialLeave(data.leaveTypeId)
        ])
            .then(([resLeaveSapDetail, resLeaveAbsence, resLutLeaveSapType, resLutSpecialLeave]) => {
                loadingState.hideLoading();

                if (resLeaveSapDetail && resLeaveSapDetail.data) {
                    resLeaveSapDetail &&
                        resLeaveSapDetail.data &&
                        setDataDetail({
                            leaveSapDetail:
                                resLeaveSapDetail.data.leaveSapDetail
                        });
                    resLeaveSapDetail &&
                        resLeaveSapDetail.data &&
                        setDataHistory(resLeaveSapDetail.data.leaveSapHistory);
                    resLeaveAbsence &&
                        resLeaveAbsence.data &&
                        setDataAbsence(resLeaveAbsence.data);
                    resLeaveAbsence &&
                        resLeaveAbsence.data &&
                        setDataSignBy(
                            setFormatSignBy(resLeaveAbsence.data.signBy)
                        );
                    resLutLeaveSapType &&
                        resLutLeaveSapType.data &&
                        setLuLeaveSapType(resLutLeaveSapType.data);
                    resLutSpecialLeave &&
                        resLutSpecialLeave.data &&
                        resLutSpecialLeave.data.length > 0 &&
                        setLutLeaveSpecial(resLutSpecialLeave.data[0])
                } else {
                    const msg = checkResStatusService(resLeaveSapDetail)
                    alertMessageState.showAlert({
                        altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
                        callbackCancel: () => {
                            onCancelClick()
                        }
                    })
                }
            })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const reloadLeaveSapDetail = () => {
        loadingState.showLoading();
        Promise.all([
            APILeaveSAP.getLeaveSapDetail(data.absenceId),
            APILeaveSAP.getLeaveAbsence(data.absenceId),
            APILeaveSAP.getLuLeaveSapType(data.leaveTypeId),
            APILeaveSAP.callServiceGetLutSpecialLeave(data.leaveTypeId)
        ])
            .then(
                ([resLeaveSapDetail, resLeaveAbsence, resLutLeaveSapType, resLutSpecialLeave]) => {
                    resLeaveSapDetail &&
                        resLeaveSapDetail.data &&
                        setDataDetail({
                            leaveSapDetail:
                                resLeaveSapDetail.data.leaveSapDetail
                        });
                    resLeaveSapDetail &&
                        resLeaveSapDetail.data &&
                        setDataHistory(resLeaveSapDetail.data.leaveSapHistory);
                    resLeaveAbsence &&
                        resLeaveAbsence.data &&
                        setDataAbsence(resLeaveAbsence.data);
                    resLeaveAbsence &&
                        resLeaveAbsence.data &&
                        setDataSignBy(
                            setFormatSignBy(resLeaveAbsence.data.signBy)
                        );
                    resLutLeaveSapType &&
                        resLutLeaveSapType.data &&
                        setLuLeaveSapType(resLutLeaveSapType.data);
                    resLutSpecialLeave &&
                        resLutSpecialLeave.data &&
                        resLutSpecialLeave.data.length > 0 &&
                        setLutLeaveSpecial(resLutSpecialLeave.data[0])
                }
            )
            .then(() => {
                loadingState.hideLoading();
                setRefresh(true)
            });
    }

    const handleControlCancelForm = data => {
        setIsOpenCancelForm(!isOpenCancelForm);
    };

    const callHistoryPage = ({ startDate, endDate }) => {
        setDateHistory({
            startDate: startDate,
            endDate: endDate,
            ready: true
        })
    }

    const callbackReady = (bool) => {
        setDateHistory({
            ...dateHistory,
            ready: bool
        })
    }

    const callbackRefresh = () => {
        setRefresh(false)
    }

    const handleChangeTab = () => {
        setDateHistory({
            startDate: null,
            endDate: null,
            ready: false
        })
    }

    // const isCancel = (data.leaveStateId === 6 || data.leaveStateId === 9)
    const isCancel = checkStatusCancel(dataAbsence.leaveStateId)

    return (
        <Fragment>
            {dataDetail && lutLeaveSapType && (
                <LeaveSAPDetailView
                    isOpenHistory={dataHistory.length > 0}
                    isCancel={isCancel}
                    permisCancel={permisCancel}
                    onCancelClick={handleControlCancelForm}
                    readyChange={dateHistory.ready}
                    callbackReady={callbackReady}
                    loadingState={loadingState}
                    onChangeMenu={handleChangeTab}
                    leaveStateId={dataAbsence.leaveStateId}
                    leaveStateName={dataAbsence.leaveStateName}
                >
                    <LeaveSAPDetailAbsence
                        data={data}
                        dataAbsence={dataAbsence}
                        onCancelClick={onCancelClick}
                        info={lutLeaveSpecial}
                    />
                    <LeaveSAPSignBy
                        signBy={dataSignBy}
                        onCancelClick={onCancelClick}
                    />
                    <LeaveSAPDetailTransaction
                        isCancel={isCancel}
                        data={data}
                        dataDetail={dataDetail}
                        profile={profile}
                        lutLeaveSapType={lutLeaveSapType}
                        onCancelAbsClick={handleControlCancelForm}
                        onCancelClick={onCancelClick}
                        onReSearch={onReSearch}
                        callHistoryPage={callHistoryPage}
                        onReload={reloadLeaveSapDetail}
                        refresh={refresh}
                        callbackRefresh={callbackRefresh}
                        dataHistory={dataHistory}
                        dataAbsence={dataAbsence}
                    />
                    {dataHistory.length > 0 && (
                        <LeaveSAPDetailHistory
                            dataHistory={dataHistory}
                            data={data}
                            onCancelClick={onCancelClick}
                            leaveDate={{
                                startDate: dateHistory.startDate,
                                endDate: dateHistory.endDate
                            }}
                        />
                    )}
                </LeaveSAPDetailView>
            )
            }
            {
                isOpenCancelForm && (
                    <LeaveSAPCancel
                        isOpenCancelForm={isOpenCancelForm}
                        data={data}
                        profile={profile}
                        onClickClose={handleControlCancelForm}
                        onCancelClick={onCancelClick}
                        onReSearch={onReSearch}
                    />
                )
            }
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment >
    );
};

export default LeaveSAPDetail
