import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Button from '../../../../../control/button/Button';

import { FORM_BOOKING } from '../../../../../constants/meeting/BookingConstants';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    containerPaper: {
        width: '85%',
        // height: '85%',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            height: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%'
        }
    },
    conGrid: {
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    title: {
        fontSize: '33px'
    },
    listItem: {
        width: '100%',
        height: '50px',
        backgroundColor: 'rgba(218, 218, 218, 0.5019607843137255)',
        marginTop: '10px',
        marginBottom: '10px',
        borderRadius: '5px',
        boxShadow: theme.field.boxShadow,
        border: '1px solid gainsboro',
        padding: '5px'
    },
    labelItem: {
        color: theme.mainTheme.color1
    },
    divider: {
        width: '90%',
        margin: '1%',
        marginLeft: '5%'
    },
    gridBox: {
        padding: '2%',
        backgroundColor: '#e6e6e682',
        borderRadius: '5px',
        boxShadow: theme.field.boxShadow
    },
    gridBtn: {
        marginTop: '2%',
        textAlign: 'center'
    },
    containerEquipment: {
        backgroundColor: '#eae9e7',
        borderRadius: '5px',
        boxShadow: theme.field.boxShadow,
        padding: '2%'
    },
    spanMaintainer: {
        minWidth: '48px',
        minHeight: 'auto',
        borderRadius: '24px',
        fontSize: '18px',
        padding: ' 0px 5px 0px 5px',
        margin: '0px 5px 5px 5px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'defaul',
        border: '1px solid grey'
    }
}));

const DetailBookingListView = props => {
    const classes = useStyles();
    const {
        isCancelled = false,
        data,
        onClickClose
    } = props;
    return (
        <Modal
            open={true}
            className={classes.modal}
            closeAfterTransition
            BackdropProps={{
                timeout: 500
            }}
        >
            <Grid container justify="center" className={classes.containerPaper} >
                <Grid container className={classes.conGrid} justify="center" >
                    <Grid item xs={11}>
                        <span className={classes.title}>
                            รายละเอียดการจองห้องประชุม
                            </span>
                    </Grid>
                    <Scrollbars
                        universal={true}
                        style={{
                            minHeight: '480px',
                            maxHeight: 'calc(100% - 110px)'
                        }}
                    >
                        <Grid container justify="center" >
                            <Grid item xs={11}>
                                <Grid container className={classes.gridBox}>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.START_DATE_TIME} :{' '}
                                        </span>{' '}
                                        {data.startDateTime}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.END_DATE_TIME} :{' '}
                                        </span>{' '}
                                        {data.endDateTime}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.TOPIC} :{' '}
                                        </span>{' '}
                                        {data.topic}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.ATTENDEES} :{' '}
                                        </span>{' '}
                                        {data.numberOfAttendees}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.CHAIRMAN} :{' '}
                                        </span>{' '}
                                        {data.chairman}
                                    </Grid>

                                    <Divider className={classes.divider} />
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.EMP_NAME} :{' '}
                                        </span>{' '}
                                        {data.empName}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.ORG_EMP_NAME} :{' '}
                                        </span>{' '}
                                        {data.empOrgName}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.TEL} :{' '}
                                        </span>{' '}
                                        {data.tel}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.MODIFY} :{' '}
                                        </span>{' '}
                                        {data.createDate}
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {isCancelled ? FORM_BOOKING.CANCELLED_BY : FORM_BOOKING.FINAL_MODIFY} :{' '}
                                        </span>{' '}
                                        {data.modifyEmp}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {isCancelled ? FORM_BOOKING.CANCELLED_DATE : FORM_BOOKING.FINAL_MODIFY_DATE}{' '} :{' '}
                                        </span>{' '}
                                        {data.modifyDate}
                                    </Grid>

                                    {isCancelled && <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.CANCEL_REASON}{' '} :{' '}
                                        </span>{' '}
                                        {data.reason}
                                    </Grid>}

                                    <Divider className={classes.divider} />
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.ROOM_NAME} :{' '}
                                        </span>{' '}
                                        {data.roomName}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.DISTRICT} :{' '}
                                        </span>{' '}
                                        {data.district}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.BUILDING_NAME} :{' '}
                                        </span>{' '}
                                        {data.building}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.FLOOR} :{' '}
                                        </span>{' '}
                                        {data.floor}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.SEAT} :{' '}
                                        </span>{' '}
                                        {data.seat}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.WEEKLY_BOOKING} :{' '}
                                        </span>{' '}
                                        {data.bookingWeekend
                                            ? `ทุกวัน`
                                            : `จันทร์-ศุกร์`}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.EQUIPTMENT} :{' '}
                                        </span>
                                        {data.equipment.length > 0 ? (
                                            <Grid container justify="center" style={{ marginBottom: '1%' }}>
                                                <Grid item xs={10} className={classes.containerEquipment} >
                                                    <Grid container>
                                                        {data.equipment.map((item, index) => {
                                                            return (
                                                                <Grid key={`equipment-${index}-${item.nameTH}`} item xs={12} sm={6}>
                                                                    <Grid container >
                                                                        <Grid item xs={7} >
                                                                            {item.nameTH}
                                                                        </Grid>
                                                                        <Grid item xs={2} style={{ textAlign: 'right' }} >
                                                                            {item.quantity}
                                                                        </Grid>
                                                                        <Grid item xs={1} />
                                                                        <Grid item xs={2}>
                                                                            {item.unitTH}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                                FORM_BOOKING.NO_EQUIPMENT
                                            )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.DESCRIPTION} :{' '}
                                        </span>{' '}
                                        {data.description}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.ORG_NAME} :{' '}
                                        </span>{' '}
                                        {data.orgName}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item md={12} xs={12} container direction={'row'} >
                                                <span className={classes.labelItem} >
                                                    {FORM_BOOKING.MAINTAINER}{' '}:{' '}
                                                </span>

                                                {data.maintainer.map(
                                                    (item, index) => {
                                                        const str = `${item.empNameTH} (${item.empId})`;
                                                        return (
                                                            <Grid key={str} item className={classes.spanMaintainer} >
                                                                <span>
                                                                    {str}
                                                                </span>
                                                            </Grid>
                                                        );
                                                    }
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <span className={classes.labelItem}>
                                            {FORM_BOOKING.CONTACT} :{' '}
                                        </span>{' '}
                                        {data.contact}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Scrollbars>

                    <Grid item xs={12} className={classes.gridBtn}>
                        <Button
                            label="ปิด"
                            className={classes.buttonCancel}
                            onClick={onClickClose}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DetailBookingListView;
