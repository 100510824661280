import React from 'react'

import { Scrollbars } from 'react-custom-scrollbars'
import { makeStyles, Paper, Grid } from '@material-ui/core'

import { MEETING } from '../../../../../constants/APDConstants'

import Button from '../../../../../control/button/Button'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            paddingTop: theme.spacing(3),
        }
    },
    rootPaper: {
        margin: '10px',
        paddingBottom: '5%',
        paddingTop: '2%'
    },
    footer: {
        marginTop: '10px !important'
    },
    subjectMeeting: {
        paddingTop: '26px !important',
        fontSize: '26px',
        borderBottom: '2px solid #F47B15'
    },
    btnCancel: {
        backgroundColor: '#808080 !important'
    }
}))

const FormMeetingRoomView = (props) => {

    const classes = useStyles()
    const {
        children,
        handleSubmit,
        onSubmit,
        onCancel
    } = props

    return (
        <Scrollbars>
            <Grid container className={classes.root} justify='center'>
                <Grid item sm={11} xs={12}>
                    <Paper className={classes.rootPaper}>
                        <Grid container justify='center' style={{ paddingBottom: '10px' }}>
                            <Grid item xs={10} className={classes.subjectMeeting}>
                                {MEETING.SUBJECT}
                            </Grid>
                        </Grid>
                        {children[0]}
                        {children[1]}
                        {children[2]}
                    </Paper>
                    <Grid container justify='center' spacing={2} className={classes.footer}>
                        <Grid item>
                            <Button
                                label='ยกเลิก'
                                onClick={onCancel}
                                className={classes.btnCancel}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                label='บันทึก'
                                onClick={handleSubmit(onSubmit)}
                                // onClick={onSubmit}
                                />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Scrollbars>
    )
}

export default FormMeetingRoomView