import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import RouteConfig from '../../../../../config/appRoute/RouteConfig'

import CalendarList from './CalendarList';
// import TableList from './TableList';
import TableList from '../../../presentational/booking/bookingList/TableListView';
import BookingListIndexView from '../../../presentational/booking/bookingList/BookingListIndexView';

const BookingListIndex = ({ history }) => {
    const { pathname } = history.location

    const handleBookingListPage = () => {
        switch (pathname) {
            case RouteConfig.MEETING_BOOKING_CALENDAR:
                history.push(RouteConfig.MEETING_BOOKING_TABLE)
                break;
            case RouteConfig.MEETING_BOOKING_TABLE:
                history.push(RouteConfig.MEETING_BOOKING_CALENDAR)
                break;
            default:
                break;
        }
    };

    const renderRoute = () => {
        return (
            <Switch>
                <Route exact key={RouteConfig.MEETING_BOOKING_TABLE} path={RouteConfig.MEETING_BOOKING_TABLE} component={TableList} />
                <Route exact key={RouteConfig.MEETING_BOOKING_CALENDAR} path={RouteConfig.MEETING_BOOKING_CALENDAR} component={CalendarList} />
                <Redirect to={RouteConfig.MEETING_BOOKING_TABLE} />
            </Switch>
        )
    }

    return (
        <BookingListIndexView
            onSwitchClick={handleBookingListPage}
            statePage={pathname === RouteConfig.MEETING_BOOKING_CALENDAR}
        >
            {renderRoute()}
        </BookingListIndexView>
    );
};

export default withRouter(BookingListIndex)
