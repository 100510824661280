import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: theme.zIndex.modal + 1,
        backgroundColor: 'rgba(255,255,255,0.5)',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: '0px',
        left: '0px',
        bottom: '0px',
        right: '0px'
    },
    progress: {
        width: '80px !important',
        height: '80px !important',
        position: 'absolute',
        top: 'calc(50% - 40px)',
        left: 'calc(50% - 40px)',
    }
}));

const Loading = ({ isShowLoading = true }) => {
    const classes = useStyles();

    if (isShowLoading) {
        return (
            <div className={classes.root} >
                <CircularProgress classes={{ root: classes.progress }} />
            </div >
        )
    } else {
        return <Fragment />
    }
}

export default Loading
