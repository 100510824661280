import React, { Fragment, useEffect, useContext, useState } from 'react';

import {
    getLeaveAbsence,
    getLeaveAbsenceDetail,
    getLutSpecialLeave,
} from '../../../../api/ApiAbsence';
import { ABSENCE, PERMISSION_ORG } from '../../../../constants/APDConstants';
import { AbsenceContext } from './AbsenceProvider';
import { getUrlParam } from '../../../../utilities/Utils';
import {
    convertDateFormatService,
    convertDateDisplay,
    getFirstDateOfCurentMount,
    getLastDateOfCurentMount,
} from '../../../../utilities/DatetimeUtils';
import { checkShowCancelAbsence } from './AbsenceUtils';
import { checkResStatusService } from '../../../../utilities/ResServiceUtils';
import * as AlertMSGConstants from '../../../../constants/AlertMSGConstants';

import AbsenceView from '../../presentational/absence/AbsenceView';
import FormSearchAbsence from './form/FormSearchAbsence';
import TableAbsence from './TableAbsence';
import EditAbsence from './EditAbsence';
import SignAbsence from './SignAbsence';
import ModalAbsebceView from '../../presentational/absence/ModalAbsebceView';
import Loading from '../../../../control/loading/Loading';
import AlertMessage from '../../../../control/alert/AlertMessage';
import AbsenceReport from './AbsenceReport';
const {
    START_DATE,
    END_DATE,
    NAME,
    NAME_SIGN,
    ABSENCE_ID,
    YEAR,
} = ABSENCE.FORM_SEARCH_ABSENCE.NAME;

const Absence = () => {
    const [searchParam, setSearchParam] = useState();
    const {
        lutAbsenceState,
        dataTableState,
        loadingState,
        alertMessageState,
        absenceState,
        panelState,
        permissionSystem,
        permissionOrgId,
        depId,
    } = useContext(AbsenceContext);

    const getParamIniForm = () => {
        const nameSign = getUrlParam('nameSign');
        let initParam = null;

        if (nameSign) {
            initParam = {
                nameSign,
                leaveStateId: ABSENCE.LEAVE_STATE_IN_PROGRESS.id,
            };
        } else {
            initParam = {
                startDate: getFirstDateOfCurentMount(),
                endDate: getLastDateOfCurentMount(),
            };
        }

        const paramConvert = getParam(initParam);

        setSearchParam(initParam);
        return paramConvert;
    };

    const initailForm = async () => {
        loadingState.showLoading();
        const paramSearchAbsence = getParamIniForm();
        const promiseAbsence = getLeaveAbsence(paramSearchAbsence);
        const promiseLut = lutAbsenceState.setInitialLut();
        const [resAbsence] = await Promise.all([promiseAbsence, promiseLut]);
        loadingState.hideLoading();

        if (resAbsence && resAbsence.data) {
            const dataTable = pareDataTable(resAbsence.data);
            dataTableState.setInitialDataTable(dataTable);
        } else {
            const msg = checkResStatusService(resAbsence);
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
            });
        }
    };

    useEffect(() => {
        initailForm();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const pareDataTable = (data) => {
        const dataUpdate = data.map((data) => {
            const isNotCancel = checkShowCancelAbsence(data.leaveStateId);

            const startMonth = new Date(data.startDate).getMonth();
            const endMonth = new Date(data.endDate).getMonth();
            const startDate = new Date(data.startDate).getDate();

            data.startDateDisplay = convertDateDisplay(data.startDate);
            data.endDateDisplay = convertDateDisplay(data.endDate);
            data.leaveDate =
                data.startDateDisplay === data.endDateDisplay
                    ? data.startDateDisplay
                    : startMonth === endMonth
                    ? `${startDate} - ${data.endDateDisplay}`
                    : `${data.startDateDisplay} - ${data.endDateDisplay}`;

            data.edit = permissionSystem.permisEdit && isNotCancel;
            data.detail = !data.edit;

            return data;
        });
        return dataUpdate;
    };

    const getParam = (values) => {
        const param = Object.assign({}, values);
        param.permissionOrgId = permissionOrgId;

        loadingState.showLoading();
        if (param[START_DATE]) {
            param.startDate = convertDateFormatService(param[START_DATE]);
        }

        if (param[END_DATE]) {
            param.endDate = convertDateFormatService(param[END_DATE]);
        }

        if (permissionOrgId !== PERMISSION_ORG.ALL_ORG) {
            //สิทธิ์มองเห็นเฉพาะหน่วยงานตัวเอง
            param.depId = depId;
        }

        if (param[NAME]) {
            param.name = param[NAME].trim();
        }

        if (param[NAME_SIGN]) {
            //สิทธิ์มองเห็นเฉพาะหน่วยงานตัวเอง
            param.nameSign = param[NAME_SIGN].trim();
        }

        if (param[ABSENCE_ID]) {
            param.absenceId = `${param[ABSENCE_ID]}/${param[YEAR]}`;
        }

        return param;
    };

    const handleSearch = async (values, isReSearch) => {
        const param = getParam(values);
        const result = await getLeaveAbsence(param);

        loadingState.hideLoading();

        if (result && result.data) {
            const dataTable = pareDataTable(result.data);
            dataTableState.setInitialDataTable(dataTable);

            if (isReSearch !== true) {
                setSearchParam(values);
            }
        } else {
            const msg = checkResStatusService(result);
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
            });
        }
    };

    const pareDataEdit = (initialEdit) => {
        const result = initialEdit;

        if (initialEdit.startDate) {
            result.startDateDisplay = convertDateDisplay(initialEdit.startDate);
        }

        if (initialEdit.endDate) {
            result.endDateDisplay = convertDateDisplay(initialEdit.endDate);
        }

        if (initialEdit.startTime) {
            result.startTimeDisplay = `${initialEdit.startTime.slice(0, 5)} น.`;
        }

        if (initialEdit.endTime) {
            result.endTimeDisplay = `${initialEdit.endTime.slice(0, 5)} น.`;
        }

        if (initialEdit.ordinationDate) {
            result.ordinationDateDisplay = convertDateDisplay(
                initialEdit.ordinationDate
            );
        }

        if (initialEdit.childBirthDate) {
            result.childBirthDateDisplay = convertDateDisplay(
                initialEdit.childBirthDate
            );
        }

        if (initialEdit.updateDate) {
            result.updateDateDisplay = convertDateDisplay(
                initialEdit.updateDate
            );
        }

        result.signBy = initialEdit.signBy.map((item) => {
            if (item.approveDate) {
                const splitDateTime = item.approveDate.split(' ');
                const dateFormat = convertDateDisplay(splitDateTime[0]);
                const timeFormat = `${splitDateTime[1].slice(0, 5)} น.`;

                item.aDate = dateFormat;
                item.aTime = timeFormat;
            } else {
                item.aDate = null;
                item.aTime = null;
            }

            return item;
        });

        return result;
    };

    const callServiceSpecialLeaveInfo = async (leaveTypeId) => {
        const result = await getLutSpecialLeave(leaveTypeId);
        if (result && result.data && result.data.length > 0) {
            // console.log('result.data.listSpecialLeaveName', result.data[0].listSpecialLeaveName)
            // let string = ''
            // result.data[0].listSpecialLeaveName.forEach((item, index) => {
            //     // if (index > 0) {
            //     //     string = `${string}<p>หรือ${item}</p>`
            //     // } else {
            //     //     string = `<p>${item}</p>`
            //     // }
            //     // if (index > 0) {
            //     //     string = `${string} หรือ${item}`
            //     // } else {
            //     //     string = `${item}`
            //     // }
            // });

            absenceState.setDataSpecialLeave(result.data[0]);
        }
    };

    const handleEdit = async (data, type) => {
        loadingState.showLoading();
        const res = await getLeaveAbsenceDetail(data.absenceId);
        if (res && res.data) {
            if (res.data.isSpecial) {
                callServiceSpecialLeaveInfo(data.leaveTypeId);
            }
            const dataEdit = pareDataEdit(res.data);

            absenceState.setdataEditAbsence({ ...dataEdit, type });
        } else {
            const msg = checkResStatusService(res);
            alertMessageState.showAlert({
                altMsg: msg ? msg : AlertMSGConstants.SYSTEM_ERROR,
            });
        }
        loadingState.hideLoading();
    };

    const handleCloseModal = (a) => {
        absenceState.setdataEditAbsence();
        panelState.changePanel(0);
    };

    const reFetchData = () => {
        handleSearch(searchParam, true);
    };

    const renderChildrenModal = () => {
        if (absenceState.dataEditAbsence) {
            return [
                <EditAbsence
                    key="EditAbsence"
                    onClose={handleCloseModal}
                    callBackSave={reFetchData}
                />,
                <SignAbsence key="SignAbsence" onClose={handleCloseModal} />,
            ];
        }
    };

    const renderModal = () => (
        <ModalAbsebceView reFetchData={reFetchData}>
            {renderChildrenModal()}
        </ModalAbsebceView>
    );

    const handleExportAbsence = () => {
        console.log(dataTableState.dataTable);
        console.log(searchParam);

        AbsenceReport(dataTableState.dataTable);
    };

    const renderAbsenceView = () => (
        <AbsenceView
            cntResult={dataTableState.dataTable.length}
            onExportAbsence={handleExportAbsence}
        >
            <FormSearchAbsence callBackSubmit={handleSearch} />
            <TableAbsence onEdit={handleEdit} />
        </AbsenceView>
    );

    return (
        <Fragment>
            {renderAbsenceView()}
            {renderModal()}
            <Loading isShowLoading={loadingState.isShowLoading} />
            <AlertMessage {...alertMessageState.altProps} />
        </Fragment>
    );
};

export default Absence;
